import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { TextField, Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormProvider } from '../../../components/hook-form';
import calenderIcon from '../../downloadEdits/CalenderIcon';
import { AutoFieldStyle, LabelStyle, FormStyling, DateGrid, GroupHeader, GroupItems } from './styled-components';

export default function CreditCardFilter({ filtersData, updateFilters, categories, hideSubCategoryFilter, setCategoriesDetails, categoriesDetails }) {
    const methods = useForm({});

    const [categoriesName, setCategoriesName] = useState([]);
    const [subCategoriesData, setSubCategoriesData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');

    const { t } = useTranslation();
    const appendLeadingZeroes = (n) => {
        if (n <= 9) {
            return `0${n}`;
        }
        return n
    }

    const updateFilterValue = (key, value) => {
        if (key === 'category') {
            if (value !== null) {
                updateFilters('categoryId', value.id);
                setSelectedCategory(value.name)
                getSubcategory(value.id);
                setCategoriesDetails({...categoriesDetails, catName: value.name})
            } else {
                updateFilters('categoryId', value);
                setSelectedCategory(value);
                getSubcategory();
                setCategoriesDetails({...categoriesDetails, catName: value})
            }
        } else if (key === 'subCatId') {
            if (value && value.id !== 'all') {
                setSelectedSubCategory(value.label)
                updateFilters(key, value.id);
                setCategoriesDetails({...categoriesDetails, subCatName: value?.label})
            } else {
                setSelectedSubCategory(value)
                updateFilters(key, null);
                setCategoriesDetails({...categoriesDetails, subCatName: value?.label})
            }
        } else if (key === 'fromMonth') {
            const parsezone= moment.parseZone(value).format('MM/DD/YYYY')
            const selectmonth = new Date(parsezone);
            const monthName = appendLeadingZeroes(selectmonth.getMonth() + 1);
            const selectMonthformat = `${selectmonth.getFullYear()}-${monthName}`;
            updateFilters(key, selectMonthformat);
        }
        else if (key === 'compareMonth') {
            const parsezone= moment.parseZone(value).format('MM/DD/YYYY')
            const selectmonth = new Date(parsezone);
            const compareMonthName = appendLeadingZeroes(selectmonth.getMonth() + 1);
            const compareYear = selectmonth.getFullYear();
            const compareMonthformat = `${compareYear}-${compareMonthName}`;
            updateFilters(key, compareMonthformat);
        }
    };

    const getSubcategory = async (categoryId) => {
        const category = categories.find((cat) => cat.id === categoryId);
        let subCategoryOptions = [];
        if (category) {
            const subcategoriesList = category.sub_categories || [];
            subCategoryOptions = subcategoriesList.map(
                (obj) => ({ id: obj.id, label: obj.name })
            ).sort((a, b) => a.label.localeCompare(b.label));
            if (subCategoryOptions.length > 1) {
                subCategoryOptions.unshift({ id: 'all', label: 'All' })
            }
        }
        setSubCategoriesData(subCategoryOptions);
        setSelectedSubCategory('');
    }

    const populateCategoryData = () => {
        const categoriesNames = categories?.map((obj) => ({ id: obj.id, label: obj.name }));
        setCategoriesName(categoriesNames);
    };

    useEffect(() => {
        populateCategoryData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories]);

    return (
        <Box>
            <FormStyling>
                <FormProvider methods={methods}>
                    <Grid container spacing={2} >
                        <Grid item md={3} xs={12}>
                            <DateGrid>
                                <LabelStyle shrink htmlFor="bootstrap-input">
                                    {t('fixed-expenses.from-Date')}
                                </LabelStyle>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        PaperProps={{ className: 'customDateSelector' }}
                                        value={moment.parseZone(filtersData.compareMonth).format('MM/DD/YYYY')}
                                        views={["year", "month"]}
                                        components={{
                                            OpenPickerIcon: calenderIcon,
                                        }}
                                        onChange={(date) => updateFilterValue('compareMonth', date)}
                                        renderInput={(params) => <TextField {...params}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: "To"
                                            }} />}
                                    />
                                </LocalizationProvider>
                            </DateGrid>
                        </Grid>

                        <Grid item md={3} xs={12}>
                            <DateGrid>
                                <LabelStyle shrink htmlFor="bootstrap-input">
                                    {t('fixed-expenses.to-date')}
                                </LabelStyle>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        PaperProps={{ className: 'customDateSelector' }}
                                        value={moment.parseZone(filtersData.fromMonth).format('MM/DD/YYYY')}
                                        views={["year", "month"]}
                                        components={{
                                            OpenPickerIcon: calenderIcon,
                                        }}
                                        onChange={(date) => updateFilterValue('fromMonth', date)}
                                        renderInput={(params) => <TextField {...params}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: "To"
                                            }} />}
                                    />
                                </LocalizationProvider>
                            </DateGrid>
                        </Grid>
                        {
                            categories?.length > 0 ?
                                <>
                                    <Grid item md={3} xs={12}>
                                        <LabelStyle shrink htmlFor="bootstrap-input">
                                            {t('fixed-expenses.Category')}
                                        </LabelStyle>
                                        <AutoFieldStyle
                                            options={categories}
                                            getOptionLabel={(option) => (option?.name || '')}
                                            groupBy={(option) => option.categoryGroupName}
                                            id="category"
                                            onChange={(e, val) => {
                                                updateFilterValue('category', val);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="standard" placeholder="Select" className="dropMenu" />
                                            )}
                                            renderGroup={(params) => (
                                                <li key={params.key}>
                                                    <GroupHeader>{params.group}</GroupHeader>
                                                    <GroupItems>{params.children}</GroupItems>
                                                </li>
                                            )}
                                        />
                                    </Grid>

                                    {!hideSubCategoryFilter && <Grid item md={3} xs={12}>
                                        <LabelStyle shrink htmlFor="bootstrap-input">
                                            {t('fixed-expenses.Sub-Category')}
                                        </LabelStyle>
                                        <AutoFieldStyle
                                            disabled={!selectedCategory}
                                            options={subCategoriesData}
                                            value={selectedSubCategory}
                                            id="sub-category"
                                            onChange={(e, val) => {
                                                updateFilterValue('subCatId', val);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="standard" placeholder="Select" className="dropMenu" />
                                            )}
                                        />
                                    </Grid>}
                                </>
                                :
                                ''
                        }
                    </Grid>
                </FormProvider>
            </FormStyling>
        </Box>
    );
}