import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, Button, Grid, TextField, Typography, styled } from '@mui/material';
import onboarding from 'constants/services/onboarding';
import yodlee from 'constants/services/yodlee';
import CountryData from 'constants/countryData';
import { showError } from 'utils/toast';
import { addDuration } from 'utils/formatTime';
import { FormContext } from 'pages/onboarding/stepper/context/StepperContext';
import { countryOptions } from 'pages/profile/financial-institution/constant';
import { StyledGrid } from 'pages/profile/personal-info/styled-components';
import videoURL from 'constants/helpVideoUrl';
import TopMenu from '../../../../../components/TopMenu';
import { FastLinkContainer, Fragment, HeadArea } from './styled-components';
import LinkedAccountInfo from './LinkedAccountInfo';
import { Wrapper } from '../../../setup/styled-components';

const AutoFieldStyle = styled(Autocomplete)(({ theme }) => ({
    fontSize: 16,
    paddingBottom: '10px',
    '& input': {
        fontSize: 16,
        [theme.breakpoints.up('xl')]: {
            fontSize: 16,
        },
    },
    '& .MuiInputBase-root': {
        '&:hover:before': {
            borderBottom: '0 !important',
        },
        '&.Mui-focused:after': {
            transform: 'scaleX(0) !important',
        },
    },
    '& .MuiAutocomplete-popupIndicator': {
        position: 'relative',
        width: 25,
        '& svg': {
            display: 'none',
        },
        '&:after': {
            position: 'absolute',
            right: 0,
            top: -4,
            content: "''",
            background: 'url(/static/loginImg/down-arrow.svg) 0 0 no-repeat',
            width: 25,
            height: 13,
        },
    },
}));

const Input = styled('div')(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontSize: 16,
    marginTop: '10px',
    whiteSpace: 'normal',
    overflow: 'auto',
    textOverflow: 'unset',
}));

export default function Step2({ onNext, mobilePlatForm }) {
    const {
        personalDetails,
        linkedInstitutions,
        setLinkedInstitutions,
        yodleeAccessToken,
        setYodleeAccessToken
    } = useContext(FormContext);
    const { t } = useTranslation();
    const [showOverview, setShowOverview] = useState(true);
    const [selectedCountry, setSelectedCountry] = useState(countryOptions[0]);
    const navigate = useNavigate();

    let selectedCountryData = CountryData.find(c => c.label === personalDetails?.country?.country);
    if (!selectedCountryData) {
        selectedCountryData = CountryData.find((c) => c.label === 'Canada');
    }

    const saveFinancialInstution = async (financialInstitutions) => {
        try {
            const institutions = financialInstitutions.filter((fi) => fi.status === 'SUCCESS').map((fi) => ({
                providerAccountId: fi.providerAccountId,
                providerId: fi.providerId,
                providerName: fi.providerName,
                requestId: fi.requestId,
            }));
            if (institutions?.length) {
                const result = await onboarding.saveFinancialInstution({ institutions });
                setLinkedInstitutions(result?.linkedInstitutions || []);
            }
        } catch (error) {
            showError(t, error);
        }
    };

    const isAccesssTokenValid = () => {
        if (!yodleeAccessToken?.accessToken) {
            return false;
        }
        if (addDuration(yodleeAccessToken.issuedAt, { seconds: 1799 }) < new Date()) {
            return false;
        }
        return true;
    }

    const loadFastlinkContainer = async () => {
        try {
            window.fastlink.close();
            let accessTokenInfo = yodleeAccessToken;
            document.body.classList.add('loading-indicator');
            if (!isAccesssTokenValid()) {
                const result = await yodlee.getYodleeToken();
                accessTokenInfo = result?.token || null;
                setYodleeAccessToken(accessTokenInfo);
            }
            const { accessToken } = accessTokenInfo;
            const configName = selectedCountry?.value || process.env.REACT_APP_YODLEE_FASTLINK_CONFIG_NAME_CA;
            const obAppName = process.env.REACT_APP_YODLEE_FASTLINK_OB_CONFIG_NAME || '';
            const config = {
                fastLinkURL: process.env.REACT_APP_YODLEE_FASTLINK_URL,
                accessToken: `Bearer ${accessToken}`,
                // params: { configName, obAppName, isIFrameMounted: true },
                params: { configName, obAppName },
                onSuccess: (data) => {
                    console.log(data);
                    document.body.classList.remove('loading-indicator');
                },
                onError: (data) => {
                    console.error("fi onError:");
                    console.error(data);
                    if (data.code) {
                        setShowOverview(true);
                    }
                    if (data.message === 'FastLink already in use, multiple instances of fastLink may not work as expected.') {
                        window.fastlink.close();
                    }
                    document.body.classList.remove('loading-indicator');
                },
                onClose: async (data) => {
                    console.log("fi onClose:");
                    console.log(data);
                    if (data?.sites?.length) {
                        await saveFinancialInstution(data.sites);
                    }
                    setShowOverview(true);
                    document.body.classList.remove('loading-indicator');
                },
                onEvent: (data) => {
                    console.log(data);
                    if (data?.type === 'OPEN_EXTERNAL_URL' && data?.data?.source === 'LOGIN_TNC') {
                        window.open(data?.data?.url, '_blank')
                    }
                    document.body.classList.remove('loading-indicator');
                }
            }
            window.fastlink.open(config, "container-fastlink");
        } catch (error) {
            console.log(error);
            showError(t, error);
            document.body.classList.remove('loading-indicator');
        }
    }

    useEffect(() => {
        if (!showOverview) {
            loadFastlinkContainer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOverview, selectedCountry]);

    const getHeading = () => {
        if (linkedInstitutions.length) {
            return "Step 2.3"
        }
        if (showOverview) {
            return "Step 2.1"
        }
    };

    const byPassOnboarding = async () => {
        try {
            await onboarding.updateOnboardingStatus(5);
            navigate('/welcome', { replace: true });
        } catch (error) {
            showError(t, error);
        }
    };

    const closeWebViewByPass = () => {
        window.postMessage({ action: 'closeWebView' }, '*');
    };

    return (
        <>
            {
                showOverview ?
                    <>
                        <div className='mdCustomPadding' >
                            <TopMenu title={getHeading()} hideHelp videoLink={videoURL.step2Video} />
                            <div className='hubPadding onboardingHeadingStyle'>
                                <Typography variant="h4" className='titleStyle margintitleStyle'>{t('step2.sub-heading')}</Typography>
                            </div>
                        </div>
                        <Wrapper>
                            <HeadArea>
                                <Typography variant="h6" className='commonText'>{linkedInstitutions.length ? t('step2.description_2') : ''}</Typography>
                            </HeadArea>
                            {!linkedInstitutions.length && <ul style={{ margin: '20px 0px 0px 20px' }}>
                                <li className='commonText' style={{ color: 'red', marginBottom: 5 }}>For your security and comfort please click <a href="/privacy-policy" target="_blank" rel="noreferrer">here</a> to read our Data Security and Privacy information.</li>
                                <li className='commonText' style={{ color: 'red', marginBottom: 5 }}>We use a third party API, provided by a Envestnet/Yodlee that is a leader in this space, which has connections to over 17,000 financial institutions worldwide.</li>
                                <li className='commonText' style={{ color: 'red', marginBottom: 5 }}>Envestnet/Yodlee is financial technology company which provides us with an interface that enables users to safely and securely link their accounts to MoolahMate. Envestnet is a public company listed on the New York Stock exchange with a market value of over $2 billion and employs over 4,000 employees. </li>
                                <li className='commonText' style={{ color: 'red', marginBottom: 5 }}>All of the connection information that you will provide is neither entered nor stored in the MoolahMate system, rather they are entered in an "i-frame" (a screen) that is provided by the API company.</li>
                            </ul>}
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    variant="contained"
                                    className='loadingButtonStyle2'
                                    style={{ marginTop: 26, marginLeft: 10, marginBottom: 26, textTransform: 'none' }}
                                    onClick={() => setShowOverview(false)}>
                                    {linkedInstitutions.length === 0 ? 'Link a financial institution' : 'Link more financial institution(s)'}
                                </Button>
                                { mobilePlatForm !== 'mobile-app'  && linkedInstitutions.length > 0 && <Button
                                    variant="contained"
                                    className='loadingButtonStyle2'
                                    style={{ marginTop: 26, marginLeft: 10, marginBottom: 26, textTransform: 'none', float: 'right' }}
                                    onClick={() => onNext()}>
                                    Next
                                </Button>}

                                { mobilePlatForm === 'mobile-app'  && linkedInstitutions.length > 0 && <Button
                                    variant="contained"
                                    className='loadingButtonStyle2'
                                    style={{ marginTop: 26, marginLeft: 10, marginBottom: 26, textTransform: 'none', float: 'right' }}
                                    onClick={() => closeWebViewByPass()}
                                >
                                    Finish
                                </Button>}
                            </div>
                            <Grid container spacing={2} style={{ margin: '0px', width: '100%' }}>
                                <LinkedAccountInfo linkedInstitutions={linkedInstitutions} />
                            </Grid>
                            {linkedInstitutions.length === 0 &&
                                <div className='commonText'>If you wish to by-pass the one-time set-up process at this time and simply navigate the site instead, click <a href="#" onClick={byPassOnboarding}>here</a>.<br />What this means is that:
                                    <ul style={{ marginTop: 5, marginLeft: 20 }}>
                                        <li className='commonText' style={{ marginBottom: 5 }}>You will not have linked your banks, credit cards and investment accounts so no transactions will be downloaded</li>
                                        <li className='commonText' style={{ marginBottom: 5 }}>The system will display the default categories and sub-categories</li>
                                        <li className='commonText' style={{ marginBottom: 5 }}>You will not have set up a budget</li>
                                    </ul>
                                    You can set-up all of this information, after your review by clicking on "My Settings" on the Hub</div>
                            }
                        </Wrapper>
                    </> : <Fragment>
                        <div className='mdCustomPadding' >
                            <TopMenu title='Step 2.2' hideHelp videoLink={videoURL.step2Video} />
                            <div className='hubPadding onboardingHeadingStyle'>
                                <Typography variant="h4" className='titleStyle margintitleStyle'>{t('step2.sub-heading2')}</Typography>
                            </div>
                        </div>
                        <Wrapper style={{ paddingRight: '10%' }}>
                            <Grid container spacing={4}>
                                <Grid item lg={8} md={8} xs={12}>
                                    <Typography variant="h6" className='commonText margintitleStyle'>{t('step2.description')}</Typography>
                                    <Typography variant="h6" className='error-text'>{t('link-institution.note')}</Typography>
                                    <Grid item lg={6} md={12} xs={12} style={{ paddingTop: '10px' }}>
                                        <StyledGrid container spacing={2} className='justifyContentStyle' style={{ justifyContent: 'start' }}>
                                            <Grid item xs={12} sm={3} className='mdPaddingTop' style={{ paddingTop: '0px' }}>
                                                <Input shrink htmlFor="bootstrap-input">
                                                    {t('personal-info.country')}
                                                </Input>
                                            </Grid>
                                            <Grid item sm={8} xs={12} className='mdPaddingTop' style={{ paddingTop: '0px' }}>
                                                <AutoFieldStyle
                                                    options={countryOptions}
                                                    value={selectedCountry}
                                                    getOptionLabel={(option) => option?.label}
                                                    disableClearable
                                                    onChange={(e, val) => setSelectedCountry(val)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            placeholder="Select Country"
                                                            className="dropMenu"
                                                            error=''
                                                            helperText=''
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </StyledGrid>
                                    </Grid>
                                    <FastLinkContainer id="container-fastlink" />
                                </Grid>
                            </Grid>
                        </Wrapper>
                    </Fragment>
            }
        </>
    );
}
