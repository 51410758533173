/* eslint-disable guard-for-in */
/* eslint-disable no-loop-func */
/* eslint-disable no-plusplus */
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useLocation } from 'react-router-dom';
import HeightIcon from '@mui/icons-material/Height';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import {ArrowDownwardIcon, ArrowUpwardIcon} from '@mui/icons-material';
import videoURL from 'constants/helpVideoUrl';
import { EXCEL_STYLE } from '../../calculators/constant';
import dashboard from '../../../constants/services/dashboard';
import Bargraph from '../Graphs/Bargraph';
import { CHARTCOLOR } from '../constant';
import Page from '../../../components/Page';
import TopMenu from '../../../components/TopMenu';
import TopHeading from '../../../components/TopHeading';
import CreditCardFilter from '../filter/CreditCardFilter';
import {
    NoDataTd,
    ButtonDivStyle,
    ReportAmountTd,
    TotalAmountDiv,
    SpanTotalFlow,
    ReportPercentageTd,
} from '../ExecutiveSummary/styled-component';
import {
    removeEmpty,
    getMonthNameYear,
    getCurrencySymbol,
    getPositiveNumber,
    getNegativeNumber,
    exportCustomExcel
} from '../../../utils/calCommonFunction';
import {
    ContentStyle,
    SubmitButton,
    TableStyle,
    StickyFirstTh,
    StickySecondTh,
    StickyThirdTh,
    TableWrapper,
    StickyFirstTd,
    StickySecondTd,
    StickyThirdTd,
    StickyTable,
    LoaderGif,
    AmountDiv,
} from '../Expense/styled-component';

export default function IncomeExpense(props) {
    const [expenseData, setExpenseData] = useState([]);
    const [expenseGroupData, setExpenseGroupData] = useState([]);
    const [monthColumn, setMonthColumn] = useState([]);
    const [monthWiseAverage, setMonthWiseAverage] = useState();
    const [monthWiseTotal, setMonthWiseTotal] = useState([]);

    const [monthWiseAvgTotal, setMonthWiseAvgTotal] = useState([])

    const [expenseMonthTotal, setExpenseMonthTotal] = useState();
    const [tableLoader, setTableLoader] = useState(false);
    const [categories, setCategories] = useState([]);
    const [showGraph, setShowGraph] = useState(true);
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState([]);

    const [incomeTotalArray, setIncomeTotalArray] = useState();
    const [incomeArrayAvg, setIncomeArrayAvg] = useState()

    const [incomeTotal, setIncomeTotal] = useState();
    const [incomeAvgTotal, setIncomeAvgTotal] = useState()

    const [sortingTotalStatus, setSortingTotalStatus] = useState(false)
    const [sortingAvgStatus, setSortingAvgStatus] = useState(false)
    const [expenseDataLength, setExpenseDataLength] = useState(null);

    const [excelData, setExcelData] = useState({})

    const location = useLocation();
    const currencyCode = getCurrencySymbol();
    const { reportName } = props;
    const { t } = useTranslation();

    const [categoriesDetails, setCategoriesDetails] = useState({
        catName: '',
        subCatName: ''
    })

    const [filtersData, setFiltersData] = useState({
        fromMonth: moment.parseZone().format('YYYY-MM'),
        compareMonth: moment.parseZone().startOf('year').format('YYYY-MM'),
        categoryGroupId: location?.state?.categoryGroupId || null,
        categoryId: null,
        type: 2,
        fetchUncategorizedExpenses: reportName === 'expense-composition' ? 'true' : 'false',
    });

    const updateFilters = (key, value) => {
        const clonedData = {
            ...filtersData,
            [key]: value,
        };
        if (key === 'categoryId') {
            clonedData.subCatId = '';
        }
        setFiltersData(clonedData);
    };

    const getExpenseCategoryData = async () => {
        const filtersDataObj = removeEmpty(filtersData);
        setTableLoader(true);

        const response = await dashboard.getDashboardData(filtersDataObj);
        const data = response?.categoriesData;

        setTableLoader(false);
        setExcelData(data)

        const groupedData = {};
        const groupedDataTot = {};

        const groupedDataMonthlyTot = [];
        const monthRange = Object.keys(data);
        const monthDifference = monthRange?.length;

        setExpenseDataLength(monthDifference);
        monthRange.sort();
        setMonthColumn(monthRange);

        // eslint-disable-next-line
        monthRange.forEach((item) => {
            let totalAsset = 0;
            let assetName = '';
            let cateTotal = 0;
            data[item].forEach((a) => {
                assetName = a.name;
                groupedData[a.name] = groupedData[a.name] || [];
                groupedData[a.name].push(a.values);
                totalAsset += Number(a.values);
                cateTotal = Number(groupedDataTot[a.name] ? groupedDataTot[a.name] : 0) + Number(a.values);
                groupedDataTot[assetName] = cateTotal;
            });
            groupedDataMonthlyTot.push(totalAsset);
        });

        const ObjectTotalData = Object.values(groupedDataTot);
        const expenseEle = Object.keys(groupedData);
        const monthAvgTotal = ObjectTotalData.map((item) => Number(item) / monthDifference);
        const monthTotal = expenseTotalSum(groupedDataMonthlyTot);
        const expenseavgTotal = expenseTotalSum(monthAvgTotal);

        const avgGroupData = []
        for(let i=0;i<groupedData[expenseEle[0]].length; i++) {
            const avgGrp = []
            expenseEle.forEach((keys)=>{
                avgGrp.push(groupedDataMonthlyTot[i] !== 0 ? Number(groupedData[keys][i] * 100 / groupedDataMonthlyTot[i]).toFixed(2) : '0.00');
            })
            const avgTotal = avgGrp.reduce((acc, val) => Number(acc) + Number(val), 0)
            avgGroupData.push(avgTotal)
        }

        const incomePercentageValue = []
        const incomePercentageAvg = []
       
        expenseEle.forEach((item, indx) => {
            incomePercentageValue.push((groupedDataTot[item] * 100) / monthTotal);
            incomePercentageAvg.push((monthAvgTotal[indx] * 100) / expenseavgTotal);
        });

        setIncomeTotalArray(incomePercentageValue);
        setIncomeArrayAvg(incomePercentageAvg);
        setMonthWiseAvgTotal(avgGroupData);

        const incomeTotalData = expenseTotalSum(incomePercentageValue);
        const incomeAvgData = expenseTotalSum(incomePercentageAvg);

        setIncomeTotal(incomeTotalData);
        setIncomeAvgTotal(incomeAvgData);

        setExpenseGroupData(groupedData);
        setMonthWiseAverage(expenseavgTotal);
        setExpenseMonthTotal(monthTotal);
        setMonthWiseTotal(groupedDataMonthlyTot);
        setExpenseData(expenseEle);

        const graphData = getGraphData(groupedData, monthRange);
        setChartData(graphData);
    };

    const expenseTotalSum = (array) => {
        const result = array.reduce((acc, val) => acc + val, 0);
        return result;
    };

    const getTotalSumofData = (item) => {
        const sumofdata = expenseGroupData[item].reduce((acc, item) => Number(acc) + Number(item), 0);
        return sumofdata;
    };

    const getPercentageSumofData = (item, index) => {
        const perCentageofData =
            monthWiseTotal[index] !== 0 ? Number((item * 100) / monthWiseTotal[index]).toFixed(2) : '0.00';
        return perCentageofData;
    };

    const getAvgSumofData = (item) => {
        const totalval = getTotalSumofData(item);
        const totalAvg = totalval / expenseDataLength;
        return totalAvg?.toFixed(2);
    };

    const getFilterData = () => {
        getExpenseCategoryData();
    };

    const handleSortingData = (arg, flag) => {
        if(reportName === 'expense-composition') {
            const getDescData= Object.keys(expenseGroupData)
            const sortedValue= getDescData.map((items)=>({
                'name': items,
                'total': getTotalSumofData(items),
                'avg': getAvgSumofData(items)   
            }))
    
            if (arg === 'total' && flag) {
                sortedValue.sort((a, b) => parseFloat(a.total) - parseFloat(b.total));
            }
            else if (arg === 'avg' && flag) {
                sortedValue.sort((a, b) => parseFloat(a.avg) - parseFloat(b.avg));
            }
            else{
                sortedValue.sort((a,b)=> a.name - b.name)
            }
    
            const sortedDiscretionaryData= sortedValue.map((items)=>items.name)
            setExpenseData(sortedDiscretionaryData);
        }
    };

    const handleAvgSorting = () => {
        setSortingAvgStatus((prevValue) => {
            const updatedValue = !prevValue;
            handleSortingData('avg', updatedValue);
            return updatedValue;
        });
        setSortingTotalStatus(false);
    };

    const handleTotalSorting = () => {
        setSortingTotalStatus((prevValue) => {
            const updatedValue = !prevValue;
            handleSortingData('total', updatedValue);
            return updatedValue;
        });
        setSortingAvgStatus(false);
    };

    const getGraphData = (data, monthrange) => {
        const categoryName = Object.keys(data);
        const catchart = Object.values(data);
        const dataset = [];

        monthrange.forEach((item, index) => {
            const dataitem = { label: getMonthNameYear(item), data: [], backgroundColor: CHARTCOLOR[index] };
            dataset.push(dataitem);
        });
        const labels = categoryName;
        for (let i = 0; i < catchart.length; i += 1) {
            const item = catchart[i];
            const monthData = item;
            for (let j = 0; j < dataset.length; j += 1) {
                let reqvalue = Number(monthData[j]);
                if (reqvalue < 0) {
                    reqvalue = Number(-reqvalue);
                }
                if (reqvalue > 0) {
                    reqvalue = Number(reqvalue);
                }
                dataset[j].data.push(reqvalue);
            }
        }
        // eslint-disable-next-line
        return { labels, dataset, title: t(`${reportName}.subtitle`) };
    };

    const getCategories = async () => {
        const response = await dashboard.getDashboardCategory({ categoryGroupId: filtersData.categoryGroupId });
        const categories = response?.data || [];

        setCategories(categories);
    };

    const getExcelData = (record, monthWiseTotal, monthWiseAvg, cateName) => {
        const arrayValue = []
        record.forEach((item, index) => {
            const existingMonth = arrayValue.find(outputItem => outputItem.Category === cateName);
            if (existingMonth) {
                existingMonth[monthColumn[index]] = reportName === 'income-composition' ? getPositiveNumber(item) : getNegativeNumber(item);
            } else {
                const newItem = { Category: cateName, Total: reportName === 'income-composition' ? getPositiveNumber(monthWiseTotal) : getNegativeNumber(monthWiseTotal), Average: reportName === 'income-composition' ? getPositiveNumber(monthWiseAvg?.toFixed(2)) : getNegativeNumber(monthWiseAvg?.toFixed(2)) };
                newItem[monthColumn[index]] = reportName === 'income-composition' ? getPositiveNumber(item) : getNegativeNumber(item);
                arrayValue.push(newItem);
            }
        });
        return arrayValue;
    }

    const getExcelSummary = () => {
        const outputArray = [];
        const percentageData = []
        const selectedFormData = []
        const merges = []

        selectedFormData.push({
            'From Date': filtersData.compareMonth,
            'To Date': filtersData.fromMonth,
            'Category Name': categoriesDetails.catName,
            'Sub-Category Name': categoriesDetails.subCatName
        })

        // eslint-disable-next-line no-restricted-syntax
        for (const month in excelData) {
            excelData[month].forEach(item => {
                const setValueSign = reportName === 'income-composition' ? getPositiveNumber(item.values) : getNegativeNumber(item.values);
                const existingItem = outputArray.find(outputItem => outputItem.Category === item.name);
                if (existingItem) {
                    existingItem[month] = setValueSign;
                } else {
                    const newItem = { Category: item.name, Total: reportName === 'income-composition' ? getPositiveNumber(getTotalSumofData(item.name)) : getNegativeNumber(getTotalSumofData(item.name)), Average: reportName === 'income-composition' ? getPositiveNumber(getAvgSumofData(item.name)) : getNegativeNumber(getAvgSumofData(item.name))};
                    newItem[month] = setValueSign;
                    outputArray.push(newItem);
                }
            });
        }

        const totalValue = getExcelData(monthWiseTotal, expenseMonthTotal, monthWiseAverage, 'Total')

        const excelObjectData = [...outputArray, ...totalValue];

        const getPercentageData=(value, index) => monthWiseTotal[index] !== 0 ?  Number(value * 100 / monthWiseTotal[index]).toFixed(2) : '0.00';
        
        // eslint-disable-next-line no-restricted-syntax
        Object.keys(excelData).forEach((items, index)=>{
            excelData[items].forEach((item, indx) => {
                const existingPercentage = percentageData.find(outputItem => outputItem.Category === item.name);
                if (existingPercentage) {
                    existingPercentage[items] = getPositiveNumber(getPercentageData(item.values, index));
                } else {
                    const newItem = { Category: item.name, Total: getPositiveNumber(incomeTotalArray[indx]), Average: getPositiveNumber(incomeArrayAvg[indx]) };
                    newItem[items] = getPositiveNumber(getPercentageData(item.values, index));
                    percentageData.push(newItem);
                }
            });
        })

        const percentagetotalValue = getExcelData(monthWiseAvgTotal, incomeTotal, incomeAvgTotal, 'Total')

        const style = [
            {
                "col": 'A1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'B1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'C1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'D1',
                "style": EXCEL_STYLE.boldHeader
            }
        ]

        const newOutputArray = excelObjectData.map((item) => {
            const { Total, Average, Category, ...rest } = item;
            return { Category, ...rest, Total, Average };
        });

        const newPercentageData = percentageData.map((item) => {
            const { Total, Average, Category, ...rest } = item;
            return { Category, ...rest, Total, Average };
        });
        
        const data2 = [{}, ...newPercentageData, ...percentagetotalValue];
    
        const addPercentage = data2.map(obj => {
            const newObj = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const key in obj) {
                if (!['Category'].includes(key)) {
                    newObj[key] = `${obj[key]} %`;
                } else {
                    newObj[key] = obj[key];
                }
            }
            return newObj;
        });

        const addCurrencySymbol = newOutputArray.map(obj => {
            const newObj = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const key in obj) {
                if (!['Category'].includes(key)) {
                    newObj[key] = `$ ${obj[key]}`;
                } else {
                    newObj[key] = obj[key];
                }
            }
            return newObj;
        });
        let data = []

        if (reportName === 'income-composition') {
            data = [selectedFormData, addCurrencySymbol, addPercentage];
        } else {
            data = [selectedFormData, addCurrencySymbol];
        }

        const cols = [{ width: 18 }, { width: 13 }, { width: 17 }, { width: 20 }, { width: 13 }, { width: 13 }, { width: 13 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 15 }];
        exportCustomExcel(data, style, cols, merges, reportName, 7, ['I'], [false, false, true], [-1, 'A4', -1]);
    }

    useEffect(() => {
        getExpenseCategoryData();
        getCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const videoUrl = reportName === 'income-composition' ? videoURL.incomeComposition : videoURL.expenseComposition;

    return (
        <>
            <Page title={t(`${reportName}.title`)}>
                <TopMenu title="Income and Expense Reports" videoLink={videoUrl} />
                <TopHeading heading={t(`${reportName}.subtitle`)} />
                <ContentStyle>
                    <Grid container pt={0} pb={4} mt={0}>
                        <Grid item md={10.5} xs={12}>
                            <CreditCardFilter
                                filtersData={filtersData}
                                updateFilters={updateFilters}
                                categories={reportName === 'savings' ? [] : categories}
                                categoriesDetails={categoriesDetails}
                                setCategoriesDetails={setCategoriesDetails}
                            />
                        </Grid>
                        <Grid
                            item
                            md={1.5}
                            xs={12}
                            style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}
                        >
                            <SubmitButton
                                loading={loading}
                                size="large"
                                variant="contained"
                                onClick={() => getFilterData()}
                                className="loadingButtonStyle2"
                            >
                                Go
                            </SubmitButton>
                        </Grid>
                    </Grid>
                    {showGraph ? (
                        <Grid container spacing={0} pt={0} justifyContent="center" alignItems="flex-start">
                            <Grid item xs={12} sm={12} md={12}>
                                <StickyTable>
                                    <TableWrapper>
                                        <TableStyle className="highlighted-fields">
                                            {tableLoader ? (
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={4}>
                                                            <LoaderGif
                                                                alt="homeInfo"
                                                                src="/static/animation_loader.gif"
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ) : (
                                                <>
                                                    {expenseData?.length > 0 ? (
                                                        <>
                                                            <thead>
                                                                <tr>
                                                                    <StickyFirstTh>
                                                                        {t(`${reportName}.category`)}
                                                                    </StickyFirstTh>
                                                                    {monthColumn.map((item, indx) => (
                                                                        <th style={{ textAlign: 'end' }} key={indx}>
                                                                            {getMonthNameYear(item)}
                                                                        </th>
                                                                    ))}
                                                                    <StickySecondTh
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            right:
                                                                                reportName === 'savings'
                                                                                    ? '119px'
                                                                                    : '139px',
                                                                        }}
                                                                        onClick={handleTotalSorting}
                                                                    >
                                                                        {reportName === 'expense-composition' ? (
                                                                            <span
                                                                                className={`sortingIcon ${
                                                                                    sortingTotalStatus
                                                                                        ? 'activeIcon'
                                                                                        : ''
                                                                                }`}
                                                                            >
                                                                                {' '}
                                                                                {t(`${reportName}.total`)}{' '}
                                                                                {sortingTotalStatus ? (
                                                                                    <ArrowDownwardIcon
                                                                                        className="sortingReportIcon"
                                                                                        style={{ opacity: 1 }}
                                                                                    />
                                                                                ) : (
                                                                                    <HeightIcon className="sortingReportIcon" />
                                                                                )}{' '}
                                                                            </span>
                                                                        ) : (
                                                                            t(`${reportName}.total`)
                                                                        )}
                                                                    </StickySecondTh>
                                                                    <StickyThirdTh
                                                                        style={{ right: '-1px', cursor: 'pointer' }}
                                                                        onClick={handleAvgSorting}
                                                                    >
                                                                        {reportName === 'expense-composition' ? (
                                                                            <span
                                                                                className={`sortingIcon ${
                                                                                    sortingAvgStatus ? 'activeIcon' : ''
                                                                                }`}
                                                                            >
                                                                                {t(`${reportName}.average`)}{' '}
                                                                                {sortingAvgStatus ? (
                                                                                    <ArrowDownwardIcon
                                                                                        className="sortingReportIcon"
                                                                                        style={{ opacity: 1 }}
                                                                                    />
                                                                                ) : (
                                                                                    <HeightIcon className="sortingReportIcon" />
                                                                                )}{' '}
                                                                            </span>
                                                                        ) : (
                                                                            t(`${reportName}.average`)
                                                                        )}
                                                                    </StickyThirdTh>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {expenseData.map((item, index) => (
                                                                    <>
                                                                        <tr key={index}>
                                                                            <StickyFirstTd>{item}</StickyFirstTd>
                                                                            {expenseGroupData[item].map(
                                                                                (item, indx) => (
                                                                                    <ReportAmountTd key={indx}>
                                                                                        <TotalAmountDiv>
                                                                                            {currencyCode}{' '}
                                                                                            <span>
                                                                                                {reportName ===
                                                                                                'income-composition'
                                                                                                    ? getPositiveNumber(
                                                                                                        item
                                                                                                    )
                                                                                                    : getNegativeNumber(
                                                                                                        item
                                                                                                    )}
                                                                                            </span>
                                                                                        </TotalAmountDiv>
                                                                                    </ReportAmountTd>
                                                                                )
                                                                            )}
                                                                            <StickySecondTd style={{ right: '139px' }}>
                                                                                <TotalAmountDiv
                                                                                    style={{
                                                                                        width: 120,
                                                                                        // float: 'right',
                                                                                    }}
                                                                                >
                                                                                    {currencyCode}{' '}
                                                                                    <span>
                                                                                        {reportName ===
                                                                                        'income-composition'
                                                                                            ? getPositiveNumber(
                                                                                                getTotalSumofData(
                                                                                                    item
                                                                                                )
                                                                                            )
                                                                                            : getNegativeNumber(
                                                                                                getTotalSumofData(
                                                                                                    item
                                                                                                )
                                                                                            )}
                                                                                    </span>
                                                                                </TotalAmountDiv>
                                                                            </StickySecondTd>

                                                                            <StickyThirdTd style={{ right: '-1px' }}>
                                                                                <TotalAmountDiv
                                                                                    style={{
                                                                                        width: 120,
                                                                                    }}
                                                                                >
                                                                                    {currencyCode}{' '}
                                                                                    <span>
                                                                                        {reportName ===
                                                                                        'income-composition'
                                                                                            ? getPositiveNumber(
                                                                                                getAvgSumofData(
                                                                                                    item
                                                                                                )
                                                                                            )
                                                                                            : getNegativeNumber(
                                                                                                getAvgSumofData(
                                                                                                    item
                                                                                                )
                                                                                            )}
                                                                                    </span>
                                                                                </TotalAmountDiv>
                                                                            </StickyThirdTd>
                                                                        </tr>
                                                                    </>
                                                                ))}
                                                                <tr>
                                                                    <StickyFirstTd
                                                                        style={{
                                                                            height: '51px',
                                                                            fontSize: '16px',
                                                                            fontWeight: '600',
                                                                        }}
                                                                    >
                                                                        {t(`${reportName}.total`)}
                                                                    </StickyFirstTd>
                                                                    {monthWiseTotal.map((item, index) => (
                                                                        <SpanTotalFlow key={index}>
                                                                            <TotalAmountDiv>
                                                                                {currencyCode}{' '}
                                                                                <span>
                                                                                    {reportName === 'income-composition'
                                                                                        ? getPositiveNumber(item)
                                                                                        : getNegativeNumber(item)}
                                                                                </span>
                                                                            </TotalAmountDiv>
                                                                        </SpanTotalFlow>
                                                                    ))}
                                                                    <StickySecondTd style={{ right: '139px' }}>
                                                                        <AmountDiv>
                                                                            {currencyCode}
                                                                            <span>
                                                                                {reportName === 'income-composition'
                                                                                    ? getPositiveNumber(
                                                                                        expenseMonthTotal
                                                                                    )
                                                                                    : getNegativeNumber(
                                                                                        expenseMonthTotal
                                                                                    )}
                                                                            </span>
                                                                        </AmountDiv>
                                                                    </StickySecondTd>
                                                                    <StickyThirdTd style={{ right: '-1px' }}>
                                                                        <AmountDiv>
                                                                            {currencyCode}
                                                                            <span>
                                                                                {reportName === 'income-composition'
                                                                                    ? getPositiveNumber(
                                                                                        monthWiseAverage?.toFixed(2)
                                                                                    )
                                                                                    : getNegativeNumber(
                                                                                        monthWiseAverage?.toFixed(2)
                                                                                    )}
                                                                            </span>
                                                                        </AmountDiv>
                                                                    </StickyThirdTd>
                                                                </tr>
                                                                {
                                                                    reportName === 'income-composition' ?
                                                                        <>
                                                                            <tr>
                                                                                <td colSpan={7} style={{ background: '#fff' }}>{''}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={7} style={{ background: '#fff' }}>{''}</td>
                                                                            </tr>
                                                                            {expenseData.map((item, index) => (
                                                                                <>
                                                                                    <tr key={index}>
                                                                                        <StickyFirstTd>{item}</StickyFirstTd>
                                                                                        {expenseGroupData[item].map(
                                                                                            (item, indx) => (
                                                                                                <ReportPercentageTd key={indx}>
                                                                                                    <TotalAmountDiv style={{marginLeft: 'auto'}}>
                                                                                                        <span>
                                                                                                            {
                                                                                                                getPositiveNumber(getPercentageSumofData(item, indx))
                                                                                                            }
                                                                                                            {' '}%
                                                                                                        </span>
                                                                                                    </TotalAmountDiv>
                                                                                                </ReportPercentageTd>
                                                                                            )
                                                                                        )}
                                                                                        <StickySecondTd style={{ right: '139px' }}>
                                                                                            <TotalAmountDiv
                                                                                                style={{
                                                                                                    width: 120,
                                                                                                    justifyContent:'end'
                                                                                                }}
                                                                                            >
                                                                                                <span> {getPositiveNumber(incomeTotalArray[index])} %</span>
                                                                                            </TotalAmountDiv>
                                                                                        </StickySecondTd>

                                                                                        <StickyThirdTd style={{ right: "-1px" }}>
                                                                                            <TotalAmountDiv
                                                                                                style={{
                                                                                                    width: 120,
                                                                                                    justifyContent:'end'
                                                                                                }}
                                                                                            >
                                                                                                <span> {getPositiveNumber(incomeArrayAvg[index])} %</span>
                                                                                            </TotalAmountDiv>
                                                                                        </StickyThirdTd>
                                                                                    </tr>
                                                                                </>
                                                                            ))}

                                                                            <tr>
                                                                                <StickyFirstTd
                                                                                    style={{
                                                                                        height: '51px',
                                                                                        fontSize: '16px',
                                                                                        fontWeight: '600',
                                                                                    }}
                                                                                >
                                                                                    {t(`${reportName}.total`)}
                                                                                </StickyFirstTd>
                                                                                {
                                                                                    monthWiseAvgTotal.map((item, indx) => (
                                                                                        <SpanTotalFlow key={indx}>
                                                                                            <TotalAmountDiv style={{ justifyContent: 'end' }}>
                                                                                                <span>
                                                                                                    {
                                                                                                        getPositiveNumber(Math.round(item))
                                                                                                    }
                                                                                                    {' '}%
                                                                                                </span>
                                                                                            </TotalAmountDiv>
                                                                                        </SpanTotalFlow>
                                                                                    ))
                                                                                }
                                                                                <StickySecondTd style={{ right: '139px' }}>
                                                                                    <AmountDiv style={{justifyContent:'end'}}>
                                                                                        <span>
                                                                                            { getPositiveNumber(incomeTotal)} %
                                                                                        </span>
                                                                                    </AmountDiv>
                                                                                </StickySecondTd>
                                                                                <StickyThirdTd style={{ right: '-1px' }}>
                                                                                    <AmountDiv style={{justifyContent:'end'}}>
                                                                                        <span>
                                                                                            { getPositiveNumber(incomeAvgTotal)} %
                                                                                        </span>
                                                                                    </AmountDiv>
                                                                                </StickyThirdTd>
                                                                            </tr>
                                                                        </>
                                                                        : ''
                                                                }

                                                            </tbody>
                                                        </>
                                                    ) : (
                                                        <tbody>
                                                            <tr>
                                                                <NoDataTd colSpan={4}>
                                                                    {t('executive-summary.no-data-found')}
                                                                </NoDataTd>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </>
                                            )}
                                        </TableStyle>
                                    </TableWrapper>
                                </StickyTable>
                            </Grid>
                        </Grid>
                    ) : (
                        <Bargraph chartData={chartData} currencyCode={currencyCode} />
                    )}
                    <ButtonDivStyle>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            // disabled={}
                            onClick={() => setShowGraph(!showGraph)}
                            className="loadingButtonStyle2"
                        >
                            {showGraph ? t(`${reportName}.graph`) : t(`${reportName}.view-table`)}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            // disabled={}
                            onClick={() => getExcelSummary()}
                            className='loadingButtonStyle2'
                        >
                            Export
                        </LoadingButton>
                    </ButtonDivStyle>
                </ContentStyle>
            </Page>
        </>
    );
}