import request from '../../utils/request';

const getMasterData = () => request.get('categories/master-data');
const saveUserPersonalInfo = (data) => request.post('users/personal-info', data);
const saveCategoryData = (data) => request.post('/categories/user', data);
const addSubCategoryData = (data) => request.post('/sub-categories', data);
const getUserProfileSummary = () => request.get('/users/profile-summary');
const getOnboardingData = () => request.get('/users/onboarding-data');
const getBudgetOnboardingData = () => request.get('/users/onboarding-budget-data');
const saveFinancialInstution = (data) => request.post('users/link-financial-institution', data);
const updateOnboardingStatus = (status) => request.post(`users/update-onboarding-status/${status}`);
const getUserDataFromToken = () => request.get('/users/mob-onboarding-data');
const skipOnboarding = () => request.post('users/skip-onboarding');
const deleteCategory = (categoryId) => request.delete(`/categories/${categoryId}`);
const deleteSubCategory = (categoryId) => request.delete(`/sub-categories/${categoryId}`)

export default {
    getMasterData,
    saveUserPersonalInfo,
    saveCategoryData,
    addSubCategoryData,
    getUserProfileSummary,
    getOnboardingData,
    getBudgetOnboardingData,
    saveFinancialInstution,
    updateOnboardingStatus,
    skipOnboarding,
    getUserDataFromToken,
    deleteCategory,
    deleteSubCategory
};