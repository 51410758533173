import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import './styles.css'

export default function pageHeadingStyle(props) {
    return (
        <div className='productDetailsStyle'>
            <img src={props.imageUrl} alt=''/>
            <Typography variant="h6">{props.headLineOne}</Typography>
            <Typography variant="body2"><strong>{props.headLineThree}</strong> {props.headLineFour}</Typography>
            {
                props.keyIndx === 1 &&
                <LoadingButton className='loadingButtonStyle1' style={{marginTop: '16px'}} type="submit" variant="outlined" href="/calculators-hub">
                    See Calculators
                </LoadingButton>
            }
        </div>
    );
}