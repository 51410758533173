import { styled, Typography, Grid, Box, Card, Stack } from '@mui/material';

export const RootStyle = styled('div')(() => ({
    paddingBottom: 30,
}));

export const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 90,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: 0,
    background: theme.palette.primary.main,
    borderRadius: 0,
    position: 'fixed',
    height: '100vh',
    [theme.breakpoints.down('md')]: {
        maxWidth: 50,
    },
}));

export const Wrapper = styled(Grid)(({ theme }) => ({
    padding: '20px 16% 50px calc(4% + 90px)',
    [theme.breakpoints.up('llg')]: {
        padding: '60px 10% 40px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '50px 10px 40px 60px',
    },
}));

export const ProfileData = styled(Box)(({ theme }) => ({
    '& .pTitle': {
        fontSize: '18px',
        fontWeight: 700,
        color: theme.palette.primary.dark,
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
    '& .pDescription': {
        fontSize: '16px',
        paddingLeft: '4px',
        fontWeight: 400,
        color: theme.palette.primary.contrastText,
    },
}));

export const ProceedingBtn = styled('div')(({ theme }) => ({
    padding: '40px 15% 0px 0px',
    display: 'flex',
    width: '100%',
    '& button':{
        maxWidth: 200,
        minWidth: 200,
        marginRight: '30px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'initial',
            minWidth: 'auto',
            marginRight: '0px',
            marginTop: '20px'
        },
    },
    [theme.breakpoints.up('llg')]: {
        padding: '60px 0px 28px',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '0px',
        display: 'block',
        marginTop: '14px'
    },
}));

export const ContentStack = styled(Stack)(({ theme }) => ({
    paddingTop: '0px',
    [theme.breakpoints.up('llg')]: {
        paddingTop: '20px',
    },
}));

export const Heading = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    width: '60%',
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
}));

export const SmallLogoStyle = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '20px 23px 20px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        padding: '20px 3px 20px',
    },
    '& a div': {
        '& path': {
            fill: theme.palette.secondary.contrastText,
        },
    },
    '& + .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',
        margin: 'auto 0',
    },
}));