/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import clone from 'rfdc/default';
import { useNavigate } from 'react-router-dom';
import DialogAtom from 'components/dialog/Dialog';
import Page from 'components/Page';
import videoURL from 'constants/helpVideoUrl';
import ListAddCategory from './ListAddCategory';
import Iconify from '../../../components/Iconify';
import { AccordionStyle, HeadArea } from './styled-components';
import onboarding from '../../../constants/services/onboarding';
import TopHeading from '../../../components/TopHeading';
import TopMenu from '../../../components/TopMenu';
import { goBackToMenu } from '../../../utils/calCommonFunction';

export default function Category() {
    const [categoriesMasterData, setCategoriesMasterData] = useState([]);
    const [openAccordionsStep3, setOpenAccordionsStep3] = useState(['Assets']);
    const [showDeleteCategoryDialog, setShowDeleteCategoryDialog] = useState(false);
    const [selectedCategoryToDelete, setSelectedCategoryToDelete] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const onCategoryAccordionClick = (panelName) => (event, isExpanded) => {
        const clonedAccordion = [...openAccordionsStep3];
        if (isExpanded) {
            clonedAccordion.push(panelName);
        } else {
            const categoryIndex = clonedAccordion.indexOf(panelName);
            if (categoryIndex > -1) {
                clonedAccordion.splice(categoryIndex, 1);
            }
        }
        setOpenAccordionsStep3(clonedAccordion);
    };

    const addCategory = (categoryGroupId) => {
        const categoryGroups = [...categoriesMasterData];
        const catGroup = categoryGroups.find((cg) => cg.id === categoryGroupId);
        if (catGroup) {
            let position = 1;
            if (catGroup.categories?.length) {
                position = catGroup.categories.length + 1;
            }
            const tempCategoryObj = {
                id: new Date().getTime(),
                name: '',
                isDiscretionaryDefaultValue: false,
                isSelected: true,
                isUserCategory: true,
                isEdited: true,
                isSynced: false,
                position,
                subCategories: [{
                    id: new Date().getTime(),
                    isSelected: true,
                    isEdited: true,
                    isSynced: false,
                    name: '',
                    position: 1,
                    isUpdatedManually: false,
                    budget: '',
                    isDiscretionary: false
                }]
            };
            if (catGroup.name === 'Expenses/Cash Outflows') {
                const catLoanPaymentIndex = catGroup.categories.findIndex((cat) => cat.name === 'Loan/Lease payments');
                if (catLoanPaymentIndex > -1) {
                    const catLoanPayment = catGroup.categories[catLoanPaymentIndex];
                    tempCategoryObj.position = catLoanPayment.position;
                    catLoanPayment.position += 1;
                    catLoanPayment.isEdited = true;
                    catGroup.categories.splice(catLoanPaymentIndex, 0, tempCategoryObj);
                }
            } else {
                catGroup.categories.push(tempCategoryObj);
            }
        }
        setCategoriesMasterData(categoryGroups);
    }

    const updateCategoryData = (categoryGroupId, tempCatId, key, value) => {
        const categoryGroups = [...categoriesMasterData];
        const catGroup = categoryGroups.find((cg) => cg.id === categoryGroupId);
        if (catGroup) {
            const category = catGroup.categories?.find(cat => cat.id === tempCatId);
            if (category) {
                category[key] = value;
                if (key === 'name') {
                    category.error = '';
                    if (!category.isSynced && category.subCategories?.length === 1 && !category.subCategories?.name) {
                        category.subCategories[0].name = value;
                    }
                }
                category.isEdited = true;
            }
        }
        setCategoriesMasterData(categoryGroups);
    }

    const fetchCategoriesMasterData = async () => {
        const response = await onboarding.getOnboardingData();
        const { userCategoriesData } = response?.data;
        const data = await onboarding.getMasterData();
        const categoryMasterData = data?.data || [];

        const clonedCatData = clone(categoryMasterData);

        if (userCategoriesData.length) {
            const defaultSubCatObj = {
                id: new Date().getTime(),
                isSelected: true,
                isEdited: true,
                isSynced: false,
                position: 1,
                budget: '',
            };
            userCategoriesData.forEach((uCat) => {
                const { id, masterCategoryId, categoryGroupId, name, position, isSelected, isUserCategory, catTransactionCount, sub_categories: subCategories } = uCat;

                let subCats = (subCategories || []).map((subCat) => ({
                    ...subCat,
                    isEdited: false,
                    isSynced: true,
                    budget: '',
                }));

                const catGroup = clonedCatData.find((c) => c.id === categoryGroupId);
                if (catGroup) {
                    if (!isUserCategory && masterCategoryId) {
                        const category = catGroup.categories.find((cat) => cat.id === masterCategoryId);
                        if (category) {
                            category.id = id;
                            category.position = position;
                            category.isSelected = isSelected;
                            category.isSynced = true;
                            category.isEdited = false;
                            category.catTransactionCount = catTransactionCount;

                            if (subCats.length && subCats.some((s) => s.isSelectionDisabled)) {
                                category.isSelectionDisabled = true;
                            }

                            if (!subCats.length) {
                                subCats = [{
                                    ...defaultSubCatObj,
                                    name,
                                    position: 1,
                                    budget: '',
                                    isUpdatedManually: category.isUpdatedManually,
                                    isDiscretionary: category.isDiscretionaryDefaultValue
                                }];
                            }
                            category.subCategories = subCats;
                        }
                    } else {
                        if (!subCats.length) {
                            subCats = [{
                                ...defaultSubCatObj,
                                name,
                                position: 1,
                                budget: '',
                                isUpdatedManually: true,
                                isDiscretionary: false
                            }];
                        }

                        catGroup.categories.push({
                            id,
                            name,
                            position,
                            catTransactionCount,
                            isUserCategory: true,
                            isSelected,
                            isEdited: false,
                            isSynced: true,
                            subCategories: subCats,
                        });
                    }
                }
            });
        }
        clonedCatData.forEach((cg) => {
            const { categories } = cg;
            (categories || []).sort((a, b) => a.position - b.position);
            (categories || []).forEach((cat) => {
                const { subCategories } = cat;
                (subCategories || []).sort((a, b) => a.position - b.position);
            })
        })
        setCategoriesMasterData(clonedCatData);
    }

    useEffect(() => {
        fetchCategoriesMasterData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveCategories = async () => {
        const clonedCategoriesData = clone(categoriesMasterData);
        const openAccordions = [...openAccordionsStep3];
        clonedCategoriesData.forEach((catGroup) => {
            catGroup.categories.forEach((cat) => { cat.error = '' });
            const filteredCategories = catGroup.categories.filter((cat) => cat.isUserCategory && cat.isSelected);
            filteredCategories.forEach((cat) => {
                if (!cat.name) {
                    cat.error = t('error-message.category-name-required');
                    if (!openAccordions.includes(catGroup.name)) {
                        openAccordions.push(catGroup.name);
                    }
                }
            });
        });
        const isValid = clonedCategoriesData.every((catGroup) => catGroup.categories.every((cat) => !cat.error));
        if (!isValid) {
            setOpenAccordionsStep3(openAccordions);
            setCategoriesMasterData(clonedCategoriesData);
            return false;
        }

        const masterCategories = categoriesMasterData.reduce((acc, catGroup) => {
            const filteredCategories = catGroup.categories.filter((cat) => !cat.isUserCategory && cat.isEdited);
            filteredCategories.forEach((cat) => {
                const { id, isSelected, isSynced, position } = cat;
                if (!isSynced && isSelected) {
                    const catObj = {
                        categoryId: id,
                        isSelected,
                        position,
                    };
                    acc.push(catObj);
                } else if (isSynced) {
                    const catObj = {
                        id,
                        isSelected,
                        position
                    };
                    acc.push(catObj);
                }
            });
            return acc;
        }, []);

        const userCategories = categoriesMasterData.reduce((acc, catGroup) => {
            const filteredCategories = catGroup.categories.filter((cat) => cat.isUserCategory && cat.isEdited);
            filteredCategories.forEach((cat) => {
                const { id, isSelected, name, position, isSynced } = cat;
                if (!isSynced && isSelected) {
                    const catObj = {
                        categoryGroupId: catGroup.id,
                        name,
                        position,
                        isSelected,
                    };
                    acc.push(catObj);
                } else if (isSynced) {
                    const catObj = {
                        id,
                        categoryGroupId: catGroup.id,
                        name,
                        position,
                        isSelected,
                    };
                    acc.push(catObj);
                }
            });
            return acc;
        }, []);

        // if (masterCategories.length || userCategories.length) {
        const payload = {
            masterCategories,
            userCategories,
            view: 'profile'
        }
        const response = await onboarding.saveCategoryData(payload);
        const { masterCategories: masterCatList, userCategories: userCatList } = response?.data;

        const clonedCatData = clone(categoriesMasterData);
        masterCatList.forEach((mCat) => {
            const { id, categoryId } = mCat;
            clonedCatData.forEach((catGroup) => {
                const category = catGroup.categories.find((cat) => cat.id === categoryId || cat.id === id);
                if (category) {
                    category.id = id;
                    category.isSynced = true;
                    category.isEdited = false;
                }
            });
        });

        userCatList.forEach((uCat) => {
            const { id, categoryGroupId, name, position } = uCat;
            const catGroup = clonedCatData.find((c) => c.id === categoryGroupId);
            if (catGroup) {
                const category = catGroup.categories.find((cat) => cat.name === name && cat.position === position);
                if (category) {
                    category.id = id;
                    category.isSynced = true;
                    category.isEdited = false;
                }
            }
        });
        setCategoriesMasterData(clonedCatData);
        // }
        return true;
    };

    const onClickDeleteCategory = (categoryObj) => {
        setSelectedCategoryToDelete(categoryObj);
        setShowDeleteCategoryDialog(true);
    };

    const deleteCategory = async () => {
        const { categoryGroupId, categoryId } = selectedCategoryToDelete;
        if (categoryGroupId && categoryId) {
            const d = new Date(categoryId);
            // eslint-disable-next-line no-restricted-globals
            if (d instanceof Date && !isNaN(d)) {
                const categoryGroups = [...categoriesMasterData];
                const catGroup = categoryGroups.find((cg) => cg.id === categoryGroupId);
                if (catGroup) {
                    const categoryIndex = catGroup.categories?.findIndex(cat => cat.id === categoryId);
                    if (categoryIndex > -1) {
                        catGroup.categories.splice(categoryIndex, 1);
                        setCategoriesMasterData(categoryGroups);
                    }
                }
            } else {
                await onboarding.deleteCategory(categoryId);
            }
        }
        setShowDeleteCategoryDialog(false);
        setSelectedCategoryToDelete(null);
        setIsLoading(false);
        fetchCategoriesMasterData();
    }

    const onDialogAction = async (buttonKey) => {
        if (buttonKey === 'cancel') {
            setShowDeleteCategoryDialog(false);
        } else {
            setIsLoading(true);
            deleteCategory();
        }
    };

    const getDeleteAccountDialog = () => (
        <DialogAtom
            open
            maxWidth="sm"
            dialogTitle={t('my-profile.delete-category')}
            content={<>{t('my-profile.delete-category-message')}</>}
            onDialogAction={onDialogAction}
            saveButtonLabel={t('my-profile.delete-category')}
            isLoading={isLoading}
        />
    );

    return (
        <Page title={t('my-profile.categories')}>
            <TopMenu title={t('my-profile.page-title')} videoLink={videoURL.step3Video} />
            <TopHeading heading={t('step3.sub-heading')} />
            <div
                className='hubPadding'
                style={{ paddingBottom: "32px" }}
            >
                <>
                    <HeadArea>
                        <Typography variant='h6' className='commonText'>{t('step3.description')}</Typography>
                    </HeadArea>
                    <Grid container>
                        {categoriesMasterData?.map((categoryGroup) => (
                            <AccordionStyle
                                key={categoryGroup.id}
                                expanded={openAccordionsStep3.includes(categoryGroup.name)}
                                onChange={onCategoryAccordionClick(categoryGroup.name)}>
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>{categoryGroup.name}</Typography>
                                    <Iconify icon="ant-design:plus-circle-outlined" sx={{ minWidth: 32, height: 32 }} />
                                    <Iconify icon="akar-icons:circle-minus" sx={{ minWidth: 32, height: 32 }} />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ListAddCategory
                                        addCategory={addCategory}
                                        updateCategoryData={updateCategoryData}
                                        categoryGroupId={categoryGroup.id}
                                        categories={categoryGroup.categories}
                                        onClickDeleteCategory={onClickDeleteCategory}
                                    />
                                </AccordionDetails>
                            </AccordionStyle>
                        ))}
                    </Grid>
                    <div className='buttonFlexStyle' style={{ marginTop: '12px' }}>
                        {
                            goBackToMenu(navigate)
                        }
                        <Button
                            variant="contained"
                            onClick={() => saveCategories()}
                            className='loadingButtonStyle2'
                        >
                            {t('personal-info.save')}
                        </Button>
                    </div>
                </>
            </div>
            {showDeleteCategoryDialog && getDeleteAccountDialog()}
        </Page>
    );
}
