export const CALCULATORS = [
    {
        title: 'Loan Payment',
        text: 'Calculates a loan payment',
        path: 'calculators/loan-payment',
        homePath: '/calculators-hub/loan-payment',
        calculatorType: 'Debt',
    },
    {
        title: 'Lease Payment',
        text: 'Calculates a lease payment',
        path: 'calculators/lease-payment',
        homePath: '/calculators-hub/lease-payment',
        calculatorType: 'Debt'
    },
    {
        title: 'Mortgage Payment',
        text: 'Calculates a mortgage payment',
        path: 'calculators/mortgage-payment',
        homePath: '/calculators-hub/mortgage-payment',
        calculatorType: 'Debt'
    },
    {
        title: 'Mortgage Prepayment',
        text: 'Calculates the impact of additional payments on the total interest saved and shortening of term',
        path: '/calculators/mortgage-prepayment',
        homePath: '/calculators-hub/mortgage-prepayment',
        calculatorType: 'Debt'
    },
    {
        title: 'Debt Scheduler',
        text: 'Allows you to list details of all your debts including credit cards and then you can experiment with different monthly payment amounts to see their impact on the amount of interest you will pay and when each debt will be fully paid off',
        path: '/calculators/debt-repayment',
        homePath: '/calculators-hub/debt-repayment',
        calculatorType: 'Debt'
    },
    {
        title: 'Embedded Rate',
        text: 'Calculates the embedded rate in a debt instrument like a lease',
        path: '/calculators/embedded-rate',
        homePath: '/calculators-hub/embedded-rate',
        calculatorType: 'Debt'
    },
    {
        title: 'ROI',
        text: 'Calculates the total return on investment from both dividends/interest and the sales proceeds or current value of your investments',
        path: '/calculators/return-on-investment',
        homePath: '/calculators-hub/return-on-investment',
        calculatorType: 'Investments'
    },
    // {
    //     title: 'Home Equity',
    //     text: 'Calculates the estimated amount of loan you could be eligible for based on the current net equity of your home',
    //     path: '/',
    //     calculatorType: 'Debt'
    // },
    {
        title: 'Future Value',
        text: 'Calculates the combined future value, at an average estimated rate of return,  of your current investments balance and regular periodic additions',
        path: '/calculators/future-value-savings',
        homePath: '/calculators-hub/future-value-savings',
        calculatorType: 'Investments'
    },
    {
        title: 'Present Value',
        text: 'Calculates the present value of an amount to be received in the future',
        path: '/calculators/present-value',
        homePath: '/calculators-hub/present-value',
        calculatorType: 'Investments'
    },
    {
        title: 'Emergency Fund',
        text: 'Calculates the amount of funds that you should always have in cash or short term deposit',
        path: '/calculators/emergency-fund',
        homePath: '/calculators-hub/emergency-fund',
        calculatorType: 'Other'
    },
    {
        title: 'Savings Goal',
        text: 'Calculates the amount you need to invest to reach your goal',
        path: '/calculators/savings-goal',
        homePath: '/calculators-hub/savings-goal',
        calculatorType: 'Other'
    },
    {
        title: 'Retirement',
        text: 'Calculates the amount of funds you will have at retirement and beyond',
        path: '/calculators/retirement',
        homePath: '/calculators-hub/retirement',
        calculatorType: 'Other'
    },
    // {
    //     title: 'Project Budget',
    //     text: 'Calculates a detailed and customized budget for any project',
    //     path: '/calculators/project-budget',
    //     calculatorType: 'Other'
    // },
];

export const EXCEL_STYLE = {
    boldHeader : {
        font: {
            bold: true,
            color: {
                rgb: "889999"
            }
        },
        alignment: {
            horizontal: 'center',
            vertical: 'center'
        }
    },
    boldColor:{
        font: {
            bold: true,
            color: {
                rgb: "889999"
            }
        }
    },
    boldColorRight:{
        font: {
            bold: true,
            color: {
                rgb: "889999"
            }
        },
        alignment: {
            horizontal: 'right'
        }
    },
    boldColorHrVr:{
        font: {
            bold: true,
            color: {
                rgb: "8899ff"
            }
        },
        alignment: {
            horizontal: 'center',
            vertical: 'center'
        }
    },
    boldHrVr:{
        font: {
            bold: true,
        },
        alignment: {
            horizontal: 'center',
            vertical: 'center'
        }
    },
    onlyRight:{
        alignment: {
            horizontal: 'right',
        }
    }
}