import { styled, Accordion } from '@mui/material';

export const HeadArea = styled('div')(({ theme }) => ({
    '& .MuiTypography-h3': {
        width: 620,
        [theme.breakpoints.down('lg')]: {
            width: 580,
        },
        [theme.breakpoints.down('md')]: {
            width: 500,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            lineHeight: '30px',
        },
    },
    '& .MuiTypography-subtitle1': {
        width: '80%',
        paddingBottom: '30px',
        fontSize: 20,
        [theme.breakpoints.down('xl')]: {
            fontSize: 16,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

export const AccordionStyle = styled(Accordion)(({ theme }) => ({
    width: '100%',
    marginTop: 15,
    '&:before': {
        height: 0,
    },
    '& svg': {
        fill: theme.palette.primary.main,
        '& path': {
            fill: theme.palette.primary.main,
        },
        '& g': {
            stroke: theme.palette.primary.main,
        },
    },
    '& .MuiButtonBase-root': {
        padding: 0,
        position: 'sticky',
        backgroundColor: '#f1f1f1',
        zIndex: '3',
        top: '0px',
        // borderBottom: `2px solid ${theme.palette.primary.main}`,
        '& .MuiAccordionSummary-content': {
            margin: 0,
            // background: theme.palette.secondary.lighter,
            padding: '26px 45px 25px',
            borderRadius: 10,
            alignItems: 'center',
            [theme.breakpoints.down('xl')]: {
                padding: 19,
            },
            '& p': {
                color: theme.palette.primary.dark,
                fontSize: 18,
                lineHeight: '27px',
                fontWeight: 700,
                marginRight: 'auto',
            },
            '& svg': {
                display: 'block',
            },
            '& svg + svg': {
                display: 'none',
            },
        },
        '& .Mui-expanded': {
            borderRadius: '10px 10px 0 0',
            '& svg': {
                display: 'none',
            },
            '& svg + svg': {
                display: 'block',
            },
        },
    },
    '& .MuiCollapse-root': {
        background: theme.palette.secondary.lighter,
        borderRadius: '0 0 10px 10px',
        padding: '0 45px 25px',
        [theme.breakpoints.down('xl')]: {
            padding: '0 19px 19px',
        },
        '& .MuiCollapse-wrapper': {
            borderTop: `solid 2px ${theme.palette.primary.main}`,
            '& .MuiAccordionDetails-root': {
                padding: 0,
            },
        },
    },
}));