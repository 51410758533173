/* eslint-disable react/button-has-type */
import { useEffect, useState } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import WindowIcon from '@mui/icons-material/Window';
import ReorderIcon from '@mui/icons-material/Reorder';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './styles.css';

export default function TopHeading({
    heading,
    setSearchValue,
    searchValue,
    setViewStyle,
    viewStyle,
    showSearch,
    headingCenter,
    showGridListToggle = true,
    hideBackLink = false
}) {
    // eslint-disable-next-line no-unused-vars
    const [isLogged, setIsLogged] = useState(false);
    const [gridLength, setGridLength] = useState(12);
    const navigate = useNavigate();

    const handleChange = (event) => {
        event.preventDefault();
        setSearchValue(event.target.value);
    };

    const loadGridLength = () => {
        if (showSearch) {
            setGridLength(8);
        } else {
            setGridLength(12)
        }
    };

    useEffect(() => {
        loadGridLength();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (sessionStorage.getItem('userData')) {
            const user = JSON.parse(sessionStorage.getItem('userData'));
            if (Object.keys(user).length > 0) {
                setIsLogged(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Grid container spacing={2} className="hubPadding" marginLeft={0} width={'100%'}>
                <Grid item lg={gridLength} md={gridLength} xs={12} style={{ paddingLeft: '0px' }}>
                    <Typography variant="h4" className="hubTitleStyle" style={{ textAlign: !headingCenter ? '' : 'center' }}>
                        {heading}
                    </Typography>
                </Grid>
                {showSearch && (
                    <Grid item lg={4} xs={12}>
                        <div className="seachBarStyle">
                            {showGridListToggle && <div className="flexDisplay">
                                <button className="imgButton" onClick={() => setViewStyle('grid')}>
                                    <WindowIcon className={viewStyle === 'grid' && 'activeSvg'} />
                                </button>
                                <button className="imgButton" onClick={() => setViewStyle('list')}>
                                    <ReorderIcon className={viewStyle === 'list' && 'activeSvg'} />
                                </button>
                            </div>}
                            <Paper
                                component="form"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <InputBase
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={handleChange}
                                    inputProps={{ disableUnderline: true }}
                                />
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                            </Paper>
                        </div>
                    </Grid>
                )}
                {
                    !hideBackLink &&
                    <Grid item xs={12} style={{ padding: '0px' }}>
                        <Button className='backToMenuStyle' onClick={() => navigate(-1)}><ArrowBackIcon /> Back</Button>
                    </Grid>
                }
            </Grid>
        </>
    );
}
