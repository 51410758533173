import request from '../../utils/request';

const getNewsroomTopics = () => request.get('/cms/newsroom-topics');
const getNews = (topics, searchText = '', pageSize = 8) => request.get(`/cms/newsrooms?fields[0]=title&populate[headerImage][fields][0]=name&populate[headerImage][fields][1]=url&populate[topic][fields][0]=title&pagination[pageSize]=${pageSize}${createFilterStr(topics)}&filters[$or][0][content][$containsi]=${searchText}&filters[$or][1][title][$containsi]=${searchText}`);
const getNewsDetail = (blogId) => request.get(`/cms/newsrooms?fields[0]=title&fields[1]=content&populate[headerImage][fields][0]=name&populate[headerImage][fields][1]=url&populate[topic][fields][0]=title&filters[id][$eq]=${blogId}`);

const createFilterStr = (filters) => {
    let filterStr = '';
    
    filters.forEach((element) => {
        filterStr += `&filters[topic][title][$eq]=${element}`;  
    });
    return filterStr;
}

export default {
    getNewsroomTopics,
    getNews,
    getNewsDetail
};