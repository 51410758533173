const videoURL = {
    homeHub: 'https://www.loom.com/share/b59b44063a5648e781f9097bbfcb5a08?sid=040ccabc-65de-4586-91f0-0476c42be731',
    otherTransactions: 'https://www.loom.com/share/277834b69ff341039cc1705155c9b3d7?sid=1d917720-5567-4934-bb31-7e8da3653f24',
    transactions: 'https://www.loom.com/share/64ce21fa62db417fb6c6d59752401d9c?sid=703be19d-7fcd-4489-9e67-ddac816134d3',
    executiveSummary: 'https://www.loom.com/share/89402378e5f64deaa10dfc3655580828?sid=be95ed0e-9056-4d39-a883-2502bfb747ca',
    reports: 'https://www.loom.com/share/686ea2a4998743269c304377b211eaaa?sid=6e3d394d-2d32-4de4-a3c7-9611fba20fe2',

    todo: 'https://www.loom.com/share/dbfa12a207ac44da9ce2320126324b09?sid=368c9840-a15b-4279-82d6-456775ed135b',
    keyDates: 'https://www.loom.com/share/1f4296a728c14d34a06d615b1caaad6f?sid=03e4296f-9c04-4dc6-9320-2b8e8cd25fbf',
    mySettings: 'https://www.loom.com/share/2e88ca7cbeb542c184f975ffd54f0a6d?sid=704c9826-58ee-469d-a199-a30dc631ece1',
    faq: 'https://www.loom.com/share/d2b75cbf7951478789607677bed9b594?sid=552627d0-1632-4541-8497-c701fd5557e9',

    calculators: 'https://www.loom.com/share/4d67032956bc460fb35c98f064ef4c9d?sid=c85d377b-c7f9-4adb-81cb-e6c2d8403e4a',
    knowledgeBase: 'https://www.loom.com/share/e150c56dd498435481ab32e0f7f3b1a5?sid=37dac795-14a1-4f9b-b7bf-d910088e652a',
    favWebsiteBlogs: 'https://www.loom.com/share/3c53825cf7b244a2954ad8d5b8a0a935?sid=6018acd8-641a-4fb9-985b-3fe4c299d95d',
    forums: '',

    suggestionBox: 'https://www.loom.com/share/02279fb45f5e4f60a91021a16aa388bb?sid=d03af044-0b21-4e93-b765-8c9943b9da5a',
    contactUs: 'https://www.loom.com/share/981c3d13f19b46a9a9c5711a0d5cb50c?sid=8191b111-944c-4ec5-8f7b-f56fcdc1c565',

    financialGoals: 'https://www.loom.com/share/b91f8aec2a374f008ec6e405a63ac726?sid=92bc74b0-00f2-468a-a17a-c3695e414f44',
    netWorth: 'https://www.loom.com/share/2fe607d6263f46a99f54d02210dadc80?sid=7c398429-8418-4c69-95f3-0ab171ddc2fc',
    budget: 'https://www.loom.com/share/3f01b5b5dddf4058912770fcf8b7aca2?sid=fd4335e9-2562-4239-9c0b-6a8bf99855c4',
    assetComposition: 'https://www.loom.com/share/159146a258944a418d382db16efa9b9e?sid=cbdddd0f-d60d-4126-b716-f0632ef4ef02',
    liabilityComposition: 'https://www.loom.com/share/ff558f3ca7b74593a2a57241f405d451?sid=3b477d7c-5591-4c5a-b57a-5bc60cb865d6',
    statusOfCreditCardUsage: '',
    statusOfLineOfCreditUsage: '',
    debtPayment: '',
    incomeExpenseSummary: 'https://www.loom.com/share/9d60da7a23804b40894ce93ddd10526a?sid=7b65cf53-9bad-4d3a-8105-94338fcf01f6',
    incomeComposition: '',
    expenseComposition: 'https://www.loom.com/share/b5a12355722647a3ae95a3a83f0529ff?sid=7f1173a1-180c-4354-82db-aadbc07a7cec',
    variableExpenses: 'https://www.loom.com/share/45baab5cbfe6435d952f168d68954a48?sid=96dbf3e3-55bd-43a9-b8ad-3a3edd575c7b',
    fixedExpenses: 'https://www.loom.com/share/7b8ee36c5ee84058a83e991c9e0baad9?sid=d858ba1d-56cd-4de2-b692-eeb7847bf0ba',
    top5Expenses: 'https://www.loom.com/share/7583249d10574ac28e8ac204a87b53a5?sid=42b90c6c-3e8f-485c-876d-1c439a963cab',
    top10Expenses: 'https://www.loom.com/share/eb45ac23918c4c5a9a71d43067e92768?sid=7487b400-3d29-454d-adf0-3259bbb97e74',

    onboardingVideo: 'https://www.loom.com/share/41c116d71cdc47988afd717db00c2b6d?sid=52b5a61f-ab17-47c0-9351-d4e6df8e2844',
    step1Video: 'https://www.loom.com/share/f2f15bf7c3bc43e38ab9ef2ae14c50a7?sid=026c7b7b-ab01-4af3-8895-c6c7b4d3a299',
    step2Video: 'https://www.loom.com/share/047d153832f14be39a9ad2b2ab5a44f8?sid=d6ef2cd2-0197-4e8c-a09e-4fa2d94b6be0',
    step3Video: 'https://www.loom.com/share/39feaf7f75df45709055aedaf8385337?sid=3c345fbb-7a85-47e1-8a97-6e121fe75cd3',
    step4Video: 'https://www.loom.com/share/33eb01d7bda1426ba7c4c6a9f3c3a426?sid=457a89ec-991e-47ce-8bc9-4663556b837f',
    step5Video: 'https://www.loom.com/share/2f5a698b490b4b84a8046efdbd35b9d1?sid=de360496-b610-4926-bcc2-1b01e9b47e08'

};

export default videoURL;