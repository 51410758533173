import { format, formatDistanceToNow, add } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, dateFormat = 'MM/dd/yyyy') {
    return format(new Date(date), dateFormat);
}

export function fDateTime(date) {
    return format(new Date(date), 'MM/dd/yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
    return format(new Date(date), 'MM/dd/yyyy hh:mm p');
}

export function fToNow(date) {
    return formatDistanceToNow(new Date(date), {
        addSuffix: true,
    });
}

export function addDuration(date, duration = {}) {
    return add(new Date(date), duration)
}

export function dayFormat(date) {
    if (date) {
        return format(new Date(date), 'eeee, MMMM dd, yyyy');
    }
    return '';
}
