import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import image from 'assets/image';
import { getOurVisionPoints } from './constant'
import { VisionCard } from './VisionCard';

export default function Vision() {
    const { t } = useTranslation();
    const ourVisionPoints = getOurVisionPoints(t);
    return (
        <div style={{overflow:'hidden'}}>
            <div className='ourVisionPadding'>
                <Grid className='ourVisionStyle'>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item md={12}>
                            <Typography variant='body1' className='visionHeading'>{t('about-vision.headline-1')}</Typography>
                            <Typography variant='body1' className='visionSubHeading'>{t('about-vision.subtext-1')}</Typography>
                            <Grid mt={10}>
                                <Typography variant='body1' className='visionHeading'>{t('about-vision.headline-2')}</Typography>
                                <Typography variant='body1' className='visionSubText'>{t('about-vision.subtext-2')}</Typography>
                            </Grid>
                            <Grid mt={10}>
                                <Typography variant='body1' className='visionHeading'>{t('about-vision.headline-3')}</Typography>
                                <Grid container spacing={2}>
                                    {
                                        ourVisionPoints.map((item, indx) => (
                                            <Grid item lg={4} md={12} sm={12} key={indx} className='smBoderBottom'>
                                                <VisionCard title={item.text} id={item.id}/>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <img src={image.visionIcon} className='relativeCurveStyle' alt='curve icon' />
            </div>
        </div>
    );
}
