import Typography from '@mui/material/Typography';
import './styles.css'

export default function PageCenterHeadingStyle(props) {
    return (
        <div className='headingStyle pageSubheading'>
            <Typography variant="h6" style={{textAlign: 'center'}}>{props.headLineOne}</Typography>
            <Typography variant="h3" style={{textAlign: 'center'}}>{props.headLinetwo}</Typography>
            <Typography variant="h5" style={{textAlign: 'center'}}>{props.headLineThree}</Typography>
        </div>
    );
}