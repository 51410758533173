import { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent } from "@mui/material";

import ReactHtmlParser from 'react-html-parser';
import policy from '../../../../constants/services/policy';
import transform from '../../../../constants/strapiHelper';
import { TncMobileStyle } from '../styled-components';

export default function TncModal({ showTncDialog, checkboxStatus, setShowTncDialog }) {
    const [tcInfo, setTcInfo] = useState('');

    const getTCdata = async () => {
        const policyData = await policy.getPolicyContent('T&C');
        if (policyData?.data?.length && policyData?.data?.[0].attributes?.content) {
            setTcInfo(policyData?.data?.[0].attributes);
        }
    };

    const onClose = () => {
        setShowTncDialog(false)
    }

    useEffect(() => {
        getTCdata()
    }, []);

    return (
        <Dialog
            open={showTncDialog}
            maxWidth="lg"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <TncMobileStyle>
                    {ReactHtmlParser(tcInfo?.content, { transform: (node) => transform(node) })}
                </TncMobileStyle>
            </DialogContent>
            <DialogActions style={{ padding: '8px 10px 10px' }}>
                <LoadingButton
                    size="large"
                    onClick={() => onClose()}
                    className='loadingButtonStyle1'
                    variant="contained"
                >
                    Cancel
                </LoadingButton>

                <LoadingButton
                    size="large"
                    className='loadingButtonStyle2'
                    variant="contained"
                    onClick={()=>checkboxStatus()}
                >
                    I Accept
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}