import {
    Link,
    Grid,
    Button,
    Typography,
    TextField,
    MenuItem,
    Input,
    Menu,
    Checkbox,
    Autocomplete,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import videoURL from 'constants/helpVideoUrl';
import Page from '../../components/Page';
import TopMenu from '../../components/TopMenu';
import TopHeading from '../../components/TopHeading';
import Iconify from '../../components/Iconify';
import todos from '../../constants/services/todos';
import { reminderData } from '../../utils/commonConstant';

export default function ToDos() {
    const [tableData, setTableData] = useState([]);
    const [loadCounter, setLoadCounter] = useState(10);
    const [inputValue, setInputValue] = useState({});
    const reminderEleData = { id: 0, label: 'None' };
    const [toggleBtn, setToggleBtn] = useState(false);
    const [reminderInput, setReminderInput] = useState('');
    const [completedData, setCompletedData] = useState([]);
    const [totalCounter, setTotalCounter] = useState();
    const [filterElement, setFilterElment] = useState('targetDate');
    const [anchorEl, setAnchorEl] = useState();
    const [show, setShow] = useState(false);
    const [error, setError] = useState(false);
    const getReminderName = useRef();
    const open = Boolean(anchorEl);
    const { t } = useTranslation();
    const defaultLoadCounter = 10;

    const handleChange = (e) => {
        if (e.name === 'reminder') {
            setInputValue({ ...inputValue, [e.name]: e.value });
            setReminderInput(e.valueId);
        } else {
            setInputValue({ ...inputValue, [e.target.name]: e.target.value });
        }
    };

    const showAdd = async () => {
        setShow(true);
    };

    const handleAdd = async () => {
        try {
            const filterReminderValue = reminderInput.toString();
            const tarDate = inputValue?.targetDate ? moment.parseZone(inputValue?.targetDate).format('YYYY-MM-DD') : '';
            const payload = {
                task: inputValue.task.trim(),
                targetDate: tarDate,
                reminder: filterReminderValue,
            };
            await todos.toDos(payload);
            setInputValue({ task: '', targetDate: '', reminder: '' });
            setReminderInput('');
            setShow(false);
        } catch (error) {
            setShow(true);
            setError(true);
        }
        loadActiveSortedData(filterElement, loadCounter);
        // getSortedTodoData(filterElement, loadCounter)
    };

    const loadmore = () => {
        const localCounter = loadCounter + defaultLoadCounter;
        setLoadCounter(localCounter);
        loadActiveSortedData(filterElement, localCounter);
        // loadUpdatedData(false, localCounter);
    };

    useEffect(() => {
        loadActiveSortedData(filterElement, loadCounter);
        // loadUpdatedData(false, loadCounter)
    }, []);

    const showCompleted = (e) => {
        e.preventDefault();
        setToggleBtn(!toggleBtn);
        loadCompletedSortedData(filterElement);
        // loadUpdatedData(false, loadCounter)
    };

    const handleFormData = (i, targetValue, name) => {
        const formValues = [...tableData];
        formValues[i][name] = targetValue;
        setTableData(formValues);
        toggleComplete(formValues[i]);
    };

    const loadUpdatedData = (flag, counterValue) => {
        getSortedTodoData(filterElement, counterValue);
    };

    const onSubmitValue = (event) => {
        if (event.key === 'Enter') {
            handleAdd();
        }
    };

    const handleMenuBar = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const getSortedTodoData = async (value) => {
        setAnchorEl(null);
        setFilterElment(value);
        loadActiveSortedData(value, loadCounter);
        loadCompletedSortedData(value);
    };

    const loadCompletedSortedData = async (value) => {
        let order = 'ASC';
        if (value !== '' && value === 'task') {
            order = 'ASC';
        }
        const completedPayload = {
            page: 1,
            limit: 99,
            isChecked: true,
            order: [value, order],
        };
        try {
            const completedTodo = await todos.toDosList(completedPayload);
            setCompletedData(completedTodo.data);
        } catch (error) {
            console.log(error);
        }
    };

    const loadActiveSortedData = async (value, counterval) => {
        let order = 'ASC';
        if (value !== '' && value === 'task') {
            order = 'ASC';
        }
        const toDosPayload = {
            page: 1,
            limit: counterval,
            isChecked: false,
            order: [value, order],
        };

        try {
            const todosData = await todos.toDosList(toDosPayload);
            setTotalCounter(todosData.total);
            setTableData(todosData.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleCompleteData = (i, targetValue, name) => {
        const formValues = [...completedData];
        formValues[i][name] = targetValue;
        setCompletedData(formValues);
        toggleComplete(formValues[i]);
    };

    const toggleComplete = async (item) => {
        const targetValue = item.targetDate ? moment.parseZone(item.targetDate).format('yyyy-MM-DD') : null;
        const reminderString = item.reminderValue.toString();
        try {
            const payload = {
                id: item.id,
                isChecked: item.isChecked,
                targetDate: targetValue,
                reminder: reminderString,
            };
            await todos.toDosCheck(payload);
        } catch (error) {
            console.log(error);
            toast.error(t('to-dos.update-error'));
        }
        getSortedTodoData(filterElement, loadCounter);
    };

    const hideCompleted = () => {
        setCompletedData([]);
        setToggleBtn(!toggleBtn);
    };

    useEffect(() => {
        if (inputValue.task?.trim()?.length > 0) {
            setError(false);
        }
    }, [inputValue.task, error]);

    const calenderIcon = () => (
        <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.71359 23.7467H19.5544C20.6088 23.7465 21.6197 23.3275 22.3652 22.5822C23.1106 21.8366 23.5296 20.8258 23.5298 19.7714V7.05042C23.5296 5.99626 23.1106 4.98521 22.3652 4.23985C21.6197 3.49431 20.6088 3.07547 19.5544 3.07511H17.1693V1.75013C17.1693 1.466 17.0177 1.20353 16.7717 1.06156C16.5258 0.919584 16.2226 0.919584 15.9767 1.06156C15.7307 1.20353 15.5791 1.46601 15.5791 1.75013V3.07511H8.6889V1.75013C8.6889 1.466 8.53734 1.20353 8.29137 1.06156C8.0454 0.919584 7.74228 0.919584 7.49631 1.06156C7.25033 1.20353 7.09878 1.46601 7.09878 1.75013V3.07511H4.71359C3.65925 3.07547 2.64838 3.49429 1.90284 4.23985C1.15748 4.98521 0.738463 5.99626 0.738281 7.05042V19.7714C0.738459 20.8258 1.15747 21.8366 1.90284 22.5822C2.64838 23.3275 3.65925 23.7465 4.71359 23.7467ZM15.8763 13.9729L11.636 18.2132V18.2134C11.4871 18.3626 11.285 18.4467 11.0741 18.4467C10.8631 18.4467 10.661 18.3626 10.5123 18.2134L8.39205 16.0931C8.20304 15.8901 8.1333 15.6033 8.20819 15.3362C8.28326 15.0693 8.49196 14.8606 8.75887 14.7856C9.02596 14.7107 9.31273 14.7804 9.51577 14.9694L11.0741 16.5267L14.7525 12.8494V12.8492C14.9556 12.6602 15.2424 12.5904 15.5094 12.6655C15.7764 12.7404 15.9851 12.9491 16.0601 13.2162C16.135 13.4833 16.0653 13.7701 15.8763 13.9729L15.8763 13.9729ZM2.67096 8.37568V7.15664C2.67043 6.54562 2.9125 5.95928 3.34409 5.52676C3.77569 5.0941 4.36136 4.8506 4.97237 4.84992H7.099V5.99049C7.099 6.27462 7.25056 6.53708 7.49653 6.67906C7.74251 6.82103 8.04563 6.82103 8.2916 6.67906C8.53757 6.53708 8.68913 6.27461 8.68913 5.99049V4.84992H15.5798L15.5796 5.99049C15.5796 6.27462 15.7312 6.53708 15.9772 6.67906C16.2231 6.82103 16.5263 6.82103 16.7722 6.67906C17.0182 6.53708 17.1698 6.27461 17.1698 5.99049V4.84992H19.2964C19.9074 4.85063 20.4931 5.09411 20.9247 5.52676C21.3563 5.95925 21.5983 6.54562 21.5978 7.15664V8.37568H2.67096Z"
                    fill="#0F9672"
                />
            </svg>
        </div>
    );

    const onDeleteToDo = async (type, objInfo = {}) => {
        if (type === 'new') {
            setInputValue({});
            setShow(false);
        } else if (type === 'complete') {
            await todos.deleteToDo(objInfo.id);
            loadCompletedSortedData(filterElement);
        } else if (type === 'incomplete') {
            await todos.deleteToDo(objInfo.id);
            loadActiveSortedData(filterElement, loadCounter);
        }
    };

    return (
        <Page title={t('to-dos.title')}>
            <TopMenu title={t('to-dos.title')} videoLink={videoURL.todo} />
            <TopHeading
                heading={t('welcome-constant.description-10')}
                hideBackLink
            />
            <div className="contentStyle" style={{ paddingTop: '0px' }}>
                <Grid container spacing={2} pt={0} justifyContent="center" alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={12}>
                        <div className="headerDivStyle">
                            <div className="tableOverflowStyle">
                                <table className="headerTableStyle">
                                    <thead>
                                        <tr height="10px">
                                            <th style={{ width: '7%' }}>{''}</th>
                                            <th style={{ width: '30%' }}>{t('to-dos.task')}</th>
                                            <th style={{ width: '24%' }}>{t('to-dos.target-date')}</th>
                                            <th style={{ width: '20%' }}>{t('to-dos.reminder')}</th>
                                            <th style={{ width: '10%' }}>
                                                <Button id="basic-button" onClick={handleMenuBar}>
                                                    <FilterAltIcon style={{ fill: '#0F9672' }} />
                                                </Button>
                                                <Menu
                                                    className="menuStyle"
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={() => setAnchorEl(null)}
                                                >
                                                    <MenuItem onClick={() => getSortedTodoData('task', loadCounter)}>
                                                        {' '}
                                                        {filterElement === 'task' ? (
                                                            <b>{t('to-dos.by-task-name')}</b>
                                                        ) : (
                                                            t('to-dos.by-task-name')
                                                        )}
                                                    </MenuItem>
                                                    <hr />
                                                    <MenuItem
                                                        onClick={() => getSortedTodoData('targetDate', loadCounter)}
                                                    >
                                                        {filterElement === 'targetDate' ? (
                                                            <b> {t('to-dos.by-target-date')}</b>
                                                        ) : (
                                                            t('to-dos.by-target-date')
                                                        )}
                                                    </MenuItem>
                                                </Menu>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr height="10px">
                                            <td className="titleBorderStyle" colSpan={5}>
                                                {''}
                                            </td>
                                        </tr>
                                        <tr height="10px">
                                            <td>{''}</td>
                                            <td>
                                                <Link className="linkStyle" onClick={showAdd}>
                                                    {t('to-dos.add-to-do')}
                                                </Link>
                                            </td>
                                            <td>{''}</td>
                                            <td>{''}</td>
                                            <td>{''}</td>
                                        </tr>
                                        {show && (
                                            <tr height="10px">
                                                <td>{''}</td>
                                                <td>
                                                    <Input
                                                        className="inputStyle"
                                                        style={{ width: '240px' }}
                                                        type="text"
                                                        name="task"
                                                        autoComplete="off"
                                                        value={inputValue.task}
                                                        placeholder={t('to-dos.description-input')}
                                                        onChange={handleChange}
                                                        onKeyPress={(event) => {
                                                            onSubmitValue(event);
                                                        }}
                                                        error={error}
                                                    />
                                                    {error && <p className="Mui-error">{t('to-dos.task-error')}</p>}
                                                </td>

                                                <td>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            className="datePickerStyle"
                                                            inputFormat="MMM dd, yyyy"
                                                            value={inputValue.targetDate || ''}
                                                            components={{
                                                                OpenPickerIcon: calenderIcon,
                                                            }}
                                                            disablePast
                                                            error={false}
                                                            helperText={null}
                                                            onChange={(newValue) => {
                                                                handleChange({
                                                                    target: { name: 'targetDate', value: newValue },
                                                                });
                                                            }}
                                                            onAccept={(newValue) => {
                                                                handleChange({
                                                                    target: { name: 'targetDate', value: newValue },
                                                                });
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    onKeyPress={(event) => {
                                                                        onSubmitValue(event);
                                                                    }}
                                                                    error={false}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </td>
                                                <td>
                                                    <Autocomplete
                                                        className="autoCompleteStyle"
                                                        id="tags-outlined"
                                                        name="reminder"
                                                        ref={getReminderName}
                                                        options={reminderData}
                                                        value={inputValue.reminder || ''}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        disableClearable
                                                        disablePortal
                                                        onChange={(e, val) => {
                                                            const reminderObj = {
                                                                name: getReminderName.current.getAttribute('name'),
                                                                value: val?.label,
                                                                valueId: val?.id,
                                                            };
                                                            handleChange(reminderObj);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="standard"
                                                                className="dropMenu"
                                                            />
                                                        )}
                                                        onKeyPress={(event) => {
                                                            onSubmitValue(event);
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <Iconify
                                                        icon={'material-symbols:save'}
                                                        width={24}
                                                        height={24}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => handleAdd()}
                                                    />
                                                    <Iconify
                                                        icon={'ri:delete-bin-6-fill'}
                                                        width={24}
                                                        style={{ marginLeft: 10, cursor: 'pointer' }}
                                                        height={24}
                                                        onClick={() => onDeleteToDo('new')}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                        {tableData.map((item, index) => (
                                            <tr key={index} height="10px">
                                                <td style={{ textAlign: 'center' }}>
                                                    <Checkbox
                                                        className="checkboxStyle"
                                                        value={item.isChecked}
                                                        name="isChecked"
                                                        onChange={(e) => {
                                                            handleFormData(index, e.target.checked, 'isChecked');
                                                        }}
                                                        checked={item.isChecked}
                                                    />
                                                </td>
                                                <td>
                                                    <Typography className="taskStyle">{item.task}</Typography>
                                                </td>
                                                <td>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            className="datePickerStyle"
                                                            inputFormat="MMM dd, yyyy"
                                                            value={
                                                                item.targetDate
                                                                    ? moment
                                                                        .parseZone(item.targetDate)
                                                                        .format('MM/DD/YYYY')
                                                                    : null
                                                            }
                                                            disablePast
                                                            error={false}
                                                            helperText={null}
                                                            components={{
                                                                OpenPickerIcon: calenderIcon,
                                                            }}
                                                            onChange={(newValue) => {
                                                                handleFormData(index, newValue, 'targetDate');
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} error={false} />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </td>
                                                <td>
                                                    <Autocomplete
                                                        className="autoCompleteStyle"
                                                        id="tags-outlined"
                                                        name="reminder"
                                                        options={reminderData}
                                                        value={
                                                            reminderData.find(
                                                                (x) => x.id === Number(item?.reminderValue)
                                                            )?.label || reminderEleData
                                                        }
                                                        isOptionEqualToValue={(option, value) =>
                                                            option.label === value.label
                                                        }
                                                        onChange={(e, val) => {
                                                            handleFormData(index, val?.id, 'reminderValue');
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="standard"
                                                                className="dropMenu"
                                                            />
                                                        )}
                                                    />
                                                </td>
                                                <td>
                                                    <Iconify
                                                        icon={'ri:delete-bin-6-fill'}
                                                        style={{ marginLeft: 32, cursor: 'pointer' }}
                                                        width={22}
                                                        height={22}
                                                        onClick={() => onDeleteToDo('incomplete', item)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                        <tr height="10px">
                                            <td
                                                colSpan={5}
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {totalCounter > loadCounter && (
                                                    <Link
                                                        style={{
                                                            width: '200px',
                                                            padding: '9px 16px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={loadmore}
                                                    >
                                                        {t('to-dos.load-more')}
                                                    </Link>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                colSpan={5}
                                                style={{
                                                    textAlign: 'center',
                                                    padding: '10px 0px 20px',
                                                }}
                                            >
                                                {toggleBtn ? (
                                                    <Button
                                                        className="loadingButtonStyle2"
                                                        variant="contained"
                                                        style={{ width: '200px', padding: '9px 16px' }}
                                                        onClick={hideCompleted}
                                                    >
                                                        {t('to-dos.hide-completed')}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        className="loadingButtonStyle2"
                                                        variant="contained"
                                                        style={{ width: '200px', padding: '9px 16px' }}
                                                        onClick={showCompleted}
                                                    >
                                                        {t('to-dos.show-completed')}
                                                    </Button>
                                                )}
                                            </td>
                                        </tr>
                                        {toggleBtn &&
                                            completedData.map((items, index) => (
                                                <tr key={index} height="10px">
                                                    <td style={{ textAlign: 'center' }}>
                                                        <Checkbox
                                                            className="checkboxStyle"
                                                            value={items.isChecked}
                                                            name="isChecked"
                                                            onChange={(e) => {
                                                                handleCompleteData(
                                                                    index,
                                                                    e.target.checked,
                                                                    'isChecked'
                                                                );
                                                            }}
                                                            checked={!!items.isChecked}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Typography className="taskStyle">{items.task}</Typography>
                                                    </td>
                                                    <td>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                className="datePickerStyle"
                                                                inputFormat="MMM dd, yyyy"
                                                                value={
                                                                    items.targetDate
                                                                        ? moment
                                                                            .parseZone(items.targetDate)
                                                                            .format('MM/DD/YYYY')
                                                                        : null
                                                                }
                                                                disablePast
                                                                error={false}
                                                                helperText={null}
                                                                components={{
                                                                    OpenPickerIcon: calenderIcon,
                                                                }}
                                                                onChange={(newValue) => {
                                                                    handleCompleteData(index, newValue, 'targetDate');
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} error={false} />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </td>
                                                    <td>
                                                        <Autocomplete
                                                            className="autoCompleteStyle"
                                                            id="tags-outlined"
                                                            name="reminder"
                                                            options={reminderData}
                                                            value={
                                                                reminderData.find(
                                                                    (x) => x.id === Number(items?.reminderValue)
                                                                )?.label || reminderEleData
                                                            }
                                                            isOptionEqualToValue={(option, value) =>
                                                                option.id === value.id
                                                            }
                                                            onChange={(e, val) => {
                                                                handleCompleteData(index, val?.id, 'reminderValue');
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="standard"
                                                                    className="dropMenu"
                                                                />
                                                            )}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Iconify
                                                            icon={'ri:delete-bin-6-fill'}
                                                            width={22}
                                                            height={22}
                                                            style={{ marginLeft: 32, cursor: 'pointer' }}
                                                            onClick={() => onDeleteToDo('complete', items)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Page>
    );
}
