import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { TextField, Grid, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import { FormProvider } from '../../../components/hook-form';
import calenderIcon from '../../downloadEdits/CalenderIcon';
import { AutoFieldStyle, LabelStyle, FormStyling, DateGrid } from './styled-components';

export default function Filter({ filtersData, updateFilters, categories, showCategoryFilter, setFiltersData, assetFilter, setAssetFilter }) {
    const methods = useForm({});

    const [categoriesName, setCategoriesName] = useState([]);
    const [subCategoriesData, setSubCategoriesData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [selectedCompare, setSelectedCompare] = useState('');
    const compareOption = ['Prior Month', 'Prior Year December', 'None'];

    const { t } = useTranslation();

    const updateFilterValue = (key, value) => {
        if (key === 'category') {
            if (value !== null) {
                updateFilters('categoryId', value.id);
                setSelectedCategory(value.label);
                setAssetFilter({...assetFilter, catName: value?.label})
                getSubcategory(value.id);
            } else {
                updateFilters('categoryId', value);
                setSelectedCategory(value);
                setAssetFilter({...assetFilter, catName: ''})
                getSubcategory();
            }
        } else if (key === 'subCatId') {
            if (value && value.id !== 'all') {
                setSelectedSubCategory(value.label)
                setAssetFilter({...assetFilter, subCatName: value?.label})
                updateFilters(key, value.id);
            } else {
                setSelectedSubCategory(value)
                setAssetFilter({...assetFilter, subCatName: value?.label})
                updateFilters(key, null);
            }
        } else if (key === 'fromMonth') {
            const selectMonthformat = moment.parseZone(value).format('YYYY-MM');
            updateFilters(key, selectMonthformat);
            setSelectedCompare('')
        }
        else if (key === 'compareMonth') {
            let finalyearmonth = null;
            const month = moment.parseZone(filtersData.fromMonth).format('MM');
            if ((value === 'Prior Month')) {
                if (month === '01') {
                    finalyearmonth = moment.parseZone(filtersData.fromMonth).subtract(1, 'months', 'years').format('YYYY-MM');;
                } else {
                    finalyearmonth = moment.parseZone(filtersData.fromMonth).subtract(1, 'months').format('YYYY-MM');
                }
            } else if (value === 'Prior Year December') {
                finalyearmonth = (moment.parseZone(filtersData.fromMonth).subtract(1, 'years').format('YYYY')).concat("-12");
            }
            setSelectedCompare(value);
            setAssetFilter({...assetFilter, compare: value})
            updateFilters(key, finalyearmonth);
        }
    };

    const getSubcategory = async (categoryId) => {
        const category = categories.find((cat) => cat.id === categoryId);
        let subCategoryOptions = [];
        if (category) {
            const subcategoriesList = category.sub_categories || [];
            subCategoryOptions = subcategoriesList.map(
                (obj) => ({ id: obj.id, label: obj.name })
            ).sort((a, b) => a.label.localeCompare(b.label));
            if (subCategoryOptions.length > 1) {
                subCategoryOptions.unshift({ id: 'all', label: 'All' })
            }
        }
        setSubCategoriesData(subCategoryOptions);
        setSelectedSubCategory('');
    }

    const populateCategoryData = () => {
        const categoriesNames = categories.map((obj) => ({ id: obj.id, label: obj.name }));
        setCategoriesName(categoriesNames);
    };

    useEffect(() => {
        populateCategoryData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories]);

    return (
        <Box>
            <FormStyling>
                <FormProvider methods={methods}>
                    <Grid container spacing={2}>
                        <Grid item md={3} xs={12}>
                            <DateGrid>
                                <LabelStyle shrink htmlFor="bootstrap-input">
                                    {t('liabilities-composition.as-at')}
                                </LabelStyle>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        PaperProps={{ className: 'customDateSelector' }}
                                        value={moment.parseZone(filtersData.fromMonth).format('MM/DD/YYYY')}
                                        views={["year", "month"]}
                                        components={{
                                            OpenPickerIcon: calenderIcon,
                                        }}
                                        maxDate={moment.parseZone().format('YYYY-MM')}
                                        minDate={moment.parseZone().subtract(1, 'years').format('YYYY-MM')}
                                        onChange={(date) => updateFilterValue('fromMonth', date)}
                                        renderInput={(params) => <TextField {...params}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: "To"
                                            }} />}
                                    />
                                </LocalizationProvider>
                            </DateGrid>
                        </Grid>
                        
                        {showCategoryFilter && <Grid item md={3} xs={12}>
                            <LabelStyle shrink htmlFor="bootstrap-input">
                                {t('liabilities-composition.Category')}
                            </LabelStyle>
                            <AutoFieldStyle
                                options={categoriesName}
                                value={selectedCategory}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                id="category"
                                onChange={(e, val) => {
                                    updateFilterValue('category', val);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" placeholder="Select" className="dropMenu" />
                                )}
                            />
                        </Grid>}

                        <Grid item md={3} xs={12}>
                            <LabelStyle shrink htmlFor="bootstrap-input">
                                {t('liabilities-composition.Sub-Category')}
                            </LabelStyle>
                            <AutoFieldStyle
                                disabled={!selectedCategory}
                                options={subCategoriesData}
                                value={selectedSubCategory}
                                id="sub-category"
                                onChange={(e, val) => {
                                    updateFilterValue('subCatId', val);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" placeholder="Select" className="dropMenu" />
                                )}
                            />
                        </Grid>

                        <Grid item md={3} xs={12}>
                            <LabelStyle shrink htmlFor="bootstrap-input">
                                {t('liabilities-composition.Compare')}
                            </LabelStyle>
                            <AutoFieldStyle
                                options={compareOption}
                                value={selectedCompare}
                                id="compare"
                                onChange={(e, val) => {
                                    updateFilterValue('compareMonth', val);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" placeholder="Select" className="dropMenu" />
                                )}
                            />
                        </Grid>

                    </Grid>
                </FormProvider>
            </FormStyling>
        </Box>
    );
}