import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';

export default function InfoProvider(props) {
    const { t } = useTranslation();

    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    const LinkText = ({ id, children }) => <a onClick={() => props.onClickHandler(id)} className='more-details-link'>{children}</a>;

    return (
        <div className="flexDisplay">
            <div>
                <div className="pointerStyle">
                    <span>{props.pointerLineOne}</span>
                </div>
            </div>
            <div>
                <Typography className="headingPointerStyle" variant="body1">
                    {t(props.pointerLineTwo)}
                </Typography>
                <Typography className="subHeadingPointerStyle" variant="body2">
                    {props.textActions ? (
                        <Trans i18nKey={props.pointerLineThree} t={t} components={[<LinkText key={0} id={props.pointerLineOne} />]} />
                    ) : (
                        t(props.pointerLineThree)
                    )}
                </Typography>
            </div>
        </div>
    );
}
