const calculatorsList = [
    {
        id: 1,
        title: 'Debt',
        submenu: [
            {
                title: 'Mortgage payment',
                linkUrl: '/calculators-hub/mortgage-payment'
            },
            {
                title: 'Mortgage Prepayment',
                linkUrl: '/calculators-hub/mortgage-prepayment'
            },
            {
                title: 'Loan Payment',
                linkUrl: '/calculators-hub/loan-payment'
            },
            {
                title: 'Lease Payment',
                linkUrl: '/calculators-hub/lease-payment'
            },
            {
                title: 'Debt Repayment',
                linkUrl: '/calculators-hub/debt-repayment'
            }
        ]
    },
    {
        id: 2,
        title: 'Investments',
        submenu: [
            {
                title: 'Embedded Rates',
                linkUrl: '/calculators-hub/embedded-rate'
            },
            {
                title: 'ROI',
                linkUrl: '/calculators-hub/return-on-investment'
            },
            {
                title: 'Future Value',
                linkUrl: '/calculators-hub/future-value-savings'
            },
            {
                title: 'Present Value',
                linkUrl: '/calculators-hub/present-value'
            }
        ]
    },
    {
        id: 3,
        title: 'Savings',
        submenu: [
            {
                title: 'Emergency Fund',
                linkUrl: '/calculators-hub/emergency-fund'
            },
            {
                title: 'Savings Goal',
                linkUrl: '/calculators-hub/savings-goal'
            },
            {
                title: 'Retirement',
                linkUrl: '/calculators-hub/retirement'
            }
        ]
    }
]

export const navLinks = [
    {
        id: 1,
        url: '/how-it-works',
        title: 'How it works',
        menuitems: [],
    },
    {
        id: 2,
        url: '/what-you-get',
        title: 'What you get',
        menuitems: [],
    },
    {
        id: 3,
        url: '/security',
        title: 'Security',
        menuitems: [],
    },
    {
        id: 4,
        url: '/calculators-hub',
        title: 'Calculators',
        menuitems: calculatorsList,
    },
    {
        id: 5,
        url: '/learn',
        title: 'Learn',
        menuitems: [],
    },
    {
        id: 6,
        url: '/about-us',
        title: 'About',
        menuitems: [],
    }
]