import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { FormHelperText, Grid, IconButton, InputAdornment, Link, Typography } from '@mui/material';
import { AuthContext } from 'contexts/AuthProvider';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFCheckbox, RHFTextField } from '../../../components/hook-form';
import user from '../../../constants/services/user';
import { showError } from '../../../utils/toast';
import { ButtonText, CheckStyle, HelperTextStyle } from './styled-components';
import TncModal from './tncEditModal/TncModal';

export default function RegisterForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locationState = window.location?.href;
    const { setAuth } = useContext(AuthContext);

    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [showTncDialog, setShowTncDialog] = useState(false);

    const RegisterSchema = Yup.object().shape({
        firstName: Yup.string().required(t('error-message.first-name-required')),
        lastName: Yup.string().required(t('error-message.last-name-required')),
        email: Yup.string().email(t('error-message.invalid-email')).required(t('error-message.email-required')),
        password: Yup.string()
            .required(t('error-message.password-required'))
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                t('error-message.password-validation')
            ),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], t('error-message.password-must-match')),
        tnc: Yup.boolean()
            .required(t('error-message.term-and-conditions'))
            .oneOf([true], t('error-message.password-must-match')),
    });

    const defaultValues = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        tnc: false,
        mode: 'onChange',
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        setValue,
        clearErrors,
        handleSubmit,
        formState: { isSubmitting, errors },
    } = methods;

    const onSubmit = async (data, event) => {
        event.preventDefault();
        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, { action: 'submit' })
                .then(async (token) => {
                    await registerUser(token, data);
                });
        });
    };

    const registerUser = async (token, data) => {
        const { firstName, lastName, email, password } = data;
        const userInfo = {
            firstName,
            lastName,
            email,
            password,
            token,
        };
        try {
            const result = await user.registerUserV2(userInfo);
            const { authToken } = result?.data;
            if (authToken) {
                navigate('/verify-code', { replace: true, state: { authToken } });
            }
        } catch (error) {
            showError(t, error);
        }
    };

    const checkboxStatus = () => {
        setValue('tnc', true);
        clearErrors('tnc');
        setShowTncDialog(false);
    };

    useEffect(() => {
        if (sessionStorage.getItem('userData')) {
            const user = JSON.parse(sessionStorage.getItem('userData'));
            if (Object.keys(user).length > 0) {
                navigate('/welcome', { replace: true });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = url;
                script.id = id;
                script.onload = () => {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        };

        // load the script by passing the URL
        loadScriptByURL(
            'recaptcha-key',
            `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`,
            () => {}
        );

        return () => {
            const script = document.getElementById('recaptcha-key');
            if (script) {
                script.remove();
            }
            const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
            if (recaptchaElems.length) {
                recaptchaElems[0].remove();
            }
        };
    }, []);

    return (
        <Grid style={{ position: 'relative' }}>
            <FormProvider methods={methods}>
                <Grid container direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Grid item xs={6}>
                        <RHFTextField name="firstName" label="First Name" />
                    </Grid>
                    <Grid item xs={6}>
                        <RHFTextField name="lastName" label="Last Name" />
                    </Grid>
                </Grid>
                <RHFTextField name="email" label="Email Address" />

                <Grid container direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Grid item xs={6}>
                        <div style={{ position: 'relative' }}>
                            <RHFTextField
                                name="password"
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6} className="orderDiv">
                        <div style={{ position: 'relative' }}>
                            <RHFTextField
                                name="confirmPassword"
                                label="Confirm Password"
                                type={showCPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowCPassword(!showCPassword)} edge="end">
                                                <Iconify icon={showCPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <HelperTextStyle variant="body1">{t('error-message.password-validation')}</HelperTextStyle>
                    </Grid>
                </Grid>

                <CheckStyle direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ mb: 3 }}>
                    <Typography variant="body2">
                        <RHFCheckbox name="tnc" label={t('register-page.i-agree-with')} />
                        <Link underline="always" color="text.primary" onClick={() => setShowTncDialog(!showTncDialog)}>
                            {t('register-page.terms-conditions')}
                        </Link>
                    </Typography>
                    {errors.tnc && (
                        <FormHelperText
                            error
                            style={{
                                marginTop: -10,
                            }}
                        >
                            {t('error-message.term-and-conditions')}
                        </FormHelperText>
                    )}
                </CheckStyle>

                <ButtonText>
                    <LoadingButton
                        fullWidth
                        size="large"
                        onClick={handleSubmit(onSubmit)}
                        className="loadingButtonStyle2"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        {t('register-page.start-my-trial')}
                    </LoadingButton>

                    {/* <DividerStyle sx={{ my: 6 }}>
                        <span>or</span>
                    </DividerStyle>

                    <LoadingButton
                        fullWidth
                        size="large"
                        type="button"
                        variant="outlined"
                        className='loadingButtonStyle1'
                        loading={isSubmitting}
                        onClick={handleSubmit(payAnnual)}
                    >
                        {t('register-page.annual-subscription')}
                    </LoadingButton> */}
                </ButtonText>
                <TncModal
                    showTncDialog={showTncDialog}
                    setShowTncDialog={setShowTncDialog}
                    checkboxStatus={checkboxStatus}
                    onClose={() => {
                        setShowTncDialog(false);
                    }}
                />
            </FormProvider>
        </Grid>
    );
}
