import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ONBOARDING_STEPS } from "./constant";

export default function StepperActions({ activeStep = 0, handleBack = () => {}, handleNext = () => {}, additionalStyles = {}}) {
    const { t } = useTranslation();

    return <div style={additionalStyles} className="stepperBtnStyle buttonFlexStyle">

        <Button 
            className='backToMenuStyle' 
            disabled={activeStep === 0 || activeStep === 2} 
            onClick={() => handleBack()} 
            style={{fontSize: '14px', marginTop: 0, marginBottom: 15, justifyContent: 'start', ...additionalStyles}}>
            <ArrowBackIcon /> Back
        </Button>
        <Button
            variant="contained"
            className="loadingButtonStyle2"
            style={{float:'right', marginBottom:15, ...additionalStyles}}
            onClick={() => handleNext()}>
            {activeStep === ONBOARDING_STEPS.length - 1 ? t('onboarding.all-done') : t('onboarding.next')}
        </Button>
    </div>
}