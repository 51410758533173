import * as Yup from 'yup';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import auth from '../../../constants/services/auth';
import { showError, showToast } from '../../../utils/toast';

const ButtonText = styled('div')(({ theme }) => ({
    '& button': {
        textTransform: 'inherit',
        fontSize: 16,
        padding: '6px 16px !important',
        [theme.breakpoints.down('md')]: {
            fontSize: '14px !important',
        },
    },
}));

export default function ChangePasswordForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [currentPassword, setCurrentPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);

    const FormSchema = Yup.object().shape({
        currentPassword: Yup.string()
            .required(t('error-message.password-required'))
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                t('error-message.password-validation')
            ),
        password: Yup.string()
            .required(t('error-message.password-required'))
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                t('error-message.password-validation')
            ),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], t('error-message.password-must-match')),
    });

    const defaultValues = {
        currentPassword: '',
        password: '',
        confirmPassword: '',
    };

    const methods = useForm({
        resolver: yupResolver(FormSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onChangeUserPassword = async (data) => {
        try {
            await auth.changePassword(data);
            showToast('Password changed successfully', { type: 'success' });
            navigate('/welcome', { replace: false });
        } catch (error) {
            showError(t, error);
        }
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onChangeUserPassword)}>
            <div style={{ position: 'relative' }}>
                <RHFTextField
                    name="currentPassword"
                    label={'Current Password'}
                    type={currentPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setCurrentPassword(!currentPassword)} edge="end">
                                    <Iconify icon={currentPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div style={{ position: 'relative' }}>
                <RHFTextField
                    name="password"
                    label={t('forgot-password.new-password')}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div style={{ position: 'relative' }}>
                <RHFTextField
                    name="confirmPassword"
                    label={t('forgot-password.confirm-new-password')}
                    type={showCPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowCPassword(!showCPassword)} edge="end">
                                    <Iconify icon={showCPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <ButtonText>
                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    className='loadingButtonStyle2'
                    variant="contained"
                    loading={isSubmitting}
                >
                    {t('forgot-password.change-password')}
                </LoadingButton>
            </ButtonText>
        </FormProvider>
    );
}
