import Iconify from "components/Iconify";
import { AuthContext } from "contexts/AuthProvider";
import { useContext } from "react";
import { Outlet } from "react-router-dom";
import SmallLogo from '../../components/SmallLogo';
import { Logout, MainStyle, RootStyle, SectionStyle, SmallLogoStyle } from "./styled-component";

export default function LayoutNoMenu() {
    const { logout } = useContext(AuthContext);

    return (
        <RootStyle>
            <SectionStyle>
                <SmallLogoStyle>
                    <SmallLogo sx={{ width: 40, height: 'auto' }} />
                </SmallLogoStyle>
                <Logout onClick={() => logout()}>
                    <Iconify icon="ri:logout-circle-r-line" sx={{ minWidth: 22, height: 22 }} />
                </Logout>
            </SectionStyle>
            <MainStyle>
                <Outlet />
            </MainStyle>
        </RootStyle>
    )
}