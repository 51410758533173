import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import image from 'assets/image';
import PageSubHeadingStyle from '../../../components/PageSubHeadingStyle';

export default function ComparationText() {
    const { t } = useTranslation();

    return (
        <Grid className='sectionCommonPadding'>
            <Grid container spacing={2} py={6} className='compartionTxtStyle' justifyContent={'center'}>
                <Grid item md={8}>
                    <PageSubHeadingStyle
                        headLineOne={''}
                        headLineTwo={t('mm-comparation-text.headline-1')}
                        headLineThree={t('mm-comparation-text.headline-2')}
                    />
                    <Typography varient='body1'>{t('mm-comparation-text.headline-3')}</Typography>
                </Grid>
                <Grid item md={4}>
                    <img src={image.curveLearn} alt="Header logo" className='smTopMargin'/>
                </Grid>
            </Grid>
        </Grid>
    );
}
