// @mui
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/system';
import { Box} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

// components
import knowledgebase from '../../constants/services/knowledgebase';
import transform from '../../constants/strapiHelper';
import TopHeading from '../../components/TopHeading';
import TopMenu from '../../components/TopMenu';
import Page from '../../components/Page';
import { BackButtonStyle } from '../calculators/styled-components';

export default function KnowledgeBaseDetailPage() {
    const BLANK_BLOG = {
        title: '',
        content: '',
    };
    const [blog, setBlog] = useState(BLANK_BLOG);
    const [isLogged, setIsLogged] = useState(false)
    const { blogId } = useParams();
    const navigate = useNavigate();

    const getBlog = async (blogId) => {
        if (blogId === '') {
            setBlog(BLANK_BLOG);
            return;
        }
        const blogsData = await knowledgebase.getBlogDetail(blogId);
        let blogsDetail = BLANK_BLOG;
        if (blogsData.data && blogsData.data.length > 0) {
            blogsDetail = {
                title: blogsData.data[0].attributes.title,
                topic: blogsData.data[0].attributes.topic.data.attributes.title,
                headerImage: blogsData.data[0].attributes.headerImage,
                content: blogsData.data[0].attributes.content,
            };
        }
        setBlog(blogsDetail);
    };

    useEffect(() => {
        getBlog(blogId);
    }, [blogId]);

    useEffect(() => {
        if (sessionStorage.getItem('userData')) {
            const user = JSON.parse(sessionStorage.getItem('userData'));
            if (Object.keys(user).length > 0) {
                setIsLogged(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { t } = useTranslation();

    return (
        <Page title={t('knowledgebase.details')} style={isLogged ? {} : {padding: '110px 8% 0px'}}>
            {isLogged && <TopMenu title={`Knowledge Base`} />}
            <TopHeading heading={blog.title} headingCenter={!isLogged} />
            <div className='contentStyle' style={{ paddingTop: '0' }}>
                <Container style={{padding: '0px'}}>
                    <Box className="knowledgeBaseStyle customSliderStyle">
                        {ReactHtmlParser(blog.content, { transform: (node, index) => transform(node) })}
                    </Box>
                </Container>
            </div>
        </Page>
    );
}
