import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

export default function SuggestionModel({ showSuggestionDialog, setShowSuggestionDialog, dialogTitle, suggestionMsg }) {
    const { t } = useTranslation();

    const onSave = () => {
        setShowSuggestionDialog(false)
    }

    return (
        <Dialog
            open={showSuggestionDialog}
            maxWidth="sm"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h4">{dialogTitle}</Typography>
            </DialogTitle>
            <DialogContent>
                {suggestionMsg}
            </DialogContent>
            <DialogActions style={{ padding: '8px 10px 10px' }}>
                <Button
                    variant="outlined"
                    onClick={() => onSave()}
                >
                    {t('suggestion-box.close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}