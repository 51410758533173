import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import auth from 'constants/services/auth';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'utils/common';
import Page from '../../components/Page';
import SmallLogo from '../../components/SmallLogo';
import TopMenu from '../../components/TopMenu';
import { FormDivStyle, OnboardingDivStyle, SectionStyle, SmallLogoStyle } from '../register/styled-components';

export default function EmailVerification() {
    const { t } = useTranslation();
    const query = useQuery();
    const [verificationMessage, setVerificationMessage] = useState('');

    const verifyEmail = async (email, token) => {
        try {
            const payload = {
                email,
                token,
            };
            const response = await auth.verifyEmail(payload);
            setVerificationMessage(response.message);
        } catch (error) {
            setVerificationMessage('INVALID_VERIFICATION_LINK');
        }
    };

    useEffect(() => {
        const email = query.get('email');
        const token = query.get('token');
        if (email && token) {
            verifyEmail(email, token);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getContent = () => {
        let message = t('email-verification.in-progress');
        switch (verificationMessage) {
            case 'EMAIL_ALREADY_VERIFIED':
                message = t('email-verification.error');
                break;
            case 'EMAIL_VERIFICATION_SUCCESS':
                message = t('email-verification.success');
                break; 
            case 'INVALID_VERIFICATION_LINK':
                message = t('email-verification.invalid-link');
                break; 
            default: message = t('email-verification.in-progress');
                break; 
        }
        return <Typography variant="h5">{message}</Typography>;
    };

    return (
        <Page title={t('email-verification.title')}>
            <div>
                <SectionStyle>
                    <SmallLogoStyle>
                        <SmallLogo sx={{ width: 40, height: 'auto', display: 'flex', justifyContent: 'center' }} />
                    </SmallLogoStyle>
                </SectionStyle>
                <Grid container justifyContent={'center'} spacing={2} marginLeft={0} width={'100%'} marginTop={0}>
                    <Grid item xs={12} className="customPaddingStyle">
                        <TopMenu title={t('email-verification.verify-your-email')} hideHelp />
                    </Grid>
                    <Grid item xl={5} lg={5} md={8} xs={12} className="extraPaddingStyle">
                        <OnboardingDivStyle>
                            <FormDivStyle>
                                {getContent()}
                                <br />
                                <LoadingButton
                                    className="loadingButtonStyle2"
                                    type="submit"
                                    variant="outlined"
                                    href="/login"
                                >
                                    {t('email-verification.login')}
                                </LoadingButton>
                            </FormDivStyle>
                        </OnboardingDivStyle>
                    </Grid>
                </Grid>
            </div>
        </Page>
    );
}
