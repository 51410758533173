import { useContext } from 'react';
import { Grid, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import videoURL from 'constants/helpVideoUrl';
import TopMenu from '../../../../../components/TopMenu';
import ListAddSubCategory from '../ListAddSubCategory';
import Iconify from '../../../../../components/Iconify';
import { HeadArea } from './styled-components';
import { AccordionStyle } from '../../styled-components'
import { Wrapper } from '../../../setup/styled-components';
import { FormContext } from '../../context/StepperContext';

export default function Step4({ skipStep }) {
    const {
        categoriesMasterData: categoryGroups,
        openAccordionsStep4,
        onSubCategoryAccordionClick,
    } = useContext(FormContext);
    const { t } = useTranslation();

    return (
        <>
            <div className='mdCustomPadding'>
                <TopMenu title={t('step4.heading')} hideHelp videoLink={videoURL.step4Video} />
            </div>
            <Wrapper>
                <HeadArea style={{ marginBottom: '26px' }}>
                    <Typography variant="h4" className='titleStyle margintitleStyle'>{t('step4.sub-heading')}</Typography>
                    <Typography>
                        You may skip this step for now and complete it later by clicking <a href="#" onClick={skipStep}>here</a>.
                        <br />
                        You can update this information later by going to the Home Hub and select My Settings and then click on the appropriate box.
                        <br />
                        <br />
                    </Typography>
                    <Typography variant="h6" className='commonText'>{<Trans i18nKey="step4.description">
                        Listed below are the categories you selected in step three.<br />Now add or rename individual sub-categories
                    </Trans>}
                    </Typography>
                </HeadArea>
                <Grid container>
                    {categoryGroups.map((categoryGroup) => (
                        <AccordionStyle
                            key={categoryGroup.id}
                            expanded={openAccordionsStep4.includes(categoryGroup.name)}
                            onChange={onSubCategoryAccordionClick(categoryGroup.name)}>
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className='commonHeading'>{categoryGroup.name}</Typography>
                                <Iconify icon="ant-design:plus-circle-outlined" sx={{ minWidth: 22, height: 22 }} />
                                <Iconify icon="akar-icons:circle-minus" sx={{ minWidth: 22, height: 22 }} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <ListAddSubCategory
                                    categoryGroupId={categoryGroup.id}
                                    categories={categoryGroup.categories}
                                    showUpdateManually={['Assets', 'Liabilities'].includes(categoryGroup.name)}
                                    showDiscretionary={categoryGroup.name === 'Expenses/Cash Outflows'}
                                    showIsIncludeInDebtPaymentReport={categoryGroup.name === 'Expenses/Cash Outflows'}
                                />
                            </AccordionDetails>
                        </AccordionStyle>
                    ))}
                </Grid>
            </Wrapper>
        </>
    );
}