import { Grid, Typography, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import image from 'assets/image';
import PageSubHeadingStyle from '../../../components/PageSubHeadingStyle';
import { getCalculatorDetails } from './constant';

export default function MoolahMateCalculators() {
    const { t } = useTranslation();
    const calculatorDetails = getCalculatorDetails(t);

    return (
        <Grid className="sectionCommonPadding" id="calculators">
            <Grid container spacing={2} pt={6} pb={8}>
                <Grid item md={6} style={{ position: 'relative' }}>
                    <PageSubHeadingStyle
                        headLineOne={t('home-page.headline-8')}
                        headLineTwo={t('home-page.subtitle-8')}
                        headLineThree={t('home-page.subtitle-info-8')}
                    />
                    <Typography varient="h5" className="commonSubText">
                        {t('home-page.sub-text')}
                    </Typography>
                    <img src={image.calculatorAnimation} alt="Header logo" style={{ width: 685 }} />
                    <div className='mdFlexDisplay'>
                        <LoadingButton
                            className="loadingButtonStyle2 marginTopBottom"
                            type="submit"
                            variant="contained"
                            href="/calculators-hub/list"
                        >
                            {t('home-page.calculator')}
                        </LoadingButton>
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div className="otherPages">
                        {calculatorDetails.map((calculator) => (
                            <div className="otherAssetBorder" key={calculator.id}>
                                <Link href={calculator.link} style={{ textDecoration: 'none' }}>
                                    <Typography varient="body" className="calcultorHeading">
                                        {calculator.title}
                                    </Typography>
                                    <Typography varient="body1" className="calcultorSubHeading">
                                        {calculator.text}
                                    </Typography>
                                </Link>
                            </div>
                        ))}
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}
