/* eslint-disable */
import * as React from 'react';
import { styled, Typography, Grid, Box, Link, Card, TextField } from '@mui/material';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import { textAlign } from '@mui/system';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { FormContext } from '../context/StepperContext';
import {getAccountingAmount, getCurrencySymbol} from '../../../../utils/calCommonFunction';

const TableScroll = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        overflowX: 'auto',
    },
}));

const TableStyle = styled('table')(({ theme }) => ({
    width: '100%',
    paddingTop: '10px',
    minWidth: 550,
    [theme.breakpoints.down('md')]: {
        paddingTop: 10,
    },
    '& th': {
        textAlign: 'left',
        fontSize: 20,
        fontWeight: 700,
        color: theme.palette.primary.dark,
        padding: 10,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 18,
        },
    },
    '& td': {
        padding: '10px 0 10px 10px',
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 16,
        },
        // '& input': {
        //   textAlign: 'right',
        // },
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
}));

const BudgetInput = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '150px',
    position:'relative',
    '& span': {
        position: 'absolute',
    },
    '& input': {
        textAlign: 'right',
    },
    '.bold-text': {
        fontWeight: 'bold !important',
    }
}));

const NameInput = styled('table')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    '& span': {
        marginRight: -10,
    },
    '& input': {
        textAlign: 'left',
    }
}));

const SwitchStyle = styled(Box)(({ theme }) => ({
    width: 136,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '& p': {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 1,
    },
    '& span + p': {
        left: 'inherit',
        right: 0,
    },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 134,
    height: 30,
    padding: 0,
    display: 'flex',
    border: `solid 1px ${theme.palette.primary.main}`,
    borderRadius: 5,
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 67,
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 0,
        position: 'relative',
        '&:before, &:after': {
            position: 'absolute',
            left: 0,
            top: 2,
            content: "'Yes'",
            width: 67,
            height: 30,
            textAlign: 'center',
            color: theme.palette.secondary.contrastText,
            [theme.breakpoints.down('md')]: {
                top: 5,
            },
        },
        '&:after': {
            content: "'No'",
            left: '100%',
            color: theme.palette.primary.contrastText,
        },
        '&.Mui-checked': {
            transform: 'translateX(67px)',
            '&:before': {
                left: '-100%',
                content: "'Yes'",
                color: theme.palette.primary.contrastText,
            },
            '&:after': {
                content: "'No'",
                left: '0',
                color: theme.palette.secondary.contrastText,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: 'inherit',
            },
        },
        '&.Mui-disabled': {
            color: 'rgb(158, 203, 181) !important',
            pointerEvents: 'none',
            cursor: 'default',
            '& .MuiSwitch-thumb': {
                backgroundColor: 'rgb(158, 203, 181) !important',
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 67,
        height: 30,
        borderRadius: 0,
        background: theme.palette.primary.main,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'inherit',
        boxSizing: 'border-box',
    },
}));

const InfoIcon = styled('img')(({ theme }) => ({
    display: 'revert',
    width: "16px",
    cursor:'pointer',
    [theme.breakpoints.down('md')]: {
        margin: '2px 0 0 -16px',
        width: "16px",
    }
}));


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
    //   backgroundColor: theme.palette.common.white,
    //   color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 13,
      padding: 10,
      fontWeight: 500,
      maxWidth: '491px'
    },
}));

export default function ListAddBudget(props) {
    const { categories, categoryGroup, cashInflow = 0, cashOutflow = 0 } = props;
    const currencySymbol = getCurrencySymbol();
    const {
        updateSubCategoryData
    } = React.useContext(FormContext);
    
    const filteredCategories = categories.filter(cat => cat.isSelected).map((cat) => ({
        ...cat,
        subCategories: cat.subCategories.filter((subCat) => subCat.isSelected && !subCat.isDefault)
        }));

    const getTotal = (subCategories) => {
        return subCategories.reduce((total, item) => {
            const budget = item.budget.toString().replace(/[^0-9.-]+/g, '');
            total += Number(budget);
            return total;
        }, 0);
    }

    const getSubTotal = (subCategories) => {
        const total = getTotal(subCategories);
        return total.toLocaleString("en-US");
    }

    const getAnnualBudget = (subCategories) => {
        const total = getTotal(subCategories) * 12;
        return total.toLocaleString("en-US");
    }

    const renderFooter = (title, total, showHardBottomBorder = true) => <>
        <td></td>
        <td colSpan="2" style={{fontWeight:600, fontSize: '17px'}}>{title}</td>
        <td>
            <span style={{textAlign: 'center', display:'flex', justifyContent: 'space-between', width:"151px", fontWeight: 'bold', padding: '7px 0 9px 0px', borderTop: 'solid 3px #00773E', borderBottom: showHardBottomBorder ? 'solid 3px #00773E' : 'solid 1px #00773E'}}>
                <span>{currencySymbol}</span>
                <span>{total.toLocaleString("en-US")}</span>
            </span>
        </td>
        <td>
            <span style={{textAlign: 'center', display:'flex', justifyContent: 'space-between', width:"151px", fontWeight: 'bold', padding: '7px 0 9px 0px', borderTop: 'solid 3px #00773E', borderBottom: showHardBottomBorder ? 'solid 3px #00773E' : 'solid 1px #00773E'}}>
                <span>{currencySymbol}</span>
                <span>{(total * 12).toLocaleString("en-US")}</span>
            </span>
        </td>
    </>;

    const getTableFooter = () => {
        if (categoryGroup.name === 'Income/Cash Inflows') {
            let total = 0;
            filteredCategories.forEach((fc) => {
                total += getTotal(fc.subCategories);
            });
            return <>
                <tr><td>{' '}</td></tr>
                <tr>{renderFooter('Total income/cash inflows', total)}</tr>
            </>
        }
        if (categoryGroup.name === 'Expenses/Cash Outflows') {
            return getSubTotalLoanPayments();
        }
    };

    const getSubTotalBeforeLoanPayments = () => {
        const catIndex = filteredCategories.findIndex((cat) => cat.name === 'Loan/Lease payments');
        let total = 0;
        let categories = filteredCategories;
        if (catIndex > -1) {
            categories = filteredCategories.slice(0, catIndex);
        }
        categories.forEach((fc) => {
            total += getTotal(fc.subCategories);
        });
        return renderFooter('Every day spending', total, false);
    };

    const getSubTotalLoanPayments = () => {
        const loanCat = filteredCategories.find((cat) => cat.name === 'Loan/Lease payments');
        const excessOrShortFall = (cashInflow - cashOutflow);
        const formattedValue = getAccountingAmount(excessOrShortFall);
        const annualExpense = getAccountingAmount(excessOrShortFall * 12);
        return <>
            {loanCat === undefined && 
                <>
                    <tr>
                        <td>{" "}</td>
                    </tr>
                    <tr>{getSubTotalBeforeLoanPayments()}</tr>
                    <tr><td>{" "}</td></tr>
                </>
            }
            <tr>
                <td>{' '}</td>
            </tr>
            <tr>{renderFooter('Total expenses/cash outflows', cashOutflow)}</tr>
            <tr>
                <td>{' '}</td>
            </tr>
            <tr>
                <td></td>
                <td colSpan="2" style={{fontWeight:600, fontSize: '17px'}}>Excess or (shortfall) {' '}
                    <LightTooltip title={
                        <>
                            <Typography>If Excess (positive number), the system will treat it as your Savings.</Typography>
                            <Typography style={{marginTop: '10px'}}>If (shortfall) (negative number), please reduce your discretionary outflows or you will go into debt</Typography>
                        </>
                    }>
                        <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" />
                    </LightTooltip>
                </td>
                <td>
                    <span style={{textAlign: 'center', display:'flex', justifyContent: 'space-between', width:"151px", fontWeight: 'bold', padding: '7px 0 9px 0px', borderTop: 'solid 3px #00773E', borderBottom: 'solid 3px #00773E'}}>
                        <span>{currencySymbol}</span>
                        <span>{formattedValue}</span>
                    </span>
                </td>
                <td>
                    <span style={{textAlign: 'center', display:'flex', justifyContent: 'space-between', width:"151px", fontWeight: 'bold', padding: '7px 0 9px 0px', borderTop: 'solid 3px #00773E', borderBottom: 'solid 3px #00773E'}}>
                        <span>{currencySymbol}</span>
                        <span>{annualExpense}</span>
                    </span>
                </td>
            </tr>
        </>;
    }
    
    return (
        <TableScroll container p={0}>
            <TableStyle>
                <thead className='sticky-header'>
                    <tr>
                        <th style={{ width: '20%' }}>Category</th>
                        <th style={{ width: '30%', textAlign: 'center' }} colSpan={2}>Sub-Category</th>
                        <th style={{width: '17%'}}>Monthly Budget</th>
                        <th style={{width: '17%'}}>Annual Budget</th>
                        {props.categoryGroup.position === 3 && <th style={{ display: 'flex' }}></th>}
                        {props.categoryGroup.position === 4 && <th style={{ display: 'flex' }}>Variable {'   '}
                            <LightTooltip style={{marginLeft: 3 }} title={
                        <>
                            <Typography>A Variable expense is something that is non- essential (like vacation) or something that is controllable in the short term (like entertainment or groceries). We have marked each expense/outflow as variable or not.  Feel free to change them based on your own assessment.</Typography>
                        </>
                    }>
                        <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" />
                    </LightTooltip>
                            </th>}
                    </tr>
                </thead>
                <tbody style={{position: 'sticky'}}>
                    {filteredCategories.map((category, index) => <>
                        {
                            category.subCategories.map((subCat, indexCat) => (
                                <tr key={subCat.id}>
                                    <td>
                                        {indexCat === 0 ?
                                            <>
                                                <Typography>{category.name}</Typography>
                                            </>
                                            : <></>
                                        }
                                    </td>
                                    <td><Typography style={{ width: 110, fontWeight: category.name === 'Loan/Lease payments' ? 'bold' : '' }}>
                                        {category.name === subCat.name ? '' : subCat.name}
                                    </Typography></td>
                                    <td>
                                        {
                                           category.subCategories.length > 1 ? 
                                            <BudgetInput style={{ width: 100 }}>
                                                <span className={category.name === 'Loan/Lease payments' ? 'bold-text' : ''}>{currencySymbol}</span>
                                                <Input 
                                                    type='text'
                                                    className={category.name === 'Loan/Lease payments' ? 'bold-text' : ''}
                                                    placeholder="0"
                                                    value={subCat.budget}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/[^0-9.-]+/g, '');
                                                        if (value === '' || /^[0-9\b]+$/.test(value)) {
                                                            const budget = Number(value).toLocaleString("en-US");
                                                            updateSubCategoryData(categoryGroup.id, category.id, subCat.id, 'budget', budget)
                                                        }
                                                    }}
                                                />
                                            </BudgetInput>
                                            : ' '
                                        }
                                    </td>
                                    <td>
                                        {
                                            category.subCategories.length === 1 ? 
                                            <BudgetInput>
                                                <span className={category.name === 'Loan/Lease payments' ? 'bold-text' : ''}>{currencySymbol}</span>
                                                <Input 
                                                    type='text'
                                                    className={category.name === 'Loan/Lease payments' ? 'bold-text' : ''}
                                                    placeholder="0"
                                                    value={subCat.budget}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/[^0-9.-]+/g, '');
                                                        if (value === '' || /^[0-9\b]+$/.test(value)) {
                                                            const budget = Number(value).toLocaleString("en-US");
                                                            updateSubCategoryData(categoryGroup.id, category.id, subCat.id, 'budget', budget)
                                                        }
                                                    }}
                                                />
                                            </BudgetInput>
                                            :
                                            category.subCategories.length - 1 === indexCat ?
                                            <span style={{textAlign: 'center',paddingTop: '6px', borderBottom: '1px solid #aba6a6', display:'flex', justifyContent: 'space-between', width: '151px'}}>
                                                <span>{currencySymbol}</span>
                                                <span>{getSubTotal(category.subCategories)}</span>
                                            </span>
                                            : ' '
                                        }
                                    </td>
                                    <td>
                                        {category.subCategories.length - 1 === indexCat && <span style={{textAlign: 'center',paddingTop: '6px', borderBottom: '1px solid #aba6a6', display:'flex', justifyContent: 'space-between', width: '151px'}}>
                                            <span>{currencySymbol}</span>
                                            <span>{getAnnualBudget(category.subCategories)}</span>
                                        </span>}
                                    </td>
                                    {props.categoryGroup.position === 3 && <td></td>}
                                    {props.categoryGroup.position === 4 &&
                                    <td>
                                        <AntSwitch
                                            disabled
                                            checked={!subCat.isDiscretionary}
                                            inputProps={{ 'aria-label': 'ant design' }}
                                        />
                                    </td>}
                                </tr>
                            ))
                        }
                        {
                           (index === filteredCategories.length - 1) && <>{getTableFooter()}</>
                        }
                        {
                            ((index + 1) < filteredCategories.length) && filteredCategories[index + 1].name === 'Loan/Lease payments' && <><tr><td>{" "}</td></tr><tr>{getSubTotalBeforeLoanPayments()}</tr><tr><td>{" "}</td></tr></>
                        }
                        </>
                    )}
                </tbody>
            </TableStyle>
        </TableScroll >
    );
}
