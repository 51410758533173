import { Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import image from 'assets/image';
import HeadingStyle from 'pages/about-us/HeadingStyle';
import AppDownloadIcons from 'components/app-download-icons/AppDownloadIcons';
import { getKnowledgeBaseDetails } from './constant'

export default function GetSmarter() {
    const { t } = useTranslation();
    const knowledgeBaseDetails = getKnowledgeBaseDetails(t);

    return (
        <div className='divOverflow'>
            <Grid className="sectionCommonPadding commonProductPadding">
                <Grid container spacing={2} className='smPaddingStyle'>
                    <Grid item xl={6} md={6}>
                        <div className='getSmarterStyle'>
                            <Typography variant='h4'>{t('about-getSmater.headline-1')}</Typography>
                            {
                                knowledgeBaseDetails.map((item, indx) => (
                                    <div key={indx}>
                                        <HeadingStyle
                                            title={item.title}
                                            text={item.text}
                                        />
                                    </div>
                                ))
                            }
                            <LoadingButton
                                className="loadingButtonStyle2 marginTopSm"
                                type="submit"
                                variant="contained"
                                href="/register"
                            >
                                {t('common-translation.Free Trial')}
                            </LoadingButton>
                            <AppDownloadIcons />
                        </div>
                    </Grid>
                    <Grid item xl={6} md={6}>
                        <img src={image.getSmarterAnimation} alt='' />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
