import { useEffect, useState } from 'react';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

export default function ScrollToTop() {
    const [showTopBtn, setShowTopBtn] = useState(false);

    // changing the showTopBtn state whenever a scroll event happens
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);

    // function to help scroll to top smoothly
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className="top-to-btm">
            {showTopBtn && (
                <ArrowCircleUpIcon className="icon-position icon-style" onClick={goToTop} />
            )}
        </div>
    );
}
