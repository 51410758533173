import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import './styles.css'

export default function pageHeadingStyle(props) {
    return (
        <div className='headingStyle'>
            <Typography variant="h6">{props.headLineOne}</Typography>
            <Typography variant="h1">{props.headLineTwo}</Typography>
            {
                props.headLineThree &&
                <Typography variant="h5">{props.headLineThree}</Typography>
            }
            <div style={{ marginTop: '24px' }}>
                {
                    props.headingButton1 &&
                    <LoadingButton className='loadingButtonStyle2' type="submit" variant="outlined" href="/register">
                        {props.headingButton1}
                    </LoadingButton>
                }
                {
                    props.headingButton2 &&
                    <LoadingButton className='loadingButtonStyle1' style={{ marginLeft: '6px' }} type="submit" variant="contained" href="/">
                        {props.headingButton2}
                    </LoadingButton>
                }
            </div>
        </div>
    );
}