import { AuthContext } from 'contexts/AuthProvider';
import React, { useContext } from 'react';
import { Navigate,Outlet } from 'react-router-dom';

const PrivateRoutes = () => {
    const { auth } = useContext(AuthContext);

    let isLoggedIn = false;
    if (auth?.token) {
        isLoggedIn = true;
    }
    
    return isLoggedIn ? <Outlet /> : <Navigate to={'/'} />
}

export default PrivateRoutes