
import { Box, Card } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
    '& header + div + div': {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 90,
        },
    },
}));

export const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: 0,
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.up('lg')]: {
        paddingTop: 0,
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
    },
}));

export const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 90,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 0,
    background: theme.palette.primary.main,
    borderRadius: 0,
    position: 'fixed',
    height: '100vh',
    top: 0,
    [theme.breakpoints.down('md')]: {
        maxWidth: 50,
    },
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

export const SmallLogoStyle = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 50,
    [theme.breakpoints.down('md')]: {
        padding: '20px 4px 20px',
    },
    '& a div': {
        '& path': {
            fill: theme.palette.secondary.contrastText,
        },
    },
    '& + .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',
        margin: 'auto 0',
    },
}));

export const Logout = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: 44,
    margin: '0 !important',
    justifyContent: 'center',
    '& a.MuiTypography-root, & p.MuiTypography-root': {
        display: 'none',
        color: theme.palette.secondary.contrastText,
    },
    '& svg': {
        fill: theme.palette.secondary.contrastText,
        '& path': {
            fill: theme.palette.secondary.contrastText,
        },
    },
}));