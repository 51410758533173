import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ResetPasswordForm } from 'sections/auth/reset-password';
import Page from '../../components/Page';
import TopMenu from '../../components/TopMenu'
import SmallLogo from '../../components/SmallLogo';
import { SmallLogoStyle, SectionStyle, OnboardingDivStyle, FormDivStyle } from '../register/styled-components';
  
export default function ResetPassword() {
    const { t } = useTranslation();
    return (
        <Page title={"Reset your MoolahMate password. Your Personal Money Manager"} description={"Reset your MoolahMate password. MoolahMate is an easy to use personal money management platform to help you plan your financial journey."}>
            <div>
                <SectionStyle>
                    <SmallLogoStyle>
                        <SmallLogo sx={{ width: 40, height: 'auto', display: 'flex', justifyContent: 'center' }} />
                    </SmallLogoStyle>
                </SectionStyle>
                <Grid container justifyContent={'center'} spacing={2} marginLeft={0} width={'100%'} marginTop={0}>
                    <Grid item xs={12} className='customPaddingStyle'>
                        <TopMenu title={'Reset Password'} hideHelp />
                    </Grid>
                    <Grid item xl={5} lg={5} md={8} xs={12} className='extraPaddingStyle'>
                        <OnboardingDivStyle>
                            <FormDivStyle>
                                <ResetPasswordForm />
                            </FormDivStyle>
                        </OnboardingDivStyle>
                    </Grid>
                </Grid>
            </div>
        </Page>
    );
}
