import image from 'assets/image';

const getImgIcon = (name) => <img className="iconImgHub" src={name} alt={name} width={21} height={21} style={{ filter: 'invert(1)'}} />;

export const adminDashboard = {
    title: 'Admin Dashboard',
    path: '/admin-dashboard',
    icon: getImgIcon(image.internalDashboard),
}

export const navConfig = [
    {
        title: 'Home Hub',
        path: '/welcome',
        icon: getImgIcon(image.hub),
    },
    {
        title: 'Transactions',
        path: '/downloaded-transactions',
        icon: getImgIcon(image.transactions),
    },
    {
        title: 'Other Transactions',
        path: '/manual-transactions',
        icon: getImgIcon(image.otherTransactions),
    },
    {
        title: 'Executive Summary',
        path: '/dashboard',
        icon: getImgIcon(image.dashboard),
    },
    {
        title: 'Reports Hub',
        path: '/dashboard/all-reports',
        icon: getImgIcon(image.report),
    },
    {
        title: 'To Do’s',
        path: '/todos',
        icon: getImgIcon(image.todoIcon),
    },
    {
        title: 'Key Dates',
        path: '/key-dates',
        icon: getImgIcon(image.keyDateIcon1),
    },
    {
        title: 'FAQs',
        path: '/faqs',
        icon: getImgIcon(image.faqIcon),
    },
    {
        title: 'Calculators Hub',
        path: '/calculators',
        icon: getImgIcon(image.calculator),
    },
    {
        title: 'Knowledge Base Hub',
        path: '/knowledge-base',
        icon: getImgIcon(image.knowledgeBaseIcon),
    },
    {
        title: 'Favourite websites',
        path: '/favorite-websites',
        icon: getImgIcon(image.favWebsite),
    },
    {
        title: 'Forums',
        path: '/forum-categories',
        icon: getImgIcon(image.forum),
    },
    {
        title: 'Suggestion Box',
        path: '/suggestion-box',
        icon: getImgIcon(image.suggestion),
    },
    {
        title: 'Contact Us',
        path: '/contact-us',
        icon: getImgIcon(image.contactUs),
    },
];