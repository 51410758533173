import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Link, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFCheckbox, RHFTextField } from '../../../components/hook-form';
import auth from '../../../constants/services/auth';
import { showError } from '../../../utils/toast';

export default function LoginForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email(t('error-message.invalid-email')).required(t('error-message.email-required')),
        password: Yup.string().required(t('error-message.password-required')),
    });

    const defaultValues = {
        email: '',
        password: '',
        remember: true,
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        handleSubmit,
    } = methods;

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);
       
            if (!isScriptExist) {
                const script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = () => {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }
       
            if (isScriptExist && callback) callback();
        }
       
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`, () => {});

        return () => {
            const script = document.getElementById('recaptcha-key');
            if (script) {
                script.remove();
            }
            const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
            if (recaptchaElems.length) {
                recaptchaElems[0].remove();
            }
        }
    }, []);

    const onSubmit = async (data, event) => {
        event.preventDefault();
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, { action: 'submit' }).then(async token => {
                await loginUser(token, data);
            });
        });
    };

    const loginUser = async (token, data) => {
        setIsLoading(true);
        const {email, password} = data;
        const loginInfo = { email, password, token };
        try {
            const result = await auth.signIn2FA(loginInfo);
            const { authToken } = result?.data;
            navigate('/verify-code', { replace: true, state: { authToken } });
        } catch (error) {
            if (error.message === 'EMAIL_NOT_VERIFIED') {
                navigate('/user/verify', { replace: true, state:'' });
                return;
            }
            showError(t, error);
        } finally {
            setIsLoading(false);
        }
    };

    const ButtonText = styled('div')(({ theme }) => ({
        '& button': {
            textTransform: 'inherit',
            fontSize: 16,
            padding: '6px 16px !important',
            [theme.breakpoints.down('md')]: {
                fontSize: '14px !important',
            },
        },
    }));

    useEffect(() => {
        if (sessionStorage.getItem('userData')) {
            const user = JSON.parse(sessionStorage.getItem('userData'));
            if (Object.keys(user).length > 0) {
                navigate('/welcome', { replace: true });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <RHFTextField
                name="email"
                label={t('login-page.email-address')}
            />
            <div style={{ position: 'relative' }}>
                <RHFTextField
                    name="password"
                    label={t('login-page.password')}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
                <RHFCheckbox name="remember" label={t('login-page.remember-me')} />
                <Link href="/forgot-password" variant="subtitle2">
                    {t('login-page.forgot-password')}
                </Link>
            </Stack>
            <ButtonText>
                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    className='loadingButtonStyle2'
                    variant="contained"
                    loading={isLoading}
                >
                    {t('login-page.login')}
                </LoadingButton>
            </ButtonText>
        </FormProvider>
    );
}
