import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import HeightIcon from '@mui/icons-material/Height';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';

import videoURL from 'constants/helpVideoUrl';
import Page from '../../../components/Page';
import TopHeading from '../../../components/TopHeading';
import TopMenu from '../../../components/TopMenu';
import dashboard from '../../../constants/services/dashboard';
import {
    exportCustomExcel,
    getCurrencySymbol,
    getMonthNameYear,
    getNegativeNumber,
    getPositiveNumber,
    removeEmpty
} from '../../../utils/calCommonFunction';
import { EXCEL_STYLE } from '../../calculators/constant';
import { ButtonText, NoDataTd, ReportAmountTd, TotalAmountDiv } from '../ExecutiveSummary/styled-component';
import {
    AmountDiv,
    ContentStyle,
    LoaderGif,
    SpanTotalFlow,
    StickyFirstTd,
    StickyFirstTh,
    StickySecondTd,
    StickySecondTh,
    StickyTable,
    StickyThirdTd,
    StickyThirdTh,
    SubmitButton,
    TableStyle,
    TableWrapper
} from '../Expense/styled-component';
import CreditCardFilter from '../filter/CreditCardFilter';

export default function DebtPayment(props) {
    const [expenseData, setExpenseData] = useState([]);
    const [expenseGroupData, setExpenseGroupData] = useState([]);
    const [monthColumn, setMonthColumn] = useState([]);
    const [monthWiseAverage, setMonthWiseAverage] = useState();
    const [monthWiseTotal, setMonthWiseTotal] = useState([]);
    const [expenseMonthTotal, setExpenseMonthTotal] = useState();
    const [totalPercentageAsset, setTotalPercentageAsset] = useState([]);
    const [tableLoader, setTableLoader] = useState(false);
    const [categories, setCategories] = useState([]);
    const [monthTotalAvg, setMonthTotalAvg] = useState();
    const [monthTotalPercentage, setMonthTotalPercentage] = useState();
    const [loading, setLoading] = useState(false);
    const [excelData, setExcelData] = useState({})

    const [discretinaryDataLength, setDiscretinaryDataLength] = useState(null);
    const [sortingTotalStatus, setSortingTotalStatus] = useState(false);
    const [sortingAvgStatus, setSortingAvgStatus] = useState(false);
    const [categoriesDetails, setCategoriesDetails] = useState({
        catName: '',
        subCatName: ''
    })

    const currencyCode = getCurrencySymbol();
    const { reportName } = props;
    const { t } = useTranslation();

    const [filtersData, setFiltersData] = useState({
        fromMonth: moment.parseZone().format('YYYY-MM'),
        compareMonth: moment.parseZone().startOf('year').format('YYYY-MM'),
        categoryId: null,
        subCatId: null,
    });

    const updateFilters = (key, value) => {
        const clonedData = {
            ...filtersData,
            [key]: value,
        };
        if (key === 'categoryId') {
            clonedData.subCatId = '';
        }
        setFiltersData(clonedData);
    };

    const getDebtPaymentData = async () => {
        const filtersDataObj = removeEmpty(filtersData);
        setTableLoader(true);

        const response = await dashboard.getDebtPaymentData(filtersDataObj);
        const data = response?.categoriesData;

        setExcelData(data)
        setTableLoader(false);

        const groupedData = {};
        const groupedDataTot = {};

        const groupedDataMonthlyTot = [];
        const monthRange = Object.keys(data);
        const monthDifference = monthRange?.length;

        setDiscretinaryDataLength(monthDifference);
        monthRange.sort();
        setMonthColumn(monthRange);

        // eslint-disable-next-line
        monthRange.forEach((item) => {
            let totalAsset = 0;
            let assetName = '';
            let cateTotal = 0;

            data[item].forEach((a) => {
                assetName = a.name;
                groupedData[a.name] = groupedData[a.name] || [];
                groupedData[a.name].push(a.values);
                totalAsset += Number(a.values);
                cateTotal = Number(groupedDataTot[a.name] ? groupedDataTot[a.name] : 0) + Number(a.values);
                groupedDataTot[assetName] = cateTotal;
                groupedDataTot[assetName] = cateTotal;
            });
            groupedDataMonthlyTot.push(totalAsset);
            assetName = '';
        });

        const ObjectTotalData = Object.values(groupedDataTot);
        const expenseEle = Object.keys(groupedData);
        const monthAvgTotal = ObjectTotalData.map((item) => Number(item) / monthDifference);
        const monthTotal = expenseTotalSum(groupedDataMonthlyTot);
        const expenseavgTotal = expenseTotalSum(monthAvgTotal);
        setExpenseData(expenseEle);
        setExpenseGroupData(groupedData);
        setMonthWiseAverage(expenseavgTotal);
        setExpenseMonthTotal(monthTotal);
        setMonthWiseTotal(groupedDataMonthlyTot);
    };

    const expenseTotalSum = (array) => {
        const result = array.reduce((acc, val) => acc + val, 0);
        return result;
    };

    const getTotalSumofData = (item) => {
        const sumofdata = expenseGroupData[item].reduce((acc, item) => Number(acc) + Number(item), 0);
        return sumofdata;
    };

    const getAvgSumofData = (item) => {
        const totalval = getTotalSumofData(item);
        const totalAvg = totalval / discretinaryDataLength;
        return totalAvg?.toFixed(2);
    };

    const getFilterData = () => {
        getDebtPaymentData();
    };

    const handleSortingData = (arg, flag) => {
        const getDescData= Object.keys(expenseGroupData)
        const sortedValue= getDescData.map((items)=>({
            'name': items,
            'total': getTotalSumofData(items),
            'avg': getAvgSumofData(items)   
        }))

        if (arg === 'total' && flag) {
            sortedValue.sort((a, b) => parseFloat(a.total) - parseFloat(b.total));
        }
        else if (arg === 'avg' && flag) {
            sortedValue.sort((a, b) => parseFloat(a.avg) - parseFloat(b.avg));
        }
        else{
            sortedValue.sort((a,b)=> a.name - b.name)
        }

        const sortedDiscretionaryData= sortedValue.map((items)=>items.name)
        setExpenseData(sortedDiscretionaryData);
    };

    const handleAvgSorting = () => {
        setSortingAvgStatus((prevValue) => {
            const updatedValue = !prevValue;
            handleSortingData('avg', updatedValue);
            return updatedValue;
        });
        setSortingTotalStatus(false);
    };

    const handleTotalSorting = () => {
        setSortingTotalStatus((prevValue) => {
            const updatedValue = !prevValue;
            handleSortingData('total', updatedValue);
            return updatedValue;
        });
        setSortingAvgStatus(false);
    };

    const getCategories = async () => {
        const response = await dashboard.getDebtPaymentCategories();
        const categories = response?.data || [];
        setCategories(categories);
    };

    const getExcelData = (record, monthWiseTotal, monthWiseAvg, cateName) => {
        const arrayValue = []
        record.forEach((item, index) => {
            const existingMonth = arrayValue.find(outputItem => outputItem.Category === cateName);
            if (existingMonth) {
                existingMonth[monthColumn[index]] = getNegativeNumber(item);
            } else {
                const newItem = { Category: cateName, Total: getNegativeNumber(monthWiseTotal), Average: getNegativeNumber(monthWiseAvg?.toFixed(2)) };
                newItem[monthColumn[index]] = getNegativeNumber(item);
                arrayValue.push(newItem);
            }
        });
        return arrayValue;
    }

    const getExcelSummary = () => {
        const merges = []
        const outputArray = [];
        const selectedFormData = []

        selectedFormData.push({
            'From Date': filtersData.compareMonth,
            'To Date': filtersData.fromMonth,
            'Category Name': categoriesDetails.catName,
            'Sub-Category Name': categoriesDetails.subCatName
        });

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const month in excelData) {
            excelData[month].forEach(item => {
                const setValueSign = getPositiveNumber(item.values);
                const existingItem = outputArray.find(outputItem => outputItem.Category === item.name);
                if (existingItem) {
                    existingItem[month] = setValueSign;
                } else {
                    const newItem = { Category: item.name, Total: getNegativeNumber(getTotalSumofData(item.name)), Average: getNegativeNumber(getAvgSumofData(item.name))};
                    newItem[month] = setValueSign;
                    outputArray.push(newItem);
                }
            });
        }

        const totalValue = getExcelData(monthWiseTotal, expenseMonthTotal, monthWiseAverage, 'Total')
        const percentageAsset = getExcelData(totalPercentageAsset, monthTotalPercentage, monthTotalAvg, '% of Total Expenditures')
        const excelObjectData = [...outputArray, ...totalValue];

        const style = [
            {
                "col": 'A1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'B1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'C1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'D1',
                "style": EXCEL_STYLE.boldHeader
            }
        ]

        const newOutputArray= excelObjectData.map((item) => {
            const { Total, Average, Category, ...rest } = item;
            return { Category, ...rest, Total, Average };
        });

        const newPercentageData = percentageAsset.map((item) => {
            const { Total, Average, ...rest } = item;
            return { ...rest, Total, Average };
        });
        const data2 = [...newPercentageData]

        const addCurrencySymbol = newOutputArray.map(obj => {
            const newObj = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const key in obj) {
                if (!['Category'].includes(key)) {
                    newObj[key] = `$ ${obj[key]}`;
                } else {
                    newObj[key] = obj[key];
                }
            }
            return newObj;
        });
        const addPercentage = data2.map(obj => {
            const newObj = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const key in obj) {
                if (!['Category'].includes(key)) {
                    newObj[key] = `${obj[key]} %`;
                } else {
                    newObj[key] = obj[key];
                }
            }
            return newObj;
        });
        const data = [selectedFormData, addCurrencySymbol, addPercentage]

        const cols = [{ width: 25 }, { width: 13 }, { width: 17 }, { width: 20 }, { width: 13 }, { width: 13 }, { width: 13 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 15 }];
        exportCustomExcel(data, style, cols, merges, 'Dept Payment', 7, ['I'], [false, false, true], [-1, 'A4', -1]);

    }

    useEffect(() => {
        getCategories();
        getDebtPaymentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Page title={t('debt-payment.title')}>
                <TopMenu title="Asset and Liability Reports" videoLink={videoURL.debtPayment} />
                <TopHeading heading={t('debt-payment.subtitle')} />
                <ContentStyle>
                    <Grid container pt={0} pb={4} mt={0}>
                        <Grid item md={10.5} xs={12}>
                            <CreditCardFilter
                                filtersData={filtersData}
                                updateFilters={updateFilters}
                                categories={categories}
                                categoriesDetails={categoriesDetails}
                                setCategoriesDetails={setCategoriesDetails}
                            />
                        </Grid>
                        <Grid
                            item
                            md={1.5}
                            xs={12}
                            style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}
                        >
                            <SubmitButton
                                loading={loading}
                                size="large"
                                variant="contained"
                                onClick={() => getFilterData()}
                                className='loadingButtonStyle2'
                            >
                                Go
                            </SubmitButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} pt={0} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} sm={12} md={12}>
                            <StickyTable>
                                <TableWrapper>
                                    <TableStyle className="highlighted-fields">
                                        {tableLoader ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan={4}>
                                                        <LoaderGif
                                                            alt="homeInfo"
                                                            src="/static/animation_loader.gif"
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <>
                                                {expenseData?.length > 0 ? (
                                                    <>
                                                        <thead>
                                                            <tr>
                                                                <StickyFirstTh>
                                                                    {t(`debt-payment.category`)}
                                                                </StickyFirstTh>
                                                                {monthColumn.map((item, indx) => (
                                                                    <th style={{ textAlign: 'end' }} key={indx}>
                                                                        {getMonthNameYear(item)}
                                                                    </th>
                                                                ))}
                                                                <StickySecondTh
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        right:
                                                                                reportName === 'savings'
                                                                                    ? '119px'
                                                                                    : '139px',
                                                                    }}
                                                                    onClick={handleTotalSorting}
                                                                >
                                                                    <span
                                                                        className={`sortingIcon ${
                                                                            sortingTotalStatus
                                                                                ? 'activeIcon'
                                                                                : ''
                                                                        }`}
                                                                    >
                                                                        {' '}
                                                                        {t(`debt-payment.total`)}{' '}
                                                                        {sortingTotalStatus ? (
                                                                            <ArrowDownwardIcon
                                                                                className="sortingReportIcon"
                                                                                style={{ opacity: 1 }}
                                                                            />
                                                                        ) : (
                                                                            <HeightIcon className="sortingReportIcon" />
                                                                        )}{' '}
                                                                    </span>
                                                                </StickySecondTh>
                                                                <StickyThirdTh
                                                                    style={{ right: '-1px', cursor: 'pointer' }}
                                                                    onClick={handleAvgSorting}
                                                                >
                                                                    <span
                                                                        className={`sortingIcon ${
                                                                            sortingAvgStatus ? 'activeIcon' : ''
                                                                        }`}
                                                                    >
                                                                        {t(`debt-payment.average`)}{' '}
                                                                        {sortingAvgStatus ? (
                                                                            <ArrowDownwardIcon
                                                                                className="sortingReportIcon"
                                                                                style={{ opacity: 1 }}
                                                                            />
                                                                        ) : (
                                                                            <HeightIcon className="sortingReportIcon" />
                                                                        )}{' '}
                                                                    </span>
                                                                </StickyThirdTh>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {expenseData.map((item, index) => (
                                                                <>
                                                                    <tr key={index}>
                                                                        <StickyFirstTd>{item}</StickyFirstTd>
                                                                        {expenseGroupData[item].map(
                                                                            (item, indx) => (
                                                                                <ReportAmountTd key={indx}>
                                                                                    <TotalAmountDiv
                                                                                        style={{
                                                                                            width: 120,
                                                                                            float: 'right',
                                                                                        }}
                                                                                    >
                                                                                        {currencyCode}
                                                                                        <span>
                                                                                            {getNegativeNumber(
                                                                                                item
                                                                                            )}
                                                                                        </span>
                                                                                    </TotalAmountDiv>
                                                                                </ReportAmountTd>
                                                                            )
                                                                        )}
                                                                        <StickySecondTd style={{ right: '139px' }}>
                                                                            <TotalAmountDiv
                                                                                style={{
                                                                                    width: 120,
                                                                                    float: 'right',
                                                                                }}
                                                                            >
                                                                                {currencyCode}{' '}
                                                                                <span>
                                                                                    {getNegativeNumber(
                                                                                        getTotalSumofData(item)
                                                                                    )}
                                                                                </span>
                                                                            </TotalAmountDiv>
                                                                        </StickySecondTd>
                                                                        <StickyThirdTd style={{ right: '-1px' }}>
                                                                            <TotalAmountDiv
                                                                                style={{
                                                                                    width: 120,
                                                                                    float: 'right',
                                                                                }}
                                                                            >
                                                                                {currencyCode}{' '}
                                                                                <span>{getNegativeNumber(getAvgSumofData(item))}</span>
                                                                            </TotalAmountDiv>
                                                                        </StickyThirdTd>
                                                                    </tr>
                                                                </>
                                                            ))}
                                                            {
                                                                <tr>
                                                                    <StickyFirstTd
                                                                        style={{
                                                                            height: '51px',
                                                                            fontSize: '16px',
                                                                            fontWeight: '600',
                                                                        }}
                                                                    >
                                                                        {t(`debt-payment.total`)}
                                                                    </StickyFirstTd>
                                                                    {monthWiseTotal.map((item, index) => (
                                                                        <td key={index}>
                                                                            <AmountDiv>
                                                                                {currencyCode}
                                                                                <span>
                                                                                    {getNegativeNumber(item)}
                                                                                </span>
                                                                            </AmountDiv>
                                                                        </td>
                                                                    ))}
                                                                    <StickySecondTd style={{ right: '139px' }}>
                                                                        <AmountDiv
                                                                            style={{ width: 120, float: 'right' }}
                                                                        >
                                                                            {currencyCode}{' '}
                                                                            <span>
                                                                                {getNegativeNumber(
                                                                                    expenseMonthTotal
                                                                                )}
                                                                            </span>
                                                                        </AmountDiv>
                                                                    </StickySecondTd>
                                                                    <StickyThirdTd style={{ right: '-1px' }}>
                                                                        <AmountDiv>
                                                                            {currencyCode}
                                                                            <span>
                                                                                {getNegativeNumber(
                                                                                    monthWiseAverage?.toFixed(2)
                                                                                )}
                                                                            </span>
                                                                        </AmountDiv>
                                                                    </StickyThirdTd>
                                                                </tr>
                                                            }
                                                            {totalPercentageAsset.length > 0 && (
                                                                <tr>
                                                                    <StickyFirstTd
                                                                        style={{
                                                                            height: '51px',
                                                                            fontSize: '16px',
                                                                            fontWeight: '600',
                                                                        }}
                                                                    >
                                                                        {t(`${reportName}.total-expenditures`)}
                                                                    </StickyFirstTd>
                                                                    {totalPercentageAsset?.map((item, index) => (
                                                                        <td style={{ padding: '0px' }} key={index}>
                                                                            <SpanTotalFlow
                                                                                style={{
                                                                                    justifyContent: 'end',
                                                                                    borderTop: '0px',
                                                                                }}
                                                                            >
                                                                                {getPositiveNumber(
                                                                                    item?.toFixed(2)
                                                                                )}{' '}
                                                                                    %
                                                                            </SpanTotalFlow>
                                                                        </td>
                                                                    ))}
                                                                    <StickySecondTd style={{ padding: '0px', right: '139px' }}>
                                                                        <SpanTotalFlow
                                                                            style={{
                                                                                justifyContent: 'end',
                                                                                borderTop: '0px',
                                                                            }}
                                                                        >
                                                                            {getPositiveNumber(
                                                                                monthTotalPercentage?.toFixed(2)
                                                                            )}{' '}
                                                                                %
                                                                        </SpanTotalFlow>
                                                                    </StickySecondTd>
                                                                    <StickyThirdTd style={{ padding: '0px', right: '-1px' }}>
                                                                        <SpanTotalFlow
                                                                            style={{
                                                                                justifyContent: 'end',
                                                                                borderTop: '0px',
                                                                            }}
                                                                        >
                                                                            {getPositiveNumber(
                                                                                monthTotalAvg?.toFixed(2)
                                                                            )}{' '}
                                                                                %
                                                                        </SpanTotalFlow>
                                                                    </StickyThirdTd>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </>
                                                ) : (
                                                    <tbody>
                                                        <tr>
                                                            <NoDataTd colSpan={4}>
                                                                {t('executive-summary.no-data-found')}
                                                            </NoDataTd>
                                                        </tr>
                                                    </tbody>
                                                )}
                                            </>
                                        )}
                                    </TableStyle>
                                </TableWrapper>
                            </StickyTable>
                        </Grid>
                    </Grid>
                    <ButtonText>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            onClick={() => getExcelSummary()}
                            className='loadingButtonStyle2'
                        >
                        Export
                        </LoadingButton>
                    </ButtonText>
                </ContentStyle>
                {/* </Wrapper> */}
            </Page>
        </>
    );
}