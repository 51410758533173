import { Grid } from '@mui/material';
import Page from 'components/Page';
import TopHeading from 'components/TopHeading';
import TopMenu from 'components/TopMenu';
import dashboard from 'constants/services/dashboard';
import moment from 'moment';
import { TotalAmountDiv } from 'pages/calculators/styled-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { exportCustomExcel, getCurrencySymbol, getPositiveNumber, removeEmpty } from 'utils/calCommonFunction';
import { LoadingButton } from '@mui/lab';
import { EXCEL_STYLE } from 'pages/calculators/constant';
import { ButtonText, LoaderGif, NoDataTd, SpanTotalFlow, StickyTable, TableStyle, TableWrapper } from '../Expense/styled-component';
import { ContentStyle } from '../styled-component';
import Filter from './Filter';
import { StickyFirstTd, StickyFirstTh, SubmitButton } from './styled-components';

export default function LoansActivity() {
    const { t } = useTranslation();
    const currencyCode = getCurrencySymbol();

    const [filtersData, setFiltersData] = useState({
        fromMonth: moment.parseZone().format('YYYY-MM'),
        compareMonth: moment.parseZone().startOf('year').format('YYYY-MM'),
        accountId: null,
    });
    const [loanAccounts, setLoanAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableLoader, setTableLoader] = useState(false);
    const [reportData, setReportData] = useState([]);
    const [monthColumn, setMonthColumn] = useState([]);
    const [totalData, setTotalData] = useState({});

    const updateFilters = (key, value) => {
        const clonedData = {
            ...filtersData,
            [key]: value,
        };
        setFiltersData(clonedData);
    };

    const getLoanAccounts = async () => {
        const response = await dashboard.getLoanAccounts();
        const loanAccounts = response?.data || [];
        setLoanAccounts(loanAccounts);
    };

    const getLoanActivityData = async () => {
        const filtersDataObj = removeEmpty(filtersData);
        setTableLoader(true);

        if (filtersDataObj?.accountId) {
            filtersDataObj.accountId = filtersDataObj?.accountId?.id;
        }

        const response = await dashboard.getLoanPaymentData(filtersDataObj);
        const data = response?.data;
        const monthRange = Object.keys(data);
        monthRange.sort().reverse();
        setMonthColumn(monthRange);
        setReportData(data);
        setTableLoader(false);

        const totals = Object.entries(data).reduce((acc, [, value]) => {
            acc = {
                ...acc,
                borrowed: acc.borrowed + (value?.borrowed || 0),
                piPayments: acc.piPayments + (value?.piPayments || 0),
                interestExpense: acc.interestExpense + (value?.interestExpense || 0)
            };
            return acc;
        }, { borrowed: 0, piPayments: 0, interestExpense: 0 });
        setTotalData(totals);
    };

    const getExcelSummary = () => {
        const merges = []
        const selectedFormData = []
        selectedFormData.push({
            'From Date': filtersData.compareMonth,
            'To Date': filtersData.fromMonth,
            [t('loans-activity.loan-loc-category')]: filtersData.accountId?.label || '',
        });

        const monthData = monthColumn.map((key) => ({
            'Date': moment.parseZone(key).format('MMM DD, YYYY'),
            'Borrowed': reportData[key].borrowed,
            'Interest Expense': reportData[key].interestExpense,
            'P & I Payments': reportData[key].piPayments
        }));

        const style = [
            {
                "col": 'A1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'B1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'C1',
                "style": EXCEL_STYLE.boldHeader
            },

            {
                "col": 'A4',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'B4',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'C4',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'D4',
                "style": EXCEL_STYLE.boldHeader
            },
        ];

        const footerTotal = [{}, {
            'Date': 'Total',
            'Borrowed': totalData.borrowed,
            'Interest Expense': totalData.interestExpense,
            'P & I Payments': totalData.piPayments,
        }]

        const data = [selectedFormData, monthData, footerTotal];
        const cols = [{ width: 15 }, { width: 15 }, { width: 30 }, { width: 30 }];
        exportCustomExcel(data, style, cols, merges, 'Loans/Lines of Credit Activity Report', 7, ['I'], [false, false, true], [-1, 'A4', -1]);
    };

    useEffect(() => {
        getLoanAccounts();
        getLoanActivityData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Page title={t('loans-activity.title')}>
                <TopMenu title="Asset and Liability Reports" />
                <TopHeading heading={t('loans-activity.subtitle')} />
                <ContentStyle>
                    <Grid container pt={0} pb={4} mt={0}>
                        <Grid item md={10.5} xs={12}>
                            <Filter
                                filtersData={filtersData}
                                updateFilters={updateFilters}
                                loanAccounts={loanAccounts}
                            />
                        </Grid>
                        <Grid
                            item
                            md={1.5}
                            xs={12}
                            style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}
                        >
                            <SubmitButton
                                loading={loading}
                                size="large"
                                variant="contained"
                                onClick={() => getLoanActivityData()}
                                className="loadingButtonStyle2"
                            >
                                Go
                            </SubmitButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} pt={0} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} sm={12} md={12}>
                            <StickyTable>
                                <TableWrapper>
                                    <TableStyle>
                                        {tableLoader ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan={4}>
                                                        <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <>
                                                {Object.keys(reportData)?.length ? (
                                                    <>
                                                        <thead>
                                                            <tr>
                                                                <StickyFirstTh>
                                                                    {t('loans-activity.date')}
                                                                </StickyFirstTh>
                                                                <StickyFirstTh style={{ textAlign: 'end' }}>
                                                                    {t('loans-activity.borrowed')}
                                                                </StickyFirstTh>
                                                                <StickyFirstTh style={{ textAlign: 'end' }}>
                                                                    {t('loans-activity.interest-expense')}
                                                                </StickyFirstTh>
                                                                <StickyFirstTh style={{ textAlign: 'end' }}>
                                                                    {t('loans-activity.pi-payments')}
                                                                </StickyFirstTh>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {monthColumn.map((key) => (
                                                                <tr key={key}>
                                                                    <StickyFirstTd>
                                                                        {moment.parseZone(key).format('MMM DD, YYYY')}
                                                                    </StickyFirstTd>
                                                                    <StickyFirstTd>
                                                                        <TotalAmountDiv
                                                                            style={{
                                                                                width: 120,
                                                                                float: 'right',
                                                                            }}
                                                                        >
                                                                            {currencyCode}
                                                                            <span>
                                                                                {getPositiveNumber(reportData[key].borrowed)}
                                                                            </span>
                                                                        </TotalAmountDiv>
                                                                    </StickyFirstTd>
                                                                    <StickyFirstTd>
                                                                        <TotalAmountDiv
                                                                            style={{
                                                                                width: 120,
                                                                                float: 'right',
                                                                            }}
                                                                        >
                                                                            {currencyCode}
                                                                            <span>
                                                                                {getPositiveNumber(reportData[key].interestExpense)}
                                                                            </span>
                                                                        </TotalAmountDiv>
                                                                    </StickyFirstTd>
                                                                    <StickyFirstTd>
                                                                        <TotalAmountDiv
                                                                            style={{
                                                                                width: 120,
                                                                                float: 'right',
                                                                            }}
                                                                        >
                                                                            {currencyCode}
                                                                            <span>
                                                                                {getPositiveNumber(reportData[key].piPayments)}
                                                                            </span>
                                                                        </TotalAmountDiv>
                                                                    </StickyFirstTd>
                                                                </tr>
                                                            ))}
                                                            <tr>
                                                                <StickyFirstTd
                                                                    style={{
                                                                        height: '51px',
                                                                        fontSize: '16px',
                                                                        fontWeight: '600',
                                                                    }}
                                                                >
                                                                    {t('loans-activity.total')}
                                                                </StickyFirstTd>
                                                                <StickyFirstTd>
                                                                    <SpanTotalFlow>
                                                                        <TotalAmountDiv>
                                                                            {currencyCode}{' '}
                                                                            <span>{getPositiveNumber(totalData?.borrowed || 0)}</span>
                                                                        </TotalAmountDiv>
                                                                    </SpanTotalFlow>
                                                                </StickyFirstTd>
                                                                <StickyFirstTd>
                                                                    <SpanTotalFlow>
                                                                        <TotalAmountDiv>
                                                                            {currencyCode}{' '}
                                                                            <span>{getPositiveNumber(totalData?.interestExpense || 0)}</span>
                                                                        </TotalAmountDiv>
                                                                    </SpanTotalFlow>
                                                                </StickyFirstTd>
                                                                <StickyFirstTd>
                                                                    <SpanTotalFlow>
                                                                        <TotalAmountDiv>
                                                                            {currencyCode}{' '}
                                                                            <span>{getPositiveNumber(totalData?.piPayments || 0)}</span>
                                                                        </TotalAmountDiv>
                                                                    </SpanTotalFlow>
                                                                </StickyFirstTd>
                                                            </tr>
                                                        </tbody>
                                                    </>
                                                ) : (
                                                    <tr>
                                                        <NoDataTd colSpan={4}>
                                                            {t('executive-summary.no-data-found')}
                                                        </NoDataTd>
                                                    </tr>
                                                )}
                                            </>
                                        )}
                                    </TableStyle>
                                </TableWrapper>
                            </StickyTable>
                        </Grid>
                    </Grid>
                    <ButtonText>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            onClick={() => getExcelSummary()}
                            className='loadingButtonStyle2'
                        >
                        Export
                        </LoadingButton>
                    </ButtonText>
                </ContentStyle>
            </Page>
        </>
    );
}
