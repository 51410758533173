import { Grid } from '@mui/material';
import Page from 'components/Page';
import TopHeading from 'components/TopHeading';
import TopMenu from 'components/TopMenu';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { fDate } from 'utils/formatTime';
import { showError } from 'utils/toast';
import _ from 'lodash'
import adminDashboard from '../../constants/services/admin-dashboard';
import DemographicTable from './DemographicTable';
import GeographicTable from './GeographicTable';
import Filter from './filter/Filter';
import DemographicTrendTable from './DemographicTrendTable';
import GeographicChart from './GeographicChart';

const genderList = [
    { id: 'Male', label: 'Male' },
    { id: 'Female', label: 'Female' },
    { id: 'Other', label: 'Other' },
];

const ageGroupOptions = [
    { id: '18-29', label: '18-29' },
    { id: '30-39', label: '30-39' },
    { id: '40-59', label: '40-59' },
    { id: '60-64', label: '60-64' },
    { id: 'Over 65', label: 'Over 65' },
];

function customizer(objValue, srcValue) {
    if (_.isArray(objValue)) {
        return objValue.concat(srcValue);
    }
}

export default function AdminDashboard() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [filtersData, setFiltersData] = useState({
        dateFrom: new Date(new Date().getFullYear(), 0, 1),
        dateTo: new Date(),
        country: [],
        state: [],
        city: [],
        gender: genderList,
        ageGroup: ageGroupOptions,
        householdPeople: [],
        userCount: { id: 'NEW_USERS', label: 'New Users' },
        aggregatedBy: 'Month',
    });
    const [loading, setLoading] = useState(false);
    const [countryStateFilters, setCountryStateFilters] = useState({});
    const [filterCountryOptions, setFilterCountryOptions] = useState([]);
    const [filterStateOptions, setFilterStateOptions] = useState([]);
    const [filterCityOptions, setFilterCityOptions] = useState([]);
    const [filterHouseholdOptions, setFilterHouseholdOptions] = useState([]);
    const [geographicToDateData, setGeographicToDateData] = useState([]);
    const [geographicTrendData, setGeographicTrendData] = useState({});
    const [demographicToDateData, setDemographicToDateData] = useState({});
    const [demographicTrendData, setDemographicTrendData] = useState({});
    const [totalSignups, setTotalSignups] = useState(0);

    const populateFilters = async () => {
        try {
            const response = await adminDashboard.getAdminDashboardFilters();
            const { countryStateMapping, householdPeople } = response;
            setCountryStateFilters(countryStateMapping);
            setFilterHouseholdOptions(householdPeople);
            let filters = {
                ...filtersData
            }
            if (countryStateMapping) {
                const countryList = Object.keys(countryStateMapping).map((k) => ({ id: k, label: k }));
                setFilterCountryOptions(countryList);

                const statesList = filterStateList(countryList, countryStateMapping);
                setFilterStateOptions(statesList);

                const cityList = filterCityList(countryList, statesList, countryStateMapping);
                setFilterCityOptions(cityList);

                filters = {
                    ...filters,
                    country: countryList,
                    state: statesList,
                    city: cityList,
                    householdPeople,
                };
                setFiltersData(filters);
            }

            await getFilteredData(filters);
        } catch (err) {
            if (err.message === 'You are not authorized') {
                navigate('/page404', { replace: true });
            }
        }
    };

    const filterStateList = (selectedCountry = [], accFilters = countryStateFilters) => {
        let filteredPropertyValue = [];
        let filteredData = [];
        if (!selectedCountry?.length) {
            filteredData = accFilters;
        } else {
            const categoryKeys = selectedCountry.map((k) => k.id);
            filteredData = Object.keys(accFilters)
                .filter((key) => categoryKeys.includes(key))
                .reduce((obj, key) => {
                    obj[key] = accFilters[key];
                    return obj;
                }, {});
        }
        if (filteredData) {
            // eslint-disable-next-line no-restricted-syntax
            for (const [, value] of Object.entries(filteredData)) {
                let filteredData = Object.keys(value);
                filteredData = filteredData.map((a) => ({ id: a, label: a }));
                filteredPropertyValue = [...filteredPropertyValue, ...filteredData];
            }
        }
        return filteredPropertyValue.filter((value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        );
    };

    const filterCityList = (selectedCountry = [], selectedState = [], accFilters = countryStateFilters) => {
        let filteredPropertyValue = [];
        let filteredData = [];
        if (!selectedState?.length) {
            const countryKeys = selectedCountry.map((k) => k.id);
            filteredData = Object.keys(accFilters)
                .filter((key) => countryKeys.includes(key))
                .reduce((obj, key) => {
                    obj[key] = accFilters[key];
                    return obj;
                }, {});
        } else {
            const stateKeys = selectedState.map((k) => k.id);
            filteredData = Object.entries(accFilters).reduce((prev, [, value]) => {
                const filteredData = Object.keys(value)
                    .filter((key) => stateKeys.includes(key))
                    .reduce((obj, key) => {
                        obj[key] = value[key];
                        return obj;
                    }, {});
                if (Object.keys(filteredData).length) {
                    prev = _.mergeWith(prev, filteredData, customizer);
                }
                return prev;
            }, {});
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const [, value] of Object.entries(filteredData)) {
            let filteredData = Object.values(value).flat(Infinity);
            filteredData = filteredData.map((a) => ({ id: a, label: a }));
            filteredPropertyValue = [...filteredPropertyValue, ...filteredData];
        }
        return filteredPropertyValue.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));
    };

    const getFilteredData = async (filtersObj = filtersData) => {
        const filters = {};
        setLoading(true);
        // eslint-disable-next-line no-restricted-syntax, prefer-const
        for (let [key, value] of Object.entries(filtersObj)) {
            if (value) {
                if (['dateFrom', 'dateTo'].includes(key)) {
                    value = fDate(value, 'dd.MM.yyyy');
                }
                if (
                    ['country', 'state', 'city', 'gender', 'ageGroup', 'householdPeople'].includes(key) &&
                    value.length
                ) {
                    const stateNames = value.map((v) => v.id);
                    value = stateNames.join(',');
                }
                if (['aggregatedBy', 'userCount'].includes(key) && value.id) {
                    filters[key] = value.id;
                }
                if (value?.length) {
                    filters[key] = value;
                }
            }
        }
        try {
            const response = await adminDashboard.getAdminDashboardData(filters);
            const { totalSignups, geographicToDate, geographicReportTrend, demographicToDate, demographicReportTrend } = response.data;
            setGeographicToDateData(geographicToDate || []);
            setGeographicTrendData(geographicReportTrend || [])
            setDemographicToDateData(demographicToDate || {});
            setDemographicTrendData(demographicReportTrend || {});
            setTotalSignups(totalSignups || 0);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            showError(t, error);
        }
    };

    useEffect(() => {
        populateFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateFilters = (key, value) => {
        let updatedFilters = {
            ...filtersData,
            [key]: value,
        };
        if (key === 'country') {
            const stateList = filterStateList(value);
            setFilterStateOptions(stateList);

            const cityList = filterCityList(value, stateList);
            setFilterCityOptions(cityList);

            updatedFilters = {
                ...updatedFilters,
                state: stateList,
                city: cityList,
            };
        }
        if (key === 'state') {
            const cityList = filterCityList(updatedFilters?.country, value);
            setFilterCityOptions(cityList);

            updatedFilters = {
                ...updatedFilters,
                city: cityList,
            };
        }
        setFiltersData(updatedFilters);
    };

    return (
        <Page title={t('admin-dashboard.title')}>
            <Grid mb={5}>
                <TopMenu title={t('admin-dashboard.title')} />
                <TopHeading heading={t('admin-dashboard.title')} hideBackLink />
            </Grid>
            <div className="hubPadding hubCommonMargin">
                <Grid container spacing={2} marginTop={0}>
                    <Grid item xl={12} md={12} xs={12}>
                        <Filter
                            filtersData={filtersData}
                            updateFilters={updateFilters}
                            loading={loading}
                            fetchFilteredData={getFilteredData}
                            filterCountryOptions={filterCountryOptions}
                            filterStateOptions={filterStateOptions}
                            filterGenderOptions={genderList}
                            ageGroupOptions={ageGroupOptions}
                            filterHouseholdOptions={filterHouseholdOptions}
                            filterCityOptions={filterCityOptions}
                        />
                    </Grid>
                    <br />
                    <Grid
                        container
                        spacing={4}
                        marginTop={2}
                        className="marginSmTop"
                        alignItems="flex-start"
                        paddingLeft={4}
                    ><b>Total Signups: {totalSignups}</b></Grid>
                    <GeographicTable geographicToDateData={geographicToDateData} />
                    <GeographicChart geographicTrendData={geographicTrendData} country={filtersData?.country} />
                    <DemographicTable demographicToDateData={demographicToDateData} genderList={filtersData?.gender} />
                    <DemographicTrendTable demographicTrendData={demographicTrendData} genderList={filtersData?.gender} />
                </Grid>
            </div>
        </Page>
    );
}
