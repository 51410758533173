import {
    AccordionDetails,
    AccordionSummary,
    Grid,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import Iconify from 'components/Iconify';
import { AccordionStyle, DividerStyle, TableStyle } from './styled-components';

export default function GeographicTable({ geographicToDateData = {} }) {
    const { countryData, totalUsers } = geographicToDateData || { countryData: [], totalUsers: 0 };
    return (
        <Grid
            container
            spacing={4}
            marginTop={2}
            className="marginSmTop"
            justifyContent="center"
            alignItems="flex-start"
            paddingLeft={4}
        >
            <AccordionStyle>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>Geographic Report as at "To" Date</Typography>
                    <Iconify icon="ant-design:plus-circle-outlined" sx={{ minWidth: 22, height: 22 }} />
                    <Iconify icon="akar-icons:circle-minus" sx={{ minWidth: 22, height: 22 }} />
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer sx={{ maxHeight: 500 }}>
                        <TableStyle>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Country</TableCell>
                                    <TableCell>Total</TableCell>
                                    <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                                        Province/State
                                    </TableCell>
                                    <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                                        City
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <DividerStyle />
                            <TableBody>
                                {countryData?.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                            No data found
                                        </TableCell>
                                    </TableRow>
                                )}
                                {countryData?.map((country, countryIndex) => (
                                    <>
                                        {country?.state?.map((state, stateIndex) => {
                                            let showCountryName = true;
                                            return (
                                                <>
                                                    {state?.city?.map((city, cityIndex) => {
                                                        showCountryName = !!(stateIndex === 0 && cityIndex === 0);
                                                        return (
                                                            <TableRow key={countryIndex}>
                                                                <TableCell>
                                                                    {showCountryName ? country.country : <></>}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <b>{showCountryName ? country.count : <></>}</b>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {cityIndex === 0 ? state.state : <></>}
                                                                </TableCell>
                                                                <TableCell style={{ textAlign: 'center' }}>
                                                                    <b>{cityIndex === 0 ? state.count : <></>}</b>
                                                                </TableCell>
                                                                <TableCell>{city.city}</TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        textAlign: 'center',
                                                                        width: '150px',
                                                                    }}
                                                                >
                                                                    {city.count}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                    <TableRow>
                                                        <TableCell colSpan={3}>&nbsp;</TableCell>
                                                        <TableCell>
                                                            {stateIndex === country.state?.length - 1 ? (
                                                                <span
                                                                    style={{
                                                                        textAlign: 'center',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        fontWeight: 'bold',
                                                                        padding: '7px 0 9px 0px',
                                                                        borderTop: 'solid 3px #0F9672',
                                                                        borderBottom: 'solid 3px #0F9672',
                                                                    }}
                                                                >
                                                                    {country.count}
                                                                </span>
                                                            ) : (
                                                                <>&nbsp;</>
                                                            )}
                                                        </TableCell>
                                                        <TableCell>&nbsp;</TableCell>
                                                        <TableCell>
                                                            <span
                                                                style={{
                                                                    textAlign: 'center',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    fontWeight: 'bold',
                                                                    width: '150px',
                                                                    padding: '7px 0 9px 0px',
                                                                    borderTop: 'solid 3px #0F9672',
                                                                    borderBottom: 'solid 3px #0F9672',
                                                                }}
                                                            >
                                                                {state.count}
                                                            </span>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            );
                                        })}
                                        {countryIndex === countryData?.length - 1 && (
                                            <TableRow style={{
                                                fontWeight: 'bold',
                                                padding: '7px 0 9px 0px',
                                                borderTop: 'solid 3px #0F9672',
                                                borderBottom: 'solid 3px #0F9672',
                                            }}>
                                                <TableCell>
                                                    <b>All Countries</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>{totalUsers}</b>
                                                </TableCell>
                                                <TableCell colSpan={4}>&nbsp;</TableCell>
                                            </TableRow>
                                        )}
                                    </>
                                ))}
                            </TableBody>
                        </TableStyle>
                    </TableContainer>
                </AccordionDetails>
            </AccordionStyle>
        </Grid>
    );
}
