// @mui
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Input from '@mui/material/Input';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
// components
import Page from '../../../components/Page';
import { EXCEL_STYLE } from '../constant';
import { ContentStyle, PaymentTitle, HeaderDivStyle, TotalFlowValueSubColumn, HeaderTableStyle, TableStyle, LightTooltip, TotalFlow, BudgetInput, ButtonText, InfoIcon, CalculatorButton } from '../styled-components';
import calculator from '../../../constants/services/calculator';
import { formatDollarValues, removeNumberFormatting, formatZeroValues, downloadExcelHeader, getCurrencySymbol, goBackToMenu } from '../../../utils/calCommonFunction';
import TopMenu from '../../../components/TopMenu';
import TopHeading from '../../../components/TopHeading';

export default function EmbeddedRate() {
    const [debtAmount, setDebtAmount] = useState("");
    const [monthlyPayment, setMonthlyPayment] = useState("");
    const [termsMonth, setTermsMonth] = useState("");
    const [baloonPayment, setBaloonPayment] = useState("");
    const [isLogged, setIsLogged] = useState(false)

    const [embeddedRate, setEmbeddedRate] = useState("");
    const navigate = useNavigate();
    const { t } = useTranslation();
    const currencySymbol = getCurrencySymbol();

    const monthlyArrearsDetails = [
        { label: 'Arrears' },
        { label: 'Advance' },
    ]

    const getEmbeddedRateDetails = async () => {
        const getembeddedRateData = await calculator.getUserCalculator("embedded-rate");
        if (getembeddedRateData.data) {
            const loanJsonData = getembeddedRateData.data?.calcJson;
            setDebtAmount(loanJsonData.debt_amount);
            setMonthlyPayment(loanJsonData.monthly_payment);
            setTermsMonth(loanJsonData.terms_month);
            setBaloonPayment(loanJsonData.baloon_payment);
            setEmbeddedRate(loanJsonData.embedded_rateType)
        }
    }

    useEffect(() => {
        if (sessionStorage.getItem('userData')) {
            const user = JSON.parse(sessionStorage.getItem('userData'));
            if (Object.keys(user).length > 0) {
                setIsLogged(true);
                getEmbeddedRateDetails();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const EmbeddedLoanDetails = () => {
        if (debtAmount !== '' && monthlyPayment !== '' && termsMonth !== '') {
            const debtAmountVal = (Number(removeNumberFormatting(debtAmount)));
            const monthlyPaymentVal = (Number(removeNumberFormatting(monthlyPayment)));
            const termsMonthVal = (Number(removeNumberFormatting(termsMonth)));
            const baloonPaymentVal = (Number(removeNumberFormatting(baloonPayment)));

            const IRRval = [];
            if (debtAmountVal > 0 && monthlyPaymentVal > 0 && termsMonthVal > 0) {
                IRRval.push(-debtAmountVal);
                for (let i = 0; i < termsMonthVal; i += 1) {
                    IRRval.push(monthlyPaymentVal);
                }
                if (baloonPaymentVal) {
                    IRRval[IRRval.length - 1] += baloonPaymentVal;
                }
                const IRR = IRRCalc(IRRval, 0.1) * 12;
                setEmbeddedRate(Number(IRR).toFixed(2).toLocaleString("en-US", { minimumFractionDigits: 2 }));
            }
        } else {
            toast.error("Please fill all the details");
        }
    }

    const handleChanged = (e) => {
        const { name, value } = e.target;
        if (name === 'monthly_payment') {
            setMonthlyPayment(value);
        }
        if (name === 'terms_month') {
            setTermsMonth(value.toString().slice(0, 3));
        }
        if (name === 'baloon_payment') {
            setBaloonPayment(value);
        }
    }

    const resetFields = () => {
        setDebtAmount("");
        setMonthlyPayment("");
        setTermsMonth("");
        setBaloonPayment("");
        setEmbeddedRate("");
    }

    const saveEmbeddedData = async () => {
        try {
            const postData = {
                "debt_amount": debtAmount,
                "monthly_payment": monthlyPayment,
                "terms_month": termsMonth,
                "baloon_payment": baloonPayment,
                "embedded_rateType": embeddedRate
            }
            const embeddedDataObj = {
                "calcType": "embedded-rate",
                "calcJson": postData
            };
            await calculator.addUserCalculator(embeddedDataObj);
            toast.success('Calculation saved successfully');
        } catch (error) {
            toast.error(error.message);
        }
    }

    const IRRCalc = (CArray) => {
        let r = 0
        let min = -1.0;
        let max = 10000.0;
        let NPV = 0;
        let guess = 0;
        do {
            guess = (min + max) / 2;
            NPV = 0;
            for (let j = 0; j < CArray.length; j += 1) {
                // eslint-disable-next-line
                NPV += CArray[j] / Math.pow((1 + guess), j);
            }
            if (NPV > 0) {
                min = guess;
            }
            else {
                max = guess;
            }
            r += 1
        } while (r < 100);
        return guess * 100
    }

    const exportExcel = () => {
        const postData = [
            {
                "Description": "Debt amount",
                "Value": `${currencySymbol} ${debtAmount}`
            },
            {
                "Description": "Monthly payment",
                "Value": `${currencySymbol} ${monthlyPayment}`
            },
            {
                "Description": "Original term in months",
                "Value": termsMonth
            },
            {
                "Description": "Balloon payment",
                "Value": `${currencySymbol} ${baloonPayment}`
            },
            {
                "Description": "",
                "Value": ""
            },
            {
                "Description": "Embedded Rate",
                "Value": `${embeddedRate} %`
            }
        ];
        const style = [
            {
                "col": 'A1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'B1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'A8',
                "style": EXCEL_STYLE.boldColor
            },
            {
                "col": 'B8',
                "style": EXCEL_STYLE.boldColorRight
            },
        ]
        const cols = [{ width: 25 }, { width: 20 }];
        const merges = [
            { s: { r: 6, c: 0 }, e: { r: 6, c: 1 } },
        ]
        downloadExcelHeader(postData, style, cols, merges, 'EmbeddedRate', 2, ['B'], false);
    }

    return (
        <Page title={"MoolahMate's embedded rate calculator makes finances easy"} description={"Calculate the embedded rate of your debt simply and easily with MoolahMate's embedded rate calculator. MoolahMate makes your finances easy."} className={isLogged ? '' : 'homeCalcultorsStyle'}>
            {isLogged && <TopMenu title={t('embedded-rate.calculator')} subText="Need Help?" hideBellIcon />}
            <TopHeading heading={t('embedded-rate.subtitle')} headingCenter={!isLogged} hideBackLink={!isLogged} />
            <div className={isLogged ? 'contentStyle' : 'topCalcPadding'}>
                <Grid container spacing={0} pt={0} justifyContent="center" alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={12}>
                        <HeaderDivStyle>
                            <PaymentTitle>{t('embedded-rate.description')}</PaymentTitle>
                            <div className="mobileOverFlowDiv">
                                <HeaderTableStyle>
                                    <tbody>
                                        <tr>
                                            <td>{t('embedded-rate.debt-amount')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="debt_amount"
                                                    value={debtAmount}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setDebtAmount(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setDebtAmount(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('embedded-rate.monthly-payment')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    placeholder="0"
                                                    name="monthly_payment"
                                                    value={monthlyPayment}
                                                    onBlur={(e) => {
                                                        setMonthlyPayment(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyPayment(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('embedded-rate.original-term-in-months')}</td>
                                            <BudgetInput>
                                                <span style={{ padding: '5px' }}>{''}</span>
                                                <Input
                                                    type="text"
                                                    placeholder="0"
                                                    name="terms_month"
                                                    value={termsMonth}
                                                    onChange={handleChanged}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t('embedded-rate.baloon-payment')}
                                                <LightTooltip
                                                    title={
                                                        <>
                                                            <p>{t('embedded-rate.due-amount')}</p>
                                                        </>
                                                    }
                                                >
                                                    <InfoIcon src="/static/linkFinancial/info-icon.svg" alt="" />
                                                </LightTooltip>
                                            </td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    placeholder="0"
                                                    name="baloon_payment"
                                                    value={baloonPayment}
                                                    onBlur={(e) => {
                                                        setBaloonPayment(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setBaloonPayment(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{''}</td>
                                            <td style={{ textAlign: 'end' }}>
                                                <CalculatorButton
                                                    fullWidth
                                                    size="large"
                                                    variant="contained"
                                                    className="loadingButtonStyle2"
                                                    onClick={() => EmbeddedLoanDetails()}
                                                >
                                                    Calculate
                                                </CalculatorButton>
                                            </td>
                                        </tr>
                                    </tbody>
                                </HeaderTableStyle>
                            </div>
                        </HeaderDivStyle>
                        <div className="divTableStyle" style={{ marginTop: '0px' }}>
                            <TableStyle>
                                <tbody>
                                    <tr>
                                        <TotalFlow>{t('embedded-rate.embedded-rate')}</TotalFlow>
                                        <TotalFlowValueSubColumn>
                                            <div style={{ textAlign: 'end' }}>
                                                {embeddedRate === '' ? 0 : embeddedRate}%
                                            </div>
                                        </TotalFlowValueSubColumn>
                                    </tr>
                                </tbody>
                            </TableStyle>
                        </div>
                    </Grid>
                </Grid>
                <ButtonText style={isLogged ? {} : { justifyContent: 'center' }}>
                    {isLogged && (
                        <div className='backButtonShowStyle'>
                            {goBackToMenu(navigate)}
                        </div>
                    )}
                    <div className='centerAlignStyle'>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            // disabled={}
                            className="loadingButtonStyle2"
                            onClick={resetFields}
                            style={isLogged ? {} : { maxWidth: '200px' }}
                        >
                            {t('embedded-rate.reset')}
                        </LoadingButton>
                        {isLogged && (
                            <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                variant="outlined"
                                className="loadingButtonStyle1"
                                onClick={saveEmbeddedData}
                            >
                                {t('embedded-rate.save')}
                            </LoadingButton>
                        )}
                        {isLogged && (
                            <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                className="loadingButtonStyle2"
                                onClick={exportExcel}
                            >
                                {t('embedded-rate.export')}
                            </LoadingButton>
                        )}
                    </div>
                </ButtonText>
            </div>
        </Page>
    );
}
