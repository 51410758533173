import { Link as RouterLink } from 'react-router-dom';
import { Link, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useResponsive from '../../hooks/useResponsive';
import Page from '../../components/Page';
import TopMenu from '../../components/TopMenu';
import SmallLogo from '../../components/SmallLogo';
import RegisterForm from '../../sections/auth/register/RegisterForm';
import { OnboardingDivStyle, SectionStyle, SmallLogoStyle, FormDivStyle } from './styled-components';

export default function Register() {
    const { t } = useTranslation();
    const smUp = useResponsive('up', 'sm');
    return (
        <Page title={"Register for a MoolahMate account: your personal money manager"} description={"Register for a MoolahMate account and start saving. Sign up today to see how you can save more, spend less and reduce your financial stress"}>
            <div>
                <SectionStyle>
                    <SmallLogoStyle>
                        <SmallLogo sx={{ width: 40, height: 'auto', display: 'flex', justifyContent:'center' }} />
                    </SmallLogoStyle>
                </SectionStyle>
                <Grid container justifyContent={'center'} spacing={2} marginLeft={0} width={'100%'} marginTop={0}> 
                    <Grid item xs={12} className='customPaddingStyle'>
                        <TopMenu title='Register' hideHelp />
                    </Grid>
                    <Grid item xl={6} lg={7} md={9} xs={12} className='extraPaddingStyle'>
                        <OnboardingDivStyle>
                            <FormDivStyle>
                                <RegisterForm />
                                {!smUp && (
                                    <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
                                        {t('register-page.already-have-account')}{' '}
                                        <Link variant="subtitle2" to="/login" component={RouterLink}>
                                            {t('register-page.login')}
                                        </Link>
                                    </Typography>
                                )}
                            </FormDivStyle>
                        </OnboardingDivStyle>
                    </Grid>
                </Grid>
            </div>
        </Page>
    );
}
