// @mui
import { Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import dashboard from '../../../constants/services/dashboard';
// components
import Bargraph from '../Graphs/Bargraph';
import { exportCustomExcel, getCurrencySymbol, getPositiveNumber } from '../../../utils/calCommonFunction';
import {
    TableStyle,
    GridExtraSpacing,
    ExecutiveTbStart,
    ExecutiveTitleStyle,
    ExecutiveTbEnd,
    ExecutiveDivStyle,
    ExecutiveDivSpacing,
    NoDataTd,
    LoaderGif,
    ButtonDivStyle,
    TotalAmountDiv,
    ExecutiveTdAlignEnd,
} from './styled-component';
import { EXCEL_STYLE } from '../../calculators/constant';

export default function ExecutiveIncDecTable({ getinvestmentChartData }) {
    const [tableLoader, setTableLoader] = useState(false);
    const [showExecutive, setShowExecutive] = useState(true);
    const [cashInvestmentYear, setCashInvestmentYear] = useState([]);
    const [cashInvestmentMonth, setCashInvestmentMonth] = useState([]);
    const [incdescCategoryYearData, setIncdescCategoryYearData] = useState([]);
    const [incdescCategoryMonthData, setIncdescCategoryMonthData] = useState([]);

    // chart
    const [incDescYearChart, setIncDescYearChart] = useState([]);
    const [incDescMonthChart, setIncDescMonthChart] = useState([]);

    const { t } = useTranslation();
    const currencySymbol = getCurrencySymbol();

    const getExecutiveSummaryData = async () => {
        const targetMonth = `incdescinvestment?type=month`;
        const targetYearge = `incdescinvestment?type=year`;
        const incdescCategoryMonth = `incdesccategory?type=month`;
        const incdescCategoryYear = `incdesccategory?type=year`;

        let getInvestmentByMonth;
        let getInvestmentByYear;
        let incdescCategoryByMonth;
        let incdescCategoryByYear;

        try {
            getInvestmentByMonth = await getIncInvestmentData(targetMonth);
            setCashInvestmentMonth(getInvestmentByMonth);
        } catch (error) {
            console.log('error', error);
        }

        try {
            getInvestmentByYear = await getIncInvestmentData(targetYearge);
            setCashInvestmentYear(getInvestmentByYear);
        } catch (error) {
            console.log('error', error);
        }

        try {
            incdescCategoryByMonth = await getIncInvestmentData(incdescCategoryMonth);
            setIncdescCategoryMonthData(incdescCategoryByMonth);
        } catch (error) {
            console.log('error', error);
        }

        try {
            incdescCategoryByYear = await getIncInvestmentData(incdescCategoryYear);
            setIncdescCategoryYearData(incdescCategoryByYear);
        } catch (error) {
            console.log('error', error);
        }

        // chart state
        const incDescArr = getInvestmentByYear.concat(incdescCategoryByYear);
        const incDescMonthArr = getInvestmentByMonth.concat(incdescCategoryByMonth);

        const incDescChartYearObj = getinvestmentChartData(incDescArr, 'Increase (Decrease) This Year');
        const incDescChartMonthObj = getinvestmentChartData(incDescMonthArr, 'Increase (Decrease) This Month');
        setIncDescYearChart(incDescChartYearObj);
        setIncDescMonthChart(incDescChartMonthObj);
    };

    const getIncInvestmentData = async (selectdata) => {
        setTableLoader(true);
        try {
            const response = await dashboard.getDashboardCategoryDetails(selectdata);
            const data = await response.data;
            setTableLoader(false);
            return data;
        } catch (error) {
            console.log(error);
            setTableLoader(false);
        }
    };

    const exportExcel = () => {
        const investData = cashInvestmentYear.map((item) => ({
            Category: item.name,
            Amount: `${currencySymbol}${getPositiveNumber(item.amount, true, false)}`,
            '%': item.percentage ? `${getPositiveNumber(item.percentage, true, false)}%` : '-',
        }));

        const incDecData = incdescCategoryYearData.map((item) => ({
            Category: item.name,
            Amount: `${currencySymbol}${getPositiveNumber(item.amount, true, false)}`,
            '%': item.percentage ? `${getPositiveNumber(item.percentage, true, false)}%` : '-',
        }));

        const increaseYear = [...investData, ...incDecData];

        increaseYear.unshift(
            { Category: 'Increase (Decrease) This Year', Amount: '', '%': '' },
            { Category: 'Category', Amount: 'Amount', '%': '%' }
        );

        const investDataMonth = cashInvestmentMonth.map((item) => ({
            Category: item.name,
            Amount: `${currencySymbol}${getPositiveNumber(item.amount, true, false)}`,
            '%': item.percentage ? `${getPositiveNumber(item.percentage, true, false)}%` : '-',
        }));

        const incDecDataMonth = incdescCategoryMonthData.map((item) => ({
            Category: item.name,
            Amount: `${currencySymbol}${getPositiveNumber(item.amount, true, false)}`,
            '%': item.percentage ? `${getPositiveNumber(item.percentage, true, false)}%` : '-',
        }));

        const increaseMonth = [...investDataMonth, ...incDecDataMonth];

        increaseMonth.unshift(
            { Category: 'Increase (Decrease) This Month', Amount: '', '%': '' },
            { Category: 'Category', Amount: 'Amount', '%': '%' }
        );

        const style = [
            {
                col: 'A1',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'E1',
                style: EXCEL_STYLE.boldHeader,
            },
        ];

        const cols = [
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 10 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
        ];

        const merges = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } },
            { s: { r: 0, c: 4 }, e: { r: 0, c: 6 } },
        ];

        exportCustomExcel(
            [increaseYear, increaseMonth],
            style,
            cols,
            merges,
            'IncDecTable',
            7,
            ['I'],
            [true, true],
            [-1, 'E1']
        );
    };

    useEffect(() => {
        getExecutiveSummaryData();
    }, []);

    return (
        <>
            {showExecutive ? (
                <ExecutiveDivStyle>
                    <ExecutiveDivSpacing style={{ padding: '24px 0px' }}>
                        <Grid
                            container
                            spacing={4}
                            justifyContent="center"
                            alignItems="flex-start"
                            style={{ margin: '0px', width: '100%' }}
                        >
                            <GridExtraSpacing item xl={6} lg={6} md={12} xs={12} className="overFlowTable">
                                <ExecutiveTitleStyle>{t('executive-summary.increase-decrease')}</ExecutiveTitleStyle>
                                <ExecutiveTitleStyle>{t('executive-summary.this-year')}</ExecutiveTitleStyle>
                                <TableStyle>
                                    <thead>
                                        <tr>
                                            <ExecutiveTbStart style={{ width: '40%' }}>
                                                {t('executive-summary.category')}
                                            </ExecutiveTbStart>
                                            <th style={{ textAlign: 'end', width: '30%' }}>
                                                {t('executive-summary.amount')}
                                            </th>
                                            <th style={{ textAlign: 'end', width: '30%' }}>%</th>
                                        </tr>
                                    </thead>
                                    {tableLoader ? (
                                        <tbody>
                                            <tr>
                                                <td colSpan={4}>
                                                    <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            {cashInvestmentYear?.length > 0 ? (
                                                <>
                                                    {cashInvestmentYear?.map((item, indx) => (
                                                        <tr key={indx}>
                                                            <td>{item.name}</td>
                                                            <ExecutiveTbEnd>
                                                                <TotalAmountDiv>
                                                                    {currencySymbol}{' '}
                                                                    <span>
                                                                        {getPositiveNumber(item.amount, true, false)}
                                                                    </span>
                                                                </TotalAmountDiv>
                                                            </ExecutiveTbEnd>
                                                            <ExecutiveTdAlignEnd>
                                                                {item.percentage
                                                                    ? `${getPositiveNumber(
                                                                        item.percentage,
                                                                        true,
                                                                        false
                                                                    )} %`
                                                                    : '-'}
                                                            </ExecutiveTdAlignEnd>
                                                        </tr>
                                                    ))}
                                                    {incdescCategoryYearData?.map((item, indx) => (
                                                        <tr key={indx}>
                                                            <td>{item.name}</td>
                                                            <ExecutiveTbEnd>
                                                                <TotalAmountDiv>
                                                                    {currencySymbol}{' '}
                                                                    <span>
                                                                        {getPositiveNumber(item.amount, true, false)}
                                                                    </span>
                                                                </TotalAmountDiv>
                                                            </ExecutiveTbEnd>
                                                            <ExecutiveTdAlignEnd>
                                                                {item.percentage
                                                                    ? `${getPositiveNumber(
                                                                        item.percentage,
                                                                        true,
                                                                        false
                                                                    )} %`
                                                                    : '-'}
                                                            </ExecutiveTdAlignEnd>
                                                        </tr>
                                                    ))}
                                                </>
                                            ) : (
                                                <tr>
                                                    <NoDataTd colSpan={3}>
                                                        {t('executive-summary.no-data-found')}
                                                    </NoDataTd>
                                                </tr>
                                            )}
                                        </tbody>
                                    )}
                                </TableStyle>
                            </GridExtraSpacing>
                            <hr className="middleBorderStyle" />
                            <GridExtraSpacing item xl={6} lg={6} md={12} xs={12} className="overFlowTable">
                                <ExecutiveTitleStyle>{t('executive-summary.increase-decrease')}</ExecutiveTitleStyle>
                                <ExecutiveTitleStyle>{t('executive-summary.this-month')}</ExecutiveTitleStyle>
                                <TableStyle>
                                    <thead>
                                        <tr>
                                            <ExecutiveTbStart style={{ width: '40%' }}>
                                                {t('executive-summary.category')}
                                            </ExecutiveTbStart>
                                            <th style={{ textAlign: 'end', width: '30%' }}>
                                                {t('executive-summary.amount')}
                                            </th>
                                            <th style={{ textAlign: 'end', width: '30%' }}>%</th>
                                        </tr>
                                    </thead>
                                    {tableLoader ? (
                                        <tbody>
                                            <tr>
                                                <td colSpan={4}>
                                                    <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            {cashInvestmentMonth?.length > 0 ? (
                                                <>
                                                    {cashInvestmentMonth?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.name}</td>
                                                            <ExecutiveTbEnd>
                                                                <TotalAmountDiv>
                                                                    {currencySymbol}{' '}
                                                                    <span>
                                                                        {getPositiveNumber(item.amount, true, false)}
                                                                    </span>
                                                                </TotalAmountDiv>
                                                            </ExecutiveTbEnd>
                                                            <ExecutiveTdAlignEnd>
                                                                {item.percentage
                                                                    ? `${getPositiveNumber(
                                                                        item.percentage,
                                                                        true,
                                                                        false
                                                                    )} %`
                                                                    : '-'}
                                                            </ExecutiveTdAlignEnd>
                                                        </tr>
                                                    ))}
                                                    {incdescCategoryMonthData?.map((item, indx) => (
                                                        <tr key={indx}>
                                                            <td>{item.name}</td>
                                                            <ExecutiveTbEnd>
                                                                <TotalAmountDiv>
                                                                    {currencySymbol}{' '}
                                                                    <span>
                                                                        {getPositiveNumber(item.amount, true, false)}
                                                                    </span>
                                                                </TotalAmountDiv>
                                                            </ExecutiveTbEnd>
                                                            <ExecutiveTdAlignEnd>
                                                                {item.percentage
                                                                    ? `${getPositiveNumber(
                                                                        item.percentage,
                                                                        true,
                                                                        false
                                                                    )} %`
                                                                    : '-'}
                                                            </ExecutiveTdAlignEnd>
                                                        </tr>
                                                    ))}
                                                </>
                                            ) : (
                                                <tr>
                                                    <NoDataTd colSpan={3}>
                                                        {t('executive-summary.no-data-found')}
                                                    </NoDataTd>
                                                </tr>
                                            )}
                                        </tbody>
                                    )}
                                </TableStyle>
                            </GridExtraSpacing>
                        </Grid>
                    </ExecutiveDivSpacing>
                </ExecutiveDivStyle>
            ) : (
                <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{ margin: '0px', width: '100%' }}
                >
                    <Grid item xs={12} md={12} style={{ padding: '0px 0px 0px 0px' }}>
                        <Bargraph chartData={incDescYearChart} currencyCode={currencySymbol} />
                    </Grid>
                    <Grid item xs={12} md={12} style={{ padding: '0px 0px 0px 0px' }}>
                        <Bargraph chartData={incDescMonthChart} currencyCode={currencySymbol} />
                    </Grid>
                </Grid>
            )}
            <ButtonDivStyle>
                <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    // disabled={}
                    onClick={() => setShowExecutive(!showExecutive)}
                    className="loadingButtonStyle2"
                >
                    {showExecutive ? t('executive-summary.graph') : t('executive-summary.view-table')}
                </LoadingButton>
                <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    // disabled={}
                    onClick={exportExcel}
                    className="loadingButtonStyle2"
                >
                    Export
                </LoadingButton>
            </ButtonDivStyle>
        </>
    );
}
