import { Grid } from '@mui/material';
import image from 'assets/image';
import AboutBanner from './banner/AboutBanner'
import ClientReview from '../home-page/clientReview/ClientReview';
import MoneyManagement from './moneyManagement';
import AccountSetup from './accountSetup';
import GetMore2 from '../home-page/getMore/GetMore2';
import './style.css'
import Page from '../../components/Page';

export default function HowItWorks() {
    return (
        <Page title={'Save more, spend less & reduce financial stress: MoolahMate'} description={'MoolahMate is an easy to use personal money management platform to help you plan your financial journey. Sign up today to get started.'}>
            <AboutBanner />
            <MoneyManagement/>
            <AccountSetup/>
            <Grid py={5}>
                <ClientReview/>
            </Grid>
            <GetMore2 imageUrl={image.footerGraphicAnimation} orderType={1}/>
        </Page>
    );
}
