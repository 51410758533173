import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export default function InfoProvider(props) {
    const { t } = useTranslation();

    return (
        <div className="security-points">
            <div>
                <div className="pointerStyle">
                    <span>{props.pointerLineOne}</span>
                </div>
            </div>
            <div>
                <Typography className="subHeadingPointerStyle" variant="body1">
                    {t(props.pointerLineTwo)}
                </Typography>
            </div>
        </div>
    );
}
