import { Button } from "@mui/material";
import DialogAtom from "components/dialog/Dialog";
import user from "constants/services/user";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { showError } from "utils/toast";

export default function CancelButton({ id, getUserSubscriptionDetails }) {
    const { t } = useTranslation();
    const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onDialogAction = async (buttonKey) => {
        try {
            if (buttonKey === 'cancel') {
                setShowCancelSubscriptionModal(false);
            } else {
                setIsLoading(true);
                await user.cancelSubscription(id);
                setShowCancelSubscriptionModal(false);
                setIsLoading(false);
                getUserSubscriptionDetails();
            }
        } catch (error) {
            showError(t, error);
            setIsLoading(false);
            setShowCancelSubscriptionModal(false);
        }
    };

    const getCancelSubscriptionDialog = () => (
        <DialogAtom
            open
            maxWidth="sm"
            dialogTitle={t('subscription.cancel-subscription')}
            content={<>{t('subscription.cancel-subscription-message')}</>}
            onDialogAction={onDialogAction}
            saveButtonLabel={t('subscription.cancel-subscription')}
            isLoading={isLoading}
        />
    );

    return (
        <>
            <Button variant="contained" color="error" onClick={() => setShowCancelSubscriptionModal(true)} style={{ width: 'fit-content' }}>
                {t('subscription.cancel-subscription')}
            </Button>
            {showCancelSubscriptionModal && getCancelSubscriptionDialog()}
        </>
    )
}