import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import image from 'assets/image';
import AppDownloadIcons from 'components/app-download-icons/AppDownloadIcons';
import PageHeadingStyle from '../../../components/PageHeadingStyle';

export default function HomePageBanner() {
    const { t } = useTranslation();
    return (
        <Grid className='sectionPadding bgColor'>
            <Grid container spacing={2} pt={6} className='mdPaddingStyle'>
                <Grid item xl={5} md={6} justifyContent={'center'} className='homeBannerStyle'>
                    <PageHeadingStyle
                        headLineOne={t('home-page.headline-1')}
                        headLineTwo={t('home-page.subtitle-1')}
                        headLineThree={t('home-page.subtitle-info-1')}
                        headingButton1={t('common-translation.Free Trial')}
                    />
                    <AppDownloadIcons />
                </Grid>
                <Grid item xl={7} md={6} sx={{ mt: 4 }}>
                    <img src={image.mainBannerAnimation} alt="Header logo" className='imageMarginStyle' style={{ width: 685 }}/>
                </Grid>
            </Grid>
        </Grid>
    );
}
