
import Page from '../../components/Page';
import Benefits from './benefits/Benefits';
import EverythingYouNeed from './everythingYouNeed/EverythingYouNeed';
import GetSmarter from './get-smarter/GetSmarter';
import InsightfulReporting from './insightfulReporting/InsightfulReporting';
import MateMoolahMate from './mateMoolahMate/MateMoolahMate';

export default function WhatYouGet() {
    return (
        <Page title={"Discover the many benefits of joining MoolahMate"} description={"MoolahMate is more than just a financial app: it's a long-term financial advisor, planner and tracker. A better financial future is possible"}>
            <EverythingYouNeed />
            <MateMoolahMate />
            <InsightfulReporting />
            <GetSmarter />
            <Benefits />
        </Page>
    );
}
