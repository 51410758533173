import { Grid, List, Typography } from '@mui/material';
import debounce from 'lodash.debounce';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import clone from 'rfdc/default';
import { InfoIcon, LightTooltip } from 'theme/common-styles';
import LinkedAccountInfo from 'pages/profile/financial-institution/LinkedAccountInfo';
import { AuthContext } from 'contexts/AuthProvider';
import '../../assets/css/common.css';
import videoURL from 'constants/helpVideoUrl';
import DialogAtom from 'components/dialog/Dialog';
import Page from '../../components/Page';
import TopHeading from '../../components/TopHeading';
import TopMenu from '../../components/TopMenu';
import { getSearchValues } from '../../utils/calCommonFunction';
import { getHubCards } from './constant';
import './style.css';

export default function Welcome() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { auth, setAuth } = useContext(AuthContext);

    const hubMenuItems = getHubCards(t);
    const [menuItems, setMenuItems] = useState(hubMenuItems);
    const [viewStyle, setViewStyle] = useState('grid');
    const [searchValue, setSearchValue] = useState('');
    const searchRef = useRef();
    const [subscriptionExpiryDialog, setSubscriptionExpiryDialog] = useState(
        !!(
            auth?.subscriptionExpiryDuration !== null &&
            auth?.subscriptionExpiryDuration >= 0 &&
            auth?.subscriptionExpiryDuration <= 15
        )
    );

    const NoDataFound = () => (
        <Typography variant="body1" className="noDataFoundHub">
            No Data Found
        </Typography>
    );

    const getDashboardGirdView = (record) => {
        if (!record.length) {
            return <NoDataFound />;
        }
        return record.map((item, key) => (
            <Grid key={key} item lg={3} md={4} sm={6} xs={12}>
                <Link to={item.path} style={{ textDecoration: 'none' }}>
                    <div
                        className="hubCardStyle"
                        style={{ backgroundColor: item.title === 'Suggestion Box' && '#FCFBF8' }}
                    >
                        <div className="dashboardFlex">
                            <img src={item.icon} className="dashboardIcon" alt="" />
                            {item.helpVideo && (
                                <LightTooltip
                                    title={
                                        <>
                                            <p>{t('help-video.tooltip')}</p>
                                        </>
                                    }
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(item.helpVideo, '_blank');
                                    }}
                                >
                                    <InfoIcon
                                        src="/static/video-solid.svg"
                                        alt=""
                                        style={{ marginLeft: 5, marginTop: 6 }}
                                    />
                                </LightTooltip>
                            )}
                        </div>
                        <Typography variant="body1" className="descriptionHeading">
                            {item.title}
                        </Typography>
                        <Typography className="descriptionText" variant="body1" title={item.description}>
                            {item.description}.
                        </Typography>
                    </div>
                </Link>
            </Grid>
        ));
    };

    const getDashboardListView = (record) => {
        if (!record.length) {
            return <NoDataFound />;
        }
        return (
            <div className="listViewStyle">
                {record.map((item, indx) => (
                    <List key={indx}>
                        <Link to={item.path} style={{ textDecoration: 'none' }}>
                            <div
                                className="hubCardStyle listDisplayStyle flexDisplay"
                                style={{ justifyContent: 'space-between' }}
                            >
                                <div className="flexDisplay">
                                    <div className="dashboardFlex">
                                        <img src={item.icon} className="dashboardIcon" alt={item.title} />
                                    </div>
                                    <div>
                                        <Typography variant="body1" className="descriptionHeading">
                                            {item.title}
                                        </Typography>
                                        <Typography className="descriptionText" variant="body1">
                                            {item.description}.
                                        </Typography>
                                    </div>
                                </div>
                                {item.helpVideo && (
                                    <LightTooltip
                                        title={
                                            <>
                                                <p>{t('help-video.tooltip')}</p>
                                            </>
                                        }
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(item.helpVideo, '_blank');
                                        }}
                                    >
                                        <InfoIcon
                                            src="/static/video-solid.svg"
                                            alt=""
                                            style={{ marginLeft: 5, marginTop: 6 }}
                                        />
                                    </LightTooltip>
                                )}
                            </div>
                        </Link>
                    </List>
                ))}
            </div>
        );
    };

    const getSearchResultDetails = () => {
        if (searchValue) {
            const clonedMenuItem = clone(hubMenuItems);
            Object.keys(clonedMenuItem).forEach((key) => {
                const filteredData = getSearchValues(clonedMenuItem[key], searchValue);
                clonedMenuItem[key] = filteredData;
            });
            setMenuItems(clonedMenuItem);
        } else {
            setMenuItems(hubMenuItems);
        }
    };

    const deboundHubSearch = debounce(() => {
        getSearchResultDetails();
    }, 500);

    useEffect(() => {
        deboundHubSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    const personalInfo = JSON.parse(sessionStorage.getItem('userData'));

    const onDialogAction = (buttonKey) => {
        if (buttonKey === 'cancel') {
            // leave blank
        } else {
            // setShowDialog(false);
        }

        const userSessObj = JSON.parse(sessionStorage.getItem('userData'));
        userSessObj.showDashboardAccountDialog = false;
        sessionStorage.setItem('userData', JSON.stringify(userSessObj));
        setAuth(userSessObj);
    };

    const getAccountActionModal = () => (
        <DialogAtom
            open
            dialogTitle={t('welcome.account-action-modal-title')}
            content={<LinkedAccountInfo linkedInstitutions={auth.failedAccounts} hideDeleteActions />}
            onDialogAction={onDialogAction}
            hideSave
        />
    );

    const onSubscriptionExpiryDialogAction = (buttonKey) => {
        const userSessObj = JSON.parse(sessionStorage.getItem('userData'));
        userSessObj.subscriptionExpiryDuration = null;
        sessionStorage.setItem('userData', JSON.stringify(userSessObj));
        setAuth(userSessObj);
        setSubscriptionExpiryDialog(false);
        if (buttonKey === 'cancel') {
            //
        } else {
            navigate('/subscribe', { replace: true });
        }
    };

    const getSubscriptionExpiryModal = () => (
        <DialogAtom
            open
            dialogTitle={t('welcome.subscription-reminder-title')}
            content={
                auth.subscriptionExpiryDuration > 0
                    ? t('welcome.subscription-reminder-message', { noOfDays: auth.subscriptionExpiryDuration })
                    : t('welcome.subscription-reminder-message-today')
            }
            onDialogAction={onSubscriptionExpiryDialogAction}
            saveButtonLabel={t('welcome.subscribe')}
        />
    );

    return (
        <Page title="Home Hub">
            <Grid container spacing={2} mt={0} className="smMobileWidth">
                <TopMenu title={`${personalInfo?.firstName}'s Home Hub`} videoLink={videoURL.homeHub} />
                <TopHeading
                    heading="Let's Go!"
                    setSearchValue={setSearchValue}
                    searchValue={searchValue}
                    searchRef={searchRef}
                    setViewStyle={setViewStyle}
                    viewStyle={viewStyle}
                    showSearch
                    hideBackLink
                />

                <Grid container spacing={2} mt={0} className="hubPadding hubCommonPadding">
                    {Object.keys(menuItems).map((menuItem, index) => (
                        <>
                            <Grid item lg={12} md={12} sm={12} key={index} style={{ paddingTop: index >= 1 ? 32 : 16 }}>
                                <Typography variant="h6" className="dashboardSubHeading">
                                    {menuItem}
                                </Typography>
                            </Grid>
                            {viewStyle === 'grid'
                                ? getDashboardGirdView(menuItems[menuItem])
                                : getDashboardListView(menuItems[menuItem])}
                        </>
                    ))}
                </Grid>
            </Grid>
            {auth?.showDashboardAccountDialog && getAccountActionModal()}
            {subscriptionExpiryDialog && getSubscriptionExpiryModal()}
        </Page>
    );
}
