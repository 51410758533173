import { Grid, Typography } from '@mui/material';
import DialogAtom from 'components/dialog/Dialog';
import videoURL from 'constants/helpVideoUrl';
import transaction from 'constants/services/transaction';
import user from 'constants/services/user';
import moment from 'moment';
import { EXCEL_STYLE } from 'pages/calculators/constant';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { exportCustomExcel } from 'utils/calCommonFunction';
import { fDate } from 'utils/formatTime';
import { showError } from 'utils/toast';
import Page from '../../components/Page';
import TopHeading from '../../components/TopHeading';
import TopMenu from '../../components/TopMenu';
import TransactionEditDialog from './edit-dialog/TransactionEditDialog';
import Filter from './filter/Filter';
import Table from './table/Table';

const DEFAULT_FILTERS = {
    dateFrom: null,
    dateTo: null,
    amountFrom: '',
    amountTo: '',
    category: [],
    subCategory: [],
    payee: '',
    isReviewed: false,
    transactionType: { id: '', label: 'All' },
};

const getFiltersFromStorage = () => {
    const filters = localStorage.getItem('transactionFilters');
    if (!filters) {
        localStorage.setItem('transactionFilters', JSON.stringify(DEFAULT_FILTERS));
        return DEFAULT_FILTERS;
    }
    return JSON.parse(filters);
};

export default function DownloadedTransactions() {
    const { t } = useTranslation();
    const [accountFilters, setAccountFilters] = useState({});
    const [transactions, setTransactions] = useState([]);
    const [filtersData, setFiltersData] = useState(getFiltersFromStorage());
    const [loading, setLoading] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [userCategories, setUserCategories] = useState([]);
    const [editTransaction, setEditTransaction] = useState(null);
    const [splitConfirmDialog, setSplitConfirmDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [filterCategoryOptions, setFilterCategoryOptions] = useState([]);
    const [filterSubCategoryOptions, setFilterSubCategoryOptions] = useState([]);

    const getFilters = async () => {
        const response = await transaction.getTransactionFilters();

        const filters = response?.transactionFilters || {};
        setAccountFilters(filters);
        if (filters) {
            let accTypes = Object.keys(filters).map((k) => ({ id: k, label: k }));
            setFilterCategoryOptions(accTypes);
            if (!filtersData?.category?.length && !filtersData?.subCategory?.length) {
                accTypes = accTypes.filter((f) => ['Banks', 'Credit Cards'].includes(f.label))
                const filteredSubCatsData = filterSubCategories(
                    accTypes,
                    filters
                );
                setFiltersData({
                    ...filtersData,
                    category: accTypes,
                    subCategory: filteredSubCatsData,
                });
            }
        }

        await fetchTransactions();
    };

    const fetchTransactions = async () => {
        const filters = {};
        setLoading(true);
        // eslint-disable-next-line no-restricted-syntax, prefer-const
        for (let [key, value] of Object.entries(filtersData)) {
            if (value) {
                if (key === 'dateFrom' || key === 'dateTo') {
                    value = fDate(value, 'dd.MM.yyyy');
                }
                if (key === 'category' && (!filtersData.subCategory || !filtersData.subCategory?.length)) {
                    const filteredCategories = value.map((v) => v.id);
                    const filteredData = Object.keys(accountFilters)
                        ?.filter((key) => filteredCategories.includes(key))
                        .reduce((obj, key) => {
                            obj[key] = accountFilters[key];
                            return obj;
                        }, {});
                    if (filteredData) {
                        const filteredAccounts = [
                            ...Object.values(filteredData)
                                .flat()
                                .map((a) => a.accountId),
                        ];
                        value = filteredAccounts.join(',');
                    }
                }
                if (key === 'subCategory' && value.length) {
                    const accId = value.map((v) => v.id);
                    value = accId.join(',');
                }
                if (['category', 'subCategory'].includes(key)) {
                    key = 'account';
                }
                if (key === 'isReviewed') {
                    filters[key] = value;
                }
                if (key === 'transactionType' && value.id) {
                    filters[key] = value.id;
                }
                if (value?.length) {
                    filters[key] = value;
                }
            }
        }

        try {
            const response = await transaction.getTransactions(filters);
            setLoading(false);
            const transactionsList = response?.transactions || [];
            setTransactions(transactionsList);
        } catch (error) {
            setLoading(false);
            showError(t, error);
        }
    };

    const getCategories = async () => {
        const response = await user.getUserCategories();
        const categories = response?.data || [];
        setUserCategories(categories);
    };

    useEffect(() => {
        getFilters();
        getCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        localStorage.setItem('transactionFilters', JSON.stringify(filtersData));
    }, [filtersData]);

    const filterSubCategories = (selectedCategory = [], accFilters = accountFilters) => {
        let filteredSubCatsData = [];
        if (!selectedCategory?.length) {
            filteredSubCatsData = [
                ...Object.values(accFilters)
                    .flat()
                    .map((a) => ({ id: a.accountId, label: a.accountName })),
            ];
            setFilterSubCategoryOptions(filteredSubCatsData);
        } else {
            const categoryKeys = selectedCategory.map((k) => k.id);
            const filteredData = Object.keys(accFilters)
                .filter((key) => categoryKeys.includes(key))
                .reduce((obj, key) => {
                    obj[key] = accFilters[key];
                    return obj;
                }, {});
            if (filteredData) {
                filteredSubCatsData = [
                    ...Object.values(filteredData)
                        .flat()
                        .map((a) => ({ id: a.accountId, label: a.accountName })),
                ];
            }
        }
        setFilterSubCategoryOptions(filteredSubCatsData);
        return filteredSubCatsData;
    };

    const updateFilters = (key, value) => {
        let updatedFilters = {
            ...filtersData,
            [key]: value,
        };
        if (key === 'category') {
            filterSubCategories(value);
            updatedFilters = {
                ...updatedFilters,
                subCategory: [],
            };
        }
        setFiltersData(updatedFilters);
    };

    const onTransactionReviewed = async (transactionId, isReviewed) => {
        try {
            await transaction.updateTransaction(transactionId, { isReviewed: !isReviewed });
            const transactionList = [...transactions];
            const transactionObj = transactionList.find((trans) => trans.id === transactionId);
            if (transactionObj) {
                transactionObj.isReviewed = !isReviewed;
                setTransactions(transactionList);
            }
        } catch (error) {
            showError(t, error);
        }
    };

    const onRowClick = async (transactionId) => {
        const transactionObj = await transaction.getTransaction(transactionId);
        const { id, transactionDate, merchant, categoryId, subCategoryId, tag, notes, amount, amountLocalCurrency } = transactionObj.data;
        let amountToShow = amountLocalCurrency;
        if (amountToShow === null || !amountToShow?.amount === null) {
            amountToShow = amount;
        }

        const category = userCategories.find((c) => c.id === categoryId);
        let subCategory = null;

        if (category) {
            subCategory = category.sub_categories.find((c) => c.id === subCategoryId);
        }
        setEditTransaction({
            id,
            merchant,
            transactionDate,
            payee: merchant?.name || '',
            category,
            subCategory,
            tag: tag || '',
            notes: notes || '',
            amount: amountToShow,
        });
        setShowEditDialog(true);
    };

    const updateTransactionData = (key, value) => {
        const clonedData = { ...editTransaction };
        clonedData[key] = value;
        if (key === 'category') {
            const { name, sub_categories: subCategories } = value;
            const subCategory = subCategories.find((c) => c.name === name);
            clonedData.subCategory = subCategory || '';
        }
        setEditTransaction(clonedData);
    };

    const splitTransactions = async () => {
        const { id, tag, notes, splitArray = [] } = editTransaction;
        const splitTransactions = splitArray.map((split) => ({
            tag,
            notes,
            amount: parseFloat(split.balance) || 0,
            category_id: split.category.id || null,
            sub_category_id: split.subCategory.id || null,
        }));

        try {
            const response = await transaction.splitTransaction(id, splitTransactions);
            if (response.status === 'SUCCESS') {
                fetchTransactions();
            }
            setEditTransaction(null);
            setShowEditDialog(false);
        } catch (err) {
            showError(t, err);
        }
    };

    const updateTransaction = async () => {
        const {
            id,
            transactionDate,
            category,
            subCategory,
            tag,
            notes,
            payee,
            merchant,
            existingTransaction = false,
            futureTransaction = false,
        } = editTransaction;
        const payload = {
            categoryId: category?.id || null,
            subCategoryId: subCategory?.id || null,
            tag,
            notes,
            transactionDate,
            merchant: {
                ...merchant,
                name: payee,
            },
            existingTransaction,
            futureTransaction,
        };
        try {
            const response = await transaction.updateTransaction(id, payload);
            if (existingTransaction) {
                fetchTransactions();
            } else if (response?.data) {
                const { data } = response;
                const { id } = data;
                const transactionList = [...transactions];
                const index = transactionList.findIndex((trans) => trans.id === id);
                if (index > -1) {
                    transactionList[index] = { ...data };
                    setTransactions(transactionList);
                }
            }
            setEditTransaction(null);
            setShowEditDialog(false);
        } catch (err) {
            showError(t, err);
        }
    };

    const transactionSave = async (sArray) => {
        if (sArray?.length) {
            await splitTransactions();
        } else {
            await updateTransaction();
        }
    };

    const saveTransaction = async () => {
        const { splitArray = [], existingTransaction, futureTransaction } = editTransaction;
        if (existingTransaction || futureTransaction) {
            setSplitConfirmDialog(true);
        } else {
            transactionSave(splitArray);
        }
    };

    const onDialogAction = async (buttonKey) => {
        if (buttonKey === 'cancel') {
            setSplitConfirmDialog(false);
        } else {
            setIsLoading(true);
            await transactionSave(editTransaction?.splitArray || []);
            setSplitConfirmDialog(false);
            setIsLoading(false);
        }
    };

    const getConfirmDialog = () => (
        <DialogAtom
            open={splitConfirmDialog}
            maxWidth="sm"
            dialogTitle="Please Confirm"
            content={<>{t('downloaded-transactions.impact-multiple-existing-transactions')}</>}
            onDialogAction={onDialogAction}
            isLoading={isLoading}
        />
    );

    const exportTransactions = () => {
        let data = [];
        const formFilters = [];

        formFilters.push({
            Category: filtersData.category.map((c) => c.label).join(', '),
            'Sub Category': filtersData.subCategory.map((c) => c.label).join(', '),
            'Date Range (From)': filtersData.dateFrom ? moment.parseZone(filtersData.dateFrom).format('MMM DD, YYYY') : '',
            'Date Range (To)': filtersData.dateTo ? moment.parseZone(filtersData.dateTo).format('MMM DD, YYYY') : '',
            'Amount (From)': filtersData.amountFrom,
            'Amount (To)': filtersData.amountTo,
            Payee: filtersData.payee,
            'Transaction Type': filtersData.transactionType?.label,
            'Hide Reviewed': filtersData.isReviewed,
        });
        const cols = [
            { width: 25 },
            { width: 25 },
            { width: 20 },
            { width: 20 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
        ];
        const merges = [];
        const style = [
            {
                col: 'A1',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'B1',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'C1',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'D1',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'E1',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'F1',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'G1',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'H1',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'I1',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'A4',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'B4',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'C4',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'D4',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'E4',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'F4',
                style: EXCEL_STYLE.boldHeader,
            },
        ];

        const transactionsData = transactions.map((t) => {
            const {
                transactionDate,
                merchant,
                category_view: category,
                sub_category: subCategory,
                notes,
                amount,
                isReviewed,
            } = t;
            const categoryName =
                        !subCategory?.name || category?.name === subCategory?.name
                            ? category?.name
                            : `${category?.name}: ${subCategory?.name}`;
            return ({
                'Transaction Date': moment.parseZone(transactionDate).format('MMM DD, YYYY'),
                'Payee/Merchant': merchant?.name,
                'Category': categoryName,
                'Notes': notes,
                'Amount': Number(amount?.amount)?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                }),
                'Reviewed': isReviewed
            });
        });

        data = [formFilters, transactionsData];
        
        exportCustomExcel(data, style, cols, merges, 'Transactions', 7, ['I'], [false, false, true], [-1, 'A4', -1]);
    };

    return (
        <Page title={t('downloaded-transactions.title')}>
            <Grid mb={5}>
                <TopMenu title="Transactions" videoLink={videoURL.transactions} />
                <TopHeading heading={t('welcome-constant.description-1')} hideBackLink />
            </Grid>
            <div className="hubPadding hubCommonMargin">
                {accountFilters === null ? (
                    <>
                        <Typography variant="h6" className="subTextHeading" style={{ textAlign: 'center' }}>
                            {t('downloaded-transactions.add-a-financial-institutions')}
                        </Typography>
                    </>
                ) : (
                    <>
                        <Grid container spacing={2} marginTop={0}>
                            <Grid item xl={12} md={12} xs={12}>
                                <Filter
                                    filtersData={filtersData}
                                    updateFilters={updateFilters}
                                    loading={loading}
                                    fetchTransactions={fetchTransactions}
                                    filterCategoryOptions={filterCategoryOptions}
                                    filterSubCategoryOptions={filterSubCategoryOptions}
                                    exportTransactions={exportTransactions}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={4}
                            pt={0}
                            marginTop={0}
                            className="marginSmTop"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Grid item xs={12}>
                                <Table
                                    transactions={transactions}
                                    onTransactionReviewed={onTransactionReviewed}
                                    onRowClick={onRowClick}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
                {showEditDialog && (
                    <TransactionEditDialog
                        showEditDialog={showEditDialog}
                        transactionData={editTransaction}
                        updateTransactionData={updateTransactionData}
                        userCategories={userCategories}
                        onClose={() => {
                            setEditTransaction(null);
                            setShowEditDialog(false);
                        }}
                        onSave={saveTransaction}
                    />
                )}
                {getConfirmDialog()}
            </div>
        </Page>
    );
}
