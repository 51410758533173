import { Grid, Typography } from '@mui/material';
import image from 'assets/image';
import React from 'react'

const ReviewCarousel = (props) => (
    <div className='clientReviewStyle'>
        <Grid container spacing={2} className='mobileDivCenter'>
            <Grid item lg={4} md={12}>
                <img alt='comma icon' src={props.clientImg} className='clientReviewImg'/>
            </Grid>
            <Grid item lg={8} md={12}>
                <div className='reviewDetailsStyle'>
                    <img src={image.comma} alt='' className='commaIconStyle'/> 
                    <Typography variant='h4'>{props.title}</Typography>
                    <Typography variant='body1'>{props.text}</Typography>
                    <Typography variant='body'>—{props.name}</Typography>
                    <br />
                    <Typography variant='body'>{props.occupation}</Typography>
                </div>
            </Grid>
        </Grid>
    </div>
)

export default ReviewCarousel;