/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Grid, Typography, styled } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import clone from 'rfdc/default';
import videoURL from 'constants/helpVideoUrl';
import Page from 'components/Page';
import Iconify from '../../../components/Iconify';
import TopHeading from '../../../components/TopHeading';
import TopMenu from '../../../components/TopMenu';
import onboarding from '../../../constants/services/onboarding';
import ListAddBudget from './ListAddBudget';
import BudgetTable from './budgetTable';
import { AccordionStyle } from './styled-components';
import { goBackToMenu } from '../../../utils/calCommonFunction';

export default function Budget() {
    const location = useLocation();
    const {state} = location;
    const [categoriesMasterData, setCategoriesMasterData] = useState([]);
    const { t } = useTranslation();
    const filteredCategoryGroups = categoriesMasterData.filter((catGrp) =>
        ['Income/Cash Inflows', 'Expenses/Cash Outflows'].includes(catGrp.name)
    );
    const [openAccordionsStep4, setOpenAccordionsStep4] = useState(['Assets']);
    const navigate = useNavigate();

    const updateSubCategoryData = (categoryGroupId, catId, subCatId, key, value) => {
        const categoryGroups = [...categoriesMasterData];
        const catGroup = categoryGroups.find((cg) => cg.id === categoryGroupId);
        if (catGroup) {
            const category = catGroup.categories?.find((cat) => cat.id === catId);
            if (category) {
                const subCat = category.subCategories?.find((cat) => cat.id === subCatId);
                if (subCat) {
                    subCat[key] = value;
                    if (key === 'name') {
                        subCat.error = '';
                    }
                    subCat.isEdited = true;
                }
            }
        }
        setCategoriesMasterData(categoryGroups);
    };

    const getTotal = (subCategories) =>
        subCategories.reduce((total, item) => {
            const budget = item.budget.toString().replace(/[^0-9.-]+/g, '');
            total += Number(budget);
            return total;
        }, 0);

    const getTotalBudget = (categoryGroup) => {
        const filteredCategories = categoryGroup.categories.filter((cat) => cat.isSelected);
        let total = 0;
        filteredCategories.forEach((fc) => {
            total += getTotal(fc.subCategories);
        });
        return total;
    };

    const getTotalInflow = () => {
        const incomeCat = filteredCategoryGroups.find((catGrp) => catGrp.name === 'Income/Cash Inflows');
        if (incomeCat) {
            return getTotalBudget(incomeCat);
        }
        return 0;
    };

    const getTotalOutflow = () => {
        const expenseCat = filteredCategoryGroups.find((catGrp) => catGrp.name === 'Expenses/Cash Outflows');
        if (expenseCat) {
            return getTotalBudget(expenseCat);
        }
        return 0;
    };

    const getDiscNonDiscOutflow = () => {
        const expenseCat = filteredCategoryGroups.find((catGrp) => catGrp.name === 'Expenses/Cash Outflows');
        let discretionaryOutflow = 0;
        let nonDiscretionaryOutflow = 0;
        if (expenseCat) {
            const filteredCategories = expenseCat.categories.filter(
                (cat) => cat.isSelected && cat.name !== 'Loan/Lease payments'
            );
            filteredCategories.forEach((fc) => {
                discretionaryOutflow += getTotal(fc.subCategories.filter((c) => c.isDiscretionary));
                nonDiscretionaryOutflow += getTotal(fc.subCategories.filter((c) => !c.isDiscretionary));
            });
        }
        return {
            discretionaryOutflow,
            nonDiscretionaryOutflow,
        };
    };

    const getLoanRepayment = () => {
        const expenseCat = filteredCategoryGroups.find((catGrp) => catGrp.name === 'Expenses/Cash Outflows');
        if (expenseCat) {
            const loanCategory = expenseCat.categories.find((cat) => cat.isSelected && cat.name === 'Loan/Lease payments');
            if (loanCategory) {
                return getTotal(loanCategory.subCategories);
            }
        }
        return 0;
    };

    const cashInflow = getTotalInflow();
    const cashOutflow = getTotalOutflow();
    const totalLoanRepayment = getLoanRepayment();
    const { discretionaryOutflow, nonDiscretionaryOutflow } = getDiscNonDiscOutflow();

    const fetchCategoriesMasterData = async () => {
        document.body.classList.add('loading-indicator');
        const response = await onboarding.getBudgetOnboardingData();
        const { userCategoriesData } = response?.data;
        const data = await onboarding.getMasterData();
        const categoryMasterData = data?.data || [];
        document.body.classList.remove('loading-indicator');
        const clonedCatData = clone(categoryMasterData);

        if (userCategoriesData.length) {
            const defaultSubCatObj = {
                id: new Date().getTime(),
                isSelected: true,
                isEdited: true,
                isSynced: false,
                position: 1,
                budget: '',
            };
            userCategoriesData.forEach((uCat) => {
                const {
                    id,
                    masterCategoryId,
                    categoryGroupId,
                    name,
                    position,
                    isSelected,
                    isUserCategory,
                    sub_categories: subCategories,
                } = uCat;

                let subCats = (subCategories || []).map((subCat) => ({
                    ...subCat,
                    isEdited: false,
                    isSynced: true,
                    budget: Number(subCat.budget) ? Number(subCat.budget) : '',
                }));

                const catGroup = clonedCatData.find((c) => c.id === categoryGroupId);
                if (catGroup) {
                    if (!isUserCategory && masterCategoryId) {
                        const category = catGroup.categories.find((cat) => cat.id === masterCategoryId);
                        if (category) {
                            category.id = id;
                            category.position = position;
                            category.isSelected = isSelected;
                            category.isSynced = true;
                            category.isEdited = false;

                            if (!subCats.length) {
                                subCats = [
                                    {
                                        ...defaultSubCatObj,
                                        name,
                                        position: 1,
                                        budget: '',
                                        isUpdatedManually: category.isUpdatedManually,
                                        isDiscretionary: category.isDiscretionaryDefaultValue,
                                    },
                                ];
                            }
                            category.subCategories = subCats;
                        }
                    } else {
                        if (!subCats.length) {
                            subCats = [
                                {
                                    ...defaultSubCatObj,
                                    name,
                                    position: 1,
                                    budget: '',
                                    isUpdatedManually: true,
                                    isDiscretionary: false,
                                },
                            ];
                        }

                        catGroup.categories.push({
                            id,
                            name,
                            position,
                            isUserCategory: true,
                            isSelected,
                            isEdited: false,
                            isSynced: true,
                            subCategories: subCats,
                        });
                    }
                }
            });
        }
        clonedCatData.forEach((cg) => {
            const { categories } = cg;
            (categories || []).sort((a, b) => a.position - b.position);
            (categories || []).forEach((cat) => {
                const { subCategories } = cat;
                (subCategories || []).sort((a, b) => a.position - b.position);
            });
        });
        setCategoriesMasterData(clonedCatData);
    };

    useEffect(() => {
        fetchCategoriesMasterData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveSubCategories = async () => {
        const clonedCategoriesData = clone(categoriesMasterData);
        const openAccordions = [...openAccordionsStep4];
        clonedCategoriesData.forEach((catGroup) => {
            const filteredCategories = catGroup.categories.filter((cat) => cat.isSelected);
            filteredCategories.forEach((cat) => {
                cat.subCategories.forEach((subCat) => {
                    subCat.error = '';
                });
                const filteredSubCategories = cat.subCategories.filter((cat) => cat.isSelected);
                filteredSubCategories.forEach((subCat) => {
                    if (!subCat.name) {
                        subCat.error = t('error-message.sub-category-name-required');
                        if (!openAccordions.includes(catGroup.name)) {
                            openAccordions.push(catGroup.name);
                        }
                    }
                });
            });
        });
        const isValid = clonedCategoriesData?.every((catGroup) =>
            catGroup?.categories
                ?.filter((cat) => cat.isSelected)
                .every((cat) => cat.subCategories?.filter((cat) => cat.isSelected).every((subCat) => !subCat.error))
        );
        if (!isValid) {
            setOpenAccordionsStep4(openAccordions);
            setCategoriesMasterData(clonedCategoriesData);
            return false;
        }

        const subCategories = categoriesMasterData.reduce((acc, catGroup) => {
            const filteredCategories = catGroup.categories?.filter((c) => c.isSelected);
            filteredCategories?.forEach((cat) => {
                const { id: categoryId, subCategories } = cat;
                subCategories.forEach((subCat) => {
                    subCat.error = '';
                });
                const filteredSubCat = subCategories.filter((sCat) => sCat.isSelected && sCat.isEdited);
                filteredSubCat?.forEach((subCat) => {
                    const { id, name, position, budget, isSelected, isUpdatedManually, isDiscretionary, isSynced } =
                        subCat;
                    const subCatObj = {
                        name,
                        position,
                        budget,
                        isSelected,
                        isUpdatedManually,
                        isDiscretionary,
                        categoryId,
                    };
                    if (isSynced) {
                        subCatObj.id = id;
                    }
                    acc.push(subCatObj);
                });
            });
            return acc;
        }, []);
        const response = await onboarding.addSubCategoryData({ subCategories, view: 'profile' });
        const updatedData = response?.data || [];
        if (updatedData.length) {
            const clonedCatData = clone(categoriesMasterData);
            updatedData.forEach((uCat) => {
                const { id, name, position, categoryId } = uCat;
                clonedCatData.forEach((catGroup) => {
                    const category = catGroup.categories.find((cat) => cat.id === categoryId);
                    if (category) {
                        const subCat = category.subCategories.find(
                            (sCat) => sCat.name === name && sCat.position === position
                        );
                        if (subCat) {
                            subCat.id = id;
                            subCat.isSynced = true;
                            subCat.isEdited = false;
                        }
                    }
                });
            });
            setCategoriesMasterData(clonedCatData);
        }
        return true;
    };

    return (
        <div style={{overflow:'hidden'}}>
            <Page title={t('my-profile.budget')}>
                <TopMenu title={state ? 'General Reports' : 'Budget'} videoLink={state ? videoURL.budget : videoURL.step5Video} />
                <TopHeading heading={state ? 'Budget' : "Add monthly budget details"} />
                <div className='hubPadding' style={{ marginTop: 32 }}>
                    <Grid container>
                        {filteredCategoryGroups.map((categoryGroup, index) => (
                            <AccordionStyle key={categoryGroup.id} defaultExpanded={index === 0 && true}>
                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography>{categoryGroup.name}</Typography>
                                    <Iconify icon="ant-design:plus-circle-outlined" sx={{ minWidth: 22, height: 22 }} />
                                    <Iconify icon="akar-icons:circle-minus" sx={{ minWidth: 22, height: 22 }} />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ListAddBudget
                                        categoryGroup={categoryGroup}
                                        categories={categoryGroup.categories}
                                        cashInflow={cashInflow}
                                        cashOutflow={cashOutflow}
                                        updateSubCategoryData={updateSubCategoryData}
                                    />
                                </AccordionDetails>
                            </AccordionStyle>
                        ))}

                        <AccordionStyle key={filteredCategoryGroups?.length || 'id'}>
                            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                <Typography>Summary</Typography>
                                <Iconify icon="ant-design:plus-circle-outlined" sx={{ minWidth: 22, height: 22, fill: '#0F9672 !important' }} />
                                <Iconify icon="akar-icons:circle-minus" sx={{ minWidth: 22, height: 22, fill: '#0F9672 !important' }} />
                            </AccordionSummary>
                            <AccordionDetails style={{ paddingTop: 40 }}>
                                <BudgetTable
                                    cashInflow={cashInflow}
                                    cashOutflow={cashOutflow}
                                    totalLoanRepayment={totalLoanRepayment}
                                    discretionaryOutflow={discretionaryOutflow}
                                    nonDiscretionaryOutflow={nonDiscretionaryOutflow}
                                />
                            </AccordionDetails>
                        </AccordionStyle>
                    </Grid>

                    <div className='buttonFlexStyle' style={{marginTop: '12px', marginBottom: '30px'}}>
                        {
                            goBackToMenu(navigate)
                        }
                        <Button
                            variant="contained"
                            onClick={() => saveSubCategories()}
                            className='loadingButtonStyle2'
                        >
                            {t('personal-info.save')}
                        </Button>
                    </div>
                </div>
            </Page>
        </div>
    );
}
