/* eslint-disable jsx-a11y/media-has-caption */
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import image from 'assets/image';
import moolahMateIntro from '../../../assets/videos/MoolahMateIntro.mp4';
import PageCenterHeadingStyle from '../../../components/PageCenterHeadingStyle';

export default function WhyLoveMoolahMate() {
    const { t } = useTranslation();

    return (
        <Grid className='sectionCommonPadding commonPadding bgColor'>
            <Grid container spacing={2} justifyContent={'center'}>
                <Grid item md={10}>
                    <PageCenterHeadingStyle
                        headLineOne={t('home-page.headline-3')}
                        headLinetwo={t('home-page.subtitle-3')}
                        headLineThree={t('home-page.subtitle-info-3')}
                    />
                </Grid>
                <Grid item md={12} mt={4}>
                    <video style={{margin:'auto', maxWidth: '100%'}} controls poster={image.moolahMateVideoPoster}>
                        <source src={moolahMateIntro} type="video/mp4" />
                    </video>
                </Grid>
            </Grid>
        </Grid>
    );
}
