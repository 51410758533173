import image from 'assets/image';
import GetMore2 from 'pages/home-page/getMore/GetMore2';
import Page from '../../components/Page';
import AboutBanner from './banner/AboutBanner';
import CompareTable from './comparationTable';
import CompareText from './comparationText';
import './styles.css';

export default function MmAndOthers() {
    return (
        <Page title={'MM vs. Competition'}>
            <AboutBanner />
            <CompareText />
            <CompareTable />
            <GetMore2 imageUrl={image.footerGraphicAnimation} orderType={''} />
        </Page>
    );
}
