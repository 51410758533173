// @mui
import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import videoURL from 'constants/helpVideoUrl';
import {
    BorderStyle,
    FormHeaderTd,
    HeaderDivStyle,
    HeaderTableStyle
} from './styled-components';
// components
import Page from '../../components/Page';
import TopHeading from '../../components/TopHeading';
import TopMenu from '../../components/TopMenu';
import forum from '../../constants/services/forum';
import AutoCompleteForumSearch from './AutoCompleteForumSearch';

export default function ForumCategory() {
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const getCategories = async () => {
        const categoriesData = await forum.getCategories();
        const categoriesList = [];
        if (categoriesData.data) {
            categoriesData.data.forEach((category) => {
                categoriesList.push({
                    id: category.id,
                    title: category.attributes.title,
                    description: category.attributes.description,
                    topics: category.attributes.topics.data,
                });
            });
        }
        setCategories(categoriesList);
    };

    useEffect(() => {
        getCategories();
    }, []);

    return (
        <Page title={t('forumcategory.title')}>
            <TopMenu title={t('forumcategory.title')} videoLink={videoURL.forums} />
            <TopHeading heading={t('forumcategory.description')} hideBackLink/>
            <div className='contentStyle' style={{ paddingTop: '0px' }}>
                <Stack
                    direction="row-reverse"
                    justifyContent="center"
                    alignItems="flex-center"
                    spacing={3}
                    style={{ paddingTop: '30px' }}
                >
                    <AutoCompleteForumSearch />
                </Stack>
                <Grid container spacing={4} pt={4} justifyContent="center" alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={12}>
                        <HeaderDivStyle style={{marginTop: 0}}>
                            <HeaderTableStyle>
                                <thead>
                                    <tr>
                                        <th style={{ width: '30%' }}>{t('forumcategory.category')}</th>
                                        <th style={{ width: '55%' }}>{t('forumcategory.category-description')}</th>
                                        <th style={{ width: '15%', textAlign: 'center' }}>
                                            {t('forumcategory.numberOfTopic')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <BorderStyle colSpan={3}>{''}</BorderStyle>
                                    </tr>
                                    {categories.map((category) => (
                                        <tr
                                            key={category.id}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                navigate(`/forum-topics/category/${category.id}`, {
                                                    state: { categoryName: category.title },
                                                })
                                            }
                                        >
                                            <FormHeaderTd>{category.title}</FormHeaderTd>
                                            <td>{category.description}</td>
                                            <td style={{ textAlign: 'center' }}>{category.topics.length}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </HeaderTableStyle>
                        </HeaderDivStyle>
                    </Grid>
                </Grid>
            </div>
        </Page>
    );
}
