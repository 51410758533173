import { Grid, Typography } from '@mui/material';

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import image from '../../../assets/image';
import { getCompareDetails } from './constant';
import './table.css';

export default function ComparationTable() {
    const [windowScreen, setWindowScreen] = useState(window.innerWidth <= 900);
    const { t } = useTranslation();
    const CompareDetails = getCompareDetails(t);

    useEffect(() => {
        const handleScreenResize = () => {
            setWindowScreen(window.innerWidth <= 900);
        };

        window.addEventListener('resize', handleScreenResize);

        return () => {
            window.removeEventListener('resize', handleScreenResize);
        };
    }, []);

    const noImage = () => <Typography variant="body" className="noIconStyle">{''}</Typography>;

    return (
        <Grid className="tableWrapper">
            <div className='tableOverFlow'>
                <table className="compareTable">
                    <thead>
                        <tr>
                            <th>{''}</th>
                            <th className='activeHeader'>{t(windowScreen ? 'MM':'mm-comparationTable.MoolahMate')}</th>
                            <th>{t('mm-comparationTable.Mint')}</th>
                            <th>{t('mm-comparationTable.Empower')}</th>
                            <th>{t('mm-comparationTable.YNAB')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {CompareDetails.map((item, indx) => (
                            <tr key={indx}>
                                <td><Typography variant="body" className='learnHeading'>{item.title}</Typography></td>
                                <td className="mobileHover">{item.MoolahMate ? <img src={image.rightIcon} alt="Yes" /> : noImage()}</td>
                                <td>{item.Mint ? <img src={image.rightIcon} alt="Yes" /> : noImage()}</td>
                                <td>{item.Empower ? <img src={image.rightIcon} alt="Yes" /> : noImage()}</td>
                                <td>{item.YNAB ? <img src={image.rightIcon} alt="Yes" /> : noImage()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Grid>
    )
}