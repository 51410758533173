/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SOCIAL_LINKS } from "./constant";

export default function SocialLinks() {
    const { t } = useTranslation();

    return (
        <div className='footerFlex socialIcon'>
            <Typography variant='body'>{t('common-translation.Follow-us')}</Typography>
            {SOCIAL_LINKS.map((socialLink) => (
                <Tooltip title={socialLink.title} key={socialLink.id}>
                    <a onClick={() => window.open(socialLink.url, '_blank')}>
                        <img className='socialIcon' alt="logo" src={socialLink.icon} />
                    </a>
                </Tooltip>
            ))}
        </div>
    )
}