import { Autocomplete, Grid, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const Wrapper = styled('div')(({ theme }) => ({
    // height: '100vh',
    background: 'url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '80px',
    },
}));

export const PageTopRightBg = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    background: 'url(/static/loginImg/loginLeftBottomBg.svg) 120% 40px no-repeat',
    height: 500,
    width: 675,
    top: 0,
    right: 0,
    backgroundSize: '100%',
    transform: 'rotate(180deg)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px',
        backgroundPosition: '-50px 100%',
    },
}));

export const SubmitButton = styled(LoadingButton)(() => ({
    padding: '6px 6px',
    height: 'auto',
    minWidth: '72px'
}));

export const AutoFieldStyle = styled(Autocomplete)(({ theme }) => ({
    fontSize: 13,
    backgroundColor: '#F8F8F8',
    padding: '5px 12px 6px 12px!important',
    borderRadius: '10px',
    '& input': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 16,
        },
    },
    '& .MuiInputBase-root': {
        fontSize: 12,
        borderBottom: '0 !important',
        [theme.breakpoints.up('xl')]: {
            fontSize: 16,
        },
        '&:before': {
            borderBottom: '0 !important',
        },
    },
    '& .MuiAutocomplete-popupIndicator': {
        position: 'relative',
        width: 25,
        '& svg': {
            display: 'none',
        },
        '&:after': {
            position: 'absolute',
            right: 0,
            top: 0,
            content: "''",
            background: 'url(/static/loginImg/down-arrow.svg) 0 0 no-repeat',
            width: 25,
            height: 13,
        },
    },
    '& .dropMenu': {
        overflowWrap: 'break-word'
    }
}));

export const LabelStyle = styled('div')(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontSize: 14,
    marginBottom: '8px',
    marginLeft: '4px',
    whiteSpace: 'normal',
    overflow: 'auto',
    textOverflow: 'unset',
    fontWeight:'500',
    [theme.breakpoints.up('xl')]: {
        fontSize: 16,
    },
}));

export const FormStyling = styled('div')(({theme}) => ({
    // margin: 20
    [theme.breakpoints.down('md')]: {
        marginTop: '20px !important',
        marginBottom: '24px'
    },
}));

export const DateGrid = styled(Grid)(({ theme }) => ({
    '& div': {
        '& div': {
            backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
            borderRadius: 10,
            '& input': {
                padding: '10px 0px 10px 10px  !important',
                fontSize: 12,
                [theme.breakpoints.up('xl')]: {
                    fontSize: 16,
                },
            },
            '& fieldset': {
                borderStyle: 'unset',
                borderWidth: 0,
            },
        },
    },
}));

export const StickyFirstTh = styled('th')(({theme}) => ({
    position: 'sticky !important',
    left: '0px',
    zIndex: 12,
    width: '120px !important',
    minWidth: '120px !important',
    [theme.breakpoints.down('sm')]: {
        position:'inherit !important',
        left: 'auto',
    },
}));

export const StickyFirstTd = styled('td')(({theme}) => ({
    position: 'sticky !important',
    left: '0px',
    zIndex: 12,
    width: '120px !important',
    minWidth: '120px !important',
    [theme.breakpoints.down('sm')]: {
        position:'inherit !important',
        left: 'auto',
    },
}));