import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import image from 'assets/image';

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
    const navigate = useNavigate();
    const location = useLocation();

    const logo = (
        <Box sx={{ width: 40, height: 40, ...sx }}>
            <img src={image.logoWebIcon} alt=''/>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="auto" viewBox="0 0 91 74" fill="none">
                <path
                    d="M56.4341 9.19376C58.549 8.12364 61.3077 5.61342 62.1768 1.80608C63.0907 -2.19755 65.6981 1.27187 65.8185 4.33468C65.9097 6.65765 65.4387 10.1192 63.4651 12.7885C64.1947 13.4631 64.8308 14.2321 65.3383 15.1088C68.4388 11.5554 77.8582 5.07898 90.7316 7.60035C91.2897 12.6678 90.2479 23.8965 81.616 28.2719C83.6004 26.3252 87.6621 20.5965 88.0341 13.2549C84.0344 12.6987 74.5469 13.329 68.5939 20.2999C68.6249 24.7494 67.8869 34.7607 64.6872 39.2102C65.4623 36.0585 65.9513 28.6116 64.6872 23.1735C63.4159 17.7043 59.7883 12.3279 52.2231 12.3279C51.479 12.9459 49.9721 14.2004 49.8977 16.1285C49.8812 16.5564 49.8957 17.0981 49.9121 17.7142C49.9883 20.5692 50.1071 25.0233 47.3863 27.1597C46.1887 28.1 44.708 28.6593 43.3032 29.19C41.1072 30.0196 39.0966 30.7791 38.6428 32.8142C38.3063 34.3234 38.2671 35.7469 38.2305 37.0765V37.0766C38.1862 38.6869 38.1456 40.1595 37.5852 41.4797C37.223 42.3329 36.8879 43.0046 36.5686 43.6448C35.986 44.8128 35.4559 45.8755 34.9095 47.7429C34.4161 49.4291 34.423 50.8745 34.9095 54.7287C29.0717 46.2975 26.1166 38.4688 26.1166 38.4688C26.1166 38.4688 22.7115 30.5945 24.2149 24.2364C16.344 16.9153 10.0251 16.6538 4.69213 17.3336C3.94801 21.8572 8.78481 29.7241 11.2962 33.0921C0.208778 25.3797 0.103363 16.0358 1.43658 12.3279C13.6402 8.32336 23.4582 13.9964 26.8417 17.3336C27.1483 16.7775 27.4849 16.222 27.853 15.665L27.8773 15.6284C25.2814 13.1296 24.3664 9.32397 24.2174 6.7927C24.0362 3.71371 26.2108 -0.018484 27.4794 3.90032C28.6608 7.54981 31.4667 9.77434 33.5928 10.6748C38.7642 8.01681 44.7403 7.97111 46.1771 7.97111L46.2539 7.97108C47.5174 7.97045 51.9954 7.9682 56.4341 9.19376ZM33.1804 62.3784C32.2972 63.9773 31.5382 65.7662 31.0619 67.6868C30.1859 71.2186 33.3881 74 37.0269 74H55.0138C58.6879 74 61.8116 70.9758 60.9642 67.4007C60.6596 66.1154 60.2274 64.7938 59.6171 63.501C57.4607 58.9333 53.7849 57.3583 48.7804 56.6736C43.2836 55.9215 35.8629 57.522 33.1804 62.3784Z"
                    fill="#4B4B4B"
                />
            </svg> */}
        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    const goTo = () => {
        if (['/setup', '/onboarding', '/profile-summary', '/payment'].includes(location?.pathname)) {
            return;
        }
        if (sessionStorage.getItem('userData')) {
            const usr = JSON.parse(sessionStorage.getItem('userData'));
            if (usr.id)
                setTimeout(() => {
                    navigate('/welcome', { replace: true });
                }, 500);
        }
        else {
            setTimeout(() => {
                navigate('/', { replace: true });
            }, 500);
        }
    }

    return <RouterLink onClick={goTo}>{logo}</RouterLink>;
}
