import * as React from 'react';
import { styled, Typography, Grid, Box } from '@mui/material';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { FormContext } from '../context/StepperContext';

const TableScroll = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        overflowX: 'auto',
    },
}));

const TableStyle = styled('table')(({ theme }) => ({
    width: '100%',
    paddingTop: '0px',
    minWidth: 550,
    [theme.breakpoints.down('md')]: {
        paddingTop: 10,
    },
    '& th': {
        textAlign: 'left',
        fontSize: 20,
        fontWeight: 700,
        color: theme.palette.primary.dark,
        padding: 10,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 18,
        },
    },
    '& thead':{
        [theme.breakpoints.down('md')]: {
            top: '0px !important',
        }
    },
    '& td': {
        padding: '20px 0 0px',
        verticalAlign: 'top',
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: '16px !important',
        },
        '& .MuiGrid-root': {
            marginBottom: 0,
            '& p.Mui-error': {
                color: 'rgb(255, 72, 66)',
                fontSize: '0.75rem',
                fontWeight: 400,
                textAlign: 'left'
            }
        },
        '&:nth-child(1), &:nth-child(3)': {
            paddingLeft: 10
        },
        
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
}));

const SwitchStyle = styled(Box)(() => ({
    width: 136,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '& p': {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 1,
    },
    '& span + p': {
        left: 'inherit',
        right: 0,
    },
}));

const CheckboxStyle = styled(Checkbox)(({ theme }) => ({
    '& svg': {
        // display: 'none',
        position: 'absolute'
    },
    position: 'relative',
    '&:after': {
        width: 20,
        height: 20,
        border: `solid 0px ${theme.palette.primary.main}`,
        content: "''",
        borderRadius: 3,
    },
    '&.Mui-checked': {
        '&:before': {
            width: 10,
            height: 10,
            // background: theme.palette.primary.main,
            content: "''",
            position: 'absolute',
            borderRadius: 1,
        },
    },
    '&.Mui-disabled': {
        '& svg': {
            '& path': {
                fill: 'rgba(145, 158, 171, 0.8); !important',
            },
        },
    }
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 134,
    height: 30,
    padding: 0,
    display: 'flex',
    border: `solid 1px ${theme.palette.primary.main}`,
    borderRadius: 5,
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 67,
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 0,
        position: 'relative',
        '&:before, &:after': {
            position: 'absolute',
            left: 0,
            top: 2,
            content: "'Yes'",
            width: 67,
            height: 30,
            textAlign: 'center',
            color: theme.palette.secondary.contrastText,
        },
        '&:after': {
            content: "'No'",
            left: '100%',
            color: theme.palette.primary.contrastText,
        },
        '&.Mui-checked': {
            transform: 'translateX(67px)',
            '&:before': {
                left: '-100%',
                content: "'Yes'",
                color: theme.palette.primary.contrastText,
            },
            '&:after': {
                content: "'No'",
                left: '0',
                color: theme.palette.secondary.contrastText,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: 'inherit',
            },
        },
        '&.Mui-disabled': {
            color: 'rgb(158, 203, 181) !important',
            pointerEvents: 'none',
            cursor: 'default',
            '& .MuiSwitch-thumb': {
                backgroundColor: 'rgb(158, 203, 181) !important',
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 67,
        height: 30,
        borderRadius: 0,
        background: theme.palette.primary.main,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'inherit',
        boxSizing: 'border-box',
    },
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({theme}) =>({
    [`& .${tooltipClasses.tooltip}`]: {
        // backgroundColor: theme.palette.common.white,
        // color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
        padding: 10,
        fontWeight: 500,
    },
}));

const InfoIcon = styled('img')(({ theme }) => ({
    display: 'revert',
    width: "16px",
    cursor:'pointer',
    [theme.breakpoints.down('md')]: {
        margin: '2px 0 0 -16px',
        width: "16px",
    }
}));

const InputBox = ({ value = '', setValue = () => {}, placeholder = '', error = '', isDisabled = false }) => {
    const [name, setName] = React.useState(value);

    return (
        <>
            <Input
                type='text'
                placeholder={placeholder}
                value={name}
                autoFocus
                error={error}
                disabled={isDisabled}
                inputProps={{ maxLength: 25 }}
                onChange={(e) => {
                    const {value} = e.target;
                    setName(value);
                    setValue(value);
                }}
                style={{ width: '80%' }}
            />
            {error && <p className='Mui-error'>{error}</p>}
        </>
    )
}

export default function SimpleAccordion(props) {
    const { categories, categoryGroupId, showUpdateManually, showDiscretionary, showIsIncludeInDebtPaymentReport } = props;

    const {
        addSubCategory,
        updateSubCategoryData
    } = React.useContext(FormContext);

    const addcss = {
        color: '#00773f',
        cursor: 'pointer',
        textDecoration: 'underline'
    };

    return (
        <TableScroll container p={0}>
            <TableStyle>
                <thead style={{position: 'sticky',backgroundColor: '#f8f8f8',zIndex: '1', top: '65px'}}>
                    <tr>
                        <th style={{ width: '20%' }}>Category</th>
                        <th style={{ width: '10%' }}>Select</th>
                        <th style={{ width: '35%' }}>
                            <div style={{ display: 'flex' }}>
                                <p>Sub-Category</p>
                                <LightTooltip style={{ marginLeft: 5 }} title={<>Add a sub-category name,  where necessary.  For example for Cash you might have  two sub-categories called Savings account and Chequing account but for the Grocery category you likely don't need to have any sub-categories.</>}>
                                    <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" />
                                </LightTooltip>
                            </div>

                        </th>
                        <th style={{ width: '20%' }}>
                            {
                                showUpdateManually ?
                                    <div style={{ display: 'flex' }}>
                                        <p>Update Manually?</p>
                                        <LightTooltip style={{ marginLeft: 5 }} title={<>Select No if transactions will be downloaded from a linked account, otherwise select Yes and the system will create an account for you to track it manually.</>}>
                                            <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" />
                                        </LightTooltip>
                                    </div> :
                                    <>&nbsp;</>
                            }
                            {
                                showDiscretionary ?
                                    <div style={{ display: 'flex' }}>
                                        <p>Variable</p>
                                        <LightTooltip style={{ marginLeft: 5 }} title={<>A Variable expense is something that is non- essential (like vacation) or something that is controllable in the short term (like entertainment or groceries). We have marked each expense/outflow as variable or not.  Feel free to change them based on your own assessment.</>}>
                                            <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" />
                                        </LightTooltip>
                                    </div> :
                                    <>&nbsp;</>
                            }
                            
                        </th>
                        <th style={{ width: '15%' }}>
                            {
                                showIsIncludeInDebtPaymentReport ?
                                    <div style={{ display: 'flex' }}>
                                        <p>Include in Debt Payment Report?</p>
                                        <LightTooltip style={{ marginLeft: 5 }} title={<>Accept setting or Select Yes if you want this sub-category to be included in your Debt Payments Report</>}>
                                            <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" />
                                        </LightTooltip>
                                    </div> :
                                    <>&nbsp;</>
                            }
                        </th>
                    </tr>
                </thead>
                <tbody style={{position: 'sticky'}}>
                    {categories.filter(cat => cat.isSelected).map((category) => <>
                        {
                            category.subCategories.filter((subCat) => !subCat.isDefault).map((subCat, indexSubCat) => (
                                <tr key={subCat.id}>
                                    <td style={{ maxWidth: 130 }}>
                                        {indexSubCat === 0 ?
                                            <>
                                                <Typography>{category.name}</Typography>
                                            </>
                                            : <></>
                                        }
                                    </td>
                                    <td>
                                        <Grid mb={3} style={{ paddingLeft: 15 }}>
                                            <CheckboxStyle disabled={subCat.isSelectionDisabled} checked={subCat.isSelected} onChange={() =>
                                                updateSubCategoryData(categoryGroupId, category.id, subCat.id, 'isSelected', !subCat.isSelected)}
                                            />
                                        </Grid>
                                    </td>
                                    <td>
                                        <Grid mb={3}>
                                            <InputBox
                                                value={subCat.name}
                                                placeholder={`${category.name}`}
                                                error={subCat.error}
                                                setValue={(name) => {
                                                    updateSubCategoryData(categoryGroupId, category.id, subCat.id, 'name', name)
                                                }}
                                                isDisabled={subCat.isSelectionDisabled}
                                            />
                                        </Grid>
                                    </td>
                                    <td>
                                        {
                                            showUpdateManually &&
                                                <Grid mb={3} style={{ paddingLeft: 15 }}>
                                                    <SwitchStyle>
                                                        <AntSwitch
                                                            disabled={subCat.isSelectionDisabled}
                                                            checked={!subCat.isUpdatedManually}
                                                            onChange={(e) => {
                                                                const {checked} = e.target;
                                                                const value = !checked;
                                                                updateSubCategoryData(categoryGroupId, category.id, subCat.id, 'isUpdatedManually', value)
                                                            }}
                                                            inputProps={{ 'aria-label': 'ant design' }}
                                                        />
                                                    </SwitchStyle>
                                                </Grid>
                                        }
                                        {
                                            showDiscretionary && 
                                            <Grid mb={3} style={{ paddingLeft: 15 }}>
                                                <SwitchStyle>
                                                    <AntSwitch
                                                        checked={!subCat.isDiscretionary}
                                                        onChange={(e) => {
                                                            const {checked} = e.target;
                                                            const value = !checked;
                                                            updateSubCategoryData(categoryGroupId, category.id, subCat.id, 'isDiscretionary', value)
                                                        }}
                                                        inputProps={{ 'aria-label': 'ant design' }}
                                                    />
                                                </SwitchStyle>
                                            </Grid>
                                        }
                                        {
                                            (!showUpdateManually && !showDiscretionary) && <>&nbsp;</>
                                        }
                                    </td>
                                    <td>
                                        {
                                            showIsIncludeInDebtPaymentReport ? 
                                                <Grid mb={3} style={{ paddingLeft: 15 }}>
                                                    <SwitchStyle>
                                                        <AntSwitch
                                                            checked={!subCat.isIncludedInDebtPaymentReport}
                                                            onChange={(e) => {
                                                                const {checked} = e.target;
                                                                const value = !checked;
                                                                updateSubCategoryData(categoryGroupId, category.id, subCat.id, 'isIncludedInDebtPaymentReport', value)
                                                            }}
                                                            inputProps={{ 'aria-label': 'ant design' }}
                                                        />
                                                    </SwitchStyle>
                                                </Grid> : <>&nbsp;</>
                                        }
                                    </td>
                                </tr>
                            ))
                        }
                        <tr>
                            <td>{''}</td>
                            <td>{''}</td>
                            <td>
                                <Grid>
                                    <Typography
                                        style={addcss}
                                        onClick={() => addSubCategory(categoryGroupId, category.id)}
                                    >
                                        Add Your Own
                                    </Typography>
                                </Grid>
                            </td>
                            <td>{}</td>
                        </tr>
                    </>
                    )}
                </tbody>
            </TableStyle>
        </TableScroll >
    );
}
