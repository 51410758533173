/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect } from 'react';
import { Grid, AccordionSummary, AccordionDetails, Typography, Button } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import clone from 'rfdc/default';
import { useNavigate } from 'react-router-dom';
import DialogAtom from 'components/dialog/Dialog';
import Page from 'components/Page';
import videoURL from 'constants/helpVideoUrl';
import ListAddSubCategory from './ListAddSubCategory';
import Iconify from '../../../components/Iconify';
import { AccordionStyle, HeadArea } from '../category/styled-components';
import onboarding from '../../../constants/services/onboarding';
import TopHeading from '../../../components/TopHeading';
import TopMenu from '../../../components/TopMenu';
import { goBackToMenu } from '../../../utils/calCommonFunction';

export default function SubCategory() {
    const [categoriesMasterData, setCategoriesMasterData] = useState([]);
    const [openAccordionsStep4, setOpenAccordionsStep4] = useState(['Assets']);
    const [showDeleteSubCategoryDialog, setShowDeleteSubCategoryDialog] = useState(false);
    const [selectedSubCategoryToDelete, setSelectedSubCategoryToDelete] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const onSubCategoryAccordionClick = (panelName) => (event, isExpanded) => {
        const clonedAccordion = [...openAccordionsStep4];
        if (isExpanded) {
            clonedAccordion.push(panelName);
        } else {
            const categoryIndex = clonedAccordion.indexOf(panelName);
            if (categoryIndex > -1) {
                clonedAccordion.splice(categoryIndex, 1);
            }
        }
        setOpenAccordionsStep4(clonedAccordion);
    }

    const addSubCategory = (categoryGroupId, catId) => {
        const categoryGroups = [...categoriesMasterData];
        const catGroup = categoryGroups.find((cg) => cg.id === categoryGroupId);
        if (catGroup) {
            const category = catGroup.categories?.find(cat => cat.id === catId);
            if (category) {
                let position = 1;
                if (category.subCategories?.length) {
                    position = category.subCategories.length + 1;
                }
                category.subCategories.push({
                    id: new Date().getTime(),
                    isSelected: true,
                    isEdited: true,
                    isSynced: false,
                    name: category.name,
                    position,
                    isUpdatedManually: category.isUpdatedManually,
                    budget: '',
                    isDiscretionary: category.isDiscretionaryDefaultValue,
                    isIncludedInDebtPaymentReport: category.isIncludedInDebtPaymentReport
                });
            }
        }
        setCategoriesMasterData(categoryGroups);
    }

    const updateSubCategoryData = (categoryGroupId, catId, subCatId, key, value) => {
        const categoryGroups = [...categoriesMasterData];
        const catGroup = categoryGroups.find((cg) => cg.id === categoryGroupId);
        if (catGroup) {
            const category = catGroup.categories?.find(cat => cat.id === catId);
            if (category) {
                const subCat = category.subCategories?.find(cat => cat.id === subCatId);
                if (subCat) {
                    subCat[key] = value;
                    if (key === 'name') {
                        subCat.error = '';
                    }
                    subCat.isEdited = true;
                }
            }
        }
        setCategoriesMasterData(categoryGroups);
    }

    const fetchCategoriesMasterData = async () => {
        const response = await onboarding.getOnboardingData();
        const { userCategoriesData } = response?.data;
        const data = await onboarding.getMasterData();
        const categoryMasterData = data?.data || [];

        const clonedCatData = clone(categoryMasterData);

        if (userCategoriesData.length) {
            const defaultSubCatObj = {
                id: new Date().getTime(),
                isSelected: true,
                isEdited: true,
                isSynced: false,
                position: 1,
                budget: '',
            };
            userCategoriesData.forEach((uCat) => {
                const { id, masterCategoryId, categoryGroupId, name, position, isSelected, isUserCategory, catTransactionCount, sub_categories: subCategories } = uCat;

                let subCats = (subCategories || []).map((subCat) => ({
                    ...subCat,
                    isEdited: false,
                    isSynced: true,
                    budget: '',
                }));

                const catGroup = clonedCatData.find((c) => c.id === categoryGroupId);
                if (catGroup) {
                    if (!isUserCategory && masterCategoryId) {
                        const category = catGroup.categories.find((cat) => cat.id === masterCategoryId);
                        if (category) {
                            category.id = id;
                            category.position = position;
                            category.isSelected = isSelected;
                            category.isSynced = true;
                            category.isEdited = false;
                            category.catTransactionCount = catTransactionCount;

                            if (!subCats.length) {
                                subCats = [{
                                    ...defaultSubCatObj,
                                    name,
                                    position: 1,
                                    budget: '',
                                    isUpdatedManually: category.isUpdatedManually,
                                    isDiscretionary: category.isDiscretionaryDefaultValue
                                }];
                            }
                            category.subCategories = subCats;
                        }
                    } else {
                        if (!subCats.length) {
                            subCats = [{
                                ...defaultSubCatObj,
                                name,
                                position: 1,
                                budget: '',
                                isUpdatedManually: true,
                                isDiscretionary: false
                            }];
                        }

                        catGroup.categories.push({
                            id,
                            name,
                            position,
                            isUserCategory: true,
                            isSelected,
                            isEdited: false,
                            isSynced: true,
                            subCategories: subCats,
                        });
                    }
                }
            });
        }
        clonedCatData.forEach((cg) => {
            const { categories } = cg;
            (categories || []).sort((a, b) => a.position - b.position);
            (categories || []).forEach((cat) => {
                const { subCategories } = cat;
                (subCategories || []).sort((a, b) => a.position - b.position);
            })
        })
        setCategoriesMasterData(clonedCatData);
    }

    useEffect(() => {
        fetchCategoriesMasterData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveSubCategories = async () => {
        const clonedCategoriesData = clone(categoriesMasterData);
        const openAccordions = [...openAccordionsStep4];
        clonedCategoriesData.forEach((catGroup) => {
            const filteredCategories = catGroup.categories.filter((cat) => cat.isSelected);
            filteredCategories.forEach((cat) => {
                cat.subCategories.forEach((subCat) => { subCat.error = '' });
                const filteredSubCategories = cat.subCategories.filter((cat) => cat.isSelected);
                filteredSubCategories.forEach((subCat) => {
                    if (!subCat.name) {
                        subCat.error = t('error-message.sub-category-name-required');
                        if (!openAccordions.includes(catGroup.name)) {
                            openAccordions.push(catGroup.name);
                        }
                    }
                })
            });
        });
        const isValid = clonedCategoriesData?.every((catGroup) =>
            catGroup?.categories?.filter((cat) => cat.isSelected).every((cat) =>
                cat.subCategories?.filter((cat) => cat.isSelected).every((subCat) => !subCat.error
                )
            ));
        if (!isValid) {
            setOpenAccordionsStep4(openAccordions);
            setCategoriesMasterData(clonedCategoriesData);
            return false;
        }

        const subCategories = categoriesMasterData.reduce((acc, catGroup) => {
            const filteredCategories = catGroup.categories?.filter((c) => c.isSelected);
            filteredCategories?.forEach((cat) => {
                const { id: categoryId, subCategories } = cat;
                subCategories.forEach((subCat) => { subCat.error = '' });
                const filteredSubCat = subCategories.filter((sCat) => sCat.isEdited);
                filteredSubCat?.forEach((subCat) => {
                    const { id, name, position, budget, isSelected, isUpdatedManually, isDiscretionary, isSynced, isIncludedInDebtPaymentReport } = subCat;
                    const subCatObj = {
                        name,
                        position,
                        budget,
                        isSelected,
                        isUpdatedManually,
                        isDiscretionary,
                        categoryId,
                        isIncludedInDebtPaymentReport
                    };
                    if (isSynced) {
                        subCatObj.id = id;
                    }
                    acc.push(subCatObj);
                });
            });
            return acc;
        }, []);
        const response = await onboarding.addSubCategoryData({ subCategories, view: 'profile' });
        const updatedData = response?.data || [];
        if (updatedData.length) {
            const clonedCatData = clone(categoriesMasterData);
            updatedData.forEach((uCat) => {
                const { id, name, position, categoryId } = uCat;
                clonedCatData.forEach((catGroup) => {
                    const category = catGroup.categories.find((cat) => cat.id === categoryId);
                    if (category) {
                        const subCat = category.subCategories.find((sCat) => sCat.name === name && sCat.position === position);
                        if (subCat) {
                            subCat.id = id;
                            subCat.isSynced = true;
                            subCat.isEdited = false;
                        }
                    }
                });
            });
            setCategoriesMasterData(clonedCatData);
        }
        return true;
    };

    const onClickDeleteSubCategory = (categoryObj) => {
        setSelectedSubCategoryToDelete(categoryObj);
        setShowDeleteSubCategoryDialog(true);
    };

    const deleteSubCategory = async () => {
        const { categoryGroupId, categoryId, subCatId } = selectedSubCategoryToDelete;
        if (categoryGroupId && categoryId && subCatId) {
            const d = new Date(subCatId);
            // eslint-disable-next-line no-restricted-globals
            if (d instanceof Date && !isNaN(d)) {
                const categoryGroups = [...categoriesMasterData];
                const catGroup = categoryGroups.find((cg) => cg.id === categoryGroupId);
                if (catGroup) {
                    const category = catGroup.categories?.find(cat => cat.id === categoryId);
                    if (category) {
                        const subCatIndex = category.subCategories?.findIndex(cat => cat.id === subCatId);
                        if (subCatIndex > -1) {
                            category.subCategories.splice(subCatIndex, 1);
                            setCategoriesMasterData(categoryGroups);
                        }

                    }
                }
            } else {
                await onboarding.deleteSubCategory(subCatId);
            }
        }
        setShowDeleteSubCategoryDialog(false);
        setSelectedSubCategoryToDelete(null);
        setIsLoading(false);
        fetchCategoriesMasterData();
    }

    const onDialogAction = async (buttonKey) => {
        if (buttonKey === 'cancel') {
            setShowDeleteSubCategoryDialog(false);
            setIsLoading(false);
        } else {
            setIsLoading(true);
            deleteSubCategory();
        }
    };

    const getDeleteSubCategoryDialog = () => (
        <DialogAtom
            open
            maxWidth="sm"
            dialogTitle={t('my-profile.delete-sub-category')}
            content={<>{t('my-profile.delete-sub-category-message')}</>}
            onDialogAction={onDialogAction}
            saveButtonLabel={t('my-profile.delete-sub-category')}
            isLoading={isLoading}
        />
    );

    return (
        <Page title={t('my-profile.sub-categories')}>
            <TopMenu title={t('my-profile.sub-categories')} videoLink={videoURL.step4Video} />
            <TopHeading heading={t('step4.sub-heading')} />
            <div
                className='hubPadding'
                style={{
                    paddingBottom: "32px"
                }}
            >
                <>
                    <HeadArea>
                        <Typography variant='h6' className='commonText'>
                            {<Trans i18nKey="step4.description">
                                Listed below are the categories you selected in step three.<br />Now select or rename or add individual sub-categories associated with each category e.g. for Cash you might add two sub-categories like" Savings account" and "Checking account" etc.
                            </Trans>}
                        </Typography>
                    </HeadArea>
                    <Grid container>
                        {categoriesMasterData.map((categoryGroup) => (
                            <AccordionStyle
                                key={categoryGroup.id}
                                expanded={openAccordionsStep4.includes(categoryGroup.name)}
                                onChange={onSubCategoryAccordionClick(categoryGroup.name)}>
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>{categoryGroup.name}</Typography>
                                    <Iconify icon="ant-design:plus-circle-outlined" sx={{ minWidth: 22, height: 22 }} />
                                    <Iconify icon="akar-icons:circle-minus" sx={{ minWidth: 22, height: 22 }} />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ListAddSubCategory
                                        addSubCategory={addSubCategory}
                                        updateSubCategoryData={updateSubCategoryData}
                                        categoryGroupId={categoryGroup.id}
                                        categories={categoryGroup.categories}
                                        showUpdateManually={['Assets', 'Liabilities'].includes(categoryGroup.name)}
                                        showDiscretionary={categoryGroup.name === 'Expenses/Cash Outflows'}
                                        onClickDeleteSubCategory={onClickDeleteSubCategory}
                                        showIsIncludeInDebtPaymentReport={categoryGroup.name === 'Expenses/Cash Outflows'}
                                    />
                                </AccordionDetails>
                            </AccordionStyle>
                        ))}
                    </Grid>
                    <div className='buttonFlexStyle' style={{ marginTop: '12px' }}>
                        {
                            goBackToMenu(navigate)
                        }
                        <Button
                            variant="contained"
                            onClick={() => saveSubCategories()}
                            className='loadingButtonStyle2'
                        >
                            {t('personal-info.save')}
                        </Button>
                    </div>
                </>
            </div>
            {showDeleteSubCategoryDialog && getDeleteSubCategoryDialog()}
        </Page>
    );
}