import { AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import Iconify from 'components/Iconify';
import { Line } from 'react-chartjs-2';
import { AccordionStyle } from './styled-components';

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};

export default function GeographicChart({ geographicTrendData = {} }) {
    const labels = Object.keys(geographicTrendData);

    const data = {
        labels,
        datasets: ['Canada', 'Australia', 'India', 'South Africa', 'United Kingdom', 'United States']
            .map((country) => ({
                label: country,
                data: labels.map((l) => geographicTrendData[l][country] || 0),
                fill: false,
                borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
            }))
            .concat({
                label: 'Others',
                data: labels.map((l) =>
                    Object.keys(geographicTrendData[l])
                        .filter(
                            (c) =>
                                ![
                                    'United States',
                                    'United Kingdom',
                                    'Canada',
                                    'Australia',
                                    'India',
                                    'South Africa',
                                ].includes(c)
                        )
                        .reduce((sum, c) => sum + (geographicTrendData[l][c] || 0), 0)
                ),
                fill: false,
                borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
            }),
    };
    return (
        <Grid
            container
            spacing={4}
            marginTop={2}
            className="marginSmTop"
            justifyContent="center"
            alignItems="flex-start"
            paddingLeft={4}
        >
            <AccordionStyle>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>Geographic Trend Report</Typography>
                    <Iconify icon="ant-design:plus-circle-outlined" sx={{ minWidth: 22, height: 22 }} />
                    <Iconify icon="akar-icons:circle-minus" sx={{ minWidth: 22, height: 22 }} />
                </AccordionSummary>
                <AccordionDetails>
                    <Line data={data} options={options} />
                </AccordionDetails>
            </AccordionStyle>
        </Grid>
    );
}
