import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import DialogAtom from "components/dialog/Dialog";
import { showError } from 'utils/toast';
import Page from '../../../components/Page';
import { ContentStyle } from './styled-components';
import EditHouseView from './house';
import transaction from '../../../constants/services/transaction';
import EditInvestmentsView from './investments';
import EditVehiclesView from './vehicles';
import EditDebtView from './debt';
import EditOthersView from './others';
import TopMenu from '../../../components/TopMenu';
import TopHeading from '../../../components/TopHeading';

export default function EditManualTransactions() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [categoryDetail, setCategoryDetail] = useState({});
    const [unAssignTemplateId, setUnassignDialogId] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const fetchInfo = async () => {
        try {
            const res = await transaction.getCategoryDetail(location?.state?.id);
            if (res?.data) {
                setCategoryDetail(res.data);
            }
        } catch (error) {
            showError(t, error);
        }
    };

    useEffect(() => {
        fetchInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSaveData = async (payload) => {
        setIsSaving(true);
        try {
            await transaction.saveTemplate(location?.state?.id, payload);
            setIsSaving(false);
            fetchInfo();
        } catch (error) {
            setIsSaving(false);
            showError(t, error);
        }
    };

    const onDialogAction = async (buttonKey) => {
        if (buttonKey === 'cancel') {
            setUnassignDialogId('');
        } else {
            setIsLoading(true);
            await transaction.unassignTemplate(unAssignTemplateId);
            setIsLoading(false);
            setUnassignDialogId('');
            navigate('/view-manual-transactions', { state: categoryDetail });
        }
    };

    const getUnassignDialog = () => <DialogAtom
        open
        maxWidth="sm"
        dialogTitle="Confirm Dialog"
        content={(
            <>
                Are you sure to unassign this template and delete the data?
            </>
        )}
        onDialogAction={onDialogAction}
        isLoading={isLoading}
    />

    const onUnassign = (subCategoryId) => {
        setUnassignDialogId(subCategoryId);
    };

    return (
        <Page title={t('edit-manual-transactions.title')}>
            <TopMenu title={location?.state?.name || t('edit-manual-transactions.title')} />
            <TopHeading
                heading={location?.state?.name || t('edit-manual-transactions.title')}
            />
            <ContentStyle templateName={categoryDetail?.template}>

                {categoryDetail?.template === 'HOUSE' &&
                    <EditHouseView
                        categoryDetail={categoryDetail}
                        onSaveData={onSaveData}
                        onUnassign={onUnassign}
                        isSaving={isSaving}
                    />}
                {categoryDetail?.template === 'INVESTMENT' &&
                    <EditInvestmentsView
                        categoryDetail={categoryDetail}
                        onSaveData={onSaveData}
                        onUnassign={onUnassign}
                        isSaving={isSaving}
                    />}
                {categoryDetail?.template === 'VEHICLE' &&
                    <EditVehiclesView
                        categoryDetail={categoryDetail}
                        onSaveData={onSaveData}
                        onUnassign={onUnassign}
                        isSaving={isSaving}
                    />}
                {categoryDetail?.template === 'DEBT' &&
                    <EditDebtView
                        categoryDetail={categoryDetail}
                        onSaveData={onSaveData}
                        onUnassign={onUnassign}
                        isSaving={isSaving}
                    />}
                {categoryDetail?.template === 'OTHERS' &&
                    <EditOthersView
                        categoryDetail={categoryDetail}
                        onSaveData={onSaveData}
                        onUnassign={onUnassign}
                        isSaving={isSaving}
                    />}

            </ContentStyle>
            {unAssignTemplateId ? getUnassignDialog() : ''}
        </Page>
    );
}
