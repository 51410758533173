import image from "assets/image";

const getProductDetails=(t)=>([
    {
        id: 1,
        icon: image.calculators,
        title:t('moolahmate-constant.title-1'),
        boldText:t('moolahmate-constant.boldText-1'),
        simpleText: t('moolahmate-constant.simpleText-1')
    },
    {
        id:2,
        icon: image.forums,
        title:t('moolahmate-constant.title-2'),
        boldText:t('moolahmate-constant.boldText-2'),
        simpleText:t('moolahmate-constant.simpleText-2')
    },
    {
        id:3,
        icon: image.knowledgeBase,
        title:t('moolahmate-constant.title-3'),
        boldText:t('moolahmate-constant.boldText-3'),
        simpleText:t('moolahmate-constant.simpleText-3')
    },
    {
        id:4,
        icon: image.toDos,
        title:t('moolahmate-constant.title-4'),
        boldText:t('moolahmate-constant.boldText-4'),
        simpleText:t('moolahmate-constant.simpleText-4')
    },
    {
        id:5,
        icon: image.keyDates,
        title:t('moolahmate-constant.title-5'),
        boldText:t('moolahmate-constant.boldText-5'),
        simpleText:t('moolahmate-constant.simpleText-5')
    },
    {
        id:6,
        icon: image.suggestionBox,
        title:t('moolahmate-constant.title-6'),
        boldText:t('moolahmate-constant.boldText-6'),
        simpleText:t('moolahmate-constant.simpleText-6')
    }
]);
export{
    getProductDetails
}
