import React from 'react';

export default function calenderIcon() {
    return (
        <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.71359 23.7467H19.5544C20.6088 23.7465 21.6197 23.3275 22.3652 22.5822C23.1106 21.8366 23.5296 20.8258 23.5298 19.7714V7.05042C23.5296 5.99626 23.1106 4.98521 22.3652 4.23985C21.6197 3.49431 20.6088 3.07547 19.5544 3.07511H17.1693V1.75013C17.1693 1.466 17.0177 1.20353 16.7717 1.06156C16.5258 0.919584 16.2226 0.919584 15.9767 1.06156C15.7307 1.20353 15.5791 1.46601 15.5791 1.75013V3.07511H8.6889V1.75013C8.6889 1.466 8.53734 1.20353 8.29137 1.06156C8.0454 0.919584 7.74228 0.919584 7.49631 1.06156C7.25033 1.20353 7.09878 1.46601 7.09878 1.75013V3.07511H4.71359C3.65925 3.07547 2.64838 3.49429 1.90284 4.23985C1.15748 4.98521 0.738463 5.99626 0.738281 7.05042V19.7714C0.738459 20.8258 1.15747 21.8366 1.90284 22.5822C2.64838 23.3275 3.65925 23.7465 4.71359 23.7467ZM15.8763 13.9729L11.636 18.2132V18.2134C11.4871 18.3626 11.285 18.4467 11.0741 18.4467C10.8631 18.4467 10.661 18.3626 10.5123 18.2134L8.39205 16.0931C8.20304 15.8901 8.1333 15.6033 8.20819 15.3362C8.28326 15.0693 8.49196 14.8606 8.75887 14.7856C9.02596 14.7107 9.31273 14.7804 9.51577 14.9694L11.0741 16.5267L14.7525 12.8494V12.8492C14.9556 12.6602 15.2424 12.5904 15.5094 12.6655C15.7764 12.7404 15.9851 12.9491 16.0601 13.2162C16.135 13.4833 16.0653 13.7701 15.8763 13.9729L15.8763 13.9729ZM2.67096 8.37568V7.15664C2.67043 6.54562 2.9125 5.95928 3.34409 5.52676C3.77569 5.0941 4.36136 4.8506 4.97237 4.84992H7.099V5.99049C7.099 6.27462 7.25056 6.53708 7.49653 6.67906C7.74251 6.82103 8.04563 6.82103 8.2916 6.67906C8.53757 6.53708 8.68913 6.27461 8.68913 5.99049V4.84992H15.5798L15.5796 5.99049C15.5796 6.27462 15.7312 6.53708 15.9772 6.67906C16.2231 6.82103 16.5263 6.82103 16.7722 6.67906C17.0182 6.53708 17.1698 6.27461 17.1698 5.99049V4.84992H19.2964C19.9074 4.85063 20.4931 5.09411 20.9247 5.52676C21.3563 5.95925 21.5983 6.54562 21.5978 7.15664V8.37568H2.67096Z"
                    fill="#0F9672"
                />
            </svg>
        </div>
    );
}