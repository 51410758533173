import request from '../../utils/request';

const websiteBlogList = (query) => request.get(`websites-blogs${query}`);
const addWebsiteBlog = (data) => request.post('websites-blogs', data);
const updateWebsiteBlog = (id, data) => request.put(`websites-blogs/${id}`, data);
const deleteWebsiteBlog = (id) => request.delete(`websites-blogs/${id}`);

export default {
    websiteBlogList,
    addWebsiteBlog,
    updateWebsiteBlog,
    deleteWebsiteBlog
};