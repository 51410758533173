import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { FormProvider, RHFTextField} from '../../components/hook-form';
import { showError, showToast } from '../../utils/toast';
import auth from '../../constants/services/auth';
import {RHFTextFieldMsg} from '../contact-us/ContactUs'

export default function ContactUsForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const userValidationSchema = Yup.object().shape({
        email: Yup.string().email(t('error-message.invalid-email')).required(t('error-message.email-required')),
    });

    const defaultValues = {
        name: '',
        email: '',
        subject: '',
        message: ''
    };

    const methods = useForm({
        resolver: yupResolver(userValidationSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);
       
            if (!isScriptExist) {
                const script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = () => {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }
       
            if (isScriptExist && callback) callback();
        }
       
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`, () => {});

        return () => {
            const script = document.getElementById('recaptcha-key');
            if (script) {
                script.remove();
            }
            const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
            if (recaptchaElems.length) {
                recaptchaElems[0].remove();
            }
        }
    }, []);

    const onSubmit = async (data, event) => {
        event.preventDefault();
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, { action: 'submit' }).then(async token => {
                await contactUsForm(token, data);
            });
        });
    };

    const contactUsForm = async (token, data) => {
        Object.assign(data, {token});
        try {
            await auth.contactUs(data);
            showToast('Message sent successfully', { type: 'success' });
            navigate('/', { replace: true });
        } catch (error) {
            showError(t, error);
        }
    };

    const ButtonText = styled('div')(({ theme }) => ({
        '& button': {
            textTransform: 'inherit',
            fontSize: 16,
            padding: '6px 16px !important',
            [theme.breakpoints.down('md')]: {
                fontSize: '14px !important',
            }
        },
    }));

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <RHFTextField
                name="name"
                label={'Name'}
            />
            <RHFTextField
                name="email"
                label={t('login-page.email-address')}
            />
            <RHFTextField
                name="subject"
                label={t('contact.subject')}
            />
            <div className='contactUsStyle'>
                <RHFTextFieldMsg 
                    name="message" 
                    label={'Message'} 
                />
            </div>            
            <ButtonText>
                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    className='loadingButtonStyle2'
                    variant="contained"
                    loading={isLoading}
                >
                    Submit
                </LoadingButton>
            </ButtonText>
        </FormProvider>
    );
}
