/* eslint-disable react/jsx-key */
import { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography, List } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';

import videoURL from 'constants/helpVideoUrl';
import { InfoIcon, LightTooltip } from 'theme/common-styles';
import onboarding from '../../../constants/services/onboarding';
import Page from '../../../components/Page';
import TopMenu from '../../../components/TopMenu';
import TopHeading from '../../../components/TopHeading';
import { DASHBOARD, INCOME_EXPENSE, GENERAL_REPORTS } from '../constant';
import { getSearchValues, noDataFound } from '../../../utils/calCommonFunction'

export const NavLinkbtn = styled('button')(() => ({
    outline: 'none',
    background: 'none',
    height: '100%',
    border: 'none',
    cursor: 'pointer'
}));

export default function AllReports() {
    const [categoryGroupVal, setCategoryGroupVal] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [expenseCategoryGroupId, setExpenseCategoryGroupId] = useState(null);

    const [generalReport, setGeneralReport] = useState(GENERAL_REPORTS)
    const [assetLiabilityReports, setAssetLiabilityReports] = useState(DASHBOARD)
    const [incomeExpenseReports, setIncomeExpenseReports] = useState(INCOME_EXPENSE)
    const [viewStyle, setViewStyle] = useState('grid')

    const { t } = useTranslation();
    const navigate = useNavigate();

    const getMasterData = async () => {
        const response = await onboarding.getMasterData();
        if (response.data) {
            const categoryGroupdata = response.data;
            const groupDataArr = {};
            // eslint-disable-next-line array-callback-return
            categoryGroupdata.map((val) => {
                groupDataArr[val.name] = val.id;
            });
            setCategoryGroupVal(groupDataArr);
            const catExpenseGroupId = groupDataArr['Expenses/Cash Outflows'];
            setExpenseCategoryGroupId(catExpenseGroupId);
        }
    };

    const getReportGridView = (record) =>
        record.map((items, indx) => (
            <Grid key={indx} item xs={12} sm={6} md={3} alignItems={'center'} justifyContent={'center'}>
                {/* eslint-disable-next-line */}
                <div
                    className="hubCardStyle allReportStyle"
                    onClick={(e) => {
                        if (e.target.innerText)
                            navigate(`${items.path}`, { state: {
                                categoryGroupId: `${categoryGroupVal[items.groupName]}`,
                                expenseCategoryId: `${expenseCategoryGroupId}`,
                            }, })
                        
                    }}
                >
                    <NavLinkbtn
                        className='flexDisplay'
                        style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center' }}
                    >
                        <div>
                            <Typography variant="body1" className="descriptionHeading" mt={0}>
                                {items.title}
                            </Typography>
                        </div>
                        {items.helpVideo && 
                            <LightTooltip
                                title={
                                    <>
                                        <p>{t('help-video.tooltip')}</p>
                                    </>
                                }
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open(items.helpVideo, "_blank");
                                }}
                            >
                                <InfoIcon src="/static/video-solid.svg" alt="" style={{ marginLeft: 5, marginTop: 6 }} />
                            </LightTooltip>
                        }
                    </NavLinkbtn>
                </div>
            </Grid>
        ));

    const getDashboardListView = (record) => record.map((items, indx) => (
        <List key={indx} className='hubListViewStyle'>
            <div className="hubCardStyle allReportStyle">
                <NavLinkbtn
                    onClick={(e) => {
                        // eslint-disable-next-line no-debugger
                        debugger;
                        if (e.target.innerText)
                            navigate(`${items.path}`, { state: {
                                categoryGroupId: `${categoryGroupVal[items.groupName]}`,
                                expenseCategoryId: `${expenseCategoryGroupId}`,
                            }, })
                    }}
                    className='flexDisplay'
                    style={{ justifyContent: 'space-between', width: '100%' }}
                >
                    <div>
                        <Typography variant="body1" className="descriptionHeading" mt={0}>
                            {items.title}
                        </Typography>
                    </div>
                    {items.helpVideo && 
                        <LightTooltip
                            title={
                                <>
                                    <p>{t('help-video.tooltip')}</p>
                                </>
                            }
                            onClick={(e) => {
                                e.preventDefault();
                                window.open(items.helpVideo, "_blank");
                            }}
                        >
                            <InfoIcon src="/static/video-solid.svg" alt="" style={{ marginLeft: 5, marginTop: 6 }} />
                        </LightTooltip>
                    }
                </NavLinkbtn>
            </div>
        </List>
    ))
    
    const getSearchResultDetails = () => {
        if (searchValue) {
            const primaryResultValue = getSearchValues(GENERAL_REPORTS, searchValue);
            const secondaryModulesResultValue = getSearchValues(DASHBOARD, searchValue)
            const extraModulesResultValue = getSearchValues(INCOME_EXPENSE, searchValue)
            setGeneralReport(primaryResultValue)
            setAssetLiabilityReports(secondaryModulesResultValue)
            setIncomeExpenseReports(extraModulesResultValue)
        }
        else {
            setGeneralReport(GENERAL_REPORTS)
            setAssetLiabilityReports(DASHBOARD)
            setIncomeExpenseReports(INCOME_EXPENSE)
        }
    }

    const deboundHubSearch = debounce(() => {
        getSearchResultDetails();
    }, 500);

    useEffect(() => {
        deboundHubSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        getMasterData();
    }, []);

    return (
        <Page title={t('dashboard.title')}>
            <TopMenu title="Reports Hub" videoLink={videoURL.reports} />
            <TopHeading
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                heading={<Trans i18nKey="dashboard.description">
                Review reports <br />and take control of your finances
                </Trans>}
                viewStyle={viewStyle}
                setViewStyle={setViewStyle}
                showSearch
                hideBackLink
            />
            <div className="hubPadding hubCommonMargin">
                <Typography className="dashboardSubHeading" style={{ paddingTop: '0px' }}>
                    {' '}
                    {t('dashboard.general-reports')}
                </Typography>
                <Grid container spacing={2} mt={0} alignItems="flex-start">
                    {viewStyle === 'grid' ? (
                        getReportGridView(generalReport)
                    ) : (
                        <div className="listViewStyle">{getDashboardListView(generalReport)}</div>
                    )}
                    {generalReport.length ? '' : noDataFound()}
                </Grid>

                <Typography className="dashboardSubHeading" style={{ paddingTop: '32px' }}>{t('dashboard.report-label-1')}</Typography>
                <Grid container spacing={2} mt={0} alignItems="flex-start">
                    {viewStyle === 'grid' ? (
                        getReportGridView(assetLiabilityReports)
                    ) : (
                        <div className="listViewStyle">{getDashboardListView(assetLiabilityReports)}</div>
                    )}
                    {assetLiabilityReports.length ? '' : noDataFound()}
                </Grid>

                <Typography className="dashboardSubHeading" style={{ paddingTop: '32px' }}>{t('dashboard.report-label-2')}</Typography>
                <Grid container spacing={2} mt={0} alignItems="flex-start">
                    {viewStyle === 'grid' ? (
                        getReportGridView(incomeExpenseReports)
                    ) : (
                        <div className="listViewStyle">{getDashboardListView(incomeExpenseReports)}</div>
                    )}
                    {incomeExpenseReports.length ? '' : noDataFound()}
                </Grid>
            </div>
        </Page>
    );
}
