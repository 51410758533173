export default function DataPrivacyModalContent() {
    return (
        <div className="why-love-mm-dialog-padding">
            <ul>
                <li>
                    When you connect to MoolahMate you will notice the “https” in the URL. This means that this is a{' '}
                    <b>highly secure 256-bit encrypted link</b> similar to what the banks use when you connect to them.
                </li>
                <li>
                    The MoolahMate <b>servers are hosted at AWS</b> (Amazon Web Services) which is one of the largest
                    and most reputable hosting service with best-in-class security.
                </li>
                <li>
                    <b>All of your personal data is anonymized</b> when it is entered in our database. What this means
                    is that we, including even our database administrators, cannot see whose data is in the database.
                </li>
                <li>
                    The <b>login credentials</b> entered by you to link your accounts to MoolahMate are{' '}
                    <b>neither entered nor stored in MoolahMate.</b> Instead, they{' '}
                    <b>are entered in an i-frame of our API Partner</b> that is a very large and reputable industry
                    leader in this space. It is <b>their system that accesses the banks and retrieves the data</b>. You
                    can find details about the security policy of the API Partner{' '}
                    <a href="https://solutions.yodlee.com/fastlink-terms.html" target="_blank" rel="noreferrer">
                        here
                    </a>
                    .
                </li>
                <li>Our Privacy Policy is listed here.</li>
                <li>
                    And to give you additional comfort, we had <b>our systems reviewed by an independent third party</b>{' '}
                    who conducted extensive due diligence and produced the following reports and, in each case,{' '}
                    <b>found the system to be complaint with the latest industry standards:</b>
                    <ul style={{ listStyleType: 'circle' }}>
                        <li>Web application security assessment.</li>
                        <li>Security code analysis</li>
                        <li>External vulnerability assessment and penetration testing.</li>
                    </ul>
                </li>
            </ul>
        </div>
    );
}
