const breakpoints = {
    values: {
        xs: 0,
        xm: 425,
        sm: 600,
        xmd: 768,
        md: 900,
        lg: 1200,
        xlg: 1560,
        llg: 1700,
        xl: 1900,
    },
};
export default breakpoints;
