import image from 'assets/image';

const getCalculatorDetails = (t) => [
    {
        id: 1,
        title: t('moolahMateCalculators-constant.title-1'),
        text: t('moolahMateCalculators-constant.text-1'),
        link: '/calculators-hub/mortgage-payment',
    },
    {
        id: 2,
        title: t('moolahMateCalculators-constant.title-2'),
        text: t('moolahMateCalculators-constant.text-2'),
        link: '/calculators-hub/loan-payment',
    },
    {
        id: 3,
        title: t('moolahMateCalculators-constant.title-3'),
        text: t('moolahMateCalculators-constant.text-3'),
        link: '/calculators-hub/retirement',
    },
    {
        id: 4,
        title: t('moolahMateCalculators-constant.title-4'),
        text: t('moolahMateCalculators-constant.text-4'),
        link: '/calculators-hub/emergency-fund',
    },
    {
        id: 5,
        title: t('moolahMateCalculators-constant.title-5'),
        text: t('moolahMateCalculators-constant.text-5'),
        link: '/calculators-hub/future-value-savings',
    },
];

const getOtherAssetsDetails = (t) => [
    {
        id: 1,
        title: t('moolahMateCalculators-constant.title-6'),
        icon: image.downloadIcon,
        statusImg: image.correctIcon,
        text: t('moolahMateCalculators-constant.text-6'),
    },
    {
        id: 1,
        title: t('moolahMateCalculators-constant.title-7'),
        icon: image.otherTrans,
        statusImg: image.wrongIcon,
        text: t('moolahMateCalculators-constant.text-7'),
    },
    {
        id: 1,
        title: t('moolahMateCalculators-constant.title-8'),
        icon: image.keyDateIcon,
        statusImg: '',
        text: t('moolahMateCalculators-constant.text-8'),
    },
    {
        id: 1,
        title: t('moolahMateCalculators-constant.title-9'),
        icon: image.balanceSheet,
        statusImg: '',
        text: t('moolahMateCalculators-constant.text-9'),
    },
];

export { getCalculatorDetails, getOtherAssetsDetails };
