// @mui
import { LoadingButton } from '@mui/lab';
import { Grid, Link, TextField, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// components
import '../../assets/css/common.css';
import videoURL from 'constants/helpVideoUrl';
import Page from '../../components/Page';
import TopHeading from '../../components/TopHeading';
import TopMenu from '../../components/TopMenu';
import knowledgebase from '../../constants/services/knowledgebase';
import { API_URL_WITHOUT_CONTEXTPATH } from '../../constants/url';
import { noDataFound } from '../../utils/calCommonFunction';
import './style.css';

export default function KnowledgeBasePage() {
    const ALL_TOPICS = 'All';
    const SELECTED_TOPICS = 'SELECTED_TOPICS';
    const NUMBER_OF_BLOGS = 'NUMBER_OF_BLOGS';
    const DEFAULT_PAGE_SIZE = 8;
    const [topics, setTopics] = useState([ALL_TOPICS]);
    const [blogs, setBlogs] = useState([]);
    const [numberOfBlogs, setNumberOfBlogs] = useState(DEFAULT_PAGE_SIZE);
    const [blogsFound, setBlogsFound] = useState(0);
    const [selectedTopics, setSelectedTopics] = useState(ALL_TOPICS);
    const [searchText, setSearchText] = useState('');
    const [isLogged, setIsLogged] = useState(false)
    const [tableLoader, setTableLoader] = useState(false);

    const deboundedSearch = debounce(() => {
        getBlogs(selectedTopics, numberOfBlogs);
    }, 500);

    useEffect(() => {
        deboundedSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    useEffect(() => {
        if (sessionStorage.getItem('userData')) {
            const user = JSON.parse(sessionStorage.getItem('userData'));
            if (Object.keys(user).length > 0) {
                setIsLogged(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTopics = async () => {
        setTableLoader(true)
        const topicsData = await knowledgebase.getTopics();
        const topicList = [ALL_TOPICS];
        if (topicsData.data) {
            topicsData.data.forEach((topic) => {
                topicList.push(topic.attributes.title);
            });
        }
        setTopics(topicList);
        let numberOfBlogToBeFetched = numberOfBlogs;
        let topicsSelected = topicList;
        if (localStorage.getItem(SELECTED_TOPICS)) {
            topicsSelected = localStorage.getItem(SELECTED_TOPICS).split(',');
            numberOfBlogToBeFetched = localStorage.getItem(NUMBER_OF_BLOGS).split(',');
            localStorage.removeItem(SELECTED_TOPICS);
            localStorage.removeItem(NUMBER_OF_BLOGS);
        }
        setSelectedTopics(topicsSelected);
        setNumberOfBlogs(numberOfBlogToBeFetched);
        await getBlogs(topicsSelected, numberOfBlogToBeFetched);
        setTableLoader(false)
    };

    const getBlogs = async (topicList, pageSize) => {
        if (topicList.length === 0) {
            setBlogs([]);
            return;
        }
        if (typeof (topicList) === 'string') {
            topicList = []
        }
        const checkedTopics = topicList.filter((e) => e !== ALL_TOPICS);
        const blogsData = await knowledgebase.getBlogs(checkedTopics, searchText, pageSize);
        const blogsList = [];
        if (blogsData.data) {
            blogsData.data.forEach((blog) => {
                blogsList.push({
                    id: blog.id,
                    title: blog.attributes.title,
                    topic: blog.attributes.topic,
                    headerImage: blog.attributes.headerImage,
                });
            });
            setBlogsFound(blogsData.meta.pagination.total);
        }
        setBlogs(blogsList);
    };

    useEffect(() => {
        getTopics();
    }, []);

    const updateLocalStorage = () => {
        localStorage.setItem(SELECTED_TOPICS, selectedTopics);
        localStorage.setItem(NUMBER_OF_BLOGS, numberOfBlogs);
    };

    const { t } = useTranslation();

    const checkBoxCheckedData = (value, data) => {
        let checkedTopics = [];
        if (data) {
            if (value === ALL_TOPICS) {
                checkedTopics = topics;
            } else {
                checkedTopics = [value, ...selectedTopics];
            }
        } else if (value === ALL_TOPICS) {
            checkedTopics = [];
        } else {
            checkedTopics = selectedTopics.filter((e) => e !== ALL_TOPICS);
            checkedTopics = checkedTopics.filter((e) => e !== value);
        }

        setSelectedTopics(checkedTopics);
        setNumberOfBlogs(DEFAULT_PAGE_SIZE);
        getBlogs(checkedTopics, DEFAULT_PAGE_SIZE);
    };

    const loadMore = () => {
        if (blogs && blogsFound >= numberOfBlogs) {
            const pageSize = numberOfBlogs + DEFAULT_PAGE_SIZE;
            setNumberOfBlogs(pageSize);
            getBlogs(selectedTopics, pageSize);
        }
    };

    return (
        <Page title={"MoolahMate's knowledge base hub: financial learning made easy"} description={"Expand your knowledge of financial concepts, terms and more with MoolahMate's knowledge base hub. MoolahMate makes financial learning easy."} style={isLogged ? {} : { padding: '110px 8% 0px' }}>
            {isLogged && <TopMenu title={t('knowledgebase.title')} videoLink={videoURL.knowledgeBase} />}
            <TopHeading heading={t('welcome-constant.description-7')} headingCenter={!isLogged} hideBackLink />
            <div className="hubPadding">
                <div className="navCalStyle knowledge-base-filters">
                    {topics.map((topic, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    className="checkboxStyle"
                                    checked={selectedTopics.indexOf(topic) !== -1}
                                    onChange={(e) => checkBoxCheckedData(topic, e.target.checked)}
                                />
                            }
                            label={
                                <Typography variant="h4" className="commonHeading" style={{ marginLeft: 10 }}>
                                    {topic}
                                </Typography>
                            }
                        />
                    ))}
                </div>
                <div className="flexDisplayItems buttonMarginStyle">
                    <Typography variant="body" className="labelStyle">
                        {t('knowledgebase.Select-All')}
                    </Typography>
                    <TextField
                        value={searchText}
                        name="search"
                        className="textInputStyle"
                        placeholder="Enter text here..."
                        onChange={(e) => setSearchText(e.target.value)}
                        InputProps={{
                            classes: {
                                borderColor: 'orange',
                            },
                        }}
                    />
                </div>
                {
                    // eslint-disable-next-line no-nested-ternary
                    tableLoader ? <img alt="homeInfo" src="/static/animation_loader.gif" className='loaderGif' />
                        :
                        blogs.length > 0 ? (
                            <><Grid container spacing={3} pt={5} pb={5}>
                                {blogs.map((cal, key) => {
                                    const image = cal.headerImage?.data?.attributes?.url
                                        ? `${API_URL_WITHOUT_CONTEXTPATH}${cal.headerImage?.data?.attributes?.url}`
                                        : '/static/homeImg/fullHomeBanner.png';
                                    const imageAltText = cal.headerImage?.data?.attributes?.name || '';
                                    const { title } = cal;
                                    const topic = cal.topic?.data?.attributes?.title || '';
                                    return (
                                        <Grid item lg={3} md={6} sm={6} xs={12} key={key}>
                                            <Link
                                                href={isLogged ? `/knowledge-base-detail/${cal.id}` : `/learn-detail/${cal.id}`}
                                                underline="none"
                                                onClick={() => updateLocalStorage()}
                                            >
                                                <div className="carouselCardStyle">
                                                    <img alt={imageAltText || ''} src={image} />
                                                    <div className="carouselDivStyle">
                                                        <Typography variant="h4" className="moreDetailsTxt" title={title}>
                                                            {title}
                                                        </Typography>
                                                        <LoadingButton
                                                            className="carouselBtnStyle"
                                                            type="submit"
                                                            variant="outlined"
                                                        >
                                                            {topic}
                                                        </LoadingButton>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Grid>
                                    );
                                })}
                            </Grid><>
                                {blogsFound >= numberOfBlogs && (
                                    <div className="divLoadButton">
                                        <LoadingButton
                                            size="large"
                                            variant="contained"
                                            onClick={() => loadMore()}
                                            className="loadingButtonStyle2"
                                        >
                                            {t('knowledgebase.Load-more')}
                                        </LoadingButton>
                                    </div>
                                )}
                            </></>

                        ) : (
                            <Grid pt={2} pb={4} style={{ justifyItems: 'center' }}>
                                {noDataFound()}
                            </Grid>
                        )
                }
            </div>
        </Page>
    );
}
