import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TopMenu from '../../../components/TopMenu';
import onboarding from '../../../constants/services/onboarding';
import { ContentStack, ProceedingBtn, ProfileData, RootStyle } from './styled-components';

export default function ProfileSummary() {
    const navigate = useNavigate();
    const [primaryData, setPrimaryData] = useState([]);
    const [secondaryData, setSecondaryData] = useState([]);

    const getUserInfo = async (userId) => {
        const profileSummary = await onboarding.getUserProfileSummary(userId);
        const details = profileSummary.data;
        const firstName = details?.firstName || '';
        const lastName = details?.lastName || '';
        const email = details?.email || '';
        const username = details?.username || '';
        const { membershipNumber, validUntil: expirationDate } = details?.subscriptions?.[0];

        const { city, state, country, createdAt, houseHoldPeople, gender, ageGroup } = details?.user_detail || {
            city: '',
            state: '',
            country: '',
            createdAt: new Date(),
            houseHoldPeople: '',
            gender: '',
            ageGroup: '',
        };
        const localData = [
            {
                title: 'Name: ',
                description: firstName.concat(' ').concat(lastName),
            },
            {
                title: 'Membership Number: ',
                description: membershipNumber,
            },
            {
                title: 'Email Address: ',
                description: email,
            },
            {
                title: 'Username: ',
                description: username,
            },
            {
                title: 'Location: ',
                description: [city, state, country].join(', '),
            },
        ];
        const extraDetails = [
            {
                title: 'Gender: ',
                description: gender,
            },
            {
                title: 'Age Group: ',
                description: ageGroup,
            },
            {
                title: 'Customer Since: ',
                description: new Date(createdAt).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                }),
            },
            {
                title: 'Subscription Expiry Date: ',
                description: new Date(expirationDate).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                }),
            },
            {
                title: 'Number of people in household: ',
                description: houseHoldPeople,
            },
        ];
        setPrimaryData(localData);
        setSecondaryData(extraDetails);
    };

    useEffect(() => {
        const userDataSession = sessionStorage.getItem('userData');
        const userId = JSON.parse(userDataSession).id;
        getUserInfo(userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigateToDashboard = () => navigate('/welcome', { replace: true });
    const navigateToPersonal = () => navigate('/my-profile', { replace: true });

    return (
        <RootStyle>
            <div className="mdCustomPadding">
                <TopMenu title={'Profile summary'} hideHelp />
            </div>
            <div className="customAlignPaddingStyle">
                <Grid container item spacing={2} marginTop={0} marginLeft={0} width={'100%'}>
                    <Grid item xs={12} style={{ padding: '0px' }}>
                        <div style={{ marginBottom: '26px' }}>
                            <Typography variant="h4" className="titleStyle">
                                Here is your profile summary
                            </Typography>
                        </div>
                        <Grid container item spacing={2} marginTop={0} marginLeft={0} width={'100%'}>
                            <Grid item lg={6} xs={12} style={{ padding: '0px' }}>
                                <ContentStack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    spacing={2}
                                >
                                    {primaryData.map((user) => (
                                        <ProfileData key={user.title}>
                                            <span className="pTitle">{user.title}</span>
                                            <span className="pDescription">{user.description}</span>
                                        </ProfileData>
                                    ))}
                                </ContentStack>
                            </Grid>
                            <Grid item lg={6} xs={12} className="mdPaddingTop" style={{ padding: '0px' }}>
                                <ContentStack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    spacing={2}
                                >
                                    {secondaryData.map((user) => (
                                        <ProfileData key={user.title}>
                                            <span className="pTitle">{user.title}</span>
                                            <span className="pDescription">{user.description}</span>
                                        </ProfileData>
                                    ))}
                                </ContentStack>
                            </Grid>
                            <ProceedingBtn>
                                <LoadingButton
                                    fullWidth
                                    size="large"
                                    variant="outlined"
                                    onClick={navigateToPersonal}
                                    className="loadingButtonStyle1"
                                >
                                    Edit Profile
                                </LoadingButton>
                                <LoadingButton
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    onClick={navigateToDashboard}
                                    className="loadingButtonStyle2"
                                >
                                    Proceed to the Hub
                                </LoadingButton>
                            </ProceedingBtn>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </RootStyle>
    );
}
