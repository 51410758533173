import request from '../../utils/request';

const getYodleeToken = (data) => request.get('fi/token', data);
const downloadTransaction = () => request.get('fi/load-account-data');
const deleteAccount = (id) => request.delete(`fi/account/${id}`);
const deleteProviderAccounts = (id) => request.delete(`fi/provider-account/${id}`);
const isAccountEligibleForRefresh = (id) => request.post(`fi/update-eliglible/${id}`);

export default {
    getYodleeToken,
    downloadTransaction,
    deleteAccount,
    deleteProviderAccounts,
    isAccountEligibleForRefresh
};