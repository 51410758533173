import { useTranslation } from 'react-i18next';
import { TableStyle, DiffTableContent, CenterTbText, BudgetInputTwo } from '../styled-components';

export default function OthersView({ currencySymbol }) {
    const { t } = useTranslation();

    const getBudgetInputTwo = (value, negative = false, sty = {}) => <BudgetInputTwo style={{ fontWeight: 600, paddingLeft: 0, ...sty }}>
        <span>{negative ? '-': ''}{currencySymbol}</span>
        <div>{value}</div>
    </BudgetInputTwo>

    return (
        <div className='divTableStyle'>
            <TableStyle style={{ padding: '0px 2%', marginTop: '1%', borderSpacing: 15 }}>
                <tbody>
                    <tr>
                        <td style={{ color: '#0F9672', fontWeight: '600', textAlign: 'left' }}>
                            {t('view-manual-transactions.description')}
                        </td>
                        <DiffTableContent style={{ textAlign: 'left' }}>{t('view-manual-transactions.date')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'right' }}>{t('view-manual-transactions.increase')}</DiffTableContent>
                        <DiffTableContent style={{ color: 'red', textAlign: 'right' }}>{t('view-manual-transactions.decrease')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'right' }}>{t('view-manual-transactions.balance')}</DiffTableContent>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            {t('view-manual-transactions.opening-balance')}
                        </td>
                        <CenterTbText>Sep 21, 2021</CenterTbText>
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('50,000')}
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            {t('view-manual-transactions.value-change')}
                        </td>
                        <CenterTbText>Mar 11, 2023</CenterTbText>
                        {getBudgetInputTwo('10,000')}
                        <CenterTbText />
                        {getBudgetInputTwo('60,000')}
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left' }}>
                            {t('view-manual-transactions.value-change')}
                        </td>
                        <CenterTbText>Dec 31, 2023</CenterTbText>
                        <CenterTbText />
                        {getBudgetInputTwo('5,000', true, { color: 'red' })}
                        {getBudgetInputTwo('55,000')}
                    </tr>
                    <tr>
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('10,000', false, { borderTop: 'solid 1px #0F9672', borderBottom: ' solid 3px #0F9672', paddingTop: 12, paddingBottom: 12 })}
                        {getBudgetInputTwo('5,000', true, { color: 'red', borderTop: 'solid 1px #0F9672', borderBottom: ' solid 3px #0F9672', paddingTop: 12, paddingBottom: 12 })}
                    </tr>
                    <tr/>
                    <tr>
                        <td colSpan={4} style={{ borderSpacing: 10 }}>
                            <CenterTbText style={{  fontWeight: 700,
                                fontSize: 18,
                                color: '#0F9672' }}>{t('view-manual-transactions.net-inc-dec')}</CenterTbText>
                            <CenterTbText />    
                        </td>
                        {getBudgetInputTwo('5,000', false, { borderTop: 'solid 1px #0F9672', borderBottom: ' solid 3px #0F9672', paddingBottom: 0, paddingTop: 0 })}
                    </tr>
                </tbody>
            </TableStyle>
        </div>
    );
}
