import { React } from 'react';
import { TextField, Grid } from '@mui/material';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getCurrencySymbol } from 'utils/calCommonFunction';
import calenderIcon from './CalenderIcon';
import { MainBox, AutoFieldStyle, Input, DateGrid, InputStyle, GroupHeader, GroupItems } from './styled-components';

export default function EditForm({ transactionData, updateTransactionData, userCategories = [] }) {
    const { transactionDate, payee, category, subCategory, tag, amount } = transactionData || {};
    return (
        <MainBox>
            <Grid container spacing={2}>
                <Grid item md={5} xs={12}>
                    <Input shrink htmlFor="bootstrap-input">
                        Date
                    </Input>
                </Grid>
                <DateGrid item md={7} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            PaperProps={{ className: 'customDateSelector' }}
                            inputFormat="MMM dd, yyyy"
                            value={moment.parseZone(transactionDate).format('MM/DD/YYYY')}
                            components={{
                                OpenPickerIcon: calenderIcon,
                            }}
                            onChange={(newValue) => {
                                updateTransactionData('transactionDate', newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </DateGrid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={5} xs={12}>
                    <Input shrink htmlFor="bootstrap-input">
                        Payee/Merchant{' '}
                    </Input>
                </Grid>
                <Grid item md={7} xs={12}>
                    <InputStyle
                        name="Payee"
                        value={payee}
                        onChange={(e) => updateTransactionData('payee', e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={5} xs={12}>
                    <Input shrink htmlFor="bootstrap-input">
                        Amount{' '}
                    </Input>
                </Grid>
                <Grid item md={7} xs={12}>
                    <Input shrink htmlFor="bootstrap-input" style={{ paddingBottom: 30, fontSize: 14 }}>
                        {getCurrencySymbol()}
                        {'  '}
                        {amount?.amount}
                    </Input>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={5} xs={12}>
                    <Input shrink htmlFor="bootstrap-input">
                        Category
                    </Input>
                </Grid>
                <Grid item md={7} xs={12}>
                    <AutoFieldStyle
                        disableClearable
                        options={userCategories}
                        getOptionLabel={(option) => option?.name || ''}
                        groupBy={(option) => option.categoryGroupName}
                        value={category}
                        onChange={(e, value) => {
                            updateTransactionData('category', value);
                        }}
                        id="tags-outlined"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                style={{ fontSize: 14 }}
                                placeholder="Select Category"
                                className="dropMenu"
                            />
                        )}
                        renderGroup={(params) => (
                            <li key={params.key}>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                            </li>
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={5} xs={12}>
                    <Input shrink htmlFor="bootstrap-input">
                        Sub-Category{' '}
                    </Input>
                </Grid>
                <Grid item md={7} xs={12}>
                    <AutoFieldStyle
                        disableClearable
                        options={category?.sub_categories.filter((s) => s.isSelected) || []}
                        getOptionLabel={(option) => option?.name || ''}
                        value={subCategory}
                        onChange={(e, value) => {
                            updateTransactionData('subCategory', value);
                        }}
                        id="tags-outlined"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder="Select Sub Category"
                                className="dropMenu"
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={5} xs={12}>
                    <Input shrink htmlFor="bootstrap-input">
                        Tag{' '}
                    </Input>
                </Grid>
                <Grid item md={7} xs={12}>
                    <AutoFieldStyle
                        options={['Income tax related', 'Business expense']}
                        value={tag}
                        id="tags-outlined"
                        onChange={(e, val) => {
                            updateTransactionData('tag', val);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} variant="standard" placeholder="Select Tag" className="dropMenu" />
                        )}
                    />
                </Grid>
            </Grid>
        </MainBox>
    );
}
