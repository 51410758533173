import request from '../../utils/request';

const listFinancialGoals = (query) => request.get(`financial-goals${query}`);
const addFinancialGoal = (data) => request.post('financial-goals', data);
const updateFinancialGoal = (id, data) => request.put(`financial-goals/${id}`, data);
const deleteFinancialGoal = (id) => request.delete(`financial-goals/${id}`);

export default {
    listFinancialGoals,
    addFinancialGoal,
    updateFinancialGoal,
    deleteFinancialGoal
};