import { Grid, styled, Autocomplete, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const Wrapper = styled('div')(({ theme }) => ({
    background: 'url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '80px',
    },
}));

export const PageTopRightBg = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    background: 'url(/static/loginImg/loginLeftBottomBg.svg) 120% 40px no-repeat',
    height: 500,
    width: 675,
    top: 0,
    right: 0,
    backgroundSize: '100%',
    transform: 'rotate(180deg)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px',
        backgroundPosition: '-50px 100%',
    },
}));

export const ContentStyle = styled('div')(({ theme }) => ({
    padding: '20px 24% 20px 4%',
    [theme.breakpoints.down('lg')]: {
        padding: '20px 4%',
    },
    [theme.breakpoints.down('md')]: {
        padding: '0px 20px 4% !important'
    },
}));

export const PaymentTitle = styled('h4')(({theme}) => ({
    fontSize: '16px',
    lineHeight: '24px',
    color: '#27272A',
    textAlign: 'center',
    borderBottom: '1px solid #27272A',
    paddingBottom: '20px',
    marginBottom: '24px',
}));

export const HeaderDivStyle = styled('div')(({theme}) => ({
    width: '100%',
    padding: '42px 44px 62px 66px',
    fontWeight: 400,
    fontSize: 16,
    background: '#F8F8F8',
    borderRadius: '10px',
    marginTop: '0px',
    [theme.breakpoints.down('md')]: {
        padding: '24px',
    }
}));

export const HeaderTableStyle = styled('table')(({ theme }) => ({
    width: '100%',
    borderRadius: '10px',
    padding: '0px 12% 0px 9%',
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
        padding: 10,
        fontSize: 14,
        minWidth: 500,
    },

    '& th': {
        textAlign: 'left',
        paddingBottom: '4px',
        color: theme.palette.primary.dark,
        fontSize: 18,
        fontWeight: 700,
        [theme.breakpoints.down('md')]: {
            padding: '6px 0px',
            fontSize: 16,
        },
    },
    '& td': {
        paddingTop: 9,
        paddingBottom: 10,
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            padding: '6px 0px',
            fontSize: 14,
        },
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
}));

export const TableStyle = styled('table')(({ theme }) => ({
    width: '100%',
    marginTop: '0px',
    padding: '42px 14% 62px 15%',
    fontWeight: 400,
    fontSize: 16,
    [theme.breakpoints.down('llg')]: {
        padding: '42px 15% 62px 15%',
    },
    [theme.breakpoints.down('md')]: {
        padding: '20px !important',
        fontSize: '14px',
        minWidth: 500,
        margin: '0px'
    },
    '& th': {
        textAlign: 'left',
        paddingBottom: '4px',
        color: theme.palette.primary.dark,
        fontSize: 18,
        fontWeight: 700,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 16,
        },
    },
    '& td': {
        paddingTop: 9,
        paddingBottom: 10,
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 14,
        },
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
}));

export const MidSideColumn = styled('td')(() => ({
    textAlign: 'right !important',
    fontWeight: '600'
}));

export const TopSideBar = styled('td')(() => ({
    color: '#0F9672',
    fontWeight: '600'
}));

export const TopSideColumn = styled('td')(() => ({
    fontWeight: '600',
    color: '#0F9672',
    textAlign: 'right !important'
}));

export const TotalFlow = styled('td')(() => ({
    fontWeight: 700,
    fontSize: 18,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#0F9672'
}));

export const TotalSubAmount = styled('td')(() => ({
    textAlign: 'end'
}));

export const TotalSubFlow = styled('td')(() => ({
    fontWeight: 700,
    fontSize: 16,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#0F9672',
    textAlign: 'end'
}));

export const BackButtonStyle = styled(Button)(() => ({
    color: '#fff',
    marginBottom: '15px',
    padding: '5px 10px',
    fontSize: '13px',
    fontWeight: '300',
    textAlign:' center',
    textTransform: 'none',
}))

export const CalculatorButton = styled(Button)(({theme}) => ({
    marginBottom: '0px',
    padding: '5px 18px',
    fontSize: '13px',
    fontWeight: '300',
    height: '40px !important',
    marginTop: '15px',
    width: '151px',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
        width: '100px',
        marginRight: '-10px'
    }
}))

export const FromMinimumText = styled('div')(() => ({
    marginRight: '33px',
    '& input': {
        textAlign: 'center !important',
        fontSize: '14px !important',
        width: '54px !important'
    }
}))

export const BudgetInput = styled('td')(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '151px',
    marginLeft: 'auto',
    '& span': {        
        position: 'absolute',
        zIndex: '1'
    },
    '& input': {
        textAlign: 'right',
        fontSize: '14px',
        width: '151px',
    },
}));

export const BudgetSubInputs = styled('td')(() => ({
    padding: '0px !important',
    fontWeight: '700',
    color: '#0F9672',
    width: '52px'
}));

export const BudgetSubTotals = styled('span')(() => ({
    border: '0px !important',
    alignItems: 'center'
}));

export const BudgetSpacing = styled('span')(() => ({
    marginLeft: '18px'
}));

export const ExtraSpacing = styled('tbody')(() => ({
    display: 'block',
    marginTop: '14px !important',
}));

export const TotalFlowHeader = styled('td')(() => ({
    textAlign: 'left',
    paddingBottom: '4px',
    color: '#27272A',
    fontSize: '18px',
    fontWeight: '700'
}));

export const BudgetPercentageInput = styled('td')(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '151px',
    marginLeft: 'auto',
    '& span': {
        marginLeft: -10,
        position: 'relative',
        zIndex: '1'
    },
    '& input': {
        textAlign: 'right',
        fontSize: '14px',
        width: '128px',
        padding: '4px 12px 5px'
    },
}));

export const TotalFlowColumn = styled('td')(({ theme }) => ({
    fontWeight: 700,
    fontSize: 18,
    textAlign: 'right !important',
    paddingTop: 0,
    position: 'relative',
    paddingBottom: 0,
    '& div': {
        padding: '10px 0 10px 2px',
        borderTop: `solid 3px ${theme.palette.primary.main}`,
        // borderBottom: `solid 3px ${theme.palette.primary.main}`,
        width: 'fit-content',
        marginLeft: 'auto'
    },
    '& span': {
        padding: '10px 0 10px 2px',
        borderTop: `solid 1px ${theme.palette.primary.main}`,
        borderBottom: `solid 3px ${theme.palette.primary.main}`,
    }
}));

export const TotalSubHeadingColumn = styled('td')(({theme}) => ({
    fontWeight: '700 !important',
    fontSize: '16px !important',
    lineHeight: '24px !important',
    // textAlign: 'end !important',
    color: '#0F9672 !important',
    marginLeft: '20px',
    [theme.breakpoints.down('sm')]: {
        textAlign: 'end'
    },
}));

export const AutoFieldStyle = styled(Autocomplete)(({ theme }) => ({
    fontSize: '15px',
    // paddingBottom: '8px',
    width: '149px',
    marginLeft: 'auto',
    [theme.breakpoints.up('llg')]: {
        paddingBottom: '30px',
    },
    '& input': {
        [theme.breakpoints.up('xl')]: {
            fontSize: '15px',
            textAlign: 'end'
        },
    },
    '& .MuiInputBase-root': {
        '&.Mui-focused:after': {
            transform: 'scaleX(0) !important',
        },
    },
    '& .MuiAutocomplete-popupIndicator': {
        position: 'relative',
        width: 25,
        fontSize: '15px',
        '& svg': {
            display: 'none',
        },
        '&:after': {
            position: 'absolute',
            right: 0,
            top: -4,
            content: "''",
            background: 'url(/static/loginImg/down-arrow.svg) 0 0 no-repeat',
            width: 25,
            height: 13,
            backgroundSize: '16px'
        },
    },
}));

export const ButtonText = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'relative',
    padding: '40px 0px',
    [theme.breakpoints.down('sm')]: {
        display: 'block',
        '& .centerAlignStyle': {
            display: 'block',
        }
    },
    '& button': {
        marginRight: '12px',
        maxWidth: 140,
        [theme.breakpoints.down('sm')]: {
            margin: '0px',
            maxWidth: 'inherit',
            marginBottom: '20px',
        },
    },
}));

export const RetirementInput = styled('td')(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '151px',
    '& span': {        
        position: 'absolute',
        zIndex: '1'
    },
    '& input': {
        textAlign: 'right',
        fontSize: '14px',
        width: '151px',
    },
}));

export const RetirementPercentageInput = styled('td')(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '151px',
    '& span': {
        marginLeft: -10,
        position: 'relative',
        zIndex: '1'
    },
    '& input': {
        textAlign: 'right',
        fontSize: '14px',
        width: '128px',
        padding: '4px 12px 5px'
    },
}));

export const InfoIcon = styled('img')(({ theme, ...props }) => ({
    display: 'revert',
    width: '16px',
    cursor: 'pointer',
    marginLeft: '2px',
    // position: 'absolute',
    // top: '12px',
    ...props,
    [theme.breakpoints.down('md')]: {
        // margin: '2px 0 0 -16px',
        width: "16px",
    },
    [theme.breakpoints.down('sm')]: {
        margin: '0px 0 0 7px',
    },
}));

export const DiffTableContent = styled('td')(({ theme }) => ({
    color: '#0F9672',
    fontWeight: '600',
    textAlign: 'right',
}));

export const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        boxShadow: theme.shadows[1],
        fontSize: '12px',
        padding: 10,
        fontWeight: 400,
        maxWidth: '491px'
    },
}));

// project budget

export const FullTableStyle = styled('table')(({ theme }) => ({
    width: '100%',
    borderRadius: '10px',
    // padding: '0px 12% 0px 9%',
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
        padding: 10,
        fontSize: 12,
        minWidth: 500,
    },

    '& th': {
        textAlign: 'left',
        paddingBottom: '4px',
        color: theme.palette.primary.dark,
        fontSize: '15px',
        width: 'auto',
        fontWeight: 700,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
    },
    '& td': {
        paddingTop: 9,
        paddingBottom: 10,
        position: 'relative',
        fontSize: '14px',
        paddingRight: '9px',
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
}));

export const FullContentStyle = styled('div')(({ theme }) => ({
    padding: '40px 10%',
    [theme.breakpoints.down('xl')]: {
        padding: '20px 4%',
    },
}));

export const DivCalTableStyle = styled('div')(() => ({
    width: '100%',
    padding: '42px 25px 62px 25px',
    fontWeight: 400,
    fontSize: 16,
    background: '#F8F8F8',
    borderRadius: '10px',
    marginTop: '0px',
}));

export const CalculatorInput = styled('td')(() => ({
    width: 'auto',
    '& span': {
        marginRight: -10,
        position: 'relative',
        zIndex: '1'
    },
    '& input': {
        textAlign: 'right',
        width: '110px',
        fontSize: '14px',
    },
}));

export const CalculatorSubInput = styled('td')(() => ({
    width: 'auto',
    '& span': {
        marginRight: -10,
        position: 'relative',
        zIndex: '1'
    },
    '& input': {
        textAlign: 'right',
        width: '80px',
    },
}));

export const NumberAutoFieldStyle = styled(Autocomplete)(({ theme }) => ({
    fontSize: '15px',
    width: '56px',
    marginLeft: 'auto',
    [theme.breakpoints.up('llg')]: {
        paddingBottom: '30px',
    },
    '& input': {
        [theme.breakpoints.up('xl')]: {
            fontSize: '15px',
            textAlign: 'center'
        },
        textAlign: 'center',
    },
    '& .MuiInputBase-root': {
        '&.Mui-focused:after': {
            transform: 'scaleX(0) !important',
        },
    },
    '& .MuiAutocomplete-popupIndicator': {
        position: 'relative',
        width: 25,
        fontSize: '15px',
        '& svg': {
            display: 'none',
        },
        '&:after': {
            position: 'absolute',
            right: 0,
            top: -4,
            content: "''",
            background: 'url(/static/loginImg/down-arrow.svg) 0 0 no-repeat',
            width: '18px',
            height: 13,
            backgroundSize: '14px'
        },
    },
}));

export const InputNumberField = styled('td')(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '151px',
    // marginLeft: 'auto',
    '& span': {
        position: 'absolute',
        zIndex: '1'
    },
    '& input': {
        textAlign: 'right',
        fontSize: '14px',
        width: '151px',
    },
}));

export const InputNumberSubField = styled('td')(() => ({
    width: '111px !important',
    textAlign: 'center',
    '& span': {
        mosition: 'relative',
        zIndex: '1',
        marginRight: '12px !important',
        marginLeft: '20px'
    },
    '& input': {
        textAlign: 'center',
        width: '60px',
    },
}));

export const ProjectTotalValue = styled('span')(() => ({
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: "121px",
    borderBottom: 'solid 1px rgba(145, 158, 171, 0.56)',
    // marginLeft: 'auto',
    padding: '4px 0px',
    fontWeight: '600'
}));

export const CenterTbText = styled('td')(({ props }) => ({
    textAlign: 'center',
    ...props
}));

export const RightTbText = styled('td')(({ props }) => ({
    textAlign: 'right',
    ...props
}));

export const AddMoreTbBUtton = styled('td')(() => ({
    paddingLeft: '12px',
    paddingTop: '14px'
}));

export const CenterTbHeading = styled('td')(() => ({
    paddingBottom: '0px !important',
    fontWeight: '600',
}));

export const CenterTbSubHeading = styled('td')(() => ({
    textAlign: 'center',
    paddingBottom: '0px !important',
    fontWeight: '600',
    borderBottom: '1px solid black'
}));

export const TotalFlowValueColumn = styled('td')(({theme}) => ({
    display:'flex',
    justifyContent:'end',
    '& div':{
        padding: '7px 0 7px 2px',
        borderTop: 'solid 1px #0F9672',
        borderBottom: ' solid 3px #0F9672',
        fontSize: '16px',
        fontWeight: '600',
        width: '151px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            fontWeight: '600',
            width: '100px'
        },
    },
}));

export const TotalFlowValueMultipleColumn = styled('td')(() => ({
    '& div':{
        padding: '7px 0 7px 1px',
        borderTop: 'solid 1px #0F9672',
        borderBottom: ' solid 3px #0F9672',
        fontSize: '16px',
        fontWeight: '600',
        width: '130px',
    }
}));

export const TotalFlowValueSubColumn = styled('td')(({theme}) => ({
    '& div':{
        justifyContent: 'space-between',
        padding: '7px 0 7px 2px',
        borderTop: 'solid 1px #0F9672',
        borderBottom: ' solid 3px #0F9672',
        fontSize: '16px',
        fontWeight: '600',
        width: '151px',
        marginLeft:'auto',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            fontWeight: '600',
            width: '100px'
        },
    }
}));

export const BudgetInputNumber = styled('td')(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        paddingRight: '20px',
    },
    '& span': {
        position: 'absolute',
        zIndex: '1'
    },
    '& input': {
        textAlign: 'right',
        fontSize: '14px',
        width: '151px',
    },
}));

export const SpanTotalFlow = styled('td')(({theme}) => ({
    '& div': {
        padding: '7px 0 7px 1px',
        borderTop: 'solid 1px #0F9672',
        borderBottom: ' solid 3px #0F9672',
        fontSize: '16px',
        fontWeight: '600',
        width: '151px',
        marginLeft: 'auto',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            fontWeight: '600',
            width: '100px'
        },
        // marginLeft: '20px !important',
    }
}));

export const CalculatorInputNumber = styled('td')(() => ({
    // width: '151px',
    paddingLeft:'0px !important',
    '& span': {
        position: 'absolute',
        zIndex: '1'
    },
    '& input': {
        textAlign: 'start',
        fontSize: '14px',
        width: '140px',
    },
}));

export const CalculatorPriceInputs = styled('span')(() => ({
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: "151px",
    borderBottom: 'solid 1px rgba(145, 158, 171, 0.56)',
    marginLeft: 'auto',
}));

export const CalculatorInputs = styled('span')((pr) => ({
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: "151px",
    borderBottom: 'solid 1px rgba(145, 158, 171, 0.56)',
    // marginLeft: 'auto',
    ...pr
}));

export const AddMoreButton = styled(Button)(({ theme }) => ({
    color: '#0F9672',
    cursor: 'pointer',
    textDecoration: 'underline',
    width: 'auto',
    fontWeight: '500',
    fontSize: '14px',
    padding: '0px !important',
    [theme.breakpoints.down('sm')]: {
        width: '148px'
    }
}));

export const ExtraTdSpacing = styled('tr')(() => ({
    display: 'block',
    marginTop: '14px !important',
}));

export const ProjectInputNumber = styled('td')(() => ({
    paddingRight: '0px !important',
    '& span': {
        position: 'absolute',
        zIndex: '1',
        top: '13px'
    },
    '& input': {
        textAlign: 'end',
        fontSize: '14px',
        width: '130px',
    },
}));

export const TotalAmountDiv = styled('div')(() => ({
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 20
}));

export const AutoCompleteInputStyle = styled(Autocomplete)(({ theme }) => ({
    fontSize: '12px',
    width: '120px',
    [theme.breakpoints.up('llg')]: {
        // paddingBottom: '30px',
    },
    '& input': {
        fontSize: '14px',
        textAlign: 'start',
        [theme.breakpoints.up('xl')]: {
            fontSize: '14px',
            textAlign: 'start'
        },
    },
    '& .MuiInputBase-root': {
        '&.Mui-focused:after': {
            transform: 'scaleX(0) !important',
        },
    },
    '& .MuiAutocomplete-popupIndicator': {
        position: 'relative',
        width: 25,
        fontSize: '12px',
        '& svg': {
            display: 'none',
        },
        '&:after': {
            position: 'absolute',
            right: 0,
            top: -4,
            content: "''",
            background: 'url(/static/loginImg/down-arrow.svg) 0 0 no-repeat',
            width: 25,
            height: 13,
            backgroundSize: '16px'
        },
    },
}));

export const DebtRepaymentTh = styled('th')(() => ({
    width: '111px !important',
}));

export const CalculatorPercentageInput = styled('td')(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '80px',
    paddingRight: '0px !important',
    '& span': {
        marginLeft: -10,
        position: 'relative',
        zIndex: '1'
    },
    '& input': {
        textAlign: 'right',
        fontSize: '14px',
        width: '57px',
        padding: '5px 12px 5px'
    },
}));

export const TotalSubFlowColumn = styled('td')(({ theme }) => ({
    fontWeight: 700,
    fontSize: 18,
    textAlign: 'right !important',
    paddingTop: 0,
    position: 'relative',
    paddingBottom: 0,
    '& div': {
        padding: '10px 0 10px 2px',
        borderTop: `solid 3px ${theme.palette.primary.main}`,
        width: 'fit-content',
        marginLeft: 'auto'
    },
    '& span': {
        padding: '10px 0 10px 2px',
        borderBottom: `solid 3px ${theme.palette.primary.main}`,
    }
}));

export const BackButton = styled(Link)(() => ({
    textDecoration: 'none',
    fontSize: '18px',
    margin: '0 12px',
    fontWeight: '500',
    minWidth: '64px',
    padding: '7px 21px',
    border: '1px solid rgba(0, 119, 62, 0.5)',
    color: '#0F9672',
    width: '100%',
    textAlign:' center',
    height: '48px',
    borderRadius: '8px'
}))

export const AutoCompleteDebtInputStyle = styled(Autocomplete)(({ theme }) => ({
    fontSize: '12px',
    width: '120px',
    [theme.breakpoints.up('llg')]: {
        width: '140px',
    },
    '& input': {
        fontSize: '14px',
        textAlign: 'start',
        [theme.breakpoints.up('xl')]: {
            fontSize: '14px',
            textAlign: 'start'
        },
    },
    '& .MuiInputBase-root': {
        '&.Mui-focused:after': {
            transform: 'scaleX(0) !important',
        },
    },
    '& .MuiAutocomplete-popupIndicator': {
        position: 'relative',
        width: 25,
        fontSize: '12px',
        '& svg': {
            display: 'none',
        },
        '&:after': {
            position: 'absolute',
            right: 0,
            top: -4,
            content: "''",
            background: 'url(/static/loginImg/down-arrow.svg) 0 0 no-repeat',
            width: 25,
            height: 13,
            backgroundSize: '16px'
        },
    },
}));
