import { styled } from '@mui/material';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

let theme = createTheme();
theme = responsiveFontSizes(theme);

const TitleStyle = styled('div')(({ theme }) => ({
    textAlign: 'center',

    '& h6': {
        fontWeight: 700,
        color: theme.palette.primary.main,
        position: 'relative',
        display: 'inline-block',
        margin: 'auto',
        '&:before': {
            position: 'absolute',
            top: '50%',
            width: '50px',
            height: '1px',
            background: theme.palette.primary.main,
            content: "' '",
            left: -65,
        },
        '&:after': {
            position: 'absolute',
            width: '50px',
            height: '1px',
            background: theme.palette.primary.main,
            content: "' '",
            right: -65,
            top: '50%',
        },
    },
    '& h3': {
        paddingTop: '17px',
        fontWeight: 700,
        color: theme.palette.primary.contrastText,
    },
}));

export default function Title(props) {
    return (
        <TitleStyle>
            <ThemeProvider theme={theme}>
                <Typography variant="h6">{props.title}</Typography>
                <Typography variant="h3">{props.subtitle}</Typography>
            </ThemeProvider>
        </TitleStyle>
    );
}
