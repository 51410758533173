import { PayPalButtons } from '@paypal/react-paypal-js';
import DialogAtom from 'components/dialog/Dialog';
import { getCurrencySymbolByCurrencyCode } from 'constants/countryCurrencyData';
import payment from 'constants/services/payment';
import { AuthContext } from 'contexts/AuthProvider';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export const SubscribeButton = ({ plan, subscriptionStartDate }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const { logout, setAuth, setCurrencyMasterData } = useContext(AuthContext);

    const [message, setMessage] = useState('');
    const [transactionCompleted, setTransactionCompleted] = useState(false);
    const [loginResponse, setLoginResponse] = useState(null);

    const isSubscriptionWorkflow = location?.pathname === '/subscribe';

    const onCompletedDialogAction = (buttonKey) => {
        if (isSubscriptionWorkflow && buttonKey !== 'cancel') {
            setTransactionCompleted(false);
            navigate('/welcome', { replace: true });
            return;
        }

        if (!loginResponse) {
            logout();
            return;
        }
        const {
            email,
            firstName,
            lastName,
            token,
            id,
            onboardingStatus,
            localCurrency,
            username,
            currencyData,
            failedAccounts,
            role,
            subscriptionExpiryDuration,
        } = loginResponse;

        const currencySymbol = getCurrencySymbolByCurrencyCode(localCurrency);

        let showDashboardAccountDialog = false;
        if (failedAccounts?.length) {
            showDashboardAccountDialog = true;
        }
        const sessionData = {
            email,
            firstName,
            lastName,
            token,
            username,
            id,
            currencySymbol,
            localCurrency,
            failedAccounts,
            showDashboardAccountDialog,
            role,
            subscriptionExpiryDuration,
        };
        sessionStorage.setItem('userData', JSON.stringify(sessionData));
        setAuth(sessionData);
        localStorage.setItem('currencyData', JSON.stringify(currencyData));
        setCurrencyMasterData(currencyData);
        const notificationFlag = 'true';
        switch (onboardingStatus) {
            case 0:
                navigate('/setup', { replace: true });
                break;
            case 5:
                navigate('/welcome', { replace: true, state: { notificationFlag } });
                break;
            default:
                navigate('/onboarding', { replace: true, state: { onboardingStatus } });
        }
    };

    const payload = {
        plan_id: plan.paypalPlanId,
    };

    if (sessionStorage.getItem('userData')) {
        const user = JSON.parse(sessionStorage.getItem('userData'));
        payload.custom_id = user.id
    }

    if (subscriptionStartDate) {
        payload.start_time = subscriptionStartDate;
    }

    const onApproveCallback = async (data, planId) => {
        try {
            const { subscriptionID } = data;
            const payload = {
                subscriptionID,
                planId
            };
            const response = await payment.captureSubscription(payload);
            const orderData = response.data;

            const { loginResponse: loginResponseObj } = orderData;
            setLoginResponse(loginResponseObj || null);

            if (subscriptionID) {
                setMessage(`Subscription successful. Subscription ID: ${subscriptionID}.`);
                setTransactionCompleted(true);
            }
        } catch (error) {
            return `Sorry, your transaction could not be processed...${error}`;
        };
        
    };

    const getTransactionCompletedDialog = () => (
        <DialogAtom
            open
            maxWidth="sm"
            dialogTitle={t('payment-info.subscription-success')}
            content={<>{message}</>}
            onDialogAction={onCompletedDialogAction}
            saveButtonLabel={t('payment-info.ok')}
            hideCancelButton
        />
    );

    return (
        <>
            <PayPalButtons
                createSubscription={(data, actions) =>
                    actions.subscription.create(payload).then(
                        (orderId) =>
                            // Your code here after create the order
                            orderId
                    )
                }
                onApprove={async (data) => await onApproveCallback(data, plan.id)}
                style={{
                    label: 'subscribe',
                }}
            />
            {transactionCompleted && getTransactionCompletedDialog()}
        </>
    );
};
