import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import image from 'assets/image';
import PageSubHeadingStyle from '../../../components/PageSubHeadingStyle';

export default function DataSecurityPrivacy() {
    const { t } = useTranslation();

    return (
        <Grid className="sectionPaddingBanner" style={{ paddingBottom: '40px' }}>
            <Grid container spacing={2} pt={6} justifyContent={'center'}>
                <Grid item lg={12} md={12} className='dataPrivacayHeading'>
                    <PageSubHeadingStyle
                        headLineOne={t('home-page.headline-11')}
                        headLineTwo={t('home-page.subtitle-11')}
                    />
                </Grid>
                <Grid item xl={8} lg={8.4} md={12}>
                    <Typography variant="h5" className="addingSubHeading">
                        {t('home-page.sub-text-2')} <a className='boldText1Style' href='https://www.yodlee.com/' target="_blank" rel="noreferrer">{t('home-page.sub-text-3')}</a>{t('home-page.sub-text-4')}
                    </Typography>
                    <div className='flexPrivacyDivStyle'>
                        <div className='privacyDivStyle'>
                            <Typography variant='body1'>{t('home-page.privacy-headline-01')}</Typography>
                            <Typography variant='body'>{t('home-page.privacy-subtitle-01')}</Typography>
                        </div>
                        <div className='privacyDivStyle'>
                            <Typography variant='body1'>{t('home-page.privacy-headline-02')}</Typography>
                            <Typography variant='body'>{t('home-page.privacy-subtitle-02')}</Typography>
                        </div>
                        <div className='privacyDivStyle'>
                            <Typography variant='body1'>{t('home-page.privacy-headline-03')}</Typography>
                            <Typography variant='body'>{t('home-page.privacy-subtitle-03')}</Typography>
                        </div>
                    </div>
                    <Typography variant="body1" className="privacySubTxt">
                        {t('home-page.subtitle-12')} <a className='boldText1Style' href='/frequently-asked-questions'>{t('home-page.subtitle-13')}</a> {t('home-page.subtitle-14')} <a className='boldText1Style' href='https://www.yodlee.com/' target="_blank" rel="noreferrer">{t('home-page.subtitle-15')}</a>
                    </Typography>
                </Grid>
                <Grid item xl={4} lg={3.6} md={12} className='centerImgStyle'>
                    <img
                        src={image.dataSecurityMoolahmate}
                        alt="Header logo"
                        className='dataPrivacyImg'
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
