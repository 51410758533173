import { Grid, Typography } from '@mui/material';
import image from 'assets/image';
import { useTranslation } from 'react-i18next';
import HeadingStyle from './HeadingStyle';
import { getKnowledgeBaseDetails } from './constant';

export default function GetSmarter() {
    const { t } = useTranslation();
    const knowledgeBaseDetails = getKnowledgeBaseDetails(t);

    return (
        <div className='divOverflow'>
            <Grid className="sectionCommonPadding commonProductPadding bgColor" style={{ paddingTop: 0 }}>
                <Grid container spacing={2} pt={6} className='smPaddingStyle'>
                    <Grid item xl={6} md={6}>
                        <div className='getSmarterStyle'>
                            <Typography variant='h4'>{t('about-getSmater.headline-1')}</Typography>
                            {
                                knowledgeBaseDetails.map((item, indx) => (
                                    <div key={indx}>
                                        <HeadingStyle
                                            title={item.title}
                                            text={item.text}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </Grid>
                    <Grid item xl={6} md={6}>
                        <img src={image.getSmarterAnimation} alt='' />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
