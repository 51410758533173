import { Accordion, Divider, Table, styled } from '@mui/material';

export const TableStyle = styled(Table)(({ theme }) => ({
    width: '100%',
    position:'relative',
    paddingTop: '10px',
    minWidth: 550,
    borderRadius: '10px !important',
    borderSpacing: '0px !important',
    [theme.breakpoints.down('md')]: {
        paddingTop: 10,
    },
    '& tr': {
        cursor: 'pointer',
        '&:nth-child(odd)': {
            backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
        },
        '& .checkBox': {
            paddingLeft: '33px !important',
            [theme.breakpoints.down('md')]: {
                paddingLeft: '14px !important',
            },
        },
        '&.transaction-reviewed': {
            background: 'rgba(76, 175, 80, 0.1)'
        }
    },
    '& th': {
        textAlign: 'left',
        fontSize: 20,
        fontWeight: 700,
        color: '#27272A',
        padding: '20px 10px',
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
        '&.css-1crl80v-MuiTableCell-root': {
            lineHeight: '0.5rem',
        },
        '& .MuiTableSortLabel-root': {
            color: '#27272A',
        },
        '&.th-foreign-currency': {
            [theme.breakpoints.up('xl')]: {
                width: '18%',
            },
            [theme.breakpoints.down('xl')]: {
                width: '18%',
            }
        }
    },
    '& td': {
        padding: '10px',
        fontSize: 16,
        [theme.breakpoints.down('md')]: {
            padding: '15px 5px 30px',
            fontSize: 12,
        },
        '& span.foreign-currency-amount': {
            [theme.breakpoints.up('xl')]: {
                width: '68%',
            },
            [theme.breakpoints.down('xl')]: {
                width: '90%',
            }
        }
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
}));

export const DividerStyle = styled(Divider)(({ theme }) => ({
    position: "absolute",
    width: "100% !important",
    borderColor: '#0F9672',
    display: "inline-block",
    [theme.breakpoints.down('md')]: {
        width: '100% !important',
    },
}));

export const AccordionStyle = styled(Accordion)(({ theme }) => ({
    width: '100%',
    marginTop: 15,
    '&:before': {
        height: 0,
    },
    '& svg': {
        fill: theme.palette.primary.main,
        '& path': {
            fill: theme.palette.primary.main,
        },
        '& g': {
            stroke: theme.palette.primary.main,
        },
    },
    '& .MuiButtonBase-root': {
        borderBottom: `solid 2px #0F9672`,
        padding: 0,
        position: 'sticky',
        backgroundColor: '#f1f1f1',
        zIndex: '10',
        top: '0px',
        '& .MuiAccordionSummary-content': {
            margin: 0,
            padding: '26px 45px 25px',
            borderRadius: 10,
            alignItems: 'center',
            [theme.breakpoints.down('xl')]: {
                padding: 19,
            },
            '& p': {
                color: theme.palette.primary.dark,
                fontSize: 20,
                lineHeight: '27px',
                fontWeight: 700,
                marginRight: 'auto',
            },
            '& svg': {
                display: 'block',
            },
            '& svg + svg': {
                display: 'none',
            },
        },
        '& .Mui-expanded': {
            borderRadius: '10px 10px 0 0',
            '& svg': {
                display: 'none',
            },
            '& svg + svg': {
                display: 'block',
            },
        },
    },
    '& .MuiCollapse-root': {
        background: theme.palette.secondary.lighter,
        borderRadius: '0 0 10px 10px',
        padding: '0 45px 25px',
        [theme.breakpoints.down('xl')]: {
            padding: '0 19px 19px',
        },
        [theme.breakpoints.down('lg')]: {
            overflow: 'auto'
        },
        '& .MuiCollapse-wrapper': {
            // borderTop: `solid 2px #0F9672`,
            '& .MuiAccordionDetails-root': {
                padding: '0px !important',
                backgroundColor: 'white',
            },
        },
        '& .sticky-header': {
            position: 'sticky',
            backgroundColor: '#f8f8f8',
            zIndex: '9',
            top: '65px',
            [theme.breakpoints.down('lg')]: {
                position: 'inherit'
            },
        },
    },
}));