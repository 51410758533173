import { Typography, styled } from '@mui/material';

export const Error = styled(Typography)(({ theme }) => ({
    color: 'red',
    margin: '-30px 0px 5px 0px',
    height: 30,
    fontSize: 14,
    position: 'absolute',
    lineHeight: '14px',
    [theme.breakpoints.down('xl')]: {
        marginTop: -15,
        fontSize: 12,
    },
}));

export const HelperTextStyle = styled(Typography)(({ theme }) => ({
    color: 'rgb(99, 115, 129)',
    lineHeight: 1.5,
    fontSize: '0.75rem',
    fontWeight: 400,
    marginTop: '-20px',
    marginLeft: '3px',
    marginBottom: '14px'
}))

export const ErrorPwd = styled(Typography)(({ theme }) => ({
    color: 'red',
    margin: '-30px 0px 5px 0px',
    height: 'auto',
    fontSize: 14,
    // position: 'absolute',
    lineHeight: '14px',
    [theme.breakpoints.down('xl')]: {
        marginTop: -15,
        fontSize: 12,
    },
}));

export const ErrorTc = styled(Typography)(({ theme }) => ({
    color: 'red',
    margin: '-30px 0px 5px 0px',
    height: 30,
    fontSize: 14,
    position: 'absolute',
    lineHeight: '14px',
    [theme.breakpoints.down('xl')]: {
        marginTop: -16,
        fontSize: 12,
    },
}));

export const ButtonText = styled('div')(({ theme }) => ({
    '& button': {
        textTransform: 'inherit',
        fontSize: 16,
        fontWeight: '500',
        padding: '6px 16px',
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
        },
    },
}));

export const CheckStyle = styled('div')(({ theme }) => ({
    '& label': {
        marginRight: 5,
        '& span': {
            [theme.breakpoints.down('md')]: {
                fontSize: 12,
            },
        }
    },
    '& a': {
        cursor: 'pointer',
        fontSize: 16,
        color: theme.palette.primary.main,
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
        },
    },
}));

export const TncMobileStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        '& p': {
            fontSize: '14px'
        },
        '& li': {
            fontSize: '14px'
        }
    },
}))

export const DividerStyle = styled('div')(({ theme }) => ({
    background: theme.palette.primary.main,
    // margin: '64px 0',
    height: 2,
    border: 0,
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
        margin: '20px 0',
    },
    '& span': {
        position: 'absolute',
        top: -12,
        left: '50%',
        transform: 'translateX(-50%)',
        background: theme.palette.secondary.contrastText,
        padding: '0 30px',
    },
}));