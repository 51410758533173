const getFinancialPoints=(t)=>([
    {
        id: 1,
        text: t('insighReporting-constant.text-1')
    },
    {
        id: 1,
        text: t('insighReporting-constant.text-2')
    },
    {
        id: 1,
        text: t('insighReporting-constant.text-3')
    },
    {
        id: 1,
        text: t('insighReporting-constant.text-4')
    },
    {
        id: 1,
        text: t('insighReporting-constant.text-5')
    }
]);
export{
    getFinancialPoints
}