const StartedList = (t) => ([
    {
        id: 1,
        title: t('GetStarted-constant.title-1'),
        simpleText: t('GetStarted-constant.simpletext-1')
    },
    {
        id: 2,
        title: t('GetStarted-constant.title-2'),
        simpleText: t('GetStarted-constant.simpletext-2')
    },
    {
        id: 3,
        title: t('GetStarted-constant.title-3'),
        simpleText: t('GetStarted-constant.simpletext-3')
    }
]);

export {
    StartedList
}