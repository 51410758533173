import * as XLSX from 'sheetjs-style';
import moment from 'moment';
import { Typography, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const formatDollarValues = (val) => {
    const value = val.replace(/[^.0-9-]+/g, '');
    const total = value.split('.');
    // eslint-disable-next-line
    const appendValue = total[1] !== undefined ? total[1] == '' ? '.' : '.' + total[1] : '';
    return (Number(total[0]).toLocaleString("en-US")).toString() + appendValue;
}

export const formatZeroValues = (num) => {
    const formateNumber = removeNumberFormatting(num);
    const zeroAdded = Number(formateNumber).toLocaleString("en", { minimumFractionDigits: 2 })
    return zeroAdded;
}

export const noDataFound = () => <Typography variant='body1' className='noDataFound'>No Data Found</Typography>;

export const goBackToMenu = (navigate) => <Button className='backToMenuStyle' onClick={() => navigate(-1)} style={{ fontSize: '14px', marginTop: 0 }}><ArrowBackIcon /> Back</Button>

// eslint-disable-next-line
export const removeNumberFormatting = (val) => {
    return val?.replace(/,/g, '');
}

export const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    // eslint-disable-next-line
    return month + '/' + day + '/' + year;
}

export const downloadExcelHeader = (data, style, cols, merges, fileName, start, alignColArray, skipHeader) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data, { skipHeader });
    for (let i = start; i <= data.length; i += 1) {
        alignColArray.forEach(item => {
            ws[`${item}${i}`].s = {
                alignment: {
                    horizontal: 'right'
                }
            }
        })
    }
    style.forEach(item => {
        ws[item.col].s = item.style;
    })
    ws['!cols'] = cols;
    const rowSpace = [{ hpt: 20 }];
    data.forEach(() => {
        rowSpace.push({ hpt: 20 })
    });
    ws['!rows'] = rowSpace;
    ws["!merges"] = merges;
    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
    XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export const exportCustomExcel = (data, style, cols, merges, fileName, start, alignColArray, skipHeader, origin) => {
    const wb = XLSX.utils.book_new();
    let ws = null;
    let length = 0;
    data.forEach((val, key) => {
        if (!key) {
            ws = XLSX.utils.json_to_sheet(data[key], { skipHeader: skipHeader[key] });
        } else {
            XLSX.utils.sheet_add_json(ws, data[key], { skipHeader: skipHeader[key], origin: origin[key] });
        }
        length += val.length;
    })
    // XLSX.utils.sheet_add_json(ws, data[1], { skipHeader: false, origin: -1 });
    // XLSX.utils.sheet_add_json(ws, data[2], { skipHeader: true, origin: -1 });
    // for (let i = start; i <= data.length; i += 1) {
    //     alignColArray.forEach(item => {
    //         ws[`${item}${i}`].s = {
    //             alignment: {
    //                 horizontal: 'right'
    //             }
    //         }
    //     })
    // }
    style.forEach(item => {
        ws[item.col].s = item.style;
    })
    ws['!cols'] = cols;
    const rowSpace = [{ hpt: 20 }];
    // data.forEach(() => {
    //     rowSpace.push({ hpt: 20 })
    // });
    for (let i = 1; i < length+data.length; i+=1) {
        rowSpace.push({ hpt: 20 })
    }
    ws['!rows'] = rowSpace;
    ws["!merges"] = merges;
    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
    XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export const getCurrencySymbol = () => {
    const userSessObj = JSON.parse(sessionStorage.getItem('userData'));
    if (userSessObj && userSessObj.currencySymbol) {
        return userSessObj.currencySymbol === 'CAD' ? '$' : userSessObj.currencySymbol;
    }
    return '$';
}

export const getCurrency = () => {
    const userSessObj = JSON.parse(sessionStorage.getItem('userData'));
    return userSessObj?.localCurrency || 'CAD';
}

export const calcPMT = (rate, nperiod, pv, fv, type) => {
    if (!fv) fv = 0;
    if (!type) type = 0;

    if (nperiod <= 0) return 0;
    if (rate === 0) return -(pv + fv) / nperiod;

    const pvif = ((1 + rate) ** nperiod);
    let pmt = rate / (pvif - 1) * -(pv * pvif + fv);

    if (type === 1) {
        pmt /= (1 + rate);
    };
    return pmt;
};

export const getAccountingAmount = (amount, currencyLocale = 'en-US', options = {}) => {
    const absValue = Math.abs(amount);
    const formattedAmount = Number(absValue).toLocaleString(currencyLocale, options);
    return amount < 0 ? `(${formattedAmount})` : formattedAmount;
};

export const removeEmpty = (obj) => Object.fromEntries(
    Object.entries(obj)
        .filter(([, v]) => v != null)
        .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
)

export const getMonthNameYear = (value, format = 'MMM YY') => {
    if (value !== null) {
        return  moment.parseZone(value).format(format);
    }
    return '';
}

export const getDateMonthsRange = (startDate, endDate) => {
    const start = startDate.split('-');
    const end = endDate.split('-');
    const startYear = Number(start[0]);
    const endYear = Number(end[0]);
    const dates = [];

    for (let i = startYear; i <= endYear; i += 1) {
        const endMonth = i !== endYear ? 11 : Number(end[1]) - 1;
        const startMon = i === startYear ? Number(start[1]) - 1 : 0;
        for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
            const month = j + 1;
            const displayMonth = month < 10 ? `0${month}` : month;
            dates.push([i, displayMonth].join('-'));
        }
    }
    return dates;
}

export const getPositiveNumber = (arg, roundValue = false, showFractionDigits = true) => {
    let options = {};
    if (showFractionDigits) {
        options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
    }
    let amountNum = Number(arg) || 0;
    amountNum = Math.abs(amountNum);
    if (roundValue) {
        amountNum = Math.floor(amountNum);
    }
    if (arg < 0) {
        return `(${amountNum.toLocaleString("en-US", options)})`;
    }
    return amountNum.toLocaleString("en-US", options);
}

export const capitalizeText = (text) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()

export const getNegativeNumber = (arg, roundValue = false, showFractionDigits = true) => {
    let options = {};
    if (showFractionDigits) {
        options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
    }
    let amountNum = Number(arg) || 0;
    amountNum = Math.abs(amountNum);
    if (roundValue) {
        amountNum = Math.floor(amountNum);
    }
    if (arg > 0) {
        return `(${amountNum.toLocaleString("en-US", options)})`;
    }
    return amountNum.toLocaleString("en-US", options);
}

export const formatCreditRecords = (rawRecords) => {
    const result = {};
    Object.values(rawRecords)
        .flat()
        .forEach((record) => {
            const dataKeys = ['total_credit_line_local_currency', 'running_balance_local_currency', 'available_credit_local_currency'];
            dataKeys.forEach((dataKey) => {
                if (!result[dataKey]) {
                    result[dataKey] = [];
                }
                const dataValue = result[dataKey];
                if (!dataValue.find(({ accountId }) => accountId === record.account_id)) {
                    dataValue.push({
                        accountId: record.account_id,
                        providerName: record.provider_name,
                        accountName: record.account_name,
                        records: [],
                    });
                }
                const providerData = dataValue.find(({ accountId }) => accountId === record.account_id);
                providerData.records.push({
                    date: record.date,
                    amount: record[dataKey] ? record[dataKey].amount : 0,
                });
            });
        });
    return result;
};

export const getCurrencyMultiplicationFactor = (sourceCurrency, targetCurrency) => {
    const currencyData = JSON.parse(localStorage.getItem('currencyData')) || [];
    const sourceCurrencyValue = currencyData.find((c) => c.target === sourceCurrency)?.value || 1;
    const targetCurrencyValue = currencyData.find((c) => c.target === targetCurrency)?.value || 1;

    return (1 / sourceCurrencyValue) * targetCurrencyValue;
};

export const getSearchValues = (arg, value) => {
    const searchItem = value.toLowerCase()
    const searchListValue = arg.filter((entry) =>
        Object.entries(entry).some(([key, val]) => (['title', 'description', 'name'].includes(key)) && (typeof val === 'string' && val.toLowerCase().includes(searchItem)))
    );
    return searchListValue;
}