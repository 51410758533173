import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import PageSubHeadingStyle from '../../../components/PageSubHeadingStyle';
import InfoProvider from './InfoProvider';
import { INFO_POINTER } from './constant';

export default function HowWeSecureData() {
    const { t } = useTranslation();

    return (
        <Grid className="sectionCommonPadding bgColor">
            <Grid container spacing={2} justifyContent={'center'}>
                <Grid item lg={12} md={12} className='dataPrivacayHeading'>
                    <PageSubHeadingStyle
                        headLineOne={t('security.how-we-secure-data')}
                    />
                </Grid>
                <Grid item xl={12} lg={12} md={12}>
                    <div>
                        {INFO_POINTER.map((point) => (
                            <InfoProvider
                                key={point.id}
                                pointerLineOne={point.id}
                                pointerLineTwo={point.title}
                            />
                        ))}
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}
