/* eslint-disable jsx-a11y/click-events-have-key-events */
import { styled, Button, Grid, Typography, Autocomplete, TextField } from '@mui/material';
import onboarding from 'constants/services/onboarding';
import yodlee from 'constants/services/yodlee';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { showError } from 'utils/toast';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Page from 'components/Page';
import videoURL from 'constants/helpVideoUrl';
import TopHeading from '../../../components/TopHeading';
import TopMenu from '../../../components/TopMenu';
import { goBackToMenu } from '../../../utils/calCommonFunction';
import { HeadArea } from '../category/styled-components';
import LinkedAccountInfo from './LinkedAccountInfo';
import { FastLinkContainer } from './styled-component';
import { StyledGrid, Fragment } from '../personal-info/styled-components';
import { countryOptions, isAccesssTokenValid } from './constant';

const AutoFieldStyle = styled(Autocomplete)(({ theme }) => ({
    fontSize: 16,
    paddingBottom: '10px',
    '& input': {
        fontSize: 16,
        [theme.breakpoints.up('xl')]: {
            fontSize: 16,
        },
    },
    '& .MuiInputBase-root': {
        '&:hover:before': {
            borderBottom: '0 !important',
        },
        '&.Mui-focused:after': {
            transform: 'scaleX(0) !important',
        },
    },
    '& .MuiAutocomplete-popupIndicator': {
        position: 'relative',
        width: 25,
        '& svg': {
            display: 'none',
        },
        '&:after': {
            position: 'absolute',
            right: 0,
            top: -4,
            content: "''",
            background: 'url(/static/loginImg/down-arrow.svg) 0 0 no-repeat',
            width: 25,
            height: 13,
        },
    },
}));

const Input = styled('div')(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontSize: 16,
    marginTop: '10px',
    whiteSpace: 'normal',
    overflow: 'auto',
    textOverflow: 'unset',
}));

export default function FinancialInstitution() {
    const { t } = useTranslation();
    const [linkedInstitutions, setLinkedInstitutions] = useState([]);
    const [yodleeAccessToken, setYodleeAccessToken] = useState(null);
    const [showOverview, setShowOverview] = useState(true);
    const [selectedCountry, setSelectedCountry] = useState(countryOptions[0]);

    const navigate = useNavigate();
    const getFinancialInstitution = async () => {
        const response = await onboarding.getOnboardingData();
        setLinkedInstitutions(response?.data?.linkedInstitutions);
    }

    const refreshInstitutions = () => {
        getFinancialInstitution();
    }

    useEffect(() => {
        getFinancialInstitution();
    }, []);

    const saveFinancialInstution = async (financialInstitutions) => {
        try {
            const institutions = financialInstitutions.filter((fi) => fi.status === 'SUCCESS').map((fi) => ({
                providerAccountId: fi.providerAccountId,
                providerId: fi.providerId,
                providerName: fi.providerName,
                requestId: fi.requestId,
            }));
            if (institutions?.length) {
                const result = await onboarding.saveFinancialInstution({ institutions });
                setLinkedInstitutions(result?.linkedInstitutions || []);
            }
        } catch (error) {
            showError(t, error);
        }
    };

    const loadFastlinkContainer = async () => {
        try {
            window.fastlink.close();
            let accessTokenInfo = yodleeAccessToken;
            document.body.classList.add('loading-indicator');
            if (!isAccesssTokenValid(yodleeAccessToken)) {
                const result = await yodlee.getYodleeToken();
                accessTokenInfo = result?.token || null;
                setYodleeAccessToken(accessTokenInfo);
            }
            const { accessToken } = accessTokenInfo;
            const configName = selectedCountry?.value || process.env.REACT_APP_YODLEE_FASTLINK_CONFIG_NAME_CA;
            const obAppName = process.env.REACT_APP_YODLEE_FASTLINK_OB_CONFIG_NAME || '';
            const config = {
                fastLinkURL: process.env.REACT_APP_YODLEE_FASTLINK_URL,
                accessToken: `Bearer ${accessToken}`,
                // params: { configName, obAppName, isIFrameMounted: true },
                params: { configName, obAppName },
                onSuccess: (data) => {
                    console.log(data);
                    document.body.classList.remove('loading-indicator');
                },
                onError: (data) => {
                    console.error("fi onError:");
                    console.error(data);
                    if (data.code) {
                        setShowOverview(true);
                    }
                    if (data.message === 'FastLink already in use, multiple instances of fastLink may not work as expected.') {
                        window.fastlink.close();
                    }
                    document.body.classList.remove('loading-indicator');
                },
                onClose: async (data) => {
                    console.log("fi onClose:");
                    console.log(data);
                    if (data?.sites?.length) {
                        await saveFinancialInstution(data.sites);
                    }
                    setShowOverview(true);
                    document.body.classList.remove('loading-indicator');
                },
                onEvent: (data) => {
                    console.log(data);
                    if (data?.type === 'OPEN_EXTERNAL_URL' && data?.data?.source === 'LOGIN_TNC') {
                        window.open(data?.data?.url, '_blank')
                    }
                    document.body.classList.remove('loading-indicator');
                }
            }
            window.fastlink.open(config, "container-fastlink");
        } catch (error) {
            console.log(error);
            showError(t, error);
            document.body.classList.remove('loading-indicator');
        }
    }

    useEffect(() => {
        if (!showOverview) {
            loadFastlinkContainer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOverview, selectedCountry]);

    return (
        <Page title={t('link-institution.title')}>
            <TopMenu title='Link Financial Institutions' videoLink={videoURL.step2Video} />
            <TopHeading heading={t('link-institution.sub-heading')} hideBackLink />
            <Grid container spacing={2} className="hubPadding" marginLeft={0} width={'100%'}>
                <Grid item xs={12} style={{ padding: '0px' }}>
                    <Button className='backToMenuStyle' onClick={() => showOverview ? navigate(-1) : setShowOverview(true)}><ArrowBackIcon /> Back</Button>
                </Grid>
            </Grid>
            <div
                className='hubPadding'
                style={{
                    paddingTop: '32px'
                }}
            >
                {showOverview ? <>
                    <HeadArea>
                        <Typography variant='h6' className='commonText' style={{ paddingTop: 0 }}>{t('link-institution.description')}</Typography>
                    </HeadArea>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 15 }}>
                        <Button
                            variant="contained"
                            className='loadingButtonStyle2'
                            style={{ marginTop: 10, marginBottom: 15, textTransform: 'none' }}
                            onClick={() => setShowOverview(false)}>
                            {linkedInstitutions.length === 0 ? 'Link a financial institution' : 'Link more financial institution(s)'}
                        </Button>
                    </div>
                    <Grid container spacing={2} style={{ marginTop: 0, paddingTop: 16, marginLeft: '0px' }}>
                        <LinkedAccountInfo linkedInstitutions={linkedInstitutions} refreshInstitutions={refreshInstitutions} />
                    </Grid>
                </> : <Fragment>
                    <Grid container spacing={2}>
                        <Grid item lg={8} md={8} xs={12}>
                            <Typography variant="h4" className='titleStyle margintitleStyle'>{t('link-institution.sub-heading2')}</Typography>
                            <Typography variant="h6" className='commonText'>{t('link-institution.description')}</Typography>
                            <br />
                            <Typography variant="h5" className='error-text' style={{ fontSize: 18 }}>{t('link-institution.note')}</Typography>
                            <br />

                            <Grid item lg={6} md={12} xs={12} style={{ paddingTop: '10px' }}>
                                <StyledGrid container spacing={2} className='justifyContentStyle' style={{ justifyContent: 'start' }}>
                                    <Grid item sm={2} xs={12} className='mdPaddingTop' style={{ paddingTop: '0px' }}>
                                        <Input shrink htmlFor="bootstrap-input">
                                            {t('personal-info.country')}
                                        </Input>
                                    </Grid>
                                    <Grid item sm={8} xs={12} className='mdPaddingTop' style={{ paddingTop: '0px' }}>
                                        <AutoFieldStyle
                                            options={countryOptions}
                                            value={selectedCountry}
                                            getOptionLabel={(option) => option?.label}
                                            disableClearable
                                            onChange={(e, val) => setSelectedCountry(val)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    placeholder="Select Country"
                                                    className="dropMenu"
                                                    error=''
                                                    helperText=''
                                                />
                                            )}
                                        />
                                    </Grid>
                                </StyledGrid>
                            </Grid>
                            <div className='buttonMarginStyle'>
                                <FastLinkContainer id="container-fastlink" />
                            </div>
                        </Grid>
                    </Grid>
                </Fragment>}
                <div className='buttonMarginStyle' style={{ marginBottom: '32px' }}>
                    {
                        goBackToMenu(navigate)
                    }
                </div>
            </div>
        </Page>
    );
}
