const getOurVisionPoints =(t)=>([
    {
        id: 1,
        text: t('about-vision.text-1'),
    },
    {
        id: 2,
        text: t('about-vision.text-2'),
    },
    {
        id: 3,
        text: t('about-vision.text-3'),
    },
    {
        id: 4,
        text: t('about-vision.text-4'),
    },
    {
        id: 5,
        text: t('about-vision.text-5'),
    },
    {
        id: 6,
        text: t('about-vision.text-6'),
    },
    {
        id: 7,
        text: t('about-vision.text-7'),
    },
    {
        id: 8,
        text: t('about-vision.text-8'),
    },
]);

export{
    getOurVisionPoints
}
