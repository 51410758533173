import { styled, Grid } from '@mui/material';

export const templateOptions = [
    {
        label: "House",
        value: "HOUSE"
    },
    {
        label: "Investments",
        value: "INVESTMENT"
    },
    {
        label: "Vehicles",
        value: "VEHICLE"
    },
    {
        label: "Debt",
        value: "DEBT"
    },
    {
        label: "Other",
        value: "OTHERS"
    }
]

export const Wrapper = styled('div')(({ theme }) => ({
    paddingTop: '10px',
    background: 'url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '80px',
    },
    '& .dropMenu': {
        borderRadius: 10,
        backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
        padding: '6px 12px',
    },
    '& .MuiInputBase-root': {
        '&:before': {
            border: 0,
        },
    },
    // height: '100vh'
}));

export const PageTopRightBg = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    background: 'url(/static/loginImg/loginLeftBottomBg.svg) 120% 40px no-repeat',
    height: 500,
    width: 675,
    top: 0,
    right: 0,
    backgroundSize: '100%',
    transform: 'rotate(180deg)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px',
        backgroundPosition: '-50px 100%',
    },
}));

export const Content = styled('div')(() => ({
    paddingBottom: '50px',
}));

export const ContentStyle = styled('div')(({ theme, templateName }) => {
    let padding = '20px 26% 15% 4%'
    if (templateName === 'DEBT') {
        padding = '20px 28% 11% 4%';
    }
    if (templateName === 'OTHERS') {
        padding = '20px 36% 11% 4%';
    }
    return {
        padding,
        [theme.breakpoints.down('xl')]: {
            padding,
        },
        [theme.breakpoints.down('xlg')]: {
            padding: '20px 4% 5% 4%'
        },
        '& h1': {
            width: 400,
            marginBottom: 20,
        },
        '& h2': {
            display: 'none',
        },
    }
});

export const TableStyle = styled('table')(({ theme }) => ({
    width: '100%',
    marginTop: '-28px',
    padding: '42px 14% 62px 15%',
    fontWeight: 400,
    // background: '#fff',
    // border: '2px solid #155041',
    // borderRadius: '10px',
    fontSize: 16,
    [theme.breakpoints.down('llg')]: {
        padding: '42px 15% 62px 15%',
    },
    [theme.breakpoints.down('md')]: {
        padding: 10,
        fontSize: 12,
        minWidth: 500,
    },
    '& th': {
        textAlign: 'left',
        paddingBottom: '4px',
        color: theme.palette.primary.dark,
        fontSize: 18,
        fontWeight: 700,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
    },
    '& td': {
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
        minWidth: 110,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
}));

export const DiffTableContent = styled('td')(({ props }) => ({
    color: '#0F9672',
    fontWeight: '600',
    textAlign: 'center',
    ...props,
}));

export const CenterTbText = styled('td')(({ props }) => ({
    textAlign: 'left',
    ...props
}));

export const NegativeText = styled('td')(() => ({
    textAlign: 'left',
    color: 'red'
}));

export const TotalFlow = styled('td')(() => ({
    fontWeight: 700,
    fontSize: 18,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#0F9672'
}));

export const TotalValue = styled('td')(() => ({
    '& div':{
        justifyContent: 'space-between',
        padding: '7px 0 7px 2px',
        borderTop: 'solid 1px #0F9672',
        borderBottom: ' solid 3px #0F9672',
        fontSize: '16px',
        fontWeight: '600',
        width: '110px',
        marginLeft:'auto'
    }
}));

export const TotalFlowValueSubColumn = styled('td')(() => ({
    '& div':{
        justifyContent: 'space-between',
        padding: '7px 0 7px 2px',
        borderTop: 'solid 1px #0F9672',
        borderBottom: ' solid 3px #0F9672',
        fontSize: '16px',
        fontWeight: '600',
        // width: '100px',
        // marginLeft:'auto'
    }
}));

export const TotalAmountDiv = styled('div')(() => ({
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
}));

export const BudgetInputTwo = styled('td')(() => ({
    alignItems: 'center',
    width: 'auto',
    marginLeft: 'auto',
    '& span': {        
        position: 'absolute',
        zIndex: '1'
    },
    '& div': {
        textAlign: 'right',
        fontSize: '14px',
        // width: '110px',
    },
}));