const getKnowledgeBaseDetails = (t)=>([
    {
        id: 1,
        title: t('about-getSmater.title-1'),
        text: t('about-getSmater.text-1')
    },
    {
        id: 2,
        title: t('about-getSmater.title-2'),
        text: t('about-getSmater.text-2')
    },
]);
export{
    getKnowledgeBaseDetails   
}