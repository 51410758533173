import { Box, Grid, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider } from '../../../components/hook-form';
import calenderIcon from '../../downloadEdits/CalenderIcon';
import { AutoFieldStyle, DateGrid, FormStyling, LabelStyle } from './styled-components';

export default function Filter({ filtersData, updateFilters, loanAccounts }) {
    const methods = useForm({});

    const { t } = useTranslation();

    const appendLeadingZeroes = (n) => {
        if (n <= 9) {
            return `0${n}`;
        }
        return n
    }

    const updateFilterValue = (key, value) => {
        if (key === 'accountId') {
            if (value !== null) {
                updateFilters('accountId', value);
            } else {
                updateFilters('accountId', value);
            }
        }
        if (key === 'fromMonth') {
            const parsezone= moment.parseZone(value).format('MM/DD/YYYY')
            const selectmonth = new Date(parsezone);
            const monthName = appendLeadingZeroes(selectmonth.getMonth() + 1);
            const selectMonthformat = `${selectmonth.getFullYear()}-${monthName}`;
            updateFilters(key, selectMonthformat);
        }
        if (key === 'compareMonth') {
            const parsezone= moment.parseZone(value).format('MM/DD/YYYY')
            const selectmonth = new Date(parsezone);
            const compareMonthName = appendLeadingZeroes(selectmonth.getMonth() + 1);
            const compareYear = selectmonth.getFullYear();
            const compareMonthformat = `${compareYear}-${compareMonthName}`;
            updateFilters(key, compareMonthformat);
        }
    };

    return (
        <Box>
            <FormStyling>
                <FormProvider methods={methods}>
                    <Grid container spacing={2} >
                        <Grid item md={3} xs={12}>
                            <DateGrid>
                                <LabelStyle shrink htmlFor="bootstrap-input">
                                    {t('common-translation.from-date')}
                                </LabelStyle>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        PaperProps={{ className: 'customDateSelector' }}
                                        value={moment.parseZone(filtersData.compareMonth).format('MM/DD/YYYY')}
                                        views={["year", "month"]}
                                        components={{
                                            OpenPickerIcon: calenderIcon,
                                        }}
                                        onChange={(date) => updateFilterValue('compareMonth', date)}
                                        renderInput={(params) => <TextField {...params}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: "To"
                                            }} />}
                                    />
                                </LocalizationProvider>
                            </DateGrid>
                        </Grid>

                        <Grid item md={3} xs={12}>
                            <DateGrid>
                                <LabelStyle shrink htmlFor="bootstrap-input">
                                    {t('common-translation.to-date')}
                                </LabelStyle>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        PaperProps={{ className: 'customDateSelector' }}
                                        value={moment.parseZone(filtersData.fromMonth).format('MM/DD/YYYY')}
                                        views={["year", "month"]}
                                        components={{
                                            OpenPickerIcon: calenderIcon,
                                        }}
                                        onChange={(date) => updateFilterValue('fromMonth', date)}
                                        renderInput={(params) => <TextField {...params}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: "To"
                                            }} />}
                                    />
                                </LocalizationProvider>
                            </DateGrid>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <LabelStyle shrink htmlFor="bootstrap-input">
                                {t('loans-activity.loan-loc-category')}
                            </LabelStyle>
                            <AutoFieldStyle
                                options={loanAccounts}
                                id="category"
                                value={filtersData.categoryId}
                                onChange={(e, val) => {
                                    updateFilterValue('accountId', val);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" placeholder="Select" className="dropMenu" />
                                )}
                            />
                        </Grid>
                    </Grid>
                </FormProvider>
            </FormStyling>
        </Box>
    );
}