const getWelcomeScreenData = (t) => ([
    {
        id: 1,
        title: t('onboarding.one-time-setup-process-1-title'),
    },
    {
        id: 2,
        title: t('onboarding.one-time-setup-process-2-title'),
    },
    {
        id: 3,
        title: t('onboarding.one-time-setup-process-3-title'),
    },
    {
        id: 4,
        title: t('onboarding.one-time-setup-process-4-title'),
    },
    {
        id: 5,
        title: t('onboarding.one-time-setup-process-5-title'),
    },
]);

export {
    getWelcomeScreenData,
}