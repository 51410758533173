// @mui
import { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import {
    Grid,
    Typography,
    Stack,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    InputLabel,
} from '@mui/material';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PostAddIcon from '@mui/icons-material/PostAdd';
import {
    ContentStyle,
    HeaderDivStyle,
    HeaderTableStyle,
    BorderStyle,
    ForumSubTopicsTd,
    BootstrapTextarea,
} from './styled-components';
// components
import transform from '../../constants/strapiHelper';
import forum from '../../constants/services/forum';
import Page from '../../components/Page';
import AutoCompleteForumSearch from './AutoCompleteForumSearch';
import { InputLabelStyle } from '../contact-us/styled-components';
import TopMenu from '../../components/TopMenu';
import TopHeading from '../../components/TopHeading';
// import TagsInput from '../../components/TagsInput';

export default function ForumTopics() {
    const [open, setOpen] = useState(false);
    const [topics, setTopics] = useState([]);
    const { categoryId } = useParams();
    const location = useLocation();
    const [newTopic, setNewTopic] = useState('');
    // const [newTitleTopic, setNewTitleTopic]= useState('');
    // const [topicTags, setTopicTags] = useState([])
    const { tag } = useParams();
    const navigate = useNavigate();
    let addedById = '';

    const { t } = useTranslation();

    if (sessionStorage.getItem('userData')) {
        const usr = JSON.parse(sessionStorage.getItem('userData'));
        if (usr.username) {
            addedById = usr.username;
        }
    }

    const getTopics = async () => {
        let topicsData = [];
        if (categoryId) {
            topicsData = await forum.getTopics(categoryId);
        } else {
            topicsData = await forum.getTopicsByTag(tag);
        }

        const topicsList = [];
        if (topicsData.data) {
            topicsData.data.forEach((topic) => {
                const { description, allowAdditionOfQuestions, updatedAt, forum_replies: forumReplies } = topic.attributes;
                let lastReply = updatedAt;
                if (forumReplies?.data?.length) {
                    // eslint-disable-next-line camelcase
                    lastReply = forumReplies.data[forumReplies.data.length - 1].attributes.updatedAt;
                }
                topicsList.push({
                    id: topic.id,
                    description,
                    allowAdditionOfQuestions,
                    updatedAt,
                    lastActivity: lastReply,
                });
            });
        }
        setTopics(topicsList);
    };

    const openAddTopicPopup = () => {
        setOpen(true);
    };

    const closeAddTopicPopup = () => {
        setOpen(false);
    };

    const handleAddTopic = (event) => {
        setNewTopic(event.target.value);
    };

    const handleAddTopicSubmit = async () => {
        if (newTopic && newTopic.trim().length > 0) {
            setOpen(false);
            const addTopicMessage = {
                data: {
                    description: newTopic,
                    category: {
                        id: categoryId,
                    },
                    username: addedById,
                },
            };
            await forum.addTopic(addTopicMessage);
            getTopics();
            setNewTopic('')
        } else {
            toast.error(t('forumcategory.title-description-mandatory'));
        }
    };

    useEffect(() => {
        getTopics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tag, categoryId]);

    return (
        <Page title={t('forumcategory.title')}>
            <TopMenu title={t('forumcategory.title')} />
            <TopHeading heading={t('forumcategory.topics')}/>
            {/* <Wrapper> */}
            {/* <PageTopRightBg /> */}
            <div className='contentStyle'>
                {/* <BackButtonStyle variant="contained" onClick={() => navigate(-1)}>
                    {t('embedded-rate.go-back')}
                </BackButtonStyle>
                <Title title={t('forumcategory.title')} subtitle={t('forumcategory.topics')} /> */}
                {/* <div style={{ textAlign: 'center', paddingTop: 25, color: '#00773E', fontSize: '1.25rem', fontWeight: 700 }}>
                    
                </div> */}
                <Typography variant='body1' className='commonHeading' style={{textAlign: 'center'}}>{location?.state?.categoryName}</Typography>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-center"
                    spacing={3}
                    style={{ paddingTop: '20px' }}
                >
                    <AutoCompleteForumSearch />
                    <PostAddIcon
                        onClick={openAddTopicPopup}
                        style={{
                            color: '#0F9672',
                            cursor: 'pointer',
                            fontSize: '38px',
                            marginTop: '10px',
                            marginLeft: '12px',
                        }}
                    />
                </Stack>
                <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={12}>
                        <HeaderDivStyle>
                            <HeaderTableStyle>
                                <thead>
                                    <tr>
                                        <th style={{ width: '50%' }}>{t('forumcategory.topic')}</th>
                                        <th style={{ width: '25%', textAlign: 'center' }}>
                                            {t('forumcategory.topic-created-date')}
                                        </th>
                                        <th style={{ width: '25%', textAlign: 'center' }}>
                                            {t('forumcategory.last-activity')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <BorderStyle colSpan={3}>{''}</BorderStyle>
                                    </tr>
                                    {topics?.map((topic) => (
                                        <tr key={topic.id}>
                                            <td>
                                                <div
                                                    onClick={() =>
                                                        navigate(`/forum-topics/topic/${topic.id}`, {
                                                            state: { categoryName: location?.state?.categoryName },
                                                        })
                                                    }
                                                    role="presentation"
                                                    style={{ cursor: 'pointer', marginBottom: '6px' }}
                                                >
                                                    {/* <ForumTopicsTd>{topic.title}</ForumTopicsTd> */}
                                                    <ForumSubTopicsTd>
                                                        {ReactHtmlParser(topic.description, {
                                                            transform: (node, index) => transform(node),
                                                        })}
                                                    </ForumSubTopicsTd>
                                                </div>
                                            </td>
                                            <td style={{ verticalAlign: 'top', textAlign: 'center' }}>
                                                <Typography variant="h7">
                                                    {format(new Date(topic.updatedAt), 'dd MMM yy')}
                                                </Typography>
                                            </td>
                                            <td style={{ verticalAlign: 'top', textAlign: 'center' }}>
                                                <Typography variant="h7">
                                                    {format(new Date(topic.lastActivity), 'dd MMM yy')}
                                                </Typography>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </HeaderTableStyle>
                        </HeaderDivStyle>
                    </Grid>
                    <div>
                        <Dialog open={open} onClose={closeAddTopicPopup} key={`DialogAddTopic`}>
                            <DialogTitle>{t('forumcategory.addTopic')}</DialogTitle>
                            <DialogContent style={{ paddingBottom: '5px' }}>
                                <RHFTextFieldMsg
                                    multiline={'true'}
                                    rows={10}
                                    name="addTopicField"
                                    onChange={handleAddTopic}
                                    key={`RHFTextFieldMsgAddTopic`}
                                    placeholder={t('forumcategory.description-hint')}
                                    required
                                />
                            </DialogContent>
                            <DialogActions style={{paddingRight: "24px"}}>
                                <Button onClick={closeAddTopicPopup} key={`closeAddTopicPopupTopic`} className='loadingButtonStyle1'>
                                    {t('forumcategory.cancel')}
                                </Button>
                                <Button onClick={handleAddTopicSubmit} key={`handleAddTopicSubmitTopic`} className='loadingButtonStyle2'>
                                    {t('forumcategory.submit')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </Grid>
            </div>
            {/* </Wrapper> */}
        </Page>
    );
}

const RHFTextFieldMsg = ({ name, multiline, rows, label, ...other }) => (
    <>
        <InputLabelStyle shrink htmlFor="bootstrap-input">
            {label}
        </InputLabelStyle>
        <BootstrapTextarea multiline={multiline} rows={rows} cols={100} name={name} fullWidth {...other} />
    </>
);
