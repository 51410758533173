import request from '../../utils/request';

const signIn = (payload) => request.post('login', payload);
const forgotPassword = (payload) => request.post('forgot-password', payload);
const resetPassword = (payload) => request.post('reset-password', payload);
const changePassword = (payload) => request.post('change-password', payload);
const contactUs = (payload) => request.post('contactUs', payload)
const googleLogin = (payload) => request.post('google/login', payload);
const facebookLogin = (payload) => request.post('facebook/login', payload);
const logout = () => request.post('logout');
const verifyEmail = (payload) => request.post('verify-email', payload);
const validate2FA = (payload) => request.post('verify-2fa-v2', payload);
const resend2FACode = (id) => request.get(`resend-2fa/${id}`);
const signIn2FA = (payload) => request.post('auth-login', payload);

export default {
    signIn,
    forgotPassword,
    resetPassword,
    changePassword,
    googleLogin,
    facebookLogin,
    contactUs,
    logout,
    verifyEmail,
    validate2FA,
    resend2FACode,
    signIn2FA
};