// @mui
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import videoURL from 'constants/helpVideoUrl';
import Bargraph from '../Graphs/Bargraph';
import Piegraph from '../Graphs/Piegraph';
import { CHARTCOLOR } from '../constant';
import { EXCEL_STYLE } from '../../calculators/constant';
// components
import Page from '../../../components/Page';
import TopHeading from '../../../components/TopHeading';
import TopMenu from '../../../components/TopMenu';
import dashboard from '../../../constants/services/dashboard';
import { downloadExcelHeader, exportCustomExcel, getCurrencySymbol, getMonthNameYear, getPositiveNumber, removeEmpty } from '../../../utils/calCommonFunction';
import { ButtonDivStyle, NoDataTd, ReportAmountTd, SpanTotalFlow } from '../ExecutiveSummary/styled-component';
import Filter from '../filter/Filter';
import {
    ContentStyle,
    LoaderGif,
    SubmitButton,
    TableStyle,
    TableUpperDiv,
    TopSideBar,
    TotalAmountDiv
} from './styled-component';

export default function AssetComposition(props) {
    const currencyCode = getCurrencySymbol();
    const { t } = useTranslation();
    const location = useLocation();
    const { reportName } = props;
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const[categoriesDetails, setCategoriesDetails]= useState({
        catName:'',
        subCatName:''
    })
    const [filtersData, setFiltersData] = useState({
        fromMonth: moment.parseZone().startOf('month').format('YYYY-MM'),
        compareMonth: null,
        categoryGroupId: location?.state?.categoryGroupId || null,
        categoryId: null,
        subCatId: null,
        type: 1,
    });

    const [monthColumn, setMonthColumn] = useState([]);
    const [assetData, setAssetData] = useState([]);
    const [assetPercentageData, setAssetPercentageData] = useState([]);
    const [totalCurrAssetData, setTotalCurrAssetData] = useState([]);
    const [totalCompAssetData, setTotalCompAssetData] = useState([]);
    const [totalIncDescAssetData, setTotalIncDescAssetData] = useState([]);
    const [totalCurrentPercentage, setTotalCurrentPercentage] = useState([]);
    const [totalComparePercentage, setTotalComparePercentage] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [horichartData, setHoriChartData] = useState([]);
    const [showGraph, setShowGraph] = useState(true);
    const [tableLoader, setTableLoader] = useState(false);
    const [assetFilter, setAssetFilter]= useState({
        catName: '',
        subCatName: '',
        compare: '',
    })

    const updateFilters = (key, value) => {
        const clonedData = {
            ...filtersData,
            [key]: value,
        };
        if (key === 'categoryId') {
            clonedData.subCatId = '';
        }
        setFiltersData(clonedData);
    };

    const getdashboardData = async () => {
        const filtersDataObj = removeEmpty(filtersData);
        let response;
        setTableLoader(true);
        if (reportName === 'asset-composition') {
            response = await dashboard.getAssetComposition(filtersDataObj);
        } else if (reportName === 'liabilities-composition') {
            response = await dashboard.getLiabilitiesComposition(filtersDataObj);
        }
        setTableLoader(false);
        const resultData = response;
        const assetDates = Object.keys(resultData.categoriesData);
        const { categoriesData } = resultData;

        setMonthColumn(assetDates);

        const groupAssetData = [];

        assetDates.forEach(key => {
            categoriesData[key].forEach(val => {
                let match = null;
                if (val.account_id) {
                    match = groupAssetData.find(k => k?.account_id === val?.account_id);
                } else {
                    match = groupAssetData.find(k => k?.name === val?.name);
                }
                if(match) {
                    match.compare_month = val.values
                    match.incdesc_val = (match.current_month - val.values);
                } else {
                    groupAssetData.push({
                        "account_id": val.account_id,
                        "name": val.name,
                        "current_month": val.values,
                        "currencySymbol": currencyCode
                    })
                }
            })
        })

        const currentMonthTotal = getMonthTotal(groupAssetData, 'current_month')
        const compareMonthTotal = getMonthTotal(groupAssetData, 'compare_month')
        const incDescValTotal = getMonthTotal(groupAssetData, 'incdesc_val')

        const assetPercentageObj = []
        
        groupAssetData.forEach((items) => {
            assetPercentageObj.push({
                name: items.name,
                current_month_percentage: currentMonthTotal !== 0 ? Number(items.current_month * 100 / currentMonthTotal) : '0.00',
                compare_month_percentage: compareMonthTotal !== 0 ? Number(items.compare_month * 100 / compareMonthTotal) : '0.00'
            })
        })

        const currentMonthPercentage = getMonthTotal(assetPercentageObj, 'current_month_percentage')
        const compareMonthPercentage = getMonthTotal(assetPercentageObj, 'compare_month_percentage')
        const horiChartData = getHorizontalGraphData(assetPercentageObj);
        const graphData = getGraphData(groupAssetData);

        // asset cash 
        const assetValWithoutCashArray = groupAssetData.filter((obj) => obj.name !== 'Cash');
        const assetCashobj = groupAssetData.filter((obj) => obj.name === 'Cash');
        assetValWithoutCashArray.sort((a, b) => Number(a.current_month - b.current_month));
        assetCashobj.push(...assetValWithoutCashArray);

        // asset percentage
        const assetPercentWithoutCashArray = assetPercentageObj.filter((obj) => obj.name !== 'Cash');
        const assetPercentCashobj = assetPercentageObj.filter((obj) => obj.name === 'Cash');
        assetPercentWithoutCashArray.sort((a, b) => Number(a.current_month_percentage - b.current_month_percentage));
        assetPercentCashobj.push(...assetPercentWithoutCashArray);
        
        setAssetPercentageData(assetPercentCashobj);
        setAssetData(assetCashobj)
        setTotalCurrAssetData(currentMonthTotal)
        setTotalCompAssetData(compareMonthTotal)
        setTotalCurrentPercentage(currentMonthPercentage.toFixed(2))
        setTotalComparePercentage(compareMonthPercentage.toFixed(2))
        setHoriChartData(horiChartData);
        setChartData(graphData);
        setTotalIncDescAssetData(incDescValTotal)
    }

    const getMonthTotal = (arr, args) => arr.reduce((acc, item) => Number(acc) + Number(item[args]), 0);

    const getGraphData = (data) => {
        let labels = [];
        let dataset = [];
        if (data.length > 0) {
            labels = data.map((item) => item.name);
            if (filtersData.compareMonth) {
                dataset = [
                    {
                        label: getMonthNameYear(filtersData.fromMonth),
                        data: [],
                        backgroundColor: 'rgba(54, 162, 235, 1)',
                    },
                    {
                        label: getMonthNameYear(filtersData.compareMonth),
                        data: [],
                        backgroundColor: 'rgba(255, 99, 132, 1)',
                    },
                ];
            } else {
                dataset = [
                    {
                        label: getMonthNameYear(filtersData.fromMonth),
                        data: [],
                        backgroundColor: 'rgba(54, 162, 235, 1)',
                    },
                ];
            }
            let monthData;
            for (let i = 0; i <= data.length - 1; i += 1) {
                const item = data[i];
                const currVal = Number(item.current_month);
                const compVal = Number(item.compare_month);
                monthData = [currVal, compVal];
                for (let j = 0; j < dataset.length; j += 1) {
                    const reqvalue = monthData[j];
                    dataset[j].data.push(reqvalue);
                }
            }
        }

        const graphTitle = `${currencyCode} ${t(`${reportName}.graph-title`)}`;
        return { labels, dataset, title: graphTitle };
    };

    const getHorizontalGraphData = (data) => {
        let labels = [];
        let dataset = [];
        if (data.length > 0) {
            labels = data.map((item) => item.name);
            if (filtersData.compareMonth) {
                dataset = [
                    {
                        label: getMonthNameYear(filtersData.fromMonth),
                        data: [],
                        backgroundColor: [],
                        hoverOffset: 4,
                    },
                    {
                        label: getMonthNameYear(filtersData.compareMonth),
                        data: [],
                        backgroundColor: [],
                        hoverOffset: 4,
                    },
                ];
            } else {
                dataset = [
                    {
                        label: getMonthNameYear(filtersData.fromMonth),
                        data: [],
                        backgroundColor: [],
                        hoverOffset: 4,
                    },
                ];
            }
            let monthData;
            for (let i = 0; i <= data.length - 1; i += 1) {
                const item = data[i];
                const currVal = Number(item.current_month_percentage);
                const compVal = Number(item.compare_month_percentage);
                monthData = [currVal, compVal];
                for (let j = 0; j < dataset.length; j += 1) {
                    const reqvalue = monthData[j];
                    dataset[j].data.push(reqvalue);
                    dataset[j].backgroundColor.push(CHARTCOLOR[i]);
                }
            }
        }
        const graphTitle = `% ${t(`${reportName}.graph-title`)}`;
        return { labels, dataset, title: graphTitle };
    };

    const removeNullValue =(record)=> record.map(obj => {
        const filteredObj = Object.fromEntries(
            Object.entries(obj).filter(([key, value]) => value !== 'NaN' || value !== undefined)
        );
        return filteredObj;
    });

    const getExcelSummary=()=>{
        // const style=[]
        // const cols = [];
        const merges = []

        const totalAssetValue =[]
        const totalAvgAssetValue =[]
        let assetDataElement = [];
        let assetPercentageElement = [];
        const selectedFormData = []

        selectedFormData.push({
            'As at': filtersData.fromMonth,
            'Category': assetFilter.catName,
            'Sub-Category': assetFilter.subCatName,
            'Compare': assetFilter.compare
        })

        if (monthColumn.length > 1) {
            assetDataElement = assetData.map((item)=>({
                Name: item.name,
                [filtersData?.fromMonth]: getPositiveNumber(item.current_month),
                [filtersData?.compareMonth]: getPositiveNumber(item.compare_month),
                'Increase(Decrease)': getPositiveNumber(item.incdesc_val)
            }))

            totalAssetValue.push({
                Name:'Total',
                [filtersData?.fromMonth]: getPositiveNumber(totalCurrAssetData),
                [filtersData?.compareMonth]: getPositiveNumber(totalCompAssetData),
                'Increase(Decrease)': getPositiveNumber(totalIncDescAssetData)
            })

            assetPercentageElement = assetPercentageData.map((item)=>({
                Name: item.name,
                [filtersData?.fromMonth]: Number(item.current_month_percentage).toFixed(2),
                [filtersData?.compareMonth]: Number(item.compare_month_percentage).toFixed(2),
            }))

            totalAvgAssetValue.push({
                Name:'Total',
                [filtersData?.fromMonth]: getPositiveNumber(totalCurrentPercentage),
                [filtersData?.compareMonth]: getPositiveNumber(totalComparePercentage),
            })

        }else{
            assetDataElement = assetData.map((item)=>({
                Name: item.name,
                [filtersData?.fromMonth]: getPositiveNumber(item.current_month),
            }))

            totalAssetValue.push({
                Name:'Total',
                [filtersData?.fromMonth]: getPositiveNumber(totalCurrAssetData),
            })

            assetPercentageElement = assetPercentageData.map((item)=>({
                Name: item.name,
                [filtersData?.fromMonth]: Number(item.current_month_percentage).toFixed(2),
            }))

            totalAvgAssetValue.push({
                Name:'Total',
                [filtersData?.fromMonth]: getPositiveNumber(totalCurrentPercentage),
            })
        }
        const excelObjectData = [...assetDataElement, ...totalAssetValue];

        const style = [
            {
                "col": 'A1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'B1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'C1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'D1',
                "style": EXCEL_STYLE.boldHeader
            }
        ]

        const newOutputArray = excelObjectData.map((item) => {
            const { Name, ...rest} = item;
            return { Name, ...rest };
        });

        const addCurrencySymbol = newOutputArray.map(obj => {
            const newObj = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const key in obj) {
                if (!['Name'].includes(key)) {
                    newObj[key] = `$ ${obj[key]}`;
                } else {
                    newObj[key] = obj[key];
                }
            }
            return newObj;
        });

        const percentageData = [...assetPercentageElement, ...totalAvgAssetValue];
        const newPercentageData = percentageData.map((item) => {
            const { Name, ...rest} = item;
            return { Name, ...rest };
        });
        const data2 = [{}, ...newPercentageData]

        const addPercentage = data2.map(obj => {
            const newObj = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const key in obj) {
                if (!['Name'].includes(key)) {
                    newObj[key] = `${obj[key]} %`;
                } else {
                    newObj[key] = obj[key];
                }
            }
            return newObj;
        });

        const data = [selectedFormData, addCurrencySymbol, addPercentage];
        const cols = [{ width: 18 }, { width: 13 }, { width: 17 }, { width: 20 }, { width: 13 }];
        exportCustomExcel(data, style, cols, merges, reportName, 7, ['I'], [false, false, true],  [-1, 'A4', -1]);
    }

    useEffect(() => {
        getdashboardData();
        getCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCategories = async () => {
        const response = await dashboard.getDashboardCategory({ categoryGroupId: filtersData.categoryGroupId });
        const categories = response?.data || [];
        setCategories(categories);
    };

    const getFilterData = () => {
        getdashboardData();
    };

    const videoUrl = reportName === 'asset-composition' ? videoURL.assetComposition : videoURL.liabilityComposition;

    return (
        <Page title={t(`${reportName}.title`)}>
            <TopMenu title="Asset and Liability Reports" videoLink={videoUrl} />
            <TopHeading heading={t(`${reportName}.subtitle`)} />
            <ContentStyle>
                <Grid container pt={2} pb={3} mt={0}>
                    <Grid item md={10.5} xs={12}>
                        <Filter
                            filtersData={filtersData}
                            updateFilters={updateFilters}
                            categories={categories}
                            setFiltersData={setFiltersData}
                            showCategoryFilter={['liabilities-composition', 'asset-composition'].includes(reportName)}
                            assetFilter={assetFilter}
                            setAssetFilter={setAssetFilter}
                        />
                    </Grid>
                    <Grid item md={1.5} xs={12} style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                        <SubmitButton
                            loading={loading}
                            size="large"
                            variant="contained"
                            onClick={() => getFilterData()}
                            className='loadingButtonStyle2'
                        >
                            Go
                        </SubmitButton>
                    </Grid>
                </Grid>
                {showGraph ? (
                    <Grid container spacing={4} pt={1} marginTop={0} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} sm={12} md={12}>
                            <TableUpperDiv>
                                <TableStyle>
                                    {tableLoader ? (
                                        <tbody>
                                            <tr>
                                                <td colSpan={4}>
                                                    <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <>
                                            {assetData.length > 0 ? (
                                                <>
                                                    <thead>
                                                        {
                                                            <tr>
                                                                <th style={{ width: '25%' }}>{''}</th>
                                                                {monthColumn?.map((item, index) => (
                                                                    <TopSideBar
                                                                        key={index}
                                                                        style={{ width: '25%', textAlign: 'end' }}
                                                                    >
                                                                        {getMonthNameYear(item)}
                                                                    </TopSideBar>
                                                                ))}
                                                                {monthColumn?.length > 1 && (
                                                                    <TopSideBar
                                                                        style={{ width: '25%', textAlign: 'end' }}
                                                                    >
                                                                        {'Increase(Decrease)'}
                                                                    </TopSideBar>
                                                                )}
                                                            </tr>
                                                        }
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{''}</td>
                                                            <td>{''}</td>
                                                            <td>{''}</td>
                                                            <td>{''}</td>
                                                        </tr>
                                                    </tbody>
                                                    {
                                                        <tbody>
                                                            {assetData.map((val, index) => (
                                                                <tr key={index}>
                                                                    <td style={{ width: '25%' }}>{val.name}</td>
                                                                    <ReportAmountTd>
                                                                        <TotalAmountDiv>
                                                                            {currencyCode}{' '}
                                                                            <span>
                                                                                {getPositiveNumber(val.current_month)}
                                                                            </span>
                                                                        </TotalAmountDiv>
                                                                    </ReportAmountTd>
                                                                    {monthColumn?.length > 1 && (
                                                                        <ReportAmountTd>
                                                                            <TotalAmountDiv>
                                                                                {currencyCode}{' '}
                                                                                <span>
                                                                                    {getPositiveNumber(
                                                                                        val.compare_month
                                                                                    )}
                                                                                </span>
                                                                            </TotalAmountDiv>
                                                                        </ReportAmountTd>
                                                                    )}
                                                                    {monthColumn?.length > 1 && (
                                                                        <ReportAmountTd>
                                                                            <TotalAmountDiv>
                                                                                {currencyCode}{' '}
                                                                                <span>
                                                                                    {getPositiveNumber(val.incdesc_val)}
                                                                                </span>
                                                                            </TotalAmountDiv>
                                                                        </ReportAmountTd>
                                                                    )}
                                                                </tr>
                                                            ))}
                                                            <tr>
                                                                {<td>{t(`${reportName}.total`)}</td>}
                                                                <SpanTotalFlow>
                                                                    <TotalAmountDiv>
                                                                        {currencyCode}{' '}
                                                                        <span>
                                                                            {getPositiveNumber(totalCurrAssetData)}
                                                                        </span>
                                                                    </TotalAmountDiv>
                                                                </SpanTotalFlow>
                                                                {monthColumn?.length > 1 && (
                                                                    <SpanTotalFlow>
                                                                        <TotalAmountDiv>
                                                                            {currencyCode}{' '}
                                                                            <span>
                                                                                {getPositiveNumber(totalCompAssetData)}
                                                                            </span>
                                                                        </TotalAmountDiv>
                                                                    </SpanTotalFlow>
                                                                )}
                                                                {monthColumn?.length > 1 && (
                                                                    <SpanTotalFlow>
                                                                        <TotalAmountDiv>
                                                                            {currencyCode}{' '}
                                                                            <span>
                                                                                {getPositiveNumber(
                                                                                    totalIncDescAssetData
                                                                                )}
                                                                            </span>
                                                                        </TotalAmountDiv>
                                                                    </SpanTotalFlow>
                                                                )}
                                                            </tr>
                                                        </tbody>
                                                    }
                                                    <tbody>
                                                        <tr>
                                                            <td>{''}</td>
                                                            <td>{''}</td>
                                                            <td>{''}</td>
                                                            <td>{''}</td>
                                                        </tr>
                                                    </tbody>

                                                    <tbody>
                                                        <tr>
                                                            <TopSideBar style={{ textAlign: 'end' }}>{''}</TopSideBar>
                                                            {monthColumn?.map((item, index) => (
                                                                <TopSideBar
                                                                    key={index}
                                                                    style={{ width: '25%', textAlign: 'end' }}
                                                                >
                                                                    {getMonthNameYear(item)}
                                                                </TopSideBar>
                                                            ))}
                                                        </tr>
                                                    </tbody>
                                                    {assetPercentageData.length > 0 && (
                                                        <tbody>
                                                            {assetPercentageData.map((val, index) => (
                                                                <tr key={index}>
                                                                    <td>{val.name}</td>
                                                                    <td style={{ textAlign: 'end' }}>
                                                                        {Number(val.current_month_percentage).toFixed(2)} %
                                                                    </td>
                                                                    {monthColumn?.length > 1 && (
                                                                        <td style={{ textAlign: 'end' }}>
                                                                            {Number(val.compare_month_percentage).toFixed(2)} %
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            ))}
                                                            <tr>
                                                                {totalCurrentPercentage >= 0 && (
                                                                    <td>{t(`${reportName}.total`)}</td>
                                                                )}
                                                                <SpanTotalFlow>
                                                                    <div>{totalCurrentPercentage} % </div>
                                                                </SpanTotalFlow>
                                                                {monthColumn?.length > 1 && (
                                                                    <SpanTotalFlow>
                                                                        <div>{totalComparePercentage} % </div>
                                                                    </SpanTotalFlow>
                                                                )}
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </>
                                            ) : (
                                                <tbody>
                                                    <tr>
                                                        <NoDataTd colSpan={4}>
                                                            {t('executive-summary.no-data-found')}
                                                        </NoDataTd>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </>
                                    )}
                                </TableStyle>
                            </TableUpperDiv>
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        <Bargraph chartData={chartData} currencyCode={currencyCode} />
                        <Piegraph chartData={horichartData} />
                    </>
                )}
                <ButtonDivStyle>
                    <LoadingButton
                        fullWidth
                        size="large"
                        variant="contained"
                        onClick={() => setShowGraph(!showGraph)}
                        className='loadingButtonStyle2'
                    >
                        {showGraph ? t(`${reportName}.graph`) : t(`${reportName}.view-table`)}
                    </LoadingButton>
                    <LoadingButton
                        fullWidth
                        size="large"
                        variant="contained"
                        // disabled={}
                        onClick={() => getExcelSummary()}
                        className='loadingButtonStyle2'
                    >
                        Export
                    </LoadingButton>
                </ButtonDivStyle>
            </ContentStyle>
        </Page>
    );
}