// @mui
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import ExecutiveIncDecTable from './ExecutiveIncDecTable';
import ExecutiveTopTransactionsTable from './ExecutiveTopTransactionsTable';
import dashboard from '../../../constants/services/dashboard';
import { CHARTCOLOR } from '../constant';
import {
    exportCustomExcel,
    getCurrencySymbol,
    getNegativeNumber,
    getPositiveNumber,
} from '../../../utils/calCommonFunction';
import { MobileResDiv, ButtonDivStyle } from './styled-component';
import ExecutiveBudgetTable from './ExecutiveBudgetTable';
import { EXCEL_STYLE } from '../../calculators/constant';
import Bargraph from '../Graphs/Bargraph';
import { ExecutiveTableSummary } from './ExecutiveTableSummary';
import ExecutiveRecurringAndLastTable from './ExecutiveRecurringAndLastTable';

export default function ExecutiveSummary() {
    const [budgetUnderYearData, setBudgetUnderYearData] = useState([]);
    const [budgetUnderMonthData, setBudgetUnderMonthData] = useState([]);
    const [underBudgetYearChart, setUnderBudgetYearChart] = useState([]);
    const [underBudgetMonthChart, setUnderBudgetMonthChart] = useState([]);

    const [bugetOverYearData, setBugetOverYearData] = useState([]);
    const [bugetOverMonthData, setBugetOverMonthData] = useState([]);
    const [overBudgetYearChart, setOverBudgetYearChart] = useState([]);
    const [overBudgetMonthChart, setOverBudgetMonthChart] = useState([]);
    const [monthExpData, setMonthExpData] = useState([]);
    const [recurringData, setRecurringData] = useState([]);
    const [lastMonthData, setLastMonthData] = useState([]);

    const [showBudget, setShowBudget] = useState(true);
    const [showUnderBudget, setShowUnderBudget] = useState(true);

    const [tableLoader, setTableLoader] = useState(false);

    const currencySymbol = getCurrencySymbol();
    const { t } = useTranslation();

    const getIncInvestmentData = async (selectdata) => {
        setTableLoader(true);
        try {
            const response = await dashboard.getDashboardCategoryDetails(selectdata);
            const data = await response.data;
            setTableLoader(false);
            setMonthExpData(data);
            return data;
        } catch (error) {
            setTableLoader(false);
            console.log('error', error);
        }
    };

    const getExecutiveSummaryData = async () => {
        // api paths
        const budgetUnderYear = `budget?type=year&budget=u`;
        const budgetUnderMonth = `budget?type=month&budget=u`;
        const bugetOverYear = `budget?type=year&budget=o`;
        const bugetOverMonth = `budget?type=month&budget=o`;

        let budgetUnderYearWise;
        let budgetUnderMonthWise;
        let bugetOverYearWise;
        let bugetOverMonthWise;
        try {
            budgetUnderYearWise = await getIncInvestmentData(budgetUnderYear);
            setBudgetUnderYearData(budgetUnderYearWise);
        } catch (error) {
            console.log('error', error);
        }

        try {
            budgetUnderMonthWise = await getIncInvestmentData(budgetUnderMonth);
            setBudgetUnderMonthData(budgetUnderMonthWise);
        } catch (error) {
            console.log('error', error);
        }

        try {
            bugetOverYearWise = await getIncInvestmentData(bugetOverYear);
            setBugetOverYearData(bugetOverYearWise);
        } catch (error) {
            console.log('error', error);
        }

        try {
            bugetOverMonthWise = await getIncInvestmentData(bugetOverMonth);
            setBugetOverMonthData(bugetOverMonthWise);
        } catch (error) {
            console.log('error', error);
        }

        const overBudgetYearObj = getinvestmentChartData(
            bugetOverYearWise,
            t('executive-summary.top-5-over-budget-year')
        );
        const overBudgetMonthObj = getinvestmentChartData(
            bugetOverMonthWise,
            t('executive-summary.top-5-over-budget-month')
        );
        const underBudgetYearObj = getinvestmentChartData(
            budgetUnderYearWise,
            t('executive-summary.top-5-under-budget-year')
        );
        const underBudgetMonthObj = getinvestmentChartData(
            budgetUnderMonthWise,
            t('executive-summary.top-5-under-budget-month')
        );

        setOverBudgetYearChart(overBudgetYearObj);
        setOverBudgetMonthChart(overBudgetMonthObj);
        setUnderBudgetYearChart(underBudgetYearObj);
        setUnderBudgetMonthChart(underBudgetMonthObj);
    };

    const getinvestmentChartData = (data, titlename) => {
        // eslint-disable-next-line array-callback-return
        const labels = data?.map((item) => item?.name);
        const datasetval = data?.map((item) => {
            let amount = null;
            if (titlename.includes('Budget')) {
                amount = item?.budget_by;
            } else {
                amount = item?.amount;
            }

            if (titlename.includes('Expense')) {
                amount = Number(amount);
                if (amount < 0) {
                    amount = Number(-amount);
                }
                if (amount > 0) {
                    amount = Number(amount);
                }
            }
            return amount || 0;
        });
        const dataset = [];

        labels.forEach((item, index) => {
            const dataitem = { label: labels[index], data: [], backgroundColor: CHARTCOLOR[index] };
            dataset.push(dataitem);
        });
        for (let i = 0; i <= datasetval.length - 1; i += 1) {
            const monthData = datasetval[i];
            dataset[i].data.push(monthData);
        }
        return { labels: [''], dataset, title: titlename };
    };

    function biggerLength(array1, array2) {
        if (array1.length > array2.length) {
            return array1.length;
        }
        if (array2.length > array1.length) {
            return array2.length;
        }
        if (array2.length === array1.length) {
            return array1.length;
        }
    }

    const exportExcelTopOver = () => {
        const recurringExpense = recurringData.map((item) => ({
            Payee: item.payee,
            Description: item.category,
            Amount: `${currencySymbol}${getNegativeNumber(item.amount)}`,
        }));

        recurringExpense.unshift(
            { Payee: 'Recurring Expense Transactions', Description: '', Amount: '' },
            { Payee: 'Payee', Description: 'Description', Amount: 'Amount' }
        );

        const lastFiveTran = lastMonthData.map((item) => ({
            Date: item.transaction_date,
            Description: item.category,
            Amount: `${currencySymbol}${getPositiveNumber(item.amount)}`,
        }));

        lastFiveTran.unshift(
            { Date: 'Last 5 Transactions', Description: '', Amount: '' },
            { Date: 'Date', Description: 'Description', Amount: 'Amount' }
        );

        const expensesOverYear = bugetOverYearData.map((item) => ({
            Expense: item.name,
            Amount: `${currencySymbol}${getNegativeNumber(item.amount, true, false)}`,
            'Over Budget By': `${currencySymbol}${getNegativeNumber(item.budget_by, true, false)}`,
        }));

        expensesOverYear.unshift(
            { Expense: 'Top 5 Expenses that are Over Budget This Year', Amount: '', 'Over Budget By': '' },
            { Expense: 'Expense', Amount: 'Amount', 'Over Budget By': 'Over Budget By' }
        );

        const expensesOverMonth = bugetOverMonthData.map((item) => ({
            Expense: item.name,
            Amount: `${currencySymbol}${getNegativeNumber(item.amount, true, false)}`,
            'Over Budget By': `${currencySymbol}${getNegativeNumber(item.budget_by, true, false)}`,
        }));

        expensesOverMonth.unshift(
            { Expense: 'Top 5 Expenses that are Over Budget This Month', Amount: '', 'Over Budget By': '' },
            { Expense: 'Expense', Amount: 'Amount', 'Over Budget By': 'Over Budget By' }
        );

        const stRow = Number(biggerLength(recurringExpense, lastFiveTran) + 1);

        console.log(stRow);

        const style = [
            {
                col: 'A1',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'E1',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: `A${stRow + 1}`,
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: `E${stRow + 1}`,
                style: EXCEL_STYLE.boldHeader,
            },
        ];

        const cols = [
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 5 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
        ];

        const merges = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } },
            { s: { r: 0, c: 4 }, e: { r: 0, c: 6 } },
            { s: { r: stRow, c: 0 }, e: { r: stRow, c: 2 } },
            { s: { r: stRow, c: 4 }, e: { r: stRow, c: 6 } },
        ];

        exportCustomExcel(
            [recurringExpense, lastFiveTran, expensesOverYear, expensesOverMonth],
            style,
            cols,
            merges,
            'RecurringAndLast',
            7,
            ['I'],
            [true, true, true, true],
            [-1, 'E1', `A${stRow + 1}`, `E${stRow + 1}`]
        );
    };

    const exportExcel = () => {
        const expensesUnderYear = budgetUnderYearData.map((item) => ({
            Expense: item.name,
            Amount: `${currencySymbol}${getNegativeNumber(item.amount, true, false)}`,
            'Under Budget By': `${currencySymbol}${getPositiveNumber(item.budget_by, true, false)}`,
        }));

        expensesUnderYear.unshift(
            { Expense: 'Top 5 Expenses that are Under Budget This Year', Amount: '', 'Under Budget By': '' },
            { Expense: 'Expense', Amount: 'Amount', 'Under Budget By': 'Under Budget By' }
        );

        const expensesUnderMonth = budgetUnderMonthData.map((item) => ({
            Expense: item.name,
            Amount: `${currencySymbol}${getNegativeNumber(item.amount, true, false)}`,
            'Under Budget By': `${currencySymbol}${getPositiveNumber(item.budget_by, true, false)}`,
        }));

        expensesUnderMonth.unshift(
            { Expense: 'Top 5 Expenses that are Under Budget This Month', Amount: '', 'Under Budget By': '' },
            { Expense: 'Expense', Amount: 'Amount', 'Under Budget By': 'Under Budget By' }
        );

        const style = [
            {
                col: 'A1',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'E1',
                style: EXCEL_STYLE.boldHeader,
            },
        ];

        const cols = [
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 5 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
        ];

        const merges = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } },
            { s: { r: 0, c: 4 }, e: { r: 0, c: 6 } },
        ];

        exportCustomExcel(
            [expensesUnderYear, expensesUnderMonth],
            style,
            cols,
            merges,
            'Top5ExpensesUnderBudget',
            7,
            ['I'],
            [true, true],
            [-1, 'E1']
        );
    };

    const getRecurringData = (data) => {
        setRecurringData(data);
    };

    const getLastMonthData = (data) => {
        setLastMonthData(data);
    };

    useEffect(() => {
        getExecutiveSummaryData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MobileResDiv>
            <ExecutiveTableSummary />
            <ExecutiveIncDecTable getinvestmentChartData={getinvestmentChartData} />

            <ExecutiveTopTransactionsTable getinvestmentChartData={getinvestmentChartData} />

            <ExecutiveRecurringAndLastTable getRecurringData={getRecurringData} getLastMonthData={getLastMonthData} />

            {showBudget ? (
                <ExecutiveBudgetTable
                    tableloader={tableLoader}
                    increseThisMonth={bugetOverMonthData}
                    increseThisYear={bugetOverYearData}
                    monthExpData={monthExpData}
                    tableFirstHeading={t('executive-summary.top-5-expenses-over-budget')}
                    tableSecondHeading={t('executive-summary.top-5-expenses-over-budget')}
                    tableFirstSubHeading={t('executive-summary.this-year')}
                    tableSecondSubHeading={t('executive-summary.this-month')}
                    thFirst={t('executive-summary.expense')}
                    thSecond={t('executive-summary.amount')}
                    thThird={t('executive-summary.over-budget-by')}
                />
            ) : (
                <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{ margin: '0px', width: '100%' }}
                >
                    <Grid item xs={12} md={12} style={{ padding: '0px 0px 0px 0px' }}>
                        <Bargraph chartData={overBudgetYearChart} currencyCode={currencySymbol} />
                    </Grid>
                    <Grid item xs={12} md={12} style={{ padding: '0px 0px 0px 0px' }}>
                        <Bargraph chartData={overBudgetMonthChart} currencyCode={currencySymbol} />
                    </Grid>
                </Grid>
            )}
            <ButtonDivStyle>
                <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    // disabled={}
                    onClick={() => setShowBudget(!showBudget)}
                    className="loadingButtonStyle2"
                >
                    {showBudget ? t('executive-summary.graph') : t('executive-summary.view-table')}
                </LoadingButton>
                <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    // disabled={}
                    onClick={exportExcelTopOver}
                    className="loadingButtonStyle2"
                >
                    Export
                </LoadingButton>
            </ButtonDivStyle>
            {showUnderBudget ? (
                <ExecutiveBudgetTable
                    underBudgetflag
                    tableloader={tableLoader}
                    increseThisMonth={budgetUnderMonthData}
                    increseThisYear={budgetUnderYearData}
                    tableFirstHeading={t('executive-summary.top-5-expenses-under-budget')}
                    tableSecondHeading={t('executive-summary.top-5-expenses-under-budget')}
                    tableFirstSubHeading={t('executive-summary.this-year')}
                    tableSecondSubHeading={t('executive-summary.this-month')}
                    thFirst={t('executive-summary.expense')}
                    thSecond={t('executive-summary.amount')}
                    thThird={t('executive-summary.under-budget-by')}
                />
            ) : (
                <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{ margin: '0px', width: '100%' }}
                >
                    <Grid item xs={12} md={12} style={{ padding: '0px 0px 0px 0px' }}>
                        <Bargraph chartData={underBudgetYearChart} currencyCode={currencySymbol} />
                    </Grid>
                    <Grid item xs={12} md={12} style={{ padding: '0px 0px 0px 0px' }}>
                        <Bargraph chartData={underBudgetMonthChart} currencyCode={currencySymbol} />
                    </Grid>
                </Grid>
            )}
            <ButtonDivStyle>
                <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    // disabled={}
                    onClick={() => setShowUnderBudget(!showUnderBudget)}
                    className="loadingButtonStyle2"
                >
                    {showUnderBudget ? t('executive-summary.graph') : t('executive-summary.view-table')}
                </LoadingButton>
                <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    // disabled={}
                    onClick={exportExcel}
                    className="loadingButtonStyle2"
                >
                    Export
                </LoadingButton>
            </ButtonDivStyle>
        </MobileResDiv>
    );
}
