import { Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import AppDownloadIcons from 'components/app-download-icons/AppDownloadIcons';
import SubHeadingStyle from '../../../components/subHeadingStyle';

export default function WhyTrustMoolahMate() {
    const { t } = useTranslation();
    return (
        <Grid mt={0} className="ourVisionPadding">
            <Grid container spacing={2} className='personalManagementStyle'>
                <Grid item lg={6} md={12}>
                    <SubHeadingStyle
                        headLineOne={t('about-whyMoolah.headline-1')}
                        headLineTwo={t('about-whyMoolah.headline-2')}
                        headLineThree={''}
                    />
                </Grid>
                <Grid item lg={6} md={12}>
                    <div className='aboutOwnerStyle'>
                        <Typography variant='body1'>{t('about-whyMoolah.text-1')}</Typography>
                        <Typography variant='body1'>{t('about-whyMoolah.text-2')}</Typography>
                        <Typography variant='body1'><strong>{t('about-whyMoolah.text-3')}</strong></Typography>
                        <LoadingButton className="loadingButtonStyle2" type="submit" variant="contained" href="/register">
                            {t('common-translation.Free Trial')}
                        </LoadingButton>
                        <AppDownloadIcons />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}
