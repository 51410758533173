/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import { Grid, Typography } from '@mui/material';
import debounce from 'lodash.debounce';
import ScrollToTop from 'components/scroll-to-top/ScrollToTop';
import TopHeading from '../../components/TopHeading';
import TopMenu from '../../components/TopMenu';
import transform from '../../constants/strapiHelper';
import Page from '../../components/Page';
import './style.css';
import faq from '../../constants/services/faq';
import videoURL from '../../constants/helpVideoUrl';

export default function FAQ() {
    const [faqCategories, setFaqCategories] = useState([]);
    const [isLogged, setIsLogged] = useState(false);
    const [faqData, setFaqData] = useState({});
    const [searchValue, setSearchValue] = useState('');
    const [tableLoader, setTableLoader] = useState(false);

    const { t } = useTranslation();

    const getFaqs = async () => {
        setTableLoader(true)
        const faqCategories = await faq.getFaqCategories(searchValue);
        const categories = [];
        const faqObj = {};

        // eslint-disable-next-line no-restricted-syntax
        for (const category of faqCategories?.data) {
            const { title, faqs } = category?.attributes;
            categories.push(title);

            // eslint-disable-next-line no-restricted-syntax
            for (const faq of faqs?.data) {
                if (!faqObj[title]) {
                    faqObj[title] = [];
                }
                faqObj[title].push(faq.attributes);
            }
        }
        setTableLoader(false)
        setFaqCategories(categories);
        setFaqData(faqObj);
    };

    useEffect(() => {
        getFaqs();
    }, []);

    useEffect(() => {
        if (sessionStorage.getItem('userData')) {
            const user = JSON.parse(sessionStorage.getItem('userData'));
            if (Object.keys(user).length > 0) {
                setIsLogged(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const moduleNameStyle = {
        textDecoration: 'underline',
        color: '#048b67',
        fontSize: 14,
        marginRight: 30,
    };

    const deboundFaqSearch = debounce(() => {
        getFaqs();
    }, 500);

    useEffect(() => {
        deboundFaqSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    return (
        <Page title={"Have questions about MoolahMate? Our FAQs are here to help"} description={"Learn the ins and outs of MoolahMate and how it can help you with your personal finances with MoolahMate's FAQs. MoolahMate makes finances easy."} style={isLogged ? {} : { padding: '110px 9% 0px' }}>
            <Grid container spacing={2} mt={0} justifyContent="space-between" className="smMobileWidth">
                {isLogged && <TopMenu title="FAQs" videoLink={videoURL.faq} />}
                <TopHeading
                    heading={t('welcome-constant.description-11')}
                    showSearch
                    showGridListToggle={false}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    hideBackLink
                />
                <ScrollToTop />
                {
                    tableLoader ? <img className='loaderGif' alt="homeInfo" src="/static/animation_loader.gif" />
                        :
                        <Grid className="faqBanner">
                            <div style={{ marginBlock: 32, display: 'flex', flexWrap: 'wrap' }}>
                                {faqCategories.length === 0 && (
                                    <Typography variant="body1" className="noDataFoundHub" style={{paddingLeft: 0}}>
                                        {t('faq.No-Data-Found')}
                                    </Typography>
                                )}
                                {faqCategories.map((category, index) => (
                                    <a href={`#${index}`} style={moduleNameStyle} key={index}>
                                        {category}
                                    </a>
                                ))}
                            </div>

                            {faqCategories.map((category, index) => (
                                <div id={index} style={{ marginBlock: 32 }} key={index}>
                                    <Typography
                                        variant="body1"
                                        className="commonHeading"
                                        style={{ paddingBottom: '20px', borderBottom: '1px solid #F4F1EA' }}
                                    >
                                        {category}
                                    </Typography>
                                    {faqData[category].map((data, index) => (
                                        <Accordion key={index}>
                                            <AccordionSummary
                                                sx={{ padding: '0' }}
                                                expandIcon={<ArrowForwardIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className="commonSubHeding">{data.title}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {ReactHtmlParser(data.content, { transform: (node) => transform(node) })}
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </div>
                            ))}
                        </Grid>
                }
            </Grid>
        </Page>
    );
}
