import { addDuration } from "utils/formatTime";

export const countryOptions = [
    { label: 'Canada', value: process.env.REACT_APP_YODLEE_FASTLINK_CONFIG_NAME_CA },
    { label: 'Australia, India, South Africa', value: process.env.REACT_APP_YODLEE_FASTLINK_CONFIG_NAME_AU_IND_SA },
    { label: 'UK', value: process.env.REACT_APP_YODLEE_FASTLINK_CONFIG_NAME_UK },
    { label: 'US', value: process.env.REACT_APP_YODLEE_FASTLINK_CONFIG_NAME_US },
];

export const isAccesssTokenValid = (yodleeAccessToken) => {
    if (!yodleeAccessToken?.accessToken) {
        return false;
    }
    if (addDuration(yodleeAccessToken.issuedAt, { seconds: 1799 }) < new Date()) {
        return false;
    }
    return true;
}
