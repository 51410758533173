// @mui
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Input from '@mui/material/Input';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
// components
import Page from '../../../components/Page';
import { EXCEL_STYLE } from '../constant';
import { ContentStyle, PaymentTitle, HeaderDivStyle, HeaderTableStyle, TableStyle, CalculatorButton, TotalFlow, BudgetInput, ButtonText, TotalFlowValueColumn, TotalAmountDiv } from '../styled-components';
import calculator from '../../../constants/services/calculator';
import { formatDollarValues, removeNumberFormatting, formatZeroValues, downloadExcelHeader, getCurrencySymbol, goBackToMenu } from '../../../utils/calCommonFunction';
import TopMenu from '../../../components/TopMenu';
import TopHeading from '../../../components/TopHeading';

export default function ReturnOnInvestment() {
    const [investmentAmount, setInvestmentAmount] = useState("");
    const [annualDividend, setAnnualDividend] = useState("");
    const [grossProceeds, setGrossProceeds] = useState("");
    const [holdingPeriodMonth, setHoldingPeriodMonth] = useState("");
    const [returnInvestment, setReturnInvestment] = useState("");
    const [isLogged, setIsLogged] = useState(false)
    const navigate = useNavigate();
    const { t } = useTranslation();
    const currencySymbol = getCurrencySymbol();

    const getRoiDetails = async () => {
        const getRoiData = await calculator.getUserCalculator("return-on-investment");
        if (getRoiData.data) {
            const roiJsonData = getRoiData.data?.calcJson;
            setInvestmentAmount(roiJsonData.investment_amount);
            setAnnualDividend(roiJsonData.annual_dividend);
            setGrossProceeds(roiJsonData.gross_proceeds);
            setHoldingPeriodMonth(roiJsonData.holding_period_month);
            setReturnInvestment(roiJsonData.return_investment)
        }
    }

    useEffect(() => {
        if (sessionStorage.getItem('userData')) {
            const user = JSON.parse(sessionStorage.getItem('userData'));
            if (Object.keys(user).length > 0) {
                setIsLogged(true);
                getRoiDetails();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ReturnOnInvestmentDetails = () => {
        if (investmentAmount !== '' && annualDividend !== '' && grossProceeds !== '' && holdingPeriodMonth !== '') {
            const investmentAmt = (Number(removeNumberFormatting(investmentAmount)));
            const annualDividendAmt = (Number(removeNumberFormatting(annualDividend)));
            const grossProceedsAmt = (Number(removeNumberFormatting(grossProceeds)));

            const IRRval = [];
            IRRval.push(-investmentAmt);
            for (let i = 1; i < Number(removeNumberFormatting(holdingPeriodMonth)); i += 1) {
                IRRval.push(annualDividendAmt);
            }
            IRRval.push(grossProceedsAmt + annualDividendAmt);
            const rateCalAmt = IRRCalc(IRRval, 0.1);
            setReturnInvestment(Number(rateCalAmt).toLocaleString("en-US", { minimumFractionDigits: 2 }));
        }
        else {
            toast.error("Please fill all the details");
        }
    }

    const handleChanged = (e) => {
        const { name, value } = e.target;
        if (name === 'gross_proceeds') {
            setGrossProceeds(formatDollarValues(value));
        }
        if (name === 'holding_period') {
            setHoldingPeriodMonth(value);
        }
    }

    const resetFields = () => {
        setInvestmentAmount("");
        setAnnualDividend("");
        setGrossProceeds("");
        setHoldingPeriodMonth("");
        setReturnInvestment("")
    }

    const saveRoiData = async () => {
        try {
            const postData = {
                "investment_amount": investmentAmount,
                "annual_dividend": annualDividend,
                "gross_proceeds": grossProceeds,
                "holding_period_month": holdingPeriodMonth,
                "return_investment": returnInvestment
            }
            const embeddedDataObj = {
                "calcType": "return-on-investment",
                "calcJson": postData
            };
            await calculator.addUserCalculator(embeddedDataObj);
            toast.success('Calculation saved successfully');
        } catch (error) {
            toast.error(error.message);
        }
    }

    const IRRCalc = (CArray) => {
        let r = 0
        let min = -1.0;
        let max = 10000.0;
        let NPV = 0;
        let guess = 0;
        do {
            guess = (min + max) / 2;
            NPV = 0;
            for (let j = 0; j < CArray.length; j += 1) {
                // eslint-disable-next-line
                NPV += CArray[j] / Math.pow((1 + guess), j);
            }
            if (NPV > 0) {
                min = guess;
            }
            else {
                max = guess;
            }
            r += 1
        } while (r < 100);
        return guess * 100
    }

    const exportExcel = () => {
        const postData = [
            {
                "Description": "Investment amount",
                "Value": `${currencySymbol} ${investmentAmount}`
            },
            {
                "Description": "Annual dividends earned",
                "Value": `${currencySymbol} ${annualDividend}`
            },
            {
                "Description": "Gross proceeds form sale",
                "Value": `${currencySymbol} ${grossProceeds}`
            },
            {
                "Description": "Holding period in years",
                "Value": holdingPeriodMonth
            },
            {
                "Description": "",
                "Value": ""
            },
            {
                "Description": "Return on investment",
                "Value": ` ${returnInvestment} %`
            }
        ];
        const style = [
            {
                "col": 'A1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'B1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'A7',
                "style": EXCEL_STYLE.boldColor
            },
            {
                "col": 'B7',
                "style": EXCEL_STYLE.boldColorRight
            }
        ]
        const cols = [{ width: 25 }, { width: 20 }];
        const merges = [
            { s: { r: 5, c: 0 }, e: { r: 5, c: 1 } },
            { s: { r: 7, c: 0 }, e: { r: 7, c: 1 } },
            { s: { r: 8, c: 0 }, e: { r: 8, c: 1 } },
        ]
        downloadExcelHeader(postData, style, cols, merges, 'ReturnOnInvestment', 2, ['B'], false);
    }

    return (
        <Page title={"Add up your profits simply with MoolahMate's ROI calculator "} description={"Calculate the return on investment from both dividends earned and capital appreciation simply and easily with MoolahMate's ROI calculator."} className={isLogged ? '' : 'homeCalcultorsStyle'}>
            {isLogged && <TopMenu title={t('return-on-investment.calculator')} subText="Need Help?" hideBellIcon />}
            <TopHeading heading={t('return-on-investment.subtitle')} headingCenter={!isLogged} hideBackLink={!isLogged} />
            <div className={isLogged ? 'contentStyle' : 'topCalcPadding'}>
                <Grid container spacing={0} pt={0} justifyContent="center" alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={12}>
                        <HeaderDivStyle>
                            <PaymentTitle>{t('return-on-investment.description')}</PaymentTitle>
                            <div className="mobileOverFlowDiv">
                                <HeaderTableStyle>
                                    <tbody>
                                        <tr>
                                            <td>{t('return-on-investment.investment-amount')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="debt_amount"
                                                    value={investmentAmount}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setInvestmentAmount(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setInvestmentAmount(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('return-on-investment.annual-earned')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    placeholder="0"
                                                    name="monthly_payment"
                                                    value={annualDividend}
                                                    onBlur={(e) => {
                                                        setAnnualDividend(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setAnnualDividend(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('return-on-investment.gross-proceeds')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    placeholder="0"
                                                    name="gross_proceeds"
                                                    value={grossProceeds}
                                                    onBlur={(e) => {
                                                        setGrossProceeds(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={handleChanged}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('return-on-investment.holding-period')}</td>
                                            <BudgetInput>
                                                <span style={{ padding: '5px' }}>{''}</span>
                                                <Input
                                                    type="text"
                                                    placeholder="0"
                                                    name="holding_period"
                                                    value={holdingPeriodMonth}
                                                    onChange={(e) => {
                                                        setHoldingPeriodMonth(
                                                            formatDollarValues(e.target.value).toString().slice(0, 2)
                                                        );
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{''}</td>
                                            <td style={{ textAlign: 'end' }}>
                                                <CalculatorButton
                                                    fullWidth
                                                    size="large"
                                                    variant="contained"
                                                    className="loadingButtonStyle2"
                                                    onClick={() => ReturnOnInvestmentDetails()}
                                                >
                                                    Calculate
                                                </CalculatorButton>
                                            </td>
                                        </tr>
                                    </tbody>
                                </HeaderTableStyle>
                            </div>
                        </HeaderDivStyle>
                        <div className="divTableStyle" style={{ marginTop: '0px' }}>
                            <TableStyle>
                                <tbody>
                                    <tr>
                                        <TotalFlow>{t('return-on-investment.return-on-investment')}</TotalFlow>
                                        <TotalFlowValueColumn>
                                            <TotalAmountDiv style={{ justifyContent: 'end' }}>
                                                <span>{returnInvestment === '' ? 0 : returnInvestment} %</span>
                                            </TotalAmountDiv>
                                        </TotalFlowValueColumn>
                                    </tr>
                                </tbody>
                            </TableStyle>
                        </div>
                    </Grid>
                </Grid>
                <ButtonText style={isLogged ? {} : { justifyContent: 'center' }}>
                    {isLogged && (
                        <div className='backButtonShowStyle'>
                            {goBackToMenu(navigate)}
                        </div>
                    )}
                    <div className='centerAlignStyle'>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            // disabled={}
                            className="loadingButtonStyle2"
                            onClick={resetFields}
                            style={isLogged ? {} : { maxWidth: '200px' }}
                        >
                            {t('return-on-investment.reset')}
                        </LoadingButton>
                        {isLogged && (
                            <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                variant="outlined"
                                className="loadingButtonStyle1"
                                onClick={saveRoiData}
                            >
                                {t('return-on-investment.save')}
                            </LoadingButton>
                        )}
                        {isLogged && (
                            <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                className="loadingButtonStyle2"
                                onClick={exportExcel}
                            >
                                {t('return-on-investment.export')}
                            </LoadingButton>
                        )}
                    </div>
                </ButtonText>
            </div>
        </Page>
    );
}
