import {
    styled,
    Grid,
    Typography,
    Autocomplete,
    Accordion,
} from '@mui/material';

export const Fragment = styled('div')(({ theme }) => ({
    '& .dropMenu': {
        borderRadius: 10,
        backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
        padding: '6px 12px',
    },
    '& .MuiInputBase-root': {
        '&:before': {
            border: 0,
        },
    },
}));

export const LogoBg = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
    borderRadius: 10,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
}));

export const BankLogo = styled('div')(({ theme }) => ({
    width: 'auto',
    '& img': {
        height: 20,
        maxHeight: 20,
        maxWidth: 140,
        [theme.breakpoints.down('sm')]: {
            height: 20,
            maxHeight: 20,
        },
    },
    // height: '58px',
}));

export const Logo = styled('div')(() => ({
    padding: '55px 0',
}));

export const BankImg = styled('div')(({ theme }) => ({
    paddingBottom: 22,
    paddingRight: 22,
    height: '100%',
    [theme.breakpoints.down('lg')]: {
        paddingBottom: 0,
        paddingRight: 0,
    },
}));

export const NextButton = styled('div')(({ theme }) => ({
    paddingBottom: '20px',
    '& button': {
        textTransform: 'inherit',
        // fontSize: 18,
        fontWeight: '500',
        [theme.breakpoints.up('xl')]: {
            fontSize: 22,
        },
    },
}));

export const TextArea = styled(Grid)(() => ({
    '& div': {
        '& .MuiTypography-subtitle1': {
            width: '90%',
        },
    },
}));

export const Error = styled(Typography)(({ theme }) => ({
    color: 'red',
    margin: '-30px 0px 5px 0px',
    height: 30,
    fontSize: 14,
    position: 'absolute',
    lineHeight: '14px',
    [theme.breakpoints.down('xl')]: {
        marginTop: -28,
        fontSize: 12,
    },
}));

export const HeadArea = styled('div')(({ theme }) => ({
    '& div': {
        // '& .MuiTypography-h3': {
        //     width: 500,
        //     [theme.breakpoints.down('lg')]: {
        //         width: 410,
        //     },
        //     [theme.breakpoints.down('md')]: {
        //         width: 380,
        //     },
        //     [theme.breakpoints.down('sm')]: {
        //         width: '100%',
        //         lineHeight: '30px',
        //     },
        // },
        '& .MuiTypography-subtitle1': {
            width: '89%',
            paddingBottom: '30px',
            fontSize: 20,
            [theme.breakpoints.down('xl')]: {
                fontSize: 16,
            },
        },
    },
}));

export const DividerStyle = styled('div')(({ theme }) => ({
    background: theme.palette.primary.main,
    margin: '25px 0 55px',
    width: '100%',
    height: 2,
    border: 0,
    position: 'relative',
    [theme.breakpoints.down('md')]: {
        width: '100% !important',
    },
    '& span': {
        position: 'absolute',
        top: -12,
        left: '50%',
        transform: 'translateX(-50%)',
        background: theme.palette.secondary.contrastText,
        padding: '0 30px',
    },
}));

export const Form = styled('div')(() => ({
    '& form': {
        '& label': {
            whiteSpace: 'normal',
            overflow: 'auto',
            textOverflow: 'unset',
        },
    },
}));

export const AutoFieldStyle = styled(Autocomplete)(({ theme }) => ({
    fontSize: 20,
    paddingBottom: '60px',
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
        maxWidth: '100% !important',
    },
    '& input': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
    '& .MuiInputBase-root': {
        '&:hover:before': {
            borderBottom: '0 !important',
        },
        '&.Mui-focused:after': {
            transform: 'scaleX(0) !important',
        },
    },
    '& .MuiAutocomplete-popupIndicator': {
        position: 'relative',
        width: 25,
        '& svg': {
            display: 'none',
        },
        '&:after': {
            position: 'absolute',
            right: 0,
            top: -4,
            content: "''",
            background: 'url(/static/loginImg/down-arrow.svg) 0 0 no-repeat',
            width: 25,
            height: 13,
        },
    },
}));

export const Input = styled('div')(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontSize: 16,
    fontWeight: 400,
    marginTop: '10px',
    marginBottom: '8px',
    whiteSpace: 'normal',
    overflow: 'auto',
    textOverflow: 'unset',
    [theme.breakpoints.up('xl')]: {
        fontSize: 20,
    },
}));

export const FormStyle = styled(Form)(() => ({
    '& label': {
        marginBottom: 15,
    },
}));

export const ButtonText = styled('div')(() => ({
    '& button': {
        textTransform: 'inherit',
    },
}));

export const FastLinkContainer = styled('div')(() => ({
    backgroundColor: '#fff',
    borderRadius: 20,
    top: 50,
    // height: '620px',
    zIndex: 3,
    boxShadow: '0 2.8px 2.2px rgb(0 0 0 / 3%), 0 6.7px 5.3px rgb(0 0 0 / 5%), 0 12.5px 10px rgb(0 0 0 / 6%), 0 22.3px 17.9px rgb(0 0 0 / 7%), 0 41.8px 33.4px rgb(0 0 0 / 9%), 0 100px 80px rgb(0 0 0 / 12%)',

    'iframe': {
        border: 'none',
        borderRadius: 10,
        '#fastlink-container': {
            height: '100vh !important'
        }
    }
}));

export const AccordionStyle = styled(Accordion)(({ theme }) => ({
    width: '100%',
    marginTop: 15,
    '&:before': {
        height: 0,
    },
    '& svg': {
        fill: theme.palette.primary.main,
        '& path': {
            fill: theme.palette.primary.main,
        },
        '& g': {
            stroke: theme.palette.primary.main,
        },
    },
    '& .MuiButtonBase-root': {
        padding: 0,
        position: 'sticky',
        backgroundColor: '#f1f1f1',
        zIndex: '3',
        top: '0px',
        // borderBottom: `2px solid ${theme.palette.primary.main}`,
        '& .MuiAccordionSummary-content': {
            margin: 0,
            // background: theme.palette.secondary.lighter,
            padding: '26px 45px 25px',
            borderRadius: 10,
            alignItems: 'center',
            [theme.breakpoints.down('xl')]: {
                padding: 19,
            },
            '& p': {
                color: theme.palette.primary.dark,
                fontSize: 20,
                lineHeight: '27px',
                fontWeight: 700,
                marginRight: 'auto',
            },
            '& svg.icon-minus': {
                display: 'none'
            },
            '& svg.icon-plus': {
                display: 'block'
            },
        },
        '& .Mui-expanded': {
            borderRadius: '10px 10px 0 0',
            '& svg.icon-minus': {
                display: 'block'
            },
            '& svg.icon-plus': {
                display: 'none'
            },
        },
        '& button': {
            backgroundColor: '#29DE94',
            padding: '4px 16px',
            '&:hover': {
                backgroundColor: '#24C382',
                border: '2px solid #24C382',
            },
            '&:active': {
                backgroundColor: '#03B578',
                border: '2px solid #03B578',
                color: '#fff'
            }
        }
    },
    '& .MuiCollapse-root': {
        background: theme.palette.secondary.lighter,
        borderRadius: '0 0 10px 10px',
        padding: '0 45px 25px',
        [theme.breakpoints.down('xl')]: {
            padding: '0 19px 19px',
        },
        '& .MuiCollapse-wrapper': {
            borderTop: `solid 2px ${theme.palette.primary.main}`,
            '& .MuiAccordionDetails-root': {
                padding: 0,
            },
        },
        '& .sticky-header': {
            position: 'sticky',
            backgroundColor: '#f8f8f8',
            zIndex: '9',
            top: '65px',
        },
    },
}));

export const TableScroll = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        overflowX: 'auto',
    },
}));

export const TableStyle = styled('table')(({ theme }) => ({
    width: '100%',
    paddingTop: '10px',
    minWidth: 550,
    borderCollapse: 'collapse',
    [theme.breakpoints.down('md')]: {
        paddingTop: 10,
    },
    '& th': {
        textAlign: 'left',
        fontSize: 18,
        fontWeight: 700,
        color: theme.palette.primary.dark,
        padding: 10,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
    },
    '& tr.category-info': {
        borderTop: `solid 1px ${theme.palette.primary.main}`,
        borderBottom: `solid 1px ${theme.palette.primary.main}`,
        background: 'white'

    },
    '& td': {
        padding: 10,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
        width: '45%',
        '&:nth-child(3)': {
            width: '10%',
        }
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
}));