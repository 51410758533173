import { useState } from 'react';
import { Grid, Typography, Box, Dialog, DialogContent, DialogActions, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import image from 'assets/image';
import DialogAtom from 'components/dialog/Dialog';
// import { RHFCheckbox } from 'components/hook-form';
import { getGeneralMenu, getCalculatorsMenu, getResourcesMenu, getHelpMenu, socialLinks, getFooterLinks } from './constant';
import './style.css'
import SocialLinks from '../socialLinks/SocialLinks';

export default function HomeContact() {
    const { t } = useTranslation();
    const footerGeneralMenu = getGeneralMenu(t);
    const footerCalculatorsMenu = getCalculatorsMenu(t);
    const footerResourcesMenu = getResourcesMenu(t)
    const footerHelpMenu = getHelpMenu(t)
    const footerLinks = getFooterLinks(t);
    const [showDialog, setShowDialog] = useState(false);
    const [showCookieDialog, setShowCookieDialog] = useState(false);

    const checkboxStatus=()=>{
        setShowCookieDialog(false)
    }

    const onClose = () => {
        setShowCookieDialog(false)
    }

    const onDialogAction = async (buttonKey) => {
        if (buttonKey === 'cancel') {
            setShowDialog(false);
        }
    };

    const showCookieModal=()=>{
        setShowCookieDialog(!showCookieDialog)
    }

    const getModalPopup = () => <DialogAtom
        open
        maxWidth="sm"
        dialogTitle={t('footer.text2')}
        content={<>{t('footer.careers-modal-text')}</>}
        onDialogAction={onDialogAction}
        hideSave
    />

    return (
        // <Box className='mainBox'>
        <footer className='mainBox'>
            <div className='footerPadding footerHeader'>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <img src={image.FooterLogo} alt='website logo' className='footerLogoStyle'/>
                    </Grid>
                    <Grid item md={6}>
                        <Typography variant='body'>{t('footer.body1')}<br /> {t('footer.body2')}</Typography>
                    </Grid>
                </Grid>

                <div className='footerNavMenu'>
                    <Grid container spacing={2} justifyContent={'space-between'}>
                        <Grid item md={3} className='hideMobileDiv'>
                            <ul className='footerMenu'>
                                <li>
                                    <LoadingButton className='loadingButtonStyle2' variant="contained" href="/register">
                                        {'Start your Free Trial'}
                                    </LoadingButton>
                                </li>
                                <li>
                                    <LoadingButton type="submit" variant="text" to="#" style={{marginBottom: '2.6px'}} onClick={() => window.open('https://apps.apple.com/us/app/moolahmate/id6449456630', '_blank')}>
                                        <img src={image.iosIcon} alt="app store logo" />
                                    </LoadingButton>
                                </li>
                                <li>
                                    <LoadingButton type="submit" variant="text" to="#" onClick={() => window.open('https://play.google.com/store/apps/details?id=com.moolahmate.app', '_blank')}>
                                        <img src={image.androidIcon} alt="android app logo" />
                                    </LoadingButton>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item md={2} xs={6}>
                            <Typography variant='body' className='footerSubHeading'>{t('common-translation.General')}</Typography>
                            <ul className='footerMenu'>
                                {footerGeneralMenu.map((items, indx) => {
                                    if (items.link) {
                                        return <li key={indx}><a href={items.link}>{items.text}</a></li>
                                    }
                                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                                    return <li key={indx}><a style={{cursor: 'pointer'}} onClick={() => setShowDialog(true)}>{items.text}</a></li>
                                })}
                            </ul>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography variant='body' className='footerSubHeading'>{t('common-translation.Calculators')}</Typography>
                            <ul className='footerMenu'>
                                {footerCalculatorsMenu.map((items, index) => (
                                    <li key={index}><Link to={items.link}>{items.text}</Link></li>
                                ))}
                            </ul>
                        </Grid>
                        <Grid item md={2} xs={6}>
                            <Typography variant='body' className='footerSubHeading'>{t('common-translation.Resources')}</Typography>
                            <ul className='footerMenu'>
                                {footerResourcesMenu.map((items, index) => (
                                    <li key={index}><Link to={items.link}>{items.text}</Link></li>
                                ))}
                            </ul>
                        </Grid>
                        <Grid item md={2} xs={6}>
                            <Typography variant='body' className='footerSubHeading'>{t('common-translation.Help-Center')}</Typography>
                            <ul className='footerMenu'>
                                {footerHelpMenu.map((items, index) => (
                                    <li key={index}><Link to={items.link}>{items.text}</Link></li>
                                ))}
                            </ul>
                        </Grid>
                    </Grid>
                </div>
            </div>

            <div className='footerBottomMenu'>
                <Grid container>
                    <Grid item md={9} p={0} sm={12} className='mobileOrder'>
                        <div className='footerFlex footerLinks mobileDisplayBlock'>
                            <Typography variant='body'>{t('common-translation.corporation')}</Typography>
                            <div className='footerBottomNavBar'>
                                {footerLinks.map((footerLink) => (
                                    <Link to={footerLink.url} key={footerLink.id} onClick={footerLink.text === 'Cookie Preferences' ? showCookieModal : ''}>{footerLink.text}</Link>
                                ))}
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={3} sm={12}>
                        <SocialLinks />
                    </Grid>
                </Grid>
                <Dialog
                    open={showCookieDialog}
                    maxWidth="sm"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <Typography variant='h4' style={{textAlign: 'center'}}>{t('footer.cookie-heading')}</Typography>
                        {/* <Typography variant='h3' style={{textAlign: 'center'}}>
                            <Checkbox disabled checked />
                        </Typography> */}
                        <FormGroup>
                            <FormControlLabel disabled control={<Checkbox checked />} label="Essential" />
                        </FormGroup>
                        <Typography variant='body'>{t('footer.cookie-content')}</Typography>
                    </DialogContent>
                    <DialogActions style={{ padding: '8px 10px 10px' }}>
                        <LoadingButton
                            size="large"
                            className='loadingButtonStyle2'
                            variant="contained"
                            onClick={() => checkboxStatus()}
                        >
                            {t('footer.cookie-close')}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </div>
            {showDialog && getModalPopup()}
        </footer>
        // </Box>
    );
}
