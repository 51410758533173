import { styled, Typography, Grid, Card, Box } from '@mui/material';

export const Wrapper = styled(Grid)(({ theme }) => ({
    padding: '0px 16% 50px calc(4% + 90px)',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        padding: '0px 12% 50px calc(4% + 50px)',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '0px 4% !important',
    },
}));

export const FullWidthWrapper = styled(Grid)(({ theme }) => ({
    padding: '0px 12% 50px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        padding: '0px 12%',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '0px 4% !important',
    },
}));

export const RootStyle = styled('div')(() => ({
    paddingBottom: 0,
    paddingTop: 0,
}));

export const Title = styled(Typography)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '70%',
    paddingLeft: 0,
    maxWidth: 300,
    [theme.breakpoints.down('lg')]: {
        width: 250,
    },
    [theme.breakpoints.down('md')]: {
        width: 210,
    },
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '90%',
    paddingTop: '25px',
    paddingLeft: 0,
    [theme.breakpoints.down('md')]: {
        paddingBottom: '30px',
    },
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

export const MainPoints = styled(Typography)(({ theme }) => ({
    color: '#048B67',
    marginLeft: '60px',
    fontSize: '16px',
    fontWeight: '600',
    minHeight: 36,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        marginRight: '10px',
    }
}));

export const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 90,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    margin: 0,
    background: theme.palette.primary.main,
    borderRadius: 0,
    position: 'fixed',
    height: '100vh',
    top: 0,
    [theme.breakpoints.down('md')]: {
        maxWidth: 50,
    },
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

export const StartButton = styled('div')(({theme}) => ({
    padding: '26px 0px 0px',
    display: 'flex',
    justifyContent: 'start',
    // width: '100%',
    paddingLeft: 60,
    '& button': {
        textTransform: 'none',
        // width: '100%',
        width: '300px',
        fontSize: 18,
        lineHeight: '27px',
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
            width: 'auto',
        },
    },
}));

export const SubPoints = styled(Typography)(({ theme }) => ({
    padding: '10px 0 48px',
    marginLeft: '60px',
    [theme.breakpoints.down('md')]: {
        marginRight: '10px',
    },
    [theme.breakpoints.down('xl')]: {
        fontSize: 14,
        lineHeight: '18px',
        paddingBottom: 20,
        paddingTop: 10,
    },
}));

export const UlStyle = styled('ul')(({ theme }) => ({
    paddingLeft: 60,
    marginTop: -40,
    marginBottom: 48,
    [theme.breakpoints.down('xl')]: {
        marginTop: -15,
        marginBottom: 20,
    },
    '& li': {
        position: 'relative',
        listStyle: 'none',
        '&:after': {
            width: 5,
            height: 5,
            background: theme.palette.primary.dark,
            content: "''",
            position: 'absolute',
            left: -17,
            top: 6,
            borderRadius: 5,
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: 14,
            lineHeight: '18px',
        },
    },
}));

export const ListItem = styled('ul')(({ theme }) => ({
    listStyle: 'none',
    position: 'relative',
    marginTop: 20,
    '& h6':{
        margin: '0px',
    },
    '& li': {
        position: 'relative',
        marginBottom: 20,
        display: 'flex',
        alignItems: 'center',
        // '&:before': {
        //     content: '"1."',
        //     color: '#fff',
        //     textAlign: 'center',
        //     paddingLeft: '4px',
        //     fontWeight: '700',
        //     fontSize: '18px',
        //     lineHeight: '38px',
        //     position: 'absolute',
        //     left: '0px',
        //     top: '0px',
        //     borderRadius: '50%',
        //     background: '#048B67',
        //     // background: 'url("/static/onetime/circle.png") no-repeat',
        //     backgroundPosition: 'center center',
        //     backgroundSize: 'cover',
        //     width: '38px',
        //     height: '36px',
        //     [theme.breakpoints.down('md')]: {
        //         left: '10px',
        //     },
        // },
        // '&:nth-child(2)': {
        //     '&:before': {
        //         content: '"2."',
        //         borderRadius: '50%',
        //         background: '#048B67',
        //         // background: 'url("/static/onetime/circle.png") no-repeat',
        //         backgroundPosition: 'center center',
        //         backgroundSize: 'cover',
        //     },
        // },
        // '&:nth-child(3)': {
        //     '&:before': {
        //         content: '"3."',
        //         borderRadius: '50%',
        //         background: '#048B67',
        //         // background: 'url("/static/onetime/circle.png") no-repeat',
        //         backgroundPosition: 'center center',
        //         backgroundSize: 'cover',
        //     },
        // },
        // '&:nth-child(4)': {
        //     '&:before': {
        //         content: '"4."',
        //         borderRadius: '50%',
        //         background: '#048B67',
        //         // background: 'url("/static/onetime/circle.png") no-repeat',
        //         backgroundPosition: 'center center',
        //         backgroundSize: 'cover',
        //     },
        // },
        // '&:nth-child(5)': {
        //     '&:before': {
        //         content: '"5."',
        //         borderRadius: '50%',
        //         background: '#048B67',
        //         // background: 'url("/static/onetime/circle.png") no-repeat',
        //         backgroundPosition: 'center center',
        //         backgroundSize: 'cover',
        //     },
        // },
    },
    '& ul': {
        '& li': {
            marginLeft: '30px',
            '&:before': {
                display: 'none !important',
            },
        },
    },
}));

export const SmallLogoStyle = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '20px 23px 20px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        padding: '20px 4px 20px',
    },
    '& a div': {
        '& path': {
            fill: theme.palette.secondary.contrastText,
        },
    },
    '& + .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',
        margin: 'auto 0',
    },
}));