import videoURL from "constants/helpVideoUrl";

export const GENERAL_REPORTS = [
    {
        title: 'Financial Goals',
        path: '/dashboard/financial-goals',
        groupName : '',
        helpVideo: videoURL.financialGoals
    },
    {
        title: 'Net Worth',
        path: '/dashboard/net-worth',
        groupName : '',
        helpVideo: videoURL.netWorth
    },
    {
        title: 'Budget',
        path: '/my-profile/budget',
        groupName : 'Assets',
        helpVideo: videoURL.budget
    },
];

export const DASHBOARD = [
    {
        title: 'Asset Composition',
        path: '/dashboard/asset-composition',
        groupName : 'Assets',
        helpVideo: videoURL.assetComposition
    }, 
    {
        title: 'Liability Composition',
        path: '/dashboard/liabilities-composition',
        groupName : 'Liabilities',
        helpVideo: videoURL.liabilityComposition
    },    
    {
        title: 'Status of Credit Card Usage',
        path: '/dashboard/liabilities-credit-card', 
        groupName : '',
        helpVideo: videoURL.statusOfCreditCardUsage       
    },
    {
        title: 'Status of Lines of Credit Usage',
        path: '/dashboard/line-of-credit',
        groupName : '',
        helpVideo: videoURL.statusOfLineOfCreditUsage
    },
    {
        title: 'Debt Payment Report',
        path: '/dashboard/debt-payment-report',
        groupName : '',
        helpVideo: videoURL.debtPayment
    },
    {
        title: 'Loans/Lines of Credit Activity',
        path: '/dashboard/loans-activity',
        groupName : '',
        helpVideo: ''
    } 
];

export const INCOME_EXPENSE = [
    {
        title: 'Income & Expense Summary',
        path: '/dashboard/income-expense-summary',
        groupName : 'Income/Cash Inflows',
        helpVideo: videoURL.incomeExpenseSummary
    }, {
        title: 'Income Composition',
        path: '/dashboard/income-composition',
        groupName : 'Income/Cash Inflows',
        helpVideo: videoURL.incomeComposition       
    },
    {
        title: 'Expense Composition',
        path: '/dashboard/expense-composition', 
        groupName : 'Expenses/Cash Outflows',
        helpVideo: videoURL.expenseComposition     
    },
    {
        title: 'Variable Expenses',
        path: '/dashboard/variable-expense',
        groupName : 'Expenses/Cash Outflows',
        helpVideo: videoURL.variableExpenses    
    }, 
    {
        title: 'Fixed Expenses',
        path: '/dashboard/fixed-expense',
        groupName : 'Expenses/Cash Outflows',
        helpVideo: videoURL.fixedExpenses      
    },
    {
        title: 'Top 5 Expenses',
        path: '/dashboard/top-five-expense',
        groupName : 'Expenses/Cash Outflows',
        helpVideo: videoURL.top5Expenses     
    },
    {
        title: 'Top 10 Expenses',
        path: '/dashboard/top-ten-expense', 
        groupName : 'Expenses/Cash Outflows',
        helpVideo: videoURL.top10Expenses      
    }
];

export const FINANCIAL_GOAL = [
]

export const CHARTCOLOR = [
    'rgba(54, 162, 235, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 0.6)',
    'rgba(153, 102, 255, 0.6)',
    'rgba(255, 159, 64, 0.6)',
    'rgba(255, 99, 132, 0.6)',
    'rgba(238, 130, 238,0.6)',
    'rgba(255, 165, 0,0.6)',
    'rgba(255, 206, 90 ,0.6)',
    'rgba(54, 162, 90 ,0.6)',
    'rgba(255, 192, 90 ,0.6)',
    'rgba(255, 206, 86, 0.6)',
    'rgba(75, 192, 192, 0.6)',
    'rgba(153, 102, 255, 0.6)',
    'rgba(255, 159, 64, 0.6)'
];
