import { AccordionDetails, AccordionSummary, Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import Iconify from "components/Iconify";
import { AccordionStyle, DividerStyle, TableStyle } from "./styled-components";

export default function DemographicTable({ demographicToDateData = {}, genderList=[] }) {
    const {countAll, genderData, avgFamilySize, avgAgeGroup} = demographicToDateData || {countAll: 0, genderData: [], avgFamilySize: 0, avgAgeGroup: 0};
    return (
        <Grid
            container
            spacing={4}
            marginTop={2}
            className="marginSmTop"
            justifyContent="center"
            alignItems="flex-start"
            paddingLeft={4}
        >
            <AccordionStyle>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>Demographic Report as at "To" Date</Typography>
                    <Iconify icon="ant-design:plus-circle-outlined" sx={{ minWidth: 22, height: 22 }} />
                    <Iconify icon="akar-icons:circle-minus" sx={{ minWidth: 22, height: 22 }} />
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer sx={{ maxHeight: 500 }}>
                        <TableStyle>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={genderList?.length + 1} style={{ textAlign: 'center' }}>Gender Type</TableCell>
                                    <TableCell colSpan={2}>&nbsp;</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>All</TableCell>
                                    {genderList?.map((g) => <TableCell key={g.label}>{g.label}</TableCell>)}
                                    <TableCell>Avg Family Size</TableCell>
                                    <TableCell>Avg Age Group</TableCell>
                                </TableRow>
                            </TableHead>
                            <DividerStyle />
                            <TableBody>
                                <TableRow>
                                    <TableCell>{countAll}</TableCell>
                                    {genderList?.map((g) => <TableCell key={g.label}>{genderData?.[g.label]}</TableCell>)}
                                    <TableCell>{avgFamilySize}</TableCell>
                                    <TableCell>{avgAgeGroup}</TableCell>
                                </TableRow>
                            </TableBody>
                        </TableStyle>
                    </TableContainer>
                </AccordionDetails>
            </AccordionStyle>
        </Grid>
        
    );
}
