/* eslint-disable guard-for-in */
/* eslint-disable no-loop-func */
/* eslint-disable no-plusplus */
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
// import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import user from 'constants/services/user';
import videoURL from 'constants/helpVideoUrl';
import dashboard from '../../../constants/services/dashboard';
import { EXCEL_STYLE } from '../../calculators/constant';
import Page from '../../../components/Page';
import TopMenu from '../../../components/TopMenu';
import TopHeading from '../../../components/TopHeading';
import CreditCardFilter from '../filter/CreditCardFilter';
import { NoDataTd, ReportAmountTd, TotalAmountDiv, SpanTotalFlow, ButtonText } from '../ExecutiveSummary/styled-component';
import {
    removeEmpty,
    getMonthNameYear,
    getCurrencySymbol,
    getPositiveNumber,
    exportCustomExcel,
    getNegativeNumber,
} from '../../../utils/calCommonFunction';
import {
    ContentStyle,
    SubmitButton,
    TableStyle,
    StickyFirstTh,
    StickySecondTh,
    StickyThirdTh,
    TableWrapper,
    StickyFirstTd,
    StickySecondTd,
    StickyThirdTd,
    StickyTable,
    LoaderGif,
    AmountDiv,
    StickyFourthTh,
    StickyFourthTd,
} from '../Expense/styled-component';

export default function IncomeExpenseSummary() {
    const [tableLoader, setTableLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [monthColumn, setMonthColumn] = useState([]);

    const [expenseCategoryNames, setExpenseCategoryNames] = useState([]);
    const [expenseTableRows, setExpenseTableRows] = useState([]);
    const [expenseMonthlyTotal, setExpenseMonthlyTotal] = useState([]);
    const [totalActualExpense, setTotalActualExpense] = useState();
    const [expenseBudgetList, setExpenseBudgetList] = useState([]);
    const [totalExpenseBudget, setTotalExpenseBudget] = useState(0);

    const [incomeCategoryNames, setIncomeCategoryNames] = useState([]);
    const [incomeTableRows, setIncomeTableRows] = useState([]);
    const [incomeMonthlyTotal, setIncomeMonthlyTotal] = useState([]);
    const [totalActualIncome, setTotalActualIncome] = useState();
    const [incomeBudgetList, setIncomeBudgetList] = useState([]);
    const [totalIncomeBudget, setTotalIncomeBudget] = useState(0);
    const [categories, setCategories] = useState([]);
    const [netIncomeMonthly, setNetIncomeMonthly] = useState([]);

    const [excelIncomeData, setExcelIncomeData] = useState({})
    const [excelExpenseData, setExcelExpenseData] = useState({})

    const location = useLocation();
    const currencyCode = getCurrencySymbol();
    const navigate = useNavigate();

    const [categoriesDetails, setCategoriesDetails] = useState({
        catName: '',
        subCatName: ''
    })

    const { t } = useTranslation();

    const [filtersData, setFiltersData] = useState({
        fromMonth: moment.parseZone().format('YYYY-MM'),
        compareMonth: moment.parseZone().startOf('year').format('YYYY-MM'),
        categoryGroupId: `${location?.state?.categoryGroupId},${location?.state?.expenseCategoryId}` || null,
        categoryId: null,
        type: 2,
        splitDataInCategoryGroup: 'true',
        fetchUncategorizedExpenses: 'true',
    });

    const updateFilters = (key, value) => {
        const clonedData = {
            ...filtersData,
            [key]: value,
        };
        if (key === 'categoryId') {
            clonedData.subCatId = '';
            if (value) {
                const categoryGroup = categories.find((c) => c.id === value);
                clonedData.categoryGroupId = categoryGroup?.categoryGroupId;
            } else {
                clonedData.categoryGroupId = `${location?.state?.categoryGroupId},${location?.state?.expenseCategoryId}`;
            }
        }
        setFiltersData(clonedData);
    };

    const getExpenseCategoryData = async () => {
        try {
            const filtersDataObj = removeEmpty(filtersData);
            setTableLoader(true);
            setLoading(true);

            const response = await dashboard.getDashboardData(filtersDataObj);
            const { incomeData, expenseData } = response;
            setTableLoader(false);
            setLoading(false);
            setExcelExpenseData(expenseData)
            setExcelIncomeData(incomeData)

            let monthRange = Object.keys(incomeData);
            if (!monthRange?.length) {
                monthRange = Object.keys(expenseData);
            }
            setMonthColumn(monthRange);

            const expenseGroupedData = [];
            const expenseMonthlyTotalArr = [];
            const expenseBudgetData = [];

            const incomeGroupedData = [];
            const incomeMonthlyTotalArr = [];
            const incomeBudgetData = [];
            monthRange.sort();

            // eslint-disable-next-line
            monthRange.map((item) => {
                if (Object.keys(incomeData)?.length) {
                    let totalAsset = 0;
                    incomeData[item].forEach((a) => {
                        const assetName = a.name;
                        incomeGroupedData[assetName] = incomeGroupedData[assetName] || [];
                        incomeGroupedData[assetName].push(a.values);

                        incomeBudgetData[assetName] = Number(a.budget) * monthRange.length;
                        totalAsset += Number(a.values);
                    });
                    incomeMonthlyTotalArr.push(totalAsset);
                }
                if (Object.keys(expenseData)?.length) {
                    let totalAsset = 0;
                    expenseData[item].forEach((a) => {
                        const assetName = a.name;
                        expenseGroupedData[assetName] = expenseGroupedData[assetName] || [];
                        expenseGroupedData[assetName].push(a.values);

                        expenseBudgetData[assetName] = Number(a.budget) * monthRange.length;
                        totalAsset += Number(a.values);
                    });
                    expenseMonthlyTotalArr.push(totalAsset);
                }
            });

            const expenseCategories = Object.keys(expenseGroupedData);
            const totalExpense = expenseTotalSum(expenseMonthlyTotalArr);

            const incomeCategories = Object.keys(incomeGroupedData);
            const totalIncome = expenseTotalSum(incomeMonthlyTotalArr);
            const netIncomeMonthlyArr = monthRange.map(
                (month, i) => incomeMonthlyTotalArr[i] - Math.abs(expenseMonthlyTotalArr[i])
            );
            setNetIncomeMonthly(netIncomeMonthlyArr);

            setIncomeTableRows(incomeGroupedData);
            setTotalActualIncome(totalIncome);
            setIncomeMonthlyTotal(incomeMonthlyTotalArr);
            setIncomeCategoryNames(incomeCategories);
            setIncomeBudgetList(incomeBudgetData);
            setTotalIncomeBudget(getBudgetTotal(incomeBudgetData));

            setExpenseTableRows(expenseGroupedData);
            setTotalActualExpense(totalExpense);
            setExpenseMonthlyTotal(expenseMonthlyTotalArr);
            setExpenseCategoryNames(expenseCategories);
            setExpenseBudgetList(expenseBudgetData);
            setTotalExpenseBudget(getBudgetTotal(expenseBudgetData));
        } catch (error) {
            setTableLoader(false);
            setLoading(false);
        }
    };

    const expenseTotalSum = (array) => {
        const result = array.reduce((acc, val) => acc + val, 0);
        return result;
    };

    const getTotalSumofData = (tableData, item) => {
        const sumofdata = tableData[item].reduce((acc, item) => Number(acc) + Number(item), 0);
        return sumofdata;
    };

    const getIncomeBudgetSummary = (tableData, budgetList, item) => {
        const actual = tableData[item].reduce((acc, item) => Number(acc) + Number(item), 0);
        const budget = budgetList[item];
        return getPositiveNumber(actual - budget);
    };

    const getExpenseBudgetSummary = (tableData, budgetList, item) => {
        const actual = tableData[item].reduce((acc, item) => Number(acc) + Number(item), 0);
        const budget = budgetList[item];
        let betterWorse = budget - actual;
        if (actual < 0) {
            betterWorse = budget + actual;
        }
        if (actual > 0) {
            return getNegativeNumber(betterWorse);
        }
        return getPositiveNumber(betterWorse);
    };

    const getBudgetTotal = (budgetData) => {
        const total = Object.values(budgetData).reduce((acc, item) => Number(acc) + Number(item), 0);
        return total;
    };

    const getFilterData = () => {
        getExpenseCategoryData();
    };

    const getCategories = async () => {
        const response = await user.getUserCategories('categoryGroupName=Income/Cash Inflows,Expenses/Cash Outflows');
        const categories = response?.data || [];
        setCategories(categories);
    };

    const getExcelDetails = (excelData, assetTableRows, getBudgetList, getReportName) => {
        const outputArray = []
        // eslint-disable-next-line no-restricted-syntax
        for (const month in excelData) {
            excelData[month].forEach(item => {
                const reportTag = getReportName==='Income'? getPositiveNumber(item.values): getNegativeNumber(item.values)
                const existingItem = outputArray.find(outputItem => outputItem[getReportName] === item.name);
                const betterWorse = getReportName==='Income'? getPositiveNumber(getIncomeBudgetSummary(assetTableRows, getBudgetList, item.name)) : getExpenseBudgetSummary(assetTableRows, getBudgetList, item.name);
                if (existingItem) {
                    existingItem[month] = reportTag;
                } else {
                    const newItem = { [getReportName]: item.name, 'Actual': getPositiveNumber(getTotalSumofData(assetTableRows, item.name)), 'Budget': getPositiveNumber(getBudgetList[item.name]), 'Better/(Worse)':  betterWorse};
                    newItem[month] = reportTag;
                    outputArray.push(newItem);
                }
            });
        }
        return outputArray
    }

    const totalofMothData = (record, totalAsset, totalBudget, betterWorse, cateName, getReportName) => {
        const setTotalValue = []
        record.forEach((item, index) => {
            const existingMonth = setTotalValue.find(outputItem => outputItem[getReportName] === cateName);
            const assetSum = getReportName==='Income' || getReportName==='NetIncome'? getPositiveNumber(totalAsset): getNegativeNumber(totalAsset)
            const reportTag = getReportName==='Income' || getReportName==='NetIncome'? getPositiveNumber(item): getNegativeNumber(item)
            if (existingMonth) {
                existingMonth[monthColumn[index]] = reportTag;
            } else {
                const newItem = { [getReportName]: cateName, Actual: assetSum, Budget: getPositiveNumber(totalBudget), 'Better/(Worse)': getPositiveNumber(betterWorse) };
                newItem[monthColumn[index]] = reportTag;
                setTotalValue.push(newItem);
            }
        });
        return setTotalValue
    }

    const getExcelSummary = () => {
        const selectedFormData = []
        const merges = []
        const incomeBetterWorse = totalActualIncome - totalIncomeBudget
        const expenseBetterWorse = totalExpenseBudget - Math.abs(totalActualExpense)
        const actualExpense = totalActualIncome - Math.abs(totalActualExpense)
        const incomeBudget = totalIncomeBudget - totalExpenseBudget
        // let betterWorse = incomeBudget - actualExpense;
        // if (actualExpense < 0 || betterWorse < 0) {
        //     betterWorse *= -1;
        // }
        const income = totalActualIncome - totalIncomeBudget;
        const expense = Math.abs(totalActualExpense) - totalExpenseBudget;

        const betterWorse = income - expense;

        const getExpenseData = getExcelDetails(excelIncomeData, incomeTableRows, incomeBudgetList, 'Income')
        const getIncomeData = getExcelDetails(excelExpenseData, expenseTableRows, expenseBudgetList, 'Expense')

        selectedFormData.push({
            'From Date': filtersData.compareMonth,
            'To Date': filtersData.fromMonth,
            'Category Name': categoriesDetails.catName,
            'Sub-Category Name': categoriesDetails.subCatName
        })

        const getIncomeTotalData = totalofMothData(incomeMonthlyTotal, totalActualIncome, totalIncomeBudget, incomeBetterWorse, 'Total income', 'Income')
        const getExpenseTotalData = totalofMothData(expenseMonthlyTotal, totalActualExpense, totalExpenseBudget, expenseBetterWorse, 'Total expenses', 'Expense')
        const getNetIncomeData = totalofMothData(netIncomeMonthly, actualExpense, incomeBudget, betterWorse, 'Net income (loss)', 'NetIncome',)

        const mergeMultiData = [...getExpenseData, ...getIncomeTotalData]
        const newExpenseData = mergeMultiData.map((item) => {
            const { Income, Actual, Budget, 'Better/(Worse)': betterOrWorse, ...rest } = item;
            return { Income, ...rest, Actual, Budget, 'Better/(Worse)': betterOrWorse };
        });
        const addExpenseCurrencySymbol = newExpenseData.map(obj => {
            const newObj = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const key in obj) {
                if (!['Income'].includes(key)) {
                    newObj[key] = `$ ${obj[key]}`;
                } else {
                    newObj[key] = obj[key];
                }
            }
            return newObj;
        });
        const incomeData = [...getIncomeData, ...getExpenseTotalData]
        const NewIncomeData = incomeData.map((item) => {
            const { Expense, Actual, Budget, 'Better/(Worse)': betterOrWorse, ...rest } = item;
            return { Expense, ...rest, Actual, Budget, 'Better/(Worse)': betterOrWorse };
        });
        const addIncomeCurrencySymbol = NewIncomeData.map(obj => {
            const newObj = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const key in obj) {
                if (!['Expense'].includes(key)) {
                    newObj[key] = `$ ${obj[key]}`;
                } else {
                    newObj[key] = obj[key];
                }
            }
            return newObj;
        });

        const netWorthData = [...getNetIncomeData]
        const newNetWorthData = netWorthData.map((item) => {
            const { NetIncome, Actual, Budget, 'Better/(Worse)': betterOrWorse, ...rest } = item;
            return { NetIncome, ...rest, Actual, Budget, 'Better/(Worse)': betterOrWorse };
        });
        const addNetWorthCurrencySymbol = newNetWorthData.map(obj => {
            const newObj = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const key in obj) {
                if (!['NetIncome'].includes(key)) {
                    newObj[key] = `$ ${obj[key]}`;
                } else {
                    newObj[key] = obj[key];
                }
            }
            return newObj;
        });

        let data2 = [];
        if (categoriesDetails && categoriesDetails.catName && categoriesDetails.catName.length !== 0 ) {
            data2 = [selectedFormData, addExpenseCurrencySymbol, addIncomeCurrencySymbol];
        } else {
            data2 = [selectedFormData, addExpenseCurrencySymbol, addIncomeCurrencySymbol, addNetWorthCurrencySymbol];
        }

        const style = [
            {
                "col": 'A1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'B1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'C1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'D1',
                "style": EXCEL_STYLE.boldHeader
            }
        ]
        const cols = [{ width: 18 }, { width: 13 }, { width: 17 }, { width: 20 }, { width: 13 }, { width: 13 }, { width: 13 }, { width: 13 }, { width: 13 }, { width: 13 }, { width: 13 }, { width: 13 }];
        const gap = `A ${newExpenseData.length + 6}`
        exportCustomExcel(data2, style, cols, merges, 'incomeExpeseSummary', 7, ['I'], [false, false, false, true], [-1, 'A4', gap, -1]);
    }

    useEffect(() => {
        getExpenseCategoryData();
        getCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getNetIncomeBetterLoss = () => {
        const income = totalActualIncome - totalIncomeBudget;
        const expense = Math.abs(totalActualExpense) - totalExpenseBudget;

        const netIncomeBetterLoss = income - expense;
        return getPositiveNumber(netIncomeBetterLoss);
    };

    return (
        <>
            <Page title={t('income-expense-summary.title')}>
                <TopMenu title="Income and Expense Reports" videoLink={videoURL.incomeExpenseSummary} />
                <TopHeading heading={t('income-expense-summary.subtitle')} />
                <ContentStyle>
                    <Grid container pt={0} pb={4} mt={0}>
                        <Grid item md={10.5} xs={12}>
                            <CreditCardFilter
                                filtersData={filtersData}
                                updateFilters={updateFilters}
                                categories={categories}
                                categoriesDetails={categoriesDetails}
                                setCategoriesDetails={setCategoriesDetails}
                            />
                        </Grid>
                        <Grid
                            item
                            md={1.5}
                            xs={12}
                            style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}
                        >
                            <SubmitButton
                                loading={loading}
                                size="large"
                                variant="contained"
                                onClick={() => getFilterData()}
                                className='loadingButtonStyle2'
                            >
                                Go
                            </SubmitButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} pt={0} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} sm={12} md={12}>
                            <StickyTable>
                                <TableWrapper>
                                    <TableStyle>
                                        {tableLoader ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan={4}>
                                                        <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <>
                                                {
                                                    incomeCategoryNames?.length === 0 && expenseCategoryNames?.length === 0 && (
                                                        <tbody>
                                                            <tr>
                                                                <NoDataTd colSpan={4}>
                                                                    {t('executive-summary.no-data-found')}
                                                                </NoDataTd>
                                                            </tr>
                                                        </tbody>
                                                    )
                                                }
                                                {incomeCategoryNames?.length > 0 && (
                                                    <>
                                                        <thead>
                                                            <tr>
                                                                <StickyFirstTh>
                                                                    {t('income-expense-summary.income-category')}
                                                                </StickyFirstTh>
                                                                {monthColumn.map((item, indx) => (
                                                                    <th style={{ textAlign: 'end' }} key={indx}>
                                                                        {getMonthNameYear(item)}
                                                                    </th>
                                                                ))}
                                                                <StickySecondTh
                                                                    style={{ right: '316px' }}
                                                                    className="highlighted-th"
                                                                >
                                                                    {t('income-expense-summary.actual')}
                                                                </StickySecondTh>
                                                                <StickyThirdTh
                                                                    style={{ right: '176px' }}
                                                                    className="highlighted-th"
                                                                >
                                                                    {t('income-expense-summary.budget')}
                                                                </StickyThirdTh>
                                                                <StickyFourthTh
                                                                    style={{ right: '0px', left: 0 }}
                                                                    className="highlighted-th"
                                                                >
                                                                    {t('income-expense-summary.better')}
                                                                </StickyFourthTh>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {incomeCategoryNames.map((item, index) => (
                                                                <>
                                                                    <tr key={index}>
                                                                        <StickyFirstTd>{item}</StickyFirstTd>
                                                                        {incomeTableRows[item].map((item, indx) => (
                                                                            <ReportAmountTd key={indx}>
                                                                                <TotalAmountDiv>
                                                                                    {currencyCode}{' '}
                                                                                    <span>
                                                                                        {getPositiveNumber(item)}
                                                                                    </span>
                                                                                </TotalAmountDiv>
                                                                            </ReportAmountTd>
                                                                        ))}
                                                                        <StickySecondTd
                                                                            style={{ right: '316px' }}
                                                                            className="highlighted-td"
                                                                        >
                                                                            <TotalAmountDiv
                                                                                style={{ width: 120, float: 'right' }}
                                                                            >
                                                                                {currencyCode}{' '}
                                                                                <span>
                                                                                    {getPositiveNumber(
                                                                                        getTotalSumofData(
                                                                                            incomeTableRows,
                                                                                            item
                                                                                        )
                                                                                    )}
                                                                                </span>
                                                                            </TotalAmountDiv>
                                                                        </StickySecondTd>
                                                                        <StickyThirdTd
                                                                            style={{ right: '176px' }}
                                                                            className="highlighted-td"
                                                                        >
                                                                            <TotalAmountDiv
                                                                                style={{ width: 120, float: 'right' }}
                                                                            >
                                                                                {currencyCode}{' '}
                                                                                <span>
                                                                                    {getPositiveNumber(
                                                                                        incomeBudgetList[item]
                                                                                    )}
                                                                                </span>
                                                                            </TotalAmountDiv>
                                                                        </StickyThirdTd>
                                                                        <StickyFourthTd
                                                                            style={{ right: '0px', left: 0 }}
                                                                            className="highlighted-td"
                                                                        >
                                                                            <TotalAmountDiv
                                                                                style={{ width: 120, float: 'right' }}
                                                                            >
                                                                                {currencyCode}{' '}
                                                                                <span>
                                                                                    {
                                                                                        getIncomeBudgetSummary(
                                                                                            incomeTableRows,
                                                                                            incomeBudgetList,
                                                                                            item
                                                                                        )
                                                                                    }
                                                                                </span>
                                                                            </TotalAmountDiv>
                                                                        </StickyFourthTd>
                                                                    </tr>
                                                                </>
                                                            ))}
                                                            <tr>
                                                                <StickyFirstTd
                                                                    style={{
                                                                        height: '51px',
                                                                        fontSize: '16px',
                                                                        fontWeight: '600',
                                                                    }}
                                                                >
                                                                    {t('income-expense-summary.total-income')}
                                                                </StickyFirstTd>
                                                                {incomeMonthlyTotal.map((item, index) => (
                                                                    <SpanTotalFlow key={index}>
                                                                        <TotalAmountDiv>
                                                                            {currencyCode}{' '}
                                                                            <span>{getPositiveNumber(item)}</span>
                                                                        </TotalAmountDiv>
                                                                    </SpanTotalFlow>
                                                                ))}
                                                                <StickySecondTd
                                                                    style={{ right: '316px' }}
                                                                    className="highlighted-td"
                                                                >
                                                                    <AmountDiv>
                                                                        {currencyCode}
                                                                        <span>
                                                                            {getPositiveNumber(totalActualIncome)}
                                                                        </span>
                                                                    </AmountDiv>
                                                                </StickySecondTd>
                                                                <StickyThirdTd
                                                                    style={{ right: '176px' }}
                                                                    className="highlighted-td"
                                                                >
                                                                    <AmountDiv>
                                                                        {currencyCode}
                                                                        <span>
                                                                            {getPositiveNumber(totalIncomeBudget)}
                                                                        </span>
                                                                    </AmountDiv>
                                                                </StickyThirdTd>
                                                                <StickyFourthTd
                                                                    style={{ right: '0px', left: 0 }}
                                                                    className="highlighted-td"
                                                                >
                                                                    <AmountDiv>
                                                                        {currencyCode}
                                                                        <span>
                                                                            {getPositiveNumber(
                                                                                totalActualIncome - totalIncomeBudget
                                                                            )}
                                                                        </span>
                                                                    </AmountDiv>
                                                                </StickyFourthTd>
                                                            </tr>
                                                            <tr>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                        </tbody>
                                                    </>
                                                )}
                                                {expenseCategoryNames?.length > 0 && (
                                                    <>
                                                        <thead>
                                                            <tr>
                                                                <StickyFirstTh>
                                                                    {t('income-expense-summary.expense-category')}
                                                                </StickyFirstTh>
                                                                {monthColumn.map((item, indx) => (
                                                                    <th style={{ textAlign: 'end' }} key={indx}>
                                                                        {getMonthNameYear(item)}
                                                                    </th>
                                                                ))}
                                                                <StickySecondTh
                                                                    style={{ right: '316px' }}
                                                                    className="highlighted-th"
                                                                >
                                                                    {t('income-expense-summary.actual')}
                                                                </StickySecondTh>
                                                                <StickyThirdTh
                                                                    style={{ right: '176px' }}
                                                                    className="highlighted-th"
                                                                >
                                                                    {t('income-expense-summary.budget')}
                                                                </StickyThirdTh>
                                                                <StickyFourthTh
                                                                    style={{ right: '0px', left: 0 }}
                                                                    className="highlighted-th"
                                                                >
                                                                    {t('income-expense-summary.better')}
                                                                </StickyFourthTh>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {expenseCategoryNames.map((item, index) => (
                                                                <>
                                                                    <tr key={index}>
                                                                        <StickyFirstTd>{item}</StickyFirstTd>
                                                                        {expenseTableRows[item].map((item, indx) => (
                                                                            <ReportAmountTd key={indx}>
                                                                                <TotalAmountDiv>
                                                                                    {currencyCode}{' '}
                                                                                    <span>
                                                                                        {getNegativeNumber(item)}
                                                                                    </span>
                                                                                </TotalAmountDiv>
                                                                            </ReportAmountTd>
                                                                        ))}
                                                                        <StickySecondTd
                                                                            style={{ right: '316px' }}
                                                                            className="highlighted-td"
                                                                        >
                                                                            <TotalAmountDiv
                                                                                style={{ width: 120, float: 'right' }}
                                                                            >
                                                                                {currencyCode}{' '}
                                                                                <span>
                                                                                    {getNegativeNumber(
                                                                                        getTotalSumofData(
                                                                                            expenseTableRows,
                                                                                            item
                                                                                        )
                                                                                    )}
                                                                                </span>
                                                                            </TotalAmountDiv>
                                                                        </StickySecondTd>
                                                                        <StickyThirdTd
                                                                            style={{ right: '176px' }}
                                                                            className="highlighted-td"
                                                                        >
                                                                            <TotalAmountDiv
                                                                                style={{ width: 120, float: 'right' }}
                                                                            >
                                                                                {currencyCode}{' '}
                                                                                <span>
                                                                                    {getPositiveNumber(
                                                                                        expenseBudgetList[item]
                                                                                    )}
                                                                                </span>
                                                                            </TotalAmountDiv>
                                                                        </StickyThirdTd>
                                                                        <StickyFourthTd
                                                                            style={{ right: '0px', left: 0 }}
                                                                            className="highlighted-td"
                                                                        >
                                                                            <TotalAmountDiv
                                                                                style={{ width: 120, float: 'right' }}
                                                                            >
                                                                                {currencyCode}{' '}
                                                                                <span>
                                                                                    {
                                                                                        getExpenseBudgetSummary(
                                                                                            expenseTableRows,
                                                                                            expenseBudgetList,
                                                                                            item
                                                                                        )
                                                                                    }
                                                                                </span>
                                                                            </TotalAmountDiv>
                                                                        </StickyFourthTd>
                                                                    </tr>
                                                                </>
                                                            ))}
                                                            <tr>
                                                                <StickyFirstTd
                                                                    style={{
                                                                        height: '51px',
                                                                        fontSize: '16px',
                                                                        fontWeight: '600',
                                                                    }}
                                                                >
                                                                    {t('income-expense-summary.total-expense')}
                                                                </StickyFirstTd>
                                                                {expenseMonthlyTotal.map((item, index) => (
                                                                    <SpanTotalFlow key={index}>
                                                                        <TotalAmountDiv>
                                                                            {currencyCode}{' '}
                                                                            <span>{getNegativeNumber(item)}</span>
                                                                        </TotalAmountDiv>
                                                                    </SpanTotalFlow>
                                                                ))}
                                                                <StickySecondTd
                                                                    style={{ right: '316px' }}
                                                                    className="highlighted-td"
                                                                >
                                                                    <AmountDiv>
                                                                        {currencyCode}
                                                                        <span>
                                                                            {getNegativeNumber(totalActualExpense)}
                                                                        </span>
                                                                    </AmountDiv>
                                                                </StickySecondTd>
                                                                <StickyThirdTd
                                                                    style={{ right: '176px' }}
                                                                    className="highlighted-td"
                                                                >
                                                                    <AmountDiv>
                                                                        {currencyCode}
                                                                        <span>
                                                                            {getPositiveNumber(totalExpenseBudget)}
                                                                        </span>
                                                                    </AmountDiv>
                                                                </StickyThirdTd>
                                                                <StickyFourthTd
                                                                    style={{ right: '0px', left: 0 }}
                                                                    className="highlighted-td"
                                                                >
                                                                    <AmountDiv>
                                                                        {currencyCode}
                                                                        <span>
                                                                            {getPositiveNumber(
                                                                                totalExpenseBudget -
                                                                                Math.abs(totalActualExpense)
                                                                            )}
                                                                        </span>
                                                                    </AmountDiv>
                                                                </StickyFourthTd>
                                                            </tr>
                                                        </tbody>
                                                    </>
                                                )}
                                                {(incomeCategoryNames?.length > 0 && expenseCategoryNames?.length > 0) &&
                                                    <>
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                        <tr>
                                                            <StickyFirstTd
                                                                style={{
                                                                    height: '51px',
                                                                    fontSize: '16px',
                                                                    fontWeight: '600',
                                                                }}
                                                            >
                                                                {t('income-expense-summary.net-income')}
                                                            </StickyFirstTd>
                                                            {netIncomeMonthly.map((item, index) => (
                                                                <SpanTotalFlow key={index}>
                                                                    <TotalAmountDiv>
                                                                        {currencyCode}{' '}
                                                                        <span>{getPositiveNumber(item)}</span>
                                                                    </TotalAmountDiv>
                                                                </SpanTotalFlow>
                                                            ))}
                                                            <StickySecondTd
                                                                style={{ right: '316px' }}
                                                                className="highlighted-td"
                                                            >
                                                                <AmountDiv>
                                                                    {currencyCode}
                                                                    <span>
                                                                        {getPositiveNumber(
                                                                            totalActualIncome -
                                                                            Math.abs(totalActualExpense)
                                                                        )}
                                                                    </span>
                                                                </AmountDiv>
                                                            </StickySecondTd>
                                                            <StickyThirdTd
                                                                style={{ right: '176px' }}
                                                                className="highlighted-td"
                                                            >
                                                                <AmountDiv>
                                                                    {currencyCode}
                                                                    <span>
                                                                        {getPositiveNumber(
                                                                            totalIncomeBudget - totalExpenseBudget
                                                                        )}
                                                                    </span>
                                                                </AmountDiv>
                                                            </StickyThirdTd>
                                                            <StickyFourthTd
                                                                style={{ right: '0px', left: 0 }}
                                                                className="highlighted-td"
                                                            >
                                                                <AmountDiv>
                                                                    {currencyCode}
                                                                    <span>
                                                                        {getNetIncomeBetterLoss()}
                                                                    </span>
                                                                </AmountDiv>
                                                            </StickyFourthTd>
                                                        </tr>
                                                    </>
                                                }
                                            </>
                                        )}
                                    </TableStyle>
                                </TableWrapper>
                            </StickyTable>
                        </Grid>
                    </Grid>
                    <ButtonText>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            // disabled={}
                            onClick={() => getExcelSummary()}
                            className='loadingButtonStyle2'
                        >
                            Export
                        </LoadingButton>
                    </ButtonText>
                </ContentStyle>
            </Page>
        </>
    );
}
