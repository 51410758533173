const getCompareDetails =(t)=> ([
    {
        id: 1,
        title: t('mm-comparationTable.title-1'),
        MoolahMate: 1,
        Mint: 0,
        Empower: 0,
        YNAB: 0
    },
    {
        id: 2,
        title: t('mm-comparationTable.title-2'),
        MoolahMate: 1,
        Mint: 1,
        Empower: 1,
        YNAB: 1
    },
    {
        id: 3,
        title: t('mm-comparationTable.title-3'),
        MoolahMate: 1,
        Mint: 1,
        Empower: 0,
        YNAB: 1
    },
    {
        id: 4,
        title: t('mm-comparationTable.title-4'),
        MoolahMate: 1,
        Mint: 1,
        Empower: 0,
        YNAB: 1
    }
    , {
        id: 5,
        title: t('mm-comparationTable.title-5'),
        MoolahMate: 1,
        Mint: 0,
        Empower: 0,
        YNAB: 0
    },
    {
        id: 6,
        title: t('mm-comparationTable.title-6'),
        MoolahMate: 1,
        Mint: 0,
        Empower: 0,
        YNAB: 0
    },
    {
        id: 7,
        title: t('mm-comparationTable.title-7'),
        MoolahMate: 1,
        Mint: 0,
        Empower: 0,
        YNAB: 0
    },
    {
        id: 8,
        title: t('mm-comparationTable.title-8'),
        MoolahMate: 1,
        Mint: 0,
        Empower: 0,
        YNAB: 0
    },
    {
        id: 9,
        title: t('mm-comparationTable.title-9'),
        MoolahMate: 0,
        Mint: 1,
        Empower: 0,
        YNAB: 0
    },
    {
        id: 10,
        title: t('mm-comparationTable.title-10'),
        MoolahMate: 0,
        Mint: 1,
        Empower: 0,
        YNAB: 0
    },
    {
        id: 11,
        title: t('mm-comparationTable.title-11'),
        MoolahMate: 0,
        Mint: 1,
        Empower: 0,
        YNAB: 0
    },
    {
        id: 12,
        title: t('mm-comparationTable.title-12'),
        MoolahMate: 1,
        Mint: 0,
        Empower: 0,
        YNAB: 1
    }
]);

export{
    getCompareDetails
}