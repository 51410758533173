import { useEffect, useState } from 'react';
import { Grid, Link, Stack } from '@mui/material';
import Iconify from 'components/Iconify';
import clone from 'rfdc/default';
import { useTranslation } from 'react-i18next';
import Page from 'components/Page';
import { useNavigate } from 'react-router-dom';
import videoURL from 'constants/helpVideoUrl';
import TopMenu from '../../../components/TopMenu';
import TopHeading from '../../../components/TopHeading';
import {
    ContentStyle,
    CustomSlider,
    HeaderDivStyle,
    HeaderTableStyle,
    StyledButton,
    StyledInput,
    TitleBorderStyle,
} from './styled-components';
import financialGoal from '../../../constants/services/financial-goals';

const theadStyle = { display: 'table', width: '100%', tableLayout: 'fixed' };
const tbodyStyle = { display: 'block', maxHeight: 500, overflowY: 'auto' };
const tbodyRowStyle = { ...theadStyle, margin: '20px 0' };

export default function FinancialGoals() {
    const { t } = useTranslation();
    const [showAdd, setShowAdd] = useState(false);
    const [financialGoalObj, setFinancialGoalObj] = useState({ goal: '', achievement: 0 });
    const financialGoalErrObj = {
        saveType: '',
        index: 0,
        goal: '',
    };
    const [financialGoalError, setFinancialGoalError] = useState(financialGoalErrObj);
    const [financialGoals, setFinancialGoals] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    const handleFinancialGoalChange = (e) => {
        setFinancialGoalObj({ ...financialGoalObj, [e.target.name]: e.target.value });
    };

    const showAddButton = async () => {
        setShowAdd(true);
    };

    const onDeleteGoal = async (type, objInfo = {}) => {
        if (type === 'new') {
            setFinancialGoalObj({ goal: '', achievement: '' });
            setShowAdd(false);
        } else if (type === 'update') {
            await financialGoal.deleteFinancialGoal(objInfo.id);
            setPage(1);
            fetchFinancialGoals(1);
        }
    };

    const fetchFinancialGoals = async (pageNo, pageLimit = 10) => {
        const response = await financialGoal.listFinancialGoals(`?page=${pageNo}&limit=${pageLimit}`);
        setCount(response?.count);
        setFinancialGoals(pageNo === 1 ? response?.rows || [] : [...financialGoals, ...response.rows]);
    };

    const saveFinancialGoal = async () => {
        try {
            const payload = {
                goal: financialGoalObj.goal.trim(),
                achievement: financialGoalObj.achievement,
            };
            await financialGoal.addFinancialGoal(payload);
            setFinancialGoalObj({ goal: '', achievement: 0 });
            setShowAdd(false);
            setPage(1);
            fetchFinancialGoals(1);
        } catch (error) {
            setShowAdd(true);
        }
    };

    const updateFinancialGoal = async (item, newValue) => {
        try {
            const payload = {
                goal: item.goal.trim(),
                achievement: newValue,
            };
            await financialGoal.updateFinancialGoal(item.id, payload);
            setFinancialGoalObj({ goal: '', achievement: 0 });
            setShowAdd(false);
            setPage(1);
            fetchFinancialGoals(1);
        } catch (error) {
            setShowAdd(true);
        }
    };

    const onSubmitFinancialGoal = async (event) => {
        if (event.key !== 'Enter') return;
        const goalError = financialGoalObj.goal ? '' : 'Goal is required!';
        setFinancialGoalError({
            ...financialGoalErrObj,
            goal: goalError,
        });
        if (goalError) {
            return;
        }

        saveFinancialGoal();
    };

    const loadMoreFinancialGoals = () => {
        setPage(page + 1);
        fetchFinancialGoals(page + 1);
    };

    useEffect(() => {
        fetchFinancialGoals(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Page title={t('financial-goals.title')}>
            <TopMenu title="General Reports" videoLink={videoURL.financialGoals} />
            <TopHeading heading="Financial Goals" />
            <ContentStyle>
                <Grid container pt={0} justifyContent="center" alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={12}>
                        <HeaderDivStyle>
                            <div className='tableOverflowStyle'>
                                <HeaderTableStyle>
                                    <thead style={theadStyle}>
                                        <tr height="8px">
                                            <th>{''}</th>
                                            <th colSpan={4}>{t('financial-goals.goal')}</th>
                                            <th colSpan={4}>{t('financial-goals.achievement')}</th>
                                            <th>{''}</th>
                                        </tr>
                                    </thead>
                                    <tbody style={tbodyStyle}>
                                        <tr height="10px" style={theadStyle}>
                                            <TitleBorderStyle>{''}</TitleBorderStyle>
                                        </tr>
                                        <tr height="10px" style={theadStyle}>
                                            <td>{''}</td>
                                            <td colSpan={4}>
                                                <StyledButton onClick={showAddButton}>
                                                    {t('financial-goals.add-financial-goal')}
                                                </StyledButton>
                                            </td>
                                            <td colSpan={4}>{''}</td>
                                            <td>{''}</td>
                                        </tr>
                                        {showAdd && (
                                            <tr height="8px" style={tbodyRowStyle}>
                                                <td>{''}</td>
                                                <td colSpan={4}>
                                                    <StyledInput
                                                        type="text"
                                                        name="goal"
                                                        autoComplete="off"
                                                        autoFocus
                                                        placeholder={t('financial-goals.goal')}
                                                        value={financialGoalObj.goal}
                                                        onChange={handleFinancialGoalChange}
                                                        onKeyPress={(event) => {
                                                            onSubmitFinancialGoal(event);
                                                        }}
                                                        error={financialGoalError.goal}
                                                    />
                                                    {financialGoalError.saveType === 'add' &&
                                                        financialGoalError.description && (
                                                        <p className="Mui-error">{financialGoalError.description}</p>
                                                    )}
                                                </td>
                                                <td colSpan={4}>
                                                    <Stack spacing={2} direction="row" alignItems="center">
                                                        <span>0</span>
                                                        <CustomSlider
                                                            aria-label="Achievement"
                                                            value={financialGoalObj.achievement}
                                                            onChange={(event, newValue) => {
                                                                setFinancialGoalObj({
                                                                    ...financialGoalObj,
                                                                    achievement: newValue,
                                                                });
                                                            }}
                                                            valueLabelDisplay="on"
                                                        />
                                                        <span>100%</span>
                                                    </Stack>
                                                </td>
                                                <td style={{width: '110px', textAlign:'end'}}>
                                                    <Iconify
                                                        icon={'material-symbols:save'}
                                                        width={24}
                                                        height={24}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => onSubmitFinancialGoal({ key: 'Enter' })}
                                                    />
                                                    <Iconify
                                                        icon={'ri:delete-bin-6-fill'}
                                                        width={24}
                                                        height={24}
                                                        style={{ marginLeft: 12, cursor: 'pointer' }}
                                                        onClick={() => onDeleteGoal('new')}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                        {financialGoals.map((item, index) => (
                                            <tr key={index} height="8px" style={tbodyRowStyle}>
                                                <td>{''}</td>
                                                <td colSpan={4} style={{ wordWrap: 'break-word', maxWidth: 250 }}>
                                                    <p>{item.goal}</p>
                                                </td>
                                                <td colSpan={4}>
                                                    <Stack spacing={2} direction="row" alignItems="center">
                                                        <span>0</span>
                                                        <CustomSlider
                                                            aria-label="Achievement"
                                                            value={item.achievement}
                                                            onChange={(event, newValue) => {
                                                                const clonedFG = clone(financialGoals);
                                                                const goal = clonedFG.find((g) => g.id === item.id);
                                                                if (goal) {
                                                                    goal.achievement = newValue;
                                                                }
                                                                setFinancialGoals(clonedFG);
                                                            }}
                                                            onChangeCommitted={(event, newValue) => {
                                                                updateFinancialGoal(item, newValue);
                                                            }}
                                                            valueLabelDisplay="on"
                                                        />
                                                        <span>100%</span>
                                                    </Stack>
                                                </td>
                                                <td style={{width: '110px', textAlign:'end'}}>
                                                    <Iconify
                                                        icon={'ri:delete-bin-6-fill'}
                                                        width={22}
                                                        height={22}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => onDeleteGoal('update', item)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                        <tr style={theadStyle}>
                                            <td colSpan={5} style={{ textAlign: 'center' }}>
                                                {financialGoals.length < count && (
                                                    <Link
                                                        style={{ width: '200px', padding: '9px 16px', cursor: 'pointer' }}
                                                        onClick={loadMoreFinancialGoals}
                                                    >
                                                        {t('favorite-websites.load-more')}
                                                    </Link>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </HeaderTableStyle>
                            </div>
                        </HeaderDivStyle>
                    </Grid>
                </Grid>
            </ContentStyle>
            {/* </Wrapper> */}
        </Page>
    );
}
