const INFO_POINTER = [
    {
        id: 1,
        title: 'security.point-1',
        text: 'whyLoveMoolahMate.text-1',
        textActions: true,
    },
    {
        id: 2,
        title: 'security.point-2',
    },
    {
        id: 3,
        title: 'security.point-3',
    },
    {
        id: 4,
        title: 'security.point-4',
    },
    {
        id: 5,
        title: 'security.point-5',
    },
    {
        id: 6,
        title: 'security.point-6',
    },
    {
        id: 7,
        title: 'security.point-7',
    },
];

export { INFO_POINTER };
