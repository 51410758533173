import Typography from '@mui/material/Typography';
import './styles.css'

export default function pageSubHeadingStyle(props) {
    return (
        <div className='subHeadingStyle'>
            {
                props.headLineOne &&
                <Typography variant="h6">{props.headLineOne}</Typography>
            }
            <Typography variant="h3">{props.headLineTwo}</Typography>
            {
                props.headLineThree &&
                <Typography variant="h5">{props.headLineThree}</Typography>
            }
        </div>
    );
}