const getFeaturePoints = (t) => ([
    {
        id: 1,
        text: t('about-feature.text-1'),
    },
    {
        id: 2,
        text: t('about-feature.text-2'),
    },
    {
        id: 3,
        text: t('about-feature.text-3'),
    },
    {
        id: 4,
        text: t('about-feature.text-4'),
    },
]);

export {
    getFeaturePoints
}
