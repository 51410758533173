import { useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import DialogAtom from 'components/dialog/Dialog';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'contexts/AuthProvider';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import useIdle from "../../hooks/useIdleTimeout";

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
    '& header + div + div': {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 90,
        },
    },
}));

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: 0,
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.up('lg')]: {
        paddingTop: 0,
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
    },
}));

export default function DashboardLayout() {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const { logout } = useContext(AuthContext);

    const handleIdle = () => {
        setOpenModal(true);
    }

    const { idleTimer } = useIdle({ onIdle: handleIdle, idleTime: 600 });

    const onDialogAction = (buttonKey) => {
        if (buttonKey === 'cancel') {
            logout();
            idleTimer.reset();
        } else {
            idleTimer.reset();
        }
        setOpenModal(false);
    };

    const getIdealTimerModal = () => <DialogAtom
        open
        maxWidth="sm"
        dialogTitle={t('idle-timer.title')}
        content={<>
            <p>{t('idle-timer.modal-text-1')}</p>
            <p>{t('idle-timer.modal-text-2')}</p>
        </>}
        onDialogAction={onDialogAction}
        saveButtonLabel={t('idle-timer.button-save')}
        cancelButtonLabel={t('idle-timer.button-cancel')}
    />;

    return (
        <RootStyle>
            <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
            <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
            <MainStyle>
                <Outlet />
            </MainStyle>
            {openModal && getIdealTimerModal()}
        </RootStyle>
    );
}
