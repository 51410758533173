import { Select, styled } from '@mui/material';

export const SelectFieldStyle = styled(Select)(({ theme, padding = '10px 12px', fontSize = 16 }) => ({
    width: '100% !important',
    backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
    borderRadius: 10,
    '& .MuiSelect-select': {
        padding,
        fontSize,
    },
    '& .MuiInputBase-input': {
        borderColor: '#f8f8f8',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#f8f8f8 !important',
    },
    '& .MuiInputBase-root': {
        backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
        borderRadius: 10,
        '+ .MuiFormHelperText-root': {
            marginLeft: 0,
        }
    },
    'img': {
        paddingRight: 10,
        position: 'absolute !important',
        right: '0 !important',
        pointerEvents: 'none !important',
        '&.flip-icon': {
            transform: 'scaleY(-1)'
        }
    }
}));
