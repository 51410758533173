import request from '../../utils/request';

const getCategories = () => request.get('/cms/forum-categories?populate[topics]=topics');
const getTopics = (categoryId) => request.get(`/cms/forum-topics?populate[0]=category,forum_replies&populate[forum_replies][fields][0]=updatedAt&filters[category][id][$eq]=${categoryId}&sort[0]=publishedAt:desc`);
const getTopicsByTag = (tag) => request.get(`/cms/forum-topics?populate[category]=category&filters[tags][$containsi][0]=${tag}&sort[0]=publishedAt:desc`);
const getTopic = (topicId) => request.get(`/cms/forum-topics?populate[0]=category,forum_replies&populate[forum_replies][populate][parent_reply][fields][0]=id&populate[forum_replies][populate][replies][fields][0]=id&filters[id][$eq]=${topicId}`);
const addReply = (payload) => request.post(`/cms/forum-replies`, payload);
const addReplyLike = (replyId, payload) => request.put(`/cms/forum-replies/${replyId}`, payload);
const getReplyLikes = (replyId) => request.get(`/cms/forum-replies?fields[0]=likes&filters[id][$eq]=${replyId}`);
const addTopicLike = (topicId, payload) => request.put(`/cms/forum-topics/${topicId}`, payload);
const getTopicLikes = (topicId) => request.get(`/cms/forum-topics?fields[0]=likes&filters[id][$eq]=${topicId}`);
const getCategoriesByTagAndContent = (tag) => request.get(`/cms/forum-categories?populate[categories]=category&filters[$or][0][title][$containsi]=${tag}&filters[$or][1][description][$containsi]=${tag}&sort[0]=publishedAt:desc`);
const getTopicsByTagAndContent = (tag) => request.get(`/cms/forum-topics?populate[topics]=topic&filters[$or][0][description][$containsi]=${tag}&sort[0]=publishedAt:desc`);
// const getTopicsByTagAndContent = (tag) => request.get(`/cms/forum-topics?populate[topics]=topic&filters[$or][0][tags][$containsi]=${tag}&filters[$or][1][description][$containsi]=${tag}&filters[$or][2][title][$containsi]=${tag}&sort[0]=publishedAt:desc`);
const getRepliesByTagAndContent = (tag) => request.get(`/cms/forum-replies?populate[replies]=reply&filters[$or][0][content][$containsi]=${tag}&sort[0]=publishedAt:desc`);
const addTopic = (payload) => request.post(`/cms/forum-topics`, payload);

export default {
    getCategories,
    getTopics,
    getTopicsByTag,
    getTopic,
    addReply,
    addReplyLike,
    getReplyLikes,
    addTopicLike,
    getTopicLikes,
    getCategoriesByTagAndContent,
    getTopicsByTagAndContent,
    getRepliesByTagAndContent,
    addTopic
};