import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import image from 'assets/image';
import { useState } from 'react';
import DialogAtom from 'components/dialog/Dialog';
import PageSubHeadingStyle from '../../../components/PageSubHeadingStyle';
import InfoProvider from './InfoProvider';
import { INFO_POINTER } from './constant';
import DataPrivacyModalContent from './data-privacy-model';

export default function WhyLoveMoolahMate() {
    const { t } = useTranslation();
    const infoPointer = INFO_POINTER;
    const [showDialog, setShowDialog] = useState(false);

    const onClickHandler = (rowId) => {
        if (rowId === 1) {
            setShowDialog(true);
        }
    };

    const onDialogAction = async (buttonKey) => {
        if (buttonKey === 'cancel') {
            setShowDialog(false);
        }
    };

    const getModalPopup = () => (
        <DialogAtom
            open
            dialogTitle={t('whyLoveMoolahMate.data-privacy-model-title')}
            content={
                <DataPrivacyModalContent />
            }
            onDialogAction={onDialogAction}
            hideSave
        />
    );

    return (
        <Grid className="sectionCommonPadding commonPadding">
            <Grid container spacing={2} justifyContent={'center'}>
                <Grid item md={6}>
                    <PageSubHeadingStyle
                        headLineOne={t('home-page.headline-2')}
                        headLineTwo={t('home-page.subtitle-2')}
                        headLineThree={t('home-page.subtitle-info-2')}
                    />
                    <Typography variant="h5" className="addingSubHeading">
                        {t('home-page.subtitle-info-25')}
                    </Typography>
                    <img
                        src={image.reasonsWhyToChooseMoolahAnimation}
                        alt="Header logo"
                        style={{ marginTop: '40px' }}
                    />
                </Grid>
                <Grid item md={6}>
                    <div className="moolahMateStyle">
                        {infoPointer.map((point, indx) => (
                            <div key={indx} className="otherAssetBorder">
                                <InfoProvider
                                    pointerLineOne={point.id}
                                    pointerLineTwo={point.title}
                                    pointerLineThree={point.text}
                                    textActions={point.textActions}
                                    customText={point.customText}
                                    onClickHandler={onClickHandler}
                                />
                            </div>
                        ))}
                    </div>
                </Grid>
            </Grid>
            {showDialog && getModalPopup()}
        </Grid>
    );
}
