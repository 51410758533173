export const sortingData = [
    {
        id: 1,
        title: '# of likes',
        orderType: 'DESC',
        orderBy: 'likes'
    },
    {
        id: 1,
        title: 'Date',
        orderType: 'DESC',
        orderBy: 'createdAt'
    },
    {
        id: 1,
        title: 'Suggester',
        orderType: 'ASC',
        orderBy: 'username'
    },
];