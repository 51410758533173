import { LoadingButton } from '@mui/lab';
import { Box, Grid, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MultiSelectPicker from 'components/select/MultiSelectPicker';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getCurrencySymbol } from 'utils/calCommonFunction';
import { FormProvider } from '../../../components/hook-form';
import calenderIcon from '../../downloadEdits/CalenderIcon';
import { AutoFieldStyle, CheckboxStyle, DateGrid, GridStyled, LabelStyle, Textfield } from './styled-components';

const preventMinus = (e) => {
    if (e.code === 'Minus') {
        e.preventDefault();
    }
};

const TRANSACTION_TYPE_FILTER = [
    { id: '', label: 'All' },
    { id: 'Expense', label: 'Expense' },
    { id: 'Income', label: 'Income' },
    { id: 'Transfers', label: 'Transfers' },
    { id: 'Uncategorized', label: 'Uncategorized' },
];

export default function Filter({
    filtersData,
    updateFilters,
    loading = false,
    fetchTransactions = () => {},
    filterCategoryOptions = [],
    filterSubCategoryOptions = [],
    exportTransactions= () => {},
}) {
    const methods = useForm({});
    const { t } = useTranslation();
    const currencySymbol = getCurrencySymbol();

    return (
        <Box>
            <FormProvider methods={methods}>
                <Grid container spacing={2}>
                    <GridStyled item lg={3} md={6} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item lg={4} md={6} xs={4}>
                                <LabelStyle shrink htmlFor="bootstrap-input">
                                    {t('common-translation.Category')}
                                </LabelStyle>
                            </Grid>
                            <Grid item lg={8} md={6} xs={8}>
                                <MultiSelectPicker
                                    options={filterCategoryOptions}
                                    selectedOption={filtersData?.category?.length ? filtersData.category : []}
                                    setValue={(val) => updateFilters('category', val)}
                                />
                            </Grid>
                            <Grid item lg={4} md={6} xs={4} className="gridPadding">
                                <LabelStyle shrink htmlFor="bootstrap-input" className="subCatStyle">
                                    {t('downloaded-transactions.Sub-Category')}
                                </LabelStyle>
                            </Grid>
                            <Grid item lg={8} md={6} xs={8} className="gridPadding">
                                <MultiSelectPicker
                                    options={filterSubCategoryOptions}
                                    selectedOption={filtersData?.subCategory?.length ? filtersData.subCategory : []}
                                    setValue={(val) => updateFilters('subCategory', val)}
                                />
                            </Grid>
                        </Grid>
                    </GridStyled>
                    <GridStyled item lg={4.25} md={6} xs={12}>
                        <Grid container spacing={2} className="smPaddingDiv">
                            <Grid item lg={3} md={4} xs={4}>
                                <LabelStyle shrink htmlFor="date-from">
                                    {t('downloaded-transactions.Date-Range')}
                                </LabelStyle>
                            </Grid>
                            <DateGrid item lg={4.5} md={4} xs={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        PaperProps={{ className: 'customDateSelector' }}
                                        value={filtersData.dateFrom || null}
                                        components={{
                                            OpenPickerIcon: calenderIcon,
                                        }}
                                        inputFormat="MMM dd, yyyy"
                                        maxDate={filtersData.dateTo}
                                        onChange={(newValue) => {
                                            updateFilters('dateFrom', newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: 'From',
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </DateGrid>

                            <DateGrid item lg={4.5} md={4} xs={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        PaperProps={{ className: 'customDateSelector' }}
                                        value={filtersData.dateTo || null}
                                        components={{
                                            OpenPickerIcon: calenderIcon,
                                        }}
                                        inputFormat="MMM dd, yyyy"
                                        minDate={filtersData.dateFrom || null}
                                        onChange={(newValue) => {
                                            updateFilters('dateTo', newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: 'To',
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </DateGrid>

                            <Grid item lg={3} md={4} xs={4} className="gridPadding">
                                <LabelStyle shrink htmlFor="amount-from">
                                    {t('downloaded-transactions.Amount')}
                                </LabelStyle>
                            </Grid>
                            <Grid item lg={4.5} md={4} xs={4} className="gridPadding">
                                <Textfield
                                    placeholder="From"
                                    type="number"
                                    value={filtersData.amountFrom}
                                    name="amount-from"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {currencySymbol === 'CAD' ? '$' : currencySymbol}
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (value < 0) return;
                                        updateFilters('amountFrom', value);
                                    }}
                                    onKeyPress={preventMinus}
                                />
                            </Grid>
                            <Grid item lg={4.5} md={4} xs={4} className="gridPadding">
                                <Textfield
                                    placeholder="To"
                                    type="number"
                                    value={filtersData.amountTo}
                                    name="amount-to"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {currencySymbol === 'CAD' ? '$' : currencySymbol}
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (value < 0) return;
                                        updateFilters('amountTo', value);
                                    }}
                                    onKeyPress={preventMinus}
                                />
                            </Grid>
                        </Grid>
                    </GridStyled>
                    <GridStyled item lg={3} md={6} xs={12}>
                        <Grid container spacing={2} className="lastSubDiv">
                            <Grid item lg={5} md={6} xs={4}>
                                <LabelStyle shrink htmlFor="payee">
                                    {t('downloaded-transactions.Search')}
                                </LabelStyle>
                            </Grid>
                            <Grid item lg={7} md={6} xs={8}>
                                <Textfield
                                    value={filtersData.payee}
                                    name="payee"
                                    // style={{ minWidth: 200 }}
                                    placeholder="Payee/Category/Notes​"
                                    onChange={(e) => updateFilters('payee', e.target.value)}
                                />
                            </Grid>
                            <Grid item lg={5} md={6} xs={4} className="gridPadding">
                                <LabelStyle shrink htmlFor="bootstrap-input">
                                    {t('downloaded-transactions.Transaction-Type')}
                                </LabelStyle>
                            </Grid>
                            <Grid item lg={7} md={6} xs={8} className="gridPadding">
                                <AutoFieldStyle
                                    options={TRANSACTION_TYPE_FILTER}
                                    id="transaction-type"
                                    value={filtersData.transactionType}
                                    onChange={(e, val) => {
                                        updateFilters('transactionType', val);
                                    }}
                                    style={{ paddingTop: 5 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder="Select"
                                            className="dropMenu"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </GridStyled>
                    <GridStyled item lg={1.75} md={6} xs={12}>
                        <Grid container className="lastSubDiv">
                            <Grid item lg={7} md={4} xs={4}>
                                <LabelStyle shrink htmlFor="payee">
                                    {t('downloaded-transactions.Hide-Reviewed')}
                                </LabelStyle>
                            </Grid>
                            <Grid item lg={5} md={5} xs={8}>
                                <CheckboxStyle
                                    checked={filtersData.isReviewed}
                                    onChange={() => {
                                        updateFilters('isReviewed', !filtersData.isReviewed);
                                    }}
                                />
                            </Grid>
                            <Grid item md={12} className="gridPadding filterBtnStyle">
                                <LoadingButton
                                    loading={loading}
                                    size="large"
                                    onClick={() => fetchTransactions()}
                                    variant="contained"
                                    className="loadingButtonStyle2"
                                >
                                    {t('downloaded-transactions.Go')}
                                </LoadingButton>
                                <LoadingButton
                                    loading={loading}
                                    size="large"
                                    onClick={() => exportTransactions()}
                                    variant="contained"
                                    className="loadingButtonStyle2"
                                    style={{ marginLeft: 10 }}
                                >
                                    {t('downloaded-transactions.export')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </GridStyled>
                </Grid>
            </FormProvider>
        </Box>
    );
}
