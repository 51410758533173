import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import image from 'assets/image';
import { getProductDetails } from './constant'
import ProductMapItem from '../../../components/ProductMapItem'

export default function MateMoolahMate() {
    const { t } = useTranslation();
    const productDetails = getProductDetails(t);

    return (
        <Grid className='sectionRightPadding' style={{ paddingLeft: '40px' }} pt={6}>
            <Grid container spacing={2} className='mdPaddingBottom' justifyContent={'center'}>
                <Grid item md={6}>
                    <img src={image.learnHubAnimation} alt="Header logo" className='imageMarginStyle imgMarginAuto' style={{ width: 470 }}/>
                </Grid>
                <Grid item md={6} className='moolaMateStyle' sx={{ mt: -2 }}>
                    <Typography variant='body1' className='moolahMateText'>{t('home-page.subtitle-info-5')}</Typography>
                    <Typography variant="body1" className='moolahMateText'>{t('home-page.moolahMateText')}</Typography>
                    <Grid container spacing={2} pt={3} className='mobileGridView'>
                        {
                            productDetails.map((products, indx) => (
                                <Grid item lg={4} md={6} xs={6} key={indx} className="mobileDivView">
                                    <ProductMapItem
                                        imageUrl={products.icon}
                                        headLineOne={products.title}
                                        headLineThree={products.boldText}
                                        headLineFour={products.simpleText}
                                        keyIndx={products.id}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
