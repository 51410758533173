import { Button, Grid, List, Typography } from '@mui/material';
import videoURL from 'constants/helpVideoUrl';
import { NavLinkbtn } from 'pages/dashboard/AllReports/AllReports';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import TopHeading from '../../components/TopHeading';
import TopMenu from '../../components/TopMenu';
import transaction from '../../constants/services/transaction';

export default function ManualTransactions() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [manuallyUpdatedCategories, setManuallyUpdatedCategories] = useState([]);
    // const [filteredManualCategories, setFilteredManualCategories] = useState([]);

    const [searchValue, setSearchValue] = useState('');
    const [viewStyle, setViewStyle] = useState('grid');
    const searchRef = useRef();

    const fetchSubCategory = async () => {
        const subCategoriesData = await transaction.getManaulTransaction();
        const manualCats = subCategoriesData?.data
            ?.filter((cat) => cat?.isUpdatedManually)
            ?.sort((a, b) => a.name.localeCompare(b.name));
        setManuallyUpdatedCategories(manualCats || []);
        // setFilteredManualCategories(manualCats || []);
    };

    useEffect(() => {
        fetchSubCategory();
    }, []);

    const onView = (categ) => {
        navigate(categ?.template ? '/edit-manual-transactions' : '/view-manual-transactions', { state: categ });
    };

    const getReportGridView = (record) =>
        record.map((items, indx) => (
            <Grid key={indx} item xs={12} sm={6} md={3} alignItems={'center'} justifyContent={'center'}>
                {/* eslint-disable-next-line */}
                <div className="hubCardStyle allReportStyle" onClick={() => onView(items)}>
                    <NavLinkbtn>
                        <Typography variant="body1" className="descriptionHeading" mt={0}>
                            {items.name}
                        </Typography>
                    </NavLinkbtn>
                </div>
            </Grid>
        ));

    const getDashboardListView = (record) =>
        record.map((items, indx) => (
            <List key={indx} className="hubListViewStyle">
                <div className="hubCardStyle allReportStyle">
                    <NavLinkbtn onClick={() => onView(items)}>
                        <Typography variant="body1" className="descriptionHeading" mt={0}>
                            {items.name}
                        </Typography>
                    </NavLinkbtn>
                </div>
            </List>
        ));

    const noDataFound = () => (
        <Typography variant="body1" className="noDataFound">
            No Data Found
        </Typography>
    );

    const getFilterData = (records) =>
        records?.filter((items) => items.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));

    return (
        <Page title={t('manual-transactions.title')}>
            <TopMenu title={t('manual-transactions.title')} videoLink={videoURL.otherTransactions} />
            <TopHeading
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                heading={t('manual-transactions.description')}
                searchRef={searchRef}
                viewStyle={viewStyle}
                setViewStyle={setViewStyle}
                showSearch
                hideBackLink
            />
            <div className="hubPadding hubCommonMargin">
                <Grid container spacing={2} mt={0} alignItems="flex-start">
                    {viewStyle === 'grid' ? (
                        getReportGridView(getFilterData(manuallyUpdatedCategories))
                    ) : (
                        <div className="listViewStyle">
                            {getDashboardListView(getFilterData(manuallyUpdatedCategories))}
                        </div>
                    )}
                    {getFilterData(manuallyUpdatedCategories).length ? '' : noDataFound()}
                </Grid>
            </div>
            <div className="hubPadding hubCommonMargin mdFlexDisplay" style={{ marginTop: '3%' }}>
                <Button
                    variant="contained"
                    className="loadingButtonStyle2"
                    style={{ marginTop: 10, textTransform: 'none', paddingInline: '4%', borderRadius: 2 }}
                    onClick={() => navigate('/my-profile/category')}
                >
                    {t('manual-transactions.new-category')}
                </Button>
                <Button
                    variant="contained"
                    style={{
                        marginTop: 10,
                        marginLeft: 30,
                        textTransform: 'none',
                        paddingInline: '4%',
                        borderRadius: 2,
                    }}
                    className="loadingButtonStyle2"
                    onClick={() => navigate('/my-profile/sub-category')}
                >
                    {t('manual-transactions.new-sub-category')}
                </Button>
                <Button
                    variant="contained"
                    style={{
                        marginTop: 10,
                        marginLeft: 30,
                        textTransform: 'none',
                        paddingInline: '4%',
                        borderRadius: 2,
                    }}
                    className="loadingButtonStyle2"
                    onClick={() => navigate('/view-all-templates', { state: { readOnly: true } })}
                >
                    {t('manual-transactions.view-all-templates')}
                </Button>
            </div>
            <div className="hubPadding">
                <Grid
                    container
                    spacing={4}
                    pt={8}
                    pb={5}
                    width={'100%'}
                    style={{ marginLeft: '0px', display: 'block' }}
                >
                    <Typography variant="h4" className="commonHeading">
                        {t('view-manual-transactions.instructions')}
                    </Typography>
                    <ul style={{ paddingLeft: 30 }}>
                        <li style={{ padding: 10, paddingLeft: 0 }}>
                            These are all the sub-categories that you selected to be tracked manually
                        </li>
                        <li style={{ padding: 10, paddingLeft: 0 }}>Select a sub-category</li>
                        <li style={{ padding: 10, paddingLeft: 0 }}>Preview templates and select an appropriate one</li>
                        <li style={{ padding: 10, paddingLeft: 0 }}>
                            Enter your information in the template selected and save{' '}
                        </li>
                    </ul>
                </Grid>
            </div>
            {/* </Wrapper> */}
        </Page>
    );
}
