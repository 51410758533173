import { Grid, Button, Typography, Chip } from '@mui/material';
import moment from 'moment';
import Page from 'components/Page';
import TopMenu from 'components/TopMenu';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useState } from 'react';
import user from 'constants/services/user';
import CancelButton from './CancelButton';

export default function Subscription() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [subscriptionsList, setSubscriptionsList] = useState([]);

    const getUserSubscriptionDetails = async () => {
        const response = await user.getSubscriptions();
        const subscriptions = response.data ?? [];
        setSubscriptionsList(subscriptions);
    };

    useEffect(() => {
        getUserSubscriptionDetails();
    }, []);

    return (
        <Page title={t('subscription.title')}>
            <TopMenu title={t('subscription.title')} />
            <div className="contentStyle" style={{ paddingTop: '0px' }}>
                <Grid container spacing={2} marginLeft={0} width={'100%'} marginTop={0}>
                    <Grid item xs={12} style={{ padding: '0px' }}>
                        <Button
                            className="backToMenuStyle"
                            style={{ marginBottom: '32px' }}
                            onClick={() => navigate(-1)}
                        >
                            <ArrowBackIcon /> Back
                        </Button>
                    </Grid>
                    <Typography variant="h4" className="commonHeading" style={{ paddingBottom: '22px' }}>
                        {t('subscription.existing-subscription')}
                    </Typography>
                    <Grid container spacing={2}>
                        {subscriptionsList.map((subscription) => {
                            const {
                                id,
                                validFrom,
                                validUntil,
                                name,
                                subscriptionStatus,
                                subscriptionType,
                            } = subscription;
                            const formattedDate = moment.parseZone(validUntil).format('MMM DD, YYYY');
                            let message = `${t('subscription.valid-until')}: ${formattedDate}`;
                            if (subscriptionType !== 'FREE_TRIAL') {
                                if (subscriptionStatus === 'ACTIVE_CANCELLED') {
                                    message = `${t('subscription.valid-until')}: ${formattedDate}`;
                                } else if (moment.parseZone(validFrom) > moment.parseZone()) {
                                    message = `${t('subscription.starts-on')}: ${moment.parseZone(validFrom).format('MMM DD, YYYY')}`;
                                } else {
                                    message = `${t('subscription.started-on')}: ${moment.parseZone(validFrom).format('MMM DD, YYYY')}`;
                                }
                            }
                            return (
                                <Grid item xl={3} lg={4} md={6} sm={12} key={id}>
                                    <div className="subscription-info-card" style={{ maxHeight: 300, minHeight: 300 }}>
                                        <div className="subscription-details">
                                            <Typography variant="body1">{name}</Typography>
                                            <div className="flexDisplay" style={{ justifyContent: 'space-between' }}>
                                                <Chip
                                                    sx={{
                                                        height: 'auto',
                                                        '& .MuiChip-label': {
                                                            display: 'block',
                                                            whiteSpace: 'normal',
                                                        },
                                                        padding: '2px',
                                                        fontWeight: '600',
                                                        width: 'fit-content',
                                                    }}
                                                    label={`${message}`}
                                                />
                                                <Button
                                                    variant="contained"
                                                    color={subscriptionStatus === 'ACTIVE' ? 'success' : 'error'}
                                                    style={{ pointerEvents: 'none' }}
                                                >
                                                    {t(`subscription.${subscriptionStatus}`)}
                                                </Button>
                                            </div>
                                            {subscriptionStatus === 'ACTIVE' && <Typography variant="body" sx={{ paddingTop: 2 }}>
                                                {t(`payment-info.${subscriptionType}`)}
                                            </Typography>}
                                        </div>

                                        {subscriptionStatus === 'ACTIVE' && subscriptionType !== 'FREE_TRIAL' && (
                                            <CancelButton id={id} getUserSubscriptionDetails={getUserSubscriptionDetails} />
                                        )}
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </div>
        </Page>
    );
}
