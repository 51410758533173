import image from 'assets/image';

export const clientReviewJson = [
    {
        id: 1,
        image: image.testimonial1,
        title: 'I used to head-up the Bank audit practise of a global audit firm, so I am quite familiar with the financial landscape.',
        text: "I have to say that I really like the dynamic reporting and the ability to customize income and expense categories so you only see information that is relevant to you. It's clear and informative and allows you to get better insight about your financial picture.",
        clientName: 'Jerry W (CA, CPA)',
        clientOccupation: 'Retired Partner - Global Audit Firm',
    },
    {
        id: 2,
        image: image.testimonial2,
        title: "I don't know how I managed our money matters before MoolahMate!",
        text: "It has been a life saver, with all our finances literally at my fingertips! Now I don't have to go through the hassle of keeping an eye on different accounts and expenses individually. It has also made it so much easier to understand where our money is going. Thanks, MoolahMate!",
        clientName: 'Yasmeen I',
        clientOccupation: 'Project Manager and Household CFO',
    },
    {
        id: 3,
        image: image.testimonial3,
        title: 'It is very user friendly.',
        text: 'The clean design, ease of use and quality of reporting are what appeal to me.',
        clientName: 'Jane B  (CA, CPA)',
        clientOccupation: 'Retired Senior Manager - Global Audit Firm',
    },
    {
        id: 4,
        image: image.testimonial1,
        title: 'What I love about MoolahMate is its simple design!',
        text: 'When you log in you can see everything relating to your finances in one spot on a slick dashboard. The simple design makes it evident that this app was designed by “real” people for “real” people. Everyone should give it a try.',
        clientName: 'Saman R',
        clientOccupation: 'Banker @ Major Canadian Bank',
    },
    {
        id: 5,
        image: image.testimonial2,
        title: '',
        text: "MoolahMate pulls in your finances and allows you to produce reports on spending and even export so you can further refine the data to whatever you need. It's never been easier to keep track of your budget! ",
        clientName: 'Kamran H',
        clientOccupation: 'Banker @ Major Canadian Bank',
    },
    {
        id: 6,
        image: image.testimonial3,
        title: 'I really love the Knowledge Base section.',
        text: "It's so simple and clear and now I've learnt so much more about financial matters and that's made me handle my finances a little better.",
        clientName: 'Michelle C',
        clientOccupation: 'Sales Manager',
    },
];
