/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Grid, Link, Typography } from '@mui/material';
import DialogAtom from 'components/dialog/Dialog';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import videoURL from 'constants/helpVideoUrl';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import TopHeading from '../../components/TopHeading';
import TopMenu from '../../components/TopMenu';
import websiteBlog from '../../constants/services/websites-blogs';
import {
    HeaderDivStyle,
    HeaderTableStyle,
    StyledButton,
    StyledInput,
} from './styled-components';

const theadStyle = { display: 'table', width: '100%', tableLayout: 'fixed', paddingBottom: '8px', borderBottom: '2px solid #89A69F' };
const tSubHeadStyle = { display: 'table', width: '100%', tableLayout: 'fixed' }
const tbodyStyle = { display: 'block', maxHeight: 400, overflowY: 'auto', paddingTop: "12px" };

export default function FavoriteWebsites() {
    const defaultPageLimit = 10;
    const { t } = useTranslation();
    const [websiteInputValue, setWebsiteInputValue] = useState({ description: '', url: '' });
    const [websiteTableData, setWebsiteTableData] = useState([]);
    const [websitePageNo, setWebsitePageNo] = useState(1);
    const [totalWebsite, setTotalWebsite] = useState(0);
    const [showAddWebsite, setShowAddWebsite] = useState(false);
    const websiteErrObj = {
        saveType: '',
        index: 0,
        description: '',
        url: '',
    };
    const [websiteError, setWebsiteError] = useState(websiteErrObj);
    const [showDialog, setShowDialog] = useState(false);
    const [navigationTimeout, setNavigationTimeout] = useState('');
    const [blogInputValue, setBlogInputValue] = useState({ description: '', url: '' });
    const [blogTableData, setBlogTableData] = useState([]);
    const [blogPageNo, setBlogPageNo] = useState(1);
    const [totalBlog, setTotalBlog] = useState(0);
    const [showAddBlog, setShowAddBlog] = useState(false);
    const blogErrObj = {
        saveType: '',
        index: 0,
        description: '',
        url: '',
    };
    const [blogError, setBlogError] = useState(blogErrObj);

    const fetchBlog = async (pageNo, pageLimit = defaultPageLimit) => {
        const response = await websiteBlog.websiteBlogList(`?favoriteType=BLOG&page=${pageNo}&limit=${pageLimit}`);
        setTotalBlog(response?.count);
        setBlogTableData(pageNo === 1 ? response?.rows || [] : [...blogTableData, ...response.rows]);
    };

    const fetchWebsite = async (pageNo, pageLimit = defaultPageLimit) => {
        const response = await websiteBlog.websiteBlogList(`?favoriteType=WEBSITE&page=${pageNo}&limit=${pageLimit}`);
        setTotalWebsite(response?.count);
        setWebsiteTableData(pageNo === 1 ? response?.rows || [] : [...websiteTableData, ...response.rows]);
    };

    useEffect(() => {
        fetchBlog(blogPageNo);
        fetchWebsite(websitePageNo);
    }, []);

    const handleWebsiteChange = (e) => {
        setWebsiteInputValue({ ...websiteInputValue, [e.target.name]: e.target.value });
    };

    const handleBlogChange = (e) => {
        setBlogInputValue({ ...blogInputValue, [e.target.name]: e.target.value });
    };

    const handleWebsiteEditChange = (index, e) => {
        const localTableData = [...websiteTableData];
        localTableData[index][e.target.name] = e.target.value;
        setWebsiteTableData(localTableData);
    };

    const handleBlogEditChange = (index, e) => {
        const localTableData = [...blogTableData];
        localTableData[index][e.target.name] = e.target.value;
        setBlogTableData(localTableData);
    };

    const showWebsiteAdd = () => {
        setShowAddWebsite(true);
    };

    const showBlogAdd = () => {
        setShowAddBlog(true);
    };

    const loadMoreWebsite = () => {
        setWebsitePageNo(websitePageNo + 1);
        fetchWebsite(websitePageNo + 1);
    };

    const loadMoreBlog = () => {
        setBlogPageNo(blogPageNo + 1);
        fetchBlog(blogPageNo + 1);
    };

    const handleAddBlog = async (favoriteType = 'BLOG') => {
        try {
            const payload = {
                favoriteType,
                description: blogInputValue.description.trim(),
                url: blogInputValue.url.trim(),
            };
            await websiteBlog.addWebsiteBlog(payload);
            setBlogInputValue({ description: '', url: '' });
            setShowAddBlog(false);
            setBlogPageNo(1);
            fetchBlog(1);
        } catch (error) {
            setShowAddBlog(true);
        }
    };

    const handleAddWebsite = async (favoriteType = 'WEBSITE') => {
        try {
            const payload = {
                favoriteType,
                description: websiteInputValue.description.trim(),
                url: websiteInputValue.url.trim(),
            };
            await websiteBlog.addWebsiteBlog(payload);
            setWebsiteInputValue({ description: '', url: '' });
            setShowAddWebsite(false);
            setWebsitePageNo(1);
            fetchWebsite(1);
        } catch (error) {
            setShowAddWebsite(true);
        }
    };

    const handleUpdateBlog = async (rowIndex) => {
        try {
            const payload = {
                description: blogTableData[rowIndex].description.trim(),
                url: blogTableData[rowIndex].url.trim(),
            };
            await websiteBlog.updateWebsiteBlog(blogTableData[rowIndex].id, { payload });
            setBlogInputValue({ description: '', url: '' });
            setShowAddBlog(false);
            setBlogPageNo(1);
            fetchBlog(1);
        } catch (error) {
            setShowAddBlog(true);
        }
    };

    const handleUpdateWebsite = async (rowIndex) => {
        try {
            const payload = {
                description: websiteTableData[rowIndex].description.trim(),
                url: websiteTableData[rowIndex].url.trim(),
            };
            await websiteBlog.updateWebsiteBlog(websiteTableData[rowIndex].id, { payload });
            setWebsiteInputValue({ description: '', url: '' });
            setShowAddWebsite(false);
            setWebsitePageNo(1);
            fetchWebsite(1);
        } catch (error) {
            setShowAddWebsite(true);
        }
    };

    const onSubmitBlog = (event, saveType = 'add', index = 0) => {
        if (event.key !== 'Enter') return;
        const descValue = saveType === 'add' ? blogInputValue.description : blogTableData[index].description;
        const urlValue = saveType === 'add' ? blogInputValue.url : blogTableData[index].url;
        const descError = descValue ? '' : 'Description is required !';
        const urlError = urlValue ? '' : 'URL is required !';
        setBlogError({
            ...blogError,
            index: saveType === 'add' ? 0 : index,
            saveType,
            description: descError,
            url: urlError,
        });
        if (descError || urlError) return;

        if (saveType === 'add') {
            handleAddBlog();
        } else {
            handleUpdateBlog(index);
        }
    };

    const onSubmitWebsite = (event, saveType = 'add', index = 0) => {
        if (event?.key !== 'Enter') return;
        const descValue = saveType === 'add' ? websiteInputValue.description : websiteTableData[index].description;
        const urlValue = saveType === 'add' ? websiteInputValue.url : websiteTableData[index].url;
        const descError = descValue ? '' : 'Description is required !';
        const urlError = urlValue ? '' : 'URL is required !';
        setWebsiteError({
            ...websiteError,
            index: saveType === 'add' ? 0 : index,
            saveType,
            description: descError,
            url: urlError,
        });
        if (descError || urlError) return;

        if (saveType === 'add') {
            handleAddWebsite();
        } else {
            handleUpdateWebsite(index);
        }
    };

    const onDeleteBlog = async (type, objInfo = {}) => {
        if (type === 'new') {
            setBlogInputValue({ description: '', url: '' });
            setShowAddBlog(false);
        } else if (type === 'update') {
            await websiteBlog.deleteWebsiteBlog(objInfo.id);
            setBlogPageNo(1);
            fetchBlog(1);
        }
    };

    const onDeleteWebsite = async (type, objInfo = {}) => {
        if (type === 'new') {
            setWebsiteInputValue({ description: '', url: '' });
            setShowAddWebsite(false);
        } else if (type === 'update') {
            await websiteBlog.deleteWebsiteBlog(objInfo.id);
            setWebsitePageNo(1);
            fetchWebsite(1);
        }
    };

    const onDialogAction = (buttonKey) => {
        if (buttonKey === 'cancel') {
            clearTimeout(navigationTimeout);
            setShowDialog(false);
        }
    };

    const navigateLink = (urlToRedirect) => {
        if (!urlToRedirect.match(/^http[s]?:\/\//)) {
            urlToRedirect = `http://${urlToRedirect}`;
        }
        window.open(urlToRedirect, '_blank');
        setShowDialog(false);
    };

    const getNavigationDialog = () => (
        <DialogAtom
            open
            maxWidth="sm"
            dialogTitle=""
            content={<>You're being redirected to a site outside MoolahMate</>}
            hideSave
            onDialogAction={onDialogAction}
        />
    );

    return (
        <Page title={t('favorite-websites.title')}>
            <TopMenu title={t('favorite-websites.heading')} videoLink={videoURL.favWebsiteBlogs} />
            <TopHeading heading={t('welcome-constant.description-12')} hideBackLink/>
            <div className='contentStyle' style={{paddingTop: '20px'}}>
                <Typography variant="h4" className="commonText">
                    {t('favorite-websites.text')}
                </Typography>

                <Grid container spacing={2} pt={2} marginTop={0} justifyContent="center" alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant='h5' className='commonHeading'>{t('favorite-websites.websites')}</Typography>
                        <HeaderDivStyle>
                            <HeaderTableStyle>
                                <thead style={theadStyle}>
                                    <tr height="8px">
                                        {/* <th>{''}</th> */}
                                        <th colSpan={3}>{t('favorite-websites.description')}</th>
                                        <th colSpan={3}>{t('favorite-websites.url')}</th>
                                        <th>{''}</th>
                                    </tr>
                                </thead>
                                <tbody style={tbodyStyle}>
                                    <>
                                        <tr height="8px" style={tSubHeadStyle}>
                                            {/* <td>{''}</td> */}
                                            <td colSpan={3}>
                                                <StyledButton onClick={showWebsiteAdd}>
                                                    {t('favorite-websites.add-new')}
                                                </StyledButton>
                                            </td>
                                            <td colSpan={3}>{''}</td>
                                            <td>{''}</td>
                                        </tr>
                                        {showAddWebsite && (
                                            <tr height="8px" style={tSubHeadStyle}>
                                                {/* <td>{''}</td> */}
                                                <td colSpan={3}>
                                                    <StyledInput
                                                        type="text"
                                                        name="description"
                                                        autoComplete="off"
                                                        placeholder={t('favorite-websites.description')}
                                                        value={websiteInputValue.description}
                                                        onChange={handleWebsiteChange}
                                                        onKeyPress={(event) => {
                                                            onSubmitWebsite(event);
                                                        }}
                                                        error={websiteError.description}
                                                    />
                                                    {websiteError.saveType === 'add' && websiteError.description && (
                                                        <p className="Mui-error">{websiteError.description}</p>
                                                    )}
                                                </td>
                                                <td colSpan={3}>
                                                    <StyledInput
                                                        type="text"
                                                        name="url"
                                                        autoComplete="off"
                                                        placeholder={t('favorite-websites.url')}
                                                        value={websiteInputValue.url}
                                                        onChange={handleWebsiteChange}
                                                        onKeyPress={(event) => {
                                                            onSubmitWebsite(event);
                                                        }}
                                                        error={websiteError.url}
                                                    />
                                                    {websiteError.saveType === 'add' && websiteError.url && (
                                                        <p className="Mui-error">{websiteError.url}</p>
                                                    )}
                                                </td>
                                                <td>
                                                    <Iconify
                                                        icon={'material-symbols:save'}
                                                        width={24}
                                                        height={24}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => onSubmitWebsite({ key: 'Enter' })}
                                                    />
                                                    <Iconify
                                                        icon={'ri:delete-bin-6-fill'}
                                                        width={24}
                                                        height={24}
                                                        style={{ marginLeft: 10, cursor: 'pointer' }}
                                                        onClick={() => onDeleteWebsite('new')}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                        {websiteTableData.map((item, index) => (
                                            <tr key={index} height="8px" style={tSubHeadStyle}>
                                                {/* <td>{''}</td> */}
                                                <td colSpan={3} style={{ wordWrap: 'break-word', maxWidth: 250 }}>
                                                    <p>{item.description}</p>
                                                </td>
                                                <td
                                                    colSpan={3}
                                                    onClick={() => {
                                                        setShowDialog(true);
                                                        setNavigationTimeout(
                                                            setTimeout(() => {
                                                                navigateLink(item.url);
                                                            }, 3000)
                                                        );
                                                    }}
                                                    style={{
                                                        wordWrap: 'break-word',
                                                        maxWidth: 250,
                                                        color: '#048B67',
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <p>{item.url}</p>
                                                </td>
                                                <td className='svgMobileIcon'>
                                                    <Iconify
                                                        icon={'ri:delete-bin-6-fill'}
                                                        width={22}
                                                        height={22}
                                                        style={{ marginLeft: 32, cursor: 'pointer' }}
                                                        onClick={() => onDeleteWebsite('update', item)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {websiteTableData.length < totalWebsite && (
                                                    <Link
                                                        style={{
                                                            width: '200px',
                                                            padding: '9px 16px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={loadMoreWebsite}
                                                    >
                                                        {t('favorite-websites.load-more')}
                                                    </Link>
                                                )}
                                            </td>
                                        </tr>
                                    </>
                                </tbody>
                            </HeaderTableStyle>
                        </HeaderDivStyle>
                        <div style={{ marginTop: 32 }}>
                            <Typography variant='h5' className='commonHeading'>{t('favorite-websites.blogs')}</Typography>
                            <HeaderDivStyle>
                                <HeaderTableStyle>
                                    <thead style={theadStyle}>
                                        <tr height="8px">
                                            {/* <th>{''}</th> */}
                                            <th colSpan={3}>{t('favorite-websites.description')}</th>
                                            <th colSpan={3}>{t('favorite-websites.url')}</th>
                                            <th>{''}</th>
                                        </tr>
                                    </thead>
                                    <tbody style={tbodyStyle}>
                                        <>
                                            <tr height="8px" style={tSubHeadStyle}>
                                                {/* <td>{''}</td> */}
                                                <td colSpan={3}>
                                                    <StyledButton onClick={showBlogAdd}>
                                                        {t('favorite-websites.add-new')}
                                                    </StyledButton>
                                                </td>
                                                <td colSpan={3}>{''}</td>
                                                <td>{''}</td>
                                            </tr>
                                            {showAddBlog && (
                                                <tr height="8px" style={tSubHeadStyle}>
                                                    {/* <td>{''}</td> */}
                                                    <td colSpan={3}>
                                                        <StyledInput
                                                            type="text"
                                                            name="description"
                                                            autoComplete="off"
                                                            placeholder={t('favorite-websites.description')}
                                                            value={blogInputValue.description}
                                                            onChange={handleBlogChange}
                                                            onKeyPress={(event) => {
                                                                onSubmitBlog(event);
                                                            }}
                                                            error={blogError.description}
                                                        />
                                                        {blogError.saveType === 'add' && blogError.description && (
                                                            <p className="Mui-error">{blogError.description}</p>
                                                        )}
                                                    </td>
                                                    <td colSpan={3}>
                                                        <StyledInput
                                                            type="text"
                                                            name="url"
                                                            autoComplete="off"
                                                            placeholder={t('favorite-websites.url')}
                                                            value={blogInputValue.url}
                                                            onChange={handleBlogChange}
                                                            onKeyPress={(event) => {
                                                                onSubmitBlog(event);
                                                            }}
                                                            error={blogError.url}
                                                        />
                                                        {blogError.saveType === 'add' && blogError.url && (
                                                            <p className="Mui-error">{blogError.url}</p>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <Iconify
                                                            icon={'material-symbols:save'}
                                                            width={24}
                                                            height={24}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => onSubmitBlog({ key: 'Enter' })}
                                                        />
                                                        <Iconify
                                                            icon={'ri:delete-bin-6-fill'}
                                                            width={24}
                                                            height={24}
                                                            style={{ marginLeft: 10, cursor: 'pointer' }}
                                                            onClick={() => onDeleteBlog('new')}
                                                        />
                                                    </td>
                                                </tr>
                                            )}
                                            {blogTableData.map((item, index) => (
                                                <tr key={index} height="8px" style={tSubHeadStyle}>
                                                    {/* <td>{''}</td> */}
                                                    <td colSpan={3} style={{ wordWrap: 'break-word', maxWidth: 250 }}>
                                                        <p>{item.description}</p>
                                                    </td>
                                                    <td
                                                        colSpan={3}
                                                        onClick={() => {
                                                            setShowDialog(true);
                                                            setNavigationTimeout(
                                                                setTimeout(() => {
                                                                    navigateLink(item.url);
                                                                }, 3000)
                                                            );
                                                        }}
                                                        style={{
                                                            wordWrap: 'break-word',
                                                            maxWidth: 250,
                                                            color: '#048B67',
                                                            textDecoration: 'underline',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <p>{item.url}</p>
                                                    </td>
                                                    <td>
                                                        <Iconify
                                                            icon={'ri:delete-bin-6-fill'}
                                                            width={22}
                                                            height={22}
                                                            style={{ marginLeft: 32, cursor: 'pointer' }}
                                                            onClick={() => onDeleteBlog('update', item)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td />
                                                <td />
                                                <td />
                                                <td />
                                                <td />
                                                <td />
                                                <td />
                                                <td />
                                                <td
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {blogTableData.length < totalBlog && (
                                                        <Link
                                                            style={{
                                                                width: '200px',
                                                                padding: '9px 16px',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={loadMoreBlog}
                                                        >
                                                            {t('favorite-websites.load-more')}
                                                        </Link>
                                                    )}
                                                </td>
                                            </tr>
                                        </>
                                    </tbody>
                                </HeaderTableStyle>
                            </HeaderDivStyle>
                        </div>
                    </Grid>
                </Grid>
                {showDialog ? getNavigationDialog() : ''}
            </div>
        </Page>
    );
}
