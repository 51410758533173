import { Grid, Typography } from '@mui/material';
import image from 'assets/image';

export default function AboutFauzi() {
    return (
        <Grid className="sectionCommonPadding aboutTopMargin">
            <Grid container spacing={2} pb={6}>
                <Grid item lg={6} md={12} className='aboutCeoReview'>
                    <Typography variant='body1' className='founderTxtStyle'>About Fauzi Zamir</Typography>
                    <Typography variant='body1' className='founderTxtStyle'>CPA, CA, CEO & Founder</Typography>
                    <Typography variant='body1' className='founderQuoteStyle'><Typography variant='body' className='boldTextStyle'>Fauzi Zamir is a Canadian financial officer with a 30-plus year career on the leadership teams of businesses:</Typography> from large multi-billion-dollar companies to smaller technology and finance organizations.</Typography>
                    <Typography variant='body1' className='founderQuoteStyle'>He began his career as a Chartered Accountant with Price Waterhouse (now PWC) and progressed to CFO and COO roles within a number of private companies in Canada, achieving recognition for his financial acumen, leadership skills, and technology development talents.</Typography>
                </Grid>
                <Grid item lg={6} md={12} sx={{ position: 'relative' }}>
                    <img className='imgMarginAuto' src={image.founderImg} alt="Fauzi" />
                </Grid>
            </Grid>
        </Grid>
    );
}
