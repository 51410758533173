import { Grid } from '@mui/material';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import videoURL from 'constants/helpVideoUrl';
import Page from 'components/Page';
import TopHeading from '../../../components/TopHeading';
import { EXCEL_STYLE } from '../../calculators/constant';
import TopMenu from '../../../components/TopMenu';
import dashboard from '../../../constants/services/dashboard';
import { getCurrencySymbol, getMonthNameYear, getPositiveNumber, exportCustomExcel } from '../../../utils/calCommonFunction';
import { ReportAmountTd, TotalAmountDiv, ButtonText } from '../ExecutiveSummary/styled-component';
import {
    AmountDiv,
    LoaderGif,
    NoDataTd,
    StickyFirstTd,
    StickyFirstTh,
    StickyTable,
    StickyThirdTd,
    StickyThirdTh,
    TableStyle,
    TableWrapper,
} from '../Expense/styled-component';
import CreditCardFilter from '../filter/CreditCardFilter';
import { ContentStyle } from '../styled-component';
import { SubmitButton } from './styled-components';

export default function NetWorth() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [tableLoader, setTableLoader] = useState(false);
    const [netWorth, setNetWorth] = useState(null);
    const [monthColumn, setMonthColumn] = useState([]);
    const currencyCode = getCurrencySymbol();

    const [filtersData, setFiltersData] = useState({
        fromMonth: moment.parseZone().format('YYYY-MM'),
        compareMonth: moment.parseZone().startOf('year').format('YYYY-MM'),
    });

    const updateFilters = (key, value) => {
        setFiltersData({
            ...filtersData,
            [key]: value,
        });
    };

    const fetchData = async () => {
        setTableLoader(true)
        setLoading(true);
        const queryString = Object.keys(filtersData)
            .map(
                (key) =>
                    // eslint-disable-next-line
                    key + '=' + filtersData[key]
            )
            .join('&');

        const response = await dashboard.getNetWorth(queryString);
        const data = response?.netWorth;

        const monthRange = Object.keys(data);
        monthRange.sort();
        setMonthColumn(monthRange);
        setNetWorth(data);
        setTableLoader(false);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTableRow = (filterKey) => {
        let total = 0;
        return (
            <tr>
                <StickyFirstTd>{t('net-worth.total')} {filterKey}</StickyFirstTd>
                {monthColumn.map((item, index) => {
                    const amount = netWorth[item][filterKey];
                    total += amount;
                    return (
                        <ReportAmountTd key={index}>
                            <TotalAmountDiv
                                style={{
                                    width: 120,
                                    float: 'right',
                                }}
                            >
                                {currencyCode}
                                <span>{getPositiveNumber(amount)}</span>
                            </TotalAmountDiv>
                        </ReportAmountTd>
                    );
                })}
                <StickyThirdTd className="highlighted-td" style={{right: '-1px'}}>
                    <TotalAmountDiv
                        style={{
                            width: 120,
                            float: 'right',
                        }}
                    >
                        {currencyCode} <span>{getPositiveNumber(total / monthColumn.length)}</span>
                    </TotalAmountDiv>
                </StickyThirdTd>
            </tr>
        );
    };

    const getNetWorthRow = () => {
        let totalNetWorth = 0;
        let totalAssets = 0;
        let totalLiabilities = 0;
        const increaseDecrease = [''];
        let lastNetWorth = 0;
        return (
            <>
                <tr>
                    <StickyFirstTd
                        style={{
                            height: '51px',
                            fontSize: '16px',
                            fontWeight: '600',
                        }}
                    >
                        {t('net-worth.title')}
                    </StickyFirstTd>
                    {monthColumn.map((item, index) => {
                        const amountAssets = netWorth[item].Assets;
                        totalAssets += amountAssets;

                        const amountLiabilities = netWorth[item].Liabilities;
                        totalLiabilities += amountLiabilities;

                        totalNetWorth = amountAssets - amountLiabilities;
                        if (index === 0) {
                            lastNetWorth = totalNetWorth;
                        }
                        if (index > 0) {
                            increaseDecrease.push(totalNetWorth - lastNetWorth);
                            lastNetWorth = totalNetWorth;
                        }

                        return (
                            <td key={index}>
                                <AmountDiv>
                                    {currencyCode}
                                    <span>{getPositiveNumber(totalNetWorth)}</span>
                                </AmountDiv>
                            </td>
                        );
                    })}
                    <StickyThirdTd className="highlighted-td" style={{right: '-1px'}}>
                        <AmountDiv>
                            {currencyCode}
                            <span>{getPositiveNumber((totalAssets - totalLiabilities) / monthColumn.length)}</span>
                        </AmountDiv>
                    </StickyThirdTd>
                </tr>
                <tr>
                    <StickyFirstTd>{t('net-worth.Increase')}</StickyFirstTd>
                    {increaseDecrease.map((item, index) => (
                        <ReportAmountTd key={index}>
                            <TotalAmountDiv
                                style={{
                                    width: 120,
                                    float: 'right',
                                }}
                            >
                                {index > 0 ? (
                                    <>
                                        {currencyCode}
                                        <span>{getPositiveNumber(item)}</span>
                                    </>
                                ) : (
                                    <>&nbsp;</>
                                )}
                            </TotalAmountDiv>
                        </ReportAmountTd>
                    ))}
                    <StickyThirdTd style={{right: '-1px'}}>{''}</StickyThirdTd>
                </tr>
            </>
        );
    };

    const getNetWorthData=(netWorthName)=>{
        let total = 0;
        const getNetWorthData = []
       
        monthColumn.forEach((item) => {
            const amount = netWorth[item][netWorthName];
            total += amount;
            const existingMonth = getNetWorthData.find(outputItem => outputItem.Category === `Total ${netWorthName}`);
            if (existingMonth) {
                existingMonth[item] = getPositiveNumber(netWorth[item][netWorthName]);
            } else {
                const newItem = { Category: `Total ${netWorthName}`}
                newItem[item] =  getPositiveNumber(netWorth[item][netWorthName]);
                getNetWorthData.push(newItem);
            }
        });

        const addingTotalData = getNetWorthData.map(v => ({...v, 'Average': getPositiveNumber(total / monthColumn.length)}))
        return addingTotalData; 
    }

    const getMonthWiseTotal=(netWorthName)=>{
        let totalNetWorth = 0;
        let totalAssets = 0;
        let totalLiabilities = 0;
        const increaseDecrease = [''];
        const netWorthMonthTotal = [];
        const increaseDecreaseTotal = [];
        let lastNetWorth = 0;
        const monthNetworth= {}

        monthColumn.forEach((item,index) => {
            const amountAssets = netWorth[item].Assets;
            totalAssets += amountAssets;
            
            const amountLiabilities = netWorth[item].Liabilities;
            totalLiabilities += amountLiabilities;

            totalNetWorth = amountAssets - amountLiabilities;
            if (index === 0) {
                lastNetWorth = totalNetWorth;
            }
            if (index > 0) {
                increaseDecrease.push(totalNetWorth - lastNetWorth);
                lastNetWorth = totalNetWorth;
            }
            monthNetworth[item] =totalNetWorth
        });

        monthColumn.forEach((item, index) => {
            const existingMonth = netWorthMonthTotal.find(outputItem => outputItem.Category === netWorthName);
            if (existingMonth) {
                existingMonth[monthColumn[index]] = getPositiveNumber(monthNetworth[item]);
            } else {
                const newItem = { Category: netWorthName, Average: getPositiveNumber((totalAssets - totalLiabilities)/ monthColumn.length)};
                newItem[monthColumn[index]] = getPositiveNumber(monthNetworth[item]);
                netWorthMonthTotal.push(newItem);
            }
        });

        increaseDecrease.forEach((items, index) => {
            const existingMonth = increaseDecreaseTotal.find(outputItem => outputItem.Category === 'Increase (Decrease)');
            if (existingMonth) {
                existingMonth[monthColumn[index]] = getPositiveNumber(items);
            } else {
                const newItem = { Category: 'Increase (Decrease)'};
                newItem[monthColumn[index]] = getPositiveNumber(items);
                increaseDecreaseTotal.push(newItem);
            }
        });

        return {netWorthMonthTotal, increaseDecreaseTotal}
    }

    const getExcelSummary =()=>{
        const selectedFormData= []
        const merges = []
       
        selectedFormData.push({
            'From Date': filtersData.compareMonth,
            'To Date': filtersData.fromMonth,
        })

        const assetNetWorth = getNetWorthData('Assets');
        const liabilitieNetWorth= getNetWorthData('Liabilities')
        const { netWorthMonthTotal ,increaseDecreaseTotal }=getMonthWiseTotal('Net Worth')
        const excelObjectData = [...assetNetWorth, ...liabilitieNetWorth, ...netWorthMonthTotal, ...increaseDecreaseTotal]
        const newOutputArray = excelObjectData.map((item) => {
            const { Category, ...rest } = item;
            return { Category, ...rest };
        });
        const addCurrencySymbol = newOutputArray.map(obj => {
            const newObj = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const key in obj) {
                if (key !== 'Category') {
                    newObj[key] = `$ ${obj[key]}`;
                } else {
                    newObj[key] = obj[key];
                }
            }
            return newObj;
        });
        const style = [
            {
                "col": 'A1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'B1',
                "style": EXCEL_STYLE.boldHeader
            }
        ]
        const data = [selectedFormData, addCurrencySymbol];
        const cols = [{ width: 18 }, { width: 13 }, { width: 17 }, { width: 20 }, { width: 13 }, { width: 13 }, { width: 13 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 15 }];
        exportCustomExcel(data, style, cols, merges, 'Net worth', 7, ['I'], [false, false], [-1, 'A4', -1]);

    }

    return (
        <Page title={t('net-worth.title')}>
            <TopMenu title="General Reports" videoLink={videoURL.netWorth} />
            <TopHeading heading="Net Worth" />
            <ContentStyle style={{ paddingTop: '0px' }}>
                <Grid container pt={4} pb={2} mt={0}>
                    <Grid item md={10.5} xs={12}>
                        <CreditCardFilter filtersData={filtersData} updateFilters={updateFilters} />
                    </Grid>
                    <Grid item md={1.5} xs={12} style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                        <SubmitButton loading={loading} size="large" variant="contained" onClick={() => fetchData()} className='loadingButtonStyle2'>
                            Go
                        </SubmitButton>
                    </Grid>
                </Grid>

                <Grid container spacing={2} pt={2} justifyContent="center" alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={12}>
                        <StickyTable>
                            <TableWrapper>
                                <TableStyle>
                                    {tableLoader ? (
                                        <tbody>
                                            <tr>
                                                <td colSpan={4}>
                                                    <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <>
                                            {monthColumn.length > 0 ? (
                                                <>
                                                    <thead>
                                                        <tr>
                                                            <StickyFirstTh>&nbsp;</StickyFirstTh>
                                                            {monthColumn.map((item, index) => (
                                                                <th style={{ textAlign: 'end' }} key={index}>
                                                                    {getMonthNameYear(item)}
                                                                </th>
                                                            ))}
                                                            <StickyThirdTh className="highlighted-th" style={{right: '-1px'}}>
                                                                {t('net-worth.average')}
                                                            </StickyThirdTh>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {getTableRow('Assets')}
                                                        {getTableRow('Liabilities')}
                                                        {getNetWorthRow()}
                                                    </tbody>
                                                </>
                                            ) : (
                                                <tbody>
                                                    <tr>
                                                        <NoDataTd colSpan={4}>
                                                            {t('executive-summary.no-data-found')}
                                                        </NoDataTd>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </>
                                    )}
                                </TableStyle>
                            </TableWrapper>
                        </StickyTable>
                    </Grid>
                </Grid>
                <ButtonText>
                    <LoadingButton
                        fullWidth
                        size="large"
                        variant="contained"
                        // disabled={}
                        onClick={() => getExcelSummary()}
                        className='loadingButtonStyle2'
                    >
                        Export
                    </LoadingButton>
                </ButtonText>
            </ContentStyle>
        </Page>
    );
}
