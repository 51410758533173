import React,{useEffect} from 'react'
import { useNavigate, Outlet } from 'react-router-dom';
import Header from './header/Header'

const HomePageSocialMediaLayout = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (sessionStorage.getItem('userData')) {
            const user = JSON.parse(sessionStorage.getItem('userData'));
            if (Object.keys(user).length > 0) {
                navigate('/welcome', { replace: true });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header />
            <Outlet />
        </>
    )
}

export default HomePageSocialMediaLayout