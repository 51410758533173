import { styled, Typography, Accordion } from '@mui/material';

export const HeadArea = styled('div')(({ theme }) => ({
    '& .commonText': {
        width: '72%',
        paddingTop: '32px',
        paddingBottom: '32px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));
  
export const AccordionStyle = styled(Accordion)(({ theme }) => ({
    width: '100%',
    marginTop: 15,
    '&:before': {
        height: 0,
    },
    '& svg': {
        fill: theme.palette.primary.main,
        '& path': {
            fill: theme.palette.primary.main,
        },
        '& g': {
            stroke: theme.palette.primary.main,
        },
    },
    '& .MuiButtonBase-root .MuiAccordionSummary-content':{
        borderBottom: 'solid 2px #0F9672',
    },
    '& .MuiButtonBase-root': {
        padding: 0,
        position: 'sticky',
        backgroundColor: '#f1f1f1',
        zIndex: '3',
        borderRadius: '10px 10px 0px 0px',
        top: '0px',
        '& .MuiAccordionSummary-content': {
            margin: 0,
            borderRadius: '10px 10px 0px 0px',
            padding: '26px 45px 25px',
            alignItems: 'center',
            [theme.breakpoints.down('xl')]: {
                padding: 19,
            },
            '& p': {
                color: theme.palette.primary.dark,
                fontSize: 18,
                lineHeight: '27px',
                fontWeight: 700,
                marginRight: 'auto',
            },
            '& svg': {
                display: 'block',
            },
            '& svg + svg': {
                display: 'none',
            },
        },
        '& .Mui-expanded': {
            borderRadius: '10px 10px 0 0',
            '& svg': {
                display: 'none',
            },
            '& svg + svg': {
                display: 'block',
            },
        },
    },
    '& .MuiCollapse-root': {
        background: theme.palette.secondary.lighter,
        borderRadius: '0 0 10px 10px',
        padding: '0 45px 25px',
        [theme.breakpoints.down('xl')]: {
            padding: '0 19px 19px',
        },
        '& .MuiCollapse-wrapper': {
            // borderTop: `solid 2px ${theme.palette.primary.main}`,
            '& .MuiAccordionDetails-root': {
                padding: 0,
            },
        },
    },
}));
  
export const Title = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.primary.light,
    paddingBottom: 48,
}));