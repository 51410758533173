import image from "assets/image";

const getAccountSetupDetails=(t)=>([
    {
        id: 1,
        img: image.step1Animation,
        title:t('how-it-works-accountSetup.title-1'),
        subtext:t('how-it-works-accountSetup.subtext-1'),
        text: t('how-it-works-accountSetup.text-1')
    },
    {
        id: 2,
        img: image.step2Animation,
        title:t('how-it-works-accountSetup.title-2'),
        subtext:t('how-it-works-accountSetup.subtext-2'),
        text: t('how-it-works-accountSetup.text-2')
    },
    {
        id: 3,
        img: image.step3Animation,
        title:t('how-it-works-accountSetup.title-3'),
        subtext:t('how-it-works-accountSetup.subtext-3'),
        text: t('how-it-works-accountSetup.text-3')
    },
    {
        id: 4,
        img: image.step4Animation,
        title:t('how-it-works-accountSetup.title-4'),
        subtext:t('how-it-works-accountSetup.subtext-4'),
        text: t('how-it-works-accountSetup.text-4')
    },
]);
export{
    getAccountSetupDetails
}