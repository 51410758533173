const genderOptions = [
    { label: 'Female'},
    { label: 'Male'},
    { label: 'Non-binary'},
    { label: 'Transgender'},
    { label: 'Intersex'},
    { label: 'I prefer not to say'},
]

const ageGroupOptions = [
    { label: '18-29'},
    { label: '30-39'},
    { label: '40-59'},
    { label: '60-64'},
    { label: 'Over 65'},
];

export {
    genderOptions,
    ageGroupOptions,
};