// @mui
import { useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import Input from '@mui/material/Input';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
// components
import { useNavigate } from 'react-router-dom';
import Page from '../../../components/Page';
import { EXCEL_STYLE } from '../constant';
import { PaymentTitle, HeaderDivStyle, HeaderTableStyle, TableStyle, MidSideColumn, TopSideBar, LightTooltip, TotalFlow, BudgetInput, TotalFlowHeader, ButtonText, AutoFieldStyle, InfoIcon, BudgetPercentageInput, ExtraSpacing, TotalFlowValueColumn, TotalAmountDiv } from '../styled-components';
import calculator from '../../../constants/services/calculator';
import { formatDollarValues, removeNumberFormatting, formatZeroValues, downloadExcelHeader, getCurrencySymbol, calcPMT, goBackToMenu } from '../../../utils/calCommonFunction';
import TopMenu from '../../../components/TopMenu';
import TopHeading from '../../../components/TopHeading';

export default function MortgagePayment() {
    const [mortgageAmount, setMortgageAmount] = useState("");
    const [interestRatePerYear, setInterestRatePerYear] = useState("");
    const [compoundingFrequency, setCompoundingFrequency] = useState("");
    const [amortizationPeriod, setAmortizationPeriod] = useState("");
    const [isLogged, setIsLogged] = useState(false)
    const { t } = useTranslation();
    const currencySymbol = getCurrencySymbol();
    const navigate = useNavigate();

    const compoundingFrequencyOptions = [
        { label: 'Annual' },
        { label: 'Semi-annual' },
    ]

    const getMortgagePayment = async () => {
        const getMorgageData = await calculator.getUserCalculator("mortgage-payment");
        if (getMorgageData.data) {
            const morgageJsonData = getMorgageData.data?.calcJson;
            setMortgageAmount(morgageJsonData.mortgage_amount);
            setInterestRatePerYear(morgageJsonData.interest_rate_per_year);
            setCompoundingFrequency(morgageJsonData.compounding_frequency);
            setAmortizationPeriod(morgageJsonData.amortization_period);
        }
    }

    useEffect(() => {
        if (sessionStorage.getItem('userData')) {
            const user = JSON.parse(sessionStorage.getItem('userData'));
            if (Object.keys(user).length > 0) {
                setIsLogged(true);
                getMortgagePayment();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mortgagePaymentDetails = () => {
        let rate = (Number(interestRatePerYear) / (12 * 100));
        if (compoundingFrequency === 'Semi-annual') {
            rate = (((Number(interestRatePerYear) / (2 * 100)) + 1) ** (1 / 6)) - 1;
        }
        return rate
    }

    // calculation
    const monthlyMortgage = calcPMT(mortgagePaymentDetails(), (Number(amortizationPeriod) * 12), Number(removeNumberFormatting(mortgageAmount)) * -1, 0).toFixed(2);
    const totalPaymt = ((Number(removeNumberFormatting(monthlyMortgage)) * (Number(amortizationPeriod)) * 12));
    const interestMortgage = ((totalPaymt - Number(removeNumberFormatting(mortgageAmount))).toFixed(2));
    const mortgageValue = Number(removeNumberFormatting(mortgageAmount));

    const principalRepaymentValue = Number(mortgageValue).toLocaleString("en-US", { minimumFractionDigits: 2 });
    const monthlyMortgageAmount = Number(monthlyMortgage).toLocaleString("en-US", { minimumFractionDigits: 2 });
    const totalPayment = Number(totalPaymt.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2 });
    const interestAmount = Number(interestMortgage).toLocaleString("en-US", { minimumFractionDigits: 2 });

    const handleChanged = (e) => {
        const { name, value } = e.target;
        if (name === 'interest_rate_per_year') {
            const value = e.target.value.replace(/[^0-9.-]+/g, '');
            setInterestRatePerYear(value);
        }
        if (name === 'compounding_frequency') {
            const value = e.target.value.replace(/[^0-9.-]+/g, '');
            setCompoundingFrequency(value);
        }
        if (name === 'amortization_period') {
            const value = e.target.value.replace(/[^0-9.-]+/g, '');
            setAmortizationPeriod(value);
        }
    }

    const resetFields = () => {
        setMortgageAmount("");
        setInterestRatePerYear("");
        setCompoundingFrequency("");
        setAmortizationPeriod("");
    }

    const saveLoanData = async () => {
        try {
            const postData = {
                "mortgage_amount": mortgageAmount,
                "interest_rate_per_year": interestRatePerYear,
                "compounding_frequency": compoundingFrequency,
                "amortization_period": amortizationPeriod
            }
            const loanDataObj = {
                "calcType": "mortgage-payment",
                "calcJson": postData
            };
            await calculator.addUserCalculator(loanDataObj);
            toast.success('Calculation saved successfully');
        } catch (error) {
            toast.error(error.message);
        }
    }

    const exportExcel = () => {
        const postData = [
            {
                "Description": "Mortgage amount",
                "Value": `${currencySymbol} ${mortgageAmount}`
            },
            {
                "Description": "Interest rate per year",
                "Value": `${interestRatePerYear} %`
            },
            {
                "Description": "Compounding frequency",
                "Value": compoundingFrequency
            },
            {
                "Description": "Amortization period in years",
                "Value": amortizationPeriod
            },
            {
                "Description": "",
                "Value": ''
            },
            {
                "Description": "Monthly mortgage payment",
                "Value": `${currencySymbol} ${monthlyMortgageAmount}`
            },
            {
                "Description": "",
                "Value": ''
            },
            {
                "Description": "Payment Summary",
                "Value": ""
            },
            {
                "Description": "Principal repayment",
                "Value": `${currencySymbol} ${mortgageAmount}`
            },
            {
                "Description": "Interest",
                "Value": `${currencySymbol} ${interestAmount}`
            },
            {
                "Description": "Total payment",
                "Value": `${currencySymbol} ${totalPayment}`
            }
        ];
        const style = [
            {
                "col": 'A1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'B1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'A7',
                "style": EXCEL_STYLE.boldColor
            },
            {
                "col": 'B7',
                "style": EXCEL_STYLE.boldColorRight
            },
            {
                "col": 'A9',
                "style": EXCEL_STYLE.boldColorHrVr
            },
            {
                "col": 'A12',
                "style": EXCEL_STYLE.boldColor
            },
            {
                "col": 'B12',
                "style": EXCEL_STYLE.boldColorRight
            }
        ]
        const cols = [{ width: 26 }, { width: 20 }];
        const merges = [
            { s: { r: 5, c: 0 }, e: { r: 5, c: 1 } },
            { s: { r: 7, c: 0 }, e: { r: 7, c: 1 } },
            { s: { r: 8, c: 0 }, e: { r: 8, c: 1 } },
        ];
        downloadExcelHeader(postData, style, cols, merges, 'MortgagePayment', 2, ['B'], false);
    }

    return (
        <Page title={"Simplify mortgage math with MoolahMate's mortgage calculator"} description={"Calculate your mortgage payments simply and easily with MoolahMate's mortgage payment calculator. MoolahMate makes your finances easy."} className={isLogged ? '' : 'homeCalcultorsStyle'}>
            {isLogged && (
                <>
                    <TopMenu title={t('mortgage-payment.calculator')} subText="Need Help?" hideBellIcon />
                </>
            )}
            <TopHeading heading={t('mortgage-payment.subtitle')} headingCenter={!isLogged} hideBackLink={!isLogged} />
            <div className={isLogged ? 'contentStyle' : 'topCalcPadding'}>
                <Grid container spacing={0} pt={0} justifyContent="center" alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={12}>
                        <HeaderDivStyle>
                            <PaymentTitle>{t('mortgage-payment.description')}</PaymentTitle>
                            <div className="mobileOverFlowDiv">
                                <HeaderTableStyle>
                                    <tbody>
                                        <tr>
                                            <td>{t('mortgage-payment.mortgage-amount')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="mortgage_amount"
                                                    value={mortgageAmount}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMortgageAmount(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMortgageAmount(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('mortgage-payment.interest-rate')}</td>
                                            <BudgetPercentageInput>
                                                <Input
                                                    type="text"
                                                    placeholder="0"
                                                    name="interest_rate_per_year"
                                                    value={interestRatePerYear}
                                                    onChange={handleChanged}
                                                />
                                                <span>%</span>
                                            </BudgetPercentageInput>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t('mortgage-payment.compounding-frequency')}
                                                <LightTooltip
                                                    title={
                                                        <>
                                                            <p>{t('mortgage-payment.mortgage-rate')}</p>
                                                        </>
                                                    }
                                                >
                                                    <InfoIcon src="/static/linkFinancial/info-icon.svg" alt="" />
                                                </LightTooltip>
                                            </td>
                                            <BudgetInput>
                                                <AutoFieldStyle
                                                    id="tags-outlined"
                                                    options={compoundingFrequencyOptions}
                                                    value={compoundingFrequency}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.label === value.label
                                                    }
                                                    disableClearable
                                                    onChange={(e, val) => {
                                                        setCompoundingFrequency(val.label);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            className="dropMenu"
                                                        />
                                                    )}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t('mortgage-payment.amortization-period')}
                                                <LightTooltip
                                                    title={
                                                        <>
                                                            <p>{t('mortgage-payment.loan-period')}</p>
                                                        </>
                                                    }
                                                >
                                                    <InfoIcon src="/static/linkFinancial/info-icon.svg" alt="" />
                                                </LightTooltip>
                                            </td>
                                            <BudgetInput>
                                                <Input
                                                    type="text"
                                                    placeholder="0"
                                                    name="amortization_period"
                                                    value={amortizationPeriod}
                                                    onChange={handleChanged}
                                                />
                                            </BudgetInput>
                                        </tr>
                                    </tbody>
                                </HeaderTableStyle>
                            </div>
                        </HeaderDivStyle>

                        <div className="divTableStyle" style={{ marginTop: '0px' }}>
                            <TableStyle>
                                <tbody>
                                    <tr>
                                        <TopSideBar>{t('mortgage-payment.monthly-mortgage-payment')}</TopSideBar>
                                        <TotalFlowValueColumn>
                                            <TotalAmountDiv>
                                                {currencySymbol}
                                                <span>
                                                    {' '}
                                                    {monthlyMortgageAmount === 'NaN' ? '0.00' : monthlyMortgageAmount}
                                                </span>
                                            </TotalAmountDiv>
                                        </TotalFlowValueColumn>
                                    </tr>
                                </tbody>
                                <ExtraSpacing>
                                    <tr>
                                        <td>{''}</td>
                                    </tr>
                                </ExtraSpacing>
                                <tbody>
                                    <tr>
                                        <TotalFlowHeader>{t('mortgage-payment.payment-summary')}</TotalFlowHeader>
                                        <td>{''}</td>
                                        <td>{''}</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td>{t('mortgage-payment.principal-repayments')}</td>
                                        <MidSideColumn>
                                            {currencySymbol}{' '}
                                            {principalRepaymentValue === 'NaN' ? 0.0 : principalRepaymentValue}
                                        </MidSideColumn>
                                    </tr>
                                    <tr>
                                        <td>{t('mortgage-payment.interest')}</td>
                                        <MidSideColumn>
                                            {currencySymbol} {interestAmount === 'NaN' ? '0.00' : interestAmount}
                                        </MidSideColumn>
                                    </tr>
                                    <tr>
                                        <TotalFlow>{t('mortgage-payment.total-payment')}</TotalFlow>
                                        <TotalFlowValueColumn>
                                            <TotalAmountDiv>
                                                {currencySymbol}
                                                <span> {totalPayment === 'NaN' ? '0.00' : totalPayment}</span>
                                            </TotalAmountDiv>
                                        </TotalFlowValueColumn>
                                    </tr>
                                </tbody>
                            </TableStyle>
                        </div>
                    </Grid>
                </Grid>
                <ButtonText style={isLogged ? {} : { justifyContent: 'center' }}>
                    {isLogged && (
                        <div className='backButtonShowStyle'>
                            {goBackToMenu(navigate)}
                        </div>
                    )}
                    <div className='centerAlignStyle'>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            className="loadingButtonStyle2"
                            // disabled={}
                            onClick={resetFields}
                            style={isLogged ? {} : { maxWidth: '200px' }}
                        >
                            {t('mortgage-payment.reset')}
                        </LoadingButton>
                        {isLogged && (
                            <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                variant="outlined"
                                className="loadingButtonStyle1"
                                onClick={saveLoanData}
                            >
                                {t('mortgage-payment.save')}
                            </LoadingButton>
                        )}
                        {isLogged && (
                            <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                className="loadingButtonStyle2"
                                variant="contained"
                                onClick={exportExcel}
                            >
                                {t('mortgage-payment.export')}
                            </LoadingButton>
                        )}
                    </div>
                </ButtonText>
            </div>
        </Page>
    );
}
