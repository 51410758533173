/* eslint-disable jsx-a11y/media-has-caption */
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import image from 'assets/image';
import PageSubHeadingStyle from '../../../components/PageSubHeadingStyle'
import { getFeaturePoints } from './constant';
import founderVideo from '../../../assets/videos/FounderVideo.mp4';

export default function EverythingYouNeed() {
    const { t } = useTranslation();
    const featurePoints = getFeaturePoints(t);

    return (
        <Grid className="sectionLeftPadding">
            <Grid container spacing={2} pt={6} pb={6}>
                <Grid item md={6} className='aboutFeatureStyle'>
                    <PageSubHeadingStyle
                        headLineOne={t('about-feature.headline-1')}
                        headLineTwo={t('about-feature.headline-2')}
                        headLineThree={t('about-feature.headline-3')}
                    />
                    <ul className="featurePoints">
                        {featurePoints.map((item, indx) => (
                            <li key={indx}>
                                <Typography variant='body' className='featurePointsStyle'>
                                    <img src={image.rightIcon} alt="" /> <Typography variant='body'>{item.text}</Typography>
                                </Typography>
                            </li>
                        ))}
                    </ul>
                </Grid>
                <Grid item md={6} sx={{ order: '1' }} style={{ marginTop: '10%' }}>
                    <img src={image.moneyTracker} alt="Header logo" className="imageStyle" />
                </Grid>
            </Grid>

            <Grid item md={12} mt={4} style={{ paddingRight: '16%' }}>
                <video style={{margin:'auto', maxWidth: '100%'}} controls poster={image.founderVideoPoster}>
                    <source src={founderVideo} type="video/mp4" />
                </video>
            </Grid>
        </Grid>
    );
}
