import Typography from '@mui/material/Typography';

export default function InfoProvider(props) {
    return (
        <div className='flexDisplay'>
            <div>
                <div className='pointerStyle'>
                    <span>{props.pointerLineOne}</span>
                </div>
            </div>
            <div>
                <Typography className='headingPointerStyle' variant="body1">{props.pointerLineTwo}</Typography>
                <Typography className='subHeadingPointerStyle' variant="body2">{props.pointerLineThree}</Typography>
            </div>
        </div>
    );
}