/* eslint-disable global-require */
export default {
    navActive: require('./images/homeImages/navActive.svg').default,
    navInactive: require('./images/homeImages/navInactive.svg').default,
    iosIcon: require('./images/homeImages/iosAppIcon.svg').default,
    androidIcon: require('./images/homeImages/androidAppIcon.svg').default,
    webLogo: require('./images/homeImages/applicationLogo.svg').default,
    FooterLogo: require('./images/homeImages/FooterLogo.svg').default,  
    clientReview: require('./images/homeImages/clientReview-1.svg').default,
    videoTrial: require('./images/homeImages/videoTrial.svg').default,
    moneyBag: require('./images/homeImages/moneyBag.svg').default,
    knowledgeBase2: require('./images/homeImages/knowledgeBase2.svg').default,
    expenseTracking: require('./images/homeImages/expenseTracking.svg').default,
    financialTools: require('./images/homeImages/financialTools.svg').default,
    cashFlowControl: require('./images/homeImages/cashFlowControl.svg').default,
    timelyReporting: require('./images/homeImages/timelyReporting.svg').default,
    calculators: require('./images/homeImages/calculators.svg').default,
    knowledgeBase: require('./images/homeImages/knowledgeBase.svg').default,
    toDos: require('./images/homeImages/toDos.svg').default,
    keyDates: require('./images/homeImages/keyDates.svg').default,
    suggestionBox: require('./images/homeImages/suggestionBox.svg').default,
    howItWorks: require('./images/homeImages/howItWorks.svg').default,
    curveIcons2: require('./images/homeImages/curveIcons-2.png'),
    comma: require('./images/homeImages/comma.svg').default,
    rightIcon: require('./images/homeImages/rightIcon.svg').default,
    curveIcons10: require('./images/homeImages/curveIcons-10.png'),
    downloadIcon: require('./images/homeImages/downloadIcon.png'),
    otherTrans: require('./images/homeImages/otherTrans.png'),
    keyDateIcon: require('./images/homeImages/keyDateIcon.png'),
    balanceSheet: require('./images/homeImages/balanceSheet.png'),
    sliderImg1 :require('./images/homeImages/sliderImg-1.svg').default,
    sliderImg2 :require('./images/homeImages/sliderImg-2.svg').default,
    sliderImg3 :require('./images/homeImages/sliderImg-3.svg').default,
    sliderImg4 :require('./images/homeImages/sliderImg-4.svg').default,
    sliderImg5 :require('./images/homeImages/sliderImg-1.svg').default,
    desktopHub: require('./images/howItWork/desktopHub.svg').default,
    moneyManagement: require('./images/howItWork/moneyManagement.png'),
    learnHeader: require('./images/learn/learnHeaderImg.png'),
    curveLearn: require('./images/learn/curveLearnIcon.png'),
    backgroudCurve: require('./images/learn/backgroudCurve.png'),
    
    forums: require('./images/homeImages/forums.png'),
    twitter: require('./images/homeImages/x-twitter.svg').default,
    youtube: require('./images/homeImages/youtube.svg').default,
    linkedin: require('./images/homeImages/linkedin.svg').default,
    facebook: require('./images/homeImages/facebook.svg').default,
    instagram: require('./images/homeImages/instagram.svg').default,
    hamburger: require('./images/icons/hamburger.png'),
    wrongIcon: require('./images/homeImages/wrongIcon.png'),
    correctIcon: require('./images/homeImages/correctIcon.png'),
    appBudget: require('./images/howItWork/appBudgetPage.png'),
    backgroundCurve1: require('./images/howItWork/backgroundCurve-1.png'),
    dataSecurityMoolahmate: require('./images/home/data-security-moolahmate.png'),

    // about us images
    founderImg: require('./images/aboutImages/founderImg.JPG'),
    laptop: require('./images/aboutImages/laptop.png'),
    founderIcon: require('./images/aboutImages/founderIcon.png'),
    features: require('./images/aboutImages/features.svg').default,
    tickIcon: require('./images/aboutImages/tick-icon.svg').default,
    visionIcon: require('./images/aboutImages/vision-icon.svg').default,
    getSmarter: require('./images/aboutImages/get-smarter.svg').default,
    otherBenefits: require('./images/aboutImages/other-benefits.png'), 
    curveImg: require('./images/aboutImages/curveImg.png'),

    showGrid: require('./images/icons/showGrid.png'),
    showList: require('./images/icons/showList.png'),
    suggestionBoxIocn: require('./images/icons/suggestionBoxIocn.png'),
    logoWebIcon: require('./images/icons/logoWebIcon.svg').default,

    dashboardIcon: require('./images/icons/dashboardIcon.svg').default,
    informationIcon: require('./images/icons/informationIcon.svg').default,
    taskIcon: require('./images/icons/taskIcon.svg').default,
    transactionIcon: require('./images/icons/transactionIcon.svg').default,

    // dashboardhub
    calculator: require('./images/dashboard/calculator.svg').default,
    dashboard: require('./images/dashboard/dashboard.svg').default,
    download: require('./images/dashboard/download.svg').default,
    faqIcon: require('./images/dashboard/faq.svg').default,
    keyDateIcon1: require('./images/dashboard/key-dates.svg').default,
    otherTransactions: require('./images/dashboard/other-transactions1.svg').default,
    suggestion: require('./images/dashboard/suggestion.svg').default,
    todoIcon: require('./images/dashboard/todo.svg').default,
    transactions: require('./images/dashboard/transactions.svg').default,
    knowledgeBaseIcon: require('./images/dashboard/knowledge-base.svg').default,
    forum: require('./images/dashboard/forum.svg').default,
    mySettings: require('./images/dashboard/my-settings.svg').default,
    favWebsite: require('./images/dashboard/fav-website.svg').default,
    contactUs: require('./images/dashboard/contact.svg').default,
    report: require('./images/dashboard/report.svg').default,
    hub: require('./images/dashboard/home.svg').default,
    internalDashboard: require('./images/dashboard/internal-dashboard.svg').default,

    // new images
    mainBannerAnimation: require('./images/home/banner-graphic-animation.webp'),
    reasonsWhyToChooseMoolahAnimation: require('./images/home/reasons-why-to-choose-moolah.webp'),
    everythingYouNeedToManageAnimation: require('./images/home/everything-you-need-animation.webp'),
    learnHubAnimation: require('./images/home/learn-hub-animation.webp'),
    getStartedAnimation: require('./images/home/get-started-animation.webp'),
    realTimeReportingAnimation: require('./images/home/real-time-reporting-animation.webp'),
    calculatorAnimation: require('./images/home/calculations-mortgage-animations.webp'),
    footerGraphicAnimation: require('./images/home/footer-graphic-animation.webp'),
    testimonial1: require('./images/home/testmonial-img-1.svg').default,
    testimonial2: require('./images/home/testmonial-img-2.svg').default,
    testimonial3: require('./images/home/testmonial-img-3.svg').default,
    rightArrow: require('./images/home/right-long-solid.svg').default,

    aboutBanner: require('./images/about/about-main-banner.webp'),
    moneyTracker: require('./images/about/money-tracker.webp'),
    enjoyBenefits: require('./images/about/enjoy-the-benefits.webp'),
    getSmarterAnimation: require('./images/about/get-smarter-animation.webp'),

    howItWorksAnimation: require('./images/how-it-works/how-it-works-animation.webp'),
    howItWorksImage: require('./images/how-it-works/how-it-works-main-image.webp'),
    step1Animation: require('./images/how-it-works/1-step-animation-link-institutions.webp'),
    step2Animation: require('./images/how-it-works/2-step-automated-download.webp'),
    step3Animation: require('./images/how-it-works/3-step-animtion-financial-overview.webp'),
    step4Animation: require('./images/how-it-works/4-step-animation-income-report.webp'),
    businessEfficiencyAnimation: require('./images/how-it-works/business-efficiency-animation.webp'),
    
    founderVideoPoster: require('./videos/FounderVideoPoster.jpg'),
    moolahMateVideoPoster: require('./videos/MoolahMateIntroPoster.jpg'),

    mmVsCompetitionBanner: require('./images/mm-vs-competition/mm-vs-competition-banner.webp')
}