import * as React from 'react';
import { styled, Typography, Grid, Box } from '@mui/material';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Iconify from 'components/Iconify';

const TableScroll = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        overflowX: 'auto',
    },
}));

const TableStyle = styled('table')(({ theme }) => ({
    width: '100%',
    paddingTop: '0px',
    minWidth: 550,
    [theme.breakpoints.down('md')]: {
        paddingTop: 10,
    },
    '& th': {
        textAlign: 'left',
        fontSize: 18,
        fontWeight: 700,
        color: theme.palette.primary.dark,
        padding: 10,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
    },
    '& td': {
        padding: '20px 0 0px',
        verticalAlign: 'top',
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: '10px !important',
        },
        '& .MuiGrid-root': {
            marginBottom: 0,
            '& p.Mui-error': {
                color: 'rgb(255, 72, 66)',
                fontSize: '0.75rem',
                fontWeight: 400,
                textAlign: 'left'
            }
        },
        '&:nth-child(1), &:nth-child(3)': {
            paddingLeft: 10
        },
        '& svg.delete-icon': {
            fill: '#000000',
            '& path': {
                fill: '#000000',
            }
        }
        
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
}));

const SwitchStyle = styled(Box)(() => ({
    width: 136,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '& p': {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 1,
    },
    '& span + p': {
        left: 'inherit',
        right: 0,
    },
}));

const CheckboxStyle = styled(Checkbox)(({ theme }) => ({
    '& svg': {
        position: 'absolute'
    },
    position: 'relative',
    '&:after': {
        width: 20,
        height: 20,
        border: `solid 0px ${theme.palette.primary.main}`,
        content: "''",
        borderRadius: 3,
    },
    '&.Mui-checked': {
        '&:before': {
            width: 10,
            height: 10,
            // background: theme.palette.primary.main,
            content: "''",
            position: 'absolute',
            borderRadius: 1,
        },
    },
    '&.Mui-disabled': {
        '& svg': {
            '& path': {
                fill: 'rgba(145, 158, 171, 0.8); !important',
            },
        },
    }
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 134,
    height: 30,
    padding: 0,
    display: 'flex',
    border: `solid 1px ${theme.palette.primary.main}`,
    borderRadius: 5,
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 67,
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 0,
        position: 'relative',
        '&:before, &:after': {
            position: 'absolute',
            left: 0,
            top: 2,
            content: "'Yes'",
            width: 67,
            height: 30,
            textAlign: 'center',
            color: theme.palette.secondary.contrastText,
        },
        '&:after': {
            content: "'No'",
            left: '100%',
            color: theme.palette.primary.contrastText,
        },
        '&.Mui-checked': {
            transform: 'translateX(67px)',
            '&:before': {
                left: '-100%',
                content: "'Yes'",
                color: theme.palette.primary.contrastText,
            },
            '&:after': {
                content: "'No'",
                left: '0',
                color: theme.palette.secondary.contrastText,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: 'inherit',
            },
        },
        '&.Mui-disabled': {
            color: 'rgb(158, 203, 181) !important',
            pointerEvents: 'none',
            cursor: 'default',
            '& .MuiSwitch-thumb': {
                backgroundColor: 'rgb(158, 203, 181) !important',
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 67,
        height: 30,
        borderRadius: 0,
        background: theme.palette.primary.main,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'inherit',
        boxSizing: 'border-box',
    },
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({theme}) =>({
    [`& .${tooltipClasses.tooltip}`]: {
        // backgroundColor: theme.palette.common.white,
        // color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
        padding: 10,
        fontWeight: 500,
    },
}));

const InfoIcon = styled('img')(({ theme }) => ({
    display: 'revert',
    width: "16px",
    cursor:'pointer',
    [theme.breakpoints.down('md')]: {
        margin: '2px 0 0 -16px',
        width: "16px",
    }
}));

const InputBox = ({ value = '', setValue = () => {}, placeholder = '', error = '', isDisabled = false }) => {
    const [name, setName] = React.useState(value);

    return (
        <>
            <Input
                type='text'
                placeholder={placeholder}
                value={name}
                autoFocus
                error={error}
                disabled={isDisabled}
                inputProps={{ maxLength: 25 }}
                onChange={(e) => {
                    const {value} = e.target;
                    setName(value);
                    setValue(value);
                }}
                style={{ width: '80%' }}
            />
            {error && <p className='Mui-error'>{error}</p>}
        </>
    )
}

const addcss = {
    color: '#0F9672',
    cursor: 'pointer',
    textDecoration: 'underline'
};

const SubCategoriesList = ({ category, categoryGroupId, addSubCategory, updateSubCategoryData, onClickDeleteSubCategory, showUpdateManually, showDiscretionary, showIsIncludeInDebtPaymentReport }) => {
    const filteredSubCategories = category.subCategories.filter((subCat) => !subCat.isDefault);
    const { t } = useTranslation();

    if (!filteredSubCategories.length) {
        return (
            <tr>
                <td><Typography>{category.name}</Typography></td>
                <td>{''}</td>
                <td>
                    <Grid>
                        <Typography
                            style={addcss}
                            onClick={() => addSubCategory(categoryGroupId, category.id)}
                        >
                                Add Your Own
                        </Typography>
                    </Grid>
                </td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
            </tr>
        );
    }
    return (
        <>
            {
                filteredSubCategories.map((subCat, indexSubCat) => (
                    <tr key={subCat.id}>
                        <td>
                            {indexSubCat === 0 ?
                                <>
                                    <Typography>{category.name}</Typography>
                                </>
                                : <></>
                            }
                        </td>
                        <td>
                            <Grid mb={3} style={{ paddingLeft: 15 }}>
                                <CheckboxStyle disabled={subCat.isSelectionDisabled} checked={subCat.isSelected} onChange={() => {
                                    if (+subCat.subCatTransactionCount > 0) {
                                        toast(t('error-message.category-delete-error'), { type: 'error' });
                                        return;
                                    }
                                    updateSubCategoryData(categoryGroupId, category.id, subCat.id, 'isSelected', !subCat.isSelected)
                                }}
                                />
                            </Grid>
                        </td>
                        <td>
                            <Grid mb={3}>
                                <InputBox
                                    value={subCat.name === 'Cash' ? 'Chequing Account': subCat.name}
                                    placeholder={`${category.name}`}
                                    error={subCat.error}
                                    setValue={(name) => {
                                        updateSubCategoryData(categoryGroupId, category.id, subCat.id, 'name', name)
                                    }}
                                    isDisabled={subCat.isSelectionDisabled}
                                />
                            </Grid>
                        </td>
                        <td>
                            {
                                showUpdateManually && 
                                <Grid mb={3} style={{ paddingLeft: 15 }}>
                                    <SwitchStyle>
                                        <AntSwitch
                                            disabled={subCat.isSelectionDisabled}
                                            checked={!subCat.isUpdatedManually}
                                            onChange={(e) => {
                                                const {checked} = e.target;
                                                const value = !checked;
                                                updateSubCategoryData(categoryGroupId, category.id, subCat.id, 'isUpdatedManually', value)
                                            }}
                                            inputProps={{ 'aria-label': 'ant design' }}
                                        />
                                    </SwitchStyle>
                                </Grid>
                            }
                            {
                                showDiscretionary && 
                                <Grid mb={3} style={{ paddingLeft: 15 }}>
                                    <SwitchStyle>
                                        <AntSwitch
                                            checked={!subCat.isDiscretionary}
                                            onChange={(e) => {
                                                const {checked} = e.target;
                                                const value = !checked;
                                                updateSubCategoryData(categoryGroupId, category.id, subCat.id, 'isDiscretionary', value)
                                            }}
                                            inputProps={{ 'aria-label': 'ant design' }}
                                        />
                                    </SwitchStyle>
                                </Grid>
                            }
                            {
                                (!showUpdateManually && !showDiscretionary) && <>&nbsp;</>
                            }
                        </td>
                        <td>
                            {
                                showIsIncludeInDebtPaymentReport ? 
                                    <Grid mb={3} style={{ paddingLeft: 15 }}>
                                        <SwitchStyle>
                                            <AntSwitch
                                                checked={!subCat.isIncludedInDebtPaymentReport}
                                                onChange={(e) => {
                                                    const {checked} = e.target;
                                                    const value = !checked;
                                                    updateSubCategoryData(categoryGroupId, category.id, subCat.id, 'isIncludedInDebtPaymentReport', value)
                                                }}
                                                inputProps={{ 'aria-label': 'ant design' }}
                                            />
                                        </SwitchStyle>
                                    </Grid> : <>&nbsp;</>
                            }
                            
                        </td>
                        <td style={{ width: '10%', textAlign: 'center' }}>
                            {subCat.isSelectionDisabled ? <>&nbsp;</> :
                                <Iconify
                                    icon={'ri:delete-bin-6-fill'}
                                    width={22}
                                    height={22}
                                    className="delete-icon"
                                    style={{ marginLeft: 32, cursor: 'pointer' }}
                                    onClick={() => {
                                        if (+subCat.subCatTransactionCount > 0) {
                                            toast(t('error-message.category-delete-error'), { type: 'error' });
                                            return;
                                        }
                                        onClickDeleteSubCategory({categoryGroupId, categoryId: category.id, subCatId: subCat.id})
                                    }}
                                />}
                        </td>
                    </tr>
                ))
            }
            <tr>
                <td>{''}</td>
                <td>{''}</td>
                <td>
                    <Grid>
                        <Typography
                            style={addcss}
                            onClick={() => addSubCategory(categoryGroupId, category.id)}
                        >
                                        Add Your Own
                        </Typography>
                    </Grid>
                </td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
            </tr>
        </>
        
    );
};

export default function SimpleAccordion(props) {
    const { categories, categoryGroupId, addSubCategory, updateSubCategoryData, showUpdateManually, showDiscretionary, onClickDeleteSubCategory, showIsIncludeInDebtPaymentReport } = props;

    return (
        <TableScroll container p={0}>
            <TableStyle>
                <thead style={{position: 'sticky',backgroundColor: '#f8f8f8',zIndex: '9', top: '60px'}}>
                    <tr>
                        <th style={{ width: '20%' }}>Category</th>
                        <th style={{ width: '10%' }}>Select</th>
                        <th style={{ width: '25%' }}>
                            <div style={{ display: 'flex' }}>
                                <p>Sub-Category</p>
                                <LightTooltip style={{ marginLeft: 5 }} title={<>You can rename the sub-categories as appropriate.</>}>
                                    <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" />
                                </LightTooltip>
                            </div>

                        </th>
                        <th style={{ width: '20%' }}>
                            {
                                showUpdateManually &&
                                    <div style={{ display: 'flex' }}>
                                        <p>Update Manually?</p>
                                        <LightTooltip style={{ marginLeft: 5 }} title={<>Select No if transactions will be downloaded from a linked account, otherwise select Yes and the system will create an account for you to track it manually.</>}>
                                            <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" />
                                        </LightTooltip>
                                    </div>
                            }
                            {
                                showDiscretionary &&
                                    <div style={{ display: 'flex' }}>
                                        <p>Variable</p>
                                        <LightTooltip style={{ marginLeft: 5 }} title={<>A Variable expense is something that is non- essential (like vacation) or something that is controllable in the short term (like entertainment or groceries). We have marked each expense/outflow as variable or not.  Feel free to change them based on your own assessment.</>}>
                                            <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" />
                                        </LightTooltip>
                                    </div>
                            }
                            { (!showUpdateManually && !showDiscretionary) && <>&nbsp;</>}
                        </th>
                        <th style={{ width: '15%' }}>
                            {
                                showIsIncludeInDebtPaymentReport ?
                                    <div style={{ display: 'flex' }}>
                                        <p>Include in Debt Payment Report?</p>
                                        <LightTooltip style={{ marginLeft: 5 }} title={<>Accept setting or Select Yes if you want this sub-category to be included in your Debt Payments Report</>}>
                                            <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" />
                                        </LightTooltip>
                                    </div> :
                                    <>&nbsp;</>
                            }
                        </th>
                        <th style={{ width: '10%' }}>&nbsp;</th>
                    </tr>
                </thead>
                <tbody style={{position: 'sticky'}}>
                    {categories.filter(cat => cat.isSelected).map((category) =>
                        <SubCategoriesList
                            key={category.id}
                            category={category}
                            categoryGroupId={categoryGroupId}
                            addSubCategory={addSubCategory}
                            updateSubCategoryData={updateSubCategoryData}
                            onClickDeleteSubCategory={onClickDeleteSubCategory}
                            showUpdateManually={showUpdateManually}
                            showDiscretionary={showDiscretionary}
                            showIsIncludeInDebtPaymentReport={showIsIncludeInDebtPaymentReport}
                        />
                    )}
                </tbody>
            </TableStyle>
        </TableScroll >
    );
}
