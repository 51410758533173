import image from "assets/image";

const getGeneralMenu = (t) => ([
    {
        id: 1,
        link: '/contact-us-home',
        text: t('footer.text1'),
    },
    {
        id: 2,
        text: t('footer.text2'),
        onClickHandler: () => {}
    },
    {
        id: 3,
        link: '/#reviews',
        text: t('footer.text3')
    },
    {
        id: 4,
        link: '/newsroom',
        text: t('footer.text27'),
    },
]);

const getCalculatorsMenu = (t) => ([
    {
        id: 1,
        link: '/calculators-hub/mortgage-payment',
        text: t('footer.text4'),
    },
    {
        id: 2,
        link: '/calculators-hub/mortgage-prepayment',
        text: t('footer.text5')
    },
    {
        id: 3,
        link: '/calculators-hub/loan-payment',
        text: t('footer.text6')
    },
    {
        id: 4,
        link: '/calculators-hub/lease-payment',
        text: t('footer.text7')
    },
    {
        id: 5,
        link: '/calculators-hub/debt-repayment',
        text: t('footer.text8')
    },
    {
        id: 6,
        link: '/calculators-hub/embedded-rate',
        text: t('footer.text9')
    },
    {
        id: 7,
        link: '/calculators-hub/return-on-investment',
        text: t('footer.text10')
    },
    {
        id: 8,
        link: '/calculators-hub/future-value-savings',
        text: t('footer.text11')
    },
    {
        id: 9,
        link: '/calculators-hub/present-value',
        text: t('footer.text24')
    },
    {
        id: 10,
        link: '/calculators-hub/emergency-fund',
        text: t('footer.text12')
    },
    {
        id: 11,
        link: '/calculators-hub/savings-goal',
        text: t('footer.text13')
    },
    {
        id: 12,
        link: '/calculators-hub/retirement',
        text: t('footer.text14')
    },
]);

const getResourcesMenu = (t) => ([
    {
        id: 1,
        link: '/learn',
        text: t('footer.text15'),
    },
    {
        id: 2,
        link: '/frequently-asked-questions',
        text: t('footer.text16'),
    },
    {
        id: 3,
        link: '/mmAndOthers',
        text: t('footer.text17'),
    },
    {
        id: 4,
        link: '/how-it-works',
        text: t('footer.text25'),
    },
    {
        id: 5,
        link: '/about-us',
        text: t('footer.text26'),
    },
]);

const getHelpMenu = (t) => ([
    {
        id: 1,
        link: '/frequently-asked-questions',
        text: t('footer.text18'),
    },
    {
        id: 2,
        link: '/frequently-asked-questions',
        text: t('footer.text19'),
    },
]);

const socialLinks = [
    {
        id: 1,
        url: 'https://www.facebook.com/profile.php?id=61552403278053',
        icon: image.facebook,
        title: 'Facebook'
    },
    {
        id: 2,
        url: 'https://www.instagram.com/moolahmate/',
        icon: image.instagram,
        title: 'Instagram'
    },
    {
        id: 3,
        url: 'https://www.linkedin.com/company/moolahmate-ventures-inc',
        icon: image.linkedin,
        title: 'Linkedin'
    },
    {
        id: 4,
        url: '#',
        icon: image.youtube,
        title: 'YouTube'
    },
    {
        id: 5,
        url: '#',
        icon: image.twitter,
        title: 'X (formerly Twitter)'
    }
];

const getFooterLinks = (t) => ([
    {
        id: 1,
        url: '/terms-and-conditions',
        text: t('footer.text21')
    },
    {
        id: 2,
        url: '/privacy-policy',
        text: t('footer.text22')
    },
    {
        id: 3,
        url: '#',
        text: t('footer.text23')
    }
]);

export {
    getGeneralMenu, 
    getCalculatorsMenu, 
    getResourcesMenu, 
    getHelpMenu,
    socialLinks,
    getFooterLinks,
}