// @mui
import { Box } from '@mui/material';
import { Container } from '@mui/system';
import { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

// components
import Page from '../../components/Page';
import TopHeading from '../../components/TopHeading';
import TopMenu from '../../components/TopMenu';
import newsroom from '../../constants/services/newsroom';
import transform from '../../constants/strapiHelper';

export default function NewsroomDetailPage() {
    const BLANK_BLOG = {
        title: '',
        content: '',
    };
    const [blog, setBlog] = useState(BLANK_BLOG);
    const [isLogged, setIsLogged] = useState(false)
    const { newsId } = useParams();

    const getBlog = async (newsId) => {
        if (newsId === '') {
            setBlog(BLANK_BLOG);
            return;
        }
        const blogsData = await newsroom.getNewsDetail(newsId);
        let blogsDetail = BLANK_BLOG;
        if (blogsData.data && blogsData.data.length > 0) {
            blogsDetail = {
                title: blogsData.data[0].attributes.title,
                topic: blogsData.data[0].attributes.topic.data.attributes.title,
                headerImage: blogsData.data[0].attributes.headerImage,
                content: blogsData.data[0].attributes.content,
            };
        }
        setBlog(blogsDetail);
    };

    useEffect(() => {
        getBlog(newsId);
    }, [newsId]);

    useEffect(() => {
        if (sessionStorage.getItem('userData')) {
            const user = JSON.parse(sessionStorage.getItem('userData'));
            if (Object.keys(user).length > 0) {
                setIsLogged(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { t } = useTranslation();

    return (
        <Page title={"Discover the latest at MoolahMate's newsroom details"} description={"Discover the latest with MoolahMate at MoolahMate's newsroom. Sign up today to see how you can save more, spend less and reduce your financial stress."} style={isLogged ? {} : {padding: '110px 8% 0px'}}>
            {isLogged && <TopMenu title={`Knowledge Base`} />}
            <TopHeading heading={blog.title} headingCenter={!isLogged} />
            <div className='contentStyle' style={{ paddingTop: '0', paddingRight: '4%' }}>
                <Container style={{padding: '0px'}}>
                    <Box className="knowledgeBaseStyle customSliderStyle">
                        {ReactHtmlParser(blog.content, { transform: (node, index) => transform(node) })}
                    </Box>
                </Container>
            </div>
        </Page>
    );
}
