import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled, Autocomplete, TextField, Grid, Button } from '@mui/material';
import Page from '../../../components/Page';
import { ContentStyle, Wrapper, PageTopRightBg, templateOptions } from './styled-components';
import HouseView from './house';
import InvestmentView from './investments';
import DebtView from './debt';
import VehiclesView from './vehicles';
import OthersView from './others';
import Title from '../Title';
import transaction from '../../../constants/services/transaction';
import { getCurrencySymbol, goBackToMenu } from '../../../utils/calCommonFunction';
import TopHeading from '../../../components/TopHeading';
import TopMenu from '../../../components/TopMenu';

const InputLabel = styled('div')(({ theme }) => ({
    color: '#4B4B4B',
    fontSize: '16px',
    fontWeight: 500,
    marginTop: '10px',
    whiteSpace: 'normal',
    overflow: 'auto',
    textOverflow: 'unset',
    [theme.breakpoints.up('xl')]: {
        fontSize: 20,
    },
}));

const BackButtonStyle = styled(Button)(({ ...props }) => ({
    color: '#fff',
    padding: '5px 10px',
    fontSize: '13px',
    fontWeight: '300',
    textAlign: 'left',
    textTransform: 'none',
    ...props
}))

const AutoFieldStyle = styled(Autocomplete)(({ theme }) => ({
    fontSize: 20,
    paddingBottom: '8px',
    [theme.breakpoints.up('llg')]: {
        paddingBottom: '30px',
    },
    '& input': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
    '& .MuiInputBase-root': {
        '&:hover:before': {
            borderBottom: '0 !important',
        },
        '&.Mui-focused:after': {
            transform: 'scaleX(0) !important',
        },
    },
    '& .MuiAutocomplete-popupIndicator': {
        position: 'relative',
        width: 25,
        '& svg': {
            display: 'none',
        },
        '&:after': {
            position: 'absolute',
            right: 0,
            top: -4,
            content: "''",
            background: 'url(/static/loginImg/down-arrow.svg) 0 0 no-repeat',
            width: 25,
            height: 13,
        },
    },
}));

export default function ViewManualTransactions() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [selectedTemplate, setSelectedTemplate] = useState(templateOptions[0]);
    const location = useLocation();
    const isReadOnly = location?.state?.readOnly || false;

    const onTemplateChange = (event, value) => {
        setSelectedTemplate(value);
    };

    const saveTemplate = async () => {
        const res = await transaction.saveTemplate(location?.state?.id, { template: selectedTemplate?.value });
        if (res && res?.status === 'SUCCESS') {
            navigate('/edit-manual-transactions', { state: location.state });
        }
    };

    return (
        <div style={{
            boxSizing: 'border-box',
            flexDirection: 'row',
            color: 'rgb(75, 75, 75)',
            padding: '0px'
        }}>
            <Page title={t('view-manual-transactions.title')}>
                <TopMenu title={t(isReadOnly ? 'view-manual-transactions.preview-template' : 'view-manual-transactions.template-preview')} />
                <TopHeading
                    heading={t(isReadOnly ? 'view-manual-transactions.preview-template' : 'view-manual-transactions.template-preview')}
                />
                <ContentStyle templateName={selectedTemplate?.value}>
                    <Grid container spacing={2} pt={2}>
                        <Grid item lg={3} sm={4} xs={6}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                {t('view-manual-transactions.preview-template')}
                            </InputLabel>
                        </Grid>
                        <Grid item lg={3} sm={4} xs={6}>
                            <AutoFieldStyle
                                options={templateOptions}
                                value={selectedTemplate}
                                getOptionLabel={(option) => (option?.label || '')}
                                disableClearable
                                onChange={onTemplateChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder={t('view-manual-transactions.template-preview')}
                                        className="dropMenu"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    {selectedTemplate?.value === 'HOUSE' && <HouseView currencySymbol={getCurrencySymbol()} />}
                    {selectedTemplate?.value === 'INVESTMENT' && <InvestmentView currencySymbol={getCurrencySymbol()} />}
                    {selectedTemplate?.value === 'VEHICLE' && <VehiclesView currencySymbol={getCurrencySymbol()} />}
                    {selectedTemplate?.value === 'DEBT' && <DebtView currencySymbol={getCurrencySymbol()} />}
                    {selectedTemplate?.value === 'OTHERS' && <OthersView currencySymbol={getCurrencySymbol()} />}
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '32px' }}>
                        {
                            goBackToMenu(navigate)
                        }
                        {!isReadOnly && <Button
                            variant="contained"
                            style={{ textTransform: 'none' }}
                            className='loadingButtonStyle2'
                            onClick={saveTemplate}>
                            {t('view-manual-transactions.select-template')}
                        </Button>}
                    </div>
                </ContentStyle>
            </Page>
        </div>
    );
}
