import { Grid, Typography } from '@mui/material';
import videoURL from 'constants/helpVideoUrl';
import TopMenu from '../../../../../components/TopMenu';
import { Wrapper } from '../../../setup/styled-components';
import DetailForm from './DetailsForm';

export default function Step1(props) {

    return (
        <>
            <div className='mdCustomPadding'>
                <TopMenu title='Login Details' hideHelp videoLink={videoURL.step1Video} />
            </div>
            <Wrapper>
                <Typography variant="h4" className='titleStyle margintitleStyle'>Add login details</Typography>
                <Grid container item style={{marginTop: 20}}>
                    <Grid item xs={12}>
                        <DetailForm
                            nextStep={props.onNext}
                            updateList={(list) => {
                                props.onsave('step5', list);
                            }}
                        />
                    </Grid>
                </Grid>
            </Wrapper>
        </>
    );
}