/* eslint-disable react-hooks/rules-of-hooks */
import { Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Page from '../../../components/Page';
import TopMenu from '../../../components/TopMenu'
import {OnboardingDivStyle, FormDivStyle  } from '../../register/styled-components';
import { ChangePassword } from '../../../sections/auth/change-password/index';

export default function index() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    return (
        <Page title={'Change Password'}>
            <div>
                <TopMenu title={'Change Password'} />
                <Grid container justifyContent={'center'} spacing={2} marginLeft={0} width={'100%'} marginTop={0}> 
                    <Grid item xs={12} style={{ padding: '0px 0px 0px 4%' }}>
                        <Button className='backToMenuStyle' style={{marginBottom: '32px'}} onClick={() => navigate(-1)}><ArrowBackIcon /> Back</Button>
                    </Grid>
                    <Grid item xl={5} lg={5} md={10} xs={12} style={{padding: 0}}>
                        <OnboardingDivStyle>
                            <FormDivStyle>
                                <ChangePassword />
                            </FormDivStyle>
                        </OnboardingDivStyle>
                    </Grid>
                </Grid>
            </div>
        </Page>
    );
}
  