import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import dashboard from '../../../constants/services/dashboard';
import { CHARTCOLOR } from '../constant';
import { EXCEL_STYLE } from '../../calculators/constant';
import { exportCustomExcel, getCurrencySymbol, getPositiveNumber } from '../../../utils/calCommonFunction';
import {
    TotalFlow,
    NoDataTd,
    TableStyle,
    GridSpacing,
    ExecutiveTbEnd,
    ExecutiveDivStyle,
    ExecutiveDivSpacing,
    ExecutiveNetDivStyle,
    TotalAmountDiv,
    TotalFlowValueColumn,
    ButtonDivStyle,
    TableThHeading,
    SummaryFirstDiv,
    SummarySecondDiv,
    SummaryLastDiv,
    LoaderGif,
} from './styled-component';
import Bargraph from '../Graphs/Bargraph';

export const ExecutiveTableSummary = () => {
    const [showGraph, setShowGraph] = useState(true);
    const [tableLoader, setTableLoader] = useState(false);
    const [assetChartData, setAssetChartData] = useState([]);
    const [inFlowChartData, setInFlowChartData] = useState([]);
    const [executiveSummary, setExecutiveSummary] = useState([]);
    const [outFlowChartData, setOutflowChartData] = useState([]);
    const [liabilitiesChartData, setLiabilitiesChartData] = useState([]);

    const currencySymbol = getCurrencySymbol();
    const { t } = useTranslation();

    const totalCashOutflowsMonth = executiveSummary['Expenses/Cash Outflows']?.reduce(
        (acc, obj) => acc + Number(obj?.amount),
        0
    );
    const totalCashInflowsMonth = executiveSummary['Income/Cash Inflows']?.reduce(
        (acc, obj) => acc + Number(obj?.amount),
        0
    );
    const TotalnetCashInfo = totalCashInflowsMonth - totalCashOutflowsMonth;

    const totalAssetMonth = executiveSummary.Assets?.reduce((acc, obj) => acc + Number(obj?.amount), 0);
    const totalLiabilitiesMonth = executiveSummary.Liabilities?.reduce((acc, obj) => acc + Number(obj?.amount), 0);
    const TotalnetWorthInfo = totalAssetMonth - totalLiabilitiesMonth;

    const getExecutiveData = async () => {
        setTableLoader(true);
        try {
            const response = await dashboard.getExecutivesummary();
            const transformedData = transformExecutiveData(response?.data || []);
            setExecutiveSummary(transformedData);
            const inflowChart = getExecutiveChartData(
                transformedData,
                'Income/Cash Inflows',
                t('executive-summary.top-3-income-month')
            );
            const outflowChart = getExecutiveChartData(
                transformedData,
                'Expenses/Cash Outflows',
                t('executive-summary.top-3-expense-month')
            );
            const assetChart = getExecutiveChartData(transformedData, 'Assets', t('executive-summary.top-3-asset'));
            const liabilitiesChart = getExecutiveChartData(
                transformedData,
                'Liabilities',
                t('executive-summary.top-3-liabilites')
            );
            setTableLoader(false);
            setInFlowChartData(inflowChart);
            setOutflowChartData(outflowChart);
            setAssetChartData(assetChart);
            setLiabilitiesChartData(liabilitiesChart);
        } catch (error) {
            setTableLoader(false);
            console.log(error);
        }
    };

    const getExecutiveChartData = (data, groupname, titlename) => {
        // eslint-disable-next-line array-callback-return
        const labels = data[groupname]?.map((item) => item.name || item.category_name);
        const outflowValues = data[groupname]?.map((item) => {
            let { amount } = item;
            if (titlename.includes('Expense')) {
                amount = Math.abs(Number(amount));
            }
            return amount;
        });
        const dataset = [];

        labels?.forEach((item, index) => {
            const dataitem = { label: labels[index], data: [], backgroundColor: CHARTCOLOR[index] };
            dataset.push(dataitem);
        });
        for (let i = 0; i <= outflowValues?.length - 1; i += 1) {
            const monthData = outflowValues[i];
            dataset[i].data.push(monthData);
        }
        return { labels: [''], dataset, title: titlename };
    };

    const getOtherComposition = (assets) => {
        let total = 0;
        if (assets?.length > 3) {
            assets?.slice(3, assets.length).forEach((asset) => {
                total += Math.abs(Number(asset.values));
            });
        }
        return total;
    };

    const transformExecutiveData = (data) => {
        let transformedData = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(data)) {
            let dataValue = value.slice(0, 3).map((val) => ({
                name: val.category_name || val.name,
                amount: Math.abs(Number(val.values)),
            }));
            dataValue = dataValue.sort((a, b) => b.amount - a.amount);
            dataValue.push({
                name: t('executive-summary.all-other'),
                amount: getOtherComposition(value),
            });
            transformedData = {
                ...transformedData,
                [key]: dataValue,
            };
        }
        return transformedData;
    };

    function biggerLength(array1, array2) {
        if (array1.length > array2.length) {
            return array1.length;
        }
        if (array2.length > array1.length) {
            return array2.length;
        }
        if (array2.length === array1.length) {
            return array1.length;
        }
    }

    const exportExcel = () => {
        const whatIOwn = executiveSummary.Assets.map((item) => ({
            Name: item.name,
            Amount: `${currencySymbol}${getPositiveNumber(item.amount, true, false)}`,
        }));

        whatIOwn.unshift({ Name: 'What I Own', Amount: '' }, { Name: 'Name', Amount: 'Amount' });
        whatIOwn.push(
            { Name: 'Total Assets', Amount: `${currencySymbol}${getPositiveNumber(totalAssetMonth, true, false)}` },
            { Name: '', Amount: '' }
        );

        const incomeThisMonth = executiveSummary['Income/Cash Inflows'].map((item) => ({
            Name: item.name,
            Amount: `${currencySymbol}${getPositiveNumber(item.amount, true, false)}`,
        }));

        incomeThisMonth.unshift({ Name: 'Income This Month', Amount: '' }, { Name: 'Name', Amount: 'Amount' });
        incomeThisMonth.push(
            {
                Name: 'Total Income',
                Amount: `${currencySymbol}${getPositiveNumber(totalCashInflowsMonth, true, false)}`,
            },
            { Name: '', Amount: '' }
        );

        const whatIOwe = executiveSummary.Liabilities.map((item) => ({
            Name: item.name,
            Amount: `${currencySymbol}${getPositiveNumber(item.amount, true, false)}`,
        }));

        whatIOwe.unshift({ Name: 'What I Owe', Amount: '' }, { Name: 'Name', Amount: 'Amount' });
        whatIOwe.push(
            {
                Name: 'Total Liabilities',
                Amount: `${currencySymbol}${getPositiveNumber(totalLiabilitiesMonth, true, false)}`,
            },
            { Name: '', Amount: '' }
        );

        const expenseThisMonth = executiveSummary['Expenses/Cash Outflows'].map((item) => ({
            Name: item.name,
            Amount: `${currencySymbol}${getPositiveNumber(item.amount, true, false)}`,
        }));

        expenseThisMonth.unshift({ Name: 'Expense This Month', Amount: '' }, { Name: 'Name', Amount: 'Amount' });
        expenseThisMonth.push(
            {
                Name: 'Total Expenses',
                Amount: `${currencySymbol}${getPositiveNumber(totalCashOutflowsMonth, true, false)}`,
            },
            { Name: '', Amount: '' }
        );

        const stRow = Number(biggerLength(whatIOwn, incomeThisMonth) + 1);
        const stRowTotal = Number(biggerLength(whatIOwe, expenseThisMonth) + stRow);
        const style = [
            {
                col: 'A1',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'D1',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: `A${stRow}`,
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: `D${stRow}`,
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: `A${stRowTotal}`,
                style: EXCEL_STYLE.boldColor,
            },
            {
                col: `B${stRowTotal}`,
                style: EXCEL_STYLE.boldColor,
            },
            {
                col: `D${stRowTotal}`,
                style: EXCEL_STYLE.boldColor,
            },
            {
                col: `E${stRowTotal}`,
                style: EXCEL_STYLE.boldColor,
            },
        ];
        const cols = [{ width: 15 }, { width: 15 }, { width: 10 }, { width: 18 }, { width: 15 }];

        const totalNetWorth = [
            {
                Name: 'Net Worth',
                Amount: `${currencySymbol}${getPositiveNumber(TotalnetWorthInfo, true, false)}`,
                gap: '',
                Name1: 'Net Income (Loss)',
                Amount2: `${currencySymbol}${getPositiveNumber(TotalnetCashInfo, true, false)}`,
            },
        ];

        const merges = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 1 } },
            { s: { r: 0, c: 3 }, e: { r: 0, c: 4 } },
            { s: { r: stRow - 1, c: 0 }, e: { r: stRow - 1, c: 1 } },
            { s: { r: stRow - 1, c: 3 }, e: { r: stRow - 1, c: 4 } },
        ];

        exportCustomExcel(
            [whatIOwn, incomeThisMonth, whatIOwe, expenseThisMonth, totalNetWorth],
            style,
            cols,
            merges,
            'ExecutiveTableSummary',
            7,
            ['I'],
            [true, true, true, true, true],
            [-1, 'D1', -1, `D${stRow}`, -1]
        );
    };

    useEffect(() => {
        getExecutiveData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getExecutiveTable = (data) => {
        const tableData = data.map((item, index) => (
            <tr key={index} height="10px">
                <td>{item.name}</td>
                <ExecutiveTbEnd>
                    <TotalAmountDiv>
                        {currencySymbol} <span>{getPositiveNumber(item.amount, true, false)}</span>
                    </TotalAmountDiv>
                </ExecutiveTbEnd>
            </tr>
        ));
        while (tableData.length < 4) {
            tableData.push(
                <tr height="10px">
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
            );
        }
        return tableData;
    };

    return (
        <>
            {showGraph ? (
                <ExecutiveDivStyle>
                    <ExecutiveDivSpacing>
                        <Grid
                            container
                            spacing={4}
                            justifyContent="center"
                            alignItems="flex-start"
                            style={{ margin: '0px', width: '100%' }}
                        >
                            <GridSpacing item xs={12} sm={12} md={6} className="overFlowTable">
                                <SummaryFirstDiv>
                                    <TableStyle>
                                        <thead>
                                            <tr>
                                                <TableThHeading colSpan={2}>
                                                    <h4>{t('executive-summary.what-own-today')}</h4>
                                                </TableThHeading>
                                            </tr>
                                        </thead>
                                        {tableLoader ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan={4} style={{ verticalAlign: 'inherit' }}>
                                                        <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <tbody>
                                                {executiveSummary.Assets?.length ? (
                                                    <>
                                                        {getExecutiveTable(executiveSummary.Assets)}
                                                        <tr height="10px">
                                                            <TotalFlow style={{ verticalAlign: 'bottom' }}>
                                                                {t('executive-summary.total-asset')}
                                                            </TotalFlow>
                                                            <TotalFlowValueColumn
                                                                style={{ height: '100%', alignItems: 'end' }}
                                                            >
                                                                <TotalAmountDiv>
                                                                    {currencySymbol}{' '}
                                                                    <span>
                                                                        {getPositiveNumber(
                                                                            totalAssetMonth,
                                                                            true,
                                                                            false
                                                                        )}
                                                                    </span>
                                                                </TotalAmountDiv>
                                                            </TotalFlowValueColumn>
                                                        </tr>
                                                    </>
                                                ) : (
                                                    <tr>
                                                        <NoDataTd>{t('executive-summary.no-data-found')}</NoDataTd>
                                                    </tr>
                                                )}
                                            </tbody>
                                        )}
                                    </TableStyle>
                                </SummaryFirstDiv>
                            </GridSpacing>
                            <GridSpacing item xs={12} sm={12} md={6} className="overFlowTable">
                                <SummarySecondDiv>
                                    <TableStyle>
                                        <thead>
                                            <tr>
                                                <TableThHeading colSpan={2}>
                                                    <h4>{t('executive-summary.income-this-month')}</h4>
                                                </TableThHeading>
                                            </tr>
                                        </thead>
                                        {tableLoader ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan={4} style={{ verticalAlign: 'inherit' }}>
                                                        <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <tbody>
                                                {executiveSummary['Income/Cash Inflows']?.length ? (
                                                    <>
                                                        {getExecutiveTable(executiveSummary['Income/Cash Inflows'])}
                                                        <tr height="10px">
                                                            <TotalFlow style={{ verticalAlign: 'bottom' }}>
                                                                {t('executive-summary.total-cash-inflow')}
                                                            </TotalFlow>
                                                            <TotalFlowValueColumn
                                                                style={{ height: '100%', alignItems: 'end' }}
                                                            >
                                                                <TotalAmountDiv>
                                                                    {currencySymbol}{' '}
                                                                    <span>
                                                                        {getPositiveNumber(
                                                                            totalCashInflowsMonth,
                                                                            true,
                                                                            false
                                                                        )}
                                                                    </span>
                                                                </TotalAmountDiv>
                                                            </TotalFlowValueColumn>
                                                        </tr>
                                                    </>
                                                ) : (
                                                    <tr>
                                                        <NoDataTd>{t('executive-summary.no-data-found')}</NoDataTd>
                                                    </tr>
                                                )}
                                            </tbody>
                                        )}
                                    </TableStyle>
                                </SummarySecondDiv>
                            </GridSpacing>
                            <GridSpacing item xs={12} sm={12} md={6} className="overFlowTable">
                                <div style={{ marginLeft: '20px', marginTop: '20px', paddingRight: '20px' }}>
                                    <TableStyle>
                                        <thead>
                                            <tr>
                                                <TableThHeading colSpan={2} style={{ verticalAlign: 'inherit' }}>
                                                    <h4>{t('executive-summary.what-owe-today')}</h4>
                                                </TableThHeading>
                                            </tr>
                                        </thead>
                                        {tableLoader ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan={4}>
                                                        <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <tbody>
                                                {executiveSummary.Liabilities?.length ? (
                                                    <>
                                                        {getExecutiveTable(executiveSummary.Liabilities)}
                                                        <tr height="10px">
                                                            <TotalFlow style={{ verticalAlign: 'bottom' }}>
                                                                {t('executive-summary.total-liabilities')}
                                                            </TotalFlow>
                                                            <TotalFlowValueColumn
                                                                style={{ height: '100%', alignItems: 'end' }}
                                                            >
                                                                <TotalAmountDiv>
                                                                    {currencySymbol}{' '}
                                                                    <span>
                                                                        {getPositiveNumber(
                                                                            totalLiabilitiesMonth,
                                                                            true,
                                                                            false
                                                                        )}
                                                                    </span>
                                                                </TotalAmountDiv>
                                                            </TotalFlowValueColumn>
                                                        </tr>
                                                    </>
                                                ) : (
                                                    <tr>
                                                        <NoDataTd>{t('executive-summary.no-data-found')}</NoDataTd>
                                                    </tr>
                                                )}
                                            </tbody>
                                        )}
                                    </TableStyle>
                                </div>
                            </GridSpacing>
                            <GridSpacing item lg={6} xs={12} sm={12} md={6} className="overFlowTable">
                                <SummaryLastDiv>
                                    <TableStyle>
                                        <thead>
                                            <tr>
                                                <TableThHeading colSpan={2}>
                                                    <h4>{t('executive-summary.expenses-this-month')}</h4>
                                                </TableThHeading>
                                            </tr>
                                        </thead>
                                        {tableLoader ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan={4} style={{ verticalAlign: 'inherit' }}>
                                                        <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <tbody>
                                                {executiveSummary['Expenses/Cash Outflows']?.length ? (
                                                    <>
                                                        {getExecutiveTable(executiveSummary['Expenses/Cash Outflows'])}
                                                        <tr height="10px">
                                                            <TotalFlow style={{ verticalAlign: 'bottom' }}>
                                                                {t('executive-summary.total-cash-outflow')}
                                                            </TotalFlow>
                                                            <TotalFlowValueColumn
                                                                style={{ height: '100%', alignItems: 'end' }}
                                                            >
                                                                <TotalAmountDiv>
                                                                    {currencySymbol}{' '}
                                                                    <span>
                                                                        {getPositiveNumber(
                                                                            totalCashOutflowsMonth,
                                                                            true,
                                                                            false
                                                                        )}
                                                                    </span>
                                                                </TotalAmountDiv>
                                                            </TotalFlowValueColumn>
                                                        </tr>
                                                    </>
                                                ) : (
                                                    <tr>
                                                        <NoDataTd>{t('executive-summary.no-data-found')}</NoDataTd>
                                                    </tr>
                                                )}
                                            </tbody>
                                        )}
                                    </TableStyle>
                                </SummaryLastDiv>
                            </GridSpacing>
                        </Grid>
                    </ExecutiveDivSpacing>
                    <ExecutiveNetDivStyle>
                        <Grid
                            container
                            spacing={4}
                            justifyContent="center"
                            alignItems="flex-start"
                            style={{ marginTop: '0px' }}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                className="overFlowTable"
                                style={{ paddingRight: '18px', paddingTop: '0px' }}
                            >
                                <TableStyle style={{ padding: '0px 16px' }}>
                                    <tbody>
                                        <tr>
                                            <TotalFlow style={{ paddingLeft: '0px' }}>
                                                {t('executive-summary.net-worth')}
                                            </TotalFlow>
                                            <TotalFlowValueColumn>
                                                <TotalAmountDiv style={{ fontSize: 18 }}>
                                                    {currencySymbol}
                                                    <span>
                                                        {TotalnetWorthInfo === 'NaN'
                                                            ? '0'
                                                            : getPositiveNumber(TotalnetWorthInfo, true, false)}
                                                    </span>
                                                </TotalAmountDiv>
                                            </TotalFlowValueColumn>
                                        </tr>
                                    </tbody>
                                </TableStyle>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                className="overFlowTable"
                                style={{ paddingRight: '0px', paddingTop: '0px' }}
                            >
                                <TableStyle style={{ paddingLeft: '46px' }}>
                                    <tbody>
                                        <tr>
                                            <TotalFlow style={{ paddingLeft: '0px' }}>
                                                {t('executive-summary.net-cash-inflow')}
                                            </TotalFlow>
                                            <TotalFlowValueColumn>
                                                <TotalAmountDiv style={{ fontSize: 18 }}>
                                                    {currencySymbol}
                                                    <span>
                                                        {TotalnetCashInfo === 'NaN'
                                                            ? '0'
                                                            : getPositiveNumber(TotalnetCashInfo, true, false)}
                                                    </span>
                                                </TotalAmountDiv>
                                            </TotalFlowValueColumn>
                                        </tr>
                                    </tbody>
                                </TableStyle>
                            </Grid>
                        </Grid>
                    </ExecutiveNetDivStyle>
                </ExecutiveDivStyle>
            ) : (
                <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{ margin: '0px', width: '100%' }}
                >
                    <Grid item xs={12} sm={6} md={6} style={{ padding: '0px 16px 0px 0px' }}>
                        <Bargraph chartData={assetChartData} currencyCode={currencySymbol} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ padding: '0px 0px 0px 16px' }}>
                        <Bargraph chartData={liabilitiesChartData} currencyCode={currencySymbol} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ padding: '0px 16px 0px 0px' }}>
                        <Bargraph chartData={inFlowChartData} currencyCode={currencySymbol} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ padding: '0px 0px 0px 16px' }}>
                        <Bargraph chartData={outFlowChartData} currencyCode={currencySymbol} />
                    </Grid>
                </Grid>
            )}
            <ButtonDivStyle>
                <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={() => setShowGraph(!showGraph)}
                    className="loadingButtonStyle2"
                >
                    {showGraph ? t('executive-summary.graph') : t('executive-summary.view-table')}
                </LoadingButton>
                <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={exportExcel}
                    className="loadingButtonStyle2"
                >
                    Export
                </LoadingButton>
            </ButtonDivStyle>
        </>
    );
};
