import image from 'assets/image';
import videoURL from 'constants/helpVideoUrl';

const getHubCards = (t) => ({
    [t('hub-menu-items.start-here')]: [
        {
            title: t('hub-menu-items.transactions'),
            description: t('hub-menu-items.transactions-description'),
            path: '/downloaded-transactions',
            icon: image.transactions,
            helpVideo: videoURL.transactions
        },
        {
            title: t('hub-menu-items.other-transactions'),
            description: t('hub-menu-items.other-transactions-description'),
            path: '/manual-transactions',
            icon: image.otherTransactions,
            helpVideo: videoURL.otherTransactions
        },
        {
            title: t('hub-menu-items.executive-summary'),
            description: t('hub-menu-items.executive-summary-description'),
            path: '/dashboard',
            icon: image.dashboard,
            helpVideo: videoURL.executiveSummary
        },
        {
            title: t('hub-menu-items.reports'),
            description: t('hub-menu-items.reports-description'),
            path: '/dashboard/all-reports',
            icon: image.report,
            helpVideo: videoURL.reports
        },
    ],
    [t('hub-menu-items.get-organized')]: [
        {
            title: t('hub-menu-items.to-do'),
            description: t('hub-menu-items.to-do-description'),
            path: '/todos',
            icon: image.todoIcon,
            helpVideo: videoURL.todo
        },
        {
            title: t('hub-menu-items.key-dates'),
            description: t('hub-menu-items.key-dates-description'),
            path: '/key-dates',
            icon: image.keyDateIcon1,
            helpVideo: videoURL.keyDates
        },
        {
            title: t('hub-menu-items.my-settings'),
            description: t('hub-menu-items.my-settings-description'),
            path: '/my-profile',
            icon: image.mySettings,
            helpVideo: videoURL.mySettings
        },
        {
            title: t('hub-menu-items.faq'),
            description: t('hub-menu-items.faq-description'),
            path: '/faqs',
            icon: image.faqIcon,
            helpVideo: videoURL.faq
        },
    ],
    [t('hub-menu-items.learn-and-take-action')]: [
        {
            title: t('hub-menu-items.calculators'),
            description: t('hub-menu-items.calculators-description'),
            path: '/calculators',
            icon: image.calculator,
            helpVideo: videoURL.calculators
        },
        {
            title: t('hub-menu-items.knowledge-base'),
            description: t('hub-menu-items.knowledge-base-description'),
            path: '/knowledge-base',
            icon: image.knowledgeBaseIcon,
            helpVideo: videoURL.knowledgeBase
        },
        {
            title: t('hub-menu-items.fav-websites'),
            description: t('hub-menu-items.fav-websites-description'),
            path: '/favorite-websites',
            icon: image.favWebsite,
            helpVideo: videoURL.favWebsiteBlogs
        },
        {
            title: t('hub-menu-items.forums'),
            description: t('hub-menu-items.forums-description'),
            path: '/forum-categories',
            icon: image.forum,
            helpVideo: videoURL.forums
        },
    ],
    [t('hub-menu-items.engage')]: [
        {
            title: t('hub-menu-items.suggestion-box'),
            description: t('hub-menu-items.suggestion-box-description'),
            path: '/suggestion-box',
            icon: image.suggestion,
            helpVideo: videoURL.suggestionBox
        },
        {
            title: t('hub-menu-items.contact-us'),
            description: t('hub-menu-items.contact-us-description'),
            path: '/contact-us',
            icon: image.contactUs,
            helpVideo: videoURL.contactUs
        },
    ],
});

export { getHubCards };
