import { Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import image from 'assets/image';
import AppDownloadIcons from 'components/app-download-icons/AppDownloadIcons';
import PageHeadingStyle from '../../../components/PageHeadingStyle';

export default function AboutBanner() {
    const { t } = useTranslation();
    return (
        <div className='wrapper'>
            <Grid className='sectionPadding bgColor'>
                <Grid container spacing={2} py={6}>
                    <Grid item md={12} className='learnMoreStyle'>
                        <PageHeadingStyle
                            headLineOne={t('mm-banner.headline-1')}
                            headLineTwo={t('mm-banner.headline-2')}
                            headLineThree={''}
                            headingButton1={''}
                        />
                        <Grid container spacing={2}>
                            <Grid item md={7}>
                                <Typography variant='body1'><strong>{t('mm-banner.headline-3')}</strong> {t('mm-banner.headline-4')}</Typography>
                                <LoadingButton className='loadingButtonStyle2' type="submit" variant="outlined" href="/register">
                                    {t('common-translation.start-button')}
                                </LoadingButton>
                                <AppDownloadIcons />
                            </Grid>
                            <Grid item md={5} className='smTopMargin'>
                                <img src={image.mmVsCompetitionBanner} alt="Header logo" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
