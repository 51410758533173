import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useLocation } from 'react-router-dom';
import Page from '../../components/Page';
import TopMenu from '../../components/TopMenu';
import SmallLogo from '../../components/SmallLogo';
import { SmallLogoStyle, SectionStyle, OnboardingDivStyle, FormDivStyle } from '../register/styled-components';

export default function VerifyEmail() {
    const { t } = useTranslation();
    const location = useLocation()
    const locationUrl = location?.state;

    return (
        <Page title={t('email-verification.title')}>
            <div>
                <SectionStyle>
                    <SmallLogoStyle>
                        <SmallLogo sx={{ width: 40, height: 'auto', display: 'flex', justifyContent: 'center' }} />
                    </SmallLogoStyle>
                </SectionStyle>
                <Grid container justifyContent={'center'} spacing={2} marginLeft={0} width={'100%'} marginTop={0}>
                    <Grid item xs={12} className="customPaddingStyle">
                        <TopMenu title={t('email-verification.verify-your-email')} hideHelp />
                    </Grid>
                    <Grid item xl={5} lg={5} md={8} xs={12} className="extraPaddingStyle">
                        <OnboardingDivStyle>
                            <FormDivStyle>
                                <Typography variant="h5">{t('email-verification.check-inbox')}</Typography>
                                <br />
                                <p>
                                    {t('email-verification.text-1')}
                                </p>
                                <br />
                                <p>{t('email-verification.text-2')}</p>
                                <br />
                                {
                                    locationUrl?.includes('mobile-app')? '' :
                                        <LoadingButton className='loadingButtonStyle2' type="submit" variant="outlined" href="/login">
                                            {t('email-verification.login')}
                                        </LoadingButton>
                                }
                            </FormDivStyle>
                        </OnboardingDivStyle>
                    </Grid>
                </Grid>
            </div>
        </Page>
    );
}
