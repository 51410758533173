import { toast } from 'react-toastify';

export const showToast = (toastText, options) => {
    toast(toastText, options);
};

export const showSuccess = (t, message, placeholderContent = {}) => {
    if (message) {
        // showToast(t(message, placeholderContent), { type: 'success' });
        return;
    }
    console.log(message);
};

export const showError = (t, error) => {
    const message = error?.message || 'API_ERROR';
    const errorMessage = `api-error.${message}`;
    toast.error(t([errorMessage, 'api-error.SOMETHING_WENT_WRONG']));
};