/* eslint-disable react/button-has-type */
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, FormControlLabel, Checkbox, Typography, Card } from '@mui/material';
import moment from 'moment';
import videoURL from 'constants/helpVideoUrl';
import Page from '../../components/Page';
import TopMenu from '../../components/TopMenu';
import TopHeading from '../../components/TopHeading';
import suggestionBox from '../../constants/services/suggestionBox';
import SuggestionModel from './suggestion-dialog/SuggestionModel';
import { sortingData } from './Constant';
import {
    StyledThumbDownActive,
    StyledThumbUpActive,
    TitleBorderStyle,
    HeaderTableStyle,
    StyledThumbDown,
    HeaderDivStyle,
    CheckboxStyle,
    StyledThumbUp,
    CheckBoxLabel,
    ContentStyle,
    NavCalStyle,
    StyledName,
    StyledSpan,
    StyledDate,
    cardStyle,
    StyledTD,
    SuggestionCard,
    SuggestionAction,
    LoadMoreButton,
    ThSortStyle,
    LightTooltip,
    SuggestionName,
} from './styled-components';

export default function SuggestionBox() {
    const [cardData, setCardData] = useState([]);
    const [suggestionMsg, setSuggestionMsg] = useState('');
    const [topLeaderBoard, setTopLeaderBoard] = useState([]);
    const [showSuggestionDialog, setShowSuggestionDialog] = useState(false);
    const defaultSorting = {
        page: 0,
        pageCount: 0,
        pageSize: 27,
        total: 0,
        orderBy: 'likes',
        orderType: 'DESC',
        title: '# of likes',
    };
    const [selectedSorting, setSelectedSorting] = useState({ ...defaultSorting });
    const MAX_LENGTH = 140;
    const { t } = useTranslation();
    const [userId, setUserId] = useState('');
    const [leaderBoardSorting, setLeaderBoardSorting] = useState({
        orderBy: 'suggestions',
        orderType: 'DESC',
    });
    useEffect(() => {
        const userDataSession = sessionStorage.getItem('userData');
        const userId = JSON.parse(userDataSession).username;
        setUserId(userId);
    }, []);

    const getTopLeaderBoard = async (sortingConfig = leaderBoardSorting) => {
        try {
            // strapi
            const qString = `orderBy=${sortingConfig?.orderBy}&orderType=${sortingConfig?.orderType}`;
            const res = await suggestionBox.getBoardSuggestions(qString);
            setTopLeaderBoard(res.data);
        } catch (error) {
            console.log('error', error.message);
        }
    };

    const handleSubmitData = async (targetData = selectedSorting) => {
        const str = `pagination[page]=${targetData.page + 1}&pagination[pageSize]=${targetData?.pageSize}&sort[0]=${
            targetData.orderBy
        }:${targetData.orderType}`;
        try {
            const filterSuggestions = await suggestionBox.getSuggestions(str);
            if (targetData.page === 0) {
                setCardData([...filterSuggestions.data]);
            } else {
                setCardData([...cardData, ...filterSuggestions.data]);
            }
            setSelectedSorting({ ...targetData, ...filterSuggestions?.meta?.pagination });
        } catch (error) {
            console.log(error);
        }
    };

    const handleSuggestionReaction = async (checked, suggestionItem, reactionType) => {
        const likesArray = suggestionItem?.attributes?.likes ? [...suggestionItem?.attributes?.likes] : [];
        const disLikesArray = suggestionItem?.attributes?.dislikes ? [...suggestionItem?.attributes?.dislikes] : [];
        if (reactionType === 'likes') {
            if (checked && !likesArray.includes(userId)) {
                likesArray.push(userId);
                const disLikeIndex = disLikesArray.findIndex((row) => row === userId);
                if (disLikeIndex >= 0) {
                    disLikesArray.splice(disLikeIndex, 1);
                }
            } else {
                const likeIndex = likesArray.findIndex((row) => row === userId);
                if (likeIndex >= 0) {
                    likesArray.splice(likeIndex, 1);
                }
            }
        }
        if (reactionType === 'dislike') {
            if (checked && !disLikesArray.includes(userId)) {
                disLikesArray.push(userId);
                const likeIndex = likesArray.findIndex((row) => row === userId);
                if (likeIndex >= 0) {
                    likesArray.splice(likeIndex, 1);
                }
            } else {
                const disLikeIndex = disLikesArray.findIndex((row) => row === userId);
                if (disLikeIndex >= 0) {
                    disLikesArray.splice(disLikeIndex, 1);
                }
            }
        }
        try {
            await suggestionBox.updateSuggestion(suggestionItem?.id, {
                data: { ...suggestionItem?.attributes, likes: likesArray, dislikes: disLikesArray },
            });
            handleSubmitData({ ...defaultSorting });
            getTopLeaderBoard();
        } catch (error) {
            console.log(error);
        }
    };

    const handleShowModel = (currentdata) => {
        setShowSuggestionDialog(true);
        setSuggestionMsg(currentdata);
    };

    const loadMoreSuggestion = () => {
        handleSubmitData();
    };

    useEffect(() => {
        getTopLeaderBoard();
        handleSubmitData();
    }, []);

    return (
        <Page title={t('suggestion-box.title')}>
            <TopMenu title={t('suggestion-box.title')} videoLink={videoURL.suggestionBox} />
            <TopHeading heading={t('welcome-constant.description-9')} hideBackLink/>
            <div className='contentStyle' style={{ paddingTop: '0px' }}>
                <Grid container spacing={3} justifyContent="center" alignItems="flex-start" style={{paddingTop: '0px', marginTop: '0px'}}>
                    <Grid item xs={12} sm={12} md={12} style={{paddingTop: '0px'}}>
                        <HeaderDivStyle>
                            <HeaderTableStyle>
                                <thead>
                                    <tr>
                                        <th style={{ width: '6%', textAlign: 'left' }}>#</th>
                                        <th style={{ width: '30%', textAlign: 'inherit' }}>
                                            {t('suggestion-box.suggested-by')}
                                        </th>
                                        <ThSortStyle style={{ width: '32%' }}>
                                            {t('suggestion-box.suggestions')}
                                            <button
                                                onClick={() => {
                                                    setLeaderBoardSorting({
                                                        ...leaderBoardSorting,
                                                        orderBy: 'suggestions',
                                                    });
                                                    getTopLeaderBoard({
                                                        ...leaderBoardSorting,
                                                        orderBy: 'suggestions',
                                                    });
                                                }}
                                            >
                                                <img src="/static/loginImg/down-arrow.svg" alt="" />
                                            </button>
                                        </ThSortStyle>

                                        <ThSortStyle style={{ width: '32%' }}>
                                            {t('suggestion-box.likes')}
                                            <button
                                                onClick={() => {
                                                    setLeaderBoardSorting({ ...leaderBoardSorting, orderBy: 'likes' });
                                                    getTopLeaderBoard({ ...leaderBoardSorting, orderBy: 'likes' });
                                                }}
                                            >
                                                <img src="/static/loginImg/down-arrow.svg" alt="" />
                                            </button>{' '}
                                        </ThSortStyle>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <TitleBorderStyle colSpan={4}>{''}</TitleBorderStyle>
                                    </tr>
                                    {topLeaderBoard.map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ width: '6%' }}>{index + 1}</td>
                                            <td style={{ width: '30%' }}>{item?.username || ''}</td>
                                            <StyledTD style={{ width: '32%' }}>{item?.noOfSuggestion}</StyledTD>
                                            <StyledTD style={{ width: '32%' }}>{item?.noOfLikes}</StyledTD>
                                        </tr>
                                    ))}
                                </tbody>
                            </HeaderTableStyle>
                            <NavCalStyle>
                                <Typography style={{ display: 'flex', alignItems: 'center', color: '#0F9672', fontWeight: '700' }}>
                                    {t('suggestion-box.sort')}
                                </Typography>
                                {sortingData.map((item, index) => (
                                    <FormControlLabel
                                        checked={selectedSorting?.title === item.title}
                                        onChange={(e) => {
                                            handleSubmitData({
                                                ...item,
                                                page: 0,
                                                pageCount: 0,
                                                pageSize: 27,
                                                total: 0,
                                            });
                                        }}
                                        key={index}
                                        control={<CheckboxStyle />}
                                        label={<CheckBoxLabel>{item.title}</CheckBoxLabel>}
                                        value={item.title}
                                    />
                                ))}
                            </NavCalStyle>
                            <Grid container spacing={3}>
                                {cardData.map((item, index) => (
                                    <Grid item xs={12} sm={6} lg={4} md={6} key={item.id}>
                                        <Card sx={cardStyle} key={index}>
                                            <SuggestionCard>
                                                <Typography variant="body2">
                                                    {`${item?.attributes?.description?.substring(0, MAX_LENGTH)}`}
                                                    {item?.attributes?.description?.length > MAX_LENGTH ? (
                                                        <button
                                                            onClick={() =>
                                                                handleShowModel(item?.attributes?.description)
                                                            }
                                                        >
                                                            {t('suggestion-box.read-more')}
                                                        </button>
                                                    ) : (
                                                        ''
                                                    )}
                                                </Typography>
                                            </SuggestionCard>
                                            <StyledDate>{moment(item?.attributes?.createdAt).format('ll')}</StyledDate>
                                            <SuggestionAction>
                                                <StyledName>
                                                    {`${item?.attributes?.username}`.length > 11 ? (
                                                        <LightTooltip
                                                            title={
                                                                <>
                                                                    <p>{`${item?.attributes?.username}`}</p>
                                                                </>
                                                            }
                                                        >
                                                            <SuggestionName>
                                                                {`${item?.attributes?.username}`.substring(0, 11)}...
                                                            </SuggestionName>
                                                        </LightTooltip>
                                                    ) : (
                                                        <SuggestionName>
                                                            {`${item?.attributes?.username}`.substring(0, 11)}
                                                        </SuggestionName>
                                                    )}
                                                </StyledName>
                                                <StyledSpan style={{ with: '30%' }}>
                                                    <Checkbox
                                                        style={{ padding: '0px 9px'}}
                                                        icon={<StyledThumbUp />}
                                                        checkedIcon={<StyledThumbUpActive />}
                                                        onChange={(e) =>
                                                            handleSuggestionReaction(e.target.checked, item, 'likes')
                                                        }
                                                        disableRipple
                                                        checked={(item?.attributes?.likes || []).includes(userId)}
                                                    />
                                                    <span>{item?.attributes?.likes?.length}</span>
                                                    <Checkbox
                                                        style={{ padding: '0px 9px' }}
                                                        icon={<StyledThumbDown style={{ width: '19px' }} />}
                                                        checkedIcon={<StyledThumbDownActive />}
                                                        onChange={(e) =>
                                                            handleSuggestionReaction(e.target.checked, item, 'dislike')
                                                        }
                                                        disableRipple
                                                        checked={(item?.attributes?.dislikes || []).includes(userId)}
                                                    />
                                                    <span>{item?.attributes?.dislikes?.length}</span>
                                                </StyledSpan>
                                            </SuggestionAction>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                            <SuggestionModel
                                showSuggestionDialog={showSuggestionDialog}
                                suggestionMsg={suggestionMsg}
                                setShowSuggestionDialog={setShowSuggestionDialog}
                                dialogTitle={'Suggestion Message'}
                                onClose={() => {
                                    setShowSuggestionDialog(false);
                                }}
                            />
                            {selectedSorting?.page < selectedSorting?.pageCount ? (
                                <div style={{ textAlign: 'center', marginTop: '60px' }}>
                                    <LoadMoreButton
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        onClick={loadMoreSuggestion}
                                    >
                                        {t('suggestion-box.load-more')}
                                    </LoadMoreButton>
                                </div>
                            ) : (
                                ''
                            )}
                        </HeaderDivStyle>
                    </Grid>
                </Grid>
            </div>
        </Page>
    );
}
