import Page from '../../components/Page';
import Banner from './banner/Banner';
import ClientReview from './clientReview/ClientReview';
import GetSmarter from './get-smarter/GetSmarter';
import GetStarted from './getStarted/GetStarted';
import GetYourFinancial from './getYourFinancial';
import WhyLoveMoolahMate from './whyLoveMoolahMate/WhyLoveMoolahMate';

export default function HomePageSocialMedia() {
    return (
        <Page title={'MoolahMate-Home Social Media'}>
            <Banner/>
            <WhyLoveMoolahMate/>
            <GetYourFinancial/>
            <GetStarted/>
            <GetSmarter />
            <ClientReview/>
        </Page>
    );
}
