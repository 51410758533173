import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import image from 'assets/image';
import PageSubHeadingStyle from '../../../components/PageSubHeadingStyle'
import InfoProvider from './InfoProvider';
import { getInfoPointer } from './constant'

export default function WhyLoveMoolahMate() {
    const { t } = useTranslation();
    const infoPointer = getInfoPointer(t);

    return (
        <Grid className='sectionCommonPadding commonPadding'>
            <Grid container spacing={2} justifyContent={'center'}>
                <Grid item md={6}>
                    <PageSubHeadingStyle
                        headLineOne={t('home-page.headline-2')}
                        headLineTwo={t('home-page.subtitle-2')}
                        headLineThree={t('home-page.subtitle-info-2')}
                    />
                    <Typography variant='h5' className='addingSubHeading'>{t('home-page.subtitle-info-25')}</Typography>
                    <img src={image.reasonsWhyToChooseMoolahAnimation} alt="Header logo" style={{ marginTop: '40px' }} />
                </Grid>
                <Grid item md={6}>
                    <div className='moolahMateStyle'>
                        {
                            infoPointer.map((point, indx) => (
                                <div key={indx} className='otherAssetBorder'>
                                    <InfoProvider
                                        pointerLineOne={point.id}
                                        pointerLineTwo={point.title}
                                        pointerLineThree={point.text}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}
