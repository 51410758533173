import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Page from '../../components/Page';
import SmallLogo from '../../components/SmallLogo';
import TopMenu from '../../components/TopMenu'
import { ForgotForm } from '../../sections/auth/forgot';
import { SectionStyle, SmallLogoStyle, OnboardingDivStyle, FormDivStyle } from '../register/styled-components';
  
export default function ForgotPassword() {
    const { t } = useTranslation();
    return (
        <Page title={"Forgot your password? MoolahMate's here to help"} description={"Forgot your password? MoolahMate's here to help. MoolahMate is an easy to use personal money management platform to help you plan your financial journey."}>
            <div>
                <SectionStyle>
                    <SmallLogoStyle>
                        <SmallLogo sx={{ width: 40, height: 'auto', display: 'flex', justifyContent:'center' }} />
                    </SmallLogoStyle>
                </SectionStyle>
                <Grid container justifyContent={'center'} spacing={2} marginLeft={0} width={'100%'} marginTop={0}> 
                    <Grid item xs={12} className='customPaddingStyle'>
                        <TopMenu title={t('forgot-password.title')} hideHelp />
                    </Grid>
                    <Grid item xl={5} lg={5} md={8} xs={12} className='extraPaddingStyle'>
                        <OnboardingDivStyle>
                            <FormDivStyle>
                                <ForgotForm />
                            </FormDivStyle>
                        </OnboardingDivStyle>
                    </Grid>
                </Grid>
            </div>
        </Page>
    );
}
  