import { useTranslation } from 'react-i18next';
import videoURL from 'constants/helpVideoUrl';
import Page from '../../components/Page';
import TopHeading from '../../components/TopHeading';
import TopMenu from '../../components/TopMenu';
import ExecutiveSummary from './ExecutiveSummary/ExecutiveSummary';
import { ContentStyle } from './styled-component';

export default function Dashboard() {
    const { t } = useTranslation();

    return (
        <Page title={t('dashboard.title')}>
            <TopMenu title="Executive Summary" videoLink={videoURL.executiveSummary} />
            <TopHeading heading={t('welcome-constant.description-3')} hideBackLink />
            <ContentStyle className="topCommonPadding" style={{ paddingBottom: '0px' }}>
                <ExecutiveSummary />
            </ContentStyle>
        </Page>
    );
}
