import React, { useState, useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import moment from 'moment';
import { InfoIcon, LightTooltip } from 'theme/common-styles';

import todos from '../constants/services/todos';
import './styles.css';

export default function TopMenu({ title = '', hideHelp = false, videoLink = '' }) {
    const [snackbarMsg, setSnackbarMsg] = useState([]);
    const [notificationMenuFlag, setNotificationMenuFlag] = useState(false);
    const anchorRef = useRef(null);
    const { t } = useTranslation();

    const handleOpenToggle = () => {
        setNotificationMenuFlag((prevOpen) => !prevOpen);
    };

    const handleNotificationClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setNotificationMenuFlag(false);
    };

    const prevOpen = useRef(notificationMenuFlag);

    useEffect(() => {
        if (prevOpen.current === true && notificationMenuFlag === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = notificationMenuFlag;
    }, [notificationMenuFlag]);

    const getSnackbarDetails = async () => {
        const requestedDate = moment.parseZone().format('yyyy-MM-DD');
        try {
            const response = await todos.getTodaysReminder(requestedDate);
            setSnackbarMsg(response.data);
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        !hideHelp && getSnackbarDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="hubMenuHeading hubPadding">
            {title && <Typography variant="h6">{title}</Typography>}
            {!hideHelp && (
                <Typography variant="body1">
                    <Link to="/contact-us" className="navLinksBtn">
                        {t('welcome.need-help')}
                    </Link>
                    {videoLink && (
                        <a href={videoLink} target="_blank" rel="noreferrer">
                            <LightTooltip
                                title={
                                    <>
                                        <p>{t('help-video.tooltip')}</p>
                                    </>
                                }
                            >
                                <InfoIcon src="/static/video-solid.svg" alt="" style={{ marginLeft: 5, marginTop: 6 }} />
                            </LightTooltip>
                        </a>
                    )}
                    <div className={snackbarMsg.length <= 0 ? '' : 'activeNotification'} style={{ marginTop: '6px' }}>
                        <NotificationsNoneIcon ref={anchorRef} onClick={handleOpenToggle} />
                    </div>
                </Typography>
            )}
            {(hideHelp && videoLink) && (
                <a href={videoLink} target="_blank" rel="noreferrer">
                    <LightTooltip
                        title={
                            <>
                                <p>{t('help-video.tooltip')}</p>
                            </>
                        }
                    >
                        <InfoIcon src="/static/video-solid.svg" alt="" style={{ marginLeft: 5, marginTop: 6 }} />
                    </LightTooltip>
                </a>
            )}
            <Popper
                className="notificationMenuStyle"
                open={notificationMenuFlag}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleNotificationClose}>
                                <MenuList
                                    autoFocusItem={notificationMenuFlag}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    style={{ paddingBottom: '0px' }}
                                >
                                    <div className="flexDisplay">
                                        <CircleNotificationsIcon />
                                        <Typography variant="h6">{t('welcome.reminder-for')}</Typography>
                                    </div>

                                    {
                                        snackbarMsg.length <= 0 ?  
                                            <Typography
                                                variant="body1"
                                                className="descriptionText"
                                                style={{ padding: '0px 30px' }}
                                            >
                                           No Reminder(s)
                                            </Typography> :
                                            <ul className="notificationText">
                                                {snackbarMsg.map((item, index) => (
                                                    <li className="descriptionText" key={index}>
                                                        {item.task || item.description}
                                                    </li>
                                                ))}
                                            </ul>
                                        
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}
