import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import image from 'assets/image';
import AppDownloadIcons from 'components/app-download-icons/AppDownloadIcons';
import PageHeadingStyle from '../../../components/PageHeadingStyle';

export default function AboutBanner() {
    const { t } = useTranslation();
    return (
        <Grid className='sectionPaddingBanner bgColor '>
            <Grid container spacing={2} pt={6} pb={8}>
                <Grid item md={6} className='workBanner'>
                    <PageHeadingStyle
                        headLineOne={t('how-it-works-banner.headline-1')}
                        headLineTwo={t('how-it-works-banner.headline-2')}
                        headLineThree={t('how-it-works-banner.headline-3')}
                        headingButton1={t('common-translation.start-button')}
                    />
                    <AppDownloadIcons />
                </Grid>
                <Grid item md={6} className='smTopMargin'>
                    <img src={image.howItWorksAnimation} alt="Header logo" />
                </Grid>
            </Grid>
        </Grid>
    );
}
