export const valuePoints = [
    {
        id: 1,
        title: 'Sleep Better',
        text: 'With an easy view, 24/7/365, of your complete financial picture, you will feel less stressed, and sleep more soundly at night.'
    },
    {
        id: 2,
        title: 'Feel Organized',
        text: 'Take the reins on your budget, categorize your payments with a few clicks—and get everything shipshape for tax time.'
    },
    {
        id: 3,
        title: 'Argue Less',
        text: 'Create a family budget everyone agrees to—running numbers together so you and your partner stay on the same page about household finances.'
    },
]