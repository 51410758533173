import * as React from 'react';
import { styled, Grid } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {getAccountingAmount, getCurrencySymbol} from '../../../utils/calCommonFunction';

const TableScroll = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        overflowX: 'auto',
    },
}));

const TableStyle = styled('table')(({ theme }) => ({
    width: '100%',
    paddingTop: '10px',
    minWidth: 550,
    [theme.breakpoints.down('md')]: {
        paddingTop: 10,
    },
    '& th': {
        textAlign: 'left',
        fontSize: 18,
        fontWeight: 700,
        color: theme.palette.primary.dark,
        padding: 10,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 16,
        },
    },
    '& td': {
        padding: '10px 0 10px 10px',
        position:'relative',
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 14,
        },
        '&:last-of-type': {
            span: {
                width: '130px !important',
            },
        },
        '& p': {
            [theme.breakpoints.down('md')]: {
                fontSize: 14,
            },
        }
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
}));

const SummaryValue = styled('span')(() => ({
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '151px',
}));

const BorderedSummaryValue = styled(SummaryValue)(({ theme }) => ({
    padding: '10px 0 10px 15px',
    borderTop: `solid 3px #0F9672`,
    borderBottom: `solid 3px #0F9672`,
    [theme.breakpoints.down('md')]: {
        padding: '6px 0px',
        width: '130px',
        marginLeft: 'auto'
    }
}));

const TotalFlow = styled('td')(() => ({
    fontWeight: 700,
    fontSize: 18,
    paddingTop: 0,
    paddingBottom: 0,
}));

const TotalFlowColumn = styled('td')(() => ({
    fontWeight: 700,
    fontSize: 18,
}));

const InfoIcon = styled('img')(({ theme }) => ({
    display: 'revert',
    width: '16px',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
        margin: '2px 0 0 -16px',
        width: '14px',
        cursor: 'pointer',
        right: '-18px !important'
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            boxShadow: theme.shadows[1],
            fontSize: 13,
            padding: 10,
            fontWeight: 500,
        },
    })
);

export default function BudgetTable(props) {
    const {cashInflow, cashOutflow, discretionaryOutflow, nonDiscretionaryOutflow, totalLoanRepayment} = props;
    const savings = cashInflow - cashOutflow;
    const everyDaySpending = discretionaryOutflow + nonDiscretionaryOutflow;
    const totalExpenseAndSavings = everyDaySpending + totalLoanRepayment + savings;
    const currencySymbol = getCurrencySymbol();

    const getPercent = (value1 = 0, value2 = 0) => {
        if (value2 === 0) {
            return 0;
        }
        const percent = (value1/value2) * 100;
        return `${Math.round(percent)}%`;
    }

    return (
        <TableScroll container p={0}>
            <TableStyle>
                <thead>
                    <tr>
                        <th style={{ width: '50%' }} colSpan={2}>
                            Category
                        </th>
                        <th style={{ width: '17%' }}>Monthly Budget</th>
                        <th style={{ width: '17%' }}>Annual Budget</th>
                        <th style={{ width: '15%' }}>% Composition</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={2}>Total income/cash inflows</td>
                        <td>
                            <SummaryValue>
                                <span>{currencySymbol}</span>
                                <span>{cashInflow.toLocaleString('en-US')}</span>
                            </SummaryValue>
                        </td>
                        <td>
                            <SummaryValue>
                                <span>{currencySymbol}</span>
                                <span>{(cashInflow * 12).toLocaleString('en-US')}</span>
                            </SummaryValue>
                        </td>
                        <td>
                            <SummaryValue>
                                <span />
                                <span>{getPercent(cashInflow, cashInflow)}</span>
                            </SummaryValue>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>Total expenses/cash outflows</td>
                        <td>
                            <SummaryValue>
                                <span>- {currencySymbol}</span>
                                <span>{cashOutflow.toLocaleString('en-US')}</span>
                            </SummaryValue>
                        </td>
                        <td>
                            <SummaryValue>
                                <span>- {currencySymbol}</span>
                                <span>{(cashOutflow * 12).toLocaleString('en-US')}</span>
                            </SummaryValue>
                        </td>
                        <td>
                            <SummaryValue>
                                <span />
                                <span>- {getPercent(cashOutflow, cashInflow)}</span>
                            </SummaryValue>
                        </td>
                    </tr>
                    <tr>
                        <TotalFlow colSpan={2}>Savings (Shortfall)</TotalFlow>
                        <TotalFlowColumn>
                            <BorderedSummaryValue>
                                <span>{currencySymbol}</span>
                                <span>{getAccountingAmount(savings)}</span>
                            </BorderedSummaryValue>
                        </TotalFlowColumn>
                        <TotalFlowColumn>
                            <BorderedSummaryValue>
                                <span>{currencySymbol}</span>
                                <span>{getAccountingAmount(savings * 12)}</span>
                            </BorderedSummaryValue>
                        </TotalFlowColumn>
                        <TotalFlowColumn>
                            <BorderedSummaryValue>
                                <span />
                                <span>{getPercent(savings, cashInflow)}</span>
                            </BorderedSummaryValue>
                        </TotalFlowColumn>
                    </tr>
                    <tr>
                        <td colSpan={5}>
                            <Grid py={3}>
                                <hr />
                            </Grid>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>Fixed Outflows </td>
                        <td>
                            <SummaryValue>
                                <span>{currencySymbol}</span>
                                <span>{nonDiscretionaryOutflow.toLocaleString('en-US')}</span>
                            </SummaryValue>
                        </td>
                        <td>
                            <SummaryValue>
                                <span>{currencySymbol}</span>
                                <span>{(nonDiscretionaryOutflow * 12).toLocaleString('en-US')}</span>
                            </SummaryValue>
                        </td>
                        <td>
                            <SummaryValue>
                                <span />
                                <span>{getPercent(nonDiscretionaryOutflow, cashInflow)}</span>
                            </SummaryValue>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>Variable Outflows</td>
                        <td>
                            <SummaryValue>
                                <span>{currencySymbol}</span>
                                <span>{discretionaryOutflow.toLocaleString('en-US')}</span>
                            </SummaryValue>
                        </td>
                        <td>
                            <SummaryValue>
                                <span>{currencySymbol}</span>
                                <span>{(discretionaryOutflow * 12).toLocaleString('en-US')}</span>
                            </SummaryValue>
                        </td>
                        <td>
                            <SummaryValue>
                                <span />
                                <span>{getPercent(discretionaryOutflow, cashInflow)}</span>
                            </SummaryValue>
                        </td>
                    </tr>
                    <tr>
                        <TotalFlow colSpan={2}>Every day spending</TotalFlow>
                        <TotalFlowColumn>
                            <BorderedSummaryValue style={{ borderBottom: 'none' }}>
                                <span>{currencySymbol}</span>
                                <span>{everyDaySpending.toLocaleString('en-US')}</span>
                            </BorderedSummaryValue>
                        </TotalFlowColumn>
                        <TotalFlowColumn>
                            <BorderedSummaryValue style={{ borderBottom: 'none' }}>
                                <span>{currencySymbol}</span>
                                <span>{(everyDaySpending * 12).toLocaleString('en-US')}</span>
                            </BorderedSummaryValue>
                        </TotalFlowColumn>
                        <TotalFlowColumn>
                            <BorderedSummaryValue style={{ borderBottom: 'none' }}>
                                <span />
                                <span style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    {getPercent(everyDaySpending, cashInflow)}
                                    <LightTooltip title={<>Target should be around 70%.</>}>
                                        <InfoIcon
                                            src="/static/linkFinancial/info-icon.svg"
                                            alt=""
                                            style={{ position: 'absolute', right: '20px' }}
                                        />
                                    </LightTooltip>
                                </span>
                            </BorderedSummaryValue>
                        </TotalFlowColumn>
                    </tr>
                    <tr>
                        <TotalFlow colSpan={2}>Loan/Lease payments</TotalFlow>
                        <TotalFlowColumn>
                            <SummaryValue>
                                <span>{currencySymbol}</span>
                                <span>{totalLoanRepayment.toLocaleString('en-US')}</span>
                            </SummaryValue>
                        </TotalFlowColumn>
                        <TotalFlowColumn>
                            <SummaryValue>
                                <span>{currencySymbol}</span>
                                <span>{(totalLoanRepayment * 12).toLocaleString('en-US')}</span>
                            </SummaryValue>
                        </TotalFlowColumn>
                        <TotalFlowColumn>
                            <SummaryValue>
                                <span />
                                <span style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    {getPercent(totalLoanRepayment, cashInflow)}
                                    <LightTooltip title={<>Target should be around 10%.</>}>
                                        <InfoIcon
                                            src="/static/linkFinancial/info-icon.svg"
                                            alt=""
                                            style={{ position: 'absolute', right: '20px' }}
                                        />
                                    </LightTooltip>
                                </span>
                            </SummaryValue>
                        </TotalFlowColumn>
                    </tr>
                    <tr>
                        <TotalFlow colSpan={2}>Total expenses/cash outflows</TotalFlow>
                        <TotalFlowColumn>
                            <BorderedSummaryValue style={{ borderBottom: 'none' }}>
                                <span>{currencySymbol}</span>
                                <span>{cashOutflow.toLocaleString('en-US')}</span>
                            </BorderedSummaryValue>
                        </TotalFlowColumn>
                        <TotalFlowColumn>
                            <BorderedSummaryValue style={{ borderBottom: 'none' }}>
                                <span>{currencySymbol}</span>
                                <span>{(cashOutflow * 12).toLocaleString('en-US')}</span>
                            </BorderedSummaryValue>
                        </TotalFlowColumn>
                        <TotalFlowColumn>
                            <BorderedSummaryValue style={{ borderBottom: 'none' }}>
                                <span />
                                <span>{getPercent(cashOutflow, cashInflow)}</span>
                            </BorderedSummaryValue>
                        </TotalFlowColumn>
                    </tr>
                    <tr>
                        <TotalFlow colSpan={2}>Savings</TotalFlow>
                        <TotalFlowColumn>
                            <SummaryValue>
                                <span>{currencySymbol}</span>
                                <span>{getAccountingAmount(savings)}</span>
                            </SummaryValue>
                        </TotalFlowColumn>
                        <TotalFlowColumn>
                            <SummaryValue>
                                <span>{currencySymbol}</span>
                                <span>{getAccountingAmount(savings * 12)}</span>
                            </SummaryValue>
                        </TotalFlowColumn>
                        <TotalFlowColumn>
                            <SummaryValue>
                                <span />
                                <span style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    {getPercent(savings, cashInflow)}
                                    <LightTooltip title={<>Target should be around 20%.</>}>
                                        <InfoIcon
                                            src="/static/linkFinancial/info-icon.svg"
                                            alt=""
                                            style={{ position: 'absolute', right: '20px' }}
                                        />
                                    </LightTooltip>
                                </span>
                            </SummaryValue>
                        </TotalFlowColumn>
                    </tr>
                    <tr>
                        <TotalFlow colSpan={2}>Total Expenses and Savings</TotalFlow>
                        <TotalFlowColumn>
                            <BorderedSummaryValue>
                                <span>{currencySymbol}</span>
                                <span>{totalExpenseAndSavings.toLocaleString('en-US')}</span>
                            </BorderedSummaryValue>
                        </TotalFlowColumn>
                        <TotalFlowColumn>
                            <BorderedSummaryValue>
                                <span>{currencySymbol}</span>
                                <span>{(totalExpenseAndSavings * 12).toLocaleString('en-US')}</span>
                            </BorderedSummaryValue>
                        </TotalFlowColumn>
                        <TotalFlowColumn>
                            <BorderedSummaryValue>
                                <span />
                                <span>{getPercent(totalExpenseAndSavings, cashInflow)}</span>
                            </BorderedSummaryValue>
                        </TotalFlowColumn>
                    </tr>
                </tbody>
            </TableStyle>
        </TableScroll>
    );
}
