import { styled, Grid, Autocomplete, Checkbox } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { RHFTextField } from 'components/hook-form';

export const Textfield = styled(RHFTextField)(({ theme }) => ({
    fontSize: 12,
    '& input': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 16,
        },
    },
    '& .MuiInputBase-input': {
        fontSize: 12,
        [theme.breakpoints.up('xl')]: {
            fontSize: 16,
        },
    }
}));

export const AutoFieldStyle = styled(Autocomplete)(({ theme }) => ({
    fontSize: 12,
    paddingBottom: '0px',
    [theme.breakpoints.down('md')]: {
        paddingBottom: '10px',
    },
    '& input': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 16,
        },
    },
    '& .MuiInputBase-root': {
        fontSize: 12,
        [theme.breakpoints.up('xl')]: {
            fontSize: 16,
        },
        '&:hover:before': {
            borderBottom: '0 !important',
        },
        '&.Mui-focused:after': {
            transform: 'scaleX(0) !important',
        },
    },
    '& .MuiAutocomplete-popupIndicator': {
        position: 'relative',
        width: 25,
        '& svg': {
            display: 'none',
        },
        '&:after': {
            position: 'absolute',
            right: 0,
            top: 0,
            content: "''",
            background: 'url(/static/loginImg/down-arrow.svg) 0 0 no-repeat',
            width: 25,
            height: 13,
        },
    },
    '& .dropMenu': {
        overflowWrap: 'break-word'
    }
}));

export const GridStyled = styled(Grid)(({ theme }) => ({
    paddingTop: '0px !important',
    '& .filterBtnStyle .loadingButtonStyle2':{
        marginTop: '1px',
    },
    '& .gridPadding': {
        paddingTop: '30px !important',
        [theme.breakpoints.down('lg')]: {
            paddingTop: '20px !important',
        },
    },
    '& .lastSubDiv': {
        [theme.breakpoints.down('lg')]: {
            paddingTop: '16px !important',
        },
    },
    '& .smPaddingDiv': {
        [theme.breakpoints.down('md')]: {
            paddingTop: '16px !important',
        },
    }
}))

export const LabelStyle = styled('div')(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    marginTop: '10px',
    whiteSpace: 'normal',
    overflow: 'auto',
    textOverflow: 'unset',
    [theme.breakpoints.up('xl')]: {
        fontSize: 16,
    },
}));

export const DateGrid = styled(Grid)(({ theme }) => ({
    '& div': {
        '& div': {
            backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
            borderRadius: 10,
            '& input': {
                padding: '10px 0px 10px 10px  !important',
                fontSize: 12,
                [theme.breakpoints.up('xl')]: {
                    fontSize: 16,
                },
            },
            '& fieldset': {
                borderStyle: 'unset',
                borderWidth: 0,
            },
        },
    },
}));

export const CheckboxStyle = styled(Checkbox)(({ theme }) => ({
    '& svg': {
        display: 'none',
    },
    position: 'relative',
    '&:after': {
        width: 20,
        height: 20,
        border: `solid 2px #0F9672`,
        content: "''",
        borderRadius: 3,
    },
    '&.Mui-checked': {
        '&:before': {
            width: 10,
            height: 10,
            background: theme.palette.primary.main,
            content: "''",
            position: 'absolute',
            borderRadius: 1,
        },
    },
}));

export const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        boxShadow: theme.shadows[1],
        fontSize: '12px',
        padding: 10,
        fontWeight: 400,
        maxWidth: '491px'
    },
}));

export const InfoIcon = styled('img')(({ theme }) => ({
    display: 'revert',
    width: "16px",
    cursor:'pointer',
    marginLeft: 10
}));