import Page from '../../components/Page';
import MoolahMateCalculators from './moolahMateCalculators/MoolahMateCalculators';

export default function Calculators() {
    return (
        <Page title={"Simplify financial math with MoolahMate's Calculators Hub"} description={"Financial math is hard. MoolahMate's Calculators Hub makes it easy. From mortgage payments to retirement savings, let us help you do the hard work."}>
            <MoolahMateCalculators />
        </Page>
    );
}
