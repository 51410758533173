import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import image from "assets/image";

export default function AppDownloadIcons({ marginTop = 6, marginBottom = 0 }) {
    return (
        <Grid mt={marginTop} mb={marginBottom}>
            <LoadingButton
                className="downloadBtn"
                type="submit"
                variant="text"
                onClick={() => window.open('https://apps.apple.com/us/app/moolahmate/id6449456630', '_blank')}
            >
                <img src={image.iosIcon} alt="app store logo" />
            </LoadingButton>
            <LoadingButton
                className="downloadBtn"
                type="submit"
                variant="text"
                onClick={() =>
                    window.open('https://play.google.com/store/apps/details?id=com.moolahmate.app', '_blank')
                }
            >
                <img src={image.androidIcon} alt="android app logo" />
            </LoadingButton>
        </Grid>
    );
}
