const getInfoPointer=(t)=> ([
    {
        id: 1,
        title:t('whyLoveMoolahMate.title-1'),
        text: t('whyLoveMoolahMate.text-1')
    },
    {
        id: 2,
        title:t('whyLoveMoolahMate.title-2'),
        text: t('whyLoveMoolahMate.text-2')
    },
    {
        id: 3,
        title:t('whyLoveMoolahMate.title-3'),
        text: t('whyLoveMoolahMate.text-3')
    },
    {
        id: 4,
        title:t('whyLoveMoolahMate.title-4'),
        text: t('whyLoveMoolahMate.text-4')
    },
    {
        id: 5,
        title:t('whyLoveMoolahMate.title-5'),
        text: t('whyLoveMoolahMate.text-5')
    },
]);

export{
    getInfoPointer
}