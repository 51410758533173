import request from '../../utils/request';

const keyDatesList = (data) => request.post('key-dates-list', data);
const keyDates = (data) => request.post('key-dates', data);
const keyDatesPatch = (data) => request.put('key-dates', data);
const deleteKeyDates = (keyDateId) => request.delete(`key-dates/${keyDateId}`);

export default {
    keyDatesList,
    keyDates,
    keyDatesPatch,
    deleteKeyDates
};