const getWhatCareerInclude1 =(t)=> ([
    {
        id: 1,
        text: t('about-founder.text-1'),
    },
    {
        id: 2,
        text: t('about-founder.text-2'),
    },
    {
        id: 3,
        text: t('about-founder.text-3'),
    },
    {
        id: 4,
        text: t('about-founder.text-4'),
    },
]);

const getWhatCareerInclude2 =(t)=> ([
    {
        id: 1,
        text: t('about-founder.text-5'),
    },
    {
        id: 2,
        text: t('about-founder.text-6'),
    },
    {
        id: 3,
        text: t('about-founder.text-7'),
    },
]);

export{
    getWhatCareerInclude1,
    getWhatCareerInclude2
}
