import request from '../../utils/request';

const validateUsername = (payload) => request.post('users/validate-user', payload);
const registerUser = (payload) => request.post('users', payload);
const getProfile = () => request.get('users/profile-summary');
const getUserCategories = (query) => request.get(`/categories/user${query ? `?${query}` : ''}`);
const updatePersonalDetails = (payload) => request.put('users', payload);
const deleteUserAccount = () => request.delete('users');
const validateSubscription = () => request.get('users/validate-subscription');
const getSubscriptions = () => request.get('users/subscriptions');
const cancelSubscription = (id) => request.post(`users/subscriptions/${id}/cancel`);
const registerUserV2 = (payload) => request.post('users/register', payload);

export default {
    validateUsername,
    registerUser,
    getProfile,
    getUserCategories,
    updatePersonalDetails,
    deleteUserAccount,
    validateSubscription,
    getSubscriptions,
    cancelSubscription,
    registerUserV2
};