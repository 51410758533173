/* eslint-disable jsx-a11y/media-has-caption */
import { Grid, Typography } from '@mui/material';
import image from 'assets/image';
import { useTranslation } from 'react-i18next';
import { getWhatCareerInclude1, getWhatCareerInclude2 } from './constant';

export default function MoreAboutFounder() {
    const { t } = useTranslation();
    const whatCareerInclude1 = getWhatCareerInclude1(t);
    const whatCareerInclude2 = getWhatCareerInclude2(t);
    return (
        <Grid className="sectionCommonPadding">
            <Grid container spacing={2} py={6} className='smPaddingStyle'>
                <Grid item lg={10} md={12} className='moreAboutFounder'>
                    <Typography variant='h4'>{t('about-founder.headline-1')}</Typography>
                    <Typography variant='body1' className='subTxtHeading'>{t('about-founder.headline-2')}</Typography>
                    <Typography variant='body1' className='subPointerHeading'>{t('about-founder.headline-3')}</Typography>
                </Grid>
                {/* <Grid container alignContent={'center'}> */}
                <Grid item md={6}>
                    <ul className="whatCareerPointStyle">
                        {whatCareerInclude1.map((item, indx) => (
                            <li key={indx}>
                                <img src={image.tickIcon} alt="tick-icon" /> <span>{item.text}</span>
                            </li>
                        ))}
                    </ul>
                </Grid>
                <Grid item md={6}>
                    <ul className="whatCareerPointStyle">
                        {whatCareerInclude2.map((item, indx) => (
                            <li key={indx}>
                                <img src={image.tickIcon} alt="tick-icon" /> <span>{item.text}</span>
                            </li>
                        ))}
                    </ul>
                </Grid>
                {/* </Grid> */}
            </Grid>
            {/* <Grid item md={12} mt={4}>
                <video style={{margin:'auto', maxWidth: '100%'}} controls poster={image.founderVideoPoster}>
                    <source src={founderVideo} type="video/mp4" />
                </video>
            </Grid> */}
        </Grid>
    );
}
