import { Card, styled, Box } from '@mui/material';

export const RootStyle = styled('div')(({ theme }) => ({
    paddingBottom: 30,
}));

export const FormDivStyle = styled('div')(({ theme }) => ({
    background: "#FFFFFF",
    marginBottom: "30px",
    padding: '24px 32px 32px 32px',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.15), 0px 0px 2px rgba(0,0,0,0.15)',
    borderRadius: '8px'
}));

export const SmallLogoStyle = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '20px 23px 20px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        padding: '20px 4px 20px',
    },
    '& a div': {
        '& path': {
            fill: theme.palette.secondary.contrastText,
        },
    },
    '& + .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',
        margin: 'auto 0',
    },
}));

export const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 90,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    margin: 0,
    background: theme.palette.primary.main,
    borderRadius: 0,
    position: 'fixed',
    height: '100vh',
    top: 0,
    [theme.breakpoints.down('md')]: {
        maxWidth: 50,
    },
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

export const OnboardingDivStyle = styled('div')(({ theme }) => ({
    width: '100%',
    padding: '0px 8%',
    marginBottom: '40px',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: '0px 4%',
        marginRight: 'auto',
    },
}));