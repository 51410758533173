import SearchIcon from '@mui/icons-material/Search';
import { Checkbox, InputAdornment, ListSubheader, MenuItem, TextField, ListItemIcon, Divider } from '@mui/material';
import { useState } from 'react';
import { SelectFieldStyle } from './styled-components';

const containsText = (option, searchText) => option.label.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export default function MultiSelectPicker({ options = [], selectedOption = [], setValue = () => {} }) {
    const [searchText, setSearchText] = useState('');

    let localOptions = [...options];
    localOptions = localOptions.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1);
    const selectedIds = selectedOption.map((o) => o.id);
    const localSelectedOptions = localOptions.filter((o) => selectedIds.includes(o.id));

    const displayedOptions = localOptions.filter((opt) => containsText(opt, searchText));
    const isAllSelected = localSelectedOptions.length === localOptions.length;

    return (
        <SelectFieldStyle
            multiple
            MenuProps={{ autoFocus: false }}
            labelId="search-select-label"
            id="search-select"
            value={localSelectedOptions}
            placeholder="Options"
            onChange={(e) => {
                const { value } = e.target;
                if (value[value.length - 1] === 'all') {
                    setValue(selectedOption.length === localOptions.length ? [] : localOptions);
                    return;
                }
                setValue(value);
            }}
            renderValue={(selectedOption) => {
                if (selectedOption.length === localOptions.length) {
                    return <>All</>;
                }
                return selectedOption.map((s) => s.label).join(', ');
            }}
            IconComponent={(props) => {
                let className = '';
                if (props.className.includes('MuiSelect-iconOpen')) {
                    className = 'flip-icon';
                }
                return <img src="/static/loginImg/down-arrow.svg" alt="" className={className} />;
            }}
        >
            <ListSubheader>
                <TextField
                    size="small"
                    autoFocus
                    placeholder="Type to search..."
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key !== 'Escape') {
                            e.stopPropagation();
                        }
                    }}
                />
            </ListSubheader>
            <MenuItem value="all">
                <ListItemIcon>
                    <Checkbox
                        checked={isAllSelected}
                        indeterminate={selectedOption.length > 0 && selectedOption.length < options.length}
                    />
                </ListItemIcon>
                Select All
            </MenuItem>
            <Divider />
            {displayedOptions.map((option) => {
                const valueOptions = localSelectedOptions.map((eachValue) => eachValue.id);
                const isChecked = valueOptions.includes(option.id);
                return (
                    <MenuItem key={option.id} value={option}>
                        <Checkbox checked={isChecked} />
                        {option.label}
                    </MenuItem>
                );
            })}
        </SelectFieldStyle>
    );
}
