import { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { Grid, Typography, Link, List } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { LoadingButton } from '@mui/lab';

import videoURL from 'constants/helpVideoUrl';
import Page from '../../components/Page';
import TopMenu from '../../components/TopMenu';
import TopHeading from '../../components/TopHeading';
import { getSearchValues, noDataFound } from '../../utils/calCommonFunction'
import { CALCULATORS } from './constant';

const CheckboxStyle = styled(Checkbox)(({ theme }) => ({
    '& svg': {
        display: 'none',
    },
    position: 'relative',
    '&:after': {
        width: 20,
        height: 20,
        border: `solid 2px black`,
        content: "''",
        borderRadius: 3,
        [theme.breakpoints.down('sm')]: {
            width: 16,
            height: 16,
        }
    },
    '&.Mui-checked': {
        '&:before': {
            width: 10,
            height: 10,
            background: 'black',
            content: "''",
            position: 'absolute',
            borderRadius: 1,
        },
    },
}));

export const CheckBoxLabel = styled('h4')(({ theme }) => ({
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '24px',
    color: '#0F9672',
    marginLeft: '10px',
    [theme.breakpoints.down('sm')]: {
        fontSize: '18px'
    }
}));

const loanCategoryList = ['All', ...new Set(CALCULATORS.map((item) => item.calculatorType))];

export default function CalculatorsPage() {
    const [primaryModules, setPrimaryModules] = useState(CALCULATORS);
    const [viewStyle, setViewStyle] = useState('grid');
    const [calculatorFilter, setCalculatorFilter] = useState('All');
    const [isLogged, setIsLogged] = useState(false)
    const [searchValue, setSearchValue] = useState('');
    const searchRef = useRef();
    const { t } = useTranslation();

    const getFilteredCalculators = () => {
        if (calculatorFilter === 'All') {
            return primaryModules;
        }
        return primaryModules.filter((calculator) => calculator.calculatorType === calculatorFilter);
    };

    const getDashboardGirdView = (record) =>
        record.map((item, indx) => (
            <Grid key={indx} item lg={3} md={4} sm={6} xs={12}>
                <Link href={isLogged ? item.path : item.homePath} style={{ textDecoration: 'none' }}>
                    <div
                        className="hubCardStyle calServiceStyle calHeightStyle"
                    >
                        <div>
                            <Typography variant="body1" className="descriptionHeading">
                                {item.title}
                            </Typography>
                            <Typography className="descriptionText" variant="body1" title={item.text}>
                                {item.text}.
                            </Typography>
                        </div>
                        <div>
                            <LoadingButton className='carouselBtnStyle' type="submit" variant="outlined" style={{ marginTop: '12px' }}>
                                {item.calculatorType}
                            </LoadingButton>
                        </div>
                    </div>
                </Link>
            </Grid>
        ));

    const getDashboardListView = (record) =>
        record.map((item, indx) => (
            <List key={indx}>
                <Link href={isLogged ? item.path : item.homePath} style={{ textDecoration: 'none' }}>
                    <div className="hubCardStyle listDisplayStyle flexDisplay">
                        <div>
                            <Typography variant="body1" className="descriptionHeading">
                                {item.title}
                            </Typography>
                            <Typography className="descriptionText" variant="body1">
                                {item.text}.
                            </Typography>
                            <LoadingButton className='carouselBtnStyle' type="submit" variant="outlined" style={{ marginTop: '12px' }}>
                                {item.calculatorType}
                            </LoadingButton>
                        </div>
                    </div>
                </Link>
            </List>
        ));

    const getSearchResultDetails = () => {
        if (searchValue) {
            const primaryResultValue = getSearchValues(CALCULATORS, searchValue);
            setPrimaryModules(primaryResultValue);
        } else {
            setPrimaryModules(CALCULATORS);
        }
    };

    const deboundHubSearch = debounce(() => {
        getSearchResultDetails();
    }, 500);

    useEffect(() => {
        deboundHubSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        if (sessionStorage.getItem('userData')) {
            const user = JSON.parse(sessionStorage.getItem('userData'));
            if (Object.keys(user).length > 0) {
                setIsLogged(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Page title={"Simplify financial math with MoolahMate's Calculators Hub"} description={"Financial math is hard. MoolahMate's Calculators Hub makes it easy. From mortgage payments to retirement savings, let us help you do the hard work."} className={isLogged ? '' : 'homeCalcultorsStyle'}>
            {isLogged && (<TopMenu title={t('calculation.title')} videoLink={videoURL.calculators} />)}
            <TopHeading
                heading={t('welcome-constant.description-6')}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                searchRef={searchRef}
                setViewStyle={setViewStyle}
                viewStyle={viewStyle}
                showSearch
                hideBackLink
            />
            <div className='hubPadding'>
                <div className='navCalStyle knowledge-base-filters'>
                    {loanCategoryList.map((item, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <CheckboxStyle
                                    checked={calculatorFilter === item}
                                    onChange={(e) => {
                                        const selectedFilter = e.target.value;
                                        if (calculatorFilter === selectedFilter) {
                                            return;
                                        }
                                        setCalculatorFilter(selectedFilter);
                                    }}
                                />
                            }
                            label={<CheckBoxLabel>{item}</CheckBoxLabel>}
                            value={item}
                        />
                    ))}
                </div>
                <Grid container spacing={4} pt={5} pb={5}>
                    {viewStyle === 'grid' ? (
                        getDashboardGirdView(getFilteredCalculators())
                    ) : (
                        <div className="listViewStyle">{getDashboardListView(getFilteredCalculators())}</div>
                    )}
                    {
                        getFilteredCalculators().length > 0 ? '' :  (
                            <Grid pt={4} pb={2} width={'100%'}>
                                {
                                    noDataFound()
                                }
                            </Grid>
                        )
                    }
                </Grid>
            </div>
        </Page>
    );
}
