// @mui
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
    Grid,
    Stack,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Badge,
} from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useEffect, useState } from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { ForumMediaFlag, BorderStyle, ForumDivStyle, RepliesTdStyle, BootstrapTextarea } from './styled-components';
// components
import transform from '../../constants/strapiHelper';
import forum from '../../constants/services/forum';
import { InputLabelStyle } from '../contact-us/styled-components';

export default function ForumReplies(props) {
    const [liked, setLiked] = useState(false);
    const [topicReply, setTopicReply] = useState('');
    const [replyLikeCount, setReplyLikeCount] = useState(0);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const reply = props.data;
    const replyDepth = props.depth;
    const { forumReplies } = props;
    const { topicId } = props;
    const { refreshTopic } = props;
    const { t } = useTranslation();
    const maxDepth = 2;
    let replyDepthSizeValue = replyDepth ? replyDepth * 40 : 0;
    if (replyDepth > maxDepth) {
        replyDepthSizeValue = maxDepth * 40;
    }
    const replyDepthSize = `${replyDepthSizeValue}px`;

    let addedById = '';
    if (sessionStorage.getItem('userData')) {
        const usr = JSON.parse(sessionStorage.getItem('userData'));
        if (usr?.username) {
            addedById = usr.username;
        }
    }

    const getForumReply = (id) => {
        const reply = forumReplies.data.filter((forumReply) => forumReply.id === id);
        return reply[0];
    };

    const setReplyLiked = () => {
        const { likes } = reply.attributes;
        if (likes != null) {
            if (likes.includes(addedById)) {
                setLiked(true);
            } else {
                setLiked(false);
            }
            setReplyLikeCount(likes.length);
        }
    };

    const addReplyLike = async () => {
        let newLikesList = [];
        const replyLikes = await forum.getReplyLikes(reply.id);
        let likesData = [];
        if (replyLikes.data) {
            replyLikes.data.forEach((reply) => {
                likesData = reply.attributes.likes;
            });
        }
        if (likesData != null) {
            if (likesData.includes(addedById)) {
                newLikesList = [...likesData];
                const index = newLikesList.indexOf(addedById);
                if (index > -1) {
                    newLikesList.splice(index, 1);
                }
                setLiked(false);
            } else {
                newLikesList = [...likesData, addedById];
                setLiked(true);
            }
        } else {
            newLikesList = [addedById];
            setLiked(true);
        }

        const addReplyLikeMessage = {
            data: {
                likes: newLikesList,
            },
        };

        await forum.addReplyLike(reply.id, addReplyLikeMessage);
        setReplyLikeCount(newLikesList.length);
    };

    const getForumDepth = () => replyDepth;

    const openTopicReplyPopup = () => {
        setOpen(true);
    };

    const closeTopicReplyPopup = () => {
        setOpen(false);
    };

    const handleTopicReply = (event) => {
        setTopicReply(event.target.value);
    };

    const handleTopicReplySubmit = async () => {
        setOpen(false);
        if (topicReply?.length) {
            const addTopicReplyMessage = {
                data: {
                    content: topicReply,
                    forum_topic: {
                        id: topicId,
                    },
                    parent_reply: {
                        id: reply.id,
                    },
                    username: addedById,
                    topicId,
                },
            };
            await forum.addReply(addTopicReplyMessage);
            refreshTopic();
        }
    };

    useEffect(() => {
        setReplyLiked();
    }, []);

    return (
        <>
            {reply.id ? (
                <>
                    <tr>
                        <BorderStyle colSpan={3}>{''}</BorderStyle>
                    </tr>
                    <tr>
                        <td style={{ width: '60%' }} colSpan={2}>
                            <div key={reply.id} role="presentation" style={{ marginLeft: `${replyDepthSize}` }}>
                                <Typography variant="h8">
                                    {ReactHtmlParser(reply.attributes.content, {
                                        transform: (node, index) => transform(node),
                                    })}
                                </Typography>
                            </div>
                            {/* {
                                reply.attributes?.tags?.map((tag, index) =>
                                    (<Typography key={tag} variant="h7" style={{cursor: 'pointer', fontWeight: 'bold', marginLeft: '200px'}} onClick={() => navigate(`/forum-topics/tag/${tag}`)}> 
                                        {index > 0?`, ${tag}`:tag} </Typography>))
                            } */}
                        </td>
                    </tr>
                    <tr>
                        <RepliesTdStyle>
                            <Stack direction="column">
                                <Typography
                                    variant="h7"
                                    style={{ fontWeight: '600', color: '#0F9672', marginLeft: `${replyDepthSize}` }}
                                >
                                    {format(new Date(reply.attributes.updatedAt), 'dd MMM yy')}
                                </Typography>
                                <Typography
                                    style={{ fontWeight: '600', marginLeft: `${replyDepthSize}` }}
                                    variant="h7"
                                >
                                    {reply.attributes.username}
                                </Typography>
                            </Stack>
                            
                        </RepliesTdStyle>
                        <RepliesTdStyle>
                            <Stack direction="row" spacing={4} justifyContent="end">
                                <ForumMediaFlag key={`AddReplyLike-${reply.id}`}>
                                    <Badge
                                        key={`BadgeReplyLike-${reply.id}`}
                                        sx={{
                                            '& .MuiBadge-badge': {
                                                color: 'lightgreen',
                                                backgroundColor: 'green',
                                                position: 'absolute',
                                                top: '1px',
                                                right: '2px',
                                            },
                                        }}
                                        badgeContent={replyLikeCount}
                                    >
                                        {liked ? (
                                            <FavoriteIcon
                                                onClick={addReplyLike}
                                                key={`FavoriteIcon-${reply.id}`}
                                                style={{ color: 'red', position: 'relative' }}
                                            />
                                        ) : (
                                            <FavoriteBorderIcon
                                                onClick={addReplyLike}
                                                style={{ color: 'red' }}
                                                key={`FavoriteBorderIcon-${reply.id}`}
                                            />
                                        )}
                                    </Badge>
                                    <span>{t('forumcategory.like')}</span>
                                </ForumMediaFlag>
                                <ForumMediaFlag key={`addReply-${reply.id}`} onClick={openTopicReplyPopup}>
                                    <ReplyIcon key={`ReplyIcon-${reply.id}`} />
                                    <span>{t('forumcategory.reply')}</span>
                                </ForumMediaFlag>
                            </Stack>
                        </RepliesTdStyle>
                    </tr>
                    {reply.attributes.replies
                        ? reply.attributes.replies.data.map((replyDepth2) => (
                            <ForumReplies
                                key={`reply-${replyDepth2.id}`}
                                topicId={topicId}
                                data={getForumReply(replyDepth2.id)}
                                depth={getForumDepth() + 1}
                                forumReplies={forumReplies}
                                refreshTopic={refreshTopic}
                            />
                        ))
                        : ''}
                </>
            ) : (
                <tbody />
            )}
            <div>
                <Dialog open={open} onClose={closeTopicReplyPopup} key={`replyDialog-${reply.id}`}>
                    <DialogTitle>{t('forumcategory.addReply')}</DialogTitle>
                    <DialogContent style={{ paddingBottom: '0px' }}>
                        <RHFTextFieldMsg
                            label={t('forumcategory.addReply')}
                            multiline={'true'}
                            rows={10}
                            name="addReplyField"
                            onChange={handleTopicReply}
                            key={`RHFTextFieldMsg-${reply.id}`}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeTopicReplyPopup} key={`closeReplyPopup-${reply.id}`}>
                            {t('forumcategory.cancel')}
                        </Button>
                        <Button onClick={handleTopicReplySubmit} key={`handleReplySubmit-${reply.id}`}>
                            {t('forumcategory.submit')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}

const RHFTextFieldMsg = ({ name, multiline, rows, label, key, ...other }) => (
    <>
        <InputLabelStyle shrink htmlFor="bootstrap-input">
            {label}
        </InputLabelStyle>
        <BootstrapTextarea multiline={multiline} rows={rows} cols={100} name={name} key={key} fullWidth {...other} />
    </>
);
