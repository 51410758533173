import request from '../../utils/request';

const getFilterSuggestions = (data) => request.get(`suggestions?${data}`);
const getLeaderboardSuggestions = (data)=> request.get('leaderboard-suggestions', data);
const getLikeDislikeSuggestions = (data, payload) => request.put(`suggestions?${data}`, payload);
const getTopSuggestion = (data, payload)=> request.get(`leaderboard-suggestions?${data}`, payload);

const getSuggestions = (queryString) => request.get(`/cms/suggestions?${queryString}`);
const updateSuggestion = (suggestionId, payload) => request.put(`/cms/suggestions/${suggestionId}`, payload);
const getBoardSuggestions = (queryString) => request.get(`/cms/suggestion-leaderboard?${queryString}`);

export default {
    getSuggestions,
    updateSuggestion,
    getBoardSuggestions,
    getTopSuggestion,
    getFilterSuggestions,
    getLeaderboardSuggestions,
    getLikeDislikeSuggestions
};
