const INFO_POINTER = [
    {
        id: 1,
        title: 'whyLoveMoolahMate.title-1',
        text: 'whyLoveMoolahMate.text-1',
        textActions: true,
    },
    {
        id: 2,
        title: 'whyLoveMoolahMate.title-2',
        text: 'whyLoveMoolahMate.text-2',
    },
    {
        id: 3,
        title: 'whyLoveMoolahMate.title-3',
        text: 'whyLoveMoolahMate.text-3',
    },
    {
        id: 4,
        title: 'whyLoveMoolahMate.title-4',
        text: 'whyLoveMoolahMate.text-4',
    },
    {
        id: 5,
        title: 'whyLoveMoolahMate.title-5',
        text: 'whyLoveMoolahMate.text-5',
    },
];

export { INFO_POINTER };
