import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { AppBar, IconButton } from '@mui/material';
import Iconify from '../../components/Iconify';

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    width: `auto`,
    [theme.breakpoints.down('md')]: {
        paddingTop: 14,
    }
}));

DashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
    return (
        <RootStyle>
            <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { md: 'none' } }}>
                <Iconify icon="eva:menu-2-fill" />
            </IconButton>
        </RootStyle>
    );
}
