import * as React from 'react';
import { styled, Typography, Grid, Checkbox, Input } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Iconify from 'components/Iconify';

const TableScroll = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        overflowX: 'auto',
    },
}));

const TableStyle = styled('table')(({ theme }) => ({
    width: '100%',
    paddingTop: '10px',
    minWidth: 550,
    [theme.breakpoints.down('md')]: {
        paddingTop: 10,
    },
    '& th': {
        textAlign: 'left',
        fontSize: 18,
        fontWeight: 700,
        color: theme.palette.primary.dark,
        padding: 10,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
    },
    '& td': {
        padding: 10,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
        '& svg.delete-icon': {
            fill: '#000000',
            '& path': {
                fill: '#000000',
            }
        }
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
}));

const NameInput = styled('table')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& span': {
        marginRight: -10,
    },
    '& input': {
        textAlign: 'left',
    },
    '& p.Mui-error': {
        color: 'rgb(255, 72, 66)',
        fontSize: '0.75rem',
        fontWeight: 400,
        textAlign: 'left'
    }
}));

const CheckboxStyle = styled(Checkbox)(({ theme }) => ({
    '& svg': {
        position: 'absolute'
    },
    position: 'relative',
    '&:after': {
        width: 20,
        height: 20,
        border: `solid 0px ${theme.palette.primary.main}`,
        content: "''",
        borderRadius: 3,
    },
    '&.Mui-checked': {
        '&:before': {
            width: 10,
            height: 10,
            content: "''",
            position: 'absolute',
            borderRadius: 1,
        },
    },
    '&.Mui-disabled': {
        '& svg': {
            '& path': {
                fill: 'rgba(145, 158, 171, 0.8); !important',
            },
        },
    }
}));

const CategoryNameInput = ({ categoryName, updateCategoryName, error }) => {
    const [name, setName] = React.useState(categoryName);

    return (
        <NameInput>
            <Input
                type='text'
                placeholder="Category"
                style={{
                    width: '90%',
                }}
                value={name}
                error={error}
                inputProps={{ maxLength: 25 }}
                onChange={(e) => {
                    const {value} = e.target;
                    setName(value);
                    updateCategoryName(value);
                }}
            />
            {error && <p className='Mui-error'>{error}</p>}
        </NameInput>
    );
}

const TableRows = ({ category, categoryGroupId, updateCategoryData, onClickDeleteCategory }) => {
    const { t } = useTranslation();

    return (
        <tr>
            <td style={{ width: '10%', textAlign: 'center' }}>
                <CheckboxStyle disabled={category.isSelectionDisabled} checked={category.isSelected} onChange={() => {
                    if (+category.catTransactionCount > 0) {
                        toast(t('error-message.category-delete-error'), { type: 'error' });
                        return;
                    }
                    updateCategoryData(categoryGroupId, category.id, 'isSelected', !category.isSelected)
                }}/>
            </td>
            <td style={{ width: '30%' }}>
                {
                    category.isUserCategory ?
                        <CategoryNameInput 
                            categoryName={category.name}
                            error={category.error}
                            updateCategoryName={(value) => updateCategoryData(categoryGroupId, category.id, 'name', value)} />
                        :
                        <Typography>{category.name}</Typography>
                }
            </td>
            <td style={{ width: '10%', textAlign: 'center' }}>
                {category.isUserCategory ? <Iconify
                    icon={'ri:delete-bin-6-fill'}
                    width={22}
                    height={22}
                    className='delete-icon'
                    style={{ marginLeft: 32, cursor: 'pointer' }}
                    onClick={() => {
                        if (+category.catTransactionCount > 0) {
                            toast(t('error-message.category-delete-error'), { type: 'error' });
                            return;
                        }
                        onClickDeleteCategory({categoryGroupId, categoryId: category.id});
                    }}
                /> : <>&nbsp;</>}
            </td>
        </tr>);
};

export default function SimpleAccordion(props) {
    const { categories, categoryGroupId, addCategory, updateCategoryData, onClickDeleteCategory } = props;

    const addcss = {
        width: '30%',
        color: '#0F9672',
        cursor: 'pointer',
        textDecoration: 'underline'
    };

    return (
        <TableScroll container p={0}>
            <TableStyle>
                <thead>
                    <tr>
                        <th style={{ width: '10%', textAlign: 'center' }}> </th>
                        <th style={{ width: '30%' }}>Category</th>
                        <th style={{ width: '10%' }}>&nbsp;</th>
                    </tr>
                </thead>
                <tbody style={{position: 'sticky'}}>
                    {categories.map((category) => 
                        <TableRows
                            updateCategoryData={updateCategoryData}
                            key={category.id}
                            categoryGroupId={categoryGroupId}
                            category={category}
                            onClickDeleteCategory={onClickDeleteCategory}
                        />)
                    }
                    <tr>
                        <td style={{ width: '10%', textAlign: 'center' }}>
                            {}
                        </td>
                        <td style={addcss}>
                            <Typography
                                onClick={() => addCategory(categoryGroupId)}
                            >
                                Add Your Own
                            </Typography>
                        </td>
                        <td style={{ width: '10%', textAlign: 'center' }} />
                    </tr>
                </tbody>
            </TableStyle>
        </TableScroll>
    );
}
