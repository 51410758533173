import { API_URL_WITHOUT_CONTEXTPATH } from './url';

const UPLOAD_FOLDER = "/uploads/";

const transform = (node) => {
    if (node.type === 'tag' && node.name === 'img') {
        return <img src={addContextPathtoURL(node.attribs.src)} alt={node.attribs.alt} srcSet={addContextPathtoURL(node.attribs.srcset)} sizes={node.attribs.sizes} />;
    }
}

const addContextPathtoURL = (field) => {
    if(field && field.includes(UPLOAD_FOLDER)) {
        const result = field.lastIndexOf(UPLOAD_FOLDER);
        const imagePath = field.substring(result, field.length);
        return imagePath.replaceAll(UPLOAD_FOLDER, `${API_URL_WITHOUT_CONTEXTPATH}/uploads/`);
    }
    return field;
}

export default transform;