import { Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import image from 'assets/image';
import AppDownloadIcons from 'components/app-download-icons/AppDownloadIcons';
import PageSubHeadingStyle from '../../../components/PageSubHeadingStyle'
import { getFinancialPoints } from './constant'

export default function InsightfulReporting() {
    const { t } = useTranslation();
    const financialPoints = getFinancialPoints(t);

    return (
        <Grid className='sectionLeftPadding commonTopPadding bgColor'>
            <Grid container spacing={2}>
                <Grid item md={5} className='paddingRightStyle'>
                    <div className='InsightfulDetails'>
                        <PageSubHeadingStyle
                            headLineOne={t('home-page.headline-7')}
                            headLineTwo={t('home-page.subtitle-7')}
                            headLineThree={t('home-page.subtitle-info-7')}
                        />
                        {/* <Typography variant="body2" pt={2} className='subInsightfulHeading'>{t('home-page.subtext')}</Typography> */}
                        <ul className='financialDetails'>
                            {
                                financialPoints.map((points, indx) => (
                                    <li key={indx}><img src={image.rightIcon} alt='' /><Typography variant='body'>{points.text}</Typography></li>
                                ))
                            }
                        </ul>
                        <LoadingButton className='loadingButtonStyle2 marginTopSm' type="submit" variant="contained" href="/register">
                            {'Start your Free Trial'}
                        </LoadingButton>
                        <AppDownloadIcons marginBottom={6} />
                    </div>
                </Grid>
                <Grid item md={7} sx={{ mt: 4 }}>
                    <img src={image.realTimeReportingAnimation} alt="Header logo" />
                </Grid>
            </Grid>
        </Grid>
    );
}
