/* eslint-disable array-callback-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-loop-func */
/* eslint-disable no-plusplus */
import { LoadingButton } from '@mui/lab';
import { useState, useEffect, Fragment } from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
// components
import 'react-toastify/dist/ReactToastify.css';
import videoURL from 'constants/helpVideoUrl';
import Page from '../../../components/Page';
import TopMenu from '../../../components/TopMenu';
import { EXCEL_STYLE } from '../../calculators/constant';
import TopHeading from '../../../components/TopHeading';
import CreditCardFilter from '../filter/CreditCardFilter';
import {
    removeEmpty,
    exportCustomExcel,
    getMonthNameYear,
    getCurrencySymbol,
    getPositiveNumber,
    formatCreditRecords,
} from '../../../utils/calCommonFunction';
import dashboard from '../../../constants/services/dashboard';
import {
    TableStyle,
    TableWrapper,
    StickyTable,
    StickyFourthTd,
    ExtraTd,
    NoDataTd,
    StickyThirdTd,
    LoaderGif,
    StickyCreditFirstTd,
    StickyCreditFirstTh,
    StickyThirdTh,
} from '../Expense/styled-component';
import { ContentStyle, SubmitButton, TotalAmountDiv, SpanTotalFlow } from './styled-component';
import { ReportAmountTd, ButtonText } from '../ExecutiveSummary/styled-component';

export default function CreditCard(props) {
    const { t } = useTranslation();
    const { reportName } = props;
    const currencyCode = getCurrencySymbol();
    const assetApi = props?.apiData;

    const [loading, setLoading] = useState(false);
    const [filtersData, setFiltersData] = useState({
        fromMonth: moment.parseZone().format('YYYY-MM'),
        compareMonth: moment.parseZone().startOf('year').format('YYYY-MM'),
    });

    const [tableLoader, setTableLoader] = useState(false);
    const [monthColumn, setMonthColumn] = useState([]);
    const [creditCardRecords, setCreditCardRecords] = useState({});
    const updateFilters = (key, value) => {
        setFiltersData({
            ...filtersData,
            [key]: value,
        });
    };

    const getdashboardData = async () => {
        const filtersDataObj = removeEmpty(filtersData);
        const queryString = Object.keys(filtersDataObj)
            .map(
                (key) =>
                    // eslint-disable-next-line
                    key + '=' + filtersDataObj[key]
            )
            .join('&');
        setTableLoader(true);
        const apiqueryString = `/dashboard/${assetApi}?${queryString}`;
        const { data } = await dashboard.getcreditCardUsage(apiqueryString);

        setCreditCardRecords(formatCreditRecords(data));
        setTableLoader(false);
        setMonthColumn(Object.keys(data));
    };

    useEffect(() => {
        getdashboardData();
    }, []);

    const getFilterData = () => {
        getdashboardData();
    };

    const getMonthWiseTotal = (records = []) => {
        records = records.map(({ records }) => records).flat();
        const sumMap = new Map();
        records.forEach((record) => {
            if (!sumMap.has(record.date)) {
                sumMap.set(record.date, 0);
            }
            sumMap.set(record.date, sumMap.get(record.date) + record.amount);
        });
        return Array.from(sumMap.values());
    };

    const renderMonthsHeaders = () =>
        monthColumn.map((item, index) => {
            let styles;
            if (index >= 0) {
                styles = (
                    <th
                        style={{
                            textAlign: 'end',
                            width: '16%',
                        }}
                        key={index}
                    >
                        {getMonthNameYear(item)}
                    </th>
                );
            }
            return styles;
        });

    const renderMonthsRecords = (records, dataKey) =>
        records.map((item, idx) => (
            <tr key={idx}>
                <StickyCreditFirstTd>{item.providerName}</StickyCreditFirstTd>
                <StickyFourthTd>{item.accountName}</StickyFourthTd>
                {item?.records?.map((record, idx) => (
                    <ReportAmountTd key={idx}>
                        <TotalAmountDiv>
                            {currencyCode} <span>{getPositiveNumber(record.amount)}</span>
                        </TotalAmountDiv>
                    </ReportAmountTd>
                ))}
                {dataKey === 'total_credit_line_local_currency' ? (
                    <StickyThirdTd style={{ right: '-1px' }}>{''}</StickyThirdTd>
                ) : (
                    <StickyThirdTd
                        style={{
                            right: '-1px',
                            minHeight: '67px',
                        }}
                    >
                        {currencyCode} <span>{getPositiveNumber(renderAvgRecords(item.records))}</span>
                    </StickyThirdTd>
                )}
            </tr>
        ));

    const renderAvgRecords = (data) => {
        const avgRenderRecord = data.reduce((acc, item) => Number(acc) + Number(item.amount), 0);
        const monthWiseAvg = avgRenderRecord / monthColumn?.length;
        return monthWiseAvg;
    };

    const renderMonthsTotals = (records) => (
        <tr>
            <StickyCreditFirstTd>{''}</StickyCreditFirstTd>
            <StickyFourthTd>{''}</StickyFourthTd>
            {getMonthWiseTotal(records).map((item, index) => (
                <SpanTotalFlow key={index}>
                    <TotalAmountDiv>
                        {currencyCode} <span>{getPositiveNumber(item)}</span>
                    </TotalAmountDiv>
                </SpanTotalFlow>
            ))}
            <StickyThirdTd style={{ right: '-1px' }}>{''}</StickyThirdTd>
        </tr>
    );

    const renderCategoryHeader = (category) => (
        <tr>
            <StickyCreditFirstTh colSpan={2}>{t(`${reportName}.${category}`)}</StickyCreditFirstTh>
            {renderMonthsHeaders()}
            <StickyThirdTh style={{ right: '-1px' }}>{category === 'amount_available' ? 'Average' : ''}</StickyThirdTh>
        </tr>
    );

    const renderCreditCardRecords = () => {
        const items = [
            { dataKey: 'total_credit_line_local_currency', dataHeader: 'amount_available' },
            { dataKey: 'running_balance_local_currency', dataHeader: 'amount_used' },
            { dataKey: 'available_credit_local_currency', dataHeader: 'amount_unused' },
        ];
        return items.map(({ dataKey, dataHeader }) => (
            <Fragment key={dataKey}>
                {renderCategoryHeader(dataHeader)}
                {renderMonthsRecords(creditCardRecords[dataKey], dataKey)}
                {renderMonthsTotals(creditCardRecords[dataKey])}
            </Fragment>
        ));
    };

    const videoUrl =
        reportName === 'liabilities-credit-card'
            ? videoURL.statusOfCreditCardUsage
            : videoURL.statusOfLineOfCreditUsage;
    const getExtractData = (record, creditHeading) =>
        record.map((item) => {
            const transformedRecord = {
                [creditHeading]: item.providerName,
                'Account Name': item.accountName,
            };

            item.records.forEach((entry) => {
                transformedRecord[entry.date] = entry.amount.toString();
            });

            if (creditHeading === 'Credit Limit') {
                // eslint-disable-next-line
                transformedRecord['Average'] = '';
            } else {
                // eslint-disable-next-line
                transformedRecord['Average'] = getPositiveNumber(renderAvgRecords(item.records));
            }

            return transformedRecord;
        });

    const getTotalValueWithMonth = (element, creditHeading) => {
        const totalValue = [];
        element.forEach((item, index) => {
            const existingMonth = totalValue.find((outputItem) => outputItem[creditHeading] === 'Total');
            if (existingMonth) {
                existingMonth[monthColumn[index]] = getPositiveNumber(item);
            } else {
                const newItem = { [creditHeading]: 'Total', 'Account Name': '', Average: '' };
                newItem[monthColumn[index]] = getPositiveNumber(item);
                totalValue.push(newItem);
            }
        });
        return totalValue;
    };

    const getExcelSummary = () => {
        if (creditCardRecords) {
            const merges = [];
            const selectedFormData = [];

            selectedFormData.push({
                'From Date': filtersData.compareMonth,
                'To Date': filtersData.fromMonth,
            });

            const getAmountAvailable = getExtractData(creditCardRecords?.total_credit_line_local_currency, 'Credit Limit');
            const getAmountUsed = getExtractData(creditCardRecords?.running_balance_local_currency, 'Amount Used');
            const getAmountUnused = getExtractData(creditCardRecords?.available_credit_local_currency, 'Amount Unused');

            const getAvailableTotal = getTotalValueWithMonth(
                getMonthWiseTotal(creditCardRecords?.total_credit_line_local_currency),
                'Credit Limit'
            );
            const getUsedTotal = getTotalValueWithMonth(
                getMonthWiseTotal(creditCardRecords?.running_balance_local_currency),
                'Amount Used'
            );
            const getUnusedTotal = getTotalValueWithMonth(
                getMonthWiseTotal(creditCardRecords?.available_credit_local_currency),
                'Amount Unused'
            );
            const excelObjectData = [...getAmountAvailable, ...getAvailableTotal];

            const newOutputArray = excelObjectData.map((item) => {
                const { 'Credit Limit': creditLimit, 'Account Name': amountName, Average, ...rest } = item;
                return { 'Credit Limit': creditLimit, 'Account Name': amountName, ...rest };
            });

            const addCurrencySymbol = newOutputArray.map((obj) => {
                const newObj = {};
                // eslint-disable-next-line no-restricted-syntax
                for (const key in obj) {
                    if (key !== 'Account Name' && key !== 'Credit Limit' && key !== 'Average') {
                        newObj[key] = `$ ${obj[key]}`;
                    } else {
                        newObj[key] = obj[key];
                    }
                }
                return newObj;
            });

            const data1 = [...addCurrencySymbol, {}];

            const newAmountData = [...getAmountUsed, ...getUsedTotal];

            const newAmountArray = newAmountData.map((item) => {
                const { 'Amount Used': amountUsed, Average, 'Account Name': amountName, ...rest } = item;
                return { 'Amount Used': amountUsed, 'Account Name': amountName, ...rest, Average };
            });
            console.log(newAmountArray);

            const addAmountCurrencySymbol = newAmountArray.map((obj) => {
                const newObj = {};
                // eslint-disable-next-line no-restricted-syntax
                for (const key in obj) {
                    if (key !== 'Account Name' && key !== 'Amount Used') {
                        newObj[key] = `$ ${obj[key]}`;
                    } else {
                        newObj[key] = obj[key];
                    }
                }
                return newObj;
            });

            const data3 = [...addAmountCurrencySymbol, {}];

            const newUnusedData = [...getAmountUnused, ...getUnusedTotal];
            const newArrayData1 = newUnusedData.map((item) => {
                const { 'Amount Unused': amountUnused, Average, 'Account Name': amountName, ...rest } = item;
                return { 'Amount Unused': amountUnused, 'Account Name': amountName, ...rest, Average };
            });

            const addUnusedCurrencySymbol = newArrayData1.map((obj) => {
                const newObj = {};
                // eslint-disable-next-line no-restricted-syntax
                for (const key in obj) {
                    if (key !== 'Account Name' && key !== 'Amount Unused') {
                        newObj[key] = `$ ${obj[key]}`;
                    } else {
                        newObj[key] = obj[key];
                    }
                }
                return newObj;
            });

            const data4 = [...addUnusedCurrencySymbol, {}];

            const style = [
                {
                    col: 'A1',
                    style: EXCEL_STYLE.boldHeader,
                },
                {
                    col: 'B1',
                    style: EXCEL_STYLE.boldHeader,
                },
            ];
            const data = [selectedFormData, data1, data3, data4];
            const cols = [
                { width: 25 },
                { width: 25 },
                { width: 17 },
                { width: 20 },
                { width: 13 },
                { width: 13 },
                { width: 13 },
                { width: 13 },
                { width: 13 },
                { width: 13 },
                { width: 13 },
                { width: 13 },
                { width: 13 },
            ];
            exportCustomExcel(
                data,
                style,
                cols,
                merges,
                reportName,
                7,
                ['I'],
                [false, false, false, false],
                [-1, 'A4', -1, -1]
            );
        }
    };

    return (
        <Page title={t(`${reportName}.title`)}>
            <TopMenu title="Asset and Liability Reports" videoLink={videoURL.statusOfCreditCardUsage} />
            <TopHeading heading={t(`${reportName}.subtitle`)} />
            <ContentStyle>
                <Grid container pt={2} pb={0} mt={0}>
                    <Grid item md={10.5} xs={12}>
                        <CreditCardFilter filtersData={filtersData} updateFilters={updateFilters} categories={[]} />
                    </Grid>
                    <Grid item md={1.5} xs={12} style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                        <SubmitButton
                            loading={loading}
                            size="large"
                            variant="contained"
                            onClick={() => getFilterData()}
                            className="loadingButtonStyle2"
                        >
                            Go
                        </SubmitButton>
                    </Grid>
                </Grid>

                <Grid container spacing={4} pt={0} marginTop={0} justifyContent="center" alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={12}>
                        <StickyTable>
                            <TableWrapper>
                                <TableStyle>
                                    {tableLoader ? (
                                        <tbody>
                                            <tr>
                                                <td colSpan={4}>
                                                    <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            {creditCardRecords.total_credit_line_local_currency?.length ||
                                            creditCardRecords.available_credit_local_currency?.length ||
                                            creditCardRecords.running_balance_local_currency?.length ? (
                                                    <>
                                                        <tr>
                                                            <ExtraTd>{''}</ExtraTd>
                                                            <ExtraTd>{''}</ExtraTd>
                                                            <ExtraTd>{''}</ExtraTd>
                                                            <ExtraTd>{''}</ExtraTd>
                                                            <ExtraTd>{''}</ExtraTd>
                                                        </tr>
                                                        {renderCreditCardRecords()}
                                                    </>
                                                ) : (
                                                    <tr>
                                                        <NoDataTd colSpan={4}>
                                                            {t('executive-summary.no-data-found')}
                                                        </NoDataTd>
                                                    </tr>
                                                )}
                                        </tbody>
                                    )}
                                </TableStyle>
                            </TableWrapper>
                        </StickyTable>
                    </Grid>
                </Grid>
                <ButtonText>
                    <LoadingButton
                        fullWidth
                        size="large"
                        variant="contained"
                        // disabled={}
                        onClick={() => getExcelSummary()}
                        className="loadingButtonStyle2"
                    >
                        Export
                    </LoadingButton>
                </ButtonText>
            </ContentStyle>
        </Page>
    );
}
