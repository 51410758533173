import PropTypes from 'prop-types';
import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

RHFTextField.propTypes = {
    name: PropTypes.string,
};

const InputLabelStyle = styled(InputLabel)(({ theme }) => ({
    fontSize: 16,
    color: theme.palette.primary.contrastText,
    transform: 'scale(1)',
    marginBottom: 10,
    [theme.breakpoints.up('xl')]: {
        fontSize: 20,
    },
    [theme.breakpoints.down('xl')]: {
        marginBottom: '8px',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#4B4B4B',
        marginLeft: '5px'
    },
}));

const BootstrapInput = styled(TextField)(({ theme, padding = '10px 12px', fontSize = 16 }) => ({
    label: {
        fontSize: 16,
        color: theme.palette.primary.contrastText,
        transform: 'scale(1)',
        marginBottom: 10,
    },
    'label + &': {
        marginTop: '0 !important',
        width: '100%',
        marginBottom: 40,
        [theme.breakpoints.down('xl')]: {
            marginBottom: 30,
        },
    },
    '& .MuiInputBase-root': {
        backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
        borderRadius: 10,
        '&:not(.Mui-error)': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#f8f8f8',
            },
            '+ .MuiFormHelperText-root': {
                marginLeft: 0,
            }
        },
    },
    '& .Mui-error': {
        marginLeft: 0,
    },
    '& .MuiInputBase-input': {
        width: '100%',
        borderRadius: 10,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
        fontSize,
        padding,
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
        '&:focus': {
            '& ~ .MuiOutlinedInput-notchedOutline': {
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            }
        },
        '&:-webkit-autofill': {
            boxShadow: 'inset 0 0 150px 0 #F8F8F8',
        },
    },
}));

export default function RHFTextField({ name, multiline, rows, label, ...other }) {
    const { control } = useFormContext();

    return (
        <>
            {label ? <InputLabelStyle shrink htmlFor="bootstrap-input">
                {label}
            </InputLabelStyle> : <></>}
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <BootstrapInput
                        {...field}
                        multiline={multiline}
                        rows={rows}
                        fullWidth
                        value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                        error={!!error}
                        helperText={error?.message}
                        {...other}
                    />
                )}
            />
        </>
    );
}
