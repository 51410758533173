import videoURL from "constants/helpVideoUrl";

export const MY_PROFILE_TILES = [
    {
        title: 'Personal Information',
        path: '/my-profile/personal-info',
        helpVideo: videoURL.step1Video
    },
    {
        title: 'Financial Institutions',
        path: '/my-profile/link-financial-institutions',
        helpVideo: videoURL.step2Video
    },
    {
        title: 'Budget',
        path: '/my-profile/budget',
        helpVideo: videoURL.budget
    },
    {
        title: 'Categories',
        path: '/my-profile/category',
        helpVideo: videoURL.step3Video
    },
    {
        title: 'Sub-Categories',
        path: '/my-profile/sub-category',
        helpVideo: videoURL.step4Video
    },
    {
        title: 'Change Password',
        path: '/my-profile/change-password',
    },
    {
        title: 'My Subscription Information',
        path: '/my-profile/subscription',
    },
    {
        title: 'Delete Account',
        path: '',
        isDangerZone: true,
    }
];