import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import image from 'assets/image';
import PageSubHeadingStyle from '../../../components/PageSubHeadingStyle';

export default function MoneyManagement() {
    const { t } = useTranslation();

    return (
        <div className="relativeParentDiv">
            <Grid className="sectionCommonPadding commonPadding">
                <Grid container spacing={2} pb={6} className="accountSettingStyle">
                    <Grid item md={8}>
                        <PageSubHeadingStyle
                            // headLineOne={'What’s Included on the MoolahMate Platform?'}
                            headLineTwo={t('how-it-works-moneyManagement.headline-1')}
                            // headLineThree={'See all your transactions clearly in one place and manage your finances with ease.'}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <img src={image.businessEfficiencyAnimation} alt="Header logo" />
                    </Grid>
                </Grid>
            </Grid>
            <img src={image.backgroundCurve1} alt='' className='backgroundImgStyle' />
        </div>
    );
}
