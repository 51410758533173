import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SubHeadingStyle from '../../../components/subHeadingStyle';
import { getAccountSetupDetails } from './constant'

export default function AccountSetup() {
    const { t } = useTranslation();
    const accountSetupDetails = getAccountSetupDetails(t);

    return (
        <div className='wrapper'>
            <Grid className='sectionCommonPadding'>
                <Grid container spacing={2} pt={6}>
                    <Grid item lg={4} md={6}>
                        <SubHeadingStyle
                            headLineTwo={t('how-it-works-accountSetup.headline-1')}
                        />
                    </Grid>
                </Grid>

                {
                    accountSetupDetails.map((item, indx) => (
                        <Grid container spacing={2} py={6} key={indx} className={item.id % 2 === 0 ? 'marginLeftStyle' : ''}>
                            <Grid item md={6} className={item.id % 2 === 0 ? 'mobileOrder' : ''} style={{ order: item.id % 2 === 0 ? 2 : 1, }}>
                                <div className='accountSetupStyle'>
                                    <div className="pointerStyle">
                                        <Typography variant='body'>{item.id}</Typography>
                                    </div>
                                    <Typography variant='h3'>{item.title}</Typography>
                                    <Typography variant='h6'>{item.subtext}</Typography>
                                    <Typography variant='body1'>{item.text}</Typography>
                                </div>
                            </Grid>
                            <Grid item md={6} className={item.id % 2 === 0 ? 'mobileOrder' : ''} style={{ order: item.id % 2 === 0 ? 1 : 2 }}>
                                <img src={item.img} alt={item.title}/>
                            </Grid>
                        </Grid>
                    ))
                }
            </Grid>
        </div>
    );
}
