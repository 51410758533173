import request from '../../utils/request';

const getPaymentToken = () => request.post('payment/token');
const createOrder = () => request.post('payment/order/generate');
const captureOrder = (orderID) => request.post(`payment/order/${orderID}/capture`);
const captureSubscription = (payload) => request.post(`payment/subscribe`, payload)

export default {
    getPaymentToken,
    createOrder,
    captureOrder,
    captureSubscription
};