import { Button, Grid, Link, List, Typography, styled } from '@mui/material';
import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { InfoIcon, LightTooltip } from 'theme/common-styles';

import videoURL from 'constants/helpVideoUrl';
import DialogAtom from 'components/dialog/Dialog';
import Page from '../../components/Page';
import TopHeading from '../../components/TopHeading';
import TopMenu from '../../components/TopMenu';
import user from '../../constants/services/user';
import { getSearchValues, noDataFound } from '../../utils/calCommonFunction';
import { MY_PROFILE_TILES } from './constant';

export const BackButtonStyle = styled(Button)(() => ({
    color: '#fff',
    marginBottom: '15px',
    padding: '5px 10px',
    fontSize: '13px',
    fontWeight: '300',
    textAlign: ' center',
    textTransform: 'none',
}));

export default function MyProfilePage() {
    const [viewStyle, setViewStyle] = useState('grid');
    const [searchValue, setSearchValue] = useState('');
    const searchRef = useRef();
    const [productList, setProductList] = useState(MY_PROFILE_TILES);
    const [showAccountDeleteDialog, setShowAccountDeleteDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    const navigate = useNavigate();

    const getDashboardGirdView = (record) =>
        record.map((item, key) => (
            <Grid key={key} item lg={3} md={4} sm={6} xs={12}>
                <Link onClick={(e)=> {
                    if (e.target.innerText) {
                        if (!item.isDangerZone) {
                            navigate(`${item.path}`)
                        } else {
                            setShowAccountDeleteDialog(true);
                        }
                    }
                }} style={{ textDecoration: 'none' }}>
                    <div className="hubCardStyle allReportStyle" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%',}} > 
                        <div>
                            <Typography variant="body1" className="descriptionHeading" style={{ color: item.isDangerZone ? 'red' : ''}}>
                                {item.title}
                            </Typography>
                        </div>
                        
                        {item.helpVideo && 
                            <LightTooltip
                                title={
                                    <>
                                        <p>{t('help-video.tooltip')}</p>
                                    </>
                                }
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open(item.helpVideo, "_blank");
                                }}
                            >
                                <InfoIcon src="/static/video-solid.svg" alt="" style={{ marginLeft: 5, marginTop: 6 }} />
                            </LightTooltip>
                        }
                    </div>
                </Link>
            </Grid>
        ));

    const getDashboardListView = (record) =>
        record.map((item, indx) => (
            <List key={indx}>
                <Link onClick={(e)=> {
                    if (e.target.innerText) {
                        if (!item.isDangerZone) {
                            navigate(`${item.path}`)
                        } else {
                            setShowAccountDeleteDialog(true);
                        }
                    }
                }} style={{ textDecoration: 'none' }}>
                    <div className="hubCardStyle listDisplayStyle flexDisplay" style={{ alignItems: 'center', justifyContent: 'space-between', width: '100%',}}>
                        <div>
                            <Typography variant="body1" className="descriptionHeading" style={{ color: item.isDangerZone ? 'red' : ''}}>
                                {item.title}
                            </Typography>
                        </div>
                        {item.helpVideo && 
                            <LightTooltip
                                title={
                                    <>
                                        <p>{t('help-video.tooltip')}</p>
                                    </>
                                }
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open(item.helpVideo, "_blank");
                                }}
                            >
                                <InfoIcon src="/static/video-solid.svg" alt="" style={{ marginLeft: 5, marginTop: 6 }} />
                            </LightTooltip>
                        }
                    </div>
                </Link>
            </List>
        ));

    const getSearchResultDetails = () => {
        if (searchValue) {
            const primaryResultValue = getSearchValues(MY_PROFILE_TILES, searchValue);
            setProductList(primaryResultValue);
        } else {
            setProductList(MY_PROFILE_TILES);
        }
    };

    const deboundHubSearch = debounce(() => {
        getSearchResultDetails();
    }, 500);

    useEffect(() => {
        deboundHubSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    const onDialogAction = async (buttonKey) => {
        if (buttonKey === 'cancel') {
            setShowAccountDeleteDialog(false);
        } else {
            setIsLoading(true);
            await user.deleteUserAccount();
            setIsLoading(false);
            setShowAccountDeleteDialog(false);
            sessionStorage.clear();
            localStorage.clear();
            navigate('/login', { replace: true });
        }
    };

    const getDeleteAccountDialog = () => (
        <DialogAtom
            open
            maxWidth="sm"
            dialogTitle={t('my-profile.delete-account')}
            content={<>{t('my-profile.delete-account-message')}</>}
            onDialogAction={onDialogAction}
            saveButtonLabel={t('my-profile.delete-account')}
            isLoading={isLoading}
        />
    );

    return (
        <Page title={t('my-profile.heading')}>
            <TopMenu title={t('my-profile.heading')} videoLink={videoURL.mySettings} />
            <TopHeading
                heading={t('welcome-constant.description-14')}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                searchRef={searchRef}
                setViewStyle={setViewStyle}
                viewStyle={viewStyle}
                showSearch
                hideBackLink
            />
            <Grid container spacing={2} mt={0} className="hubPadding hubCommonPadding">
                {viewStyle === 'grid' ? (
                    getDashboardGirdView(productList)
                ) : (
                    <div className="listViewStyle">{getDashboardListView(productList)}</div>
                )}
                <Grid mt={4} width={'100%'}>
                    {
                        productList.length ? '' : noDataFound()
                    }
                </Grid>
            </Grid>
            {showAccountDeleteDialog && getDeleteAccountDialog()}
        </Page>
    );
}
