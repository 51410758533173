import { Grid, Typography } from '@mui/material';
import image from 'assets/image';
import HeadingStyle from 'pages/about-us/HeadingStyle';
import { valuePoints } from './constant'
import SubHeadingStyle from '../../../components/subHeadingStyle';

export default function Benefits() {
    return (
        <Grid className="commonPadding bgColor">
            <Grid container spacing={2} justifyContent="space-around" className='smPaddingStyle'>
                <Grid item md={6}>
                    <img src={image.enjoyBenefits} alt="person" />
                </Grid>
                <Grid item md={6} className='benefitsofMoolahStyle'>
                    <SubHeadingStyle
                        headLineOne={'What happens when you subscribe?'}
                        headLineTwo={'Enjoy the benefits of MoolahMate.'}
                        headLineThree={''}
                    />
                    <Typography variant='body1' className='mobileViewText'>How soon will you be able to get out of debt? Can you afford that beach vacation or fancy car? When can you retire?</Typography>
                    <Typography variant='body1' className='mobileViewText'>Finding out is a snap! Try out "what if" scenarios in a pinch with our MoolahMate suite of handy calculators to crunch those numbers with ease</Typography>
                    {
                        valuePoints.map((item, indx)=>(
                            <div key={indx}>
                                <HeadingStyle
                                    title={item.title}
                                    text={item.text}
                                />
                            </div>
                        ))
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}
