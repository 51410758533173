import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
import { Box } from '@mui/material';

const Page = forwardRef(({ children, title = '', description = '', meta, ...other }, ref) => (
    <>
        <Helmet>
            <title>{`${title}`}</title>
            <meta name="description" content={description} />
            {meta}
        </Helmet>

        <Box ref={ref} {...other}>
            {children}
        </Box>
    </>
));

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    meta: PropTypes.node,
};

export default Page;
