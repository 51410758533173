import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import image from 'assets/image';
import AppDownloadIcons from 'components/app-download-icons/AppDownloadIcons';
import PageSubHeadingStyle from '../../../components/PageSubHeadingStyle';
import { StartedList } from './constant';

export default function GetStarted() {
    // const [windowScreen, setWindowScreen] = useState(window.innerWidth < 900);

    // useEffect(() => {
    //     const handleScreenResize = () => {
    //         setWindowScreen(window.innerWidth < 900);
    //     };

    //     window.addEventListener('resize', handleScreenResize);

    //     return () => {
    //         window.removeEventListener('resize', handleScreenResize);
    //     };
    // }, []);

    const { t } = useTranslation();

    const getStartedList = StartedList(t);

    return (
        <Grid className='sectionPadding mobileSliderPadding bgColor'>
            <Grid container spacing={2} className='getStartedStyle' width={'100%'}>
                <Grid item lg={6} md={12}>
                    <PageSubHeadingStyle
                        headLineOne={t('home-page.headline-6')}
                        headLineTwo={t('home-page.subtitle-6')}
                        headLineThree={t('home-page.subtitle-info-6')}
                    />
                    <div className='marginTopBottom mobileFlexShow'>
                        <LoadingButton className='loadingButtonStyle2' type="submit" variant="contained" href="/register">
                            {t('common-translation.Free Trial')}
                        </LoadingButton>
                        <LoadingButton className='loadingButtonStyle1 mobileBtnView' style={{ marginLeft: '6px' }} type="submit" variant="outlined" href="/how-it-works">
                            {t('common-translation.how-it-works')}
                        </LoadingButton>
                    </div>
                    <AppDownloadIcons />
                </Grid>
                <Grid item lg={6} md={12} className='parentImgStyle' mt={2}>
                    <img src={image.getStartedAnimation} alt="Header logo" className='getStartedImageStyle'/>
                </Grid>
                {
                    // windowScreen ?
                    //     <Grid container spacing={2} >
                    //         <MobileSlider
                    //             sliderDetails={getStartedList}
                    //         />
                    //     </Grid>
                    //     :
                    <Grid item lg={12} mt={0}>
                        <div className='easyStepMargin'>
                            <Grid container spacing={2}>
                                {
                                    getStartedList.map((list, index) => (
                                        <Grid item xl={4} lg={4} md={12} sm={12} key={index}>
                                            <div className='pointerDivStyle'>
                                                <div className='pointerWidthStyle'>
                                                    <div className='pointerStyle'>
                                                        <Typography variant='body'>{list.id}</Typography>
                                                    </div>
                                                    <Typography variant='body1'>{list.title}</Typography>
                                                    <Typography variant='body'>{list.simpleText}</Typography>
                                                </div>
                                            </div>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </div>
                    </Grid>
                }
            </Grid>
        </Grid>
    );
}
