import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { getCurrencySymbolByCurrencyCode } from 'constants/countryCurrencyData';
import { AuthContext } from 'contexts/AuthProvider';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import { InfoIcon } from 'theme/common-styles';
import { FormProvider, RHFTextField } from '../../components/hook-form';
import auth from '../../constants/services/auth';
import { showError, showSuccess } from '../../utils/toast';

const ButtonText = styled('div')(({ theme }) => ({
    '& button': {
        textTransform: 'inherit',
        fontSize: 16,
        padding: '6px 16px !important',
        [theme.breakpoints.down('md')]: {
            fontSize: '14px !important',
        },
    },
}));

export default function VerifyForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const authTokenRef = useRef();
    const { setAuth, setCurrencyMasterData } = useContext(AuthContext);
    const [disabled, setDisabled] = useState(true);
    const [counter, setCounter] = useState(60);

    useEffect(() => {
        authTokenRef.current = location?.state?.authToken || null;
        if (!authTokenRef.current) {
            navigate('/login', { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.state?.authToken]);

    useEffect(() => {
        if (counter === 0) {
            setDisabled(false);
        }
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const FormSchema = Yup.object().shape({
        authCode: Yup.string().required(t('error-message.verification-code-required')),
    });

    const defaultValues = {
        authCode: '',
    };

    const methods = useForm({
        resolver: yupResolver(FormSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        const { authCode } = data;
        const payload = { authCode, authToken: authTokenRef.current };
        try {
            const result = await auth.validate2FA(payload);
            const {
                email,
                firstName,
                lastName,
                token,
                id,
                onboardingStatus,
                localCurrency,
                username,
                currencyData,
                failedAccounts,
                role,
                isSubscribed,
                subscriptionExpiryDuration,
            } = result.data;

            if (isSubscribed === false) {
                const sessionData = {
                    id,
                    token,
                    isSubscribed,
                };
                sessionStorage.setItem('userData', JSON.stringify(sessionData));
                navigate('/payment', { replace: true });
                setAuth(sessionData);
            } else {
                const currencySymbol = getCurrencySymbolByCurrencyCode(localCurrency);

                let showDashboardAccountDialog = false;
                if (failedAccounts?.length) {
                    showDashboardAccountDialog = true;
                }
                const sessionData = {
                    email,
                    firstName,
                    lastName,
                    token,
                    username,
                    id,
                    currencySymbol,
                    localCurrency,
                    failedAccounts,
                    showDashboardAccountDialog,
                    role,
                    subscriptionExpiryDuration
                };
                sessionStorage.setItem('userData', JSON.stringify(sessionData));
                setAuth(sessionData);
                localStorage.setItem('currencyData', JSON.stringify(currencyData));
                setCurrencyMasterData(currencyData);
                showSuccess(t, 'login-page.login-success');
                const notificationFlag = 'true';
                switch (onboardingStatus) {
                    case 0:
                        navigate('/setup', { replace: true });
                        break;
                    case 5:
                        navigate('/welcome', { replace: true, state: { notificationFlag } });
                        break;
                    default:
                        navigate('/onboarding', { replace: true, state: { onboardingStatus } });
                }
            }
        } catch (error) {
            showError(t, error);
        }
    };

    const resendCode = async (e) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            setDisabled(true);
            setCounter(60);
            await auth.resend2FACode(authTokenRef.current);
        } catch (error) {
            showError(t, error);
        }
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <RHFTextField
                name="authCode"
                label={'  '}
                type="number"
                style={{ marginBottom: 20 }}
                onInput={(e) => {
                    e.target.value = e.target.value.toString().slice(0, 6);
                }}
            />
            <a
                href="#"
                className={`resend-approval-link ${disabled ? 'resend-approval-link-disabled' : ''}`}
                onClick={resendCode}
            >
                Send code again
            </a>
            {disabled && (
                <div id="timer">
                    <InfoIcon src="/static/linkFinancial/info-icon.svg" alt="" />
                    <p>Please wait {counter} seconds before requesting another code.</p>
                </div>
            )}
            <ButtonText>
                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    className="loadingButtonStyle2"
                    loading={isSubmitting}
                    style={{ marginTop: 10 }}
                >
                    {t('verify-code.submit')}
                </LoadingButton>
            </ButtonText>
        </FormProvider>
    );
}
