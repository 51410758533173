import Typography from '@mui/material/Typography';
import './style.css'

export default function HeadingStyle(props) {
    return (
        <div className="benefitsHeading">
            <Typography className="titleHeading" variant="body2">
                {props.title}
            </Typography>
            <Typography className="subTextStyle" variant="body2">
                {props.text}
            </Typography>
        </div>
    );
}
