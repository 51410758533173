import { Link as RouterLink } from 'react-router-dom';
import { styled,  Grid, Button, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Page from '../../components/Page';
import { HeaderStyle, LogoStyle, RootStyle, SectionStyle } from './styled-components';
import Logo from '../../components/Logo';

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    height: '90vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

export default function Page404() {
    const { t } = useTranslation();
    return (
        <Page title={t('forgot-password.title')}>
            <RootStyle>
                <SectionStyle />
                <Grid container direction="row" spacing={2} alignItems="baseline">
                    <HeaderStyle item xs={12} sm={6}>
                        <LogoStyle>
                            <Logo sx={{ width: 430, height: 'auto' }} />
                        </LogoStyle>
                    </HeaderStyle>
                    <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
                        <Typography variant="h3" paragraph>
                Sorry, page not found!
                        </Typography>

                        <Typography sx={{ color: 'text.secondary' }}>
                Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your
                spelling.
                        </Typography>

                        <Box
                            component="img"
                            src="/static/illustrations/illustration_404.svg"
                            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
                        />

                        <Button to="/" size="large" variant="contained" component={RouterLink}>
                Go to Home
                        </Button>
                    </ContentStyle>
                </Grid>
            </RootStyle>
        </Page>
    );
}
