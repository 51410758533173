// @mui
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// components
import { EXCEL_STYLE } from 'pages/calculators/constant';
import Bargraph from '../Graphs/Bargraph';
import dashboard from '../../../constants/services/dashboard';
import { exportCustomExcel, getCurrencySymbol, getNegativeNumber } from '../../../utils/calCommonFunction';
import {
    TableStyle,
    GridExtraSpacing,
    ExecutiveTbStart,
    ExecutiveTitleStyle,
    ExecutiveTbEnd,
    ExecutiveDivStyle,
    ButtonDivStyle,
    ExecutiveDivSpacing,
    NoDataTd,
    LoaderGif,
    TotalAmountDiv,
} from './styled-component';

export default function ExecutiveTopTransactionsTable({ getinvestmentChartData }) {
    const [tableLoader, setTableLoader] = useState(false);
    const [showExecutive, setShowExecutive] = useState(true);
    const [fiveExpenseYearData, setFiveExpenseYearData] = useState([]);
    const [fiveExpenseMonthData, setFiveExpenseMonthData] = useState([]);

    // chart
    const [topfiveTransYearChart, setTopfiveTransYearChart] = useState([]);
    const [topfiveTransMonthChart, setTopfiveTransMonthChart] = useState([]);

    const currencySymbol = getCurrencySymbol();
    const { t } = useTranslation();

    const getExecutiveSummaryData = async () => {
        const topFiveexpenseMonth = `topexpenses?type=month`;
        const topFiveexpenseYear = `topexpenses?type=year`;

        let fiveExpenseByMonth;
        let fiveExpenseByYear;

        try {
            fiveExpenseByMonth = await getIncInvestmentData(topFiveexpenseMonth);
            setFiveExpenseMonthData(fiveExpenseByMonth);
        } catch (error) {
            console.log('err', error);
        }

        try {
            fiveExpenseByYear = await getIncInvestmentData(topFiveexpenseYear);
            setFiveExpenseYearData(fiveExpenseByYear);
        } catch (error) {
            console.log('err', error);
        }

        const topfiveTraYearObj = getinvestmentChartData(fiveExpenseByYear, t('executive-summary.top-5-expense-year'));
        const topfiveTraMonthObj = getinvestmentChartData(
            fiveExpenseByMonth,
            t('executive-summary.top-5-expense-month')
        );

        setTopfiveTransYearChart(topfiveTraYearObj);
        setTopfiveTransMonthChart(topfiveTraMonthObj);
    };

    const getIncInvestmentData = async (selectdata) => {
        setTableLoader(true);
        try {
            const response = await dashboard.getDashboardCategoryDetails(selectdata);
            const data = await response.data;
            setTableLoader(false);
            return data;
        } catch (error) {
            console.log(error);
            setTableLoader(false);
        }
    };

    const exportExcel = () => {
        const expensesThisYear = fiveExpenseYearData.map((item) => ({
            Date: item.transaction_date,
            Payee: item.payee,
            Amount: `${currencySymbol}${getNegativeNumber(item.amount)}`,
        }));

        expensesThisYear.unshift(
            { Date: 'Top 5 Expenses that are Under Budget This Year', Payee: '', Amount: '' },
            { Date: 'Date', Payee: 'Payee', Amount: 'Amount' }
        );

        const expensesThisMonth = fiveExpenseMonthData.map((item) => ({
            Date: item.transaction_date,
            Payee: item.payee,
            Amount: `${currencySymbol}${getNegativeNumber(item.amount)}`,
        }));

        expensesThisMonth.unshift(
            { Date: 'Top 5 Expenses that are Under Budget This Month', Payee: '', Amount: '' },
            { Date: 'Date', Payee: 'Payee', Amount: 'Amount' }
        );

        const style = [
            {
                col: 'A1',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'E1',
                style: EXCEL_STYLE.boldHeader,
            },
        ];

        const cols = [
            { width: 18 },
            { width: 18 },
            { width: 18 },
            { width: 10 },
            { width: 18 },
            { width: 18 },
            { width: 18 },
        ];

        const merges = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } },
            { s: { r: 0, c: 4 }, e: { r: 0, c: 6 } },
        ];

        exportCustomExcel(
            [expensesThisYear, expensesThisMonth],
            style,
            cols,
            merges,
            'Top5Expenses',
            7,
            ['I'],
            [true, true],
            [-1, 'E1']
        );
    };

    useEffect(() => {
        getExecutiveSummaryData();
    }, []);

    return (
        <>
            {showExecutive ? (
                <ExecutiveDivStyle>
                    <ExecutiveDivSpacing style={{ padding: '24px 0px' }}>
                        <Grid
                            container
                            spacing={4}
                            justifyContent="center"
                            alignItems="flex-start"
                            style={{ margin: '0px', width: '100%' }}
                        >
                            <GridExtraSpacing item xl={6} lg={6} md={12} xs={12} className="overFlowTable">
                                <ExecutiveTitleStyle>
                                    {t('executive-summary.top-5-expense-transactions')}
                                </ExecutiveTitleStyle>
                                <ExecutiveTitleStyle>{t('executive-summary.this-year')}</ExecutiveTitleStyle>
                                <TableStyle>
                                    <thead>
                                        <tr>
                                            <ExecutiveTbStart style={{ width: '25%' }}>
                                                {t('executive-summary.date')}
                                            </ExecutiveTbStart>
                                            <th style={{ textAlign: 'start', width: '45%' }}>
                                                {t('executive-summary.payee')}
                                            </th>
                                            <th style={{ textAlign: 'end', width: '30%' }}>
                                                {t('executive-summary.amount')}
                                            </th>
                                        </tr>
                                    </thead>
                                    {tableLoader ? (
                                        <tbody>
                                            <tr>
                                                <td colSpan={4}>
                                                    <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            {fiveExpenseYearData?.length > 0 ? (
                                                fiveExpenseYearData?.map((item, indx) => (
                                                    <tr key={indx}>
                                                        <td>{item.transaction_date}</td>
                                                        <td>{item?.payee}</td>
                                                        <ExecutiveTbEnd>
                                                            <TotalAmountDiv>
                                                                {currencySymbol}{' '}
                                                                <span>{getNegativeNumber(item.amount)}</span>
                                                            </TotalAmountDiv>
                                                        </ExecutiveTbEnd>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <NoDataTd colSpan={3}>
                                                        {t('executive-summary.no-data-found')}
                                                    </NoDataTd>
                                                </tr>
                                            )}
                                        </tbody>
                                    )}
                                </TableStyle>
                            </GridExtraSpacing>
                            <hr className="middleBorderStyle" />
                            <GridExtraSpacing item xl={6} lg={6} md={12} xs={12} className="overFlowTable">
                                <ExecutiveTitleStyle>
                                    {t('executive-summary.top-5-expense-transactions')}
                                </ExecutiveTitleStyle>
                                <ExecutiveTitleStyle>{t('executive-summary.this-month')}</ExecutiveTitleStyle>
                                <TableStyle>
                                    <thead>
                                        <tr>
                                            <ExecutiveTbStart style={{ width: '25%' }}>
                                                {t('executive-summary.date')}
                                            </ExecutiveTbStart>
                                            <th style={{ textAlign: 'start', width: '45%' }}>
                                                {t('executive-summary.payee')}
                                            </th>
                                            <th style={{ textAlign: 'end', width: '30%' }}>
                                                {t('executive-summary.amount')}
                                            </th>
                                        </tr>
                                    </thead>
                                    {tableLoader ? (
                                        <tbody>
                                            <tr>
                                                <td colSpan={4}>
                                                    <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            {fiveExpenseMonthData?.length > 0 ? (
                                                fiveExpenseMonthData?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.transaction_date}</td>
                                                        <td>{item?.payee}</td>
                                                        <ExecutiveTbEnd>
                                                            <TotalAmountDiv>
                                                                {currencySymbol}{' '}
                                                                <span>{getNegativeNumber(item.amount)}</span>
                                                            </TotalAmountDiv>
                                                        </ExecutiveTbEnd>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <NoDataTd colSpan={3}>
                                                        {t('executive-summary.no-data-found')}
                                                    </NoDataTd>
                                                </tr>
                                            )}
                                        </tbody>
                                    )}
                                </TableStyle>
                            </GridExtraSpacing>
                        </Grid>
                    </ExecutiveDivSpacing>
                </ExecutiveDivStyle>
            ) : (
                <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{ margin: '0px', width: '100%' }}
                >
                    <Grid item xs={12} md={12} style={{ padding: '0px 0px 0px 0px' }}>
                        <Bargraph chartData={topfiveTransYearChart} currencyCode={currencySymbol} />
                    </Grid>
                    <Grid item xs={12} md={12} style={{ padding: '0px 0px 0px 0px' }}>
                        <Bargraph chartData={topfiveTransMonthChart} currencyCode={currencySymbol} />
                    </Grid>
                </Grid>
            )}
            <ButtonDivStyle>
                <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    // disabled={}
                    onClick={() => setShowExecutive(!showExecutive)}
                    className="loadingButtonStyle2"
                >
                    {showExecutive ? t('executive-summary.graph') : t('executive-summary.view-table')}
                </LoadingButton>
                <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    // disabled={}
                    onClick={exportExcel}
                    className="loadingButtonStyle2"
                >
                    Export
                </LoadingButton>
            </ButtonDivStyle>
        </>
    );
}
