/* eslint-disable react/jsx-key */
import React, { useRef } from 'react';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { clientReviewJson } from './constant'
import './style.css'
import ReviewCarousel from './ReviewCarousel';
import PageSubHeadingStyle from '../../../components/PageSubHeadingStyle';

export default function ClientReview() {
    const { t } = useTranslation();
    const sliderRef = useRef(useRef);

    const clientSliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <Grid className='sectionPadding bgColor mdPaddingBottom' id="reviews">
            <PageSubHeadingStyle
                headLineOne={t('client-review.heading-line-1')}
                headLineTwo={t('client-review.heading-line-2')}
                headLineThree={''}
            />
            <div className='slickSliderStyle'>
                <Slider {...clientSliderSettings} ref={sliderRef} style={{ marginBottom: '10px' }}>
                    {
                        clientReviewJson.map((review, indx) => (
                            <div key={indx}>
                                <ReviewCarousel
                                    clientImg={review.image}
                                    title={review.title}
                                    text={review.text}
                                    name={review.clientName}
                                    occupation={review.clientOccupation}
                                />
                            </div>
                        ))
                    }
                </Slider>
            </div>
            <Grid container spacing={2} pb={4} justifyContent={'center'} className='slickSliderStyle'>
                <Grid item md={4} className='mdDisplayShow'>
                    {''}
                </Grid>
                <Grid item md={8}>
                    <LoadingButton type="submit" variant="outlined" onClick={() => sliderRef?.current.slickPrev()}>
                        <ArrowBackIcon />
                    </LoadingButton>
                    <LoadingButton type="submit" variant="outlined" onClick={() => sliderRef?.current.slickNext()}>
                        <ArrowForwardIcon />
                    </LoadingButton>
                </Grid>
            </Grid>
        </Grid >
    );
}
