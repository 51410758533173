import Typography from '@mui/material/Typography';
import './styles.css'

export default function SubHeadingStyle(props) {
    return (
        <div className='subHeadingStyle'>
            <Typography variant="h6">{props.headLineOne}</Typography>
            <Typography variant="h2">{props.headLineTwo}</Typography>
            <Typography variant="h5">{props.headLineThree}</Typography>
        </div>
    );
}