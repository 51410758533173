import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import videoURL from 'constants/helpVideoUrl';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Page from '../../../components/Page';
import TopMenu from '../../../components/TopMenu';
import { getWelcomeScreenData } from './constant';
import { FullWidthWrapper, ListItem, RootStyle, StartButton, SubPoints, UlStyle } from './styled-components';

export default function Setup() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const welcomeScreenData = getWelcomeScreenData(t);
    const onSubmit = () => {
        navigate('/onboarding', { replace: true });
    };
    return (
        <Page title={t('onboarding.one-time-setup-title')}>
            <RootStyle>
                <div className='mdCustomPadding'>
                    <TopMenu title={t('step1.heading')} hideHelp videoLink={videoURL.onboardingVideo} />
                </div>
                <Grid container justifyContent={'center'} spacing={2} marginLeft={0} width={'100%'} marginTop={0}>
                    <Grid item xl={5} lg={7} md={9} xs={12} className='extraPaddingStyle'>
                        <FullWidthWrapper>
                            <div>
                                <Typography variant="h4" className='titleStyle margintitleStyle'>{t('onboarding.one-time-setup-title')}</Typography>
                                <Typography variant="h6" className='commonText'>{t('onboarding.one-time-setup-subtitle')}</Typography>
                            </div>
                            <ListItem className='mdlistStyle'>
                                {welcomeScreenData.map((data) => <li key={data.id}>
                                    <div className='pointerStyle'>
                                        <Typography variant='body'>{data.id}</Typography>
                                    </div>
                                    <Typography variant="h6" className='headingPointerStyle'>{data.title}</Typography>
                                    {data.content && <SubPoints variant="subtitle1">
                                        {data.content}
                                    </SubPoints>}
                                    {data.children &&
                                        <UlStyle>
                                            {data.children.map((child) => <li key={child.id}>{child.text}</li>)}
                                        </UlStyle>
                                    }
                                </li>)}
                            </ListItem>
                            <StartButton>
                                <LoadingButton type="submit" variant="contained" onClick={onSubmit} className='loadingButtonStyle2'>
                                    {t('onboarding.start-now')}
                                </LoadingButton>
                            </StartButton>
                        </FullWidthWrapper>
                    </Grid>
                </Grid>
            </RootStyle>
        </Page>
    );
}
