import { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import Page from '../../components/Page';
import policy from '../../constants/services/policy';
import transform from '../../constants/strapiHelper';

export default function TermsAndConditions() {
    const [isLogged, setIsLogged] = useState(false);
    const [tcInfo, setTcInfo] = useState('');
    const { t } = useTranslation();
    
    const getTCdata = async () => {
        const policyData = await policy.getPolicyContent('T&C');
        if (policyData?.data?.length && policyData?.data?.[0].attributes?.content) {
            setTcInfo(policyData?.data?.[0].attributes);
        }
    };

    useEffect(() => {
        getTCdata()
    }, []);

    return (
        <Page title={"MoolahMate's terms & conditions: Your personal money manager"} description={"MoolahMate's terms and conditions. Sign up today to see how you can save more, spend less and reduce your financial stress."} style={isLogged ? {} : {padding: '110px 8% 0px'}}>
            <div className="hubPadding">
                {ReactHtmlParser(tcInfo?.content, { transform: (node, index) => transform(node) })}
            </div>
        </Page>
    );
}
