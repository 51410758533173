// @mui
import { useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import Input from '@mui/material/Input';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
// components
import Page from '../../../components/Page';
import { EXCEL_STYLE } from '../constant';
import { ContentStyle, PaymentTitle, HeaderDivStyle, HeaderTableStyle, TableStyle, RetirementInput, ButtonText, AutoFieldStyle, RetirementPercentageInput, DiffTableContent, ExtraTdSpacing, TotalSubHeadingColumn, InputNumberField, InputNumberSubField, CenterTbHeading, CenterTbSubHeading, TopSideBar, BudgetInputNumber, SpanTotalFlow, CalculatorInputs, AddMoreButton, CalculatorInputNumber, TotalAmountDiv, LightTooltip, InfoIcon, RightTbText } from '../styled-components';
import calculator from '../../../constants/services/calculator';
import { formatDollarValues, removeNumberFormatting, formatZeroValues, downloadExcelHeader, getCurrencySymbol, goBackToMenu } from '../../../utils/calCommonFunction';
import TopMenu from '../../../components/TopMenu';
import TopHeading from '../../../components/TopHeading';

export default function CalculatorsPage() {
    // States
    const [currentYear, setCurrentYear] = useState("");
    const [currentAge, setCurrentAge] = useState("");
    const [retirementAge, setRetirementAge] = useState("");
    const [calculateTill, setCalculateTill] = useState("");
    const [currentInvestment, setCurrentInvestment] = useState("");
    const [annualReturn, setAnnualReturn] = useState("");
    const [annualContribution, setAnnualContribution] = useState("");
    const [tillIm, setTillIm] = useState("");
    const [monthlyCinSalary, setMonthlyCinSalary] = useState("");
    const [monthlyCinPension, setMonthlyCinPension] = useState("");
    const [monthlyCinAddAnother, setMonthlyCinAddAnother] = useState("");
    const [monthlyCoutRentMortgage, setMonthlyCoutRentMortgage] = useState("");
    const [monthlyCoutPropertyTaxes, setMonthlyCoutPropertyTaxes] = useState("");
    const [monthlyCoutUtilities, setMonthlyCoutUtilities] = useState("");
    const [monthlyCoutInsurance, setMonthlyCoutInsurance] = useState("");
    const [monthlyCoutCarloanLease, setMonthlyCoutCarloanLease] = useState("");
    const [monthlyCoutCargasTransport, setMonthlyCoutCargasTransport] = useState("");
    const [monthlyCoutCarRepairs, setMonthlyCoutCarRepairs] = useState("");
    const [monthlyCoutGroceries, setMonthlyCoutGroceries] = useState("");
    const [monthlyCoutEntertainment, setMonthlyCoutEntertainment] = useState("");
    const [monthlyCoutTravel, setMonthlyCoutTravel] = useState("");
    const [monthlyCoutOther, setMonthlyCoutOther] = useState("");

    const [shortfallWithdrawn, setShortfallWithdrawn] = useState("");
    const [annualInflation, setAnnualInflation] = useState("");

    const [rentMortgagePost, setRentMortgagePost] = useState("");
    const [monthlyCpostCarloanLease, setMonthlyCpostCarloanLease] = useState("");
    const [monthlyPostTaxes, setMonthlyPostTaxes] = useState("");
    const [monthlyCpostUtilities, setMonthlyCpostUtilities] = useState("");
    const [monthlyCpostInsurance, setMonthlyCpostInsurance] = useState("");
    const [monthlyCpostCargasTransport, setMonthlyCpostCargasTransport] = useState("");
    const [monthlyCpostGroceries, setMonthlyCpostGroceries] = useState("");
    const [monthlyCpostCarRepairs, setMonthlyCpostCarRepairs] = useState("");
    const [monthlyCpostEntertainment, setMonthlyCpostEntertainment] = useState("");
    const [monthlyCpostOther, setMonthlyCpostOther] = useState("");
    const [monthlyCpostTravel, setMonthlyCpostTravel] = useState("");
    const [assetIndex, setAssetIndex] = useState("");
    const [isLogged, setIsLogged] = useState(false)

    const [retirementSummaryDetails, setRetirementSummaryDetails] = useState([{
        assetTitle: '',
        assetValue: '',
        assetPoValue: '',
    }]);

    const [retirementPostDetails, setRetirementPostDetails] = useState([{
        assetPostTitle: '',
        assetPostValue: '',
        assetPreValue: '',
    }]);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const currencySymbol = getCurrencySymbol();

    // calculations
    const totaladdMoreInput = retirementSummaryDetails?.reduce((acc, obj) => acc + Number(removeNumberFormatting(obj?.assetValue)), 0);
    const totaladdPostMoreInput = retirementSummaryDetails?.reduce((acc, obj) => acc + Number(removeNumberFormatting(obj?.assetPoValue)), 0);
    const ContributionsTillYear = (tillIm === "" || currentAge === "" || currentYear === "") ? '0' : Number(tillIm) - Number(currentAge) + Number(currentYear);
    const TotalPreRetirement = Number(Number(removeNumberFormatting(monthlyCinSalary)) + Number(totaladdMoreInput)).toLocaleString("en-US", { minimumFractionDigits: 2 });
    const TotalPostRetirement = Number(Number(removeNumberFormatting(monthlyCinPension)) + Number(totaladdPostMoreInput)).toLocaleString("en-US", { minimumFractionDigits: 2 });

    const totaladdMonthlyCashOutflows = retirementPostDetails?.reduce((acc, obj) => acc + Number(removeNumberFormatting(obj?.assetPostValue)), 0);
    const totaladdMonthlyCashInflows = retirementPostDetails?.reduce((acc, obj) => acc + Number(removeNumberFormatting(obj?.assetPreValue)), 0);

    const TotalPostInMonthlyOutflows = Number(Number(removeNumberFormatting(rentMortgagePost)) + Number(removeNumberFormatting(monthlyPostTaxes)) + Number(removeNumberFormatting(monthlyCpostUtilities)) + Number(removeNumberFormatting(monthlyCpostInsurance)) + Number(removeNumberFormatting(monthlyCpostCarloanLease)) + Number(removeNumberFormatting(monthlyCpostCargasTransport)) + Number(removeNumberFormatting(monthlyCpostGroceries)) + Number(removeNumberFormatting(monthlyCpostCarRepairs)) + Number(removeNumberFormatting(monthlyCpostEntertainment)) + Number(removeNumberFormatting(monthlyCpostOther)) + Number(totaladdMonthlyCashOutflows) + Number(removeNumberFormatting(monthlyCpostTravel))).toLocaleString("en-US", { minimumFractionDigits: 2 })
    const TotalPreInMonthlyOutflows = Number(Number(removeNumberFormatting(monthlyCoutRentMortgage)) + Number(removeNumberFormatting(monthlyCoutPropertyTaxes)) + Number(removeNumberFormatting(monthlyCoutUtilities)) + Number(removeNumberFormatting(monthlyCoutInsurance)) + Number(removeNumberFormatting(monthlyCoutCarloanLease)) + Number(removeNumberFormatting(monthlyCoutCargasTransport)) + Number(removeNumberFormatting(monthlyCoutCarRepairs)) + Number(removeNumberFormatting(monthlyCoutGroceries)) + Number(removeNumberFormatting(monthlyCoutEntertainment)) + Number(removeNumberFormatting(monthlyCoutTravel)) + Number(totaladdMonthlyCashInflows) + Number(removeNumberFormatting(monthlyCoutOther))).toLocaleString("en-US", { minimumFractionDigits: 2 })

    const TotalPreRetirementShortFall = ((TotalPreRetirement === "" || TotalPreInMonthlyOutflows === "") ? '0' : Number(Number(removeNumberFormatting(TotalPreRetirement)) - Number(removeNumberFormatting(TotalPreInMonthlyOutflows)))).toLocaleString("en-US", { minimumFractionDigits: 2 });
    const TotalPostRetirementShortFall = (TotalPostRetirement === "" || TotalPostInMonthlyOutflows === "") ? '0' : Number(Number(removeNumberFormatting(TotalPostRetirement)) - Number(removeNumberFormatting(TotalPostInMonthlyOutflows))).toLocaleString("en-US", { minimumFractionDigits: 2 });

    const totalAnnualExcessShortfall = Number(Number(removeNumberFormatting(TotalPreRetirement)) * 12).toLocaleString("en-US", { minimumFractionDigits: 2 });
    const totalPostAnnualExcessShortfall = Number(Number(removeNumberFormatting(TotalPostRetirement)) * 12).toLocaleString("en-US", { minimumFractionDigits: 2 });

    const totalPostAnnualCashInflows = Number(Number(removeNumberFormatting(TotalPreInMonthlyOutflows)) * 12).toLocaleString("en-US", { minimumFractionDigits: 2 });
    const totalPostAnnualLivingExpense = Number(Number(removeNumberFormatting(TotalPostInMonthlyOutflows)) * 12).toLocaleString("en-US", { minimumFractionDigits: 2 });

    const subtractPreExcess = Number(Number(removeNumberFormatting(totalAnnualExcessShortfall)) - Number(removeNumberFormatting(totalPostAnnualCashInflows))).toLocaleString("en-US", { minimumFractionDigits: 2 });
    const subtractPostExcess = Number(Number(removeNumberFormatting(totalPostAnnualExcessShortfall)) - Number(removeNumberFormatting(totalPostAnnualLivingExpense))).toLocaleString("en-US", { minimumFractionDigits: 2 });

    const ShortfallCurrentPerValue = currentInvestment === "" ? '0' : (-Number(removeNumberFormatting(subtractPostExcess)) / Number(removeNumberFormatting(currentInvestment)) * 100).toFixed(2)

    const withDrawnOption = [
        { id: '0', label: 'Yes' },
        { id: '1', label: 'No' },
    ]

    const addRetirementInputFields = () => {
        setRetirementSummaryDetails([...retirementSummaryDetails, {
            assetTitle: '',
            assetValue: '',
            assetPoValue: ''
        }])
    }

    const retirementBudgetTargetValue = (i, e) => {
        setAssetIndex(i)
        const projectValues = [...retirementSummaryDetails];
        if (e.target.name !== "assetTitle") {
            projectValues[i][e.target.name] = formatDollarValues(e.target.value);
        }
        else {
            projectValues[i][e.target.name] = e.target.value;
        }
        setRetirementSummaryDetails(projectValues);
    }

    const getRetirementDetails = async () => {
        const getRetirementData = await calculator.getUserCalculator("retirement");
        if (getRetirementData.data) {
            const loanJsonData = getRetirementData.data?.calcJson;
            setCurrentYear(loanJsonData.current_year);
            setCurrentAge(loanJsonData.current_age);
            setRetirementAge(loanJsonData.retirement_age);
            setCalculateTill(loanJsonData.calculate_till);
            setCurrentInvestment(loanJsonData.current_investment);
            setAnnualReturn(loanJsonData.annual_return);
            setAnnualContribution(loanJsonData.annual_contribution);
            setTillIm(loanJsonData.till_im);
            setMonthlyCinSalary(loanJsonData.monthly_cin_salary);
            setMonthlyCinPension(loanJsonData.monthly_cin_pension);
            setMonthlyCinAddAnother(loanJsonData.monthly_cin_add_another);
            setRetirementSummaryDetails(loanJsonData.retirement_summary_data);
            setMonthlyCoutRentMortgage(loanJsonData.monthly_cout_rent_mortgage);
            setRentMortgagePost(loanJsonData.monthly_cpost_mortgage);
            setMonthlyCoutPropertyTaxes(loanJsonData.monthly_cout_property_taxes);
            setMonthlyPostTaxes(loanJsonData.monthly_property_post_taxes);
            setMonthlyCoutUtilities(loanJsonData.monthly_cout_utilities);
            setMonthlyCpostUtilities(loanJsonData.monthly_cpost_utilities);
            setMonthlyCoutInsurance(loanJsonData.monthly_cout_insurance);
            setMonthlyCpostInsurance(loanJsonData.monthly_cpost_insurance);
            setMonthlyCoutCarloanLease(loanJsonData.monthly_cout_carloan_lease);
            setMonthlyCpostCarloanLease(loanJsonData.monthly_cpost_carloan_lease);
            setMonthlyCoutCargasTransport(loanJsonData.monthly_cout_cargas_transport);
            setMonthlyCpostCargasTransport(loanJsonData.monthly_cpost_cargas_transport);
            setMonthlyCoutCarRepairs(loanJsonData.monthly_cout_car_repairs);
            setMonthlyCpostCarRepairs(loanJsonData.monthly_cpost_car_repairs);
            setMonthlyCoutGroceries(loanJsonData.monthly_cout_groceries);
            setMonthlyCpostGroceries(loanJsonData.monthly_cpost_groceries);
            setMonthlyCoutEntertainment(loanJsonData.monthly_cout_entertainment);
            setMonthlyCpostEntertainment(loanJsonData.monthly_cpost_entertainment);
            setMonthlyCoutTravel(loanJsonData.monthly_cout_travel);
            setMonthlyCpostTravel(loanJsonData.monthly_cpost_travel);
            setMonthlyCoutOther(loanJsonData.monthly_cout_other);
            setRetirementPostDetails(loanJsonData.retirement_post_details)
            setMonthlyCpostOther(loanJsonData.monthly_cpost_other);
            setShortfallWithdrawn(loanJsonData.shortfall_withdrawn);
            setAnnualInflation(loanJsonData.annual_inflation);
        }
    }

    useEffect(() => {
        if (sessionStorage.getItem('userData')) {
            const user = JSON.parse(sessionStorage.getItem('userData'));
            if (Object.keys(user).length > 0) {
                setIsLogged(true);
                getRetirementDetails();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addRetirementPostInput = () => {
        setRetirementPostDetails([...retirementPostDetails, {
            assetPostTitle: '',
            assetPostValue: '',
            assetPreValue: '',
        }])
    }

    const retirementBudgetPostValue = (i, e) => {
        const retirementValues = [...retirementPostDetails];
        if (e.target.name !== "assetPostTitle") {
            retirementValues[i][e.target.name] = formatDollarValues(e.target.value);
        }
        else {
            retirementValues[i][e.target.name] = e.target.value;
        }
        setRetirementPostDetails(retirementValues);
    }

    const retirementDetails = () => {
        if (currentYear !== "" && currentAge !== "" && retirementAge !== "" && calculateTill !== "" && currentInvestment !== ""
            && annualReturn !== "") {
            // const tillIm1 = tillIm;
            let ageTemp = currentAge;

            // if(tillIm1.trim() === "") {
            //     if(annualContribution !== "" && Number(annualContribution) > 0) {
            //         return;
            //     }
            // }else if((Number(tillIm1)) <= currentAge) {
            //     return;
            // }
            // else if(annualContribution === "" || Number(annualContribution) <= 0) {
            //     return;
            // }

            const detailsSummary = [];
            let element = [];
            let yearNoTemp = 0;
            let yeartemp = currentYear;
            let annualContriTemp = "";
            let annualWithdrawlTemp = "";
            let invReturnTemp = "";
            let incTemp = "";
            let investmentTemp = Number(removeNumberFormatting(currentInvestment));

            const summaryDataTemp = {
                age: Number(ageTemp),
                yearNo: yearNoTemp,
                year: Number(yeartemp),
                contribution: annualContriTemp,
                withdrawl: annualWithdrawlTemp,
                investmentReturn: invReturnTemp,
                inc: incTemp,
                investment: Number(investmentTemp.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2 })
            };
            detailsSummary.push(summaryDataTemp);

            yearNoTemp = Number(yearNoTemp) + 1;
            yeartemp = Number(yeartemp) + 1;
            ageTemp = Number(ageTemp) + 1;
            for (let i = ageTemp; i <= calculateTill; i += 1, yearNoTemp += 1, yeartemp += 1) {
                if (i <= tillIm) {
                    annualContriTemp = Number(removeNumberFormatting(annualContribution));
                    annualWithdrawlTemp = "";
                } else {
                    annualContriTemp = "";
                    if ((i > retirementAge)
                        && ((Number(removeNumberFormatting(subtractPostExcess)) < 0))
                        && (shortfallWithdrawn !== "" && shortfallWithdrawn.toUpperCase() === "YES")) {
                        annualWithdrawlTemp = (0 - Number(removeNumberFormatting(subtractPostExcess))) * ((1 + (Number(removeNumberFormatting(annualInflation)) / 100)) ** yearNoTemp);
                    } else {
                        annualWithdrawlTemp = "";
                    }
                }
                invReturnTemp = investmentTemp * (Number(removeNumberFormatting(annualReturn)) / 100);
                incTemp = (annualContriTemp === "" ? 0 : annualContriTemp) +
                    (invReturnTemp === "" ? 0 : invReturnTemp) -
                    (annualWithdrawlTemp === "" ? 0 : annualWithdrawlTemp);
                investmentTemp += incTemp;

                const annualContriValue = Number(Number(annualContriTemp).toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2 });
                const annualWithdrawlValue = Number(Number(annualWithdrawlTemp).toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2 });
                const invReturnValue = Number(Number(invReturnTemp).toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2 });
                const incTempValue = Number(Number(incTemp).toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2 });
                const investmentValue = Number(investmentTemp.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2 });

                const summaryDataTemp = {
                    age: i,
                    yearNo: yearNoTemp,
                    year: yeartemp,
                    contribution: annualContriValue,
                    withdrawl: annualWithdrawlValue,
                    investmentReturn: invReturnValue,
                    inc: incTempValue,
                    investment: investmentValue
                };
                detailsSummary.push(summaryDataTemp);
                element = Object.values(detailsSummary);
            }
            return detailsSummary;
        }
    };

    const resetFields = () => {
        setCurrentYear("");
        setCurrentAge("");
        setRetirementAge("");
        setCalculateTill("");
        setCurrentInvestment("");
        setAnnualReturn("");
        setAnnualContribution("");
        setTillIm("");
        setMonthlyCinSalary("");
        setMonthlyCinPension("");
        setMonthlyCinAddAnother("");
        setMonthlyCoutRentMortgage("");
        setRentMortgagePost("");
        setMonthlyCoutPropertyTaxes("");
        setMonthlyPostTaxes("");
        setMonthlyCoutUtilities("");
        setMonthlyCpostUtilities("");
        setMonthlyCoutInsurance("");
        setMonthlyCpostInsurance("");
        setMonthlyCoutCarloanLease("");
        setMonthlyCpostCarloanLease("");
        setMonthlyCoutCargasTransport("");
        setMonthlyCpostCargasTransport("");
        setMonthlyCoutCarRepairs("");
        setMonthlyCpostCarRepairs("");
        setMonthlyCoutGroceries("");
        setMonthlyCpostGroceries("");
        setMonthlyCoutEntertainment("");
        setMonthlyCpostEntertainment("");
        setMonthlyCoutTravel("");
        setMonthlyCpostTravel("");
        setMonthlyCoutOther("");
        setMonthlyCpostOther("");
        setShortfallWithdrawn("");
        setAnnualInflation("");
        setRetirementPostDetails([])
        setRetirementSummaryDetails([])
    }

    const savePresentData = async () => {
        try {
            const postData = {
                "current_year": currentYear,
                "current_age": currentAge,
                "retirement_age": retirementAge,
                "calculate_till": calculateTill,
                "current_investment": currentInvestment,
                "annual_return": annualReturn,
                "annual_contribution": annualContribution,
                "till_im": tillIm,
                "monthly_cin_salary": monthlyCinSalary,
                "retirement_summary_data": retirementSummaryDetails,
                "monthly_cin_pension": monthlyCinPension,
                "monthly_cin_add_another": monthlyCinAddAnother,
                "monthly_cout_rent_mortgage": monthlyCoutRentMortgage,
                "monthly_cpost_mortgage": rentMortgagePost,
                "monthly_cout_property_taxes": monthlyCoutPropertyTaxes,
                "monthly_property_post_taxes": monthlyPostTaxes,
                "monthly_cout_utilities": monthlyCoutUtilities,
                "monthly_cpost_utilities": monthlyCpostUtilities,
                "monthly_cout_insurance": monthlyCoutInsurance,
                "monthly_cpost_insurance": monthlyCpostInsurance,
                "monthly_cout_carloan_lease": monthlyCoutCarloanLease,
                "monthly_cpost_carloan_lease": monthlyCpostCarloanLease,
                "monthly_cout_cargas_transport": monthlyCoutCargasTransport,
                "monthly_cpost_cargas_transport": monthlyCpostCargasTransport,
                "monthly_cout_car_repairs": monthlyCoutCarRepairs,
                "monthly_cpost_car_repairs": monthlyCpostCarRepairs,
                "monthly_cout_groceries": monthlyCoutGroceries,
                "monthly_cpost_groceries": monthlyCpostGroceries,
                "monthly_cout_entertainment": monthlyCoutEntertainment,
                "monthly_cpost_entertainment": monthlyCpostEntertainment,
                "monthly_cout_travel": monthlyCoutTravel,
                "monthly_cpost_travel": monthlyCpostTravel,
                "retirement_post_details": retirementPostDetails,
                "monthly_cout_other": monthlyCoutOther,
                "monthly_cpost_other": monthlyCpostOther,
                "shortfall_withdrawn": shortfallWithdrawn,
                "annual_inflation": annualInflation,
            }
            const embeddedDataObj = {
                "calcType": "retirement",
                "calcJson": postData
            };

            await calculator.addUserCalculator(embeddedDataObj);
            toast.success('Calculation saved successfully');
        } catch (error) {
            toast.error(error.message);
        }
    }
    const appendRetirementSummaryDetails = retirementSummaryDetails.map(item => {
        const obj = {}
        obj.year = item.assetTitle;
        obj.contribution = `${currencySymbol} ${item.assetValue}`;
        obj.withdrawl = `${currencySymbol} ${item.assetPoValue}`;
        return obj;
    })

    const appendRetirementPostDetails = retirementPostDetails.map(item => {
        const obj = {}
        obj.year = item.assetPostTitle;
        obj.contribution = `${currencySymbol} ${item.assetPostValue}`;
        obj.withdrawl = `${currencySymbol} ${item.assetPreValue}`;
        return obj;
    })
    const exportExcel = () => {
        const postData = [
            {
                age: '',
                yearNo: '',
                year: 'Discription',
                contribution: 'Values',
                withdrawl: '',
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Current year',
                contribution: currentYear,
            },
            {
                year: "Current age",
                contribution: currentAge,
            },
            {
                year: 'Retirement age',
                contribution: retirementAge,
            },
            {
                year: 'Calculate till I am',
                contribution: `${calculateTill}`,
            },
            {
                year: 'Current investment value',
                contribution: `${currencySymbol} ${currentInvestment}`,
            },
            {
                year: 'Annual investment return',
                contribution: `${annualReturn} %`,
            },
            {
                year: 'Additional annual contributions of',
                contribution: `${currencySymbol} ${annualContribution}`,
                withdrawl: "Till I'm",
                investmentReturn: tillIm,
                inc: 'Year',
                investment: ContributionsTillYear
            },
            {
                year: 'Annual inflation rate',
                contribution: `${annualInflation} %`,
                withdrawl: '',
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: '',
                contribution: '',
                withdrawl: '',
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: '',
                contribution: 'Monthly',
                withdrawl: '',
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: '',
                contribution: 'Pre - Retirement',
                withdrawl: 'Post - Retirement',
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Monthly Cash Inflows',
                contribution: '',
                withdrawl: '',
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Salary',
                contribution: `${currencySymbol} ${monthlyCinSalary}`,
                withdrawl: '',
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Pension',
                contribution: '',
                withdrawl: `${currencySymbol} ${monthlyCinPension}`,
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            ...appendRetirementSummaryDetails,
            {
                year: '',
                contribution: `${currencySymbol} ${TotalPreRetirement}`,
                withdrawl: `${currencySymbol} ${TotalPostRetirement}`,
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: '',
                contribution: '',
                withdrawl: '',
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Monthly Cash Outflows',
                contribution: '',
                withdrawl: '',
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Rent/Mortgage',
                contribution: `${currencySymbol} ${monthlyCoutRentMortgage}`,
                withdrawl: `${currencySymbol} ${rentMortgagePost}`,
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Property taxes',
                contribution: `${currencySymbol} ${monthlyCoutPropertyTaxes}`,
                withdrawl: `${currencySymbol} ${monthlyPostTaxes}`,
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Utilities',
                contribution: `${currencySymbol} ${monthlyCoutUtilities}`,
                withdrawl: `${currencySymbol} ${monthlyCpostUtilities}`,
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Insurance',
                contribution: `${currencySymbol} ${monthlyCoutInsurance}`,
                withdrawl: `${currencySymbol} ${monthlyCpostInsurance}`,
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Car loan/lease',
                contribution: `${currencySymbol} ${monthlyCoutCarloanLease}`,
                withdrawl: `${currencySymbol} ${monthlyCpostCarloanLease}`,
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Car gas/transportation',
                contribution: `${currencySymbol} ${monthlyCoutCargasTransport}`,
                withdrawl: `${currencySymbol} ${monthlyCpostCargasTransport}`,
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Car repairs',
                contribution: `${currencySymbol} ${monthlyCoutCarRepairs}`,
                withdrawl: `${currencySymbol} ${monthlyCpostCarRepairs}`,
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Groceries',
                contribution: `${currencySymbol} ${monthlyCoutGroceries}`,
                withdrawl: `${currencySymbol} ${monthlyCpostGroceries}`,
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Entertainment',
                contribution: `${currencySymbol} ${monthlyCoutEntertainment}`,
                withdrawl: `${currencySymbol} ${monthlyCpostEntertainment}`,
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Travel',
                contribution: `${currencySymbol} ${monthlyCoutTravel}`,
                withdrawl: `${currencySymbol} ${monthlyCpostTravel}`,
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Other',
                contribution: `${currencySymbol} ${monthlyCoutOther}`,
                withdrawl: `${currencySymbol} ${monthlyCpostOther}`,
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            ...appendRetirementPostDetails,
            {
                year: '',
                contribution: `${currencySymbol} ${TotalPreInMonthlyOutflows}`,
                withdrawl: `${currencySymbol} ${TotalPostInMonthlyOutflows}`,
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Monthy Excess or (shortfall)',
                contribution: `${currencySymbol} ${TotalPreRetirementShortFall}`,
                withdrawl: `${currencySymbol} ${TotalPostRetirementShortFall}`,
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: '',
                contribution: 'Annual',
                withdrawl: '',
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: '',
                contribution: 'Pre - Retirement',
                withdrawl: 'Post - Retirement',
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Monthly Cash Outflows',
                contribution: '',
                withdrawl: '',
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Annual cash inflows',
                contribution: `${currencySymbol} ${totalAnnualExcessShortfall}`,
                withdrawl: `${currencySymbol} ${totalPostAnnualExcessShortfall}`,
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Annual living expenses',
                contribution: `${currencySymbol} ${totalPostAnnualCashInflows}`,
                withdrawl: `${currencySymbol} ${totalPostAnnualLivingExpense}`,
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Excess or (shortfall)',
                contribution: `${currencySymbol} ${subtractPreExcess}`,
                withdrawl: `${currencySymbol} ${subtractPostExcess}`,
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Shortfall as % of current investment value',
                contribution: '',
                withdrawl: `${ShortfallCurrentPerValue} %`,
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {
                year: 'Shortfall (if any) withdrawn from Investment?',
                contribution: shortfallWithdrawn,
                withdrawl: '',
                investmentReturn: '',
                inc: '',
                investment: '',
            },
            {

            },
            {

            },
            {
                age: 'Age',
                yearNo: 'Year #',
                year: 'Year',
                contribution: 'Annual Contribution',
                withdrawl: 'Annual Net Withdrawals Post Retirement',
                investmentReturn: 'Inv Return',
                inc: 'Net Inc (Dec)',
                investment: 'Investment',
            },
        ];
        const style = [
            {
                "col": 'C1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'D1',
                "style": EXCEL_STYLE.boldHeader
            },
            // {
            //     "col": 'A44',
            //     "style": EXCEL_STYLE.boldHeader
            // },
            // {
            //     "col": 'B44',
            //     "style": EXCEL_STYLE.boldHeader
            // },
            // {
            //     "col": 'C44',
            //     "style": EXCEL_STYLE.boldHeader
            // },
            // {
            //     "col": 'D44',
            //     "style": EXCEL_STYLE.boldHeader
            // },
            // {
            //     "col": 'E44',
            //     "style": EXCEL_STYLE.boldHeader
            // },
            // {
            //     "col": 'F44',
            //     "style": EXCEL_STYLE.boldHeader
            // },
            // {
            //     "col": 'G44',
            //     "style": EXCEL_STYLE.boldHeader
            // },
            // {
            //     "col": 'H44',
            //     "style": EXCEL_STYLE.boldHeader
            // }
        ]
        const finalExcel = postData.concat(retirementDetails()?.map((item) => ({
            ...item,
            contribution: `${currencySymbol} ${item?.contribution}`,
            withdrawl: `${currencySymbol} ${item?.withdrawl}`,
            investmentReturn: `${currencySymbol} ${item?.investmentReturn}`,
            inc: `${currencySymbol} ${item?.inc}`,
            investment: `${currencySymbol} ${item?.investment}`,
        })))
        const cols = [{ width: 12 }, { width: 12 }, { width: 40 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }];
        const merges = [
            // { s: { r: 5, c: 0 }, e: { r: 5, c: 2 } },
            // { s: { r: 7, c: 0 }, e: { r: 7, c: 2 } },
            // { s: { r: 11, c: 0 }, e: { r: 11, c: 2 } },
            // { s: { r: 13, c: 0 }, e: { r: 13, c: 2 } },
        ]
        downloadExcelHeader(finalExcel, style, cols, merges, 'Retirement', 2, [], true);
    }

    return (
        <Page title={"Enjoy peace of mind with MoolahMate's retirement calculator"} description={"Calculate the amount of funds you will have at retirement and beyond with MoolahMate's retirement calculator. MoolahMate makes saving easy."} className={isLogged ? '' : 'homeCalcultorsStyle'}>
            {isLogged && <TopMenu title={t('retirement.calculator')} subText="Need Help?" hideBellIcon />}
            <TopHeading heading={t('retirement.subtitle')} headingCenter={!isLogged} hideBackLink={!isLogged} />
            <div className={isLogged ? 'contentStyle' : 'topCalcPadding'} style={{ padding: isLogged ? '20px 4%' : '' }}>
                <Grid container spacing={0} pt={0} justifyContent="center" alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={12}>
                        <HeaderDivStyle>
                            <PaymentTitle>{t('retirement.description')}</PaymentTitle>
                            <div className="mobileOverFlowDiv">
                                <HeaderTableStyle>
                                    <tbody>
                                        <tr>
                                            <td>{t('retirement.current-year')}</td>
                                            <RetirementInput>
                                                <span style={{ padding: '5px' }}>{''}</span>
                                                <Input
                                                    type="text"
                                                    name="current_year"
                                                    value={currentYear}
                                                    placeholder="0"
                                                    onChange={(e) => {
                                                        setCurrentYear(e.target.value);
                                                    }}
                                                />
                                            </RetirementInput>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.current-age')}</td>
                                            <RetirementInput>
                                                <span style={{ padding: '5px' }}>{''}</span>
                                                <Input
                                                    type="text"
                                                    name="current_age"
                                                    value={currentAge}
                                                    placeholder="0"
                                                    onChange={(e) => {
                                                        setCurrentAge(e.target.value);
                                                    }}
                                                />
                                            </RetirementInput>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.retirement-age')}</td>
                                            <RetirementInput>
                                                <span style={{ padding: '5px' }}>{''}</span>
                                                <Input
                                                    type="text"
                                                    name="retirement_age"
                                                    value={retirementAge}
                                                    placeholder="0"
                                                    onChange={(e) => {
                                                        setRetirementAge(e.target.value);
                                                    }}
                                                />
                                            </RetirementInput>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.calculate-till')}</td>
                                            <RetirementInput>
                                                <span style={{ padding: '5px' }}>{''}</span>
                                                <Input
                                                    type="text"
                                                    name="calculate_till"
                                                    value={calculateTill}
                                                    placeholder="0"
                                                    onChange={(e) => {
                                                        setCalculateTill(e.target.value);
                                                    }}
                                                />
                                                <InputNumberSubField>
                                                    <div style={{ minWidth: 180 }}>years old</div>
                                                </InputNumberSubField>
                                            </RetirementInput>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.current-investment')}</td>
                                            <RetirementInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="current_investment"
                                                    value={currentInvestment}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setCurrentInvestment(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setCurrentInvestment(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </RetirementInput>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.annual-return')}</td>
                                            <RetirementPercentageInput>
                                                <span style={{ padding: '5px' }}>{''}</span>
                                                <Input
                                                    type="text"
                                                    name="annual_return"
                                                    value={annualReturn}
                                                    placeholder="0"
                                                    onChange={(e) => {
                                                        setAnnualReturn(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                                <span>%</span>
                                            </RetirementPercentageInput>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.annual-contribution')}</td>
                                            <InputNumberField>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="annual_contribution"
                                                    value={annualContribution}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setAnnualContribution(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setAnnualContribution(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </InputNumberField>
                                            <InputNumberSubField>
                                                <span>{t('retirement.till-im')}</span>
                                                <Input
                                                    type="text"
                                                    name="till_im"
                                                    value={tillIm}
                                                    placeholder="0"
                                                    onChange={(e) => {
                                                        setTillIm(e.target.value);
                                                    }}
                                                />
                                            </InputNumberSubField>
                                            <InputNumberSubField>
                                                <div style={{ minWidth: 205 }}>
                                                    years old or Year:{' '}
                                                    {ContributionsTillYear === 'NaN' ? '0' : ContributionsTillYear}
                                                </div>
                                            </InputNumberSubField>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.annual-inflation')}</td>
                                            <RetirementPercentageInput>
                                                <Input
                                                    type="text"
                                                    name="annual_inflation"
                                                    value={annualInflation}
                                                    placeholder="0"
                                                    onChange={(e) => {
                                                        setAnnualInflation(e.target.value);
                                                    }}
                                                />
                                                <span>%</span>
                                            </RetirementPercentageInput>
                                        </tr>
                                        <ExtraTdSpacing>
                                            <td> </td>
                                        </ExtraTdSpacing>
                                        <tr>
                                            <td>{''}</td>
                                            <CenterTbSubHeading colSpan={2}>Monthly</CenterTbSubHeading>
                                        </tr>
                                        <tr>
                                            <td>{''}</td>
                                            <TotalSubHeadingColumn>
                                                Pre - Retirement
                                                <LightTooltip
                                                    title={
                                                        <>
                                                            <p>{t('retirement.pre-retirement-tool-tip')}</p>
                                                        </>
                                                    }
                                                >
                                                    <InfoIcon
                                                        style={{ position: 'initial' }}
                                                        src="/static/linkFinancial/info-icon.svg"
                                                        alt=""
                                                    />
                                                </LightTooltip>
                                            </TotalSubHeadingColumn>
                                            <TotalSubHeadingColumn>
                                                Post - Retirement
                                                <LightTooltip
                                                    title={
                                                        <>
                                                            <p>{t('retirement.post-retirement-tool-tip')}</p>
                                                        </>
                                                    }
                                                >
                                                    <InfoIcon
                                                        style={{ position: 'initial' }}
                                                        src="/static/linkFinancial/info-icon.svg"
                                                        alt=""
                                                    />
                                                </LightTooltip>
                                            </TotalSubHeadingColumn>
                                        </tr>
                                        <tr>
                                            <CenterTbHeading>Monthly Cash Inflows</CenterTbHeading>
                                            <td>{''}</td>
                                            <td>{''}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.monthly-cin-salary')}</td>
                                            <RetirementInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cin_salary"
                                                    value={monthlyCinSalary}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCinSalary(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCinSalary(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </RetirementInput>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.monthly-cin-pension')}</td>
                                            <td>{''}</td>
                                            <RetirementInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cin_pension"
                                                    value={monthlyCinPension}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCinPension(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCinPension(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </RetirementInput>
                                        </tr>
                                        {retirementSummaryDetails.map((item, inx) => (
                                            <>
                                                <tr key={inx}>
                                                    <CalculatorInputNumber>
                                                        <span style={{ padding: '5px' }}>{''}</span>
                                                        <Input
                                                            type="text"
                                                            name="assetTitle"
                                                            value={item.assetTitle}
                                                            placeholder="Add Another"
                                                            onChange={(e) => {
                                                                retirementBudgetTargetValue(inx, e);
                                                            }}
                                                        />
                                                    </CalculatorInputNumber>
                                                    <BudgetInputNumber>
                                                        <span>{currencySymbol}</span>
                                                        <Input
                                                            type="text"
                                                            name="assetValue"
                                                            value={item.assetValue}
                                                            placeholder={'0'}
                                                            onBlur={(e) => {
                                                                retirementSummaryDetails[inx].assetValue =
                                                                    formatZeroValues(e.target.value);
                                                                setRetirementSummaryDetails([
                                                                    ...retirementSummaryDetails,
                                                                ]);
                                                            }}
                                                            onChange={(e) => {
                                                                retirementBudgetTargetValue(inx, e);
                                                            }}
                                                        />
                                                    </BudgetInputNumber>
                                                    <BudgetInputNumber>
                                                        <span>{currencySymbol}</span>
                                                        <Input
                                                            type="text"
                                                            name="assetPoValue"
                                                            value={item.assetPoValue}
                                                            placeholder={'0'}
                                                            onBlur={(e) => {
                                                                retirementSummaryDetails[inx].assetPoValue =
                                                                    formatZeroValues(e.target.value);
                                                                setRetirementSummaryDetails([
                                                                    ...retirementSummaryDetails,
                                                                ]);
                                                            }}
                                                            onChange={(e) => {
                                                                retirementBudgetTargetValue(inx, e);
                                                            }}
                                                        />
                                                    </BudgetInputNumber>
                                                </tr>
                                            </>
                                        ))}
                                        <tr>
                                            <td>
                                                <AddMoreButton onClick={addRetirementInputFields}>
                                                    Add Another
                                                </AddMoreButton>
                                            </td>
                                        </tr>
                                        <tr>
                                            <TopSideBar>{''}</TopSideBar>
                                            <SpanTotalFlow>
                                                <TotalAmountDiv style={{ marginLeft: 0, width: 151 }}>
                                                    {currencySymbol}
                                                    <span>
                                                        {TotalPreRetirement === 'NaN' ? '0.00' : TotalPreRetirement}
                                                    </span>
                                                </TotalAmountDiv>
                                            </SpanTotalFlow>
                                            <SpanTotalFlow>
                                                <TotalAmountDiv style={{ marginLeft: 0, width: 151 }}>
                                                    {currencySymbol}
                                                    <span>
                                                        {' '}
                                                        {TotalPostRetirement === 'NaN' ? '0.00' : TotalPostRetirement}
                                                    </span>
                                                </TotalAmountDiv>
                                            </SpanTotalFlow>
                                        </tr>
                                        <ExtraTdSpacing>
                                            <td> </td>
                                        </ExtraTdSpacing>
                                        <tr>
                                            <CenterTbHeading>Monthly Cash Outflows</CenterTbHeading>
                                            <td>{''}</td>
                                            <td>{''}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.monthly-cout-rent-mortgage')}</td>
                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cout_rent_mortgage"
                                                    value={monthlyCoutRentMortgage}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCoutRentMortgage(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCoutRentMortgage(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInputNumber>

                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cpost_mortgage"
                                                    value={rentMortgagePost}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setRentMortgagePost(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setRentMortgagePost(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInputNumber>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.monthly-cout-property-taxes')}</td>
                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cout_property_taxes"
                                                    value={monthlyCoutPropertyTaxes}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCoutPropertyTaxes(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCoutPropertyTaxes(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInputNumber>

                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_property_post_taxes"
                                                    value={monthlyPostTaxes}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyPostTaxes(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyPostTaxes(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInputNumber>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.monthly-cout-utilities')}</td>
                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cout_utilities"
                                                    value={monthlyCoutUtilities}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCoutUtilities(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCoutUtilities(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInputNumber>

                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cpost_utilities"
                                                    value={monthlyCpostUtilities}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCpostUtilities(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCpostUtilities(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInputNumber>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.monthly-cout-insurance')}</td>
                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cout_insurance"
                                                    value={monthlyCoutInsurance}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCoutInsurance(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCoutInsurance(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInputNumber>

                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cpost_insurance"
                                                    value={monthlyCpostInsurance}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCpostInsurance(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCpostInsurance(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInputNumber>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.monthly-cout-carloan-lease')}</td>
                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cout_carloan_lease"
                                                    value={monthlyCoutCarloanLease}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCoutCarloanLease(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCoutCarloanLease(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInputNumber>

                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cpost_carloan_lease"
                                                    value={monthlyCpostCarloanLease}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCpostCarloanLease(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCpostCarloanLease(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInputNumber>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.monthly-cout-cargas-transport')}</td>
                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cout_cargas_transport"
                                                    value={monthlyCoutCargasTransport}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCoutCargasTransport(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCoutCargasTransport(
                                                            formatDollarValues(e.target.value)
                                                        );
                                                    }}
                                                />
                                            </BudgetInputNumber>

                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cpost_cargas_transport"
                                                    value={monthlyCpostCargasTransport}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCpostCargasTransport(
                                                            formatZeroValues(e.target.value)
                                                        );
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCpostCargasTransport(
                                                            formatDollarValues(e.target.value)
                                                        );
                                                    }}
                                                />
                                            </BudgetInputNumber>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.monthly-cout-car-repairs')}</td>
                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cout_car_repairs"
                                                    value={monthlyCoutCarRepairs}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCoutCarRepairs(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCoutCarRepairs(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInputNumber>

                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cpost_car_repairs"
                                                    value={monthlyCpostCarRepairs}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCpostCarRepairs(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCpostCarRepairs(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInputNumber>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.monthly-cout-groceries')}</td>
                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cout_groceries"
                                                    value={monthlyCoutGroceries}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCoutGroceries(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCoutGroceries(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInputNumber>

                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cpost_groceries"
                                                    value={monthlyCpostGroceries}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCpostGroceries(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCpostGroceries(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInputNumber>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.monthly-cout-entertainment')}</td>
                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cout_entertainment"
                                                    value={monthlyCoutEntertainment}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCoutEntertainment(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCoutEntertainment(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInputNumber>

                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cpost_entertainment"
                                                    value={monthlyCpostEntertainment}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCpostEntertainment(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCpostEntertainment(
                                                            formatDollarValues(e.target.value)
                                                        );
                                                    }}
                                                />
                                            </BudgetInputNumber>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.monthly-cout-travel')}</td>
                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cout_travel"
                                                    value={monthlyCoutTravel}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCoutTravel(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCoutTravel(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInputNumber>

                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cpost_travel"
                                                    value={monthlyCpostTravel}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCpostTravel(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCpostTravel(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInputNumber>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.monthly-cout-other')}</td>
                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cout_other"
                                                    value={monthlyCoutOther}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCoutOther(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCoutOther(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInputNumber>

                                            <BudgetInputNumber>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type="text"
                                                    name="monthly_cpost_other"
                                                    value={monthlyCpostOther}
                                                    placeholder="0"
                                                    onBlur={(e) => {
                                                        setMonthlyCpostOther(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyCpostOther(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInputNumber>
                                        </tr>
                                        {retirementPostDetails.map((item, ind) => (
                                            <>
                                                <tr key={ind}>
                                                    <CalculatorInputNumber>
                                                        <span style={{ padding: '5px' }}>{''}</span>
                                                        <Input
                                                            type="text"
                                                            name="assetPostTitle"
                                                            value={item.assetPostTitle}
                                                            placeholder="Add Another"
                                                            onChange={(e) => {
                                                                retirementBudgetPostValue(ind, e);
                                                            }}
                                                        />
                                                    </CalculatorInputNumber>
                                                    <BudgetInputNumber>
                                                        <span>{currencySymbol}</span>
                                                        <Input
                                                            type="text"
                                                            name="assetPreValue"
                                                            value={item.assetPreValue}
                                                            placeholder={'0'}
                                                            onBlur={(e) => {
                                                                retirementPostDetails[ind].assetPreValue =
                                                                    formatZeroValues(e.target.value);
                                                                setRetirementPostDetails([...retirementPostDetails]);
                                                            }}
                                                            onChange={(e) => {
                                                                retirementBudgetPostValue(ind, e);
                                                            }}
                                                        />
                                                    </BudgetInputNumber>
                                                    <BudgetInputNumber>
                                                        <span>{currencySymbol}</span>
                                                        <Input
                                                            type="text"
                                                            name="assetPostValue"
                                                            value={item.assetPostValue}
                                                            placeholder={'0'}
                                                            onBlur={(e) => {
                                                                retirementPostDetails[ind].assetPostValue =
                                                                    formatZeroValues(e.target.value);
                                                                setRetirementPostDetails([...retirementPostDetails]);
                                                            }}
                                                            onChange={(e) => {
                                                                retirementBudgetPostValue(ind, e);
                                                            }}
                                                        />
                                                    </BudgetInputNumber>
                                                </tr>
                                            </>
                                        ))}
                                        <tr>
                                            <td>
                                                <AddMoreButton onClick={addRetirementPostInput}>
                                                    Add Another
                                                </AddMoreButton>
                                            </td>
                                        </tr>
                                        <tr>
                                            <TopSideBar>{''}</TopSideBar>
                                            <SpanTotalFlow>
                                                <TotalAmountDiv style={{ marginLeft: 0, width: 151 }}>
                                                    {currencySymbol}
                                                    <span>
                                                        {' '}
                                                        {TotalPreInMonthlyOutflows === 'NaN'
                                                            ? '0.00'
                                                            : TotalPreInMonthlyOutflows}
                                                    </span>
                                                </TotalAmountDiv>
                                            </SpanTotalFlow>
                                            <SpanTotalFlow>
                                                <TotalAmountDiv style={{ marginLeft: 0, width: 151 }}>
                                                    {currencySymbol}
                                                    <span>
                                                        {' '}
                                                        {TotalPostInMonthlyOutflows === 'NaN'
                                                            ? '0.00'
                                                            : TotalPostInMonthlyOutflows}
                                                    </span>
                                                </TotalAmountDiv>
                                            </SpanTotalFlow>
                                        </tr>
                                        <tr>
                                            <TopSideBar>{t('retirement.monthly-excess-shortfall')}</TopSideBar>
                                            <SpanTotalFlow>
                                                <TotalAmountDiv style={{ marginLeft: 0, width: 151 }}>
                                                    {currencySymbol}
                                                    <span>
                                                        {' '}
                                                        {TotalPreRetirementShortFall === 'NaN'
                                                            ? '0.00'
                                                            : TotalPreRetirementShortFall}
                                                    </span>
                                                </TotalAmountDiv>
                                            </SpanTotalFlow>
                                            <SpanTotalFlow>
                                                <TotalAmountDiv style={{ marginLeft: 0, width: 151 }}>
                                                    {currencySymbol}
                                                    <span>
                                                        {' '}
                                                        {TotalPostRetirementShortFall === 'NaN'
                                                            ? '0.00'
                                                            : TotalPostRetirementShortFall}
                                                    </span>
                                                </TotalAmountDiv>
                                            </SpanTotalFlow>
                                        </tr>
                                        <ExtraTdSpacing>
                                            <td> </td>
                                        </ExtraTdSpacing>
                                        <tr>
                                            <td>{''}</td>
                                            <CenterTbSubHeading colSpan={2}>Annual</CenterTbSubHeading>
                                        </tr>
                                        <tr>
                                            <td>{''}</td>
                                            <TotalSubHeadingColumn>Pre - Retirement</TotalSubHeadingColumn>
                                            <TotalSubHeadingColumn>Post - Retirement</TotalSubHeadingColumn>
                                        </tr>
                                        <tr>
                                            <CenterTbHeading>Monthly Cash Outflows</CenterTbHeading>
                                            <td>{''}</td>
                                            <td>{''}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.annual-cash-inflows')}</td>
                                            <td>
                                                <CalculatorInputs>
                                                    {currencySymbol}
                                                    <span>
                                                        {' '}
                                                        {totalAnnualExcessShortfall === 'NaN'
                                                            ? '0.00'
                                                            : totalAnnualExcessShortfall}
                                                    </span>
                                                </CalculatorInputs>
                                            </td>
                                            <td>
                                                <CalculatorInputs>
                                                    {currencySymbol}
                                                    <span>
                                                        {' '}
                                                        {totalPostAnnualExcessShortfall === 'NaN'
                                                            ? '0.00'
                                                            : totalPostAnnualExcessShortfall}{' '}
                                                    </span>
                                                </CalculatorInputs>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.annual-living-expenses')}</td>
                                            <td>
                                                <CalculatorInputs>
                                                    {currencySymbol}
                                                    <span>
                                                        {' '}
                                                        {totalPostAnnualCashInflows === 'NaN'
                                                            ? '0.00'
                                                            : totalPostAnnualCashInflows}
                                                    </span>
                                                </CalculatorInputs>
                                            </td>
                                            <td>
                                                <CalculatorInputs>
                                                    {currencySymbol}
                                                    <span>
                                                        {' '}
                                                        {totalPostAnnualLivingExpense === 'NaN'
                                                            ? '0.00'
                                                            : totalPostAnnualLivingExpense}
                                                    </span>
                                                </CalculatorInputs>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: '700' }}>
                                                {t('retirement.annual-excess-shortfall')}
                                            </td>
                                            <SpanTotalFlow>
                                                <TotalAmountDiv style={{ marginLeft: 0, width: 151 }}>
                                                    {currencySymbol}
                                                    <span>
                                                        {' '}
                                                        {subtractPreExcess === 'NaN' ? '0.00' : subtractPreExcess}
                                                    </span>
                                                </TotalAmountDiv>
                                            </SpanTotalFlow>
                                            <SpanTotalFlow>
                                                <TotalAmountDiv style={{ marginLeft: 0, width: 151 }}>
                                                    {currencySymbol}
                                                    <span>
                                                        {' '}
                                                        {subtractPostExcess === 'NaN' ? '0.00' : subtractPostExcess}
                                                    </span>
                                                </TotalAmountDiv>
                                            </SpanTotalFlow>
                                        </tr>
                                        <tr>
                                            <td>{t('retirement.shortfall-percent')}</td>
                                            <td />
                                            <td>
                                                <CalculatorInputs justifyContent="flex-end">
                                                    {ShortfallCurrentPerValue === '' ||
                                                        ShortfallCurrentPerValue === 'Infinity' ||
                                                        ShortfallCurrentPerValue === 'NaN' ||
                                                        Number(removeNumberFormatting(subtractPostExcess)) > 0
                                                        ? '0'
                                                        : ShortfallCurrentPerValue}
                                                    <span>%</span>
                                                </CalculatorInputs>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t('retirement.shortfall-withdrawn')}
                                                <LightTooltip
                                                    title={
                                                        <>
                                                            <p>{t('retirement.shortfall-withdrawn-tool-tip')}</p>
                                                        </>
                                                    }
                                                >
                                                    <InfoIcon
                                                        style={{ position: 'initial' }}
                                                        src="/static/linkFinancial/info-icon.svg"
                                                        alt=""
                                                    />
                                                </LightTooltip>
                                            </td>
                                            <td />
                                            <RetirementInput>
                                                <span style={{ padding: '5px' }}>{''}</span>
                                                <AutoFieldStyle
                                                    id="tags-outlined"
                                                    options={withDrawnOption}
                                                    name="shortfall_withdrawn"
                                                    value={shortfallWithdrawn}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option?.label === value?.label
                                                    }
                                                    disableClearable
                                                    onChange={(e, val) => {
                                                        setShortfallWithdrawn(val?.label);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            className="dropMenu"
                                                        />
                                                    )}
                                                />
                                            </RetirementInput>
                                        </tr>
                                    </tbody>
                                </HeaderTableStyle>
                            </div>
                        </HeaderDivStyle>
                        <div className="divTableStyle" style={{ marginTop: '0px' }}>
                            <TableStyle style={{ padding: '42px 4% 42px' }} className="tableFullWidthScroll mobileWidthStyle">
                                <tbody>
                                    <tr>
                                        <DiffTableContent>Age</DiffTableContent>
                                        <DiffTableContent>Year #</DiffTableContent>
                                        <DiffTableContent>Year</DiffTableContent>
                                        <DiffTableContent>Annual Contribution</DiffTableContent>
                                        <DiffTableContent>
                                            Shortfall/Withdrawals
                                            <LightTooltip
                                                title={
                                                    <>
                                                        <p>{t('retirement.shortfall-tool-tip')}</p>
                                                    </>
                                                }
                                            >
                                                <InfoIcon
                                                    style={{ position: 'initial' }}
                                                    src="/static/linkFinancial/info-icon.svg"
                                                    alt=""
                                                />
                                            </LightTooltip>
                                        </DiffTableContent>
                                        <DiffTableContent>
                                            Inv Return
                                            <LightTooltip
                                                title={
                                                    <>
                                                        <p>{t('retirement.inv-return-tool-tip')}</p>
                                                    </>
                                                }
                                            >
                                                <InfoIcon
                                                    style={{ position: 'initial' }}
                                                    src="/static/linkFinancial/info-icon.svg"
                                                    alt=""
                                                />
                                            </LightTooltip>
                                        </DiffTableContent>
                                        <DiffTableContent>Net Inc (Dec)</DiffTableContent>
                                        <DiffTableContent>Balance</DiffTableContent>
                                    </tr>
                                    {retirementDetails()?.map((item, indx) => (
                                        <tr key={indx}>
                                            <RightTbText>{item.age}</RightTbText>
                                            <RightTbText>{item.yearNo}</RightTbText>
                                            <RightTbText>{item.year}</RightTbText>
                                            <RightTbText>
                                                {item.contribution === '' ? (
                                                    item.contribution
                                                ) : (
                                                    <span>
                                                        {currencySymbol} {item.contribution}
                                                    </span>
                                                )}
                                            </RightTbText>
                                            <RightTbText style={{ color: item.withdrawl === '0.00' ? 'black' : 'red' }}>
                                                {item.withdrawl === '' ? (
                                                    item.withdrawl
                                                ) : (
                                                    <span>
                                                        {currencySymbol} {item.withdrawl}
                                                    </span>
                                                )}
                                            </RightTbText>
                                            <RightTbText
                                                style={{
                                                    color: !item.investmentReturn.includes('-') ? 'black' : 'red',
                                                }}
                                            >
                                                {item.investmentReturn === '' ? (
                                                    item.investmentReturn
                                                ) : (
                                                    <span>
                                                        {currencySymbol} {item.investmentReturn}
                                                    </span>
                                                )}
                                            </RightTbText>
                                            <RightTbText style={{ color: !item.inc.includes('-') ? 'black' : 'red' }}>
                                                {item.inc === '' ? (
                                                    item.inc
                                                ) : (
                                                    <span>
                                                        {currencySymbol} {item.inc}
                                                    </span>
                                                )}
                                            </RightTbText>
                                            <RightTbText
                                                style={{ color: !item.investment.includes('-') ? 'black' : 'red' }}
                                            >
                                                {item.investment === '' ? (
                                                    item.investment
                                                ) : (
                                                    <span>
                                                        {currencySymbol} {item.investment}
                                                    </span>
                                                )}
                                            </RightTbText>
                                        </tr>
                                    ))}
                                    {(retirementDetails() || []).length ? (
                                        <tr>
                                            <DiffTableContent>Age</DiffTableContent>
                                            <DiffTableContent>Year #</DiffTableContent>
                                            <DiffTableContent>Year</DiffTableContent>
                                            <DiffTableContent>Annual Contribution</DiffTableContent>
                                            <DiffTableContent>
                                                Shortfall/Withdrawals
                                                <LightTooltip
                                                    title={
                                                        <>
                                                            <p>{t('retirement.shortfall-tool-tip')}</p>
                                                        </>
                                                    }
                                                >
                                                    <InfoIcon
                                                        style={{ position: 'initial' }}
                                                        src="/static/linkFinancial/info-icon.svg"
                                                        alt=""
                                                    />
                                                </LightTooltip>
                                            </DiffTableContent>
                                            <DiffTableContent>Inv Return</DiffTableContent>
                                            <DiffTableContent>Net Inc (Dec)</DiffTableContent>
                                            <DiffTableContent>Balance</DiffTableContent>
                                        </tr>
                                    ) : (
                                        ''
                                    )}
                                </tbody>
                            </TableStyle>
                        </div>
                    </Grid>
                </Grid>
                <ButtonText style={isLogged ? {} : { justifyContent: 'center' }}>
                    {isLogged && (
                        <div className='backButtonShowStyle'>
                            {goBackToMenu(navigate)}
                        </div>
                    )}
                    <div className='centerAlignStyle'>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            className="loadingButtonStyle2"
                            // disabled={}
                            onClick={resetFields}
                            style={isLogged ? {} : { maxWidth: '200px' }}
                        >
                            {t('retirement.reset')}
                        </LoadingButton>
                        {isLogged && (
                            <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                variant="outlined"
                                className="loadingButtonStyle1"
                                onClick={savePresentData}
                            >
                                {t('retirement.save')}
                            </LoadingButton>
                        )}
                        {isLogged && (
                            <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                className="loadingButtonStyle2"
                                onClick={exportExcel}
                            >
                                {t('retirement.export')}
                            </LoadingButton>
                        )}
                    </div>
                </ButtonText>
            </div>
        </Page>
    );
}
