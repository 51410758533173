import image from "assets/image"

const getProductDetails = (t) => ([
    {
        id: 1,
        icon: image.moneyBag,
        title: t('everythingYouNeed-constant.title-1'),
        boldText: t('everythingYouNeed-constant.boldText-1'),
        simpleText: t('everythingYouNeed-constant.simpleText-1')
    },
    {
        id: 2,
        icon: image.knowledgeBase2,
        title: t('everythingYouNeed-constant.title-2'),
        boldText: t('everythingYouNeed-constant.boldText-2'),
        simpleText: t('everythingYouNeed-constant.simpleText-2')
    },
    {
        id: 3,
        icon: image.expenseTracking,
        title: t('everythingYouNeed-constant.title-3'),
        boldText: t('everythingYouNeed-constant.boldText-3'),
        simpleText: t('everythingYouNeed-constant.simpleText-3')
    },
    {
        id: 4,
        icon: image.financialTools,
        title: t('everythingYouNeed-constant.title-4'),
        boldText: t('everythingYouNeed-constant.boldText-4'),
        simpleText: t('everythingYouNeed-constant.simpleText-4')
    },
    {
        id: 5,
        icon: image.cashFlowControl,
        title: t('everythingYouNeed-constant.title-5'),
        boldText: t('everythingYouNeed-constant.boldText-5'),
        simpleText: t('everythingYouNeed-constant.simpleText-5')
    },
    {
        id: 6,
        icon: image.timelyReporting,
        title: t('everythingYouNeed-constant.title-6'),
        boldText: t('everythingYouNeed-constant.boldText-6'),
        simpleText: t('everythingYouNeed-constant.simpleText-6')
    }
]);
export {
    getProductDetails
}