import image from 'assets/image';
import GetMore2 from 'pages/home-page/getMore/GetMore2';
import AboutFounder from './about-founder/AboutFounder';
import FeaturesMoolahMate from './featuresMoolahMate/FeaturesMoolahMate';
import MoreAboutFounder from './more-about-founder/MoreAboutFounder';
import WhyTrustMoolahMate from './why-trust-moolahmate/WhyTrustMoolahMate';

import Page from '../../components/Page';
import Vision from './our-vision/OurVision';
import './style.css';

export default function AboutUs() {
    return (
        <Page title={'A new personal finance platform that does it all: MoolahMate'} description={'MoolahMate is a money tracker and budget planner that’s so easy to use. Sign up today to see how you can save more, spend less and reduce your financial stress.'}>
            {/* <Banner /> */}
            <FeaturesMoolahMate />
            <WhyTrustMoolahMate />
            <AboutFounder />
            <MoreAboutFounder />
            <Vision/>
            {/* <Benefits />
            <GetSmarter /> */}
            <GetMore2 imageUrl={image.footerGraphicAnimation} orderType={''} />
        </Page>
    );
}
