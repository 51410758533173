import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

import image from 'assets/image';
import AppDownloadIcons from 'components/app-download-icons/AppDownloadIcons';
import SubHeadingStyle from '../../../components/subHeadingStyle';
import { getProductDetails } from './constant';
import ProductMapItem from '../../../components/ProductMapItem';

export default function EverythingYouNeed() {
    const { t } = useTranslation();
    const productDetails = getProductDetails(t);

    return (
        <Grid className='sectionLeftPadding bgColor mdPaddingBottom'>
            <Grid container spacing={2} pt={6} justifyContent={'end'}>
                <Grid item xl={7} lg={7} md={8} className='everyThingHeadingStyle' mb={2}>
                    <SubHeadingStyle
                        headLineOne={t('home-page.headline-4')}
                        headLineTwo={t('home-page.subtitle-4')}
                        headLineThree={t('home-page.subtitle-info-4')}
                    />
                    <LoadingButton className='loadingButtonStyle2 marginTopSm' type="submit" variant="contained" href="/register">
                        {'Start your Free Trial'}
                    </LoadingButton>
                    <AppDownloadIcons />
                </Grid>
                <Grid item xl={5} lg={5} md={4}>
                    {''}
                </Grid>
                <Grid item md={6} className='mobileOrder'>
                    <Grid container spacing={2}>
                        {
                            productDetails.map((product, indx) => (
                                <Grid item md={6} sm={6} key={indx}>
                                    <ProductMapItem
                                        imageUrl={product.icon}
                                        headLineOne={product.title}
                                        headLineThree={product.boldText}
                                        headLineFour={product.simpleText}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
                <Grid item lg={6} md={12} sx={{ order: '1' }}>
                    <img src={image.everythingYouNeedToManageAnimation} alt="Header logo" className='imageStyle' style={{ width: 640 }}/>
                </Grid>
            </Grid>
        </Grid>
    );
}
