import image from "assets/image";

export const SOCIAL_LINKS = [
    {
        id: 1,
        url: 'https://www.facebook.com/profile.php?id=61552403278053',
        icon: image.facebook,
        title: 'Facebook'
    },
    {
        id: 2,
        url: 'https://www.instagram.com/moolahmate/',
        icon: image.instagram,
        title: 'Instagram'
    },
    {
        id: 3,
        url: 'https://www.linkedin.com/company/moolahmate-ventures-inc',
        icon: image.linkedin,
        title: 'Linkedin'
    },
    {
        id: 4,
        url: 'https://www.youtube.com/@MoolahMate',
        icon: image.youtube,
        title: 'YouTube'
    },
    {
        id: 5,
        url: 'https://twitter.com/moolahmate',
        icon: image.twitter,
        title: 'X (formerly Twitter)'
    }
];