import Page from '../../components/Page';
import DataSecurityPrivacy from './dataSecurityPrivacy/DataSecurityPrivacy';
import HowWeSecureData from './howWeSecureData/HowWeSecureData';

export default function Security() {
    return (
        <Page title={"MoolahMate is backed by world-class, bank-level security"} description={"With MoolahMate, you can rest easy knowing your financial information is protected by certified, high-level security"}>
            <DataSecurityPrivacy />
            <HowWeSecureData />
        </Page>
    );
}
