import React from "react";
import { Pie } from "react-chartjs-2";
import { StickyTable, GraphStyle } from "../Expense/styled-component"

const Piegraph = (props) => {
    const labels = props?.chartData.labels;
    const datasets = props?.chartData.dataset;
    return (
        <StickyTable>
            <GraphStyle>
                <Pie
                    data={{
                        labels,
                        datasets

                    }}
                    options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                            title: {
                                display: true,
                                text: ""
                            },
                            legend: {
                                display: true
                            },
                        },
                        title: {
                            display: true,
                            text: props?.chartData.title,
                            fontSize: 18
                        },
                        tooltips: {
                            callbacks: {
                                label (tooltipItem, data) {                         
                                    const dataset = data.datasets[tooltipItem.datasetIndex];
                                    const currentValue = dataset.data[tooltipItem.index];
                                    const {label} = data.datasets[tooltipItem.datasetIndex];
                                    return ` ${label} (${  currentValue  }%)`;
                                },
                                title (tooltipItem, data) {                                    
                                    return data.labels[tooltipItem[0].index];
                                }
                            }
                        }                      
                    }}
                />
            </GraphStyle>
        </StickyTable>
    )
}

export default Piegraph;